import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

import { actionTypes } from '../constants/actionTypes';
import { apiRoutes } from '../constants/apiRoutes';
import { toastr } from 'react-redux-toastr'

const getWordDocuments = (providerId, webLeadExclusive) => {
  return async (dispatch) => {
    try {
      let url = webLeadExclusive ? apiRoutes.wordDocumentRelated.getWebLeadWordDocuments : apiRoutes.wordDocumentRelated.getWordDocuments;
      url = url.replace('{providerId}', providerId);
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getWordDocumentsSuccess(response));
    } catch (error) {
      toastr.error('Ophalen van documenten mislukt', error.message);
    }
  }
};

const getWordDocumentsSuccess = (response) => {
  return {
    type: actionTypes.getWordDocuments,
    response
  }
};

const addWordDocument = (data) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.wordDocumentRelated.addWordDocument;
      const response = await ApiService.performRequest(RequestType.POST_MULTIPART, url, data);

      dispatch(postWordDocumentSuccess(response));
    } catch (error) {
      toastr.error('Toevoegen mislukt. Probeer het opnieuw', error.message);
    }
  }
};

const postWordDocumentSuccess = (response) => {
  return {
    type: actionTypes.addWordDocument,
    response
  }
};

const editWordDocument = (documentId, data) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.wordDocumentRelated.update.replace('{documentId}', documentId);
      let response = await ApiService.performRequest(RequestType.POST_MULTIPART, url, data);

      dispatch(editWordDocumentSuccess(response));
    } catch (error) {
      toastr.error('Opslaan mislukt. Probeer het opnieuw', error.message);
    }
  }
};

const editWordDocumentSuccess = (response) => {
  return {
    type: actionTypes.editWordDocument,
    response
  }
};

const deleteWordDocument = (documentId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.wordDocumentRelated.update.replace('{documentId}', documentId);
      await ApiService.performRequest(RequestType.DELETE, url);

      dispatch(deleteWordDocumentSuccess(documentId));
    } catch (error) {
      toastr.error('Verwijderen mislukt. Probeer het opnieuw', error.message);
    }
  }
};

const deleteWordDocumentSuccess = (documentId) => {
  return {
    type: actionTypes.deleteWordDocument,
    documentId
  }
};

const downloadWordDocument = (documentId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.wordDocumentRelated.download.replace('{documentId}', documentId);
      const response = await ApiService.performRequest(RequestType.GETFILE, url);

      dispatch(downloadWordDocumentSuccess(response));

      return true;
    } catch ({message}) {
      toastr.error('Kan word document niet downloaden', message);
    }
  }
};

const downloadWordDocumentSuccess = (response) => {
  return {
    type: actionTypes.downloadWordDocument,
    response
  }
};

const generateWordDocument = (documentId, quotationId, isLead) => {
  return async () => {
    try {
      let url = isLead ? apiRoutes.wordDocumentRelated.generateLeadDocument : apiRoutes.wordDocumentRelated.generate;
      url = url.replace('{documentId}', documentId).replace(isLead ? '{leadId}' : '{quotationId}', quotationId);
      await ApiService.performRequest(RequestType.POST, url);
    } catch (e) {
      toastr.error('Kan word document niet downloaden', e.message);
      throw e;
    }
  }
};

const generateQuoteWordDocument = (documentId, quotationId) => {
  return async () => {
    try {
      let url = apiRoutes.wordDocumentRelated.generateQuote;
      url = url.replace('{documentId}', documentId).replace('{quotationId}', quotationId);
      await ApiService.performRequest(RequestType.POST, url);
    } catch (e) {
      toastr.error('Kan word document niet downloaden', e.message);
      throw e;
    }
  }
};

const downloadConvertedWordDocument = (quotation) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.wordDocumentRelated.downloadConvertedWordDocument.replace('{quotation}', quotation);
      const response = await ApiService.performRequest(RequestType.GETFILE, url);

      dispatch(downloadConvertedWordDocumentSuccess(response));
      return true;
    } catch ({message}) {
      toastr.error('Kan pdf document niet downloaden', message);
    }
  }
};

const downloadConvertedWordDocumentSuccess = (response) => {
  return {
    type: actionTypes.downloadConvertedWordDocument,
    response
  }
};

export default {
  getWordDocuments,
  addWordDocument,
  editWordDocument,
  deleteWordDocument,
  downloadWordDocument,
  generateWordDocument,
  downloadConvertedWordDocument,
  generateQuoteWordDocument,
}
