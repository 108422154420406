import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Input} from 'react-materialize';
import PartnerService from "./PartnerService";


class PartnerServicesForm extends Component {

    constructor(props) {
        super(props);
        let product = this.props.product;

        this.state = {
            allowEditOnService: null,
            productLabel: this.getPropValue(product.product_type, 'text'),
            productName: this.getPropValue(product.name, 'text'),
        };
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;
        if(oldProps.product.name !== newProps.product.name) {
            this.setState({
                productLabel: this.getPropValue(newProps.product.product_type, 'text'),
                productName: this.getPropValue(newProps.product.name, 'text'),
                allowEditOnService: null
            });
        }
    }

    getPropValue = (value, type) => {
        if (value !== null && value !== undefined) {
            return value;
        }

        if (type === 'text') {
            return '';
        } else {
            return 0;
        }
    };

    onEditClick = (serviceId) => {
      this.setState({
          allowEditOnService: serviceId
      });
    };

    render() {
        let product = this.props.product;

        return (
            <div>
                <div className="row">
                    <Input
                        s={6}
                        l={6}
                        name="productName"
                        type='text'
                        label={this.state.productLabel}
                        value={this.state.productName}
                        readOnly={true}>
                    </Input>
                </div>
                {product.service_options.map((service, index) => {
                    return (
                        <div>
                            <PartnerService
                                key={index}
                                service={service}
                                isLinked={true}
                                allowEditOnService={this.state.allowEditOnService}
                                onDelete={this.props.onDelete}
                                onSave={this.props.onSave}
                                onEdit={this.onEditClick}
                            />
                        </div>);
                })}
            </div>
        )
    };
}

const mapStateToProps = () => {
    return {};

};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerServicesForm);
