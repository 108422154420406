import React, {Component} from 'react';
import {Input} from 'react-materialize';
import connect from "react-redux/es/connect/connect";
import ReactDOM from "react-dom";
import {toastr} from "react-redux-toastr";
import {formatDate, isElInput, sleep} from "../../../utils/utils";

import KpnDialog from '../../../containers/common/KpnDialog/KpnDialog';
import DialogHeader from "../../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";
import DialogFooter from "../../../containers/common/KpnDialog/DialogFooter";
import DialogButton from "../../../containers/common/KpnDialog/DialogButton";

import quotationType from "../../../constants/quotationType";
import telephoneNumberAction from "../../../constants/telephoneNumberType";
import MobileTelephoneNumberForm from "../../../steps/common/TelephoneNumberForm/MobileTelephoneNumberForm";

class TelephoneNumberDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      telephoneNumber: this.props.telephoneNumber || null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.telephoneNumber !== prevProps.telephoneNumber) {
      const telephone = this.props.telephoneNumber;
      this.setState({
        telephoneNumber: {
          ...telephone,
          contract_termination_date: telephone.contract_termination_date ? telephone.contract_termination_date.substr(0, 10) : null
        }
      });
    }
  }

  componentDidMount() {
    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);
      this.validateDates();
    });
  }

  onChange = ({target}) => {
    let telephoneNumber = {...this.state.telephoneNumber};
    let {name, value, type} = target;

    value = type !== 'checkbox' ? value : target.checked;

    if (name === 'contractNoticePeriod' && (value < 0 || value > 3)) {
      toastr.error('Please insert a value between 0 and 3.');
      return false;
    }

    if (name === 'flexiblePorting' && value) {
      telephoneNumber.portingDate = '';
    }

    if ('isFlexiblePortingVoiceMail' === name) {
      telephoneNumber.isFlexiblePortingVoiceMailFixedIp = false;
    }

    if ('isFlexiblePortingVoiceMailFixedIp' === name) {
      telephoneNumber.isFlexiblePortingVoiceMail = false;
    }

    telephoneNumber[name] = value;

    this.setState({
      telephoneNumber: telephoneNumber
    });

    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);
      this.validateDates();
    });
  };

  onChangeContractStartDate = (date) => {
    this.setState({
      telephoneNumber: {
        ...this.state.telephoneNumber,
        contractStartDate: formatDate(date)
      }
    });
    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);
      this.validateDates();
    });
  };

  onChangeUpgradeWishDate = (date) => {
    this.setState({
      telephoneNumber: {
        ...this.state.telephoneNumber,
        upgrade_wish_date: formatDate(date)
      }
    });
    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);
      this.validateDates();
    });
  };

  onChangeContractEndDate = (date) => {
    this.setState({
      telephoneNumber: {
        ...this.state.telephoneNumber,
        contractEndDate: formatDate(date)
      }
    });
    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);
      this.validateDates();
    });
  };

  onChangePortingDate = (date) => {
    this.setState({
      telephoneNumber: {
        ...this.state.telephoneNumber,
        portingDate: formatDate(date)
      }
    });
    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);
      this.validateDates();
    });
  };

  handleChange = ({target}) => {
    const {name, value} = target;

    if (name === 'contractNoticePeriod') {
      if (value < 0 || value > 3) {
        toastr.error('Please insert a value between 0 and 3.');
        return false;
      }
    }

    if (name === 'terminateAsSoonPossible' && value === 'Ja') {
      this.setState({
        telephoneNumber: {
          ...this.state.telephoneNumber,
          ['contract_termination_date']: new Date()
        }
      });
    }

    this.setState({
      telephoneNumber: {
        ...this.state.telephoneNumber,
        [name]: value
      }
    });
    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);
    });
  };

  onChangeContractTerminationDate = (date) => {
    this.setState({
      telephoneNumber: {
        ...this.state.telephoneNumber,
        contract_termination_date: formatDate(date)
      }
    });
    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);
      this.validateDates();
    });
  };

  validateDates = () => {
    let form = ReactDOM.findDOMNode(this.globalForm);
    let el;
    let startDay = new Date();
    startDay.setUTCHours(0, 0, 0, 0);

    if (!this.state.telephoneNumber || !form) {
      return;
    }

    const {action} = this.state.telephoneNumber;
    const portingDate = this.state.telephoneNumber.portingDate ? new Date(this.state.telephoneNumber.portingDate) : null;

    el = form.querySelector('input[name="portingDate"]');
    if (el && portingDate) {
      if (portingDate < startDay) {
        el.classList.remove('valid');
        el.classList.add('invalid');
      } else {
        el.classList.remove('invalid');
        el.classList.add('valid');
      }
    }

    if ([2, 3, 5].includes(parseInt(action))) {
      const contractStartDate = this.state.telephoneNumber.contractStartDate ? new Date(this.state.telephoneNumber.contractStartDate) : null;
      el = form.querySelector('input[name="contractStartDate"]');
      if (el && contractStartDate) {
        if (contractStartDate === startDay) {
          el.classList.remove('valid');
          el.classList.add('invalid');
        } else {
          el.classList.remove('invalid');
          el.classList.add('valid');
        }
      }
    }

    if (parseInt(action) === 6) {
      const upgradeWishDate = this.state.telephoneNumber.upgradeWishDate ? new Date(this.state.telephoneNumber.upgradeWishDate) : null;
      el = form.querySelector('input[name="upgradeWishDate"]');
      if (el && upgradeWishDate) {
        if (upgradeWishDate === startDay) {
          el.classList.remove('valid');
          el.classList.add('invalid');
        } else {
          el.classList.remove('invalid');
          el.classList.add('valid');
        }
      }
    }

    if (parseInt(action) === 8 || parseInt(action) === 7) {
      const today = startDay;
      const contractDurationDate = this.state.telephoneNumber.contract_termination_date ? new Date(this.state.telephoneNumber.contract_termination_date) : null;
      const minimumContractTerminationDate = new Date(formatDate(new Date(today.setMonth(today.getMonth() + 1))));

      el = form.querySelector('input[name="contractTerminationDate"]');
      if (el && contractDurationDate) {
        if ((parseInt(action) === 8 && contractDurationDate.getTime() < minimumContractTerminationDate.getTime()) || contractDurationDate === startDay) {
          el.classList.remove('valid');
          el.classList.add('invalid');
        } else {
          el.classList.remove('invalid');
          el.classList.add('valid');
        }
      }
    }
  };

  onSubmit = () => {
    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);
      this.validateDates();

      let invalidElements = ReactDOM.findDOMNode(this.globalForm).getElementsByClassName('invalid');

      if (!invalidElements.length) {
        const {telephoneNumber} = this.state;

        if (!telephoneNumber.terminateAsSoonPossible) {
          telephoneNumber.terminateAsSoonPossible = 'Nee'
        }
        this.props.onSave(telephoneNumber);
      }
    });
  };

  onCancel = () => {
    this.props.onCancel();
  };

  checkInputsForValidity = (el) => {
    if (el && el.children) {
      Array.from(el.children).forEach((child) => {
        this.checkInputsForValidity(child);
      });
    }

    if (isElInput(el) === false) {
      return;
    }

    const parentElement = el.parentElement;

    if (el.checkValidity() === false) {
      el.classList.remove('valid');
      el.classList.add('invalid');

      if (parentElement.classList.contains('select-wrapper')) {
        parentElement.classList.remove('valid');
        parentElement.classList.add('invalid');
      }
    }

    if (el.checkValidity() === true) {
      el.classList.remove('invalid');
      el.classList.add('valid');

      if (parentElement.classList.contains('select-wrapper')) {
        parentElement.classList.remove('invalid');
        parentElement.classList.add('valid');
      }
    }
  };

  render() {
    const {telephoneNumber, inputsDisabled, quotationNumber} = this.props;
    const {type} = this.props.quotationType;

    const isExceptionAction = this.props.isExceptionQuotation && [6, 7].includes(telephoneNumber.action);
    const isOpheffenActionNumber = telephoneNumber.action === telephoneNumberAction.OPHEFFEN_ACTION;
    const isVerlengenActionNumber = telephoneNumber.action === telephoneNumberAction.VERLENGEN_ACTION;
    const isVerlengenBetaType = [quotationType.TYPE_VERLENGEN, quotationType.TYPE_EXISTING].includes(type);
    const isVerlengenBeta = isVerlengenActionNumber && isVerlengenBetaType;
    const verlengenCondition = [6, 7, 8].includes(telephoneNumber.action);

    return (
      <div className="telephone-number-dialog">
        {<KpnDialog
          dialogState={this.props.dialogOpened}
          modal={false}
          overflowException={isVerlengenBetaType && verlengenCondition}
          dialogHeader={(
            <DialogHeader
              hasVerlengenAction={isVerlengenBeta}
              headerIcon="edit_location"
              headerText={!isOpheffenActionNumber ? `${this.props.numberText || 'Nieuwe telefoon nummer'}: Aanvullen data` : `${this.props.numberText}: Ophefwensen`}
              closeHandler={this.onCancel}
            />
          )}

          dialogBody={
            <DialogBody>
              {
                <form ref={(ref) => (this.globalForm = ref)}>
                  {!isOpheffenActionNumber && !isExceptionAction && !isVerlengenBeta &&
                  <div className="row">
                    <Input
                      s={4}
                      value={this.state.telephoneNumber.firstName || ''}
                      onChange={this.onChange}
                      label="Voornaam"
                      name="firstName"
                      type="text"
                      disabled={inputsDisabled}
                    />
                    <Input
                      s={4}
                      value={this.state.telephoneNumber.insertion || ''}
                      onChange={this.onChange}
                      label="Tussenvoegsels"
                      name="insertion"
                      type="text"
                      disabled={inputsDisabled}
                    />
                    <Input
                      s={4}
                      value={this.state.telephoneNumber.lastName || ''}
                      onChange={this.onChange}
                      label="Achternaam"
                      name="lastName"
                      type="text"
                      required
                      disabled={inputsDisabled}
                    />
                  </div>
                  }

                  {!isOpheffenActionNumber && !isExceptionAction && !isVerlengenBeta &&
                  <div className="row">
                    <Input
                      s={8}
                      value={this.state.telephoneNumber.streetName || ''}
                      onChange={this.onChange}
                      label="Straat"
                      name="streetName"
                      type="text"
                      required
                      disabled={inputsDisabled}
                    />
                    <Input
                      s={2}
                      value={this.state.telephoneNumber.houseNumber || ''}
                      onChange={this.onChange}
                      label="Huisnummer"
                      name="houseNumber"
                      type="text"
                      pattern="[0-9]*"
                      required
                      disabled={inputsDisabled}
                    />
                    <Input
                      s={2}
                      value={this.state.telephoneNumber.houseNumberExtension || ''}
                      onChange={this.onChange}
                      label="toevoeging"
                      name="houseNumberExtension"
                      maxLength="4"
                      minLength="0"
                      type="text"
                      disabled={inputsDisabled}
                    />
                  </div>
                  }

                  {!isOpheffenActionNumber && !isExceptionAction && !isVerlengenBeta &&
                  <div className="row">
                    <Input
                      s={2}
                      value={this.state.telephoneNumber.postalCode || ''}
                      onChange={this.onChange}
                      label="Postcode"
                      name="postalCode"
                      type="text"
                      pattern="[1-9][0-9]{3}[a-zA-Z]{2}"
                      required
                      disabled={inputsDisabled}
                    />
                    <Input
                      s={10}
                      value={this.state.telephoneNumber.city || ''}
                      onChange={this.onChange}
                      label="Woonplaats"
                      name="city"
                      type="text"
                      pattern="[a-zA-Z\s]+"
                      required
                      disabled={inputsDisabled}
                    />
                  </div>
                  }

                  {this.state.telephoneNumber.type === 'mobile' && (
                    <MobileTelephoneNumberForm
                      telephoneNumber={this.state.telephoneNumber}
                      onChange={this.onChange}
                      onChangeContractStartDate={this.onChangeContractStartDate}
                      onChangeUpgradeWishDate={this.onChangeUpgradeWishDate}
                      onChangeContractEndDate={this.onChangeContractEndDate}
                      onChangePortingDate={this.onChangePortingDate}
                      onChangeContractTerminationDate={this.onChangeContractTerminationDate}
                      handleChange={this.handleChange}
                      openedWarningPopup={this.state.openedWarningPopup}
                      isExceptionAction={isExceptionAction}
                      isVerlengenBeta={isVerlengenBeta}
                      isVerlengenBetaType={isVerlengenBetaType}
                      verlengenCondition={verlengenCondition}
                      useNina={this.props.authentication.useNina}
                      inputsDisabled={inputsDisabled}
                    />
                    )}
                </form>
              }
            </DialogBody>
          }
          dialogFooter={
            <DialogFooter
              buttonLeft={(
                <DialogButton
                  buttonAction={this.onCancel}
                  buttonText='Annuleren'
                  buttonDisabled={inputsDisabled}
                  left={true}
                />
              )}
              buttonRight={(
                <DialogButton
                  buttonAction={this.onSubmit}
                  buttonText='Opnieuw versturen'
                  buttonDisabled={inputsDisabled}
                />
              )}
            />
          }
        />}
      </div>
    )
  };
}

const mapStateToProps = ({authentication}) => {
  return {
    authentication
  }
};

const mapDispatchToProps = () => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(TelephoneNumberDialog);
