import React, {Component} from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from "material-ui";
import TotalTables from "../../steps/workflow/discount/totalTables";

class DigitalSigningOverview extends Component {

  generateTotalHeaders = () => {
    return (
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow>
          <TableHeaderColumn className={'digital-signing-table-column-description'}>Naam</TableHeaderColumn>
          <TableHeaderColumn className={'digital-signing-table-column-amount'}>Bruto prijs</TableHeaderColumn>
          <TableHeaderColumn className={'digital-signing-table-column-amount'}>Korting</TableHeaderColumn>
          <TableHeaderColumn className={'digital-signing-table-column-amount'}>Netto prijs</TableHeaderColumn>
        </TableRow>
      </TableHeader>
    );
  };

  getProductsByDiscriminator = (discriminator) => {
    return this.props.quotationProducts.totals.find(
      (x) => x.name === discriminator,
    ).products;
  };

  generateTotalRows = (discriminator) => {
    const products = this.getProductsByDiscriminator(discriminator);

    return products.map((product) => (
      <TableRow
        key={product.id}
        className="totals"
      >
        <TableRowColumn  className={'digital-signing-table-column-description'}
          style={{whiteSpace: 'normal', wordWrap: 'break-word'}}
        >
          {product.name}
        </TableRowColumn>
        <TableRowColumn className={'digital-signing-table-column-amount'} style={{textAlign: 'right'}}>
          {new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',
          }).format(product.price)}
        </TableRowColumn>
        <TableRowColumn className={'digital-signing-table-column-amount'} style={{textAlign: 'right'}}>
          {new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',
          }).format(product.discount)}
        </TableRowColumn>
        <TableRowColumn className={'digital-signing-table-column-amount'} style={{textAlign: 'right'}}>
          {new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',
          }).format(product.price - product.discount)}
        </TableRowColumn>
      </TableRow>
    ));
  };

  generateHeaders = (productGroup) => {
    return (
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow>
          <TableHeaderColumn className={'digital-signing-table-column-description'}>
            <div style={{whiteSpace: 'break-word', fontWeight: 'bold'}}>Productomschrijving</div>
            <div className="subthread" style={{display: 'inline-block'}}/>
          </TableHeaderColumn>
          <TableHeaderColumn className={'digital-signing-table-column-amount'}>
            <div style={{fontWeight: 'bold'}}>Aantal</div>
            <div className="subthread" style={{display: 'inline-block'}}/>
          </TableHeaderColumn>
          <TableHeaderColumn className={'digital-signing-table-column-amount'}>
            <span style={{fontWeight: 'bold'}}>Totale prijs</span>
            <div className="subthread">maandelijks</div>
          </TableHeaderColumn>
          <TableHeaderColumn className={'digital-signing-table-column-amount'}>
            <span style={{fontWeight: 'bold'}}>Totale prijs</span>
            <div className="subthread">eenmalig</div>
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>
    )
  };

  calculatePieceMonthlyPrice = (product) => {
    return product.price_gross_monthly;
  };

  calculatePieceOneTimePrice = (product, isCustomCategory) => {
    if (isCustomCategory) {
      return product.price_gross_onetime + product.setUpPrice + product.installationPrice + product.pmPrice;
    } else {
      return product.price_gross_onetime;
    }
  };

  generateProductRow = (product, productGroup) => {
    const isCustomCategory = productGroup.isCustomCategory;

    return (
      <TableRow>
        <TableRowColumn className={'digital-signing-table-column-description'} style={{ whiteSpace: 'normal', wordWrap: 'break-word'}}>
          {product.name}
        </TableRowColumn>
        <TableRowColumn className={'digital-signing-table-column-amount'}>
          {product.allow_decimal_quantity ? new Intl.NumberFormat('nl-NL', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(product.amount) : product.amount}
        </TableRowColumn>
        <TableRowColumn className={'digital-signing-table-column-amount'} style={{textAlign: 'right'}}>
          {new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR'
          }).format(this.calculatePieceMonthlyPrice(product))}
        </TableRowColumn>
        <TableRowColumn className={'digital-signing-table-column-amount'} style={{textAlign: 'right'}}>
          {new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR'
          }).format(this.calculatePieceOneTimePrice(product, isCustomCategory))}
        </TableRowColumn>
      </TableRow>
    );
  };

  render() {
    const {quotationProducts} = this.props;
    const quotationProductsExist = quotationProducts && quotationProducts.length !== 0;
    const categories = quotationProductsExist ? quotationProducts.products : [];
    const totals = quotationProductsExist ? quotationProducts.totals : [];

    return (
      <div>
        <div className="row overviewkopbgtotal">
          <div className="col s12">
            <div className="overviewkopgroen">
              Offerte overzicht
              <span className="right bodynormal"/>
            </div>
          </div>
        </div>

        {
          quotationProductsExist &&
          categories.map((productGroup) => {
            const {name, products} = productGroup;
            const productGroupName = name !== 'KPN IT Diensten' ? name : 'Microsoft IT Diensten';
            return (
              <div className="row" style={{ overflow: 'hidden'}}>
                <div style={{ overflowX: 'auto', display: 'grid' }}>
                  <div className="col s12 overviewkopbg">
                    <div className="overviewkop">
                      {productGroupName}
                    </div>
                  </div>
                  <div className={'digitalSigningTable'}>
                    <Table
                      wrapperStyle={{overflow: 'visible'}}
                      selectable={false}
                      displayRowCheckbox={false}
                    >
                      {this.generateHeaders(productGroup)}
                      <TableBody displayRowCheckbox={false}>
                        {products.map((product) => (this.generateProductRow(product, productGroup)))}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>
            );
          })
        }
        {
          totals &&
          ((totals[0] && totals[0].products.length) || (totals[1] && totals[1].products.length)) &&
          (
            <div
              className="row"
              style={{
                marginLeft: '-0.75rem',
                marginRight: '-0.75rem',
              }}
            >
              <div className="col s12 m12">
                <div className="row overviewkopbgtotal">
                  <div className="col s12">
                    <div className="overviewkopgroen">
                      Totalen
                      <span className="right bodynormal"/>
                    </div>
                  </div>
                </div>
                {totals[0].products.length ? (
                  <React.Fragment>
                    <div className="row" style={{ overflow: 'hidden'}}>
                      <div style={{ overflowX: 'auto', display: 'grid' }}>
                        <div className="col s12 overviewkopbg">
                          <div className="overviewkop">{"Maandelijkse kosten"}<span className="right bodynormal"></span></div>
                        </div>
                        <div className={'digitalSigningTable'}>
                          <Table
                            className="highlight without-margin-bottom"
                            wrapperStyle={{ overflow: 'visible' }}
                            selectable={false}
                          >
                            {this.generateTotalHeaders()}
                            <TableBody displayRowCheckbox={false}>
                              {this.generateTotalRows('Monthly costs')}
                            </TableBody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : null}
                {totals[1].products.length ? (
                  <React.Fragment>
                    <div className="row" style={{ overflow: 'hidden'}}>
                      <div style={{ overflowX: 'auto', display: 'grid' }}>
                        <div className="col s12 overviewkopbg">
                          <div className="overviewkop">{"Eenmalige kosten"}<span className="right bodynormal"></span></div>
                        </div>
                        <div className={'digitalSigningTable'}>
                          <Table
                            className="highlight without-margin-bottom"
                            wrapperStyle={{ overflow: 'visible' }}
                            selectable={false}
                          >
                            {this.generateTotalHeaders()}
                            <TableBody displayRowCheckbox={false}>
                              {this.generateTotalRows('One time cost')}
                            </TableBody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

export default DigitalSigningOverview;
