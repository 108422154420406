import axios from 'axios';
import RequestType from '../constants/requestType';
import {apiRoutes} from '../constants/apiRoutes';
import loginActions from '../actions/authenticationActions';
import store from '../store';

export default class ApiService {

    static ROOT_URL = apiRoutes.rootUrl;

    static async performRequest(type, url, data = null) {
        url = url.replace(/\/$/g, '')
        let token = localStorage.getItem('token');
        let response;

        try{
            switch(type) {
                case RequestType.GET:
                    let req = await axios.get(ApiService.ROOT_URL + url, {headers: {'Authorization' : `Bearer ${token}`}});
                    response =  req;
                    return response.data;
                    break;
                case RequestType.GETFILE:
                    response = await this.getFileRequest(url);
                    break;
                case RequestType.PUT:
                    response = await this.putRequest(url, data);
                    break;
                case RequestType.DELETE:
                    response = await this.deleteRequest(url);
                    break;
                case RequestType.POST:
                    response = await this.postRequest(url, data);
                    break;
                case RequestType.PATCH:
                    response  = await this.patchRequest(url, data);
                    break;
                case RequestType.POST_MULTIPART:
                    response = await this.postMultipartRequest(url, data);
                    break;
                default:
                    return null;
            }

            return response.data;
        } catch(e) {
            if(e.response.status === 401) {
                if (localStorage.getItem('token')) {
                    store.dispatch(loginActions.sessionHasExpired());
                } else {
                    store.dispatch(loginActions.logout());
                }
            } else {
                throw e;
            }
        }
    }

    static getFileRequest = async (url) => {
        let token = localStorage.getItem('token');

        return await axios.get(ApiService.ROOT_URL + url, { responseType:"arraybuffer",  headers: {'Authorization' : `Bearer ${token}`}}); // Use array buffer instead of blob, see link https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/responseType
    };

    static getCustomerSuggestions = async (url, cancelToken) => {
        let token = localStorage.getItem('token');

        let config = {headers: {'Authorization' : `Bearer ${token}`}};

        if (cancelToken) {
            config.cancelToken = cancelToken;
        }

        const response = await axios.get(ApiService.ROOT_URL + url, config);

        return response.data;
    };

    static putRequest = async (url, data) => {
        let token = localStorage.getItem('token');

        return await axios.put(ApiService.ROOT_URL + url, data, {headers: {'Authorization' : `Bearer ${token}`}});
    };

    static postMultipartRequest = async (url, data) => {
        let token = localStorage.getItem('token');

        // Convert data to form data
        let formData = new FormData();
        Object.keys(data).forEach(function(item) {
            formData.append(item, data[item]);
        });

        return axios.post(ApiService.ROOT_URL + url, formData, {headers: {
            'Authorization' : `Bearer ${token}`,
            'Content-Type' : 'multipart/form-data'
        }});
    };

    static deleteRequest = async (url) => {
        let token = localStorage.getItem('token');

        return await axios.delete(ApiService.ROOT_URL + url, {headers: {'Authorization' : `Bearer ${token}`}});
    };

    static postRequest = async (url, data) => {
        let token = localStorage.getItem('token');

        if (data instanceof File) {
            let formData = new FormData();
            formData.append('file', data);
            return await axios.post(ApiService.ROOT_URL + url, formData, {headers: {
                'Authorization' : `Bearer ${token}`,
                'Content-Type' : 'multipart/form-data'
            }});
        }
        else {
            return await axios.post(ApiService.ROOT_URL + url, data, {headers: {'Authorization' : `Bearer ${token}`}});
        }
    };

    static patchRequest = async (url, data) => {
        let token = localStorage.getItem('token');

        return await axios.patch(ApiService.ROOT_URL + url, data, {headers: {'Authorization' : `Bearer ${token}`}})
    }
}
