import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Input} from 'react-materialize';
import HardwareOption from "./HardwareOption";

class HardwareOptionForm extends Component {

  constructor(props) {
      super(props);
      let product = this.props.product;
      this.state = {
          allowEditOnHardware: null,
          productLabel: this.getPropValue(product.product_type, 'text'),
          productName: this.getPropValue(product.name, 'text'),
          productId: product.id
      };
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.product.id!==this.props.product.id){
        let {productLabel, productName, productId}=this.state;
        this.setState({
            productId: productId,
            productName: productName,
            productLabel: productLabel
        });
    }
  }

  getPropValue = (value, type) => {
      if (value !== null && value !== undefined) {
          return value;
      }

      if (type === 'text') {
          return '';
      } else {
          return 0;
      }
  };

  onEditClick = (hardwareId) => {
      this.setState({
          allowEditOnHardware: hardwareId
      });
  };

  render() {
    let product = this.props.product;

    return (
      <div>
        <div className="row">
          <Input
            s={6}
            l={6}
            name="product"
            type='text'
            label={this.state.productLabel}
            value={this.state.productName}
            readOnly={true}>
          </Input>
        </div>

        {product.hardware_options.map((hardwareOption, index) => {
          return (
              <div>
                  <HardwareOption
                      key={index}
                      index={index}
                      hardwareOption={hardwareOption}
                      isLinked={true}
                      allowEditOnHardware={this.state.allowEditOnHardware}
                      onDelete={this.props.onDelete}
                      onSave={this.props.onSave}
                      onEdit={this.onEditClick}/>
              </div>);
        })}
      </div>
    )
  };
}

const mapStateToProps = () => {
    return {};

};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(HardwareOptionForm);

