import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Forbidden extends Component {
  render() {
    return (
      <div className="valign-wrapper" style={{ height: '100vh', justifyContent: 'center' }}>
        <div className="valign" style={{ maxWidth: '600px' }}>
          <div className="container maincontainer">
            <div className="section">
              Toegang voor deze pagina is verboden.
              <br />
              <br />
              <Link to="/">Terug naar de startpagina</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Forbidden;
