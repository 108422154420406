import React from 'react';
import { Row, Col, Icon } from 'react-materialize'
import {toastr} from "react-redux-toastr";

import {DelayInput} from "react-delay-input";
import RegularSelect from "../../../components/RegularSelect";

import "react-datepicker/dist/react-datepicker.css";
import providerType from "../../../constants/providerType";

const HostedVoiceNumber = (props) => {
  const {number, index, formErrors, numbersMatrix, inputsDisabled, provider} = props;

  const validate = (changedNumber) => {
    if (parseInt(changedNumber.existing) === 0) {
      changedNumber.valid = true;
      changedNumber['startingNumber'] = '';
    } else {
      changedNumber.valid = props.validateField('startingNumber', index, changedNumber.startingNumber, hasPrefix(changedNumber) ? 7 : 10);
    }

    return changedNumber;
  };

  const onChange = ({target}) => {
    const {name} = target;
    let {value} = target;

    if (target.type === 'number' && value !== '' && !value.match(/^\d+$/)) {
      toastr.error("Can't specify negative values - to be translated");
      return;
    }

    if (name === 'existing') {
      value = JSON.parse(value);
    }
    let changedNumber = {...number};
    changedNumber[name] = value;
    changedNumber.valid = false;

    if (name === 'numberType') {
      changedNumber['existing'] = numbersMatrix[value].existing[0];

      // reset no. on change of type
      changedNumber['startingNumber'] = '';
    }

    changedNumber = validate(changedNumber);

    const blocksAvailable = changedNumber.existing ?
      numbersMatrix[changedNumber.numberType].existingBlocks :
      numbersMatrix[changedNumber.numberType].newBlocks;

    const isBlocksSelectionAvailable = blocksAvailable.reduce((acc, el) => {
      if (el === parseInt(changedNumber.blocks)) {
        acc = true;
      }
      return acc;
    }, false);

    if (!isBlocksSelectionAvailable) {
      changedNumber['blocks'] = blocksAvailable[0];
    }

    changedNumber.prefix = hasPrefix(changedNumber);

    props.changeNumber(changedNumber, index)
  };

  const renderNumberTypeOptions = () => {
    let matrix = Object.keys(numbersMatrix).map((key) => {
      return {
        type: parseInt(numbersMatrix[key].type),
        name: numbersMatrix[key].name
      }
    });
    const idsAllowedVodafone = [0,1,2];
    matrix = [...matrix].filter(obj => {
      if (provider === providerType.VODAFONE && idsAllowedVodafone.includes(obj.type)) {
        return true;
      } else if (provider === providerType.VODAFONE) {
        return false;
      }

      return true;
    });

    return matrix.map(obj => {
      return <option value={obj.type} key={obj.type}>{obj.name}</option>
    });
  };

  const renderExistingOptions = () => {
    return numbersMatrix[number.numberType].existing.map((el) => {
      return <option value={el} key={el}>{el ? 'porteren' : 'nieuw'}</option>
    });
  };

  const renderBlocksOptions = () => {
    let blocksOptions = number.existing ?
      numbersMatrix[number.numberType].existingBlocks :
      numbersMatrix[number.numberType].newBlocks;

    blocksOptions = blocksOptions.filter(option => {
      return !(provider === providerType.VODAFONE && option === 10000);
    });

    return blocksOptions.map((el) => {
      return <option value={el} key={el}>{el}</option>
    })
  };

  const hasPrefix = (number) => {
    return numbersMatrix[number.numberType].prefix || null;
  };

  const hasRange = (number) => {
    const value = number.startingNumber;
    if (!value || parseInt(number.blocks) === 1) {
      return '';
    }

    let rangeStart = value;
    let rangeEnd = String(parseInt(value) + parseInt(number.blocks) - 1);
    if (hasPrefix(number)) {
      rangeStart = '(' + hasPrefix(number) + ') ' + value.padStart(7, '0');
      rangeEnd = '(' + hasPrefix(number) + ') ' + rangeEnd.padStart(7, '0');
    } else {
      rangeStart = rangeStart.padStart(10, '0');
      rangeEnd = rangeEnd.padStart(10, '0');
    }

    return `Reeks: ${rangeStart} - ${rangeEnd}`;
  };

  const fieldHasError = (fieldName) => {
    //search if formErrors[index] contains the fieldName
    if (formErrors[index] && formErrors[index].indexOf(fieldName) !== -1) {
      return true;
    }

    //number.error => add error for startingNumber
    return !!(fieldName === 'startingNumber' && number.error);
  };

  return (
    <Row>
      {provider !== providerType.VODAFONE &&
        <Col s={6} l={2} className="input-field inner-addon">
          <RegularSelect
            name="numberType"
            id={"numberType" + index}
            onChange={onChange}
            value={number.numberType}
            multiple={false}
            disabled={inputsDisabled}
          >
            {renderNumberTypeOptions()}
          </RegularSelect>
          <label htmlFor={"numberType" + index}>Type telefoonnummer</label>
        </Col>
      }

      <Col s={6} l={2} className="input-field inner-addon">
        <RegularSelect
          name="existing"
          id={"existing" + index}
          onChange={onChange}
          value={number.existing}
          multiple={false}
          disabled={inputsDisabled}
        >
          {renderExistingOptions()}
        </RegularSelect>
        <label htmlFor={"existing" + index}>Selecteer</label>
      </Col>

      <Col s={3} l={1} className="input-field inner-addon">
        <RegularSelect
          name="blocks"
          id={"blocks" + index}
          onChange={onChange}
          value={number.blocks}
          multiple={false}
          disabled={inputsDisabled}
        >
          {renderBlocksOptions()}
        </RegularSelect>
        <label htmlFor={"blocks" + index}>Selecteer blok</label>
      </Col>

      {parseInt(number.existing) !== 0 ? <div className="col input-field s7 l3">
        {hasPrefix(number) ? (
          <span className="input-group-addon prefix"
                style={{
                  fontSize: "1rem",
                  backgroundColor: "#ddd",
                  lineHeight: "3rem",
                  padding: "0 0.5rem",
                  border: "solid 1px #939393",
                  marginLeft: "2px",
                  borderRadius: "4px"
                }}>{hasPrefix(number)}</span>
        ) : null
        }

        <DelayInput
          delayTimeout={1000}
          name="startingNumber"
          type="text"
          value={number.startingNumber !== number.prefix ? number.startingNumber : ''}
          onChange={onChange}
          maxLength={[0, 1, 2, 13].includes(parseInt(number.numberType)) ? (`${hasPrefix(number) ? '7' : '10'}`) : ''}
          required="required"
          className={inputsDisabled || `${fieldHasError('startingNumber') ? 'invalid' : 'valid'}`}
          style={hasPrefix(number) ? {width:"calc(100% - 60px)"} : {}}
          disabled={inputsDisabled}
        />
        <label className="active" style={{width:"100%", marginLeft: 0}}>(Eerste) telefoonnummer</label>
        {number.error && number.error === 'numbers-collide' ? (
            <span style={{position: "absolute", bottom: "10px", color: "#ff0000", fontSize: "0.65rem"}}>Tenminste een van deze nummers is al bezet.</span>
          )
          : (<span style={{position: "absolute", bottom: "10px", fontSize: "0.65rem"}}>{hasRange(number)}</span>)
        }
      </div> : null}

      <button
        className={'btn kpn-btn white-text ratio-btn-right right s1 ' + (inputsDisabled || 'doceri-btn-left')}
        onClick={() => props.removeNumber(props.index, number.id)}
        disabled={inputsDisabled}
      >
        Verwijder
        <Icon right>
          close
        </Icon>
      </button>
    </Row>
  )
};

export default HostedVoiceNumber;
