import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom'

import adminActions from '../../../actions/adminActions';
import UsersGrid from './UsersGrid';

import KpnDialog  from '../../../containers/common/KpnDialog/KpnDialog';
import DialogHeader from '../../../containers/common/KpnDialog/DialogHeader';
import DialogBody from '../../../containers/common/KpnDialog/DialogBody';
import DialogFooter from '../../../containers/common/KpnDialog/DialogFooter';
import DialogButton from '../../../containers/common/KpnDialog/DialogButton';
import RegularSelect from '../../../components/RegularSelect';
import {getUserInfo} from "../../../actions/authenticationActions";

class UsersPanel extends Component {
  userInitial = {
    id: 0,
    lastName: '',
    surName: '',
    username: '',
    phoneNumber: '',
    allowedDiscount: 0,
    type: 'ratio',
    roles: [],
  };

  state = {
    deleteDialogOpened: false,
    assignDialogOpened: false,
    users: [],
    userId: 0,
    assignUser: null,
  };

  componentDidMount() {
    this.props.adminActions.getUsers();
  };

  onAddClick = () => {
    this.props.adminActions.setCurrentUser(this.userInitial).then(() => {
      this.props.adminActions.setCurrentSection('user');
    });
  };

  onEditClick = (row) => {
    this.props.adminActions.setCurrentUser(row).then(() => {
      this.props.adminActions.setCurrentSection('user');
    });
  };

  onDeleteClick = (row) => {
    this.setState({
      deleteDialogOpened: true,
      userId: row.id
    });
  };

  handleDeleteDialogSubmit = () => {
    this.props.adminActions.isDependentUser(this.state.userId).then((response) => {
      if (response) {
        this.setState({ deleteDialogOpened: false, assignDialogOpened: true});
      } else {
        this.props.adminActions.deleteUser(this.state.userId)
          .then(this.setState({ deleteDialogOpened: false, userId: 0 }))
          .then(this.props.adminActions.getUsers());
      }
    });
  };

  handleAssignDialogSubmit = () => {
    this.props.adminActions.assignDataToUser(this.state.userId, this.state.assignUser)
      .then((response) => {
        if (response) {
          this.props.adminActions.deleteUser(this.state.userId)
            .then(this.setState({ assignDialogOpened: false, userId: 0 , assignUser: null}))
            .then(this.props.adminActions.getUsers());
        }
      });
  };

  handleDialogClose = (stateProperty) => {
    this.setState({
      [stateProperty]: false,
      assignUser: null
    })
  };

  onChange = ({target}) => {
    this.setState({
      assignUser: target.value || null
    });
  };

  renderUserListOptions = () => {
    return this.props.users.filter(user => user.id !== this.state.userId).map((el) => {
      return <option
        value={el ? el.id : null}
        key={el.username}
        name="username">
        {el ? el.username : 'Selecteer een gebruiker'}
      </option>
    });
  };

  goToDashboard = () => {
    this.props.history.push('/');
  };

  render() {
    const { users } = this.props;

    return (
      <div>
        <div id="Forms" className="ratiotable paddingbottomnone">
          <h1 className="admin-subheader-text">
            <span className="ratio-section-title">Gebruikers</span>
          </h1>
          <button
              className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
              onClick={() => this.onAddClick()}
              type="submit"
              name="action">
            <i className="small material-icons left white-text">add</i>
            Voeg nieuwe gebruiker toe
          </button>
        </div>

        <UsersGrid
          rows={users}
          onEditClick={this.onEditClick}
          onDeleteClick={this.onDeleteClick}
        />

        <KpnDialog
          dialogState={this.state.deleteDialogOpened}
          onRequestClose={() => { this.handleDialogClose('deleteDialogOpened') }}
          modal={true}
          dialogHeader={(
            <DialogHeader
              headerIcon="remove_circle_outline"
              headerText="Bevestig verwijdering"
            />
          )}

          dialogBody={
            <DialogBody>
              <div className="col s12">
                Weet u zeker dat u deze gebruiker wilt verwijderen?<br /><br />
              </div>
            </DialogBody>
          }
          dialogFooter={
            <DialogFooter
              buttonLeft={(
              <DialogButton
                buttonAction={() => { this.handleDialogClose('deleteDialogOpened') }}
                buttonText='Nee'
                left={true}
              />
              )}
              buttonRight={(
              <DialogButton
                buttonAction={this.handleDeleteDialogSubmit}
                buttonText='Ja'
              />
              )}
            />
          }
        />

        <KpnDialog
          dialogState={this.state.assignDialogOpened}
          onRequestClose={() => { this.handleDialogClose('deleteDialogOpened') }}
          modal={true}
          dialogHeader={(
            <DialogHeader
              headerIcon="remove_circle_outline"
              headerText="Aan wie wil je de offertes van deze gebruiker overdragen?"
            />
          )}

          dialogBody={
            <DialogBody>
              <div className="col s12" style={{ marginBottom: '10%' }}>
                <label htmlFor={"assignUser"} style={{ fontSize: '14px' }}>Selecteer een gebruiker</label>
                <RegularSelect
                  name="user"
                  id={"assignUser"}
                  onChange={(event) => {
                    this.onChange(event)
                  }}
                  value={this.state.assignUser || ''}
                  multiple={false}
                >
                  <option value={''} name="username">
                    {'Selecteer een gebruiker'}
                  </option>
                  {this.renderUserListOptions()}
                </RegularSelect>
              </div>
            </DialogBody>
          }
          dialogFooter={
            <DialogFooter
              buttonLeft={(
                <DialogButton
                  buttonAction={() => { this.handleDialogClose('assignDialogOpened') }}
                  buttonText='Nee'
                  left={true}
                />
              )}
              buttonRight={(
                <DialogButton
                  buttonAction={this.handleAssignDialogSubmit}
                  buttonText='Ja'
                />
              )}
            />
          }
        />
      </div>
    );
  }
}

const mapStateToProps = ({ admin, authentication }) => {
  return {
    users: admin.users,
    categories: admin.categories,
    allowedRoles: authentication.roles,
    authUser: authentication,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminActions: bindActionCreators(adminActions, dispatch),
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersPanel));
