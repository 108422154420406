import React, {Component, Fragment} from 'react';
import {Button, Col, Icon, Input, Row} from 'react-materialize'
import {bindActionCreators} from "redux";
import telephonyActions from "../../../actions/workflow/telephonyActions";
import connect from "react-redux/es/connect/connect";
import stepsActions from "../../../actions/stepsActions";
import {DelayInput} from "react-delay-input";
import BulkUpload from "../info/BulkUpload";
import quotationActions from "../../../actions/quotationActions";
import FileDownload from "js-file-download";
import KpnDialog from "../../../containers/common/KpnDialog/KpnDialog";
import DialogHeader from "../../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";
import DialogFooter from "../../../containers/common/KpnDialog/DialogFooter";
import DialogButton from "../../../containers/common/KpnDialog/DialogButton";
import DefaultDialogBody from "../../../containers/common/KpnDialog/DefaultDialogBody";
import DeleteData from "../info/DeleteData";
import TelephonyNumber from "./TelephonyNumber";
import {sleep} from "../../../utils/utils";

class TelephonyContractLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'numbers': props.telephonyOptions.numbers,
      'formErrors': {},
      bulkUploadDialogOpened: false,
      successBulkImport: false,
      importedRows: 0,
      uploadErrors: {},
      deleteDataDialogOpened: false,
    };
  };

  componentDidMount() {
    this.canProceedToNextStep();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.telephonyOptions.numbers) !== JSON.stringify(this.props.telephonyOptions.numbers)) {
      this.setState({numbers: this.props.telephonyOptions.numbers});
      this.canProceedToNextStep();
    }
  }

  canProceedToNextStep = () => {
    sleep(500).then(() => {
      this.validateAllPhoneNumbers();
      let stepValid = Object.keys(this.state.formErrors).length === 0;
      this.props.stepsActions.setAvailableToGoForward(stepValid);
    });
  };

  validateAllPhoneNumbers = () => {
    this.setState({
      formErrors: {}
    });
    (this.state.numbers || []).forEach((number, index) => {
      if (number.count > 0 && parseInt(number.existing) !== 0) {
        this.validateField('startingNumber', index, number.startingNumber, number.prefix ? 7 : 10);
      }
      if (number.error) {
        this.addNumberFieldError(index, 'startingNumber');
      }
    });
  };

  addNumberFieldError = (index, field) => {
    let {formErrors} = this.state;

    if (!formErrors[index]) {
      formErrors[index] = [];
    }

    formErrors[index].push(field);

    this.setState({
      formErrors: formErrors
    });
  };

  validateField = (fieldName, index, value, length) => {
    let isValid = true;

    if (fieldName !== 'startingNumber') {
      return isValid;
    }

    if (!(value.length === length && value.match(/^\d+$/))) {
      this.addNumberFieldError(index, fieldName);
    } else {
      this.removeNumberFieldError(index, fieldName);
    }

    return isValid;
  };

  removeNumberFieldError = (index, field) => {
    let {formErrors} = this.state;
    if (formErrors) {
      if (formErrors[index]) {
        //remove error only from fieldName(send as parameter)
        if (field) {
          const elemIndex = formErrors[index].indexOf(field);
          if (elemIndex !== -1) {
            formErrors[index].splice(elemIndex, 1);
          }

          if (!formErrors[index].length) {
            delete formErrors[index];
          }
        } else {
          delete formErrors[index];
        }
      }

      this.setState({
        formErrors: formErrors
      });
    }
  };

  addNumber = () => {
    if (this.props.quotationStatus || this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }
    const newNumber = {
      numberType: 39,
      blocks: 10,
      existing: 0,
      telephonyOptions: this.props.telephonyOptions.id,
      count: 1,
      startingNumber: '',
      prefix: '',
    };

    this.props.telephonyActions.updateTelephonyOptions(this.props.quotationId, {
      ...this.props.telephonyOptions,
      numbers: [
        ...this.props.telephonyOptions.numbers,
        {...newNumber}
      ]
    }).then(() => {
      this.setState({
        numbers: this.props.telephonyOptions.numbers
      });

      this.canProceedToNextStep();
    });
  };

  changeNumber = (number, index) => {
    if (this.props.quotationStatus || this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }
    let changedNumbers = [...this.state.numbers];
    changedNumbers[index] = number;
    this.setState({
      numbers: changedNumbers
    });

    if (number.valid) {
      changedNumbers[index] = {
        existing: number.existing,
        blocks: number.blocks,
        numberType: number.numberType,
        startingNumber: number.startingNumber,
        telephonyOptions: this.props.telephonyOptions.id,
        count: 1,
        prefix: number.prefix,
      };

      this.props.telephonyActions.updateTelephonyOptions(this.props.quotationId, {
        ...this.props.telephonyOptions,
        numbers: changedNumbers
      }, !number.valid)
        .then(() => {
          this.setState({
            numbers: this.props.telephonyOptions.numbers
          });
          this.canProceedToNextStep();
        });
    } else {
      this.canProceedToNextStep();
    }
  };

  removeNumber = (index) => {
    if (this.props.quotationStatus || this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }
    const newNumbers = [...this.props.telephonyOptions.numbers];
    newNumbers.splice(index, 1);
    this.props.telephonyActions.updateTelephonyOptions(this.props.quotationId, {
      ...this.props.telephonyOptions,
      numbers: newNumbers
    }).then(() => {
      this.setState({
        numbers: this.props.telephonyOptions.numbers
      });

      this.canProceedToNextStep();
    });
  };

  _handleDialogOpen = () => {
    if (this.props.quotationStatus || this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }
    this.setState({bulkUploadDialogOpened: true});
  };

  _handleDialogClose = () => {
    this.setState({bulkUploadDialogOpened: false});
  };

  closeUploadErrorsDialog = () => {
    this.setState({uploadErrors: {}});
  };

  getExampleFile = () => {
    this.props.quotationActions.getVoiceBulkExampleFile(this.props.quotationId).then((success) => {
      if(success === true) {
        this.downloadExampleFile()
      }
    });
  };

  downloadExampleFile = () => {
    FileDownload(this.props.quotation.fixedBulkExampleFile, 'Bulk-upload-ratio-vaste-telefoonnummers.xlsx');
  };

  save = () => {
    if (this.props.quotationStatus || this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }

    this.props.quotationActions.bulkImport(this.props.quotationId, this.props.locations.selectedLocation.id,
      this.state.file, 'fixed', false).then((response) => {
      if(response && response.importedRows) {
        this.setState({
          successBulkImport:true,
          importedRows: response.importedRows
        })
      }

      if(response && response.errors) {
        this.setState({uploadErrors: response.errors});
      }

      this._handleDialogClose();
    });
  };

  _onDrop = (files) => {
    let file = files[0];

    if (file) {
      this.setState({file: file});
    }
  };

  _handleDeleteDataDialogOpen = () => {
    this.setState({deleteDataDialogOpened: true});
  };

  _handleDeleteDataDialogClose = () => {
    this.setState({deleteDataDialogOpened: false});
  };

  deleteAllData = () => {
    if (this.props.quotationStatus || this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }
    this.props.quotationActions.deleteAllMobileOptions(this.props.quotationId, -1, 'voip');

    this._handleDeleteDataDialogClose();
  };

  closeSuccessDialog = () => {
    this.setState({
      successBulkImport: false,
      importedRows: 0
    });
  };

  render() {
    const {telephonyOptions, onChangeCallback, quotation} = this.props;
    const {currentQuotation} = quotation;
    const {inputsDisabled} = currentQuotation;

    let reportingValue = telephonyOptions.reportingLight > 0 ? 'reportingLight' : (telephonyOptions.reportingPremium > 0 ? 'reportingPremium' : '');
    let numbersMatrix = {
      39: {
        id: 39,
        type: 39,
        name: 'Doorkiesreeks',
        prefix: null,
        existing: [1, 0],
        newBlocks: [10, 100, 1000],
        existingBlocks: [10, 100, 1000],
      },
      40: {
        id: 40,
        type: 40,
        name: '1 Individuel nummer',
        prefix: null,
        existing: [1, 0],
        newBlocks: [1],
        existingBlocks: [1],
      }
    };

    return (
      <div>
        <div id="Forms" style={{paddingBottom: "10px"}}>
          <Row>
            <Col s={12}>
              <div>
                <h1 className={'ratio-section-title'}>
                    <span className="ratio-section-title">
                      <i className="small material-icons left ratio-section-title">settings_phone</i>
                      Telefoonnummers:
                    </span> gewenste opties
                </h1>
              </div>
            </Col>
          </Row>
          <Row>
            {
              (this.state.numbers || []).map((element, index) => {
                return <TelephonyNumber
                  number={element}
                  key={index}
                  index={index}
                  removeNumber={this.removeNumber}
                  changeNumber={this.changeNumber}
                  numbersMatrix={numbersMatrix}
                  validateField={this.validateField}
                  formErrors={this.state.formErrors}
                  inputsDisabled={inputsDisabled}
                  provider={currentQuotation.provider}
                />
              })
            }
          </Row>
          <Row>
            <Button
              type="submit"
              waves="light"
              className="doceri-btn-right"
              style={{marginLeft: 20}}
              onClick={() => this._handleDeleteDataDialogOpen()}
              disabled={inputsDisabled}
            >
              Delete
              <Icon right>
                delete
              </Icon>
            </Button>

            <DeleteData
              dialogOpened={this.state.deleteDataDialogOpened}
              onRequestClose={this._handleDeleteDataDialogClose}
              deleteData={this.deleteAllData}
              type="Vaste telefonie"
            />

            <button className="btn doceri-btn-right ratio-btn-right" style={{marginLeft: 20}} onClick={this.addNumber}>
              Nummers toevoegen
            </button>

            <Fragment>
              <Button
                type="submit"
                waves="light"
                className="doceri-btn-right"
                style={{marginLeft: 20}}
                onClick={() => this._handleDialogOpen()}
                disabled={inputsDisabled}
              >
                Bulk Upload
                <Icon right>
                  save
                </Icon>
              </Button>

              <Button
                type="submit"
                waves="light"
                className="doceri-btn-right"
                style={{marginLeft: 20}}
                onClick={() => this.getExampleFile()}
              >
                Download bulk template
                <Icon right>
                  save
                </Icon>
              </Button>

              <BulkUpload
                dialogOpened={this.state.bulkUploadDialogOpened}
                onRequestClose={this._handleDialogClose}
                onDrop={this._onDrop}
                droppedFile={this.state.file}
                save={this.save}
              />
            </Fragment>
          </Row>
        </div>

        <div id="Forms" style={{paddingBottom: "10px"}}>
          <Row>
            <Col s={12}>
              <div>
                <h1 className={'ratio-section-title'}>
                    <span className="ratio-section-title">
                      <i className="small material-icons left ratio-section-title">settings_phone</i>
                      Per bedrijf:
                    </span> gewenste opties
                </h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Input
              s={6}
              type="select"
              label="Wallboard"
              name="wallboard"
              onChange={onChangeCallback}
              multiple={false}
              value={Number(telephonyOptions.wallboard).toString()}
            >
              <option key={-1} value={-1}>
                Niets geselecteerd
              </option>
              <option key={1} value={0}>Nee</option>
              <option key={2} value={1}>Ja</option>
            </Input>
            <Input
              s={6}
              type="select"
              label="Reporting"
              name="reporting"
              onChange={onChangeCallback}
              multiple={false}
              value={reportingValue}
            >
              <option key={-1} value={''}>
                Niets geselecteerd
              </option>
              <option key={1} value={'reportingLight'}>Reporting Light</option>
              <option key={2} value={'reportingPremium'}>Reporting Premium</option>
            </Input>
          </Row>
        </div>
      </div>
    );
  };
}

const mapStateToProps = ({quotation, authentication, locations}) => {
  return {
    quotation,
    quotationId: quotation.currentQuotation.id,
    telephoneNumbers: quotation.currentQuotation.telephone_numbers,
    authentication: authentication,
    locations: locations
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    quotationActions: bindActionCreators(quotationActions, dispatch),
    telephonyActions: bindActionCreators(telephonyActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TelephonyContractLevel);
