import actionTypes from "../../constants/actionTypes";

export const getQuotationOptions = (locationId) => ({
    type: actionTypes.getQuotationOptions.init,
    payload: {
        locationId,
    },
});
export const getQuotationOptionsSuccess = (response) => ({
    type: actionTypes.getQuotationOptions.success,
    payload: {
        response,
    },
});

export const getQuotationOptionsError = (error) => ({
    type: actionTypes.getLocationOffers.error,
    payload: {
        error,
    },
    error: true,
});
