import React from 'react';

const Connection = (props) => {
  const {name, id} = props.connectionProperties;
  const {onEdit, onDelete} = props;

  let {inputsDisabled, isTMobile} = props;

  return (
    <div className="col s12 overviewkopbgalleen">
      <div className="overviewkop">
                <span className="themeprimarycolor">
                    <i className="small material-icons left themeprimarycolor">playlist_add_check</i>
                    Aansluitpunt{isTMobile ? '' : ':'}&nbsp;
                </span>
        {isTMobile ? '' : name}

        <a href="#" onClick={() => onEdit(props.connectionProperties)}
           style={inputsDisabled ? {pointerEvents: 'none'} : {cursor: 'pointer'}}>
          <i
            className={"small material-icons right " + (inputsDisabled ? "grey-text" : "themeprimarycolor")}>mode_edit</i>
        </a>

        {name !== 'Primair' &&
        <a href="#" onClick={() => onDelete(id)}
           style={inputsDisabled ? {pointerEvents: 'none'} : {cursor: 'pointer'}}>
          <i className={"small material-icons right " + (inputsDisabled ? "grey-text" : "themeprimarycolor")}>close</i>
        </a>
        }
      </div>
    </div>
  );
};

export default Connection;
