import React from 'react';
import PropTypes from 'prop-types';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn,} from 'material-ui/Table';
import {Input} from "react-materialize";

import ProviderHelper from "../../../helper/providerHelper";
import RegularSelect from "../../../components/RegularSelect";
import {DelayInput} from "react-delay-input";
import RatioOverflowTableWrapper from "../../../components/styled-components/ratio-overflow-table-wrapper/RatioOverflowTableWrapper";

const HeadersNames = ['Producttype', 'Provider', 'Vergoeding One Time (%)', 'Vergoeding Recurring (%)'];

class CompensationGrid extends React.Component {
  changeCompensation = (compensation, {target}) => {
    let {name, value} = target;

    if (!isNaN(value) && value >= 0 && value <= 100) {
      let compensationData = {...compensation};
      compensationData[name] = value;
      this.props.updateCompensation(compensationData);
    }
  };

  generateProviderList = () => {
    let providers = this.props.providers.filter((element) => element.provider !== 1);
    let providerOptions = [];

    providerOptions.push(<option value="-1" key={-1}>Kies provider</option>);
    providers.forEach((element) => {
      providerOptions.push(<option value={element.provider} key={element.provider}>{ProviderHelper.getQuotationProviderName(element.provider)}</option>)
    });

    return providerOptions;
  };

  generateRow = (row) => {
    if (!row.display) {
      return '';
    }

    return (
      <TableRow key={row.username}>
        <TableRowColumn>
          <Input
            name="moduleName"
            type='text'
            value={row.name}
            readOnly={true}>
          </Input>
        </TableRowColumn>
        <TableRowColumn>
          <div className="col input-field">
            <RegularSelect
              className="provider-select"
              name="provider"
              multiple={false}
              required={true}
              value={row.provider}
              onChange={(e) => this.changeCompensation(row, e)}
            >
              {this.generateProviderList()}
            </RegularSelect>
          </div>
        </TableRowColumn>
        <TableRowColumn>
          <div className="input-field inner-addon" style={{width: '50%'}}>
            <DelayInput
              value={row.compensation_one_time}
              type="number"
              name="compensation_one_time"
              min={0}
              max={100}
              delayTimeout={1000}
              className="without-margin-bottom"
              onChange={(e) => this.changeCompensation(row, e)}
            />
          </div>
        </TableRowColumn>
        <TableRowColumn>
          <div className="input-field inner-addon" style={{width: '50%'}}>
            <DelayInput
              value={row.compensation_recurring}
              type="number"
              name="compensation_recurring"
              min={0}
              max={100}
              delayTimeout={1000}
              className="without-margin-bottom"
              onChange={(e) => this.changeCompensation(row, e)}
            />
          </div>
        </TableRowColumn>
      </TableRow>
    );
  };

  generateHeader = () => {
    return (
      HeadersNames.map(headerName =>
        <TableHeaderColumn key={headerName}>{headerName}</TableHeaderColumn>
      )
    );
  };

  render() {
    return (
        <RatioOverflowTableWrapper>
            <div className="valign-wrapper">
                <Table
                    selectable={false}
                >
                    <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                        <TableRow>
                            {this.generateHeader()}
                        </TableRow>
                    </TableHeader>
                    <TableBody displayRowCheckbox={false}>
                        {this.props.rows.map(row => this.generateRow(row))}
                    </TableBody>
                </Table>
            </div>
        </RatioOverflowTableWrapper>
    );
  }
}

CompensationGrid.propTypes = {
  rows: PropTypes.array.isRequired,
  updateCompensation: PropTypes.func.isRequired
};

export default CompensationGrid;