import {apiRoutes} from '../../constants/apiRoutes';
import {actionTypes} from "../../constants/actionTypes";
import quotationActions from "../quotationActions";
import ApiService from "../../services/apiService";
import RequestType from "../../constants/requestType";
import providerType, {odidoProviders} from "../../constants/providerType";
import {toastr} from "react-redux-toastr";
import _ from "lodash";

const updateMobileOptions = (quotationId, name, value) => {
  return async () => {
    const url = apiRoutes.mobileRelated.patch.replace(/{quotationId}/, quotationId);

    await ApiService.performRequest(RequestType.PATCH, url, {[name]: value});
  }
};

const updateMobileOptionsState = (name, value) => {
  return {
    type: actionTypes.updateMobileOptions,
    payload: {
      name,
      value
    }
  }
};

const addNewProductSelection = (selection) => {
  return {
    type: actionTypes.addNewMobileProductSelection,
    payload: selection
  }
};

const _getMobileProductsOfferYearsSuccess = (mobileYears) => {
  return {
    type: actionTypes.updateMobileOfferYears,
    payload: mobileYears
  }
};

const mobileProductsOfferYears = (quotationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.mobileRelated.getOfferYears.replace(/{quotationId}/, quotationId);

      let requestData = await ApiService.performRequest(RequestType.GET, url);

      dispatch(_getMobileProductsOfferYearsSuccess(requestData));
    }
    catch (error) {
      toastr.error('RATIO Backend error', error.message);
    }
  }
};

const _getMobileOfferProductsSuccess = (mobileProducts) => {
  return {
    type: actionTypes.getMobileOfferProducts,
    payload: mobileProducts
  }
};

const updateProfilesLoader = (value) => {
  return {
    type: actionTypes.updateProfilesLoader,
    payload: value
  };
};

const mobileOfferProducts = (year, quotationId, locationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.mobileRelated.getProductsByOfferYear
        .replace(/{quotationId}/, quotationId)
        .replace(/{year}/, year)
        .replace(/{locationId}/, locationId);

      const requestData = await ApiService.performRequest(RequestType.GET, url);
      dispatch(_getMobileOfferProductsSuccess(requestData));
      if (requestData.hasOwnProperty('telephoneNumbers')) {
        dispatch(quotationActions.getTelephoneNumbersSuccess(requestData.telephoneNumbers));
      }
    } catch (error) {
      toastr.error('RATIO Backend error', error.message);
    } finally {
      dispatch(updateProfilesLoader(false));
    }
  }
};

const updateMobileProductsSuccess = (value) => {
  return {
    type: actionTypes.updateMobileOfferProducts,
    payload: value
  }
};

const updateMobileProductOptionsSuccess = (mobileProductOptions) => {
  return {
    type: actionTypes.updateMobileOfferProductOptions,
    payload: mobileProductOptions
  }
};

const updateMobileProducts = (quotation, locationId, products, options = {}) => {
  return async (dispatch) => {
    const url = apiRoutes.mobileRelated.patchOfferProducts
      .replace(/{quotationId}/, quotation.id)
      .replace(/{locationId}/, locationId);

    try {
      const requestData = await ApiService.performRequest(RequestType.PATCH, url, {
        "products": products,
        "options": options
      });
      dispatch(updateMobileProductOptionsSuccess(requestData));
      dispatch(mobileOfferProducts(quotation.mobileOfferYear, quotation.id, locationId));

      if (requestData.hasOwnProperty('telephoneNumbers')) {
        dispatch(quotationActions.getTelephoneNumbersSuccess(requestData.telephoneNumbers));
      }

      if (quotation.provider === providerType.VODAFONE) {
        dispatch(quotationActions.getQuotation(quotation.id));
      }

      if (odidoProviders.includes(quotation.provider)) {
        dispatch(quotationActions.getPresetDiscountCheck(quotation.id));
      }

      if (requestData.warning) {
        toastr.warning('Warning', requestData.warning);
      }
    } catch (error) {
      dispatch(updateProfilesLoader(false));
      toastr.error('RATIO Backend error', error.message);
    }
  }
};

const updateQuotationMobileStep = (quotation) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.mobileRelated.putQuotation.replace(/{quotationId}/, quotation.id);

      const response = await ApiService.performRequest(RequestType.PUT, url, [quotation]);

      dispatch(getQuotationSuccess(response));
    }
    catch (error) {
      const message = _.has(error, 'response.data.error.exception') ? error.response.data.error.exception[0].message : error.message;
      toastr.error('Offerte kan niet worden gewijzigd', message);
    }
  }
};

const getQuotationSuccess = (quotation) => {
  return {
    type: actionTypes.getQuotationSuccess,
    payload: quotation
  }
};

const disableMobileSelections = (quotationId) => {
  return async () => {
    try {
      const url = apiRoutes.mobileRelated.disableMobileSelections.replace(/{quotationId}/, quotationId);
      await ApiService.performRequest(RequestType.DELETE, url);

    } catch (message) {
      toastr.error('Offerte kan niet worden gewijzigd ', message);
    }
  }
};

const retrieveExistingMobileData = (quotationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.mobileRelated.retrieveExistingMobileData.replace(/{quotationId}/, quotationId);
      const response = await ApiService.performRequest(RequestType.GET, url);

      if (response) {
        dispatch(retrieveExistingMobileDataSuccess(response));
      }
    } catch (message) {
      toastr.error('Kan bestaande gegevens niet ophalen omdat ', message);
    }
  }
};

const resetExistingMobileData = (quotationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.mobileRelated.resetExistingMobileData.replace(/{quotationId}/, quotationId);
      await ApiService.performRequest(RequestType.GET, url);

      dispatch(retrieveExistingMobileDataSuccess([]));
    } catch (message) {
      toastr.error('Kan bestaande gegevens niet ophalen omdat ', message);
    }
  }
};

const retrieveExistingMobileDataSuccess = (result) => {
  return {
    type: actionTypes.getExistingMobileData,
    payload: result
  }
};

const retrieveUpgradingMobileData = (quotationId, orderId, phoneNumber) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.mobileRelated.retrieveExistingMobileData.replace(/{quotationId}/, quotationId)
        .replace(/{orderId}/, orderId).replace(/{phoneNumber}/, phoneNumber);
      const response = await ApiService.performRequest(RequestType.GET, url);

      if (response) {
        dispatch(retrieveUpgradingMobileDataSuccess(response));
      }
    } catch (message) {
      toastr.error('Kan bestaande gegevens niet ophalen omdat ', message);
    }
  }
};

const retrieveUpgradingMobileDataSuccess = (result) => {
  return {
    type: actionTypes.getExistingMobileData,
    payload: result
  }
};

const deleteTemporaryData = (quotationId) => {
  return async () => {
    try {
      const url = apiRoutes.mobileRelated.deleteTemporaryData.replace(/{quotationId}/, quotationId);
      await ApiService.performRequest(RequestType.DELETE, url);

    } catch (message) {
      toastr.error('Kan tijdelijke gegevens niet verwijderen ', message);
    }
  }
};

const getMobileBulkExampleFileForQuotation = (quotationId, locationId, existing) => {
  return async () => {
    try {
      let url = apiRoutes.mobileRelated.getMobileBulkXlsExample
          .replace('{quotationId}', quotationId)
          .replace(/{locationId}/, locationId)
          .replace(/{existing}/, existing);

      await ApiService.performRequest(RequestType.POST, url);

      return true;
    }
    catch (e) {
      toastr.error('Kan het voorbeeldbestand niet downloaden', e.message);
      throw e;
    }
  }
};

const getMobileEmptyBulkExampleFileForQuotation = (quotationId, locationId, existing) => {
  return async () => {
    try {
      let url = apiRoutes.mobileRelated.getMobileEmptyBulkXlsExample
          .replace('{quotationId}', quotationId)
          .replace(/{locationId}/, locationId)
          .replace(/{existing}/, existing);

      await ApiService.performRequest(RequestType.POST, url);

      return true;
    }
    catch (e) {
      toastr.error('Kan het voorbeeldbestand niet downloaden', e.message);
      throw e;
    }
  }
};

const updateMobileAddons = (quotation, locationId, hasActiveAddons) => {
  return async (dispatch) => {
    const url = apiRoutes.mobileRelated.patchMobileAddons
      .replace(/{quotationId}/, quotation.id)
      .replace(/{locationId}/, locationId);

    try {
      const requestData = await ApiService.performRequest(RequestType.PATCH, url, {
        "hasActiveAddons": hasActiveAddons
      });

      dispatch(updateMobileProductOptionsSuccess(requestData));
      dispatch(mobileOfferProducts(quotation.mobileOfferYear, quotation.id, locationId));

      if (requestData.hasOwnProperty('telephoneNumbers')) {
        dispatch(quotationActions.getTelephoneNumbersSuccess(requestData.telephoneNumbers));
      }

      dispatch(updateHasActiveAddons(hasActiveAddons));

      if (requestData.warning) {
        toastr.warning('Warning', requestData.warning);
      }
    } catch (error) {
      toastr.error('RATIO Backend error', error.message);
    }
  }
};

const updateEndDateOptions = (quotation, locationId, hasActiveEndDateOptions) => {
  return async (dispatch) => {
    const url = apiRoutes.mobileRelated.updateEndDateOptions
      .replace(/{quotationId}/, quotation.id)
      .replace(/{locationId}/, locationId);

    try {
      const requestData = await ApiService.performRequest(RequestType.PATCH, url, {
        "hasActiveEndDateOptions": hasActiveEndDateOptions
      });

      dispatch(updateMobileProductOptionsSuccess(requestData));
      dispatch(mobileOfferProducts(quotation.mobileOfferYear, quotation.id, locationId));

      dispatch(updateHasActiveEndDateOptions(hasActiveEndDateOptions));

      if (requestData.warning) {
        toastr.warning('Warning', requestData.warning);
      }
    } catch (error) {
      toastr.error('RATIO Backend error', error.message);
    }
  }
};

const getExceptionEndDateProducts = () => {
  return async (dispatch) => {
    const url = apiRoutes.mobileRelated.getExceptionEndDateProducts;

    try {
      const requestData = await ApiService.performRequest(RequestType.GET, url);

      if (requestData.hasOwnProperty('products')) {
        dispatch(getExceptionEndDateProductsSuccess(requestData.products));
      }
    } catch (error) {
      toastr.error('RATIO Backend error', error.message);
    }
  }
}

const updateHasActiveAddons = (newValue) => {
  return {
    type: actionTypes.updateHasActiveAddons,
    payload: newValue,
  };
};

const updateHasActiveEndDateOptions = (newValue) => {
  return {
    type: actionTypes.updateHasActiveEndDateOptions,
    payload: newValue,
  };
};

const getExceptionEndDateProductsSuccess = (newValue) => {
  return {
    type: actionTypes.getExceptionEndDateProducts,
    payload: newValue,
  };
};

const updateVAMOForTelephoneNumbers = (data, quotationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.AKBRelated.updateVAMOForTelephoneNumbers.replace(/{quotationId}/, quotationId);
      const response = await ApiService.performRequest(RequestType.PATCH, url, data);

      dispatch(saveAllAKBTelephoneNumbersSuccess(response.telephoneNumbers));
    } catch (error) {
      toastr.error('Kan telefoonnummergegevens niet opslaan', error);
      return {error};
    }
  }
};

const saveAllAKBTelephoneNumbersSuccess = (response) => {
  return {
    type: actionTypes.saveAKBTelephoneNumberSuccess,
    payload: response
  }
};

const updateDataSimAmountForTelephoneNumbers = (data, quotationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.AKBRelated.updateDataSimAmountForTelephoneNumbers.replace(/{quotationId}/, quotationId);
      const response = await ApiService.performRequest(RequestType.PATCH, url, data);

      dispatch(saveAllAKBTelephoneNumbersSuccess(response.telephoneNumbers));
    } catch (error) {
      toastr.error('Kan telefoonnummergegevens niet opslaan', error);
      return {error};
    }
  }
};

const getUpgradeOptions = (quotationID, data) => {
  return async () => {
    try {
      const url = apiRoutes.mobileRelated.getUpgradeMobileData.replace('{quotationId}', quotationID);
      await ApiService.performRequest(RequestType.POST, url, data);
    } catch (error) {
      const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;
      toastr.error('Something went wrong', message);
      throw error;
    }
  }
};

const updateDataRetrieval = (value) => {
  return {
    type: actionTypes.updateMobileDataRetrieval,
    payload: value
  };
};

const hasPendingUpgradeData = (quotationID) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.mobileRelated.hasPendingUpgradeData.replace('{quotationId}', quotationID);
      let response = await ApiService.performRequest(RequestType.GET, url);
      let {pendingUpgradeData} = response;

      dispatch(updateDataRetrieval(pendingUpgradeData));
    } catch (error) {
      const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;
      toastr.error('Something went wrong', message);
      throw error;
    }
  }
};

const getQuotationOperators = (quotationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.mobileRelated.getQuotationOperators.replace('{quotationId}', quotationId);
      let response = await ApiService.performRequest(RequestType.GET, url);
      let {operators} = response;

      dispatch(getQuotationOperatorsSuccess(operators));
    } catch (error) {
      const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;
      toastr.error('Something went wrong', message);
      throw error;
    }
  }
}

const getQuotationOperatorsSuccess = (operators) => {
  return {
    type: actionTypes.getQuotationOperators,
    payload: operators
  };
};

const updateQuotationOperators = (quotationId, data) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.mobileRelated.updateQuotationOperators.replace('{quotationId}', quotationId);
      let response = await ApiService.performRequest(RequestType.PATCH, url, data);
      let {success} = response;

      if (success) {
        dispatch(updateQuotationOperatorsSuccess(data));
      }
    } catch (error) {
      const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;
      toastr.error('Something went wrong', message);
      throw error;
    }
  }
}

const updateQuotationOperatorsSuccess = (data) => {
  return {
    type: actionTypes.updateQuotationOperators,
    payload: data
  };
};

const getAvailableTelephoneNumbers = (quotationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.mobileRelated.getAvailableTelephoneNumbers.replace('{quotationId}', quotationId);
      let response = await ApiService.performRequest(RequestType.GET, url);
      let {availableTelephoneNumbers} = response;

      dispatch(getAvailableTelephoneNumbersSuccess(availableTelephoneNumbers));
    } catch (error) {
      const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;
      toastr.error('Something went wrong', message);
      throw error;
    }
  }
}

const getAvailableTelephoneNumbersSuccess = (availableTelephoneNumbers) => {
  return {
    type: actionTypes.getAvailableTelephoneNumbers,
    payload: availableTelephoneNumbers
  };
};

export default {
  updateMobileOptions,
  updateMobileOptionsState,
  mobileProductsOfferYears,
  mobileOfferProducts,
  updateMobileProductsSuccess,
  updateMobileProductOptionsSuccess,
  updateMobileProducts,
  addNewProductSelection,
  updateQuotationMobileStep,
  disableMobileSelections,
  retrieveExistingMobileData,
  resetExistingMobileData,
  retrieveUpgradingMobileData,
  deleteTemporaryData,
  getMobileBulkExampleFileForQuotation,
  updateMobileAddons,
  updateEndDateOptions,
  getExceptionEndDateProducts,
  updateProfilesLoader,
  updateVAMOForTelephoneNumbers,
  updateDataSimAmountForTelephoneNumbers,
  getUpgradeOptions,
  updateDataRetrieval,
  hasPendingUpgradeData,
  getQuotationOperators,
  updateQuotationOperators,
  getAvailableTelephoneNumbers,
  getMobileEmptyBulkExampleFileForQuotation
}