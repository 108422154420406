import {actionTypes} from '../constants/actionTypes';
import {apiRoutes} from '../constants/apiRoutes';
import {toastr} from 'react-redux-toastr'

import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

const getOrderWizardDetails = (quotationID) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.orderWizardRelated.getOrderWizardDetails.replace(/{quotationID}/, quotationID);
      let requestData = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getOrderWizardDetailsSuccess(requestData));
    } catch ({message}) {
      toastr.error('Het is niet mogelijk om de bestelgegevens op te halen', message);
    }
  }
};

const getOrderWizardDetailsSuccess = (wizardDetails) => {
  return {
    type: actionTypes.getOrderWizardDetails,
    payload: wizardDetails
  };
};

const updateOrderWizardDetails = (data) => {
  return async (dispatch) => {
    try {

      let url = apiRoutes.orderWizardRelated.updateOrderWizardDetails.replace(/{orderDetailsID}/, data.id);
      let response = await ApiService.performRequest(RequestType.POST, url, data);

      if (response && response.success) {
        dispatch(updateOrderWizardDetailsSuccess(data));
      }
    } catch ({message}) {
      toastr.error('Het is niet mogelijk om de bestelgegevens bij te werken', message);
    }
  }
};

const updateOrderWizardDetailsSuccess = (data) => {
  return {
    type: actionTypes.updateOrderWizardDetails,
    payload: data
  };
};

const getOrderWizardDetailOptions = () => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.orderWizardRelated.getOrderWizardDetailOptions;
      let requestData = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getOrderWizardDetailOptionsSuccess(requestData));
    } catch ({message}) {
      toastr.error('Het is niet mogelijk om de besteldetailopties op te halen', message);
    }
  }
};

const getOrderWizardDetailOptionsSuccess = (wizardOptions) => {
  return {
    type: actionTypes.getOrderWizardDetailOptions,
    payload: wizardOptions
  };
};

const getOrderWizardAddressList = (customerOrderDetails) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.orderWizardRelated.getOrderAddressList.replace(/{customerOrderDetails}/, customerOrderDetails);
      let requestData = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getOrderWizardAddressListSuccess(requestData));
    } catch ({message}) {
      toastr.error('Het is niet mogelijk om de besteldetailopties op te halen', message);
    }
  }
};

const getOrderWizardAddressListSuccess = (addressList) => {
  return {
    type: actionTypes.getOrderAddressList,
    payload: addressList
  };
};

const addOrderWizardAddress = (customerOrderDetails, newAddress) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.orderWizardRelated.addOrderAddress.replace(/{customerOrderDetails}/, customerOrderDetails);
      let response = await ApiService.performRequest(RequestType.POST, url, newAddress);

      if (response && response.success) {
        newAddress['id'] = response.id;
        dispatch(addOrderWizardAddressSuccess(newAddress));
      }
    } catch ({message}) {
      toastr.error('Het is niet mogelijk om de besteldetailopties op te halen', message);
    }
  }
};

const addOrderWizardAddressSuccess = (address) => {
  return {
    type: actionTypes.addOrderAddress,
    payload: address
  };
};

const updateOrderWizardAddress = (addressID, addressData) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.orderWizardRelated.updateOrderAddress.replace(/{orderAddress}/, addressID);
      let response = await ApiService.performRequest(RequestType.PATCH, url, addressData);

      if (response && response.success) {
        dispatch(updateOrderWizardAddressSuccess(addressData));
      }
    } catch ({message}) {
      toastr.error('Het is niet mogelijk om de besteldetailopties op te halen', message);
    }
  }
};

const updateOrderWizardAddressSuccess = (address) => {
  return {
    type: actionTypes.updateOrderAddress,
    payload: address
  };
};

const removeOrderWizardAddress = (addressID) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.orderWizardRelated.removeOrderAddress.replace(/{orderAddress}/, addressID);
      let response = await ApiService.performRequest(RequestType.DELETE, url);

      if (response && response.success) {
        dispatch(removeOrderWizardAddressSuccess(addressID));
      }
    } catch ({message}) {
      toastr.error('Het is niet mogelijk om de besteldetailopties op te halen', message);
    }
  }
};

const removeOrderWizardAddressSuccess = (addressID) => {
  return {
    type: actionTypes.removeOrderAddress,
    payload: addressID
  };
};

export default {
  getOrderWizardDetails,
  updateOrderWizardDetails,
  getOrderWizardDetailOptions,
  getOrderWizardAddressList,
  addOrderWizardAddress,
  updateOrderWizardAddress,
  removeOrderWizardAddress
}
