export const hasRequiredRole = (currentRoles, requiredRoles) => {
  let validRoles = false;

  currentRoles.forEach((currentRole) => {
    if (requiredRoles.indexOf(currentRole) > -1) {
      validRoles = true;
    };
  });

  return validRoles;
};

export const hasAllRequiredRoles = (currentRoles, requiredRoles) => {
  let validRoles = true;

  requiredRoles.forEach((requiredRole) => {
    if (currentRoles.indexOf(requiredRole) === -1) {
      validRoles = false;
    };
  });

  return validRoles;
};

export default { hasRequiredRole, hasAllRequiredRoles };
