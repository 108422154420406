const telephoneNumberActions = {
    PORTING_ACTION: 1,
    RETAINING_ACTION: 2,
    CANCELING_ACTION: 3,
    SIMLESS_PORTING_ACTION: 4,
    NEW_ACTION: 5,
    UPGRADE_ACTION: 6,
    VERLENGEN_ACTION: 7,
    OPHEFFEN_ACTION: 8,
    ADDITION_ACTION: 9,
    RETENTIE_ACTION: 10,
    REODER_ACTION: 11,
    REODER_PORTING_ACTION: 12
};

export default telephoneNumberActions;

const additionTypes = {
    ADDITION_VAMO: 1,
    ADDITION_DATA_SIM: 2,
    ADDITION_BOTH: 3
};

export const porting = telephoneNumberActions.PORTING_ACTION;
export const retaining = telephoneNumberActions.RETAINING_ACTION;
export const canceling = telephoneNumberActions.CANCELING_ACTION;
export const simlessPorting = telephoneNumberActions.SIMLESS_PORTING_ACTION;
export const nieuw = telephoneNumberActions.NEW_ACTION;
export const upgrade = telephoneNumberActions.UPGRADE_ACTION;
export const verlengen = telephoneNumberActions.VERLENGEN_ACTION;
export const opheffen = telephoneNumberActions.OPHEFFEN_ACTION;
export const addition = telephoneNumberActions.ADDITION_ACTION;
export const retention = [telephoneNumberActions.RETENTIE_ACTION];
export const reoder = telephoneNumberActions.REODER_ACTION
export const reoderPorting = telephoneNumberActions.REODER_PORTING_ACTION;
export const additionVamo = [additionTypes.ADDITION_VAMO, additionTypes.ADDITION_BOTH];
export const additionDataSim = [additionTypes.ADDITION_DATA_SIM, additionTypes.ADDITION_BOTH];