import actionTypes from "../../constants/actionTypes";

export const updateConnectionOptions = (locationId) => ({
    type: actionTypes.updateConnectionOptions.init,
    payload: {
        locationId,
    },
});
export const updateConnectionOptionsSuccess = (data) => ({
    type: actionTypes.updateConnectionOptions.success,
    payload: {
        data,
    },
});

export const updateConnectionOptionsError = (error) => ({
    type: actionTypes.getLocationOffers.error,
    payload: {
        error,
    },
    error: true,
});
