import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import getQuotationOptionsThunk from '../../../actions/workflow/isdnMigrationActions/getQuotationOptionsThunk';
import updateQuotationOptionsThunk from '../../../actions/workflow/isdnMigrationActions/updateQuotationOptionsThunk';
import quotationActions from "../../../actions/quotationActions";
import partnerServicesActions from "../../../actions/partnerServicesActions";
import hardwareActions from "../../../actions/hardwareActions";

import HardwareDependencyHelper from "../../../helper/hardwareDependencyHelper";

import PartnerServiceOptionWrapper from '../../common/PartnerServiceOptionWrapper';
import AddOneTimeProduct from "../../common/AddOneTimeProduct";
import LoaderComponent from '../../../containers/common/LoaderComponent';
import QuotationQuestionModule from "../../common/QuotationQuestionsTable/QuotationQuestionModule";

import quotationType from "../../../constants/quotationType";

class PartnerServicesStep extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    const { currentQuotation } = this.props.quotation;
    if (currentQuotation.type === quotationType.TYPE_QA) {
      this.setState({ loading: false });
      return;
    }

    this.props.hardwareActions.getAllCustomHardwareOptionsSelections(currentQuotation.id);
    this.props.partnerServicesActions.getPartnerServicesOptions(currentQuotation.id);
    this.props.partnerServicesActions.getPartnerServicesOptionsForQuotation(currentQuotation.id, 'partner');
    this.props.partnerServicesActions.getPartnerServicesOptionsForQuotation(currentQuotation.id, 'it');

    this.props.partnerServicesActions.getServiceOptionCategories();
    this.props.partnerServicesActions.getProductsToLink(currentQuotation.provider, currentQuotation.id).then(() => {
      this.setState({
        loading: false
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { partnerServices, quotation } = this.props;
    const { partnerServicesSelections } = partnerServices;

    if (prevProps.partnerServices.partnerServicesSelections !== partnerServicesSelections) {
      this.props.hardwareActions.getAllCustomHardwareOptionsSelections(quotation.currentQuotation.id);
    }
  }

  render() {
    const { customHardwareSelections, partnerServices } = this.props;
    const { partnerServicesOptions } = partnerServices;
    let categories = [...new Set(partnerServicesOptions.map(option => option.category))];

    // Sort alphabetically
    categories = categories.sort();

    // Move overige dienst category to last
    if(categories.indexOf('Overige dienst') > -1) {
      categories = categories.filter(category => category !== 'Overige dienst');
      categories.push('Overige dienst')
    }

    const { currentQuotation } = this.props.quotation;
    const isQAQuotation = currentQuotation.type === quotationType.TYPE_QA;
    const { theme, roles, oneTimePartnerService } = this.props.authentication;
    const canAddPartnerItProduct = roles.some(item => oneTimePartnerService.includes(item));

    let alreadySelected = HardwareDependencyHelper.getAlreadySelectedHardware(customHardwareSelections, '', null).filter(selectionId => selectionId);

    return (
      <div>
        {this.state.loading ?
          <LoaderComponent theme={theme} /> :
          ( isQAQuotation ? <QuotationQuestionModule module={'partner'} location={-1} /> :
            <>
            {categories.map(category => {
              const optionsForCategory = partnerServicesOptions.filter(option => option.category === category && HardwareDependencyHelper.hasRelationDependencyMet(option, alreadySelected));
              const allowDecimalQuantity = optionsForCategory[0].allow_decimal_quantity || false;

              return (
                <div className="row" id="Forms" style={{padding: 20}}>
                  <PartnerServiceOptionWrapper
                    optionsList={optionsForCategory}
                    category={category}
                    allowDecimalQuantity={allowDecimalQuantity}
                  />
                </div>
              )
            })}

            {canAddPartnerItProduct &&
              <div className="row" id="Forms" style={{padding: 20}}>
                <AddOneTimeProduct type={'partner'}/>
              </div>
            }
          </>
          )
        }
      </div>
    )
  }
}

const mapStateToProps = ({ quotation, quotationOptions, partnerServices, authentication, hardware }) => {
  return {
    quotation,
    quotationOptions,
    partnerServices,
    authentication,
    customHardwareSelections: hardware.customHardwareSelections || [],
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getQuotationOptions: bindActionCreators(getQuotationOptionsThunk, dispatch),
    updateQuotationOptions: bindActionCreators(updateQuotationOptionsThunk, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
    partnerServicesActions: bindActionCreators(partnerServicesActions, dispatch),
    hardwareActions: bindActionCreators(hardwareActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerServicesStep);
