const statuses = {
  STATUS_OPEN: { value: 0, label: 'Open' },
  STATUS_WON: { value: 1, label: 'Gewonnen' },
  STATUS_LOST: { value: 2, label: 'Verloren' },
  STATUS_ORDERED: { value: 3, label: 'Georderd' },
  STATUS_PARTLY_ORDERED: { value: 4, label: 'Deels georderd' },
  STATUS_PARTLY_ORDERED_ALERT: { value: 5, label: 'Deels georderd - ALERT' },
  STATUS_SEND: { value: 6, label: 'Verzonden' },
};

const orderingStatuses = [
  'STATUS_ORDERED',
  'STATUS_PARTLY_ORDERED',
  'STATUS_PARTLY_ORDERED_ALERT'
];

export default statuses;

export const statusData = Object.entries(statuses)
  .map(entry => entry[1]);

export const statusDataWithoutOrdering = Object.entries(statuses)
  .filter(entry => !orderingStatuses.includes(entry[0]))
  .map(entry => entry[1]);