/* global _ */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toastr} from 'react-redux-toastr'
import {Preloader} from "react-materialize";

import FileDownload from 'js-file-download';

import locationActions from '../../../actions/locationActions';
import connectionsActions from '../../../actions/connectionsActions';
import quotationActions from "../../../actions/quotationActions";
import leadActions from "../../../actions/leadActions";

import LocationForQuotation from './locationForQuotation';
import AddLocationForQuotation from './addLocationForQuotation';
import IsraAddressesDialog from './israAddressesDialog';
import BulkUpload from "../../../steps/workflow/info/BulkUpload";

import GeneralDialog from "../../common/GeneralDialog";
import DefaultDialogBody from "../../../containers/common/KpnDialog/DefaultDialogBody";
import Loader from "../../../containers/common/loader";

import providerType from "../../../constants/providerType";

class LocationsForQuotation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      bulkUploadDialogOpened: false,
      deleteDialogOpened: false,
      locationDialogOpened: false,
      importFailureDialogOpened: false,
      importErrorMessage: '',
      nameError: false,
      postCodeError: false,
      uploadProcessing: false,
      file: null,
      successBulkImport: false,
      importedRows: 0,
      uploadErrors: {},
    };

    this.getLocationsForAutocomplete = _.throttle(this.getLocationsForAutocomplete.bind(this), 1000)
  }

  componentDidMount() {
    if (this.props.locations.allLocations.length > 0 && !this.props.quotation.isLead) {
      let isMultipleIsraCheck = false;
      this.props.locations.allLocations.map(item => {
        if (item.id !== -1 && !item.isra_check_complete && !isMultipleIsraCheck && !(this.props.quotation.currentQuotation.inputsDisabled)) {
          this.doPrequal(item);
          isMultipleIsraCheck = true
        }
      })
    }

    if (!this.props.quotation.currentQuotation.initialized && !this.props.quotation.isLead) {
      this.props.quotationActions.initializeQuotation(this.props.quotation.currentQuotation.id);
    }
  }

  _handleDeleteDialogOpen = (location) => {
    this.props.locationActions.setSelectedLocation(location);
    this.setState({deleteDialogOpened: true});
  };

  _handleDeleteDialogClose = () => {
    this.setState({deleteDialogOpened: false});
  };

  /**
   * Open the location dialog. This is used when creating a new location.
   *
   * @private
   */
  _handleLocationDialogOpen = () => {
    // Creating a new location should also reinitialize the current selected location with its initial state.
    this.props.locationActions.resetSelectedLocation();

    this.setState({locationDialogOpened: true});
  };

  _handleLocationDialogClose = () => {
    this.setState({
      locationDialogOpened: false,
      nameError: false,
      postCodeError: false
    });
  };

  _handleIsraDialogClose = () => {
    this.props.locationActions.closeIsraDialog();
  };

  _handleImportFailureDialogClose = () => {
    this.setState({importFailureDialogOpened: false});
  };

  _retryBulkUpload = () => {
    this.setState({
      importFailureDialogOpened: false,
      locationDialogOpened: true
    })
  };

  _onDrop = (files) => {
    let file = files[0];

    if (!file)
      return;

    let quotationId = this.props.quotation.currentQuotation.id;
    const response = this.props.locationActions.uploadBulk(file, quotationId);
    response.then((val) => {
      if (val && val.error) {
        this.setState({
          importErrorMessage: val.error.response.data,
          importFailureDialogOpened: true
        })
      }
    });

    this._handleLocationDialogClose();
  };

  _getDialogAddresses() {
    const {selectedLocation} = this.props.locations;
    return selectedLocation.hasOwnProperty('israDialogData') ? selectedLocation.israDialogData.addresses : [];
  }

  _getDialogAddressesType() {
    const {selectedLocation} = this.props.locations;
    return selectedLocation.hasOwnProperty('israDialogData') ? selectedLocation.israDialogData.type : '';
  }

  handleLocationSearchChange = (locationAddress) => {
    this.props.locationActions.setSelectedLocationAddress(locationAddress);

    this.getLocationsForAutocomplete(locationAddress);
  };

  getLocationsForAutocomplete = (locationAddress) => {
    this.props.locationActions.getLocationAddressForAutocomplete(locationAddress);
  };

  openEditionDialog = (location) => {
    this.props.locationActions.setSelectedLocation(location);
    this.props.locationActions.openEditionDialog();
  };

  saveIsra = (israAddress, addressType) => {
    this.props.locationActions.closeEditionDialog();
    switch (addressType) {
      case 'extensions':
        this.saveSelectedExtension(israAddress);
        break;
      default:
        this.doIsraCheck(israAddress);
        break;
    }

    this._handleIsraDialogClose();
  };

  saveSelectedExtension = (address) => {
    let {selectedLocation} = this.props.locations;

    if (address === 'complexAddress') {
      selectedLocation.isra_name = address;
      this.props.locationActions.updateExistingLocationInDb(selectedLocation);
      return;
    }

    let arrayOfStrings = address.split('/');
    selectedLocation.isra_postal_code = arrayOfStrings[0];
    selectedLocation.isra_house_number = arrayOfStrings[1];
    selectedLocation.isra_house_number_extension = arrayOfStrings[2];

    // update location address and call again ISRA Check
    if (selectedLocation.quotation) {
      this.props.locationActions.updateExistingLocationInDb(selectedLocation).then(() => this.doIsraCheck());
    }
  };

  save = (locationOptionalName) => {
    let {selectedLocation} = this.props.locations;
    let {isLead} = this.props.quotation;
    let quotationId = this.props.quotation.currentQuotation.id;

    this.props.locationActions.setLocationAddressToEmptyString();

    if (!locationOptionalName || !selectedLocation.postal_code || !selectedLocation.house_number) {
      this.setState({
        nameError: !locationOptionalName,
        postCodeError: !selectedLocation.postal_code && !selectedLocation.house_number
      });
      return;
    }
    if (locationOptionalName) {
      selectedLocation.name = locationOptionalName;
    }

    if (selectedLocation.id) {
      this.props.locationActions.updateExistingLocationInDb(selectedLocation, isLead);
    } else {
      if (isLead) {
        this.props.locationActions.addNewLocationInDb(selectedLocation, quotationId, isLead)
          .then(() => this.props.leadActions.getLead(quotationId));
      } else {
        this.props.locationActions.addNewLocationInDb(selectedLocation, quotationId, isLead)
          .then(() => this.doIsraCheck())
          .then(() => this.props.quotationActions.getQuotation(quotationId));
      }
    }

    this._handleLocationDialogClose();
  };

  deleteLocation = () => {
    this._handleDeleteDialogClose();
    let {selectedLocation} = this.props.locations;
    let {isLead} = this.props.quotation;

    if (selectedLocation.quotation) {
      this.props.locationActions.deleteLocation(selectedLocation.id, isLead);
    } else {
      toastr.error('Locatie kan niet worden verwijderd', 'Je kan deze locatie niet verwijderen');
    }
  };

  selectLocation = (locationName, location, locationOptionalName) => {
    if (locationOptionalName)
      location.name = locationOptionalName;

    this.props.locationActions.setSelectedLocation(location);
  };

  deleteConnection = (connectionId) => {
    let quotationId = this.props.quotation.currentQuotation.id;
    this.props.connectionsActions.deleteConnection(connectionId, quotationId);
  };

  createConnection = (connectionName, locationId) => {
    let quotationId = this.props.quotation.currentQuotation.id;
    this.props.connectionsActions.createConnection(connectionName, locationId, quotationId);
  };

  updateConnection = (connection, locationId) => {
    let quotationId = this.props.quotation.currentQuotation.id;
    this.props.connectionsActions.updateConnection(connection, locationId, quotationId);
  };

  doIsraCheck = (israAddress = null) => {
    let {accessPortfolio, provider} = this.props.quotation.currentQuotation;
    let noIsraCondition = [providerType.FLASH, providerType.YIELDER].includes(provider);
    if ((provider === providerType.ODIDO && !['irma', 'tele2'].includes(accessPortfolio)) || noIsraCondition ) {
      return;
    }

    let {selectedLocation} = this.props.locations;

    if (israAddress === 'complexAddress') {
      selectedLocation.isra_name = israAddress;
      this.props.locationActions.updateExistingLocationInDb(selectedLocation);
      return;
    }

    this.props.locationActions.locationIsraCheck(selectedLocation, israAddress);
  };

  // By editing a location first, we reset the isra check. Then we can run it again.
  doPrequal = (location) => {
    this.props.locationActions.setSelectedLocation(location);
    this.props.locationActions.updateSelectedLocation(location.id, location.quotation).then(() => this.doIsraCheck());
  };

  getExampleFile = (isIsdnMigration = false) => {
    this.props.locationActions.getExampleFile(isIsdnMigration).then(() => this.downloadExampleFile());
  }

  downloadExampleFile = () => {
    FileDownload(this.props.locations.exampleFile, 'kpn-bulkupload.xlsx');
  }

  getLocationExampleFile = () => {
    let quotationId = this.props.quotation.currentQuotation.id;

    this.props.locationActions.getLocationExampleFile(quotationId)
      .then(() => FileDownload(this.props.locations.exampleFile, quotationId + '-Bulk-upload-ratio-locatie.xlsx'));
  }

  handleUploadDialogOpen = () => {
    let quotation = this.props.quotation.currentQuotation;
    if (quotation.status) {
      return;
    }

    this.setState({bulkUploadDialogOpened: true});
  };

  handleUploadDialogClose = () => {
    this.setState({
      bulkUploadDialogOpened: false,
      file: null
    });
  };

  closeSuccessDialog = () => {
    this.setState({
      successBulkImport: false,
      importedRows: 0
    });
  };

  closeUploadErrorsDialog = () => {
    this.setState({
      uploadErrors: {}
    });
  };

  onDrop = (files) => {
    let file = files[0];

    if (file) {
      this.setState({file: file});
    }
  };

  onUpload = () => {
    let quotation = this.props.quotation.currentQuotation;
    if (quotation.status) {
      return;
    }

    this.importBulkTemplateLocation();

    this.handleUploadDialogClose();
  };

  importBulkTemplateLocation = () => {
    this.setState({uploadProcessing: true});

    let quotation = this.props.quotation.currentQuotation;
    let isLead = this.props.quotation.isLead;
    const selectedLocationId = this.props.locations.selectedLocation.id;

    this.props.quotationActions.bulkImport(quotation.id, selectedLocationId, this.state.file, 'location', false).then((response) => {
      if (response) {
        if (response.errors) {
          this.setState({
            uploadErrors: response.errors,
            uploadProcessing: false
          });
        }

        if (response.importedRows) {
          this.setState({
            successBulkImport: true,
            importedRows: response.importedRows,
            uploadProcessing: false
          });

          this.props.locationActions.setQuotationLocations(quotation.id, isLead);
        }
      } else {
        this.props.locationActions.setQuotationLocations(quotation.id, isLead);
        this.setState({uploadProcessing: false});
      }
    });
  };

  renderSuccessMessage = (rowsImported) => {
    return (
      <GeneralDialog
        dialogState={this.state.successBulkImport}
        headerIcon="folder_open"
        headerText="Gelukt!"
        closeHandler={this.closeSuccessDialog}
        dialogBodyContent={(
          <div className="input-field col s12" style={{margin: '10px 0 20px'}}>
            Uw bulk-upload is succesvol toegevoegd. Er zijn {rowsImported} locaties.
          </div>
        )}
        rightButtonText={'Ik heb dit begrepen'}
        rightButtonAction={this.closeSuccessDialog}
        />
    );
  };

  renderErrorMessage = () => {
    return (
      <GeneralDialog
        dialogState={!!(Object.keys(this.state.uploadErrors).length)}
        headerText="Onjuiste of missende gegevens"
        closeHandler={this.closeUploadErrorsDialog}
        onRequestClose={this.closeUploadErrorsDialog}
        dialogBodyContent={
          <DefaultDialogBody text="De volgende punten moeten aangepast worden in uw bestand. Daarna kunt u het bestand nogmaals uploaden."/>
        }
        />
    );
  };

  render() {
    let {allLocations} = this.props.locations;
    let {inputsDisabled, provider} = this.props.quotation.currentQuotation;
    let {isLead} = this.props.quotation;

    return (
      <fieldset
        disabled={inputsDisabled}
        style={{border: 'none', padding: 'unset', margin: 'unset'}}
      >
        {this.props.locations.israLoading && <Loader message="Beschikbaarheid wordt gecontroleerd"/>}
        <IsraAddressesDialog
          dialogOpened={this.props.locations.israDialogOpened}
          onRequestClose={this._handleIsraDialogClose}
          addresses={this._getDialogAddresses()}
          addressesType={this._getDialogAddressesType()}
          saveIsra={this.saveIsra}
        />

        <AddLocationForQuotation
          dialogOpened={this.state.locationDialogOpened}
          onRequestClose={this._handleLocationDialogClose}
          matchingAddresses={this.props.locations.matchingAddresses}
          isRefusedPostcode={this.props.locations.refusedPostcode}
          handleLocationSearchChange={this.handleLocationSearchChange}
          selectLocation={this.selectLocation}
          locationAddress={this.props.locations.locationAddress}
          nameError={this.state.nameError}
          postCodeError={this.state.postCodeError}
          save={this.save}
          getExampleFile={this.getExampleFile}
          onDrop={this._onDrop}
          inputsDisabled={inputsDisabled}
        />

        <BulkUpload
          dialogOpened={this.state.bulkUploadDialogOpened}
          onRequestClose={this.handleUploadDialogClose}
          onDrop={this.onDrop}
          droppedFile={this.state.file}
          save={this.onUpload}
          mobile={false}
        />

        {this.state.successBulkImport &&
        this.renderSuccessMessage(this.state.importedRows)
        }

        {this.renderErrorMessage(this.state.importedRows)}

        {this.state.uploadProcessing && (
          <div className="loader-component" style={{zIndex: 99}}>
            <div className="overlay" style={{backgroundColor: `rgba(255, 255, 255, 0.3)`}}/>
            <Preloader size='medium'/>
          </div>
        )}

        <div id="Forms" style={{paddingBottom: "15px", marginBottom: "30px"}}>
          <div className="row">
            <div className="col s12">
              <div className="sendendform" style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}>
                <button
                  onClick={this._handleLocationDialogOpen}
                  className="btn doceri-btn-right ratio-btn-white-right" type="submit"
                  name="action"
                  disabled={inputsDisabled}>
                  <i className="small material-icons left">add_location</i>
                  Locatie toevoegen
                </button>

                <div>
                  <button
                    onClick={() => this.handleUploadDialogOpen()}
                    className="btn doceri-btn-right ratio-btn-white-right" type="submit"
                    name="action"
                    disabled={inputsDisabled}
                  >
                    <i className="small material-icons left">upload</i>
                    Bulk upload
                  </button>

                  <button
                    onClick={() => this.getLocationExampleFile()}
                    className="btn doceri-btn-right ratio-btn-white-right" type="submit"
                    name="action"
                    disabled={inputsDisabled}
                    style={{margin: '0 10px'}}
                  >
                    <i className="small material-icons left">download</i>
                    Bulk template
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="linesplit">
          <div className="line"/>
        </div>

        {
          allLocations.filter(x => x.id !== -1).map(location =>
            <LocationForQuotation
              key={location.id}
              locationId={location.id}
              onLocationDelete={() => this._handleDeleteDialogOpen(location)}
              onLocationEdit={() => this.openEditionDialog(location)}
              onConnectionDelete={this.deleteConnection}
              onConnectionCreate={this.createConnection}
              onConnectionUpdate={this.updateConnection}
              doPrequal={this.doPrequal}
              config={location}
              inputsDisabled={inputsDisabled}
              provider={provider}
              isLead={isLead}
            />
          )
        }

        <GeneralDialog
          dialogState={this.state.deleteDialogOpened}
          onRequestClose={this._handleDeleteDialogClose}
          headerText='Locatie'
          headerSubtext='verwijderen?'
          headerIcon='add_location'
          closeHandler={this._handleDeleteDialogClose}
          dialogBodyContent={(<DefaultDialogBody text=''/>)}
          leftButtonLeft={true}
          leftButtonText={'Nee'}
          leftButtonAction={this._handleDeleteDialogClose}
          rightButtonText={'Ja'}
          rightButtonAction={this.deleteLocation}
          />

        <GeneralDialog
          dialogState={this.state.importFailureDialogOpened}
          onRequestClose={this._handleImportFailureDialogClose}
          headerText='Upload mislukt'
          headerIcon='location_on'
          closeHandler={this._handleImportFailureDialogClose}
          dialogBodyContent={(<DefaultDialogBody text={this.state.importErrorMessage}/>)}
          leftButtonLeft={true}
          leftButtonText={'Melding sluiten'}
          leftButtonAction={this._handleImportFailureDialogClose}
          rightButtonText={'Opnieuw proberen'}
          rightButtonAction={this._retryBulkUpload}
        />

        <AddLocationForQuotation
          dialogOpened={this.state.locationDialogOpened}
          onRequestClose={this._handleLocationDialogClose}
          matchingAddresses={this.props.locations.matchingAddresses}
          isRefusedPostcode={this.props.locations.refusedPostcode}
          handleLocationSearchChange={this.handleLocationSearchChange}
          selectLocation={this.selectLocation}
          locationAddress={this.props.locations.locationAddress}
          nameError={this.state.nameError}
          postCodeError={this.state.postCodeError}
          save={this.save}
          getExampleFile={this.getExampleFile}
          onDrop={this._onDrop}
          inputsDisabled={inputsDisabled}
        />

        <div className="linesplit">
          <div className="line"/>
        </div>

        <div id="Forms" style={{paddingBottom: "15px"}}>
          <div className="row">
            <div className="col s12">
              <div className="sendendform">
                <button
                  onClick={this._handleLocationDialogOpen}
                  className="btn doceri-btn-right ratio-btn-white-right left"
                  type="submit"
                  name="action"
                  disabled={inputsDisabled}
                >
                  <i className="small material-icons left">add_location</i>
                  Locatie toevoegen
                </button>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}

const mapStateToProps = ({locations, quotation}) => {
  return {
    locations,
    quotation
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    locationActions: bindActionCreators(locationActions, dispatch),
    leadActions: bindActionCreators(leadActions, dispatch),
    connectionsActions: bindActionCreators(connectionsActions, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsForQuotation);
