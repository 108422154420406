import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {toastr} from "react-redux-toastr";

import {Input, Col, Row} from "react-materialize";
import {DelayInput} from "react-delay-input";

import hostedVoiceActions from '../../../actions/workflow/hostedVoiceActions';
import stepsActions from '../../../actions/stepsActions';
import locationActions from "../../../actions/locationActions";
import businessConnectActions from "../../../actions/workflow/businessConnectActions";
import hardwareActions from "../../../actions/hardwareActions";
import mobileActions from "../../../actions/workflow/mobileActions";
import partnerServicesActions from "../../../actions/partnerServicesActions";
import quotationActions from "../../../actions/quotationActions";

import ExistingVoiceData from '../../common/ExistingVoiceData';
import HostedVoiceToggle from './HostedVoiceToggle';
import HostedVoiceOptionsYielder from './HostedVoiceOptionsYielder';
import HostedVoiceOptions from './HostedVoiceOptions';
import BusinessConnectionOptions from "./BusinessConnectionOptions";
import QuotationQuestionModule from "../../common/QuotationQuestionsTable/QuotationQuestionModule";
import CustomHardwareWithCategories from "../../common/CustomHardwareWithCategories";

import DefaultDialogBody from "../../../containers/common/KpnDialog/DefaultDialogBody";
import GeneralDialog from "../../../containers/common/GeneralDialog";

import QuotationType from '../../../constants/quotationType';
import HostedVoiceHelper from '../../../helper/hostedVoiceHelper'
import LoaderComponent from '../../../containers/common/LoaderComponent';

import providerType, {yielderProviders} from "../../../constants/providerType";
import quotationType from "../../../constants/quotationType";
import productType from "../../../constants/productType";

class HostedVoiceStep extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isNewSipTrunk: true,
      hostedVoiceDialogState: false,
      callReportingDialogState: false,
      webexLicenceDialogState: false,
      hostedVoiceCallTrafficDialogState: false,
      hostedVoiceUserToolDialogState: false,
      hostedVoiceCallCenterDialogState: false,
      publicSwitchesDialogState: false,
      publicSwitchesCombinedDialogState: false,
      usersDialogState: false,
      isdnWarningDialogState: false,
      summaryUsersBlurred: false,
      p2gBlurred: false,
      unlimitedBlurred: false,
      hostedVoiceOptions: props.hostedVoiceOptions,
      voipPerLocation: this.props.authentication.providers[0] ? this.props.authentication.providers[0].organization.voip_per_location : false,
      existingVoiceData: [],
      upgradingData: [],
      dataRetrievalInProcess: false,
      existingTelephoneExchange: null,
      hostedVoiceBusinessVoiceDialogState: false,
      microsoftBusinessVoice: 0,
      isQuantityTouched: false,
      dialogToTrigger: null,
      crmCaraSoloRequiredErrorMessage: ''
    }
  }

  componentWillMount() {
    this.props.locationActions.resetSelectedLocationToGlobal();

    const locationId = this.props.locations.selectedLocation.id > 0 ? this.props.locations.selectedLocation.id : null;
    const quotationId = this.props.quotation.id;
    const isVerlengenType = this.props.quotation.type === QuotationType.TYPE_VERLENGEN;

    if (this.props.quotation.type === QuotationType.TYPE_QA) {
      this.setState({ loading: false });
      return;
    }

    this.props.hardwareActions.getAllCustomHardwareOptionsSelections(this.props.quotationId);

    this.props.hardwareActions.getCustomHardwareOptions('voice', this.props.quotationId);
    this.props.hardwareActions.getCustomHardwareOptionsSelections(this.props.quotationId, 'voice', locationId);

    this.props.partnerServicesActions.getPartnerServicesOptionsForQuotation(this.props.quotationId, 'it')
      .then(() => {
        const {itServicesSelections} = this.props.partnerServices;
        const microsoftBusinnessVoiceSelection = itServicesSelections.find(sel => sel.category === 'KPN');
        const microsoftBusinessVoiceQuantity = microsoftBusinnessVoiceSelection ? microsoftBusinnessVoiceSelection.quantity : 0;

        this.setState({microsoftBusinessVoice: microsoftBusinessVoiceQuantity});
      });
    this.props.mobileActions.mobileOfferProducts(this.props.quotation.mobileOfferYear, this.props.quotationId).then(() => {
      if (isVerlengenType) {
        this.props.hardwareActions.retrieveExistingVoiceData(quotationId).then(() => {
          this.setState({
            loading: false
          })
        }).then(() => {
          this.props.hardwareActions.retrieveExistingTelephoneExchange(quotationId).then((response) => {
            if (response) {
              this.setState({existingTelephoneExchange: response})
            }
          })
        });
      } else {
        this.setState({
          loading: false
        })
      }
    });
  }

  componentDidMount() {
    if (this.props.quotation.type === QuotationType.TYPE_QA) {
      return;
    }
    const hostedVoiceOptions = this.props.hostedVoiceOptions;

    this.props.stepsActions.setAvailableToGoForward(this.usersSumValid());
    if (this.props.loading.existingVoiceDataRetrieval) {
      this.setState({ dataRetrievalInProcess: true })
    }
    this.props.hostedVoiceActions.getVamoVoiceProductsCount(this.props.quotationId);

    this.setState({crmCaraSoloRequiredErrorMessage: hostedVoiceOptions.crmOption3 >= 1 ? 'Eén Integratie Cara Solo Instalatie is vereist.' : '' })

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.locations.selectedLocation.id !== this.props.locations.selectedLocation.id) {
          this.props.hardwareActions.getAllCustomHardwareOptionsSelections(this.props.quotationId);

          const locationId = this.props.locations.selectedLocation.id > 0 ? this.props.locations.selectedLocation.id : null;
          this.props.hardwareActions.getCustomHardwareOptionsSelections(this.props.quotationId, 'voice', locationId);
          this.props.quotationActions.getQuotation(this.props.quotation.id);
          const location = this.props.locations.selectedLocation.id > 0 ? this.props.locations.selectedLocation.id : null;
          this.setState({
              // isNewSipTrunk: this.props.hostedVoiceOptions.hostedVoiceActive,
              hostedVoiceOptions: this.props.quotation.hostedVoiceOptionsAllLocations.find((each) => {return each.location === location})
          });
      }
      if (prevProps.hostedVoiceOptions !== this.props.hostedVoiceOptions) {
          const location = this.props.locations.selectedLocation.id > 0 ? this.props.locations.selectedLocation.id : null;
          const newHostedVoiceOptions = this.props.quotation.hostedVoiceOptionsAllLocations.find((each) => {return each.location === location});
          this.setState({
              hostedVoiceOptions: newHostedVoiceOptions
          });
      }
      if (prevProps.existingVoiceData !== this.props.existingVoiceData) {
          this.setState({
            existingVoiceData: this.props.existingVoiceData
          });

          const orderIds = this.props.existingVoiceData.map(el => el.order_id);
          this.props.quotationActions.getUpgradingData(this.props.quotationId, orderIds);
      }

      if (prevProps.quotation.upgradingData !== this.props.quotation.upgradingData) {
          this.setState({
            upgradingData: this.props.quotation.upgradingData
          });
      }

    if (prevProps.loading.existingVoiceDataRetrieval !== this.props.loading.existingVoiceDataRetrieval) {
      this.setState({ dataRetrievalInProcess: this.props.loading.existingVoiceDataRetrieval })
      if (! this.props.loading.existingVoiceDataRetrieval) {
        this.props.hardwareActions.retrieveExistingVoiceData(this.props.quotationId)
      }
    }
  }

  usersSumValid = () => {
    const hostedVoiceOptions = this.state.hostedVoiceOptions || {};

    if (parseInt(hostedVoiceOptions.callChargeType, 10) === 3) {
      return parseInt(hostedVoiceOptions.p2gUsersCount, 10) + parseInt(hostedVoiceOptions.unlimitedUsersCount, 10) === parseInt(hostedVoiceOptions.usersPerPublicSwitch, 10);
    }

    return true;
  };

  updateHostedVoiceOptions = (e) => {
    if (this.props.quotation.status) {
      return;
    }

    const {target} = e;
    let {name, value, type, min, max} = target;
    let existingData = this.state.existingVoiceData;

    let {hostedVoiceOptions, microsoftBusinessVoice} = this.state;
    const { quotation } = this.props;
    const isStandardQuotation = [QuotationType.TYPE_STANDARD, QuotationType.TYPE_QA, QuotationType.TYPE_VERGELIJKING, QuotationType.TYPE_HUUR, QuotationType.TYPE_KOOP].includes(quotation.type);

    if (target.type === 'number' && value !== '' && !value.match(/^\d+$/)) {
      toastr.error("Can't specify negative values - to be translated");
      return;
    }

    if (type === 'number' && max) {
      min = isNaN(min) ? 0 : min;
      value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    }

    if (['callCenterSoftware', 'callCenterAgentLicense'].includes(name)) {
      value = JSON.parse(value);
    }

    if (['voicePerLocation'].includes(name)) {
        this.props.quotationActions.getQuotation(this.props.quotation.id)
    }

    if (['publicSwitches', 'callChargeType'].includes(name) && !this.validatePublicSwitches(name, value) && isStandardQuotation) {
      this.setCorrectPublicSwitches(name, value);

      if (name === 'publicSwitches') {
        return;
      }
    }

    let vodafoneReceptionistCondition = this.props.quotation.provider === providerType.VODAFONE && name === 'receptionistRoleCount';
    if (!vodafoneReceptionistCondition && (HostedVoiceHelper.addonsFieldsNames.includes(name) || name === 'usersPerPublicSwitch')) {
      const {currentMobileProductOptions} = this.props;
      const {hostedVoiceOptions} = this.state;

      if (!HostedVoiceHelper.validateHostedVoiceOptions(quotation, hostedVoiceOptions, currentMobileProductOptions, name, value, existingData, microsoftBusinessVoice)) {
        if (HostedVoiceHelper.webexLicenceFieldNames.includes(name)) {
          this.updateOptionsState(name, value, 'webexLicenceDialogState');
        } else {
          this.updateOptionsState(name, value, 'hostedVoiceDialogState');
        }

        return;
      }
    }

    let existingUsers = existingData.filter((el) => el.product_id === '101A00001N' && el.terminate === null).length;
    let usersAmount = hostedVoiceOptions.usersPerPublicSwitch + existingUsers + this.state.microsoftBusinessVoice;

    if (HostedVoiceHelper.callReportingFieldNames.includes(name)) {
      let localSum = HostedVoiceHelper.callReportingFieldNames.map((el) => el === name ? parseInt(value) : parseInt(hostedVoiceOptions[el]));
      let selectionCheck = localSum.reduce((a, b) => a + b, 0);
      let existingCallReportingDataCount = existingData.filter((el) => {
        return HostedVoiceHelper.callReportingProductNumbers.includes(el.product_id) && el.terminate === null
      }).length;

      if ((selectionCheck + existingCallReportingDataCount) > usersAmount &&
          ![quotationType.TYPE_EXTENSION, quotationType.TYPE_EXPANSION].includes(quotation.type)
      ) {
        this.updateOptionsState(name, value, 'callReportingDialogState');
        return;
      }
    }

    if (isStandardQuotation && (
      (name === 'endPointLicenses' && value > usersAmount) ||
      (name === 'usersPerPublicSwitch' && value < hostedVoiceOptions.endPointLicenses))
    ) {
      this.updateOptionsState(name, value, 'hostedVoiceDialogState');
      return;
    }

    if (name === 'fixedUserOption2' && value > hostedVoiceOptions.fixedUserOption1) {
      this.updateOptionsState(name, value, 'hostedVoiceCallTrafficDialogState');
      return;
    }

    if (name === 'userTool' && hostedVoiceOptions.fixedUserOption5) {
      this.updateOptionsState(name, value, 'hostedVoiceUserToolDialogState');
      return;
    }

    if (['centerOption3', 'centerOption4'].includes(name) && !hostedVoiceOptions.centerOption2) {
      this.updateOptionsState(name, value, 'hostedVoiceCallCenterDialogState');
      return;
    }

    if (name === 'teamsCall' && parseInt(value) >= 1) {
      this.props.partnerServicesActions.getPartnerServicesOptionsForQuotation(this.props.quotationId, 'it')
        .then(() => {
          const {itServicesSelections} = this.props.partnerServices;
          const microsoftBusinnessVoiceSelection = itServicesSelections.find(sel => sel.category === 'KPN');
          const microsoftBusinessVoiceQuantity = microsoftBusinnessVoiceSelection ? microsoftBusinnessVoiceSelection.quantity : 0;

          this.setState({microsoftBusinessVoice: microsoftBusinessVoiceQuantity});
        })
        .then(() => {this.setState({hostedVoiceBusinessVoiceDialogState: true})})
    }

    let data = {...hostedVoiceOptions, [name]: value};

    if (quotation.type === QuotationType.TYPE_VERLENGEN && name === 'hostedVoiceActive' && !existingData.length) {
      data = {...data, publicSwitches: value ? 1 : 0};
    }
    if (name === 'callReportingOption3' && parseInt(value) === 0  && hostedVoiceOptions.callReportingOption4 > 0) {
      data = {...data, callReportingOption4: 0};
    }

    if (name ==='crmOption3' && value === 0) {
      data = {...data, crmCaraSoloOption1: false, crmCaraSoloOption2: false, crmCaraSoloOption3 : false}
    }

    if (name ==='crmOption3' && !hostedVoiceOptions.crmCaraSoloOption1 && !hostedVoiceOptions.crmCaraSoloOption2 && !hostedVoiceOptions.crmCaraSoloOption3) {
      this.setState({crmCaraSoloRequiredErrorMessage: 'Eén Integratie Cara Solo Instalatie is vereist'});
    }

    this.setState({hostedVoiceOptions: data, isQuantityTouched: true});

    if (['hostedVoiceActive', 'businessConnectActive', 'callChargeType', 'receptionistRole', 'receptionistRole', 'voiceChannels'].includes(name)) {
      const locationId = this.props.locations.selectedLocation.id;
      const quotationId = this.props.quotationId;
      const localOnly = value === '';

      this.updateVoiceOptions(localOnly, data, quotationId, locationId);
    }
  };

  onChangeCrmCaraSoloOptions = ({target: { value }}) => {
    let {hostedVoiceOptions} = this.state;

    if (value === 1) {
      return;
    }

    this.setState({hostedVoiceOptions: {
      ...hostedVoiceOptions,
        crmCaraSoloOption1: value === 'crmCaraSoloOption1',
        crmCaraSoloOption2: value === 'crmCaraSoloOption2',
        crmCaraSoloOption3: value === 'crmCaraSoloOption3'
      }});

    this.updateVoiceOptions(false, this.state.hostedVoiceOptions, this.props.quotationId, this.props.locations.selectedLocation.id);
  }

  updateOptionsState = (name, value, dialog) => {
    let {hostedVoiceOptions} = this.state;
    let data = {...hostedVoiceOptions, [name]: value};

    this.setState({
      hostedVoiceOptions: data,
      isQuantityTouched: true,
      dialogToTrigger: dialog,
    });
  };

  onQuantitySaveAction = (e) => {
    if (!this.state.isQuantityTouched) {
      return;
    }

    let {dialogToTrigger,  hostedVoiceOptions} = this.state;

    if (dialogToTrigger) {
      const location = this.props.locations.selectedLocation.id > 0 ? this.props.locations.selectedLocation.id : null;
      const lastStableState = this.props.quotation.hostedVoiceOptionsAllLocations.find((each) => {return each.location === location});

      this.setState({
        hostedVoiceOptions: lastStableState,
        [dialogToTrigger]: true,
        dialogToTrigger: null,
        isQuantityTouched: false
      });

      return;
    }

    const {target} = e;
    let {value} = target;

    const locationId = this.props.locations.selectedLocation.id;
    const quotationId = this.props.quotationId;
    const localOnly = value === '';

    this.updateVoiceOptions(localOnly, hostedVoiceOptions, quotationId, locationId);
  };

  updateVoiceOptions = (localOnly, hostedVoiceOptions, quotationId, locationId) => {
    if (localOnly) {
      this.props.hostedVoiceActions.updateHostedVoiceOptionsState(hostedVoiceOptions);
    } else {
      this.props.hostedVoiceActions.updateHostedVoiceOptions(quotationId, locationId, hostedVoiceOptions)
        .then(() => {
          if (hostedVoiceOptions.hostedVoiceActive) {
            this.props.hardwareActions.getCustomHardwareOptionsSelections(quotationId, 'voice', locationId)
          }
        }).then(() => this.props.hostedVoiceActions.getVamoVoiceProductsCount(this.props.quotationId));
    }

    this.props.stepsActions.setAvailableToGoForward(this.usersSumValid());
    this.setState({isQuantityTouched: false});
  };

  validatePublicSwitches = (name, value) => {
    if (value < 1) {
      return false;
    }

    const {hostedVoiceOptions} = this.state;
    const callChargeType = name === 'callChargeType' ? parseInt(value, 10) : parseInt(hostedVoiceOptions['callChargeType'], 10);
    const publicSwitches = name === 'publicSwitches' ? parseInt(value, 10) : parseInt(hostedVoiceOptions['publicSwitches'], 10);

    //edge case when changing from combined to normal and switches were set to 2
    if (name === 'callChargeType' && value !== 3 && publicSwitches === 2) {
      return false;
    }

    return callChargeType === 3 ? publicSwitches >= 2 : publicSwitches >= 1;
  };

  setCorrectPublicSwitches = (name, value) => {
    let {quotation} = this.props;
    let {hostedVoiceOptions} = this.state;
    if (!hostedVoiceOptions.hostedVoiceActive || ![QuotationType.TYPE_STANDARD, QuotationType.TYPE_QA, QuotationType.TYPE_KOOP, QuotationType.TYPE_HUUR, QuotationType.TYPE_VERGELIJKING].includes(quotation.type)) {
      return;
    }

    const callChargeType = name === 'callChargeType' ? parseInt(value, 10) : parseInt(hostedVoiceOptions['callChargeType'], 10);

    hostedVoiceOptions.publicSwitches = (callChargeType === 3) ? 2 : 1;

    if (callChargeType === 3) {
      this.setState({publicSwitchesCombinedDialogState: true});
    } else {
      this.setState({publicSwitchesDialogState: true});
    }

    this.setState({hostedVoiceOptions});
    this.props.hostedVoiceActions.updateHostedVoiceOptions(this.props.quotationId, this.props.locations.selectedLocation.id, {publicSwitches: hostedVoiceOptions.publicSwitches});
  };

  updateSelectedLocation = (data, sipTrunkId) => {
    if (this.props.quotation.status) {
      return;
    }
    const locationHasEntryConnections = this.props.locations.selectedLocation.has_entry_connections;

    if (data.hasOwnProperty('locationCallChargeType')) {
      this.props.businessConnectActions.updateLocationCallChargeType(this.props.locations.selectedLocation.id, data.locationCallChargeType);
    } else {

      if (locationHasEntryConnections) {
        if (data.hasOwnProperty('isdn2Count')) {
          if (data.isdn2Count === 3 || data.isdn2Count === 4) {
            this.setState({isdnWarningDialogState: true});
          }
        }
        if (data.hasOwnProperty('isdn30Count')) {
          if (data.isdn30Count !== 0) {
            this.setState({isdnWarningDialogState: true});
          }
        }
      }

      this.props.businessConnectActions.updateLocationSipTrunk(
        this.props.locations.selectedLocation.id,
        sipTrunkId,
        data,
        this.props.quotationId,
      );
    }
  };

  handleClickSaveNewSipTrunk = (data) => {
    this.props.businessConnectActions.addLocationSipTrunk(
      this.props.locations.selectedLocation.id,
      data,
      this.props.quotationId,
    );
  };

  handleClickDeleteSipTrunk = (sipTrunkId) => {
    if (sipTrunkId) {
      this.props.businessConnectActions.deleteLocationSipTrunk(
        this.props.locations.selectedLocation.id,
        sipTrunkId,
        this.props.quotationId,
      );
    }

    this.setState({
      isNewSipTrunk: false,
    })
  };

  changeCallCharge = (unlimitedCount, p2gCount) => {
    this.props.businessConnectActions.updateBusinessCallChargeType(unlimitedCount, p2gCount, this.props.locations.selectedLocation.id)
  };

  handleClickDialogAdjustButton = () => {
    this.addUserValues();
    this.closeUsersDialog();
  };

  addUserValues = () => {
    const p2gElem = document.getElementById('p2gUsersCount');
    const unlimitedElem = document.getElementById('unlimitedUsersCount');

    const totalUsersCount = parseInt(p2gElem.value, 10) + parseInt(unlimitedElem.value, 10);
    if (totalUsersCount > -1) {
      this.props.hostedVoiceActions.updateHostedVoiceOptions(this.props.quotationId, this.props.locations.selectedLocation.id,{usersPerPublicSwitch: totalUsersCount});
    } else {
      this.props.hostedVoiceActions.updateHostedVoiceOptionsState({...this.state.hostedVoiceOptions, usersPerPublicSwitch: totalUsersCount});
    }
  };

  closeHostedVoiceDialog = () => {
    this.setState({hostedVoiceDialogState: false});
  };

  closeCallReportingDialog = () => {
    this.setState({callReportingDialogState: false});
  };

  closeWebexLicenceDialog = () => {
    this.setState({webexLicenceDialogState: false});
  };

  closeHostedVoiceCallTrafficDialog = () => {
    this.setState({hostedVoiceCallTrafficDialogState: false});
  };

  closeHostedVoiceUserToolDialog = () => {
    this.setState({hostedVoiceUserToolDialogState: false});
  };

  closeHostedVoiceCallCenterDialog = () => {
    this.setState({hostedVoiceCallCenterDialogState: false});
  };

  closeUsersDialog = () => {
    this.setState({usersDialogState: false});
  };

  closeIsdnDialog = () => {
    this.setState({isdnWarningDialogState: false});
  };

  closeBusinessVoiceDialog = () => {
    this.setState({hostedVoiceBusinessVoiceDialogState: false});
  };

  onChangeMicrosoftBusinessVoice = ({target}) => {
    const {name, value} = target;

    this.setState({
      [name]: value
    });
  };

  saveMicrosoftBusinessVoice = () => {
    this.props.partnerServicesActions.persistPartnerServicesSelections(
      this.state.microsoftBusinessVoice,
      this.props.quotationId,
      productType.TYPE_MICROSOFT_IT_SERVICE
    ).then(() => this.setState({hostedVoiceBusinessVoiceDialogState: false}));
  };

  resetAddons = () => {
    let data = {};
    HostedVoiceHelper.addonsFieldsNames.forEach((e) => { data[e] = 0 });

    this.props.hostedVoiceActions.updateHostedVoiceOptions(this.props.quotationId, this.props.locations.selectedLocation.id, data);
    this.setState({hostedVoiceDialogState: false})
  };

  updateQuotation = (target) => {
    this.props.quotationActions.updateQuotationProperty(target);

    if (this.updateQuotationTimeout) {
      clearTimeout(this.updateQuotationTimeout);
    }
    this.updateQuotationTimeout = setTimeout(() => {
      this.props.quotationActions.updateQuotation(
          this.props.quotation
      );
    }, 250);
  };

  handleClickAddSipTrunk = () => {
    const { selectedLocation } = this.props.locations;
    const canAdd = selectedLocation.sip_trunks.length
      < selectedLocation.connections.length;

    if (canAdd) {
      this.setState({
        isNewSipTrunk: true,
      });
    } else {
      toastr.error('Count of sip trunks cannot be higher than connections count.');
    }
  };

  onNewSipTrunk = () => {
    this.setState({
      isNewSipTrunk: true,
    })
  };

  onOrderIdChange = ({target}) => {
    let value = target.value;
    this.props.hardwareActions.updateTelephoneExchangeOrderId(this.props.quotation.id, {orderId: value}).then((response) => {
      if (response.success) {
        this.setState({existingTelephoneExchange: response.existingTelephoneExchange});
      }
    });
  };

  calculateVamoAmount = () => {
    let microsoftProductsCount = 0;
    let kantoorProductsCount = 0;
    let vamoProductsCount = 0;

    if (this.props.hostedVoice.products) {
      let {vamoCount, microsoftCount, kantoorCount} = this.props.hostedVoice.products;

      vamoProductsCount += vamoCount;
      microsoftProductsCount += microsoftCount;
      kantoorProductsCount += kantoorCount;
    }

    return `Deze klant heeft ${kantoorProductsCount} Kantoor, ${vamoProductsCount} VAMO en ${microsoftProductsCount}  Microsoft 365 Business Voice profielen nu actief.`;
  };

  render() {
    const {mobileOptions, hostedVoice, locations, isMigration, quotation, authentication} = this.props;
    const {selectedLocation} = locations;
    const { theme, providers, isSalesValue, company } = authentication;
    const { inputsDisabled, type, provider, voicePerLocation } = quotation;

    const {hostedVoiceOptions, existingVoiceData, loading } = this.state;

    let vamoAmountString = this.calculateVamoAmount();

    const isQAQuotation = type === QuotationType.TYPE_QA;
    const isVerlengenType = type === QuotationType.TYPE_VERLENGEN;

    const callChargeTypeInt = parseInt(hostedVoiceOptions.callChargeType, 10);
    const isChargeTypeCombined = callChargeTypeInt === 3;
    const locationCallChargeTypeInt = selectedLocation.locationCallChargeType ? parseInt(selectedLocation.locationCallChargeType, 10) : 0;
    const locationCallChargeType = isChargeTypeCombined ? locationCallChargeTypeInt : callChargeTypeInt;

    const selectedLocationId = locations.selectedLocation.id;
    const showMobileSelections = selectedLocationId > -1;

    const contractOption = quotation.hostedVoiceOptionsAllLocations.find((el) => !el.location);
    const hostedVoiceEnabled = contractOption && contractOption.hostedVoiceActive;
    const bedrijfConnectEnable = contractOption && contractOption.businessConnectActive;

    const isKPNProvider = provider === providerType.KPN;
    const isYielderProvider = yielderProviders.includes(provider);
    const isVodafoneProvider = provider === providerType.VODAFONE;
    const isYielderPartner = company.includes('Yielder Partners');

    let showToggle = !showMobileSelections && providers.length;
    let isGlobalLocationSelected = selectedLocation.id <= 0;
    let showOptions = (bedrijfConnectEnable && !hostedVoiceEnabled && isGlobalLocationSelected) ||
      (hostedVoiceEnabled && ((!voicePerLocation && !hostedVoiceOptions.location) || (hostedVoiceOptions.location && voicePerLocation)));
    let callChargeTypeDisableCondition = isVerlengenType && existingVoiceData.length > 0;
    let callChargeTypeLabel = callChargeTypeDisableCondition ? 'Wilt u het belverkeer afkopen? (momenteel is het niet mogelijk deze instellingen te wijzigen)' : 'Wilt u het belverkeer afkopen?';

    return (
      <div>
        {loading ?
          <LoaderComponent theme={theme} /> :
          ( isQAQuotation ? <QuotationQuestionModule module={'voice'} location={selectedLocationId} /> :
              <div>
                {locations.selectedLocation.flight_case === true ?
                  <div>This step is disabled for flightcase locations.</div> :
                  <div className="row">
                    <fieldset disabled={inputsDisabled } style={{border: 'none', padding: 'none', margin: 'none'}}>
                      {showMobileSelections && bedrijfConnectEnable && !this.state.isNewSipTrunk ? <div>
                        {!locations.selectedLocation.sip_trunks.length && !this.state.isNewSipTrunk &&
                        <div className="row">
                          <div className="col s12">
                            <button
                              className="btn doceri-btn-right ratio-btn-right"
                              onClick={this.handleClickAddSipTrunk}
                              name="addSipTrunk"
                              disabled={!(locationCallChargeType !== 0) || inputsDisabled}
                            >
                              Bedrijf Connect Kanaal toevoegen
                              <i className="small material-icons right white-text">add</i>
                            </button>
                          </div>
                        </div>
                        }

                        <GeneralDialog
                          dialogState={this.state.isdnWarningDialogState}
                          modal={false}
                          onRequestClose={this.closeIsdnDialog}
                          headerText="Let op voldoende bandbreedte beschikbaar"
                          closeHandler={this.closeIsdnDialog}
                          dialogBodyContent={(
                            <DefaultDialogBody
                              text="Wij adviseren om te kiezen voor een 'Premium' verbinding"/>
                          )}
                        />
                      </div> : ''
                      }
                      <div>
                        <GeneralDialog
                          dialogState={this.state.publicSwitchesCombinedDialogState}
                          modal={false}
                          onRequestClose={() => {
                            this.setState({publicSwitchesCombinedDialogState: false})
                          }}
                          dialogBodyContent={(
                            <DefaultDialogBody
                              text="Het aantal Telefooncentrales is gezet naar 2, het minimale dat nodig is voor Hosted Voice met gecombineerd bellen afkopen. Indien meer gewenst zijn kun je deze waarde aanpassen."/>
                          )}
                        />

                        <GeneralDialog
                          dialogState={this.state.publicSwitchesDialogState}
                          modal={false}
                          onRequestClose={() => {
                            this.setState({publicSwitchesDialogState: false})
                          }}
                          dialogBodyContent={(
                            <DefaultDialogBody
                              text="Het aantal Telefooncentrales is verlaagd naar 1, het minimale dat nodig is voor Hosted Voice. Indien meer gewenst zijn kun je deze waarde aanpassen."/>
                          )}
                        />

                        <GeneralDialog
                          dialogState={this.state.hostedVoiceDialogState}
                          onRequestClose={this.closeHostedVoiceDialog}
                          headerText="Aantal gebruikers aanpassen niet mogelijk"
                          closeHandler={this.closeHostedVoiceDialog}
                          dialogBodyContent={(
                            <DefaultDialogBody
                              text="Het is niet mogelijk minder gebruikers te hebben als Add-ons. U dient eerst de hoeveelheid add-ons te verlagen of alles weer op 0 te zetten en opnieuw instellen."/>
                          )}
                          leftButtonAction={this.closeHostedVoiceDialog}
                          leftButtonText={"Gebruikers niet aanpassen"}
                          leftButtonLeft={true}
                          rightButtonAction={this.resetAddons}
                          rightButtonText={"Alle opties resetten"}
                        />

                        <GeneralDialog
                          dialogState={this.state.hostedVoiceCallTrafficDialogState}
                          onRequestClose={this.closeHostedVoiceCallTrafficDialog}
                          headerText="Aantal afkoop van belverkeer aanpassen niet mogelijk"
                          closeHandler={this.closeHostedVoiceCallTrafficDialog}
                          dialogBodyContent={(
                            <DefaultDialogBody text="U kunt niet meer belverkeer afkopen dan vaste One Net Vast gebruikers."/>
                          )}
                          rightButtonAction={this.closeHostedVoiceCallTrafficDialog}
                          rightButtonText={"Ik heb begrepen"}
                          rightButtonRight={true}
                        />

                        <GeneralDialog
                          dialogState={this.state.hostedVoiceUserToolDialogState}
                          onRequestClose={this.closeHostedVoiceUserToolDialog}
                          headerText="Error"
                          closeHandler={this.closeHostedVoiceUserToolDialog}
                          dialogBodyContent={(
                            <DefaultDialogBody text="You cannot take user tool in combination with One Mobiel."/>
                          )}
                          rightButtonAction={this.closeHostedVoiceUserToolDialog}
                          rightButtonText={"Close"}
                          rightButtonRight={true}
                        />

                        <GeneralDialog
                          dialogState={this.state.hostedVoiceCallCenterDialogState}
                          onRequestClose={this.closeHostedVoiceCallCenterDialog}
                          headerText="Error"
                          closeHandler={this.closeHostedVoiceCallCenterDialog}
                          dialogBodyContent={(
                            <DefaultDialogBody text="You need to have call center premium add-on in order to complete this selection."/>
                          )}
                          rightButtonAction={this.closeHostedVoiceCallCenterDialog}
                          rightButtonText={"Close"}
                          rightButtonRight={true}
                        />

                        <GeneralDialog
                          dialogState={this.state.callReportingDialogState}
                          onRequestClose={this.closeCallReportingDialog}
                          headerText="Gewenste keuze niet mogelijk"
                          closeHandler={this.closeCallReportingDialog}
                          dialogBodyContent={(
                            <DefaultDialogBody text="U kunt slechts één van deze opties kiezen!"/>
                          )}
                          rightButtonAction={this.closeCallReportingDialog}
                          rightButtonText={"Ik heb begrepen"}
                        />

                        <GeneralDialog
                          dialogState={this.state.webexLicenceDialogState}
                          onRequestClose={this.closeWebexLicenceDialog}
                          headerText="Aantal licenties aanpassen niet mogelijk"
                          closeHandler={this.closeWebexLicenceDialog}
                          dialogBodyContent={(
                            <DefaultDialogBody
                              text="Het is niet mogelijk om meer Webex licenties te selecteren dan het aantal Kantoorprofielen plus het aantal Vast-mobiel-modules plus het aantal Anywhere-modules."/>
                          )}
                          leftButtonAction={this.closeWebexLicenceDialog}
                          leftButtonText={"Licenties niet aanpassen"}
                          leftButtonLeft={true}
                          rightButtonAction={this.resetAddons}
                          rightButtonText={"Alle opties resetten"}
                        />

                        <GeneralDialog
                          dialogState={this.state.usersDialogState}
                          onRequestClose={this.closeUsersDialog}
                          headerText="Het aantal gebruikers komt niet overeen"
                          closeHandler={this.closeUsersDialog}
                          dialogBodyContent={(
                            <DefaultDialogBody
                              text="Het aantal P2G en Ongelimiteerde bellers dient overeen te komen met het aantal gebruikers."/>
                          )}
                          leftButtonAction={this.handleClickDialogAdjustButton}
                          leftButtonText={"Aantal gebruikers aanpassen"}
                          leftButtonLeft={true}
                          rightButtonAction={this.closeUsersDialog}
                          rightButtonText={"Aantal per type wijzigen"}
                        />

                        <GeneralDialog
                          dialogState={this.state.hostedVoiceBusinessVoiceDialogState}
                          onRequestClose={this.closeBusinessVoiceDialog}
                          modal={true}
                          headerText="Vergeet niet de aanvullende Microsoft licenties te bestellen"
                          headerSubtext="(deze kunt u vinden bij IT Diensten) 123A00042N Microsoft 365 Business Voice (without calling plan)"
                          closeHandler={this.closeBusinessVoiceDialog}
                          dialogBodyContent={(
                            <div style={{marginTop: 10}}>
                              <Input
                                s={12}
                                value={this.state.microsoftBusinessVoice}
                                onChange={this.onChangeMicrosoftBusinessVoice}
                                label="Hoeveel Microsoft 365 Business Voice (without calling plan) licenties wenst u?"
                                name="microsoftBusinessVoice"
                                type="number"
                                min={0}
                                max={999}
                                disabled={inputsDisabled}
                              />
                            </div>
                          )}
                          rightButtonAction={this.saveMicrosoftBusinessVoice}
                          rightButtonText={"Opslaan"}
                        />

                        {isVerlengenType && (
                          <div id="Forms" className="row">
                            <div className="col s12">
                              <div className="row">
                                <h1 className={'ratio-section-title'} style={{marginBottom: 0}}>
                                  <span className={'ratio-section-title'}>
                                    <i className="small material-icons left ratio-section-title">settings_phone</i>
                                    Actieve Voice orders wijzigen
                                  </span>
                                </h1>
                              </div>
                            </div>

                        <Col s={12}>
                          <Row style={{marginBottom: 0}}>
                            <div className="line tussenkop">
                              Kies hier per bestaand product welke actie u wilt doen
                              {isVerlengenType &&
                                <span>Bedrijf Connect en Kantoor Profielen zijn (nog) niet via Ratio aan te passen (up/downgrade/opheffen).</span>
                              }
                            </div>
                          </Row>

                              <Row>
                                <ExistingVoiceData
                                  data={this.state.existingVoiceData}
                                  inputsDisabled={inputsDisabled}
                                  addPhoneNumber={() => {}}
                                  upgradeData={this.state.upgradingData}
                                  dataRetrievalInProcess={this.state.dataRetrievalInProcess}
                                />
                              </Row>
                            </Col>
                          </div>
                        )}

                        {
                          showToggle &&
                          <HostedVoiceToggle
                            onChangeCallback={this.updateHostedVoiceOptions}
                            hostedVoiceState={hostedVoiceOptions.hostedVoiceActive}
                            businessConnectState={hostedVoiceOptions.businessConnectActive}
                            isMigrationOffer={isMigration}
                            quotation={quotation}
                            inputsDisabled={inputsDisabled}
                            updateQuotation={this.updateQuotation}
                            isSalesValue={isSalesValue}
                            isYielderProvider={isYielderProvider}
                            isYielderPartner={isYielderPartner}
                            showMobileSelections={!showMobileSelections}
                            organization={providers[0].organization}
                            voipPerLocation={this.state.voipPerLocation}
                            existingTelephoneExchange={this.state.existingTelephoneExchange}
                            onOrderIdChange={this.onOrderIdChange}
                            isVerlengenType={isVerlengenType}
                          />
                        }

                        {locations.selectedLocation.id > 0 && bedrijfConnectEnable ?
                          <BusinessConnectionOptions
                          quotation={quotation}
                          location={selectedLocation}
                          isNewSipTrunk={this.state.isNewSipTrunk}
                          onChangeCallback={this.updateSelectedLocation}
                          callChargeType={hostedVoiceOptions.callChargeType}
                          onNewSipTrunk={this.onNewSipTrunk}
                          changeCallCharge={this.changeCallCharge}
                          onClickSaveNewSipTrunk={this.handleClickSaveNewSipTrunk}
                          onClickDeleteSipTrunk={this.handleClickDeleteSipTrunk}
                          inputsDisabled={inputsDisabled}
                          /> : ''
                        }

                        {
                          (hostedVoiceOptions.hostedVoiceActive || hostedVoiceOptions.businessConnectActive) &&
                          !hostedVoiceOptions.location && isKPNProvider &&
                          <div id="Forms" className="col s12">
                            <div className="row">
                              <div className="col s12">
                                <h1 className={'ratio-section-title'}>
                                        <span className="ratio-section-title">
                                            <i className="small material-icons left ratio-section-title">settings_phone</i>Belverkeer:
                                        </span> belverkeer afkopen
                                </h1>
                              </div>
                            </div>
                            <div className="row">
                              <Input
                                name="callChargeType"
                                type='select'
                                label={callChargeTypeLabel}
                                onChange={this.updateHostedVoiceOptions}
                                value={hostedVoiceOptions.callChargeType}
                                disabled={callChargeTypeDisableCondition || inputsDisabled}
                                multiple={false}
                              >
                                <option value={1}>Nee</option>
                                <option value={2}>Ja</option>
                              </Input>
                            </div>
                          </div>
                        }

                        {
                          (!isVerlengenType) && isKPNProvider && hostedVoiceOptions.hostedVoiceActive && !hostedVoiceOptions.location &&
                          <div id="Forms" className="col s12">
                            <div className="row">
                              <div className="col s12">
                                <h1 className={'ratio-section-title'}>
                              <span className="ratio-section-title">
                                <i className="small material-icons left ratio-section-title">settings_phone</i>Telefooncentrale:
                              </span> gewenste opties
                                </h1>
                              </div>
                            </div>
                            <div className="row">
                              <div className="input-field col s6 m6 inner-addon">
                                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                                <DelayInput
                                  delayTimeout={500}
                                  id="publicswiches"
                                  type="number"
                                  min={0}
                                  max={999}
                                  className="validate"
                                  name="publicSwitches"
                                  value={Number(hostedVoiceOptions.publicSwitches).toString()}
                                  onChange={this.updateHostedVoiceOptions}
                                  onBlur={this.onQuantitySaveAction}
                                  disabled={inputsDisabled}
                                />
                                <label htmlFor="publicswiches">Hoeveel telefooncentrales zijn er nodig?</label>
                              </div>
                            </div>
                          </div>
                        }

                        {(!isVerlengenType) && isKPNProvider && hostedVoiceOptions.hostedVoiceActive && !hostedVoiceOptions.location && !isSalesValue &&
                        <div id="Forms" className="col s12">
                          <div className="row">
                            <div className="col s12">
                              <h1 className={'ratio-section-title'}>
                              <span className="ratio-section-title">
                                <i className="small material-icons left ratio-section-title">settings_phone</i>Teams bellen:
                              </span> gewenste opties
                              </h1>
                            </div>
                          </div>
                          <div className="row">
                            <div className="input-field col s6 m6 inner-addon">
                              <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                              <DelayInput
                                delayTimeout={500}
                                id="teamsCall"
                                type="number"
                                min={0}
                                max={999}
                                className="validate"
                                name="teamsCall"
                                value={Number(hostedVoiceOptions.teamsCall).toString()}
                                onChange={this.updateHostedVoiceOptions}
                                onBlur={this.onQuantitySaveAction}
                                disabled={inputsDisabled}
                              />
                              <label htmlFor="teamsCall">Aantal spraakkanalen gewenst</label>
                            </div>

                            {
                              hostedVoiceOptions.callChargeType === 2 && hostedVoiceOptions.teamsCall > 0 &&
                                <div className="input-field col s6 m6 inner-addon">
                                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                                  <DelayInput
                                      delayTimeout={500}
                                      id="voiceChannels"
                                      type="number"
                                      min={0}
                                      max={hostedVoiceOptions.teamsCall}
                                      className="validate"
                                      name="voiceChannels"
                                      value={Number(hostedVoiceOptions.voiceChannels).toString()}
                                      onChange={this.updateHostedVoiceOptions}
                                      onBlur={this.onQuantitySaveAction}
                                      disabled={inputsDisabled}
                                  />
                                  <label htmlFor="teamsCall">Aantal teams onbeperkt bellen gewenst</label>
                                </div>
                            }
                          </div>
                        </div>
                        }

                        {showOptions && (isKPNProvider || isVodafoneProvider) &&
                        <HostedVoiceOptions
                          hostedVoiceOptions={hostedVoiceOptions}
                          onChangeCallback={this.updateHostedVoiceOptions}
                          addNumber={this.addNumber}
                          removeNumber={this.removeNumber}
                          changeNumber={this.changeNumber}
                          mobileOptions={mobileOptions}
                          hardwareList={hostedVoice.hardware}
                          usersSumValid={this.usersSumValid}
                          quotationStatus={this.props.quotation.status}
                          inputsDisabled={inputsDisabled}
                          receptionTypes={this.props.quotation.receptionTypes}
                          isVerlengenType={isVerlengenType}
                          onQuantitySaveAction={this.onQuantitySaveAction}
                          existingVoiceData={this.props.existingVoiceData}
                          vamoAmountString={vamoAmountString}
                          onChangeCrmCaraSoloOptions={this.onChangeCrmCaraSoloOptions}
                          crmCaraSoloRequiredErrorMessage={this.state.crmCaraSoloRequiredErrorMessage}
                        />
                        }

                        { showOptions && isYielderProvider && (
                            <HostedVoiceOptionsYielder
                            />
                        )}

                        <CustomHardwareWithCategories
                            type="voice"
                            connectionId={null}
                            isGrouped={false}
                            inputsDisabled={inputsDisabled}
                        />
                      </div>
                    </fieldset>
                  </div>
                }
              </div>
          )
        }
      </div>
    )
  }
}

const mapStateToProps = ({quotation, locations, hostedVoice, mobile, authentication, hardware, loading, partnerServices}) => {
  return {
    quotation: quotation.currentQuotation,
    hostedVoiceOptions: quotation.currentQuotation.hostedVoiceOptions,
    quotationId: quotation.currentQuotation.id,
    locations: locations,
    hostedVoice: hostedVoice,
    mobileOptions: quotation.currentQuotation.mobileOptions,
    isMigration: quotation.currentQuotation.type === QuotationType.TYPE_MIGRATION,
    currentMobileProductOptions: mobile.currentMobileProductOptions,
    existingVoiceData: hardware.existingVoiceData,
    authentication: authentication,
    loading,
    partnerServices
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    hostedVoiceActions: bindActionCreators(hostedVoiceActions, dispatch),
    businessConnectActions: bindActionCreators(businessConnectActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
    locationActions: bindActionCreators(locationActions, dispatch),
    hardwareActions: bindActionCreators(hardwareActions, dispatch),
    mobileActions: bindActionCreators(mobileActions, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
    partnerServicesActions: bindActionCreators(partnerServicesActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(HostedVoiceStep);
