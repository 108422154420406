import {
    actionTypes
} from '../constants/actionTypes';

const initialState = {
    orders: [],
    quotationOrderProducts: [],
    pages: 1,
    currentPage: 1,
    resultsCount: 0,
    currentSectionId: null,
    currentQuotationOrder: {}
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.setCurrentOrderSection:
            return {
                ...state,
                currentSectionId: action.payload
            };
        case actionTypes.setCurrentQuotationOrder:
            return {
                ...state,
                currentQuotationOrder: action.payload
            };
        case actionTypes.getOrdersData:
            return {
                ...state,
                orders: action.response.orders
            };
        case actionTypes.savePagination:
            return {
                ...state,
                pages : action.payload.pages,
                currentPage: action.payload.currentPage,
                resultsCount: action.payload.resultsCount
            };
        case actionTypes.getQuotationOrderProductsSuccess:
            return {
                ...state,
                quotationOrderProducts: action.payload
            };
        default:
            return state;
    }
}
