import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import debounce from "lodash/debounce";
import {toastr} from 'react-redux-toastr'

import {Input} from "react-materialize";

import getQuotationOptionsThunk from "../../actions/workflow/isdnMigrationActions/getQuotationOptionsThunk";
import updateQuotationOptionsThunk from "../../actions/workflow/isdnMigrationActions/updateQuotationOptionsThunk";
import quotationActions from "../../actions/quotationActions";
import partnerServicesActions from "../../actions/partnerServicesActions";

class ITDienstenMKBTVOption extends Component {
  CATEGORY = 'MKB';

  constructor(props) {
    super(props);

    this.state = {
      productFirstSection: [],
      productsSecondSection: [],
      productThirdSection: [],
      selections: [],
      firstProduct: null,
      customSelections: []
    }
  }

  componentDidMount() {
    const productFirstSection = this.props.partnerServices.productsToLink.mkbProductZakelijk;
    const productsSecondSection = this.props.partnerServices.productsToLink.mkbProductsRemoteControl;
    const productThirdSection = this.props.partnerServices.productsToLink.mkbProductZender;

    this.saveSelections = debounce(this.saveSelections, 1000);

    const selections = this.props.partnerServices.itServicesSelections;
    const firstProduct = productFirstSection.length ? selections.find((el) => el.id === productFirstSection[0].id) : null;
    const secondProduct = selections.filter((el) => productsSecondSection.find((product) => product.id === el.id));
    const thirdProduct = selections.find((el) => el.id === productThirdSection[0]?.id);
    let customProducts = this.state.customSelections;
    if (secondProduct.length) {
      customProducts = customProducts.concat(secondProduct);
    }

    if (productsSecondSection.length && !customProducts.length) {
      customProducts = customProducts.concat({
        id: "",
        quantity: 1,
        category: this.CATEGORY
      });
    }

    this.setState({
      productFirstSection: productFirstSection,
      productsSecondSection: productsSecondSection,
      productThirdSection: productThirdSection,
      firstProduct: firstProduct ? firstProduct.id : "",
      thirdProduct: thirdProduct ? thirdProduct.id : "",
      selections: selections.filter(selection => selection.category === this.CATEGORY),
      customSelections: customProducts
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const selections = this.props.partnerServices.itServicesSelections;
    const oldSelections = prevProps.partnerServices.itServicesSelections;

    if (oldSelections !== selections) {
      const productFirstSection = this.props.partnerServices.productsToLink.mkbProductZakelijk;
      const productsSecondSection = this.props.partnerServices.productsToLink.mkbProductsRemoteControl;
      const productThirdSection = this.props.partnerServices.productsToLink.mkbProductZender;

      const selections = this.props.partnerServices.itServicesSelections;
      const firstProduct = selections.find((el) => el.id === productFirstSection[0].id);
      const secondProduct = selections.filter((el) => productsSecondSection.find((product) => product.id === el.id));
      const thirdProduct = selections.find((el) => el.id === productThirdSection[0]?.id);

      let customProducts = this.state.customSelections;
      if (secondProduct.length && !customProducts.find((el) => secondProduct.find((product) => product.id === el.id))) {
        customProducts = customProducts.concat(secondProduct);
      }

      if (productsSecondSection.length && !this.state.customSelections.length) {
        customProducts = customProducts.concat({
          id: "",
          quantity: 1,
          category: this.CATEGORY
        });
      }

      this.setState({
        selections: [...selections.filter(selection => selection.category === this.CATEGORY)],
        firstProduct: firstProduct ? firstProduct.id : "",
        thirdProduct: thirdProduct ? thirdProduct.id : "",
        customSelections: customProducts
      })
    }
  }

  saveSelections = (selections, quotationId, type) => {
    const {itServicesSelections} = this.props.partnerServices;

    const allSelections = itServicesSelections.filter(selection => selection.category !== this.CATEGORY);
    const newSelections = [...allSelections, ...selections];

    this.props.partnerServicesActions.persistPartnerServicesSelections(newSelections, quotationId, type).then(() => {
      this.props.partnerServicesActions.setITServiceSelection(newSelections);
    });
  };

  addOption = () => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }
    const {customSelections} = this.state;

    customSelections.push({
      id: "",
      quantity: 1,
      category: this.CATEGORY
    });

    this.setState({customSelections});
  };

  onCustomChange = (event) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }
    const {value, name} = event.target;
    const {selections, customSelections, firstProduct, thirdProduct} = this.state;

    if (value) {
      if (name.includes('secondProduct')) {
        this.setState({
          customSelections: [...customSelections.filter((selection) => {
            return selection.id !== customSelections[parseInt(name.replace('secondProduct-', ''), 10)].id
          }), {
            id: parseInt(value, 10),
            quantity: 1,
            category: this.CATEGORY
          }]
        });
      } else {
        this.setState({
          selections: [...selections, {
            id: parseInt(value, 10),
            quantity: 1,
            category: this.CATEGORY
          }]
        })
      }
    } else {
      if (name.includes('secondProduct')) {
        this.setState({
          customSelections: customSelections.filter((selection) => {
            return selection.id !== customSelections[parseInt(name.replace('secondProduct-', ''), 10)].id;
          })
        });
      } else {
        this.setState({
          selections: selections.filter((selection) => {
            let productId = firstProduct;

            if (name === 'thirdProduct') {
              productId = thirdProduct;
            }
            return selection.id !== productId;
          })
        });
      }
    }

    const filteredSelections = this.state.selections
      .filter((el) => !this.props.partnerServices.productsToLink.mkbProductsRemoteControl
        .find((product) => product.id === el.id));

    this.saveSelections(filteredSelections.concat(this.state.customSelections), this.props.quotation.currentQuotation.id, 'it');
  };

  changeQuantity = (event, productId) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }

    let {selections, customSelections, firstProduct, thirdProduct} = this.state;
    const {value, name} = event.target;

    if (!this.validateAmount(value, productId) && name !== 'firstProduct') {
      toastr.error("Maximum aantal bereikt");
      return;
    }

    const tvProduct = selections.find((el) => el.id === firstProduct);
    let quantity = 0;

    if ((productId === firstProduct) && (value < tvProduct.quantity)) {
      if (customSelections.length > 0) {
        customSelections.filter(product => product.id !== '').map((product) => {
          if (product.quantity >= tvProduct.quantity) {
            product.quantity--;
          }

          return product;
        });
      }

      if (thirdProduct) {
        selections.filter(product => product.id !== '').map((product) => {
          if (product.id === thirdProduct && product.quantity >= tvProduct.quantity) {
            product.quantity--;
          }

          return product;
        });
      }
    }

    if (thirdProduct && productId === thirdProduct) {
      if (value > tvProduct.quantity) {
        return toastr.error('The quantity should be equal to or lower than the Zakelijke TV quantity', '');
      }
    }

    if (productId !== firstProduct && productId !== thirdProduct) {
      if (value > tvProduct.quantity) {
        return toastr.error('The quantity should be equal to or lower than the Zakelijke TV quantity', '');
      } else {
        customSelections.filter(product => product.id !== '').map((product) => {
          if (product.id !== productId) {
            return quantity = product.quantity;
          }

          return product;
        });

        if ((parseInt(quantity, 10) + parseInt(value, 10)) > tvProduct.quantity * 2) {
          return toastr.error('The amount of zenderpakketten should be lower than  Zakelijke TV amount', '');
        }
      }
    }

    this.setState({
      selections: selections.map((selection) => {
        if (selection.id == productId) {
          selection.quantity = value;
        }
        return selection;
      })
    });

    //filter selections to not send null ids
    selections = this.state.selections.filter(selection => selection.id != null) || [];

    this.saveSelections(selections, this.props.quotation.currentQuotation.id, 'it');
  };

  validateAmount = (amount, productId) => {
    let mainSelection = this.state.productFirstSection[0].id;
    let mainProduct = this.props.partnerServices.itServicesSelections.filter((el) => el.id === mainSelection);
    mainProduct = mainProduct[0] ? mainProduct[0] : mainProduct;
    let selections = this.state.selections.filter((selection) => {
      if (selection.id === productId) {
        return parseInt(selection.quantity) > parseInt(amount);
      }
    });

    if (selections.length >= 1) {
      return true;
    }

    return mainProduct.quantity >= amount;
  };

  render() {
    const {allowDecimalQuantity} = this.props;
    const {
      selections,
      customSelections,
      firstProduct,
      thirdProduct,
      productFirstSection,
      productsSecondSection,
      productThirdSection
    } = this.state;
    const {inputsDisabled} = this.props.quotation.currentQuotation;

    const quantityStep = allowDecimalQuantity ? 0.01 : 1;

    let firstProductQuantity = 1;
    let thirdProductQuantity = 1;

    if (selections.length) {
      if (firstProduct) {
        const option = selections.find((el) => el.id === firstProduct);
        if (option) {
          firstProductQuantity = option.quantity
        }
      }

      if (thirdProduct) {
        const option = selections.find((el) => el.id === thirdProduct);
        if (option) {
          thirdProductQuantity = option.quantity
        }
      }
    }

    return (
      <>
        <fieldset disabled={inputsDisabled} style={{border: 'none', padding: 'unset', margin: 'unset'}}>
          <div className="row">
            <h1 className={'ratio-section-title'} style={{marginTop: 0}}>
              <span className="ratio-section-title">
                <i className="small material-icons left ratio-section-title">call_merge</i>IT Diensten
              </span> MKB TV
            </h1>
          </div>

          <div className="row">
            <div className="row">
              <Input
                s={1}
                name='firstProduct'
                type="number"
                label="aantal"
                value={firstProductQuantity}
                onChange={(e) => {
                  this.changeQuantity(e, firstProduct)
                }}
                step={quantityStep}
                min={quantityStep}
                max={999}
                disabled={inputsDisabled}
              />

              {productFirstSection.length &&
              <Input
                s={7}
                name='product'
                type='select'
                label='Kies hier voor zakelijke tv wanneer u deze wenst'
                value={firstProduct}
                onChange={(e) => {
                  this.onCustomChange(e)
                }}
                multiple={false}
                disabled={inputsDisabled}
              >
                <option value="">Selecteer</option>
                {productFirstSection.map((optionItem) => <option key={optionItem.id}
                                                                 value={optionItem.id}>{optionItem.name}</option>)}
              </Input>}
            </div>

            <div className="row themeprimarycolor"
                 style={{width: '100%', borderTop: '2px dotted', paddingBottom: '20px'}}>
              Stel hier de zender pakketten in die u wenst
            </div>

            {customSelections.map((option, index) => {
              return (
                <div className="row" style={{marginBottom: customSelections?.length - 1 !== index ? 0 : null}}>
                  <Input
                    s={1}
                    type="number"
                    label={index === 0 ? 'aantal' : ''}
                    style={{marginBottom: selections?.length > 1 ? '15px' : null}}
                    value={option.quantity}
                    onChange={(e) => {
                      this.changeQuantity(e, option.id)
                    }}
                    step={quantityStep}
                    min={quantityStep}
                    max={999}
                    disabled={!this.state.firstProduct || inputsDisabled}
                  />

                  <Input
                    s={7}
                    name={'secondProduct-' + index}
                    className={customSelections?.length > 1 && !(customSelections?.length - 1 === index) ? "ratio-input" : ''}
                    type='select'
                    label={index === 0 ? 'Kies hier de zenderpakketten die u wenst' : ''}
                    value={option.id}
                    disabled={!this.state.firstProduct || inputsDisabled}
                    onChange={(e) => {
                      this.onCustomChange(e)
                    }}
                    multiple={false}
                  >
                    <option value="">Selecteer</option>
                    {
                      productsSecondSection
                        .filter((el) => !customSelections
                          .find((product) => product.id === el.id) || el.id === option.id)
                        .map((optionItem) =>
                          <option key={optionItem.id} value={optionItem.id}>
                            {optionItem.name}
                          </option>)
                    }
                  </Input>

                  {index === customSelections.length - 1 && customSelections.length < 2 &&
                  <button
                    className='btn doceri-btn-right ratio-btn-right s1'
                    onClick={this.addOption}
                    disabled={!this.state.firstProduct || inputsDisabled}
                    style={{padding: '0px 15px'}}
                  >
                    Extra zender pakket toevoegen
                  </button>
                  }
                </div>
              );
            })}

            {
              customSelections.length &&
              <div className="row themeprimarycolor"
                   style={{width: '100%', borderTop: '2px dotted', paddingBottom: '20px'}}>
                Stel hier de hardware in die u wenst
              </div>
            }
            <div className="row">
              <Input
                s={1}
                type="number"
                label="aantal"
                value={thirdProductQuantity}
                disabled={!this.state.firstProduct || inputsDisabled}
                onChange={(e) => {
                  this.changeQuantity(e, thirdProduct)
                }}
                step={quantityStep}
                min={quantityStep}
                max={999}
              />

              {
                productThirdSection.length &&
                <Input
                  s={7}
                  name='thirdProduct'
                  type='select'
                  label='Kies hier de hardware die u wenst'
                  value={thirdProduct}
                  disabled={!this.state.firstProduct || inputsDisabled}
                  onChange={(e) => {
                    this.onCustomChange(e)
                  }}
                  multiple={false}
                >
                  <option value="">Selecteer</option>
                  {productThirdSection.map((optionItem) => <option key={optionItem.id}
                                                                   value={optionItem.id}>{optionItem.name}</option>)}
                </Input>
              }
            </div>
          </div>
        </fieldset>
      </>
    )
  }
}

const mapStateToProps = ({quotation, quotationOptions, partnerServices, authentication}) => {
  return {
    quotation,
    quotationOptions,
    partnerServices,
    authentication
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getQuotationOptions: bindActionCreators(getQuotationOptionsThunk, dispatch),
    updateQuotationOptions: bindActionCreators(updateQuotationOptionsThunk, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
    partnerServicesActions: bindActionCreators(partnerServicesActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ITDienstenMKBTVOption);
