import React, {Component} from 'react';

import DigitalSigningCustomGroups from "./digitalSigningCustomGroups";
import {bindActionCreators} from "redux";
import digitalSigningLogActions from "../../actions/digitalSigningLogActions";
import {connect} from "react-redux";
import {Preloader} from "react-materialize";
import FileDownload from "js-file-download";
import {withRouter} from "react-router-dom";
import GeneralDialog from "../common/GeneralDialog";
import {FormControlLabel, FormGroup} from "@mui/material";
import {Checkbox} from "material-ui";
import termsAndConditionsActions from "../../actions/termsAndConditionsActions";
import {sleep} from "../../utils/utils";

class DigitalSigningContent extends Component {
	state = {
		loader: false,
		code: '',
		signatureOff: false,
		attachmentDownloadDialog: false,
		isTermsAndConditionsSigned: this.props.digitalSigningLog.isDigitalSigningLogTermsSigned,
		digitalSigningLog: this.props.digitalSigningLog
	};

	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
		if (prevProps.digitalSigningLog.isDigitalSigningLogTermsSigned !== this.props.digitalSigningLog.isDigitalSigningLogTermsSigned) {
			this.setState({isTermsAndConditionsSigned: this.props.digitalSigningLog.isDigitalSigningLogTermsSigned})
		}
	}

	handleChange = ({target}) => {
		const {name, value} = target;
		
		this.setState({
			[name]: value
		});
	};
	
	downloadConvertedWordDocument = (documentType) => {
		const {quotationName} = this.props.digitalSigningLog.signingLog;
		const {attachmentDocuments, newAttachmentDocuments} = this.props;
		const uuid = this.props.match.params.uuid;
		
		this.setState({loader: true});

		if (documentType !== 'attachment') {
			this.processWordDocumentDownload(quotationName, uuid, documentType, null, 0);
		} else {
			if (newAttachmentDocuments.length) {
				newAttachmentDocuments.forEach((attachmentDocument, index) => {
					this.processAttachmentDownload(quotationName, uuid, attachmentDocument.id, attachmentDocument.type, index + 1);
				})
			}

			if (attachmentDocuments.length) {
				attachmentDocuments.forEach((attachmentDocument, index) => {
					this.processWordDocumentDownload(quotationName, uuid, documentType, attachmentDocument.id, index + 1);
				})
			} else {
				this.setState({loader: false, attachmentDownloadDialog: true});
			}
		}
	};

	processAttachmentDownload = (quotationName, uuid, documentId, documentType, index) => {
		const fileName = index ? `${quotationName} Bijlage ${index}.${documentType}` : `${quotationName}.${documentType}`;
		this.props.digitalSigningLogActions.downloadAttachments(uuid, documentId)
			.then(() => FileDownload(this.props.digitalSigningLog.attachmentDocument, fileName))
			.then(() => this.setState({loader: false})
			);
	};

	processWordDocumentDownload = (quotationName, uuid, documentType, documentId, index) => {
		const fileName = index ? `${quotationName} Bijlage ${index}.pdf` : `${quotationName}.pdf`;
		this.props.digitalSigningLogActions.downloadPDFDocument(uuid, documentType, documentId)
			.then(() => FileDownload(this.props.digitalSigningLog.pdfDocument, fileName))
			.then(() => this.setState({loader: false})
			);
	};

	closeAttachmentDownloadDialog = () => {
		this.setState({attachmentDownloadDialog: false});
	};

	signTermsAndConditions = ({target: { checked} }) => {
		const uuid = this.props.match.params.uuid;

		this.setState({isTermsAndConditionsSigned: checked}, () => {
			this.props.termsAndConditionsActions.signTermsAndConditions(uuid, checked)
				.then(() => {
					this.setState({
						digitalSigningLog: {...this.state.digitalSigningLog, isSubmitButtonDisabled: !checked}
					}, () => {
						this.props.digitalSigningLogActions.isDigitalSigningSubmitButtonDisabled(this.state.digitalSigningLog)
					})
				})
		})
	};

	openTermsAndConditionsFile = (event) => {
		event.preventDefault();
		let tcFiles= this.props.tcFiles;
		const uuid = this.props.match.params.uuid;

		tcFiles.forEach((tcFile) => {
			this.props.termsAndConditionsActions.retrieveTermsAndConditionsFile(uuid, tcFile.id)
				.then((response) => {
					if (response) {
						let blob = new Blob([response], { type: 'application/pdf' });
						let blobUrl = URL.createObjectURL(blob);
						window.open(blobUrl, '_blank');
					}
				});
		});
	};
	
	render() {
		const customGroups = this.props.digitalSigningGroups;
		const customFields = this.props.digitalSigningFields;
		const {PreloaderParent, digitalSigningDetails} = this.props;
		const {isHeaderBold, isContentBold, header, content} = digitalSigningDetails;
		const digitalSigningLog = this.props.digitalSigningLog;

		return (
			<fieldset style={{border: 'none', padding: 'unset', margin: 'unset'}}>
				<div className="Forms digitalSigningForm digital-signing-form-content">
					<h1 className={'ratio-section-title'}>
            <span className="ratio-section-title">
              <i className="small material-icons left ratio-section-title">assignment</i>
            </span>
						Digitaal ondertekenen
					</h1>
					
					{header &&
						<div className="row">
							<span style={{whiteSpace: 'pre-wrap'}}>{isHeaderBold ? <b>{header}</b> : header}</span>
						</div>
					}
					
					{content &&
						<div className="row">
							<span  style={{whiteSpace: 'pre-wrap'}}>{isContentBold ? <b>{content}</b> : content}</span>
						</div>
					}

					<GeneralDialog
						dialogState={this.state.attachmentDownloadDialog}
						headerText={`Op dit moment zijn er geen bijlagedocumenten beschikbaar om te downloaden`}
						rightButtonAction={this.closeAttachmentDownloadDialog}
						rightButtonText={"Ok, ik heb dit begrepen"}
					/>
					
					<div className="row download-buttons">
						<button
							id="download-offerte-pdf"
							className={'btn doceri-btn-right'}
							onClick={() => this.downloadConvertedWordDocument('standard')}
							name="forward"
							disabled={this.state.loader}
						>
							{this.state.loader ?
								<PreloaderParent>
									<Preloader/>
								</PreloaderParent> :
								'Download offerte'
							}
						</button>
						
						<button
							id="download-offerte-attachment"
							className={'btn doceri-btn-right'}
							onClick={() => this.downloadConvertedWordDocument('attachment')}
							name="download-offerte-attachment"
							disabled={this.state.loader}
						>
							{this.state.loader ?
								<PreloaderParent>
									<Preloader/>
								</PreloaderParent> :
								'Download bijlages'
							}
						</button>
					</div>
				</div>
				
				{customGroups.map((customGroup, index) =>
					<div key={index} className={'Forms digitalSigningForm'}>
						<DigitalSigningCustomGroups
							digitalCustomGroup={customGroup}
							digitalCustomFields={customFields}
							key={index}
						/>
					</div>
				)}
				{digitalSigningLog.isTermsAndConditionsEnabled &&
					<div id="Forms" className="ratiotable paddingbottomnone">
						<h1 className="admin-subheader-text"><span className="ratio-section-title">Algemene voorwaarden</span></h1>
						<div className="row terms-and-conditions-container">
							<FormGroup className="col">
								<FormControlLabel control={
									<Checkbox style={{width: '0%'}}
											  checked={this.state.isTermsAndConditionsSigned}
											  onCheck={this.signTermsAndConditions}
									/>
								}
												  label="Akkoord"
								/>
							</FormGroup>
							<a href="javascript:void(0)"
							   className="col" style={{fontWeight: '750'}}
							   onClick={this.openTermsAndConditionsFile}
							>
								Bekijk de algemene voorwaarden
							</a>
						</div>
					</div>
				}
			</fieldset>
		);
	}
}

const mapStateToProps = ({digitalSigningLog}) => {
	return {
		digitalSigningLog
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		digitalSigningLogActions: bindActionCreators(digitalSigningLogActions, dispatch),
		termsAndConditionsActions: bindActionCreators(termsAndConditionsActions, dispatch)
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DigitalSigningContent));
