import {actionTypes} from '../constants/actionTypes';
import {apiRoutes} from '../constants/apiRoutes';
import {toastr} from 'react-redux-toastr'

import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

const getDiscountProduct = (quotationId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.discountProductRelated.getDiscountProduct.replace(/{quotationId}/, quotationId);
      let response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getDiscountProductSuccess(response));
    }
    catch ({message}) {
      toastr.error('Het is niet mogelijk de lijst met offertes op te halen', message);
    }
  }
};

const getDiscountProductSuccess = (response) => {
  return {
    type: actionTypes.getDiscountProduct,
    response
  };
};

const editDiscountProduct = (quotationId, data) => {
  return async (dispatch) => {
    try
    {
      let url = apiRoutes.discountProductRelated.editDiscountProduct.replace(/{quotationId}/, quotationId);
      let response = await ApiService.performRequest(RequestType.PATCH, url, data);

      toastr.success('Product bewerkt', '');
      dispatch(_editDiscountProductSuccess(response));
    }
    catch (error)
    {
      if (error.response.status === 400) {
        toastr.error('Het is niet mogelijk om deze gebruiker te bewerken', 'Formulier bevat fouten')
      } else if(error.response.status === 403) {
        toastr.error('Het is niet mogelijk om deze gebruiker te bewerken', 'U heeft geen rechten om deze gebruiker te bewerken')
      } else {
        toastr.error('Het is niet mogelijk om deze gebruiker te bewerken', error.message);
      }
    }
  }
};

const _editDiscountProductSuccess = (response) => {
  return {
    type: actionTypes.editDiscountProduct,
    response
  }
};

export default {
  getDiscountProduct,
  editDiscountProduct,
}
