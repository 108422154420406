import {
  actionTypes
} from '../constants/actionTypes';

const initialState = {
  newLogo: {
    name: '',
  },
  logos: [],
  logoFileBlob: '',
  provider: null,
  pages: 1,
  currentPage: 1,
  resultsCount: 0
};

export default (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.getLogos:
      return {
        ...state,
        logos: action.response,
      };
    case actionTypes.deleteLogoFile:
      return {
        ...state,
        logos: state.logos.filter( logoFile => logoFile.id !== action.fileId )
      };
    case actionTypes.downloadLogoFile:
      return {
        ...state,
        logoFileBlob: action.response
      };

    case actionTypes.setOrganizationProviderFilter:
      return {
        ...state,
        provider: action.payload
      };
    case actionTypes.setLogosPagination:
      return {
        ...state,
        pages : action.payload.pages,
        currentPage: action.payload.currentPage,
        resultsCount: action.payload.resultsCount
      };
    default:
      return state;
  }
}
