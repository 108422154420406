import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import productActions from "../../../actions/productActions";

import {DelayInput} from "react-delay-input";
import {Button, Input, Preloader} from "react-materialize";

import ProviderHelper from "../../../helper/providerHelper";
import ProductsAdjustingGrid from "./ProductsAdjustingGrid";
import Pagination from "../../../components/pagination/pagination";
import {odidoProviders} from "../../../constants/providerType";

class ProductsAdjustingPanel extends Component {
  sortInitialState = {
    name: false,
    offer: false,
    module: false,
    expired: false,
    createdAt: false,
    updatedAt: false,
  };

  searchFields = [
    {
      name: 'Productnaam',
      value: 'name'
    },
    {
      name: 'Productnummer',
      value: 'productNumber',
    },
    {
      name: 'ID',
      value: 'id'
    }
  ];

  odidoSearchFields = [
    {
      name: 'Productnaam',
      value: 'name'
    },
    {
      name: 'Productcode',
      value: 'code',
    },
    {
      name: 'Scenario',
      value: 'scenario'
    },
    {
      name: 'ID',
      value: 'id'
    }
  ];

  tableHeaderNames = [
    'ID', 'Productnaam', 'Productnummer', 'Module', 'Categorie', 'Verlopen', 'laatst gewijzigd', 'Acties'
  ];
  tableObjectProperties = [
    'id', 'name', 'productNumber', 'module', 'productCategory', 'expired', 'updatedAt', ''
  ];

  odidoTableHeaders = [
    'ID', 'Productnaam', 'Code', 'Scenario', 'Categorie', 'Specificatie', 'laatst gewijzigd', 'Acties'
  ];

  odidoTableObjectProperties = [
    'id', 'name', 'code', 'scenario', 'category', 'specification', 'updatedAt', ''
  ];

  constructor(props) {
    super(props);

    this.state = {
      sorting: {
        ...this.sortInitialState
      },
      odidoProducts: [],
      selectedOdidoProduct: null,
      selectedOdidoProductDetails: null,
      pfsOverview: true,
      loading: false,
      provider: this.props.products.provider,
      page: 1,
      searchPhrase: '',
      searchField: 'name',
      selectedProduct: null,
      selectedProductDetails: null
    };
  }

  componentDidMount() {
    if (this.state.provider !== null) {
      this.getAllProducts();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.provider !== this.state.provider) {
      this.getAllProducts();
    }
  }

  getAllProducts = (sortField, sortDirection, page, searchField, searchQuery) => {
    this.setState({loading: true});
    const {provider} = this.state;

    let queryParams = '';

    if (sortField && sortDirection) {
      queryParams += '?sortField=' + sortField;
      queryParams += '&sortDirection=' + sortDirection;
    }
    if (searchQuery) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?');
      queryParams += 'searchQuery=' + searchQuery;
      queryParams += '&searchField=' + searchField;
    }
    if (page) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?');
      queryParams += 'page=' + page
    }

    this.props.productActions.getAllProducts(queryParams, provider).then(() => this.setState({loading: false}));
  };

  handleChangeProvider = ({target}) => {
    const provider = target.value;

    this.setState({ provider: provider, pfsOverview: true});
    this.props.productActions.setProvider(provider);
  };

  onSearch = ({target}) => {
    let {value} = target;

    let {pfsOverview, sorting, page, searchField, odidoProducts} = this.state;
    if (! pfsOverview) {
      let filteredOdidoProducts = [];

      if (value.length) {
        filteredOdidoProducts = odidoProducts.filter((product) => product[searchField].includes(value));

        this.setState({odidoProducts: filteredOdidoProducts});
      } else {
        let {selectedProduct} = this.state;

        this.props.productActions.getProductOdidoDetails(selectedProduct.id).then((response) => {
          if (response && response.products) {
            this.setState({odidoProducts: response.products});
          }
        });
      }
    } else {
      let tableProperties = pfsOverview ? this.tableObjectProperties : this.odidoTableObjectProperties;

      const sortField = tableProperties.find(x => sorting[x] !== false);
      const sortValue = sorting[sortField];

      this.setState({searchPhrase: value});

      this.getAllProducts(sortField, sortValue, page, searchField, value);
    }
  };

  handleChangeSearchField = ({target}) => {
    this.setState({searchField: target.value, searchPhrase: ''});
  };

  changePage = ({target}) => {
    const {value} = target;
    this.setState({page: value});

    let {pfsOverview, sorting, searchPhrase, searchField} = this.state;
    let tableProperties = pfsOverview ? this.tableObjectProperties : this.odidoTableObjectProperties;

    const sortField = tableProperties.find(x => sorting[x] !== false);
    const sortValue = sorting[sortField];

    this.getAllProducts(sortField, sortValue, value, searchField, searchPhrase);
  };

  onSortChange = ({target}) => {
    const headerName = target.getAttribute('headername');
    const propertyName = this.getHeaderPropertyForName(headerName);

    if (!propertyName) {
      return;
    }

    this.changeSortDirection(propertyName, this.getSortDirection(propertyName));
  };

  getSortDirection = (propertyName) => {
    const sortDirection = this.state.sorting[propertyName];

    return sortDirection === 'ASC' ? 'DESC' : (sortDirection === 'DESC' ? false : 'ASC');
  };

  changeSortDirection = (propertyName, direction) => {
    this.setState({sorting: {...this.sortInitialState, [propertyName]: direction}});

    this.getAllProducts(propertyName, direction, this.state.page, this.state.searchPhrase)
  };

  getHeaderPropertyForName = (displayName) => {
    const {pfsOverview} = this.state;

    if (pfsOverview) {
      const index = this.tableHeaderNames.indexOf(displayName);
      return this.tableObjectProperties[index];
    } else {
      const index = this.odidoTableHeaders.indexOf(displayName);
      return this.odidoTableObjectProperties[index];
    }
  };

  onExtend = (product) => {
    const {pfsOverview} = this.state;

    if (pfsOverview) {
      this.setState({selectedProduct: product});

      this.props.productActions.getProductDetails(product.id).then((response) => {
        if (response && response.productDetails) {
          this.setState({selectedProductDetails: response.productDetails});
        }
      });
    } else {
      this.setState({selectedOdidoProduct: product});

      this.props.productActions.getExtendedProductOdidoDetails(product.id).then((response) => {
        if (response && response.productDetails) {
          this.setState({selectedOdidoProductDetails: response.productDetails});
        }
      });
    }
  };

  onMinimize = () => {
    let {pfsOverview} = this.state;

    if (pfsOverview) {
      this.setState({selectedProduct: null, selectedProductDetails: null});
    } else {
      this.setState({selectedOdidoProduct: null, selectedOdidoProductDetails: null});
    }
  };


  onUpdateProductDetails = (productDetails) => {
    const {pfsOverview} = this.state;

    if (pfsOverview) {
      this.props.productActions.updateProductDetails(productDetails).then((response) => {
        if (response && response.success) {
          this.getAllProducts();
        }
      });
    } else {
      this.props.productActions.updateProductOdidoDetails(productDetails).then((response) => {
        if (response && response.success) {
          this.getAllProducts();
        }
      });
    }
  };

  onLoadApiRelations = (productID) => {
    this.setState({loading: true, pfsOverview: false});

    this.props.productActions.getProductOdidoDetails(productID).then((response) => {
      if (response && response.products) {
        this.setState({odidoProducts: response.products});
      }
    }).then(() => this.setState({loading: false}));
  };

  getTableAction = () => {
    const {pfsOverview, selectedProduct, selectedOdidoProduct, provider} = this.state;

    if (pfsOverview) {
      if (selectedProduct) {
        return `Details van het product ${selectedProduct.name}`;
      } else {
        return `Productlijst van ${ProviderHelper.getQuotationProviderName(provider)}-aanbieder`;
      }
    }

    if (selectedOdidoProduct) {
      return `API-gegevens van ${selectedOdidoProduct.name} product`;
    } else {
      return `De ${selectedProduct.name} API-relatielijst van het product`;
    }
  };

  onChangeOverview = () => {
    this.setState({pfsOverview: true, selectedOdidoProduct: null});
  };

  render() {
    const {allProducts, currentPage, pages} = this.props.products;
    const {providers} = this.props.authentication;
    let {
      searchPhrase,
      searchField,
      sorting,
      selectedProduct,
      selectedProductDetails,
      loading,
      provider,
      pfsOverview,
      odidoProducts,
      selectedOdidoProduct,
      selectedOdidoProductDetails
    } = this.state;
    let hasApiProducts = odidoProviders.includes(provider);

    let rows = pfsOverview ? allProducts : odidoProducts;
    let searchFields = pfsOverview ? this.searchFields : this.odidoSearchFields;

    return (
      <div>
        <div id="Forms" className="ratiotable paddingbottomnone">
          <h1 className="admin-subheader-text">
            <span className="ratio-section-title">Product overzicht</span>
          </h1>
        </div>

        <div className="overviewkopbg ratiotable row">
          <div className="col s6">
            <div style={{ top: '25px', position: 'relative'}}>
              <Input
                type="select"
                id="provider"
                name="provider"
                required={true}
                multiple={false}
                defaultValue={-1}
                className="validate"
                label="Producten leverancier"
                onChange={(e) => this.handleChangeProvider(e)}
              >
                <option value={-1} disabled={'disabled'} key={-1}>Kies provider</option>
                {
                  providers.filter((el => el.provider !== 1)).map((el) => {
                    return (
                      <option value={el.provider} key={el.provider}>
                        {ProviderHelper.getQuotationProviderName(el.provider)}
                      </option>
                    );
                  })
                }
              </Input>
            </div>
          </div>
          <div className="col s6">
            <div className="overviewkop">
              <form>
                <div style={{display: 'flex', top: '10px', position: 'relative'}}>
                  <div className="input-field margingone inner-addon">
                    <i className="glyphicon tiny material-icons left lightgrey">search</i>
                    <DelayInput
                      id="search"
                      type="search"
                      delayTimeout={800}
                      name="searchPhrase"
                      value={searchPhrase}
                      onChange={this.onSearch}
                    />
                  </div>
                  <Input
                    type="select"
                    required={true}
                    multiple={false}
                    id="searchField"
                    label="Zoek met"
                    name="searchField"
                    defaultValue={searchField}
                    className="validate"
                    onChange={(e) => this.handleChangeSearchField(e)}
                  >
                    {
                      searchFields.map((el) => {
                        return (
                          <option value={el.value} key={el.value}>
                            {el.name}
                          </option>
                        );
                      })
                    }
                  </Input>
                </div>
              </form>
            </div>
          </div>
        </div>

        {
          provider && (
            <div id="Forms" className="ratiotable paddingbottomnone">
              <h1 className="admin-subheader-text">
                <span className="ratio-section-title">{this.getTableAction()}</span>
              </h1>

              {
                !pfsOverview && (
                  <Button
                    waves="light"
                    className="doceri-btn-right"
                    onClick={() => this.onChangeOverview()}
                    style={{marginTop: '20px'}}
                  >
                    <i className="small material-icons left">undo</i>
                    Terug naar het pfs-overzicht
                  </Button>
                )
              }
            </div>
          )
        }

        {
          loading ? (
            <div
              id="Forms"
              className="ratiotable paddingbottomnone"
              style={{minHeight: '120px', textAlign: 'center', paddingTop: '20px'}}
            >
              <Preloader size='big'/>
            </div>
          ) : (
            <ProductsAdjustingGrid
              rows={rows}
              sortingState={sorting}
              onExtend={this.onExtend}
              pfsOverview={pfsOverview}
              onMinimize={this.onMinimize}
              hasApiProducts={hasApiProducts}
              onHeaderClick={this.onSortChange}
              selectedProduct={selectedProduct}
              headerNames={pfsOverview ? this.tableHeaderNames : this.odidoTableHeaders}
              onLoadApiRelations={this.onLoadApiRelations}
              selectedProductDetails={selectedProductDetails}
              selectedOdidoProduct={selectedOdidoProduct}
              selectedOdidoProductDetails={selectedOdidoProductDetails}
              onUpdateProductDetails={this.onUpdateProductDetails}
              getPropertyNameFromDisplayName={this.getHeaderPropertyForName}
            />
          )
        }

        {
          pfsOverview && (
            <div className="col s12 sendendform">
              <div className="col s12 m10">
                <Pagination
                  pages={pages}
                  page={currentPage}
                  changePage={this.changePage}
                />
              </div>
            </div>
          )
        }

      </div>
    )
  }
}

const mapStateToProps = ({products, authentication}) => {
  return {
    authentication,
    products
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    productActions: bindActionCreators(productActions, dispatch)
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductsAdjustingPanel));
