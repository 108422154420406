import {
    actionTypes
} from '../constants/actionTypes';

const initialState = {
    allTags: [],
    pages: 1,
    currentPage: 1,
    resultsCount: 0,
    categoriesToLink: []
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.getAllTags:
            return {
                ...state,
                allTags: action.payload
            };
        case actionTypes.savePagination:
            return {
                ...state,
                pages : action.payload.pages,
                currentPage: action.payload.currentPage,
                resultsCount: action.payload.resultsCount
            };
        case actionTypes.setTagCategoriesToLink:
            return {
                ...state,
                categoriesToLink: action.payload
            };
        case actionTypes.patchTag:
            return {
                ...state,
                allTags: state.allTags.map( (tag) => tag.id === action.payload.id ? action.payload : tag )
            };
        default:
            return state;
    }
}
