import React, {Component} from 'react';
import {Input, Preloader} from "react-materialize";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";

import ReactTable from 'react-table';
import {AutoSizer, Column, Table} from "react-virtualized";
import RegularSelect from "../../components/RegularSelect";
import "react-table/react-table.css";
import 'react-virtualized/styles.css';

import quotationActions from "../../actions/quotationActions";
import stepsActions from "../../actions/stepsActions";
import mobileActions from "../../actions/workflow/mobileActions";
import quotationType from "../../constants/quotationType";

import orderState from "../../constants/orderState";
import disabledOrderStates from "../../constants/disabledOrderStates";
import organization from "../../constants/organization";

class ExistingVoiceData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      overallStatus: {
        no_action: false,
        extend: false,
        upgrade: false,
        terminate: false,
        retention: false,
      },
      loading: false,
      dataRetrieval: false
    }
  }

  componentDidMount() {
    this.props.quotationActions.getVoiceProducts(this.props.quotationId).then(() => this.fixHeaderCheckbox())
    this.setState({dataRetrieval: this.props.dataRetrievalInProcess})
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data,
      });
    }

    if (prevProps.dataRetrievalInProcess !== this.props.dataRetrievalInProcess) {
      this.setState({dataRetrieval: this.props.dataRetrievalInProcess})
    }
  }

  switchKeys(product, column, data = null) {
    let value = data ? data[column] : !product[column];
    if (value === false) {
      value = null;
    }

    product['terminate'] = product['terminate'] === false ? false : null;
    product['upgrade'] = product['upgrade'] === false ? false : null;
    product['extend'] = product['extend'] === false ? false : null;
    product['retention'] = product['retention'] === false ? false : null;

    if (product['no_action']) {
      product['no_action'] = product['no_action'] === false ? false : null;
    }

    product[column] = value;

    if (!product['terminate'] && !product['upgrade'] && !product['extend'] && !product['no_action'] && !product['retention']) {
      product['no_action'] = true;
    }
  }

  toggleSelect(column, data) {
    let c2 = null;

    if (data['terminate'] === false) {
      c2 = 'terminate'
    }
    if (data['upgrade'] === false) {
      c2 = 'upgrade'
    }
    if (data['extend'] === false) {
      c2 = 'extend'
    }
    if (data['no_action'] === false) {
      c2 = 'no_action'
    }
    if (data['retention'] === false) {
      c2 = 'retention';
    }

    this.switchKeys(data, column);
    data[c2] = false;
    let payload = {category: false, data};
    this.setState({loading: true});

    this.props.quotationActions.updateVoiceProducts(payload, this.props.quotationId, true)
      .then(() => this.props.quotationActions.getVoiceProducts(this.props.quotationId))
      .then(() => this.fixHeaderCheckbox())
      .then(() => this.setState({loading: false}));
  };

  fixHeaderCheckbox() {
    let extendCount = 0;
    let upgradeCount = 0;
    let terminateCount = 0;
    let noActionCount = 0;
    let retentionCount = 0;
    let products = this.state.data.filter((el) => !disabledOrderStates.includes(el.order_state));
    let productsCount = products.filter((el) => !el.contract_end_date).length;

    products.filter((product) => !product.contract_end_date).forEach((el) => {
      if (el.extend) {
        extendCount++;
      } else if (el.upgrade) {
        upgradeCount++;
      } else if (el.terminate) {
        terminateCount++;
      } else if (el.no_action) {
        noActionCount++;
      } else if (el.retention) {
        retentionCount++;
      }
    });

    this.setState({
      overallStatus: {
        extend: extendCount === productsCount,
        upgrade: upgradeCount === productsCount,
        terminate: terminateCount === productsCount,
        no_action: noActionCount === productsCount,
        retention: retentionCount === productsCount,
      }
    })
  }

  toggleSelectAll(column, original = null) {
    this.setState({loading: true});

    let overallStatus = this.state.overallStatus;
    this.switchKeys(typeof original !== 'boolean' ? original : overallStatus, column);

    let data = this.state.data.filter((el) => el.id === original.id);
    if (typeof original === 'boolean') {
      data = this.state.data.filter((el) => !disabledOrderStates.includes(el.order_state))
        .map((el) => {
          if (el[column] !== false) {
            this.switchKeys(el, column, overallStatus);
          }

          return el;
        })
    }

    // Skip Telefooncentrale
    data = data.filter((el) => el.product_id !== '028A00003N');

    this.props.quotationActions.updateVoiceProducts({category: true, data}, this.props.quotationId, true)
      .then(() => this.setState({loading: false, overallStatus}))
      .then(() => this.fixHeaderCheckbox())
  }

  renderProductOptions = (data) => {
    let options = [];

    options.push(<option value="" key={0}/>);

    if (data && data.upgrade === true) {
      const upgradingData = this.props.upgradeData;

      const products = upgradingData.filter((item) => item.order_id === data.order_id);
      products.forEach(product => {
        options.push(
          <option
            key={product.id}
            value={product.product_id}
          >
            {product.product_name}
          </option>
        );
      });
    }

    return options;
  };

  onChange = (e, index, data) => {
    let {value} = e.target;

    if (value !== "") {
      const upgradingData = this.props.upgradeData;

      const product = upgradingData.find((item) => item.order_id === data.order_id && item.product_id === value);

      data['upgrade_product_id'] = product.product_id;
      data['upgrade_product_name'] = product.product_name;

      let payload = {category: false, data};

      this.props.quotationActions.updateAccessProducts(payload, this.props.quotationId, true)
        .then(() => this.props.quotationActions.getAccessProducts(this.props.quotationId))
    }
  };

  render() {
    const {data, inputsDisabled} = this.props;
    const {company} = this.props.authentication;
    const isVerlengenType = this.props.quotation.currentQuotation.type === quotationType.TYPE_VERLENGEN;

    let statuses = {
      no_action: 'Geen actie',
      extend: 'Verlengen',
      terminate: 'Opzeggen',
      retention: 'Retentie'
    };
    let overallStatus = this.state.overallStatus;

    if ([organization.SV, organization.KPN_RETAIL].includes(company)) {
      delete statuses.terminate;
      delete statuses.extend;
    } else {
      delete statuses.retention;
    }

    let columns = [
      {
        id: 'label',
        accessor: 'label',
        Cell: ({original, index}) => {
          return (
            <span>
              {`${original.label || ''}`}
            </span>
          )
        },
        Header: 'Kenmerk',
        headerClassName: 'highlight',
        headerStyle: {textAlign: 'left'},
        maxWidth: 200,
        textAlign: 'left',
        fontWeight: 'bold',
        sortable: false,
      },
      {
        id: 'product',
        Cell: ({original, index}) => {
          return (
            <div>
              {!original.upgrade ?
                <span style={{
                  fontStyle: `${!original.parent_id ? 'normal' : 'italic'}`
                }}>
                  {original.quantity > 1 && `(${original.quantity}x) `}
                  {original.product_name}
                </span> :
                <RegularSelect
                  s={12}
                  name="product"
                  onChange={(event) => this.onChange(event, index, original)}
                  value={original.upgrade_product_id || original.product_id}
                  disabled={inputsDisabled}
                  multiple={false}
                >
                  {this.renderProductOptions(original)}
                </RegularSelect>
              }
            </div>
          )
        },
        Header: 'Product',
        headerClassName: 'highlight',
        headerStyle: {textAlign: 'left'},
        maxWidth: 400,
        textAlign: 'left',
        fontWeight: 'bold',
        resizable: false,
        sortable: false,
      },
      {
        id: 'irma_order_status',
        accessor: 'irma_order_status',
        Cell: ({original}) => {
          return (<span>{original.order_state || ''}</span>)
        },
        Header: 'Irma Order status',
        headerClassName: 'highlight',
        headerStyle: {textAlign: 'left'},
        maxWidth: 200,
        textAlign: 'left',
        fontWeight: 'bold',
        sortable: false,
      },
    ];

    for (let checkboxKey in statuses) {

      columns.push({
        id: checkboxKey,
        accessor: "",
        Cell: ({original, index}) => {
          const hasParent = !!original.parent_id;
          const hasTerminateState = original.order_state === orderState.ORDER_STATE_TERMINATE;

          let parent = null;
          if (hasParent) {
            parent = data.find(el => el.order_id === original.parent_id);
          }

          if (hasTerminateState && checkboxKey !== 'no_action') {
            return <span/>;
          }

          let disableCondtion = isVerlengenType && hasParent && checkboxKey !== 'terminate' && parent && parent[checkboxKey] !== true;

          return (
            <Input
              type="checkbox"
              name={`${checkboxKey}-${original.id}`}
              label=" "
              className="checkbox"
              disabled={inputsDisabled || disableCondtion}
              checked={original[checkboxKey] === true}
              onChange={() => isVerlengenType ? this.toggleSelectAll(checkboxKey, original) : this.toggleSelect(checkboxKey, original)}
            />
          );
        },
        Header: () => {
          return (
            <div>
              <div>
                <Input
                  type="checkbox"
                  label={statuses[checkboxKey]}
                  name={`all_${checkboxKey}`}
                  className="checkbox"
                  disabled={inputsDisabled}
                  checked={overallStatus[checkboxKey]}
                  onChange={() => this.toggleSelectAll(checkboxKey, overallStatus[checkboxKey])}
                />
              </div>
            </div>
          );
        },
        sortable: false,
        resizable: false,
        className: 'col-checkbox',
        headerClassName: 'head-checkbox',
        minWidth: 40,
      });
    }

    return (
      <div style={{height: '600px'}}>
        {(this.state.dataRetrieval || this.state.loading) &&
        (
          <div className="loader-component" style={{zIndex: 99, top: "10%"}}>
            <div className="overlay" style={{backgroundColor: `rgba(255, 255, 255, 0.3)`}}/>
            <Preloader size='medium'/>
          </div>
        )
        }
        {
          isVerlengenType ?
            <AutoSizer>
              {({width, height}) => (
                <Table
                  width={width}
                  height={height}
                  headerHeight={40}
                  rowHeight={40}
                  rowCount={data.length}
                  rowGetter={({index}) => data[index]}
                  className='striped'
                >
                  <Column
                    label='Kenmerk'
                    dataKey='label'
                    width={width / 4}
                  />
                  <Column
                    width={width / 2}
                    label='Product'
                    dataKey='product_name'
                    style={{fontStyle: 'italic'}}
                  />
                  <Column
                    width={width / 5}
                    label={<div className="irma-status-label">IRMA<br/>Order status</div>}
                    dataKey='translated_action'
                    cellRenderer={({rowData}) => {
                      return <div>{rowData.translated_action}</div>
                    }}
                  />
                  {
                    Object.keys(statuses).map((checkboxKey) => (
                      <Column
                        width={width / 5}
                        label='Acties'
                        headerRenderer={() => (
                          <div className="header-cell-container">
                            <span className="header-identifier">{statuses[checkboxKey]}</span>
                            <Input
                              type="checkbox"
                              label={<div className="check-all-label">Alles</div>}
                              name={`all_${checkboxKey}`}
                              className="checkbox"
                              disabled={inputsDisabled}
                              checked={overallStatus[checkboxKey]}
                              onChange={() => this.toggleSelectAll(checkboxKey, overallStatus[checkboxKey])}
                            />
                          </div>
                        )}
                        cellRenderer={({rowData}) => {
                          const hasParent = !!rowData.parent_id;
                          if (disabledOrderStates.includes(rowData.order_state)) {
                            return <span/>;
                          }

                          let parent = null;
                          if (hasParent && isVerlengenType) {
                            parent = data.find(el => el.order_id === rowData.parent_id);
                          }

                          if (['028A00003N', '028A00005N'].includes(rowData.product_id)) {
                            return <span/>;
                          }
                          if (parent && !['028A00003N', '028A00005N'].includes(parent.product_id)) {
                            return <span/>;
                          }
                          if (rowData.upgrade === false && checkboxKey === 'upgrade') {
                            return <span/>;
                          }

                          let disableCondition = isVerlengenType && hasParent && checkboxKey !== 'terminate' && parent && parent[checkboxKey] !== true;
                          return (
                            <div style={{'marginTop': '9px'}}>
                              <Input
                                type="checkbox"
                                name={`${checkboxKey}-${rowData.id}`}
                                label=" "
                                className="checkbox"
                                // disabled={inputsDisabled || disableCondition}
                                disabled={inputsDisabled}
                                checked={rowData[checkboxKey] === true}
                                onChange={() => isVerlengenType ? this.toggleSelectAll(checkboxKey, rowData) : this.toggleSelect(checkboxKey, rowData)}
                              />
                            </div>
                          );
                        }}
                      />
                    ))
                  }
                </Table>
              )}
            </AutoSizer> :
            <ReactTable
              data={data}
              resolveData={data => data.map(row => row)}
              columns={columns}
              minRows={0}
              defaultPageSize={999}
              showPagination={false}
              style={{border: 'none'}}
              className="responsive-table -striped"
              getTbodyProps={() => {
                return {
                  style: {
                    overflow: 'visible',
                    textAlign: 'left',
                  }
                }
              }}
            />
        }
      </div>
    );
  }
}

const mapStateToProps = ({quotation, hardware, mobile, authentication}) => {
  return {
    quotationId: quotation.currentQuotation.id,
    customHardwareOptions: hardware.customHardwareOptions,
    mobileOfferYear: quotation.currentQuotation.mobileOfferYear,
    devicePaymentOptions: mobile.devicePaymentOptions,
    quotation,
    authentication: authentication,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    quotationActions: bindActionCreators(quotationActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
    mobileActions: bindActionCreators(mobileActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ExistingVoiceData);
