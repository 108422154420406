import React from 'react';
import DialogHeader from "./KpnDialog/DialogHeader";
import DialogBody from "./KpnDialog/DialogBody";
import DialogFooter from "./KpnDialog/DialogFooter";
import DialogButton from "./KpnDialog/DialogButton";
import KpnDialog from "./KpnDialog/KpnDialog";

const cleanInputProps = (inputProps) => {
  return Object.keys(inputProps).reduce((object, key) => {
    if (inputProps[key] !== undefined) {
      object[key] = inputProps[key];
    }
    return object;
  }, {});
};

const GeneralDialog = ({
  dialogState,
  onRequestClose,
  modal,
  style,
  overflowException,
  closeHandler,
  hasVerlengenAction,
  headerIcon,
  headerText,
  headerSubtext,
  width,
  fixedWidth,
  dialogBodyContent,
  dialogBodyContentRaw,
  leftButtonAction,
  leftButtonText,
  centerButtonAction,
  centerButtonText,
  rightButtonAction,
  rightButtonText,
  leftButtonLeft,
  leftButtonRight,
  centerButtonLeft,
  centerButtonRight,
  rightButtonLeft,
  rightButtonRight,
  dialogFooterChildren
}) => {
  let mainProps = {
    dialogState: dialogState,
    onRequestClose: onRequestClose,
    modal: modal,
    width: width,
    overflowException: overflowException,
    fixedWidth: fixedWidth,
    style: style
  };

  let dialogHeaderProps = {
    closeHandler: closeHandler,
    hasVerlengenAction: hasVerlengenAction,
    headerIcon: headerIcon,
    headerText: headerText,
    headerSubtext: headerSubtext
  };

  let dialogLeftButtonProps = {
    buttonAction: leftButtonAction,
    buttonText: leftButtonText,
    left: leftButtonLeft,
    right: leftButtonRight
  };

  let dialogRightButtonProps = {
    buttonAction: rightButtonAction,
    buttonText: rightButtonText,
    left: rightButtonLeft,
    right: rightButtonRight
  };

  let dialogCenterButtonProps = {
    buttonAction: centerButtonAction,
    buttonText: centerButtonText,
    left: centerButtonLeft,
    right: centerButtonRight
  };

  dialogHeaderProps = cleanInputProps(dialogHeaderProps);
  dialogLeftButtonProps = cleanInputProps(dialogLeftButtonProps);
  dialogRightButtonProps = cleanInputProps(dialogRightButtonProps);
  dialogCenterButtonProps = cleanInputProps(dialogCenterButtonProps);

  let dialogFooterProps = {};

  if (Object.keys(dialogLeftButtonProps).length !== 0) {
    dialogFooterProps.buttonLeft = (<DialogButton {...dialogLeftButtonProps} />);
  }

  if (Object.keys(dialogRightButtonProps).length !== 0) {
    dialogFooterProps.buttonRight = (<DialogButton {...dialogRightButtonProps} />);
  }

  if (Object.keys(dialogCenterButtonProps).length !== 0) {
    dialogFooterProps.buttonCenter = (<DialogButton {...dialogCenterButtonProps} />);
  }

  if (dialogFooterChildren) {
    dialogFooterProps.children = dialogFooterChildren;
  }

  if (dialogHeaderProps) {
    mainProps.dialogHeader = (<DialogHeader {...dialogHeaderProps} />);
  }

  if (dialogBodyContent && !dialogBodyContentRaw) {
    mainProps.dialogBody = (<DialogBody> {dialogBodyContent} </DialogBody>);
  }

  if (dialogBodyContentRaw && !dialogBodyContent) {
    mainProps.dialogBody = {...dialogBodyContentRaw}
  }

  if (dialogFooterProps) {
    mainProps.dialogFooter = (<DialogFooter {...dialogFooterProps}/>);
  }

  mainProps = cleanInputProps(mainProps);

  return (
    <KpnDialog
      {...mainProps}
    />
  );
};

GeneralDialog.defaultProps = {
  modal: true,
  autoScrollBodyContent: true,
  onRequestClose: () => {
  },
};

export default GeneralDialog;
