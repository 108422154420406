import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom'

import organizationActions from '../../../actions/organizationActions';

import InternalTable from "../../../components/internalTable";
import {DelayInput} from "react-delay-input";
import Pagination from '../../../components/pagination/pagination';
import OrganizationForm from "./OrganizationForm";
import configGeneral from "../../../config/general";
import authenticationActions from "../../../actions/authenticationActions";

const { providersList } = configGeneral;

class OrganizationsPanel extends Component {
  organizationInitial = {
    id: 0,
    name: '',
    customWebUrl: '',
    crmConnection: '',
    providers: [],
    allowDigitalSigning: false,
    isTermsAndCondition: false
  };

  sortInitialState = {
    name: false,
    createdAt: false,
    updatedAt: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isEdition: false,
      organizationDialogOpened: false,
      organizationId: 0,
      searchPhrase: '',
      allOrganizations: [],
      sorting: {
        ...this.sortInitialState
      },
      page: 1
    };
  };

  componentDidMount() {
    this.getAllOrganizations();
  };

  getAllOrganizations = (sortField, direction, page, searchQuery) => {
    let queryParams = '';
    if (sortField && direction) {
      queryParams += '?sortField=' + sortField;
      queryParams += '&sortDirection=' + direction;
    }

    if (page) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?')
      queryParams += 'page=' + page
    }
    if (searchQuery) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?')
      queryParams += 'searchQuery=' + searchQuery;
    }

    this.props.organizationActions.getAllOrganizations(queryParams).then(() => {
      this.setState({
        allOrganizations: this.props.organizations.allOrganizations
      });
    });
  };

  changePage = ({target}) => {
    const {value} = target;
    this.setState({page: value});

    const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false);
    const sortValue = this.state.sorting[sortField];

    this.getAllOrganizations(sortField, sortValue, value, this.state.searchPhrase);
  };

  onAddClick = () => {

  };

  onSearch = ({target}) => {
    let {name, value} = target;

    const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false);
    const sortValue = this.state.sorting[sortField];

    this.setState({searchPhrase: value});

    this.getAllOrganizations(sortField, sortValue, this.state.page, value)
  };

  onSortChange = ({target}) => {
    const headerName = target.getAttribute('headername');
    const propertyName = this.getHeaderPropertyForName(headerName);

    if (!propertyName) {
      return;
    }

    this.changeSortDirection(propertyName, this.getSortDirection(propertyName));
  };

  getSortDirection = (propertyName) => {
    const sortDirection = this.state.sorting[propertyName];
    switch (sortDirection) {
      case false:
        return "ASC";
      case "ASC":
        return "DESC";
      case "DESC":
        return false
    }
  };

  changeSortDirection = (propertyName, direction) => {
    this.setState({sorting: {...this.sortInitialState, [propertyName]: direction}});

    this.getAllOrganizations(propertyName, direction, this.state.page, this.state.searchPhrase)
  };

  getHeaderPropertyForName = (displayName) => {
    const index = this.tableHeaderNames.indexOf(displayName);
    return this.tableObjectProperties[index];
  };

  onEditClick = (row) => {
    this.setState({
      isEdition: true,
      organizationDialogOpened: true,
      organizationId: row.id,
    });
  };

  handleDialogClose = (stateProperty) => {
    this.setState({
      [stateProperty]: false
    })
  };

  handleOrganizationDialogSubmit = (organization) => {
    if (this.state.isEdition) {
      this.props.organizationActions.editOrganization(this.state.organizationId, organization)
        .then(() => this.setState({ organizationDialogOpened: false }))
        .then(() => this.props.authenticationActions.getUserInfo());
    }
  };

  getCurrentOrganization = () => {
    if (this.state.isEdition) {
      return this.props.organizations.allOrganizations.find(el => el.id === this.state.organizationId);
    }

    return this.organizationInitial;
  };

  getActionsColumnForTable = (row) => {
    return (
        <div>
          <a href="#" onClick={() => this.onEditClick(row)}>
            <i className="small material-icons left themeprimarycolor">edit</i>
          </a>
        </div>
    );
  };

  tableHeaderNames = ["Organisatie naam", "Contactpersoon", "Aangemaakt", "laatst gewijzigd", "Portfolios", "Custom hostname", "Acties"];
  tableObjectProperties = ['name', 'Contactpersoon', 'createdAt', 'updatedAt', 'providersFormatted', 'customWebUrl'];

  render() {

    let actions = this.getActionsColumnForTable;
    let {searchPhrase, allOrganizations} = this.state;

    return (
        <div>
          <div className="ratiotable paddingbottomnone Forms">
            <h1 className="admin-subheader-text">
              <span className="ratio-section-title">Organisatie overzicht</span>
            </h1>
          </div>

          <div className="overviewkopbg ratiotable row">
            <div className="col s3">
              <div className="overviewkop">
                <form>
                  <div className="input-field margingone inner-addon">
                    <i className="glyphicon tiny material-icons left lightgrey">search</i>
                    <DelayInput delayTimeout={800} id="search" type="search" name="searchPhrase"
                                value={searchPhrase} onChange={this.onSearch}/>
                  </div>
                </form>
              </div>
            </div>
            <div className="col s9">
              <button
                  className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
                  onClick={() => this.onAddClick()}
                  type="submit"
                  name="action">
                <i className="small material-icons left white-text">add</i>
                Nieuwe organisatie
              </button>
            </div>
          </div>

          <InternalTable headersNames={this.tableHeaderNames}
                         propertiesToShow={this.tableObjectProperties}
                         rows={allOrganizations}
                         onHeaderClick={this.onSortChange}
                         sortingState={this.state.sorting}
                         actionsColumn={actions}
                         getPropertyNameFromDisplayName={this.getHeaderPropertyForName}/>

          {this.state.organizationDialogOpened &&
            <OrganizationForm
              onSubmit={this.handleOrganizationDialogSubmit}
              onCancel={() => this.handleDialogClose('organizationDialogOpened')}
              organization={this.getCurrentOrganization()}
              providersList={providersList}
            />
          }

          <div className="col s12 sendendform">
            <div className="col s12 m10">
              <Pagination page={this.props.organizations.currentPage}
                          pages={this.props.organizations.pages}
                          changePage={this.changePage}
              />
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = ({ organizations }) => {
  return {
    organizations
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    organizationActions: bindActionCreators(organizationActions, dispatch),
    authenticationActions: bindActionCreators(authenticationActions, dispatch)
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationsPanel));
