import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-materialize';
import './scrollTopButton.css';

class ScrollTopButton extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false,
    };

    this.intervalId = 0;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 0) {
      this.setState({
        visible: true,
      });
    } else {
      this.setState({
        visible: false,
      });
    }
  };

  scrollStep = () => {
    if (window.scrollY === 0) {
        clearInterval(this.intervalId);
    }
    window.scroll(0, window.scrollY - this.props.scrollStepInPx);
  };

  scrollToTop = () => {
    this.intervalId = setInterval(this.scrollStep, this.props.delayInMs);
  };

  handleClick = () => {
    this.scrollToTop();
  };

  render() {
    const visibleClassName = this.state.visible ? '' : ' scroll-top-button--hidden';

    return (
      <Button
        floating
        large
        className={`scroll-top-button${visibleClassName}`}
        waves="light"
        icon="arrow_upward"
        onClick={this.handleClick}
        ref={(el) => { this.buttonElement = el; }}
        tooltip="Terug naar boven"
        tooltipoptions={{ position: 'left' }}
      />
    )
  }
}

ScrollTopButton.defaultProps = {
  scrollStepInPx: 50,
  delayInMs: 16,
}

ScrollTopButton.propTypes = {
  scrollStepInPx: PropTypes.number,
  delayInMs: PropTypes.number,
}

export default ScrollTopButton;
