import React, {Component} from 'react';
import { Preloader } from 'react-materialize';
import ratioTheme from "../../constants/theme";
import '../loaderComponent.css';

class Loader extends Component {

  render() {
    const { theme } = this.props;
    const opacity = theme.hasOwnProperty('backgroundOpacity') ?
                      1 - theme.backgroundOpacity :
                      1 - ratioTheme.backgroundOpacity;

    return (
      <div
        className="loader-component"
        style={{
          backgroundImage: (theme.hasOwnProperty('background') && theme.background) ?
            `url(${theme.background})` :
            `url(${ratioTheme.background})`
        }}
      >
        <div
          className="overlay"
          style={{ backgroundColor: `rgba(255, 255, 255, ${opacity})` }}
        />
        <Preloader size='big'/>
      </div>
    )
  }
}

export default Loader;