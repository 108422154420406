import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import accessActions from "../../../actions/workflow/accessActions";
import stepsActions from '../../../actions/stepsActions';
import locationActions from '../../../actions/locationActions';
import connectionsActions from '../../../actions/connectionsActions';
import hardwareActions from "../../../actions/hardwareActions";

import ConnectionFormForAccessLocation from './connectionFormForAccessLocation';
import AccessLocationSdWan from './accessLocationSdWan';
import ConnectionAvailability from './connectionAvailability';
import ExistingAccessData from "../../common/ExistingAccessData";
import Loader from '../../../containers/common/loader';

import DialogBody from "../../../containers/common/KpnDialog/DialogBody";

import {toastr} from 'react-redux-toastr';
import {Col, Row} from "react-materialize";

import 'rc-slider/assets/index.css';

import quotationType from "../../../constants/quotationType";
import providerType, {odidoProviders} from "../../../constants/providerType";
import GeneralDialog from "../../../containers/common/GeneralDialog";
import DefaultDialogBody from "../../../containers/common/KpnDialog/DefaultDialogBody";

class AccessLocationForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showConnections: false,
      connectionDialogOpen: false,
      connectionType: null,
      confirmed: null,
      connectionId: null,
      isUpgrade: false,
      ftthExceptionDialogOpen: this.props.access.ftthException || false,
    };
  }

  componentDidMount() {
    const {location, getLocationOptions, quotation} = this.props;
    const isVodafone = quotation.currentQuotation.provider === providerType.VODAFONE;

    getLocationOptions(location.id);

    if (isVodafone && location.id > 0) {
      this.props.locationActions.getLocationSdWanOptions(location.id);
    }

    this.props.hardwareActions.getAllCustomHardwareOptionsSelections(this.props.quotationId);

    this.props.hardwareActions.getCustomHardwareOptions('access', this.props.quotationId);
    this.props.hardwareActions.getCustomHardwareOptionsSelections(this.props.quotationId, 'access', location.id);

    this.props.accessActions.getOrganizationHipAllow(this.props.quotationId);
  }

  componentDidUpdate(prevProps) {
    const {availableToGoForward: availableToGoForwardPrev} = prevProps;
    const {availableToGoForward, location, quotation, getLocationOptions} = this.props;
    const isVodafone = quotation.currentQuotation.provider === providerType.VODAFONE;

    if (availableToGoForwardPrev !== availableToGoForward) {
      this.props.stepsActions.setAvailableToGoForward(this.isAvailableToGoForward());
    }

    if (prevProps.location.id !== location.id) {
      getLocationOptions(location.id);
      if (isVodafone && location.id > 0) {
        this.props.locationActions.getLocationSdWanOptions(location.id);
      }

      this.props.hardwareActions.getAllCustomHardwareOptionsSelections(this.props.quotationId);
      this.props.hardwareActions.getCustomHardwareOptionsSelections(this.props.quotationId, 'access', location.id);

      this.props.connectionsActions.updateFtthConnectionException({ftthException: false});
    }

    if (prevProps.access.ftthException !== this.props.access.ftthException) {
      this.setState({ftthExceptionDialogOpen: this.props.access.ftthException});
    }
  }

  isAvailableToGoForward() {
    const locationsWithoutSelectedConnection = this.props.locations.allLocations.filter(location => (
      location.id > 0 && location.connections.length > 0 && location.connections[0].selected_connections.length === 0
    ));

    const currentQuotationIsMigration = this.props.quotation.currentQuotation.isMigration;

    return (!currentQuotationIsMigration || (locationsWithoutSelectedConnection.length === 0));
  }

  onConnectionInputChange = ({target}, connectionId, isCheckbox = false, vlanOptions, index) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }

    let {name, value} = target;
    name = name.split('-')[0];

    if (target.type === 'number' && value !== '' && !value.match(/^\d+$/)) {
      toastr.error("Can't specify negative values - to be translated");
      return;
    }

    let selectedLocation = this.props.locations.selectedLocation;
    let locationId = selectedLocation.id;
    let quotation = this.props.quotation.currentQuotation;
    let quotationId = quotation.id;

    if (isCheckbox) {
      value = target.checked;
    } else if (vlanOptions && index !== false) {
      value = vlanOptions;
      value[index] = target.value;
    }

    let productId = parseInt(value);
    if (productId && [quotationType.TYPE_VERLENGEN, quotationType.TYPE_STANDARD, quotationType.TYPE_QA, quotationType.TYPE_KOOP, quotationType.TYPE_HUUR, quotationType.TYPE_VERGELIJKING].includes(quotation.type)) {
      let connection = this.props.connections.connectionsListPerSelectedLocation.find((el) => el.id === productId);

      if (connection) {
        this.props.accessActions.checkConnectionTypeRequired(connectionId, productId).then((response) => {
          if (response) {
            this.setState({connectionDialogOpen: response, connectionId: connectionId});
          }
        });
      }
    }

    this.props.accessActions.updateConnection(name, value, connectionId, locationId, quotationId).then(() => {
      // Run check after quotation is updated with new connection
      if (name === 'selection_connection') {
        this.checkConnectionAllowed(connectionId, value);
        this.props.connectionsActions.getNtAllowed(connectionId, value);
        this.props.connectionsActions.getFtthConnectionCheck(connectionId, value);
        this.props.accessActions.getAccessStepState(quotationId);
      }
    });

    if (name === 'selection_connection') {
      this.props.locationActions.setQuotationLocations(quotationId);
    }
  };

  onLocationInputChange = ({target}, isCheckbox = false) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }
    let {name, value} = target;
    name = name.split('-')[0];

    let locationId = this.props.locations.selectedLocation.id;
    let quotationId = this.props.quotation.currentQuotation.id;

    if (isCheckbox) {
      value = target.checked;
    }

    this.props.accessActions.updateLocation(name, value, locationId, quotationId);
  };

  generateDropdownOptions = (propertyName, connectionId) => {
    let locationId = this.props.locations.selectedLocation.id;
    let options = this.props.access.locationsAccessOptions.find(x => x.locationId === locationId && x.connectionId === connectionId);

    const connection = this.props.formState.connections.find(x => x.id === connectionId);
    if (!options)
      return (<option/>); //what to do here?

    const formOptions = options.formOptions.find(x => x.name === propertyName);
    let result = [];

    if (!formOptions)
      return result;

    const {enumValues, enumCaptions} = formOptions.dropdownConfiguration;

    result.push(<option key={-1} value="">Selecteer een optie</option>);
    for (let i = 0; i < enumValues.length; i++) {
      let name;
      if (propertyName === 'selection_connection' && this.shouldDisplayFtthWarning() && enumCaptions[i].match(/^FTTH/)) {
        name = enumCaptions[i] + ' (bezet)'
      } else {
        name = enumCaptions[i];
      }

      if (propertyName === 'selection_connection' && connection.custom_connection && name.match(/^Data Only.*/)) {
        continue;
      }

      result.push(<option key={name} value={enumValues[i]}>{name}</option>)
    }

    return result;
  };

  hasConnectionOptions = (propertyName, connectionId) => {
    let locationId = this.props.locations.selectedLocation.id;
    let options = this.props.access.locationsAccessOptions.find(optionList => optionList.locationId === locationId
      && optionList.connectionId === connectionId);

    if (!options) {
      return false;
    }

    const formOptions = options.formOptions.find(option => option.name === propertyName);

    if (!formOptions) {
      return false;
    }

    const {enumValues} = formOptions.dropdownConfiguration;

    return enumValues.length > 0;
  };

  generateRetentionOptions = (optionRendering = true) => {
    let locationId = this.props.locations.selectedLocation.id;
    let options = this.props.access.locationsAccessOptions.find(x => x.locationId === locationId);
    let connectionId = options.connectionId;

    const connection = this.props.formState.connections.find(x => x.id === connectionId);

    let result = [];
    let productOptions = [];
    result.push(<option key={-1} value="">Selecteer een optie</option>);

    if (connection.product) {
      let connectionProduct = connection.product;
      result.push(<option key={connectionProduct.product_name} value={connectionProduct.product_id}>{connectionProduct.product_name}</option>);
      productOptions.push(connectionProduct);
    }

    if (!options) {
      return optionRendering ? result : productOptions;
    }

    const formOptions = options.formOptions.find(x => x.name === 'selection_connection');
    if (!formOptions) {
      return optionRendering ? result : productOptions;
    }

    const {enumValues, enumCaptions} = formOptions.dropdownConfiguration;
    for (let i = 0; i < enumValues.length; i++) {
      let name;
      if (this.shouldDisplayFtthWarning() && enumCaptions[i].match(/^FTTH/)) {
        name = enumCaptions[i] + ' (bezet)'
      } else {
        name = enumCaptions[i];
      }

      if (connection.custom_connection && name.match(/^Data Only.*/)) {
        continue;
      }

      result.push(<option key={name} value={enumValues[i]}>{name}</option>);
      productOptions.push({product_id: enumValues[i], product_name: name});
    }

    return optionRendering ? result : productOptions;
  };

  setLoading = (loading) => {
    this.setState({
      loading: loading
    })
  };

  toggleConnectionsDisplay = () => {
    this.setState({
      showConnections: !this.state.showConnections
    })
  };

  shouldDisplayFtthWarning = () => {
    return this.props.connections.results.reduce((accumulator, value) => {
      return value.provider === "FttH NLS type" && value.technology === 6 && value.occupied === true;
    }, false);
  };

  checkConnectionAllowed = (connectionId, productId) => {
    if (!productId) {
      this.props.stepsActions.setAvailableToGoForward(false);
      return;
    }
    this.props.accessActions.getConnectionAllowed(connectionId, productId).then(() => {
      //Disable navigation on error
      if (this.props.access.connectionErrors.find(error => error.success === false)) {
        this.props.stepsActions.setAvailableToGoForward(false);
        return;
      }

      this.props.stepsActions.setAvailableToGoForward(true);
    });
  };

  closeConnectionDialog = () => {
    this.setState({connectionDialogOpen: false});
  };

  closeFtthExceptionDialog = () => {
    this.props.connectionsActions.updateFtthConnectionException({ftthException: false});
    this.setState({ftthExceptionDialogOpen: false});
  };

  onChangeConnectionType = (value) => {
    if (value === 1) {
      this.saveConnectionType(false, value);
    } else {
      this.setState({connectionType: value});
    }
  };

  saveConnectionType = (value, connectionType = null) => {
    let connectionId = this.state.connectionId;
    let data = {
      connectionType: connectionType ? connectionType : this.state.connectionType,
      confirmationType: value
    };

    this.props.accessActions.updateConnectionType(data, connectionId);
    this.setState({
      connectionDialogOpen: false,
      connectionId: null,
      connectionType: null,
      confirmationType: null,
      isUpgrade: false
    });
  };

  savePositiveConnectionType = () => {
    this.saveConnectionType(true);
  };

  saveNegativeConnectionType = () => {
    this.saveConnectionType(false);
  };

  renderConnectionTypeQuestion = () => {
    let connectionType = parseInt(this.state.connectionType);
    let wsoQuestion = 'Moet de installatie buiten kantoortijden plaatsvinden?';
    let upgradeQuestion = 'Vind de upgrade binnen de huidige contractsperiode plaats?';

    if (![2, 3].includes(connectionType) && !this.state.isUpgrade) {
      return;
    }

    let isWsoMigration = this.state.connectionType === 2;

    return (
      <div style={{ marginTop: "30px" }}>
        <span>{connectionType === 2 ? wsoQuestion : upgradeQuestion}</span>

        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
          <button
            style={{ width: "35%" }}
            className="btn doceri-btn-right ratio-btn-white-right"
            name="action"
            onClick={() => this.savePositiveConnectionType()}
          >
            {isWsoMigration ? 'Ja, buiten kantooruren' : 'Ja, binnen contractsduur'}
          </button>

          <button
            style={{ width: "35%" }}
            className="btn doceri-btn-right ratio-btn-white-right"
            name="action"
            onClick={() => this.saveNegativeConnectionType()}
          >
            {isWsoMigration ? 'Nee, binnen kantooruren' : 'Nee, buiten contractsduur'}
          </button>
        </div>
      </div>
    );
  };

  renderDialogButtons = () => {
    let isWsoMigration = this.state.connectionType === 2;

    return (
      <div style={{ display: "flex", justifyContent: (this.state.isUpgrade? "center" : "space-between") }}>
        {! this.state.isUpgrade  &&
          (
            <button
              style={{ width: "30%" }}
              className={"btn doceri-btn-right ratio-btn-white-right " + (isWsoMigration ? "test" : '')}
              name="connectionTypeOption2"
              onClick={() => this.onChangeConnectionType(2)}
            >
              Ja, WSO Migratie
            </button>
          )
        }

        <button
          style={{ width: "30%" }}
          className="btn doceri-btn-left ratio-btn-white-right"
          name="connectionTypeOption3"
          onClick={() => this.onChangeConnectionType(3)}
          disabled={this.state.isUpgrade}
        >
          Ja, upgrade
        </button>

        {! this.state.isUpgrade  &&
          (
            <button
              style={{ width: "30%" }}
              className="btn doceri-btn-right ratio-btn-white-right"
              name="connectionTypeOption1"
              onClick={() => this.onChangeConnectionType(1)}
            >
              Nee, nieuwe aanleg
            </button>
          )
        }
      </div>
    );
  };

  triggerConnectionType = (connectionId) => {
    this.setState({connectionDialogOpen: true, connectionId: connectionId, isUpgrade: true, connectionType: 3});
  };

  changeAccessType = (locationId, value) => {
    this.props.accessActions.changeAccessType(locationId, value);
  };

  renderConnections = (formState, allConnections, currentLocation, location, locationOptions, updateConnectionOptions, isVerlengenType, locationData) => {
    if (!formState) {
      return (<div>Er is nog geen connectie voor deze locatie</div>);
    }

    return allConnections.map(connection =>
        <ConnectionFormForAccessLocation
          key={connection.id}
          hasConnectionOptions={this.hasConnectionOptions}
          generateDropdownOptions={this.generateDropdownOptions}
          onInputChange={this.onConnectionInputChange}
          checkConnectionAllowed={this.checkConnectionAllowed}
          errors={this.props.access.connectionErrors}
          currentLocation={currentLocation}
          onLocationInputChange={this.onLocationInputChange}
          selectedLocationId={this.props.locations.selectedLocation.id}
          location={location}
          connectionData={locationOptions[connection.id] || {}}
          connection={connection}
          updateConnectionOptions={updateConnectionOptions}
          quotationId={this.props.quotationId}
          quotation={this.props.quotation.currentQuotation}
          isVerlengenType={isVerlengenType}
          existingAccessData={locationData}
        />
      );
  };

  renderSdWanForm = (currentLocation) => {
    return (
      <AccessLocationSdWan
        locationId={currentLocation.id}
        locationSdWanOptions={currentLocation.locationSdWanOptions}
        inputsDisabled={this.props.quotation.currentQuotation.inputsDisabled}
      />
    );
  };

  renderLocationConfiguration = (formState, allConnections, currentLocation, location, locationOptions, updateConnectionOptions, isVerlengenType, locationData, isVodafone) => {
    if (!isVodafone || (isVodafone && currentLocation.sdWanActive === false)) {
      return this.renderConnections(formState, allConnections, currentLocation, location, locationOptions, updateConnectionOptions, isVerlengenType, locationData);
    }

    if (isVodafone && !currentLocation.sdWanActive) {
      return;
    }

    return this.renderSdWanForm(currentLocation);
  };

  getFtthExceptionMessage = () => {
    let currentLocation = this.props.locations.selectedLocation;

    switch (currentLocation.ftth_nls_type) {
      case '7':
        return 'De glasvezel ligt tot aan de voorgevel. De glasvezelkabel ligt onder de grond tot aan de voorgevel van uw woning.' +
          ' Deze moet nog ingevoerd en afgewerkt worden met een FTU. Er zijn werkzaamheden op klantlocatie nodig om de verbinding fysiek op te leveren.';
      case '8':
        return 'Glasvezel ligt tot de erfgrens. Glasvezel (FTTH) is beschikbaar op het adres. ' +
          'De infrastructuur moet nog worden aangelegd door graafwerkzaamheden naar de woning.' +
          ' Ook zal de glasvezelkabel worden afgewerkt met een FTU. Er zijn dus werkzaamheden op klantlocatie nodig om de verbinding fysiek op te leveren.';
      case 'IO':
      case 'NO':
      case '9':
        return 'Dit betreft een projectlocatie en daarom is er geen garantie op levertijd en/of levering!';
      case '11':
        return 'De glasvezelkabel is in het pand afgemonteerd en afgewerkt op een FTU. Deze FTU dient echter vervangen te worden omdat het een ouder type betreft.';
      default:
        return '';
    }
  };

  render() {
    let {connectionDialogOpen, ftthExceptionDialogOpen} = this.state;
    let {formState} = this.props;

    let currentLocation = this.props.locations.selectedLocation;
    let locationName = currentLocation.name;
    const flightcase = currentLocation.flight_case;
    const locationAddress = `
        ${currentLocation.street || ''}
        ${currentLocation.house_number || ''} 
        ${currentLocation.house_number_extension || ''} 
        ${currentLocation.city || ''}`;
    const ftthExceptionMessage = this.getFtthExceptionMessage();

    let {inputsDisabled, provider, type, accessPortfolio} = this.props.quotation.currentQuotation;
    const isVerlengenType = type === quotationType.TYPE_VERLENGEN;
    const isVodafone = provider === providerType.VODAFONE;
    const isOdido = provider === providerType.ODIDO;

    let connectionsDisplayTitle = this.state.loading ? 'Postcodecheck is bezig' : 'Aansluitpunt check';
    if (isOdido) {
      if (accessPortfolio === 'irma') {
        connectionsDisplayTitle = connectionsDisplayTitle + ' IRMA (KPN)';
      }
      if (accessPortfolio === 'tele2') {
        connectionsDisplayTitle = connectionsDisplayTitle + ' (TELE2)';
      }
    }

    const {location, locationOptions, updateConnectionOptions} = this.props;
    const locationData = this.props.existingAccessData.filter(el => el.location && el.location.id === currentLocation.id);
    let allConnections = formState ? formState.connections : [];
    let selectedConnections = allConnections.filter((connection) => connection.selection_connection_order_id);
    allConnections = allConnections.map((el) => {
      el.product = null;

      if (el.location_order_id || selectedConnections.find((a) => a.selection_connection_order_id === el.selection_connection_order_id)) {
        el.product = locationData.find((data) => data.order_id === el.location_order_id)
      }

      return el;
    });

    return (
      <div>
        <div className={'row'}>
          <div id="Forms" className="col s12 paddingbottomnone">
            <div>
              <h1><span className="themeprimarycolor"><i
                className="small material-icons left themeprimarycolor">settings_input_component</i>Access locatie instellingen:&nbsp;</span>{locationName} {!flightcase && (locationName === locationAddress ? '' : ` - ${locationAddress}`)}
              </h1>
            </div>
          </div>
        </div>

        {connectionDialogOpen &&
          <GeneralDialog
            dialogState={connectionDialogOpen}
            onRequestClose={this.closeConnectionDialog}
            overlfowException={true}
            style={{minHeight: 400}}
            headerText="Is er sprake van een migratie of een upgrade?"
            closeHandler={this.closeConnectionDialog}
            dialogBodyContentRaw={(
              <DialogBody>
                {this.renderDialogButtons()}
                {this.renderConnectionTypeQuestion()}
              </DialogBody>
            )}
          />
        }

        {ftthExceptionDialogOpen &&
          <GeneralDialog
            dialogState={ftthExceptionDialogOpen}
            closeHandler={this.closeFtthExceptionDialog}
            headerText={'Let op: Afwijkende NLS gedecteerd'}
            dialogBodyContent={(
              <DefaultDialogBody text={ftthExceptionMessage} />
            )}
            rightButtonAction={this.closeFtthExceptionDialog}
            rightButtonText={'Begrepen'}
          />
        }

        {isVerlengenType && currentLocation.fetched && (
          <div>
            <div id="Forms" className="row">
              <div className="col s12">
                <div className="row" style={{margin: 0}}>
                  <h1 className={'ratio-section-title'} style={{margin: '22px 0'}}>
                    <span className={'ratio-section-title'}>
                      <i className="small material-icons left ratio-section-title">settings_input_component</i>
                      Actieve Access orders wijzigen
                    </span>
                  </h1>
                </div>
              </div>

              <Col s={12}>
                <Row style={{margin: 0}}>
                  <div className="line tussenkop">
                    Kies hier per product welke actie u wilt doen <br/>
                    Glasvezel is momenteel niet via Ratio te verlengen, up/downgraden of opheffen.
                  </div>
                </Row>

                <Row>
                  <ExistingAccessData
                    data={locationData}
                    inputsDisabled={inputsDisabled}
                    upgradeData={this.props.upgradingData}
                    getQuotationOptions={this.props.getQuotationOptions}
                    triggerConnectionType={this.triggerConnectionType}
                    generateRetentionOptions={this.generateRetentionOptions}
                  />
                </Row>
              </Col>
            </div>
          </div>
        )}

        {
          isVodafone && (
            <div className="col s12">
              <div id="Forms" className="col s12">
                <h1 className={'ratio-section-title'}>
                      <span className={'ratio-section-title'}>
                          <i className="small material-icons left ratio-section-title">phone_android</i>
                        {'Wenst u access producten?'}
                      </span>
                </h1>
                <div>
                  <div>
                    <button
                      className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${currentLocation.sdWanActive === false ? "active" : ""}`}
                      style={{marginRight: 15}}
                      onClick={() => this.changeAccessType(currentLocation.id, false)}
                      disabled={inputsDisabled}
                    >
                      Connecties
                    </button>
                    {
                      false &&
                      <button
                          className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${currentLocation.sdWanActive === true ? "active" : ""}`}
                          onClick={() => this.changeAccessType(currentLocation.id, true)}
                          disabled={inputsDisabled}
                      >
                        SD WAN
                      </button>
                    }

                  </div>
                </div>
              </div>
            </div>
          )
        }

        {
          this.renderLocationConfiguration(
            formState,
            allConnections,
            currentLocation,
            location,
            locationOptions,
            updateConnectionOptions,
            isVerlengenType,
            locationData,
            isVodafone
          )
        }

        <div className={'row'}>
          <div className="col s12 linesplit">
            <div className="line30"/>
          </div>
        </div>

        {
          currentLocation.flight_case === false ? (
            <div className={'row'}>
              <div className="col s12 overviewkopbg">
                {this.state.loading && <Loader/>}
                <div className="overviewkop clickable" onClick={this.toggleConnectionsDisplay}>
                  {connectionsDisplayTitle}
                  <i className="material-icons right themeprimarycolor clickable">
                    {this.state.showConnections ? `keyboard_arrow_up` : `keyboard_arrow_down`}
                  </i>
                </div>
              </div>
              <ConnectionAvailability
                setLoading={this.setLoading}
                showConnections={this.state.showConnections}/>
            </div>
          ) : null
        }
      </div>
    );
  }
}

const mapStateToProps = ({steps, access, locations, quotation, connections, locationOptions}) => {
  return {
    access,
    locations,
    quotation,
    connections,
    availableToGoForward: steps.availableToGoForward,
    locationOptions: locationOptions.data.connections,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    accessActions: bindActionCreators(accessActions, dispatch),
    connectionsActions: bindActionCreators(connectionsActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
    locationActions: bindActionCreators(locationActions, dispatch),
    hardwareActions: bindActionCreators(hardwareActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessLocationForm);
