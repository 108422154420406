import React, { Component } from 'react';

import SignaturePad from 'signature_pad';
import {bindActionCreators} from "redux";
import digitalSigningLogActions from "../../actions/digitalSigningLogActions";
import {connect} from "react-redux";
import {Input, Preloader} from "react-materialize";
import {toastr} from "react-redux-toastr";
import {withRouter} from "react-router-dom";
import {apiRoutes} from "../../constants/apiRoutes";
import {poll} from "../../utils/utils";

class DigitalSigningSignature extends Component {
  state = {
    loader: false,
    code: '',
    signatureOff: false,
    loading: false,
    isSubmitButtonDisabled: this.props.digitalSigningLog.isSubmitButtonDisabled
  };

  componentDidMount() {
    this.SignaturePad = new SignaturePad(document.querySelector('canvas'));
    this.uuid = this.props.digitalSigningLog.signingLog.uuid ? this.props.digitalSigningLog.signingLog.uuid : null;

    if (this.props.signingLog.hasSignature) {
      this.showSignature();
    }
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if (prevProps.digitalSigningLog.isSubmitButtonDisabled !== this.props.digitalSigningLog.isSubmitButtonDisabled) {
      this.setState({isSubmitButtonDisabled: this.props.digitalSigningLog.isSubmitButtonDisabled})
    }
  }

  showSignature = () => {
    const uuid = this.props.match.params.uuid;
    const {REACT_APP_API_URL} = process.env;
    const token = localStorage.getItem('token');

    const canvas = document.querySelector('canvas');
    const ctx = canvas.getContext('2d');

    const image = new Image();

    image.onload = () => {
      const w = canvas.width;
      const h = w / (image.naturalWidth / image.naturalHeight);

      ctx.drawImage(image, 0, 0, w, h);
    };

    image.src = `${REACT_APP_API_URL}public/digital-signing-log/${uuid}/digital-signing-image?bearer=${token}`;

    this.SignaturePad.off();
    this.setState({signatureOff: true});
  };

  clearSignatureContent = () => {
    this.SignaturePad.clear();
    this.SignaturePad.on();

    this.setState({signatureOff: false});
  };

  saveSignature = () => {
    if (this.SignaturePad.isEmpty()) {
      return toastr.error('Please, fill your signature.', '');
    }

    this.setState({loader: true});

    this.props.digitalSigningLogActions.saveSignature(this.uuid, this.SignaturePad.toDataURL()).then(() => {
      this.SignaturePad.off();
      this.setState({loader: false, signatureOff: true});
    });
  };

  request2FACode = () => {
    this.props.digitalSigningLogActions.sendSmsCode(this.uuid).then((response) => {
      if (response) {
        this.scrollToBottom();
      }
    });
  };

  confirmSmsCode = () => {
    this.props.digitalSigningLogActions.confirmSmsCode(this.uuid, this.state.code)
      .then((response) => {
        if (response) {
          const url = apiRoutes.digitalSigningLogsRelated.sendConfirmationMail.replace('{uuid}', this.uuid);

          this.props.digitalSigningLogActions.sendConfirmationMail(this.uuid)
            .then(() => poll(url))
            .catch((e) => toastr.error('We konden geen bevestigingsmail naar uw e-mailadres sturen', e.message))
            .then(() => this.setState({loading: false}))
            .then(() => this.initiateSendingThePdfDocuments())
        }
      });
  };

  initiateSendingThePdfDocuments = () => {
    const url = apiRoutes.digitalSigningLogsRelated.sendPdfDocuments.replace('{uuid}', this.uuid);

    this.props.digitalSigningLogActions.sendPdfDocument(this.uuid)
      .then(() => poll(url))
      .catch((e) => toastr.error('Kan bestaande gegevens niet ophalen', e.message));
  };

  handleChange = ({target}) => {
    const {name, value} = target;

    this.setState({
      [name]: value
    });
  };

  scrollToBottom = () => {
    const section = document.querySelector('#confirmCodeSection');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  render() {
    const {PreloaderParent} = this.props;
    const {isSentSmsCode} = this.props.digitalSigningLog;

    return (
      <fieldset style={{border: 'none', padding: 'unset', margin: 'unset', minWidth: '100%'}}>
        <div className={'Forms digitalSigningForm digital-signing-form-signature'}>
          <div>
            <h1 className={'ratio-section-title'}>
              <span className="ratio-section-title">
                <i className="small material-icons left ratio-section-title">assignment</i>
                Ondertekenen:
              </span>{' '}
              Handtekening
            </h1>
          </div>

          <div className={'row'}>
            <div className="canvas-signatures-section">
              <canvas
                className={'canvas-signatures'}
                width='650'
                height='200'
                style={{ border: '1px solid black' }}
              />
            </div>
          </div>
  
          <div className="row">
            <button
              style={{ marginLeft: 20 }}
              className="btn doceri-btn-right ratio-btn-right save-signature-button"
              type='button'
              onClick={this.saveSignature}
              disabled={this.state.signatureOff || this.state.loader}
            >
              {this.state.loader ?
                <PreloaderParent>
                  <Preloader />
                </PreloaderParent> :
                'Handtekening opslaan'
              }
            </button>

            <button
              className="btn doceri-btn-right ratio-btn-right delete-signature-button"
              type='button'
              onClick={this.clearSignatureContent}>
              Verwijderen
            </button>
          </div>
        </div>

        <div className="row">
          <button
            className={'btn doceri-btn-right'}
            onClick={this.request2FACode}
            style={{float: 'right', marginBottom: !isSentSmsCode ? '20px' : null}}
            name="forward"
            disabled={this.props.digitalSigningLog.isSubmitButtonDisabled}
          >
            Ondertekenen en SMS-code opvragen
          </button>
        </div>

        {isSentSmsCode &&
        <div style={{marginBottom: '20px'}} className={'Forms digitalSigningForm'} id="confirmCodeSection">
          <div>
            <h1 className={'ratio-section-title'}>
              <span className="ratio-section-title">
                <i className="small material-icons left ratio-section-title">assignment</i>
                Ondertekenen:
              </span>{' '}
              Sms verificatie code
            </h1>
          </div>

          <div className={'row'}>
            <div>
              <Input
                s={12}
                value={this.state.code}
                onChange={this.handleChange}
                label='Vul uw sms code in'
                name='code'
                type='text'
              />

              <div className="col s6 offset-s6">
                <button
                  onClick={this.confirmSmsCode}
                  className="btn doceri-btn-right ratio-btn-right"
                  type="submit"
                  name="confirmSmsCode"
                  disabled={this.state.loading}
                >
                  {
                    this.state.loading ?
                      <PreloaderParent>
                        <Preloader />
                      </PreloaderParent> :
                      'Bevestigen'
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
        }
      </fieldset>
    );
  }
}

const mapStateToProps = ({digitalSigningLog}) => {
  return {
    digitalSigningLog
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    digitalSigningLogActions: bindActionCreators(digitalSigningLogActions, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DigitalSigningSignature));
