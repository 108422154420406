import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

import { actionTypes } from '../constants/actionTypes';
import { apiRoutes } from '../constants/apiRoutes';
import { toastr } from 'react-redux-toastr'

const getDownloads = () => {
    return async (dispatch) => {
        try
        {
            const url = apiRoutes.downloadsRelated.getDownloads;
            const response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(getDownloadsSuccess(response));
        }
        catch (error)
        {
            toastr.error('Ophalen van documenten mislukt', error.message);
        }
    }
};

const getDownloadsSuccess = (response) => {
    return {
        type: actionTypes.getDownloads,
        response
    }
};

const downloadRatioGuideFile = (documentId) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.downloadsRelated.download.replace('{documentId}', documentId);
            const response = await ApiService.performRequest(RequestType.GETFILE, url);

            dispatch(downloadRatioGuideFileSuccess(response));

            return true;
        }
        catch ({message}) {
            toastr.error('Kan document niet downloaden', message);
        }
    }
};

const downloadRatioGuideFileSuccess = (response) => {
    return {
        type: actionTypes.downloadRatioGuideFile,
        response
    }
};

const editRatioGuideFile = (data) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.downloadsRelated.update.replace('{documentId}', data.id);
            delete data.id;
            let response = await ApiService.performRequest(RequestType.POST_MULTIPART, url, data);

            dispatch(editRatioGuideFileSuccess(response));

            toastr.success('Upload succesvol', '');
        } catch (error) {
            toastr.error('Opslaan mislukt. Probeer het opnieuw', error.message);
        }
    }
};

const editRatioGuideFileSuccess = (response) => {
    return {
        type: actionTypes.editRatioGuideFile,
        response
    }
};

export default {
    getDownloads,
    downloadRatioGuideFile,
    editRatioGuideFile,
}
