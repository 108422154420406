import React, {Component} from 'react';
import {withRouter} from 'react-router'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'

import quotationActions from '../actions/quotationActions';
import stepsActions from '../actions/stepsActions';
import authenticationActions from '../actions/authenticationActions';
import apiStatusActions from "../actions/apiStatusActions";

import Steps from '../steps/steps';
import Content from './content';
import LocationsList from './locations/locationsList';
import Menu from '../components/menu';
import Navbar from '../components/navbar';
import FileDownload from "js-file-download";
import DefaultDialogBody from "../containers/common/KpnDialog/DefaultDialogBody";
import GeneralDialog from "./common/GeneralDialog";

import ProviderHelper from "../helper/providerHelper";
import providerType from "../constants/providerType";

class Main extends Component {
  static quotationTypes = [
    'Nieuwe klant', 'Migratie', 'Verlenging', 'Uitbreiding', 'Aanpassen bestaand', 'Bestaande klant', 'Q&A Offerte', 'Koop', 'Huur', 'Vergelijking'
  ];

  constructor(props) {
    super(props);

    this.state = {
      alerts: {
        currentSoftAlert: '',
        currentHardAlert: '',
        allSoftAlerts: [],
        allHardAlerts: []
      },
      apiStatusList: []
    };
  }

  componentWillMount() {
    let quotationId = this.props.match.params.id;
    let path = this.props.match.path;
    this.props.quotationActions.setQuotationId(quotationId);

    if (path.includes('lead')) {
      this.props.stepsActions.updateIsLead(true);
    } else {
      this.props.apiStatusActions.getApiStatus(quotationId);
      this.setState({ alerts: this.props.alerts, apiStatusList: this.props.apiStatus });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.alerts !== this.props.alerts) {
      this.setState({alerts: this.props.alerts});
    }

    let {apiStatus, alerts} = this.props;
    if (prevProps.apiStatus !== apiStatus) {
      this.setState({ apiStatusList: apiStatus.apiStatusList });

      let apiDownList = apiStatus.apiStatusList.filter((el) => el.isDown);
      if (apiDownList.length > 0) {
        let apiList = apiDownList.map((el) => el.api_name).join(',');
        let alertMessage = 'LET OP: de huidige API\'s zijn niet beschikbaar: {apiList}';

        alertMessage = alertMessage.replace(/{apiList}/, apiList);
        this.props.quotationActions.addHardAlert(alertMessage);
      }
    }

    if (prevProps.alerts.allHardAlerts.length > 0 && !alerts.allHardAlerts.length) {
      this.props.quotationActions.resetHardAlerts();
    }

    let prevLoading = prevProps.loading;
    let currentLoading = this.props.loading;
    let prevExistingDataLoaders = prevLoading.existingAccessDataRetrieval || prevLoading.existingMobileDataRetrieval || prevLoading.existingVoiceDataRetrieval;
    let currentExistingDataLoaders = currentLoading.existingAccessDataRetrieval || currentLoading.existingMobileDataRetrieval || currentLoading.existingVoiceDataRetrieval;

    if (prevExistingDataLoaders !== currentExistingDataLoaders && !currentExistingDataLoaders) {
      this.props.quotationActions.resetSoftAlerts();
    }
  }

  getQuotationTypeName = (quotationTypeEnum) => {
    if (this.props.quotation.isLead) {
      return 'Lead';
    }

    let {currentQuotation} = this.props.quotation;
    if (currentQuotation.provider === providerType.VODAFONE &&
      typeof Main.quotationTypes[quotationTypeEnum] === 'string' &&
      Main.quotationTypes[quotationTypeEnum] === 'Migratie'
    ) {
      return 'Verlengen';
    }

    return typeof Main.quotationTypes[quotationTypeEnum] === 'string' && Main.quotationTypes[quotationTypeEnum];
  };

  redirectToHome = () => {
    this.props.stepsActions.setCurrentStep(1);
    this.props.history.push('/');
  };

  goToAdminPage = () => {
    this.props.history.push('/admin');
  };

  logout = () => {
    this.props.authenticationActions.logout();
  };

  onDownloadLocationsFile = () => {
    this.props.quotationActions.getQuotationLocationsFile(this.props.quotation.currentQuotation.id)
      .then(() => {
        return FileDownload(this.props.quotation.locationsXls, 'locations.xls');
      });
  };

  redirectToAccess = () => {
    const accessStepId = this.props.steps.availableSteps.find(x => x.displayName === 'Access').id;
    this.props.stepsActions.setCurrentStep(accessStepId);
  };

  redirectToNextStep = () => {
    const {currentStepId} = this.props.steps;
    const nextStepPosition = this.props.steps.availableSteps.findIndex(x => x.id === currentStepId) + 1;

    this.props.stepsActions.setCurrentStep(this.props.steps.availableSteps[nextStepPosition].id);
  };

  renderContent = () => {
    //TODO: We should consider using slugs for specyfing steps rather than id's/names
    const {currentStepId} = this.props.steps;
    const stepParams = this.props.steps.availableSteps.find(x => x.id === currentStepId);
    const {access_selection} = this.props.quotation.currentQuotation;

    if (stepParams.displayName === 'Voice' && access_selection === 'unmanaged-access') {
      return this.renderAccessDialog(stepParams.displayName, 'Doorgaan zonder Voice');
    }

    return <Content/>;
  };

  renderAccessDialog = (moduleName, skipButtonLabel) => {
    return (
      <GeneralDialog
        dialogState={true}
        closeHandler={this.redirectToNextStep}
        dialogBodyContent={
          <DefaultDialogBody text={'Voor ' + moduleName + ' heeft u een managed access verbinding nodig.'}/>
        }
        leftButtonAction={this.redirectToAccess}
        leftButtonText={'Manage access instellen'}
        leftButtonLeft={true}
        rightButtonAction={this.redirectToNextStep}
        rightButtonText={skipButtonLabel}
        />
    );
  };

  switchOrganization = async ({target}) => {
    const organizationId = target.value;
    await this.props.authenticationActions.switchOrganization(organizationId);
    const {switchOrganization} = this.props.authentication;

    if (switchOrganization) {
      window.location.reload();
    }
  }

  render() {
    const {steps, authentication, quotation, match} = this.props;
    const {name, company, theme } = authentication;
    const {currentStepId} = steps;
    const stepParams = steps.availableSteps.find(step => step.id === currentStepId);
    const {currentQuotation} = quotation;
    const {createdAt, updatedAt, type, provider, accessPortfolio, dealId} = currentQuotation;
    const isQuotationClicked = match.path.includes('/quotation');
    const isYielderPartner = company.includes('Yielder Partners')

    const {alerts} = this.state;

    let quotationProvider = ProviderHelper.getQuotationProviderName(provider, accessPortfolio);
    if (dealId) {
      quotationProvider = '#' + dealId + '- ' + quotationProvider;
    }

    return (
      <div>
        <Navbar
          onAdminClick={this.goToAdminPage}
          onLogoutClick={this.logout}
          onSettingsClick={this.redirectToHome}
          alerts={alerts}
          switchableOrganizationList={this.props.authentication.switchableOrganizationList}
          switchOrganizationAction={this.switchOrganization}
          allowSwitch={this.props.authentication.allowSwitch}
        />
        <div className="valign-wrapper">
          <div className="valign">
            <div className="container maincontainer">
              <div className="section">
                <div className="row" style={{ marginBottom: '5px' }}>
                  <Menu
                    fullName={name}
                    company={company}
                    theme={theme}
                    createdAt={createdAt}
                    updatedAt={updatedAt}
                    isQuotationClicked={isQuotationClicked}
                  />

                  <div id="Steps" className="col s10 m10 l9">
                    <div className={'steps-title'} style={{height: '58px'}}>
                      <h6 className="hide-large">Naam gebruiker
                        <a href="#">
                          <a href="#">
                            <i className="Groot material-icons right">dehaze</i>
                          </a>
                          <i className="Groot material-icons right">edit_location</i>
                        </a>
                      </h6>
                      <h4 className={isYielderPartner ? 'quotation-title-yielder' : 'quotation-title'}>
                        {this.getQuotationTypeName(type)} ({quotationProvider}):
                        <span> {currentQuotation.name}</span>
                      </h4>
                    </div>

                    <Steps/>
                  </div>
                </div>

                <div className="row" style={{ overflowX: 'visible' }}>
                  {
                    stepParams.displayLocations &&
                    <div className={'col s12 m12 l3'}>
                      <LocationsList onDownloadLocationsFile={() => this.onDownloadLocationsFile()}/>
                    </div>
                  }
                  {this.renderContent()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({quotation, authentication, steps, alerts, apiStatus, loading}) => {
  return {
    quotation,
    authentication,
    steps,
    alerts,
    apiStatus,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    quotationActions: bindActionCreators(quotationActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
    authenticationActions: bindActionCreators(authenticationActions, dispatch),
    apiStatusActions: bindActionCreators(apiStatusActions, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
