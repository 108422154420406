import styled from 'styled-components';

export default styled.div`
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
  padding: 0 10px;
  text-align: center;
  input {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
  }
`;
