import React from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table';
import {Button} from "react-materialize";
import RatioOverflowTableWrapper from "../../../components/styled-components/ratio-overflow-table-wrapper/RatioOverflowTableWrapper";

const PostcodeManagementGrid = (props) => {

    const generateRow = (row) => {
        return (
            <TableRow key={row.id}>
                <TableRowColumn>{row.categoryName}</TableRowColumn>
                <TableRowColumn>{row.description}</TableRowColumn>
                <TableRowColumn>{row.name}</TableRowColumn>
                <TableRowColumn>{new Date(row.createdAt).toLocaleString('nl-NL')}</TableRowColumn>
                <TableRowColumn>{new Date(row.updatedAt).toLocaleString('nl-NL')}</TableRowColumn>
                <TableRowColumn>{row.updatedBy}</TableRowColumn>
                <TableRowColumn>
                    <div>
                        <a href="#" onClick={() => props.onEditClick(row)}>
                            <i className="small material-icons left themeprimarycolor">edit</i>
                        </a>
                    </div>

                    <div>
                        <Button
                            waves="light"
                            className="doceri-btn-right"
                            style={{float: "left", marginLeft: "10px"}}
                            onClick={() => props.onDeleteClick(row)}
                        >
                            <i className="large material-icons" >clear</i>
                        </Button>
                    </div>
                </TableRowColumn>
            </TableRow>
        );
    };

    const getIcon = (sortDirection) => {
        switch (sortDirection) {
            case "ASC":
                return 'arrow_downward';
            case "DESC":
                return 'arrow_upward';
            case false:
                return 'swap_vert'
        }
    };

    const generateHeader = () => {
        return (
            props.headerNames.map(headerName =>
                <TableHeaderColumn key={headerName} className="clickable" headername={headerName} >
                    {headerName}<i className="material-icons right lightgrey">{getIcon(props.sortingState[props.getPropertyNameFromDisplayName(headerName)])}</i>
                </TableHeaderColumn>
            )
        );
    };

    return (
        <RatioOverflowTableWrapper>
            <div className="valign-wrapper">
                <Table selectable={false}>
                    <TableHeader displaySelectAll={false} adjustForCheckbox={false} >
                        <TableRow onCellClick = {props.onHeaderClick}>
                            {
                                generateHeader()
                            }
                        </TableRow>
                    </TableHeader>
                    <TableBody displayRowCheckbox={false}>
                        {
                            props.rows.map(row =>
                                generateRow(row)
                            )
                        }
                    </TableBody>
                </Table>
            </div>
        </RatioOverflowTableWrapper>
    );
};

PostcodeManagementGrid.propTypes = {
    onHeaderClick: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    getPropertyNameFromDisplayName: PropTypes.func.isRequired,
    rows: PropTypes.array.isRequired,
    sortingState: PropTypes.array.isRequired,
    headerNames: PropTypes.array.isRequired
};

export default PostcodeManagementGrid;