import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import authenticationActions from "../../../actions/authenticationActions";
import orderingActions from "../../../actions/workflow/orderingActions";
import {DelayInput} from "react-delay-input";
import adminOrdersActions from "../../../actions/adminOrdersActions";
import QuotationOrderProductsTable from "./quotationOrderProductsTable";
import Pagination from "../../../components/pagination/pagination";
import DialogBody from "../../common/KpnDialog/DialogBody";
import DialogFooter from "../../common/KpnDialog/DialogFooter";
import DialogButton from "../../common/KpnDialog/DialogButton";
import DefaultDialogBody from "../../common/KpnDialog/DefaultDialogBody";
import LoaderComponent from "../../common/LoaderComponent";
import Moment from "react-moment";
import './index.css';
import GeneralDialog from "../../common/GeneralDialog";
import AccessDialog from "./AccessDialog";
import TelephoneNumberDialog from "./TelephoneNumberDialog";
import HostedVoiceNumberDialog from "./HostedVoiceNumberDialog";
import VamoProductDialog from "./VamoProductDialog";
import OrderingUserNamesDialog from "./OrderingUserNamesDialog";
import productActionTypes from "../../../constants/productActionTypes";
import StatusOrder from "./StatusOrder";

class QuotationOrderProducts extends Component {
  statusColors = {
    accepted: '#02b81f',
    alert: '#f8d806',
    declined: '#e80404',
    pending: '#FFA500',
    reject: '#FFA500'
  };

  sortInitialState = {
    productName: false,
    productNumber: false,
    irmaOrderId: false,
    productGroup: false,
    status: false,
    updatedAt: false
  };

  constructor(props) {
    super(props);

    this.state = {
      searchPhrase: '',
      page: 1,
      loading: true,
      sorting: {
        ...this.sortInitialState
      },
      productDetailsDialog: false,
      telephoneNumberDialogOpened: false,
      hostedVoiceDialogOpened: false,
      accessDialogOpened: false,
      vamoDialogOpened: false,
      userNameDialogOpened: false,
      productDetails: {},
      quotationOrderProducts: {},
      selectedRow: null,
      changeOrderStatusDialog: false,
      currentOrderStatus: null
    };
  };

  componentDidMount() {
    this.getQuotationOrderProducts();
  };

  changePage = ({target}) => {
    const {value} = target;
    this.setState({page: value});

    const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false);
    const sortValue = this.state.sorting[sortField];

    this.getQuotationOrderProducts(sortField, sortValue, value, this.state.searchPhrase);
  };

  getQuotationOrderProducts = (sortField, direction, page, searchQuery) => {
    const {quotationNumber} = this.props.adminOrders.currentQuotationOrder;
    let queryParams = '';
    if (sortField && direction) {
      queryParams += '?sortField=' + sortField;
      queryParams += '&sortDirection=' + direction;
    }

    if (page) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?');
      queryParams += 'page=' + page
    }

    if (searchQuery) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?');
      queryParams += 'searchQuery=' + searchQuery;
    }

    this.props.adminOrdersActions.getQuotationAdminOrderProducts(quotationNumber, queryParams)
      .then(() => {this.setState({quotationOrderProducts: this.props.adminOrders.quotationOrderProducts.orderSentProducts})})
      .then(() => {this.setState({loading: false})})
  };

  onSearch = ({target}) => {
    let {value} = target;

    const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false);
    const sortValue = this.state.sorting[sortField];

    this.setState({searchPhrase: value});

    this.getQuotationOrderProducts(sortField, sortValue, this.state.page, value)
  };

  getSortDirection = (propertyName) => {
    const sortDirection = this.state.sorting[propertyName];
    switch (sortDirection) {
      case false:
        return "ASC";
      case "ASC":
        return "DESC";
      case "DESC":
        return false
    }
  };

  changeSortDirection = (propertyName, direction) => {
    this.setState({sorting: {...this.sortInitialState, [propertyName]: direction}});

    this.getQuotationOrderProducts(propertyName, direction, this.state.page, this.state.searchPhrase)
  };

  onSortChange = ({target}) => {
    const headerName = target.getAttribute('headername');
    const propertyName = this.getHeaderPropertyForName(headerName);

    if (!propertyName) {
      return;
    }

    this.changeSortDirection(propertyName, this.getSortDirection(propertyName));
  };

  closeProductDetailsDialog = () => {
    this.setState({productDetailsDialog: false});
  };

  cancelEditAccessOptions = () => {
    this.setState({accessDialogOpened: false});
  };

  saveAccessOptions = (data) => {
    this.setState({accessDialogOpened: false});
    const orderId = this.state.productDetails.id;
    const newData = {...data};

    this.props.orderingActions.updateAccessOrder(orderId, newData).then((response) => {
      if(response && response.success) {
        this.props.orderingActions.resendOrder(orderId);
      }
    });
  };

  cancelEditVamoProduct = () => {
    this.setState({accessDialogOpened: false});
  };

  saveVamoProduct = (vamoProduct) => {
    this.setState({vamoDialogOpened: false});
    const orderId = this.state.productDetails.id;

    this.props.orderingActions.updateVamoOrder(orderId, vamoProduct).then((response) => {
      if(response && response.success) {
        this.props.orderingActions.resendOrder(orderId);
      }
    });
  };

  saveUserNames = (userNames) => {
    this.setState({userNameDialogOpened: false})

    const orderId = this.state.productDetails.id;
    const {quotationNumber} = this.props.adminOrders.currentQuotationOrder;
    const data = {
      userNames,
      quotation: quotationNumber
    };

    this.props.orderingActions.updateUsernameOrder(orderId, data).then((response) => {
      if(response && response.success) {
        this.props.orderingActions.resendOrder(orderId);
      }
    });
  };

  onCancelEditUserNames = () => {
    this.setState({userNameDialogOpened: false});
  };

  saveNumberDetails = (telephoneNumber) => {
    this.setState({telephoneNumberDialogOpened: false})

    const orderId = this.state.productDetails.id;

    if (telephoneNumber.action === productActionTypes.OPHEFFEN) {
      if (telephoneNumber.contract_termination_date) {
        telephoneNumber.contractEndDate = telephoneNumber.contract_termination_date;
      }
      if (telephoneNumber.contract_termination_duration) {
        telephoneNumber.contractLength = telephoneNumber.contract_termination_duration;
      }
    }

    this.props.orderingActions.updateTelephoneNumberOrder(orderId, telephoneNumber).then((response) => {
      if(response && response.success) {
        this.props.orderingActions.resendOrder(orderId);
      }
    });
  };

  cancelEditNumber = () => {
    this.setState({telephoneNumberDialogOpened: false});
  };

  saveHostedVoiceNumber = (data) => {
    this.setState({hostedVoiceDialogOpened: false})

    const orderId = this.state.productDetails.id;
    const newData = {...data};
    delete newData.id;
    delete newData.startingNumber;

    this.props.orderingActions.updateVoiceNumberOrder(orderId, newData).then((response) => {
      if(response && response.success) {
        this.props.orderingActions.resendOrder(orderId);
      }
    });
  };

  cancelEditHostedVoiceNumber = () => {
    this.setState({hostedVoiceDialogOpened: false});
  };

  updateOrderSentProductStatus = (orderSentProductData) => {
    this.props.adminOrdersActions.updateOrderSentProduct(orderSentProductData.id, {status: 'reject'})
      .then(() => this.getQuotationOrderProducts())
      .then(() => this.setState({productDetailsDialog: false}));
  };

  goBack = () => {
    this.props.adminOrdersActions.setAdminCurrentSection('orders');
  };

  onViewProductDetailsClick = (row) => {
    this.setState({productDetails: row});
    this.setState({productDetailsDialog: true});
  };

  getHeaderPropertyForName = (displayName) => {
    const index = this.tableHeaderNames.indexOf(displayName);
    return this.tableObjectProperties[index];
  };

  getCorrectColors = (status, isPending) => {
    const acceptedColor = this.statusColors['accepted'];
    const alertColor = this.statusColors['alert'];
    const declinedColor = this.statusColors['declined'];
    const pendingColor = this.statusColors['pending'];
    const rejectColor = this.statusColors['reject'];

    if (isPending) {
      return {orderIdColor: pendingColor, statusColor: pendingColor};
    }

    switch (status) {
      case 'Success':
        return {orderIdColor: acceptedColor, statusColor: acceptedColor};
      case 'Order afgewezen':
        return {orderIdColor: alertColor, statusColor: declinedColor};
      case 'Geannuleerd door gebruiker':
        return {orderIdColor: rejectColor, statusColor: rejectColor};
      default:
        return {orderIdColor: acceptedColor, statusColor: acceptedColor};
    }
  };

  tableHeaderNames = [
    '#',
    'Product',
    'Product Code',
    'OrderID',
    'Product group',
    'Status',
    'Last update',
    'Actions'
  ];
  tableObjectProperties = [
    'id',
    'productName',
    'productNumber',
    'irmaOrderId',
    'productGroup',
    'status',
    'updatedAt',
    'actions'
  ];

  updateCommentInput = (orderingSentProductId, event) => {
    let {value} = event.target;
    const {quotationNumber} = this.props.adminOrders.currentQuotationOrder;

    this.props.orderingActions.updateOrderingSentProductComment(orderingSentProductId, value)
        .then(() => this.props.adminOrdersActions.getQuotationOrderProducts(quotationNumber))
        .then(() => this.setState({quotationOrderProducts: this.props.adminOrders.quotationOrderProducts.orderSentProducts}))
  };

  onAdjustOrderDetails = () => {
    let {productDetails} = this.state;
    this.setState({productDetailsDialog: false});

    if (productDetails.isAccess) {
      this.setState({accessDialogOpened: true});
      return;
    }
    if (productDetails.isHostedVoice) {
      this.setState({hostedVoiceDialogOpened: true});
      return;
    }
    if (productDetails.isMobile) {
      this.setState({telephoneNumberDialogOpened: true});
      return;
    }
    if (productDetails.isUsernameProduct) {
      this.setState({userNameDialogOpened: true});
      return;
    }
    if (productDetails.isVamo) {
      this.setState({vamoDialogOpened: true});
    }
  };

  renderOrderDetails = () => {
    const {
      productNumber,
      irmaOrderId,
      oldOrderId,
      phoneNumber,
      status,
      isPending,
      productGroup,
      productAction,
      createdAt,
      desiredDeliveryDate,
      contractTerminationDate,
      contractTerminationDuration,
      productIssueMessage,
      productRejectMessage,
      id,
      commentData,
      isFlexiblePorting,
      isNewCustomerOrder
    } = this.state.productDetails;
    const colors = this.getCorrectColors(status, isPending);
    const {missingKrnNumberMessage} = this.props.adminOrders.currentQuotationOrder;

    if (isNewCustomerOrder) {
      return (
          <DialogBody>
            <span style={{fontWeight: 'bold'}} >
                  Status: <span style={{color: colors?.statusColor}}>{status}</span>
                </span>
            <span style={{display: 'flex'}}>
                  <DefaultDialogBody text={'Datum aangemaakt:'}/>
                  <Moment format="YYYY-MM-DD" style={{marginLeft: '5px'}}>{ new Date(createdAt)}</Moment>
                </span>

            {desiredDeliveryDate &&
            <span style={{display: 'flex'}}>
                    <DefaultDialogBody text={'Gewenste opleverdatum:'}/>
                    <Moment format="YYYY-MM-DD" style={{marginLeft: '5px'}}>{ new Date(desiredDeliveryDate)}</Moment>
                  </span>
            }

            {(productIssueMessage || missingKrnNumberMessage) &&
            <div>
              <div className="row themeprimarycolor" style={{width: '100%', borderTop: '2px dotted', color: 'red'}}>
                Order issues
              </div>

              {missingKrnNumberMessage &&
              <DefaultDialogBody text={'Via het bewerken scherm in de IRMA UI kun je de klant bewerken en daar het juiste KRN invoeren.'}/> }<br/>

              {productIssueMessage &&
              <DefaultDialogBody text={'Datum aangemaakt: ' + productIssueMessage.replace('|', '\n')}/>}
            </div>
            } <br/>

            {productRejectMessage &&

            <div style={{color: colors?.statusColor, marginBottom: '20px'}}>{productRejectMessage}</div>
            }
            <div className="order-products-input-comment">
              <div className="label">Interne opmerking</div>
              <DelayInput
                  delayTimeout={3000}
                  id="commentData"
                  type="text"
                  name="commentData"
                  value={commentData}
                  onChange={(event) => {this.updateCommentInput(id, event)}}
              />
            </div>
          </DialogBody>
      )
    }

    return (
      <DialogBody>
        <DefaultDialogBody text={'Product groep: ' + productNumber}/>
        {!['Upgraden', 'Porteren', 'Simless porteren'].includes(productAction) &&
        <DefaultDialogBody text={'OrderID: ' + irmaOrderId} style={{fontWeight: 'bold'}}/>
        }
        {productAction === 'Upgraden' &&
        <>
          <DefaultDialogBody text={'Oude orderID: ' + oldOrderId} style={{fontWeight: 'bold'}}/>
          <DefaultDialogBody text={'Nieuwe orderID: ' + irmaOrderId} style={{fontWeight: 'bold'}}/>
        </>
        }
        {phoneNumber &&
        <DefaultDialogBody text={'Nummer: ' + phoneNumber} style={{fontWeight: 'bold'}}/>
        }
        <span style={{fontWeight: 'bold'}} >
                  Status: <span style={{color: colors?.statusColor}}>{status}</span>
                </span>
        <DefaultDialogBody text={'Product group: ' + productGroup}/>
        <span style={{display: 'flex'}}>
                  <DefaultDialogBody text={'Datum aangemaakt:'}/>
                  <Moment format="YYYY-MM-DD" style={{marginLeft: '5px'}}>{ new Date(createdAt)}</Moment>
                </span>
        <DefaultDialogBody text={'Gekozen actie in offerte: ' + productAction}/> <br/>

        <div className="row themeprimarycolor" style={{width: '100%', borderTop: '2px dotted', fontWeight: 'bold'}}>
          Order details
        </div>

        {isFlexiblePorting &&
        <span>Flexibel porteren gekozen</span>
        }

        {desiredDeliveryDate &&
        <span style={{display: 'flex'}}>
                    <DefaultDialogBody text={'Gewenste opleverdatum:'}/>
                    <Moment format="YYYY-MM-DD" style={{marginLeft: '5px'}}>{ new Date(desiredDeliveryDate)}</Moment>
                  </span>
        }

        <span style={{display: 'flex'}}>
                  <DefaultDialogBody text={'Einddatum contract:'}/>
          {contractTerminationDate &&
          <Moment format="YYYY-MM-DD" style={{marginLeft: '5px'}}>{ new Date(contractTerminationDate)}</Moment>
          }
                </span>
        <DefaultDialogBody text={'Contract Termination Opzegtermijn: ' + contractTerminationDuration}/> <br/>

        {(productIssueMessage || missingKrnNumberMessage) &&
        <div>
          <div className="row themeprimarycolor" style={{width: '100%', borderTop: '2px dotted', color: 'red'}}>
            Order issues
          </div>

          {missingKrnNumberMessage &&
          <DefaultDialogBody text={'Via het bewerken scherm in de IRMA UI kun je de klant bewerken en daar het juiste KRN invoeren.'}/> }<br/>

          {productIssueMessage &&
          <DefaultDialogBody text={'Datum aangemaakt: ' + productIssueMessage.replace('|', '\n')}/>}
        </div>
        } <br/>

        {productRejectMessage &&

        <div style={{color: colors?.statusColor, marginBottom: '20px'}}>{productRejectMessage}</div>
        }
        <div className="order-products-input-comment">
          <div className="label">Interne opmerking</div>
          <DelayInput
            delayTimeout={3000}
            id="commentData"
            type="text"
            name="commentData"
            value={commentData}
            onChange={(event) => {this.updateCommentInput(id, event)}}
          />
        </div>
      </DialogBody>
    );
  };

  renderOrderButtons = () => {
    const {productDetails} = this.state;
    const {status, isRejected} = productDetails;

    return (
      <DialogFooter
        buttonLeft={(
          <DialogButton
            buttonAction={() => this.updateOrderSentProductStatus(productDetails)}
            buttonText='Order in ratio afwijzen'
            buttonDisabled={status === 'Rejected'}
            left={true}
            style={{display: status === 'Success' ? 'none' : 'block'}}
          />
        )}
       /* buttonCenter={
          <DialogButton
            buttonAction={() => this.onAdjustOrderDetails()}
            buttonText={'Order aanpassen'}
            style={{display: isRejected ? 'block' : 'none'}}
          />
        }*/
        buttonRight={
          <DialogButton
            buttonAction={this.closeProductDetailsDialog}
            buttonText='Sluiten'
          />
        }
      />
    )
  };

  onOpenStatusDialogClick = async (row) => {
    this.setState({
      currentOrderStatus: row.status,
      changeOrderStatusDialog: true,
      selectedRow: row
    });
  };

  closeStatusDialog = () => {
    this.setState({changeOrderStatusDialog: false});
  };

  changeOrderStatus = (status, isProductStatus = false) => {
    const {id} = this.state.selectedRow;
    this.props.adminOrdersActions.changeAdminOrderStatus(id, status, isProductStatus);
  }

  render() {
    let {
      searchPhrase,
      productDetails,
      loading,
      sorting,
      productDetailsDialog,
      accessDialogOpened,
      vamoDialogOpened,
      hostedVoiceDialogOpened,
      userNameDialogOpened,
      telephoneNumberDialogOpened,
      changeOrderStatusDialog,
      currentOrderStatus
    } = this.state;

    const {adminOrders, authentication} = this.props;
    const { theme } = authentication;
    const {productName, complex, isUsernameProduct, voipIndex} = productDetails;
    const {quotationOrderProducts} = adminOrders;
    const {voipUserProducts, complexProducts, usernameProducts, userNames} = quotationOrderProducts;
    const {quotationName, quotationNumber, quotationType, irmaClientId} = adminOrders.currentQuotationOrder;

    const isExistingType = quotationType === quotationType.TYPE_EXISTING;
    const isVerlengenType = quotationType === quotationType.TYPE_VERLENGEN;
    const isExceptionQuotation = isVerlengenType || isExistingType;

    const usernameModule = complex ? complexProducts : usernameProducts;
    const { useNina } = this.props.authentication;

    return (
      <div>
        <div className="col s12">
          <div id="Forms" className="row ratiotable paddingbottomnone">
            <h1>
              <span className="ratio-section-title">
                Order overzicht: #{quotationNumber} - {quotationName} - {irmaClientId}
              </span>
            </h1>
          </div>

          <div className="row overviewkopbg ratiotable ordersSearchDiv">
            <div className="col s12 m3">
              <div className="overviewkop">
                <form>
                  <div className="input-field margingone inner-addon">
                    <i className="glyphicon tiny material-icons left lightgrey">search</i>
                      <DelayInput
                        delayTimeout={800}
                        id="search"
                        type="search"
                        name="searchPhrase"
                        value={searchPhrase}
                        onChange={this.onSearch}
                        placeholder="Order  ID zoeken..."
                      />
                  </div>
                </form>
              </div>
            </div>

            <div className="col s12 m9">
              <div className="overviewkop quotation-btns-inline">
                <button className="btn doceri-btn-right ratio-btn-right" onClick={() => this.goBack()}>
                  <i className="small material-icons left white-text">arrow_back</i>
                  Terug
                </button>
              </div>
            </div>
          </div>

          {loading ?
            <LoaderComponent theme={theme} /> :

            <div className="row">
              <QuotationOrderProductsTable
                headersNames={this.tableHeaderNames}
                propertiesToShow={this.tableObjectProperties}
                rows={this.state.quotationOrderProducts}
                onViewProductDetailsClick={this.onViewProductDetailsClick}
                onHeaderClick={this.onSortChange}
                sortingState={sorting}
                getPropertyNameFromDisplayName={this.getHeaderPropertyForName}
                colors={this.getCorrectColors}
                openStatusDialogClick={this.onOpenStatusDialogClick}
              />

              <div className="col s12 sendendform">
                <div className="col s12 m10">
                  <Pagination
                    page={quotationOrderProducts.currentPage}
                    pages={quotationOrderProducts.pages}
                    changePage={this.changePage}
                  />
                </div>
              </div>
            </div>
          }

          {changeOrderStatusDialog &&
              <StatusOrder
                  dialogOpen={changeOrderStatusDialog}
                  onCancel={this.closeStatusDialog}
                  currentStatus={currentOrderStatus}
                  onStatusChange={this.changeOrderStatus}
                  isProductStatusChange={true}
              />
          }

          <GeneralDialog
            dialogState={productDetailsDialog}
            headerText={productName}
            closeHandler={this.closeProductDetailsDialog}
            dialogBodyContentRaw={this.renderOrderDetails()}
            dialogFooterChildren={this.renderOrderButtons()}
            onRequestClose={this.closeProductDetailsDialog}
          />

          {
            accessDialogOpened && (
              <AccessDialog
                dialogOpened={accessDialogOpened}
                onCancel={this.cancelEditAccessOptions}
                accessOptions={productDetails.accessOptions}
                onSubmit={this.saveAccessOptions}
                errors={[]}
                orderingAccessType={productDetails.accessType}
                quotationType={quotationType}
                quotationNumber={quotationNumber}
                inputsDisabled={false}
                useNina={useNina}
              />
            )
          }

          {
            hostedVoiceDialogOpened && (
              <HostedVoiceNumberDialog
                dialogOpened={hostedVoiceDialogOpened}
                hostedVoiceNumber={productDetails.hostedVoiceNumber}
                onCancel={this.cancelEditHostedVoiceNumber}
                onSubmit={this.saveHostedVoiceNumber}
                quotationType={quotationType}
                quotationNumber={quotationNumber}
                inputsDisabled={false}
              />
            )
          }

          {
            telephoneNumberDialogOpened && (
              <TelephoneNumberDialog
                isExceptionQuotation={isExceptionQuotation}
                dialogOpened={telephoneNumberDialogOpened}
                quotationNumber={quotationNumber}
                quotationType={quotationType}
                telephoneNumber={productDetails.telephoneNumber}
                numberText={productDetails.phoneNumber}
                onCancel={this.cancelEditNumber}
                onSave={this.saveNumberDetails}
                inputsDisabled={false}
              />
            )
          }

          {
            vamoDialogOpened && (
              <VamoProductDialog
                selectedVamoProduct={productDetails.vamoProduct}
                quotationType={quotationType}
                quotationNumber={quotationNumber}
                onCancel={this.cancelEditVamoProduct}
                onSave={this.saveVamoProduct}
              />
            )
          }

          {
            userNameDialogOpened && (
              <OrderingUserNamesDialog
                userNames={userNames}
                userNameSelected={voipIndex}
                dialogOpened={userNameDialogOpened}
                onCancel={this.onCancelEditUserNames}
                module={isUsernameProduct ? usernameModule : null}
                voipUserProducts={voipUserProducts}
                onSubmit={this.saveUserNames}
                selectedProduct={productDetails.productNumber}
                quotationType={quotationType}
                quotationNumber={quotationNumber}
                complexProducts={usernameModule}
                isComplex={complex}
                inputsDisabled={false}
              />
            )
          }

        </div>
      </div>
    );
  }
}

const mapStateToProps = ({authentication, adminOrders}) => {
  return {
    authentication,
    adminOrders,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch),
    adminOrdersActions: bindActionCreators(adminOrdersActions, dispatch),
    orderingActions: bindActionCreators(orderingActions, dispatch)
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuotationOrderProducts));
