import { actionTypes } from '../constants/actionTypes';
import { apiRoutes } from '../constants/apiRoutes';
import { toastr } from 'react-redux-toastr'

import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";
import store from '../store';
import ObjectHelper from '../services/objectHelper';
import QuotationActions from './quotationActions';

export const setQuotationLocations = (quotationId, isLead = false) => {
    return async (dispatch) => {
        try {
            let url = (isLead ? apiRoutes.locationsRelated.getLeadLocations : apiRoutes.locationsRelated.getLocations) + `\\${quotationId}`;
            let requestData = await ApiService.performRequest(RequestType.GET, url);

            requestData.forEach(x => {
                x.quotation = quotationId
            });

            let generalLocation = {
                name: 'Opties contractniveau',
                id: -1
            };
            requestData = ObjectHelper.sortByName(requestData);
            requestData.unshift(generalLocation);

            dispatch(_setQuotationLocationsSuccess(requestData));
        }
        catch ({message}) {
            toastr.error('Locatie toevoegen mislukt', message);
        }
    }
};

const _setQuotationLocationsSuccess = (locations) => {
    return {
        type: actionTypes.setCustomerLocations,
        payload: locations
    }
};

const setSelectedLocation = (location, merge) => {
    return {
        type: actionTypes.setSelectedLocation,
        payload: location,
        merge: merge,
    }
};


const updateSelectedLocation = (locationId, quotationId, isLead = false) => {
    return async (dispatch) => {
        try {
            // dispatch(updateSelectedLocationProperty(propertyName, value))
            let body = store.getState().locations.selectedLocation;
            let url = isLead ? apiRoutes.locationsRelated.updateLeadLocation : apiRoutes.locationsRelated.updateLocation;
            url = url + locationId;
            const response = await ApiService.performRequest(RequestType.PUT, url, [body]);

            dispatch(setQuotationLocations(quotationId, isLead));

            return response;
        }
        catch (error) {

            const validErrors = error.response.data;

            ['house_number_extension', 'isra_house_number_extension'].forEach(key => {
                if (validErrors[key]) {
                    validErrors[key].forEach(e => toastr.error(e));
                }
            });

            toastr.error('Het is niet mogelijk de geselecteerde locatie te wijzigen', '');
        }
    }
};

export const updateSelectedLocationInRedux = (propertyName, value) => {
    return dispatch => {
        dispatch(updateSelectedLocationProperty(propertyName, value))
    };
};

const updateSelectedLocationProperty = (propertyName, value) => {
    return {
        type: actionTypes.updateSelectedLocation,
        payload: {
            propertyName,
            value
        }
    };
};

export const updateWholeSelectedLocationInRedux = () => {
    return dispatch => {
        const selectedLocationId = store.getState().locations.selectedLocation.id;
        const locationFromAllLocations = store.getState().locations.allLocations.find(x => x.id === selectedLocationId);
        dispatch(setSelectedLocation(locationFromAllLocations));
    };
};

const clearSelectedLocation = () => {
    let emptySelectedLocation = {
        id: 0,
        name: '',
        street: '',
        city: '',
        postal_code: '',
        house_number: '',
        house_number_extension: '',
        isra_postal_code: '',
        isra_house_number: '',
        isra_house_number_extension: '',
        isra_reference: '',
        serviceErrors: {},
        israDialogData: {},
    };
    emptySelectedLocation = _enrichLocationWithAdditionalProperties(emptySelectedLocation);

    return {
        type: actionTypes.setSelectedLocation,
        payload: emptySelectedLocation
    }
};

const setLocationsAsInvalid = (locationsIds) => {
    return {
        type: actionTypes.setLocationsAsInvalid,
        payload: {
            locationsIds,
        }
    };
};

const updateExistingLocationInDb = (location) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.locationsRelated.location + `\\${location.id}`;
            let requestData = await ApiService.performRequest(RequestType.PUT, url, [location]);

            toastr.success('Locatie is gewijzigd', '');
            dispatch(_updateExistingLocationInDbSuccess(requestData));
        }
        catch (error) {
            toastr.error('Het is niet mogelijk de geselecteerde locatie te wijzigen', '');
        }
    }
};

const _updateExistingLocationInDbSuccess = (updatedLocation) => {
    return {
        type: actionTypes.updateExistingLocationInDb,
        payload: updatedLocation
    }
};

const addNewLocationInDb = (location, quotationId, isLead = false) => {
    return async (dispatch) => {
        try {
            location.quotation = quotationId;
            location = _enrichLocationWithAdditionalProperties(location);

            let url = isLead ? apiRoutes.locationsRelated.leadLocation : apiRoutes.locationsRelated.location;
            let requestData = await ApiService.performRequest(RequestType.POST, url, [location]);

            toastr.success('Nieuwe locatie toegevoegd', '');
            dispatch(_addNewLocationInDbSuccess(requestData));
            dispatch(setQuotationLocations(quotationId, isLead));
            // merge to keep the quotation
            dispatch(setSelectedLocation(requestData, true));
        }
        catch (error) {
            toastr.error('Nieuwe locatie toevoegen niet mogelijk', '');
        }
    }
};

const _addNewLocationInDbSuccess = (location) => {
    return {
        type: actionTypes.addNewLocationInDb,
        payload: location
    }
};

const getLocationAddressForAutocomplete = (query) => {
  return async (dispatch) => {
    try
    {
      let url = apiRoutes.locationsRelated.postalCodeCheck + '?query=' + query;
      let requestData = await ApiService.performRequest(RequestType.GET, url);

      let {results} = requestData;

      dispatch(_getLocationAddressForAutocompleteSuccess(results));
      dispatch(isRefusedPostcode(requestData.refusedPostcode));
    }
    catch ({message})
    {
      toastr.error('Het is niet mogelijk de locaties op te halen van de server', message);
    }
  }
};

const _getLocationAddressForAutocompleteSuccess = (matchingAddresses) => {
  return {
    type: actionTypes.setAddresses,
    payload: matchingAddresses
  }
};

const setSelectedLocationAddress = (locationAddress) => {
  return {
    type: actionTypes.setLocationAddress,
    payload: locationAddress
  }
};

const isRefusedPostcode = (refusedPostcode) => {
    return {
        type: actionTypes.isRefusedPostcode,
        payload: refusedPostcode
    }
};

const deleteLocation = (locationId, isLead = false) => {
    return async (dispatch) => {
        try {
            let url = isLead ? apiRoutes.locationsRelated.leadLocation : apiRoutes.locationsRelated.location;
            url = url + `\\${locationId}`;
            let requestData = await ApiService.performRequest(RequestType.DELETE, url);

            if (requestData) {
                toastr.success('Locatie is verwijderd', '');
                dispatch(_removeLocation(locationId));
                dispatch(clearSelectedLocation());
                dispatch(QuotationActions.getQuotationSuccess(requestData))
            }
            else {
                toastr.error('Verwijderen locatie niet gelukt', '');
            }
        }
        catch ({message}) {
            toastr.error('Verwijderen locatie niet gelukt', message);
        }
    }
};

const _removeLocation = (locationId) => {
    return {
        type: actionTypes.removeLocation,
        payload: locationId
    }
};

const _enrichLocationWithAdditionalProperties = (location) => {
    let objectWithAdditionalProperties = {
        isra_postal_code: '',
        isra_house_number: '',
        isra_house_number_extension: '',
        isra_reference: '',
        bandWidthType: '',
        bandWidthDown: 0,
        bandWidthUp: 0,
        optionPin: false,
        optionAlarm: false,
        optionIpAddresses: 0,
        SDWANCPE: '',
        SDLANSwitch: '',
        SDLANAP: '',
        voipPorts: 1,
        serviceErrors: {},
        israDialogData: {}
    };

    Object.assign(location, objectWithAdditionalProperties);
    return location;
};

const uploadBulk = (file, quotationId) => {
    const invalidLocations = [];

    return async (dispatch) => {
        try {
            dispatch(toggleIsraLoading());
            let url = apiRoutes.locationsRelated.locationBulk + `${quotationId}`;
            let response = await ApiService.performRequest(RequestType.POST, url, file);
            dispatch(QuotationActions.getQuotation(quotationId));
            dispatch(_uploadBulkSuccess(response.locations));
        }
        catch (error) {
            return {error}
        } finally {
          dispatch(toggleIsraLoading());
        }
    }
};

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const _uploadBulkSuccess = (importedLocations) => {
    return {
        type: actionTypes.uploadBulk,
        payload: importedLocations
    }
};

const closeEditionDialog = () => {
    return {
        type: actionTypes.closeEditionDialog
    };
};

const openEditionDialog = () => {
    return {
        type: actionTypes.openEditionDialog
    };
};

const openIsraDialog = () => ({
    type: actionTypes.openIsraDialog
});

const closeIsraDialog = () => ({
    type: actionTypes.closeIsraDialog
});

const toggleIsraLoading = () => ({
    type: actionTypes.toggleIsraLoading
});

const setIsraDialogData = payload => ({
    type: actionTypes.setIsraDialogData,
    payload
});

const setIsraServiceErrors = payload => ({
    type: actionTypes.setIsraServiceErrors,
    payload
});

const searchLocationsList = (searchPhrase) => {
    return {
        type: actionTypes.searchLocationsList,
        payload: searchPhrase
    };
};

const locationIsraCheck = (location, israAddress = null) => {
  return async (dispatch) => {

    let params = '';

    if (israAddress === '') {
        israAddress = '%20';
    }

    if (israAddress != null) {
        params =`?israAddress=${israAddress}`
    }

    dispatch(toggleIsraLoading());

    let url = apiRoutes.locationsRelated.israCheck + `\\${location.id}${params}`;
    try {
      let response = await ApiService.performRequest(RequestType.GET, url, [location]);

      dispatch(_locationIsraCheckSuccess(location));
      dispatch(_checkForIsraServiceErrors(response));
      dispatch(toggleIsraLoading());

      return response;
    } catch (error) {
      toastr.error('ISRA fout', 'Er is een fout opgetreden tijdens het uitvoeren van de ISRA-controle.');
      dispatch(toggleIsraLoading());
      dispatch(locationIsraCheckError(location));
    }
  }
};

const _checkForIsraServiceErrors = (response) => {
    return async(dispatch) => {
        if (response.message !== undefined) {
            if (response.message === 'multiple-isra-addresses' && response.addresses) {
                dispatch(setIsraDialogData({addresses: JSON.parse(response.addresses), type: 'points'}));
                dispatch(openIsraDialog());
            }

            if (response.message === 'multiple-extra-addresses' && response.addresses) {
                dispatch(setIsraDialogData({addresses: JSON.parse(response.addresses), type: 'extensions'}));
                dispatch(openIsraDialog());
            }

            if (response.success === 0 && response.message.includes('Carrier type is niet beschikbaar voor dit adres vanaf het distributiepunt (SDF)')) {
                toastr.error('ISRA fout', response.message);
                dispatch(setIsraServiceErrors(response.fields));
                return;
            }

            if (response.success !== undefined && response.success === 0) {
                toastr.error('ISRA fout', response.message);
                dispatch(setIsraServiceErrors(response.fields));
                dispatch(openEditionDialog());
            }

            if (response.warning !== undefined && response.warning === 1) {
                toastr.warning('Let op', response.message);
                const quotationId = store.getState().quotation.currentQuotation.id;
                // refresh the locations properties
                dispatch(setQuotationLocations(quotationId));
            }
        } else if (response.success !== undefined && response.success === 1) {
            const quotationId = store.getState().quotation.currentQuotation.id;
            // refresh the locations properties if IS/RA call is success
            dispatch(setQuotationLocations(quotationId));
        }
    }
};

const _locationIsraCheckSuccess = (location) => {
    return {
        type: actionTypes.locationIsraCheckSuccess,
        payload: location
    }
};

const locationIsraCheckError = (location) => {
    return {
        type: actionTypes.locationIsraCheckError,
        payload: location,
    }
};

const getExampleFile = (isIsdnMigration = false) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.fileRelated.get + (isIsdnMigration ? `/kpn-bulkupload-ISDNmigratie.xlsx` : `/kpn-bulkupload.xlsx`);
            let requestData = await ApiService.performRequest(RequestType.GETFILE, url);

            dispatch(_setExampleFile(requestData));
        }
        catch ({message}) {
            toastr.error('Kan het voorbeeldbestand niet downloaden', message);
        }
    }
}

const _setExampleFile = (data) => {
  return {
    type: actionTypes.downloadExampleFile,
    payload: data
  };
}

const setLocationAddressToEmptyString = () => {
    return {
        type: actionTypes.setLocationAddressToEmptyString
    };
};

const resetSelectedLocationToGlobal = () => {
    return dispatch => {
        const globalLocation = store.getState().locations.allLocations.find(x => x.id === -1);
        dispatch(setSelectedLocation(globalLocation));
    };
};

const changeSortingOfLocations = () => {
    return {
        type: actionTypes.changeSortingOfLocations
    };
};

const fillGlobalLocationProperties = (customer) => {
    return {
        type: actionTypes.fillGlobalLocationProperties,
        payload: customer
    };
};

const getLocationSipTrunks = (locationId) => {
  return async(dispatch) => {
    try {
      const url = apiRoutes.hostedVoiceRelated.getLocationSipTrunks.replace('{locationId}', locationId);
      const data = await ApiService.performRequest(RequestType.GET, url);

      const locationFromAllLocations = store.getState().locations.allLocations.find(x => x.id === locationId);
      const locationExtendedWithSipTrunks= {...locationFromAllLocations, sip_trunks: data}

      dispatch(setSelectedLocation(locationExtendedWithSipTrunks))

    } catch({message}) {
          toastr.error('Locatie toevoegen mislukt', message);
    }
  }
}

const resetSelectedLocation = () => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.resetSelectedLocation,
            payload: {}
        });
    }
};

const getLocationExampleFile = (quotationId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.quotationRelated.getLocationBulkXlsx.replace('{quotation}', quotationId);
            let requestData = await ApiService.performRequest(RequestType.GETFILE, url);

            dispatch(_setExampleFile(requestData));
        } catch ({message}) {
            toastr.error('Kan het voorbeeldbestand niet downloaden', message);
        }
    }
};

const getLocationSdWanOptions = (locationId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.accessRelated.getLocationSdWanOptions.replace('{locationId}', locationId);
            let requestData = await ApiService.performRequest(RequestType.GET, url);

            dispatch(_setSdWanOptions(requestData));
        } catch ({message}) {
            toastr.error('Kan het voorbeeldbestand niet downloaden', message);
        }
    }
};

const _setSdWanOptions = (data) => {
    return {
        type: actionTypes.updateLocationSdWanOptions,
        payload: data
    };
};

export default {
    setQuotationLocations,
    setSelectedLocation,
    resetSelectedLocation,
    updateSelectedLocation,
    updateSelectedLocationInRedux,
    clearSelectedLocation,
    updateExistingLocationInDb,
    addNewLocationInDb,
    deleteLocation,
    uploadBulk,
    getLocationAddressForAutocomplete,
    setSelectedLocationAddress,
    isRefusedPostcode,
    closeEditionDialog,
    openEditionDialog,
    setIsraServiceErrors,
    openIsraDialog,
    closeIsraDialog,
    toggleIsraLoading,
    setIsraDialogData,
    searchLocationsList,
    locationIsraCheck,
    setLocationAddressToEmptyString,
    resetSelectedLocationToGlobal,
    changeSortingOfLocations,
    fillGlobalLocationProperties,
    getExampleFile,
    setLocationsAsInvalid,
    getLocationSipTrunks,
    getLocationExampleFile,
    getLocationSdWanOptions
};
