import React, { Component } from 'react';
import KpnDialog  from '../../containers/common/KpnDialog/KpnDialog';
import DialogHeader from "../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../containers/common/KpnDialog/DialogBody";
import DialogFooter from "../../containers/common/KpnDialog/DialogFooter";
import DialogButton from "../../containers/common/KpnDialog/DialogButton";
import {connect} from "react-redux";

class DeleteHardwareAndServices extends Component {
    render() {
        let {
            dialogOpened,
            onRequestClose,
            tab,
            onClickFunc
        } = this.props;

        return (
            <KpnDialog
                dialogState={dialogOpened}
                dialogHeader={(
                    <DialogHeader
                        headerIcon="delete"
                        headerText="Verwijder alles"
                    />
                )}
                dialogBody={
                    <DialogBody>
                        <div className="input-field col s12">
                            Alle {tab} verwijderen?
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </DialogBody>
                }
                dialogFooter={
                    <DialogFooter
                        buttonLeft={(
                            <DialogButton
                                buttonAction={onRequestClose}
                                buttonText='Nee'
                                left={true}
                            />
                        )}
                        buttonRight={(
                            <DialogButton
                                buttonAction={onClickFunc}
                                buttonText='Ja'
                            />
                        )}
                    />
                }
            />
        );
    }
};

const mapStateToProps = ({quotation}) =>
{
    return {
        quotation: quotation
    }
};

export default connect(mapStateToProps)(DeleteHardwareAndServices);
