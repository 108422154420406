import {actionTypes} from '../constants/actionTypes';
import {apiRoutes} from '../constants/apiRoutes';
import {toastr} from 'react-redux-toastr'

import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";
import _ from "lodash";
import ratioTheme from "../constants/theme";
import AuthenticationService from "../services/authenticationService";

export const getDigitalSigningLog = (uuid) => {
    return async (dispatch) => {
        dispatch(processingDigitalSigningLogData(true));

        try {
            let url = apiRoutes.digitalSigningLogsRelated.getDigitalSigningLog.replace('{uuid}', uuid);
            let requestData = await ApiService.performRequest(RequestType.GET, url);
            let {organizationTheme} = requestData;
            let smsConfirmedAt = requestData.digitalSigningLog && !!requestData.digitalSigningLog.smsCodeConfirmedAt;

            const theme = organizationTheme ?
                {
                    ...ratioTheme,
                    ...organizationTheme,
                    logo: organizationTheme.logo ? prefixFileUrl(organizationTheme.logo) : ratioTheme.logo,
                    background: organizationTheme.background ? prefixFileUrl(organizationTheme.background) : ratioTheme.background,
                    primaryColor: organizationTheme.palette.primaryColor,
                    secondaryColor: organizationTheme.palette.secondaryColor,
                } : null;

            AuthenticationService.setTheme(theme);
            dispatch(updateThemeSuccess(theme));
            dispatch(getDigitalSigningLogSuccess(requestData));
            dispatch(getDigitalSigningGroupsSuccess(requestData));
            dispatch(getDigitalSigningFieldsSuccess(requestData));
            dispatch(getQuotationSelectedProductsSuccess(requestData));
            dispatch(getQuotationOrganization(requestData));
            dispatch(processingDigitalSigningLogData(false));
            dispatch(confirmSmsCodeSuccess(smsConfirmedAt));
            dispatch(digitalSigningDetails(requestData));
            dispatch(isTermsAndConditions(requestData));
            dispatch(isDigitalSigningLogSigned(requestData));
            dispatch(isDigitalSigningSubmitButtonDisabled(requestData));
            return requestData;
        } catch ({message}) {
            dispatch(processingDigitalSigningLogData(false));
            toastr.error('Geen offerte gevonden. ', message);
        }
    }
};

const updateThemeSuccess = (response) => {
    return {
        type: actionTypes.updateThemeSuccess,
        response,
    };
};

const prefixFileUrl = (logoUrl) => {
    let urlPrefixed = logoUrl;

    if (logoUrl && logoUrl.indexOf('http') === -1) {
        const siteURL = process.env.REACT_APP_API_URL;
        const prefix = siteURL.replace('/api/', '');
        urlPrefixed = logoUrl ? `${prefix}${logoUrl}` : logoUrl;
    }

    return urlPrefixed;
};

const getDigitalSigningLogSuccess = ({digitalSigningLog}) => {
    return {
        type: actionTypes.getDigitalSigningLog,
        payload: digitalSigningLog
    };
};

const getQuotationSelectedProductsSuccess = ({quotationSelectedProducts}) => {
    return {
        type: actionTypes.getQuotationSelectedProducts,
        payload: quotationSelectedProducts
    };
};

const getDigitalSigningGroupsSuccess = ({digitalSigningGroups}) => {
    return {
        type: actionTypes.getDigitalSigningGroupsSuccess,
        payload: digitalSigningGroups
    };
};

const getDigitalSigningFieldsSuccess = ({digitalSigningFields}) => {
    return {
        type: actionTypes.getDigitalSigningFieldsSuccess,
        payload: digitalSigningFields
    };
};

const getQuotationOrganization = ({organization}) => {
    return {
        type: actionTypes.getQuotationOrganization,
        payload: organization
    };
};

const saveSignature = (uuid, data) => {
    return async () => {
        try {
            const url = apiRoutes.digitalSigningLogsRelated.saveSignature.replace('{uuid}', uuid);
            await ApiService.performRequest(RequestType.POST, url, data);

            toastr.success('Handtekening is opgeslagen', '');
        } catch (error) {
            const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;
            const organization = _.has(error, 'response.data.organization') ? error.response.data.organization : '';
            toastr.error('Het is niet gelukt uw digitale handtekening op te slaan, neem aub contact op met uw contactpersoon ' + (organization ? ('bij '  + organization + '. ') : '.'), message);
        }
    }
};

const sendSmsCode = (uuid) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.digitalSigningLogsRelated.sendSmsCode.replace('{uuid}', uuid);
            const response =  await ApiService.performRequest(RequestType.GET, url);

            dispatch(sendSmsCodeSuccess(true));
            toastr.success('Een SMS-code is verzonden naar uw mobiele nummer.', response.message, { timeOut: 15000 });
            return true;
        } catch (error) {
            const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;
            toastr.error('Het is niet mogelijk de SMS code op te vragen.', message);
        }
    }
};

const confirmSmsCode = (uuid, data) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.digitalSigningLogsRelated.confirmSmsCode.replace('{uuid}', uuid);
            await ApiService.performRequest(RequestType.POST, url, data);

            dispatch(confirmSmsCodeSuccess(true));
            toastr.success('De SMS code is bevestigd.', '');
            return true;
        } catch (error) {
            const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;
            toastr.error('Er is iets fout gegaan', message);
            return false;
        }
    }
};

const downloadPDFDocument = (uuid, documentType, documentId) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.digitalSigningLogsRelated.downloadPDFDocument
              .replace('{uuid}', uuid)
              .replace('{type}', documentType)
              .replace('{documentId}', documentId);
            const response = await ApiService.performRequest(RequestType.GETFILE, url);

            dispatch(downloadPDFDocumentSuccess(response));
            return true;
        } catch ({message}) {
            toastr.error('Kan pdf document niet downloaden', message);
        }
    }
};

const downloadPDFDocumentSuccess = (response) => {
    return {
        type: actionTypes.downloadPDFDocumentSuccess,
        response
    }
};

const downloadAttachments = (uuid, documentId) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.digitalSigningLogsRelated.downloadAttachmentDocument
              .replace('{uuid}', uuid)
              .replace('{documentId}', documentId);
            const response = await ApiService.performRequest(RequestType.GETFILE, url);

            dispatch(downloadAttachmentDocumentSuccess(response));
            return true;
        } catch ({message}) {
            toastr.error('Kan pdf document niet downloaden', message);
        }
    }
};

const downloadAttachmentDocumentSuccess = (response) => {
    return {
        type: actionTypes.downloadAttachmentFilesSuccess,
        response
    }
};


const patchDigitalSigningField = (field, uuid, data) => {
    return async () => {
        try {
            const url = apiRoutes.digitalSigningLogsRelated.patchDigitalSigningField
                .replace('{field}', field)
                .replace('{uuid}', uuid);
            await ApiService.performRequest(RequestType.PATCH, url, data);

            toastr.success('Gegevens opgeslagen', '');
        } catch ({message}) {
            toastr.error('Kan het ondertekeningsveld niet updaten. ', message);
        }
    }
};

const sendSmsCodeSuccess = (sendSmsCodeSuccess) => {
    return {
        type: actionTypes.sendSmsCodeSuccess,
        payload: sendSmsCodeSuccess
    };
};

const confirmSmsCodeSuccess = (confirmSmsCodeSuccess) => {
    return {
        type: actionTypes.confirmSmsCodeSuccess,
        payload: confirmSmsCodeSuccess
    };
};

const processingDigitalSigningLogData = (isLoading) => ({
    type: actionTypes.setProcessingDigitalSigningLogData,
    payload: isLoading,
});

const digitalSigningDetails = ({digitalSigningDetails}) => {
    return {
        type: actionTypes.setDigitalSigningDetails,
        payload: digitalSigningDetails
    }
};

const isTermsAndConditions = ({isTermsAndConditionsEnabled}) => {
    return {
        type: actionTypes.setIsTermsAndConditions,
        payload: isTermsAndConditionsEnabled
    }
};

const isDigitalSigningLogSigned = ({isDigitalSigningLogTermsSigned}) => {
    return {
        type: actionTypes.setIsDigitalSigningLogSigned,
        payload: isDigitalSigningLogTermsSigned
    }
};

const isDigitalSigningSubmitButtonDisabled = ({isSubmitButtonDisabled}) => {
    return {
        type: actionTypes.setIsSubmitButtonDisabled,
        payload: isSubmitButtonDisabled
    }
};

const sendConfirmationMail = (uuid) => {
    return async () => {
        try {
            const url = apiRoutes.digitalSigningLogsRelated.sendConfirmationMail.replace('{uuid}', uuid);
            const response =  await ApiService.performRequest(RequestType.POST, url);

            toastr.success('Er is een bevestigingsmail naar uw e-mailadres gestuurd', response.message, { timeOut: 15000 });
            return true;
        } catch (error) {
            const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;
            toastr.error('We konden geen bevestigingsmail naar uw e-mailadres sturen. ', message);
        }
    }
};

const retrieveAttachmentDocuments = (uuid) => {
    return async () => {
        try {
            const url = apiRoutes.digitalSigningLogsRelated.retrieveAttachmentDocuments.replace('{uuid}', uuid);
            return await ApiService.performRequest(RequestType.GET, url);
        } catch (error) {
            toastr.error('Er is iets misgegaan bij het ophalen van de bijlage documenten ', error.message);
        }
    }
};

const sendPdfDocument = (uuid) => {
    return async () => {
        try {
            const url = apiRoutes.digitalSigningLogsRelated.sendPdfDocuments.replace('{uuid}', uuid);
            return await ApiService.performRequest(RequestType.POST, url);
        } catch (error) {
            toastr.error('Er is iets misgegaan bij het ophalen van de documenten ', error.message);
        }
    }
};

export default {
    getDigitalSigningLog,
    saveSignature,
    sendSmsCode,
    confirmSmsCode,
    downloadPDFDocument,
    patchDigitalSigningField,
    sendConfirmationMail,
    retrieveAttachmentDocuments,
    sendPdfDocument,
    isTermsAndConditions,
    isDigitalSigningLogSigned,
    downloadAttachments,
    isDigitalSigningSubmitButtonDisabled
}
