import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import { Icon } from 'react-materialize';
import ProviderHelper from "../../../helper/providerHelper";
import RatioOverflowTableWrapper from "../../../components/styled-components/ratio-overflow-table-wrapper/RatioOverflowTableWrapper";

const HeadersNames = [
  'Naam',
  'Portfolio',
  'Type',
  'Bestandstype',
  'Aangemaakt',
  'Aangemaakt door',
  'Laatst bewerkt',
  'Laatst gewijzigd door',
  'Acties'
];

const buttonStyle = {
  fontSize: 12
};

const getWordDocumentType = (wordDocument) => {
  let wordDocumentType = '';
  
  if (wordDocument.dsAudit) {
    wordDocumentType = 'DS Audit';
  }
  
  if (wordDocument.dsAttachment) {
    wordDocumentType = 'DS Bijlage';
  }
  
  if (wordDocument.default) {
    wordDocumentType = 'Standaard';
  }

  if (wordDocument.isQuote) {
    wordDocumentType = 'Prijsopgave';
  }
  
  return wordDocumentType;
};

const WordDocumentsGrid = (props) => {
  const generateRow = (row) => {
    return (
      <TableRow key={row.id}>
        <TableRowColumn>{row.name}</TableRowColumn>
        <TableRowColumn style={{width: "8%"}}>{ProviderHelper.getQuotationProviderName(row.provider)}</TableRowColumn>
        <TableRowColumn style={{width: "8%"}}>{getWordDocumentType(row)}</TableRowColumn>
        <TableRowColumn style={{width: "8%"}}>{row.documentType}</TableRowColumn>
        <TableRowColumn style={{width: "10%"}}>{new Date(row.created_at).toLocaleString('nl-NL')}</TableRowColumn>
        <TableRowColumn style={{width: "8%"}}>{row.createdBy ? row.createdBy.username : ''}</TableRowColumn>
        <TableRowColumn style={{width: "10%"}}>{new Date(row.updated_at).toLocaleString('nl-NL')}</TableRowColumn>
        <TableRowColumn style={{width: "8%"}}>{row.updatedBy ? row.updatedBy.username : ''}</TableRowColumn>
        <TableRowColumn>
          <button style={buttonStyle} className="btn" onClick={() => props.onDownloadClick(row)}>
            <Icon>cloud_download</Icon>
          </button>
          <button style={buttonStyle} className="btn" onClick={() => props.onEditClick(row)}>
            <Icon>edit</Icon>
          </button>
          <button style={buttonStyle} className="btn" onClick={() => props.onDeleteClick(row)}>
            <Icon>delete</Icon>
          </button>
        </TableRowColumn>
      </TableRow>
    );
  }

  const generateHeader = () => {
    return (
      HeadersNames.map((headerName) => {
        if (['Portfolio', 'Type', 'Bestandstype', 'Aangemaakt door', 'Laatst gewijzigd door'].includes(headerName)) {
          return <TableHeaderColumn key={headerName} style={{width: "8%"}}>{headerName}</TableHeaderColumn>
        }
        if (['Aangemaakt', 'Laatst bewerkt'].includes(headerName)) {
          return <TableHeaderColumn key={headerName} style={{width: "10%"}}>{headerName}</TableHeaderColumn>
        }
        return <TableHeaderColumn key={headerName}>{headerName}</TableHeaderColumn>
      })
    );
  }

  return (
      <RatioOverflowTableWrapper>
          <div className="valign-wrapper">
              <Table selectable={false}>
                  <TableHeader displaySelectAll={false} adjustForCheckbox={false} >
                      <TableRow>
                          {generateHeader()}
                      </TableRow>
                  </TableHeader>
                  <TableBody displayRowCheckbox={false}>
                      {props.rows.map(row => generateRow(row))}
                  </TableBody>
              </Table>
          </div>
      </RatioOverflowTableWrapper>
  );
};

WordDocumentsGrid.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
  rows: PropTypes.array.isRequired
}

export default WordDocumentsGrid;