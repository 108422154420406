import React, {Component} from 'react';

class InterfaceStep extends Component {
  render() {
    return (
      <div>
          <h1> This is InterfaceStep </h1>
      </div>
    )
  }
}

export default InterfaceStep;