import React from 'react';

const Step = props => {
    const isStepActive = props.config.id === props.currentStep;
    return (
        <a className={"btn doceri-btn ratio-btn-steps " + ( isStepActive ? 'activeStep' : '')} onClick={ props.onClick }>
            <i className={"small material-icons left " + (isStepActive ? 'activeStepIcon' : 'themeprimarycolor')}>{ props.config.iconType }</i>
            <span className="hide-small"> { props.config.displayName } </span>
        </a>
    );
};

export default Step;