import React, {Component} from 'react';
import {DelayInput} from "react-delay-input";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import digitalSigningFieldActions from "../../actions/digitalSigningFieldActions";
import digitalSigningLogActions from "../../actions/digitalSigningLogActions";

import RatioTextEditor from "../../components/RatioTextEditor";

class DigitalSigningCustomFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSigningLog: {},
    };
  }

  onChange = (e, index) => {
    let {name, value} = e.target;

    if (!this.props.excludesDigitalSigning) {
      const uuid = this.props.digitalSigningLog.signingLog.uuid;

      this.setState(prevState => ({
        selectedSigningLog: {
          ...prevState.selectedSigningLog,
          [name]: value
        }
      }));

      this.props.digitalSigningLogActions.patchDigitalSigningField(index, uuid, {signingFieldValue: value});
    } else {
      this.props.digitalSigningFieldActions.updateDigitalSigningField(
        index,
        {
          signingFieldValue: value,
          quotation: this.props.quotation.currentQuotation.id
        }
      );
    }
  };

  render() {
    const {digitalCustomField, needsTextSettings} = this.props;

    return (
      <div key={digitalCustomField.id} style={{marginBottom: '20px'}}>
        <span className="ratio-section-title">
          {digitalCustomField.name}
        </span>

        {
          needsTextSettings ? (
            <div className="input-field">
              <RatioTextEditor
                updateFunction={this.onChange}
                data={digitalCustomField}
              />
            </div>
          ) : (
            <DelayInput
              delayTimeout={2000}
              type="text"
              name={digitalCustomField.name}
              ref={digitalCustomField.name}
              onChange={(event) => this.onChange(event, digitalCustomField.id)}
              value={digitalCustomField.value}
            />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = ({digitalSigningLog, quotation}) => {
  return {
    digitalSigningLog,
    quotation
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    digitalSigningFieldActions: bindActionCreators(digitalSigningFieldActions, dispatch),
    digitalSigningLogActions: bindActionCreators(digitalSigningLogActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DigitalSigningCustomFields);
