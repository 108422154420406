import {actionTypes} from '../constants/actionTypes';
import {apiRoutes} from '../constants/apiRoutes';
import {toastr} from 'react-redux-toastr'

import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

const getAllOrganizations = (queryParams) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.organizationRelated.getAll + (queryParams ? queryParams : '');
      let requestData = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getAllOrganizationsSuccess(requestData));
      dispatch(savePaginationParameters(requestData))
    }
    catch ({message}) {
      toastr.error('Het is niet mogelijk de lijst met offertes op te halen', message);
    }
  }
};

const getOrganizationDetails = () => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.organizationRelated.getOrganization;
      let requestData = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getOrganizationSuccess(requestData));
    }
    catch ({message}) {
      toastr.error('Het is niet mogelijk de lijst met offertes op te halen', message);
    }
  }
};

const getOrganizationSuccess = (organization) => {
  return {
    type: actionTypes.getOrganization,
    payload: organization
  };
};

const getAllOrganizationsSuccess = ({organizations}) => {
  return {
    type: actionTypes.getAllOrganizations,
    payload: organizations
  };
};

const savePaginationParameters = ({currentPage, resultsCount, pages}) => {
  return {
    type: actionTypes.savePagination,
    payload: {
      currentPage,
      resultsCount,
      pages
    }
  }
};

const editOrganization = (organizationId, data) => {
  return async (dispatch) => {
    try
    {
      let url = apiRoutes.organizationRelated.editOrganization.replace(/{organizationId}/, organizationId);
      let requestData = await ApiService.performRequest(RequestType.PATCH, url, data);

      toastr.success('Organisatie bewerkt', '');
      dispatch(_editOrganizationSuccess(requestData));
    }
    catch (error)
    {
      if (error.response.status === 400) {
        let message = error.response.data.message || 'Formulier bevat fouten';
        toastr.error('Het is niet mogelijk om deze gebruiker te bewerken', message)
      } else if(error.response.status === 403) {
        toastr.error('Het is niet mogelijk om deze gebruiker te bewerken', 'U heeft geen rechten om deze gebruiker te bewerken')
      } else {
        toastr.error('Het is niet mogelijk om deze gebruiker te bewerken', error.message);
      }
    }
  }
};

const _editOrganizationSuccess = (user) => {
  return {
    type: actionTypes.editOrganization,
    payload: user
  }
};

export default {
  getAllOrganizations,
  getOrganizationDetails,
  editOrganization,
}
