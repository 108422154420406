import { actionTypes } from '../constants/actionTypes';

const initialState = {
    userInfo: false,
    existingAccessDataRetrieval: false,
    existingMobileDataRetrieval: false,
    existingVoiceDataRetrieval: false,
    processingDigitalSigningLogData: false
}

export default (state = initialState, action) => {
    switch(action.type) {
      case actionTypes.setUserInfoIsLoading:
        return { ...state, userInfo: action.payload };
      case actionTypes.processingExistingVoiceData:
        return { ...state, existingVoiceDataRetrieval: action.payload};
      case actionTypes.processingExistingMobileData:
        return { ...state, existingMobileDataRetrieval: action.payload};
      case actionTypes.setProcessingDigitalSigningLogData:
        return { ...state, processingDigitalSigningLogData: action.payload};
      default:
          return state;
    }
}
