import {actionTypes} from '../constants/actionTypes';
import {apiRoutes} from '../constants/apiRoutes';
import {toastr} from 'react-redux-toastr'

import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";
import _ from "lodash";

const getAllDigitalSigningGroups = () => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.digitalSigningGroupsRelated.getAll;
            let requestData = await ApiService.performRequest(RequestType.GET, url);

            dispatch(getAllDigitalSigningGroupsSuccess(requestData));
        } catch ({message}) {
            toastr.error('Het is niet mogelijk de lijst met offertes op te halen', message);
        }
    }
};

const getAllDigitalSigningGroupsSuccess = ({digitalSigningGroups}) => {
    return {
        type: actionTypes.getDigitalSigningGroups,
        payload: digitalSigningGroups
    };
};

const patchDigitalSigningGroup = (signingGroup, data) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.digitalSigningGroupsRelated.patchDigitalSigningGroup.replace('{group}', signingGroup);
            const response = await ApiService.performRequest(RequestType.PATCH, url, data);

            toastr.success('Signing group bewerkt', '');
            dispatch(_patchSigningGroupSuccess(response));
        } catch ({message}) {
            toastr.error('Kan de tag niet updaten', message);
        }
    }
};

const _patchSigningGroupSuccess = ({signing_group}) => {
    return {
        type: actionTypes.patchDigitalSigningGroup,
        payload: signing_group
    }
};

const addDigitalSigningGroup = (data) => {
    return async () => {
        try {
            const url = apiRoutes.digitalSigningGroupsRelated.addDigitalSigningGroup;
            await ApiService.performRequest(RequestType.POST, url, data);

            toastr.success('Digital signing group bewerkt', '');
        } catch (error) {
            const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;
            toastr.error('Kan de signing group niet updaten', message);
        }
    }
};

const deleteSigningGroup = (signingGroup) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.digitalSigningGroupsRelated.deleteDigitalSigningGroup.replace(/{group}/, signingGroup.id);
            await ApiService.performRequest(RequestType.DELETE, url);

            dispatch(deleteSigningGroupSuccess(signingGroup));
        } catch (error) {
            toastr.error('Failed to delete the signing group', error);
            return {error};
        }
    }
};

const deleteSigningGroupSuccess = (signingGroup) => {
    return {
        type: actionTypes.deleteDigitalSigningGroup,
        payload: signingGroup
    }
};

const sortSigningGroup = (data) => {
    return async () => {
        try {
            const url = apiRoutes.digitalSigningGroupsRelated.sortDigitalSigningGroup;
            await ApiService.performRequest(RequestType.POST, url, data);
        } catch ({message}) {
            toastr.error('Sorting the digital signing groups failed', message);
        }
    }
};

const getDigitalSigningDetails = () => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.digitalSigningDetails.getDigitalSigningDetails;
            let requestData = await ApiService.performRequest(RequestType.GET, url);

            if (requestData.digitalSigningDetails) {
                dispatch(getDigitalSigningDetailsSuccess(requestData.digitalSigningDetails));
            }
        } catch ({message}) {
            toastr.error('The digital signing details could not be retrieved', message);
        }
    }
};

const getDigitalSigningDetailsSuccess = (data) => {
    return {
        type: actionTypes.getDigitalSigningDetails,
        payload: data
    };
};

const patchSigningDetails = (data) => {
    return async () => {
        try {
            const url = apiRoutes.digitalSigningDetails.patchDigitalSigningDetails;
            await ApiService.performRequest(RequestType.PATCH, url, data);
        } catch ({message}) {
            toastr.error('The digital signing details could not be updated', message);
        }
    }
};

export default {
    getAllDigitalSigningGroups,
    addDigitalSigningGroup,
    patchDigitalSigningGroup,
    deleteSigningGroup,
    sortSigningGroup,
    getDigitalSigningDetails,
    patchSigningDetails,
}
