import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import debounce from 'lodash/debounce';

import PropTypes from 'prop-types';
import {Row} from 'react-materialize'

import hardwareActions from "../../actions/hardwareActions";

import CustomHardwareOptions from './CustomHardwareOptions';

class CustomHardware extends Component {
  constructor(props) {
    super(props);

    const selections = this.props.customHardwareSelections[this.props.type] || [];

    this.state = {
      selections: [...selections.filter(selection => selection.category === this.props.category)]
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customHardwareSelections !== this.props.customHardwareSelections) {
      if (this.props.customHardwareSelections[this.props.type]) {
        this.setState({
          selections: [...this.props.customHardwareSelections[this.props.type].filter(selection => selection.category === this.props.category)],
        });
      }
    }
  }

  componentDidMount() {
    this.saveHardwareSelections = debounce(this.saveHardwareSelections, 1000);
  }

  saveHardwareSelections = (selections, quotationId, type, idsProductsToDelete = null) => {
    this.props.hardwareActions.persistCustomHardwareSelections(selections, quotationId, idsProductsToDelete, type, this.props.locations.selectedLocation.id);
  };

  addHardware = () => {
    const { selections } = this.state;
    selections.push({
      id: "",
      quantity: 1,
      connectionId: this.props.connectionId,
      category: this.props.category,
      locationId: this.props.locations.selectedLocation.id
    });
    this.setState({ selections })
  };

  removeHardware = (index) => {
    let selections = this.state.selections;
    let currentSelection = selections[index];

    selections.splice(index, 1);

    this.setState({ selections });

    if (currentSelection && currentSelection.id) {
      this.saveHardware(currentSelection.id);
    }
  };

  changeHardware = (event, index) => {
    const { value } = event.target;
    let selections = this.state.selections;
    let oldSelectionProduct = selections[index].id;

    selections[index].id = value !== '' ? parseInt(value): value;
    selections[index].connectionId = this.props.connectionId;
    selections[index].locationId = this.props.locations.selectedLocation.id;

    const foundSelectedIndex = selections.findIndex((selection, idx) => selection.id === value && selection.connectionId === this.props.connectionId && idx !== index);
    if (foundSelectedIndex !== -1) {

      if(selections[foundSelectedIndex].quantity >= 999) {
        return;
      }

      selections[index].quantity = parseInt(selections[index].quantity)
          + parseInt(selections[foundSelectedIndex].quantity);
      selections.splice(foundSelectedIndex, 1);
    }

    this.setState({selections});
    this.saveHardware(oldSelectionProduct);
  };

  changeHardwareQuantity = (event, index) => {
    let { value, min, max } = event.target;
    let selections = this.state.selections;

    value = Math.max(Number(min), Math.min(Number(max), Number(value)));

    selections[index].quantity = parseInt(value);

    this.setState({selections});

    this.saveHardware();
  };

  saveHardware(currentProduct = null) {
    const { category, type } = this.props;
    const localSelections = this.state.selections;
    const allSelections = this.props.customHardwareSelections[type].filter(selection => selection.category !== category);

    let newSelections = [...localSelections, ...allSelections];

    this.props.hardwareActions.setCustomHardwareSelections(this.props.type, newSelections);

    this.saveHardwareSelections(newSelections, this.props.quotation.id, this.props.type, currentProduct ? currentProduct : null);
  }

  render() {
    const { hardwareList, category, inputsDisabled } = this.props;
    const { selections } = this.state;
    const currentConnectionSelections = selections.filter((selection) => selection.connectionId === this.props.connectionId && selection.locationId === this.props.locations.selectedLocation.id) || [];

    if (!currentConnectionSelections.length) {
      selections.push({
        id: "",
        quantity: 1,
        connectionId: this.props.connectionId,
        locationId: this.props.locations.selectedLocation.id,
        category: this.props.category
      });
    }

    return (
      <Row>
        {hardwareList.length && selections.length ? selections.map((hardware, index, {length}) => {
          if (hardware.connectionId === this.props.connectionId) {
            return (
              <div key={`${index}`} className={'col s12'}>
                <CustomHardwareOptions
                  key={index}
                  hardware={hardware}
                  index={index}
                  hardwareList={hardwareList}
                  removeHardware={this.removeHardware}
                  changeHardware={this.changeHardware}
                  changeHardwareQuantity={this.changeHardwareQuantity}
                  category={category}
                  inputsDisabled={inputsDisabled}
                  selections={selections}
                />

                {hardwareList.length && length - 1 === index ?
                  (<div className="col" style={{ float: "right" }}>
                    <button
                      className="btn doceri-btn-right ratio-btn-right"
                      onClick={this.addHardware}
                      disabled={inputsDisabled}
                    >
                      extra {category ? category.toLowerCase() : 'hardware'}
                      <i className="tiny material-icons right white-text">add</i>
                    </button>
                  </div>) : null
                }
              </div>
            )
          }}) : null
        }
      </Row>
    )
  }
}

CustomHardware.propTypes = {
  type: PropTypes.string.isRequired,
  connectionId: PropTypes.number,
  hardwareList: PropTypes.array,
  locationId: PropTypes.number,
  category: PropTypes.string
};

const mapStateToProps = ({ quotation, locations }) => {
  return {
    quotation: quotation.currentQuotation,
    locations: locations
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    hardwareActions: bindActionCreators(hardwareActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomHardware);
