import React from 'react';

const DialogFooterFlex = ({ children }) => {
  return (
    <footer className="md-dialog__footer kpn-dialog__footer">
      {children}
    </footer>
  );
};

export default DialogFooterFlex;
