import styled from 'styled-components';

export default styled.span`
  display: block;
  width: 100%;
  font-size: 100px;
  line-height: 100px;
  color: red;
  position: absolute;
  left: 0px;
  top: 6px;
  z-index: 5;
  text-align: center;
  cursor: default;
`;
