import {actionTypes} from '../constants/actionTypes';
import {apiRoutes} from '../constants/apiRoutes';
import {toastr} from 'react-redux-toastr'

import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";
import infoStepActions from "./workflow/infoStepActions";

import _ from 'lodash'

const createNewLead = (provider, defaultQuotationName) => {
  return async (dispatch) => {
    try {
      const body = {
        customer: 0,
        name: defaultQuotationName ? defaultQuotationName : '',
        provider: provider ? provider : 0
      };

      dispatch(clearSelectedCustomer());

      const url = apiRoutes.leadRelated.createNew;
      const requestData = await ApiService.performRequest(RequestType.POST, url, [body]);

      const {id, name} = requestData;

      dispatch(createNewQuotationSuccess(id, name));
    }
    catch ({message}) {
      toastr.error('Het is niet mogelijk een nieuwe offerte aan te maken', message);
    }
  }
};

const clearSelectedCustomer = () => {
  return {
    type: actionTypes.clearSelectedCustomer
  };
};

const takeOverLead = (lead) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.leadRelated.takeOver + lead.id;
      const response = await ApiService.performRequest(RequestType.PUT, url);

      dispatch(getQuotationSuccess(response));

      if (response['previousUser'] !== '') {
        toastr.success('Lead overgenomen van ' + response['previousUser']);
      } else {
        toastr.success('Lead teruggegeven aan ' + response['quotation']['author']);
      }
    }
    catch (error) {
      const message = _.has(error, 'response.data.error.exception') ? error.response.data.error.exception[0].message : error.message;
      toastr.error('Kan niet Lead overnemen', message);
    }
  }
};

const setProjectManagerLead = (lead) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.leadRelated.assignProjectManager + lead.id;

      const response = await ApiService.performRequest(RequestType.PUT, url, {userToAssign: lead.assignUser});

      dispatch(getQuotationSuccess(response));
      toastr.success('Lead toegewezen aan ' + response['assignedUser']);
    }
    catch (error) {
      const message = _.has(error, 'response.data.error.exception') ? error.response.data.error.exception[0].message : error.message;
      toastr.error('Kan niet Lead overnemen', message);
    }
  }
};

const updateLead = (lead) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.leadRelated.updateLead + lead.id;
      const response = await ApiService.performRequest(RequestType.PUT, url, [lead]);

      dispatch(getQuotationSuccess(response));
      return response;
    }
    catch (error) {
      const message = _.has(error, 'response.data.error.exception') ? error.response.data.error.exception[0].message : error.message;
      toastr.error('Lead kan niet worden gewijzigd', message);
    }
  }
};

const setLeadId = (leadId) => {
  return {
    type: actionTypes.setQuotationId,
    payload: leadId
  };
};

const createNewQuotationSuccess = (id, name) => {
  return {
    type: actionTypes.createNewQuotation,
    payload: {
      id,
      name
    }
  }
};

const getLead = (leadId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.leadRelated.get;
      const response = await ApiService.performRequest(RequestType.GET, url + '/' + leadId);

      if (response.customer) {
        dispatch(infoStepActions.setSelectedCustomerInRedux(response.customer));
      }

      dispatch(getQuotationSuccess(response));
    } catch (error) {
      toastr.error('Lead details kunnen niet worden opgehaald', error);
    }
  }
};

const getQuotationSuccess = (quotation) => {
  return {
    type: actionTypes.getQuotationSuccess,
    payload: quotation
  }
};

const getAllLeads = (queryParams) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.leadRelated.getAll + (queryParams ? queryParams : '');
      let requestData = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getAllQuotationsSuccess(requestData));
      dispatch(savePaginationParameters(requestData))
    }
    catch ({message}) {
      toastr.error('Het is niet mogelijk de lijst met leads op te halen', message);
    }
  }
};

const getAllQuotationsSuccess = ({quotations}) => {
  return {
    type: actionTypes.getAllQuotations,
    payload: quotations
  };
};

const savePaginationParameters = ({currentPage, resultsCount, pages}) => {
  return {
    type: actionTypes.savePagination,
    payload: {
      currentPage,
      resultsCount,
      pages
    }
  }
};

const deleteLead = (lead) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.leadRelated.deleteLead + '/' + lead.id;
      await ApiService.performRequest(RequestType.DELETE, url);

      dispatch(_deleteQuotationSuccess(lead));
      toastr.success('Lead verwijderd', '');
    }
    catch ({message}) {
      toastr.error('Lead verwijderen niet mogelijk', message);
    }
  }
};

const patchLead = (leadId, data) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.leadRelated.patchLead.replace('{leadId}', leadId);
      let requestData = await ApiService.performRequest(RequestType.PATCH, url, data);

      dispatch(_updateQuotationSuccess(requestData.quotation));
    }
    catch ({message}) {
      toastr.error('Kan de lead niet updaten', message);
    }
  }
};

const _updateQuotationSuccess = (quotation) => {
  return {
    type: actionTypes.updateQuotationSuccess,
    quotation: quotation
  }
};

const _deleteQuotationSuccess = (quotation) => {
  return {
    type: actionTypes.deleteQuotation,
    payload: quotation.id
  }
};

const updateLeadProperty = (target) => {
  let value = target.value;
  if (target.type === 'checkbox') {
    value = !!target.checked;
  }
  if (target.name === 'snumber') {
    value = `S${target.value}`;
  }
  if (target.name === 'contractDuration' || target.name === 'mobileContractDuration') {
    value = +target.value;
  }

  if(target.name === 'transferService' || target.name === 'transferServiceReason') {
    value = +target.value;
  }

  if (target.name === 'fortiGateActive') {
    value = target.value === 'true';
  }

  return {
    type: actionTypes.updateQuotationProperty,
    payload: {
      propertyToChange: target.name,
      newValue: value
    }
  }
};

const getProducts = (leadId) => {
  return async () => {
    try {
      let url = apiRoutes.leadRelated.getProducts + leadId;
      return await ApiService.performRequest(RequestType.GET, url);
    }
    catch ({message}) {
      toastr.error('Kon de producten niet krijgen!', message);
    }
  }
};

const getSelections = (leadId) => {
  return async () => {
    try {
      let url = apiRoutes.leadRelated.getSelections + leadId;
      return await ApiService.performRequest(RequestType.GET, url);
    }
    catch ({message}) {
      toastr.error('Kon de product selecties niet krijgen!', message);
    }
  }
};

const updateSelections = (leadId, data) => {
  return async () => {
    try {
      let url = apiRoutes.leadRelated.updateSelections + leadId;
      return await ApiService.performRequest(RequestType.PUT, url, data);
    }
    catch ({message}) {
      toastr.error('Kan de productselectie niet bijwerken', message);
    }
  }
};

const setExcludeRelationsProducts = (lead, data) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.leadRelated.setExcludeRelationsProducts.replace(/{lead}/, lead);
      const response = await ApiService.performRequest(RequestType.POST, url, data);

      dispatch(getQuotationSuccess(response));
    } catch ({message}) {
      toastr.error('Something went wrong', message);
    }
  }
};

export default {
  createNewLead,
  takeOverLead,
  setProjectManagerLead,
  updateLead,
  setLeadId,
  getLead,
  getAllLeads,
  deleteLead,
  patchLead,
  updateLeadProperty,
  getProducts,
  getSelections,
  updateSelections,
  setExcludeRelationsProducts
}
