import React, {Component, Fragment} from 'react';
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";
import {Col, Input, Row} from "react-materialize";
import DatePicker from "../../../components/datePicker";
import addDays from 'date-fns/addDays';
import RegularSelect from "../../../components/RegularSelect";
import qType from "../../../constants/quotationType";
import telephoneNumberAction from "../../../constants/telephoneNumberType";
import {formatDate} from "../../../utils/utils";
import connectionType, {xdslConnectionTypes} from "../../../constants/connectionType";
import './index.css';
import DialogButton from "../../common/KpnDialog/DialogButton";
import DialogFooter from "../../common/KpnDialog/DialogFooter";
import GeneralDialog from "../../common/GeneralDialog";

class AccessDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accessOptions: {
        id: null,
        connectionSelectionId: null,
        wishDate: null,
        label: null,
        generalDate: false,
        daysAvailable: [],
        connectionSelection: {
          disableWishDate: false,
          type: '',
          locationName: '',
          product: {
            accessType: ''
          }
        },
        secondContactPersonData: {
          secondContactPersonName: '',
          secondContactPersonPhoneNumber: '',
          secondContactPersonEmail: ''
        },
        isOutletRequired: false,
        isPlacingBox: false,
        isPlacingBoxEnabled: false,
        isSkipTestingLabeling: false
      }
    }
  }

  componentDidMount() {
    const {accessOptions} = this.props;

    if (accessOptions) {
      this.setState({
        accessOptions: {
          ...accessOptions,
          wishDate: formatDate(accessOptions.wishDate),
          newBuildLocationDate: formatDate(accessOptions.newBuildLocationDate),
          label: accessOptions.label,
          connectionSelection: accessOptions.connectionSelection
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.accessOptions !== prevProps.accessOptions) {
      const {accessOptions} = this.props;
      this.setState({
        accessOptions: {
          ...accessOptions,
          wishDate: formatDate(accessOptions.wishDate),
          newBuildLocationDate: formatDate(accessOptions.newBuildLocationDate),
          label: accessOptions.label,
          connectionSelection: accessOptions.connectionSelection
        }
      });
    }
  };

  onChangeWishDate = (date) => {
    this.setState({
      accessOptions: {
        ...this.state.accessOptions,
        wishDate: formatDate(date)
      }
    });
  };

  onChangeContractEndDate = (date) => {
    this.setState({
      accessOptions: {
        ...this.state.accessOptions,
        contractEndDate: formatDate(date)
      }
    });
  };

  onChangeBuildLocationDate = (date) => {
    this.setState({
      accessOptions: {
        ...this.state.accessOptions,
        newBuildLocationDate: formatDate(date)
      }
    });
  };

  onChangeText = (e) => {
    const {name, value} = e.target;

    if (['secondContactPersonName', 'secondContactPersonPhoneNumber', 'secondContactPersonEmail'].includes(name)) {
      this.setState({
        accessOptions: {
          ...this.state.accessOptions,
          secondContactPersonData: {
            ...this.state.accessOptions.secondContactPersonData,
            [name]: value
          }
        }
      });
      return;
    }

    this.setState({
      accessOptions: {
        ...this.state.accessOptions,
        [name]: value
      }
    })
  };

  onMultipleSelectChange = ({target}) => {
    const options = [...target.selectedOptions].map((o) => o.value);

    this.setState({
      accessOptions: {
        ...this.state.accessOptions,
        daysAvailable: options
      }
    });
  };

  onChangeGeneral = (e) => {
    const {name, checked} = e.target;

    this.setState({
      accessOptions: {
        ...this.state.accessOptions,
        [name]: checked
      }
    });
  };

  onBooleanSelectChange = (e) => {
    const {name, value} = e.target;
    const newValue = parseInt(value);

    this.setState({
      accessOptions: {
        ...this.state.accessOptions,
        [name]: !!newValue
      }
    })
  };

  isWeekday = date => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.onSubmit(this.state.accessOptions);
  };

  onChangeOutletRequired = ({target: {name}}) => {
    this.setState({
      accessOptions: {
        ...this.state.accessOptions,
        [name]: !this.state.accessOptions.isOutletRequired
      }
    });
  };

  onChangePlacingBox = ({ target: {name} }) => {
    this.setState({
      accessOptions: {
        ...this.state.accessOptions,
        [name]: !this.state.accessOptions.isPlacingBox
      }
    });
  }

  onChangeSkipTestingLabeling = ({ target: {name} }) => {
    this.setState({
      accessOptions: {
        ...this.state.accessOptions,
        [name]: !this.state.accessOptions.isSkipTestingLabeling
      }
    });
  }

  render() {
    const {dialogOpened, onCancel, errors, orderingAccessType, quotationType, inputsDisabled, useNina} = this.props;
    const {accessOptions} = this.state;

    let secondContactPersonValidation = accessOptions.secondContactPersonData.secondContactPersonEmail ||
      accessOptions.secondContactPersonData.secondContactPersonName || accessOptions.secondContactPersonData.secondContactPersonPhoneNumber;

    const isVerlengenQuotation = quotationType === qType.TYPE_VERLENGEN;
    const isVerlengenBetaType = [qType.TYPE_VERLENGEN, qType.TYPE_EXISTING].includes(quotationType);
    const isTerminateAction = accessOptions.action === 8;
    const isVerlengenAction = accessOptions.action === 7;
    const isUpgradeAction = accessOptions.action === 6;
    const isNewAction = accessOptions.action === null;
    const disableWishDate = accessOptions.connectionSelection.disableWishDate;
    const accessProductType = accessOptions.connectionSelection.product.accessType;
    const isPlacingBoxEnabled = accessOptions.isPlacingBoxEnabled;
    const isSkipLabelingBVDSLDisabled = accessProductType === connectionType.CONNECTION_TYPE_BVDSL;

    const styles = {
      dropdown: {
        marginBottom: 30
      },
      dropdownLabel: {
        fontSize: '14px'
      }
    };

    let headerText = 'Bewerk access opties';
    if (orderingAccessType === 'fiber_vlan') {
      headerText = 'Bewerk VLAN opties';
    }

    if (['connection', 'fiber'].includes(orderingAccessType)) {
      headerText = 'Locatiedetails: ';
      headerText = headerText.concat(accessOptions.connectionSelection.locationName);
    }

    return (
      <div className="telephone-number-dialog">
        <GeneralDialog
          dialogState={dialogOpened}
          headerText={headerText}
          closeHandler={onCancel}
          dialogBodyContentRaw={
            <DialogBody>
              <form ref={(ref) => (this.globalForm = ref)}>
                {errors.length > 0 && <Row>
                  <Col s={12} style={{color: '#ff0000'}}>
                    <strong>De volgende fouten zijn opgetreden:</strong>
                    {errors.map(error => <div>{error}</div>)}
                  </Col>
                </Row>}

                {isVerlengenQuotation && (isTerminateAction || isVerlengenAction) &&
                <Row>
                  <Col s={6} className="input-field porting-date-field">
                    <label
                      htmlFor={"contractEndDate"}>{isVerlengenAction ? 'Einddatum verlengde contract' : 'Per wanneer opheffen?'}</label>
                    <DatePicker
                      name="contractEndDate"
                      id={"contractEndDate"}
                      selected={accessOptions.contractEndDate ? new Date(accessOptions.contractEndDate) : ''}
                      onChange={this.onChangeContractEndDate}
                      onChangeRaw={e => e.preventDefault()}
                      minDate={new Date()}
                      dateFormat="dd-MM-yyyy"
                      autoComplete="off"
                      required="true"
                      disabled={inputsDisabled}
                    />
                  </Col>
                  {isVerlengenAction &&
                  <Input
                    s={6}
                    value={accessOptions.contract_termination_duration}
                    onChange={this.onChangeText}
                    label="Opzegtermijn nieuw contract (maanden)"
                    name="contract_termination_duration"
                    type="number"
                    min={1}
                    max={3}
                    required={true}
                    validate={true}
                    disabled={inputsDisabled}
                  />
                  }
                </Row>
                }

                {
                  isVerlengenQuotation && isUpgradeAction &&
                  <Row>
                    <div className="col s6 input-field porting-date-field">
                      <label htmlFor={"upgradeWishDate"}>Gewenste upgrade datum</label>
                      <DatePicker
                        name="wishDate"
                        id={"wishDate"}
                        selected={accessOptions.wishDate ? new Date(accessOptions.wishDate) : ''}
                        onChange={this.onChangeWishDate}
                        onChangeRaw={e => e.preventDefault()}
                        dateFormat="dd-MM-yyyy"
                        autoComplete="off"
                        required
                        disabled={inputsDisabled}
                      />
                    </div>
                  </Row>
                }

                {['fiber', 'connection'].includes(orderingAccessType) &&
                <Fragment>
                  <Row>
                    <Input
                      s={6}
                      onChange={this.onChangeText}
                      label="Naam 2e contactperson"
                      name="secondContactPersonName"
                      type="text"
                      required={secondContactPersonValidation}
                      value={accessOptions.secondContactPersonData.secondContactPersonName}
                      disabled={inputsDisabled}
                    />
                    <Input
                      s={6}
                      onChange={this.onChangeText}
                      label="Telnr 2e contactperson"
                      name="secondContactPersonPhoneNumber"
                      type="text"
                      required={secondContactPersonValidation}
                      value={accessOptions.secondContactPersonData.secondContactPersonPhoneNumber}
                      disabled={inputsDisabled}
                      validate
                      minLength="10"
                      maxLength="10"
                      autocomplete="off"
                    />
                  </Row>
                  <Row>
                    <Input
                      s={12}
                      onChange={this.onChangeText}
                      label="Email 2e contactperson"
                      name="secondContactPersonEmail"
                      pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,9}$"
                      type="email"
                      required={secondContactPersonValidation}
                      validate
                      value={accessOptions.secondContactPersonData.secondContactPersonEmail}
                      disabled={inputsDisabled}
                    />
                  </Row>
                </Fragment>
                }

                {['fiber', 'connection', ''].includes(orderingAccessType) && (!isVerlengenQuotation || isNewAction) && !disableWishDate &&
                <Fragment>
                  <Row>
                    <Col s={6} className="input-field porting-date-field">
                      <div>
                        <label htmlFor={"portingDate"}>Wensdatum</label>
                        <DatePicker
                          style={{marginLeft: '10px'}}
                          name="wishDate"
                          id={"wishDate"}
                          selected={accessOptions.wishDate ? new Date(accessOptions.wishDate) : ''}
                          onChange={this.onChangeWishDate}
                          onChangeRaw={e => e.preventDefault()}
                          dateFormat="dd-MM-yyyy"
                          autoComplete="off"
                          required
                          filterDate={this.isWeekday}
                          popperPlacement="bottom"
                          disabled={inputsDisabled}
                          minDate={addDays(new Date(), 16)}
                          popperModifiers={{
                            flip: {
                              behavior: ["bottom"] // don't allow it to flip to be above
                            },
                            preventOverflow: {
                              enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                            },
                            hide: {
                              enabled: false // turn off since needs preventOverflow to be enabled
                            }
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  {!isVerlengenQuotation && !isNewAction &&
                  <Row>
                    <Input
                      s={4}
                      value={accessOptions.label || ''}
                      label="Label"
                      name="label"
                      type="text"
                      onChange={this.onChangeText}
                      disabled={inputsDisabled}
                    />
                  </Row>
                  }
                </Fragment>
                }

                {orderingAccessType === 'fiber' && quotationType !== 1 &&
                <Fragment>
                  <Row>
                    <Input
                      s={12}
                      value={accessOptions.whereDoesItEnterTheBuilding || ''}
                      label="Waar komt de glasvezel het gebouw binnen?"
                      name="whereDoesItEnterTheBuilding"
                      type="text"
                      onChange={this.onChangeText}
                      required
                      disabled={inputsDisabled}
                    />
                  </Row>

                  <Row>
                    <Col s={12} className='input-field' style={styles.dropdown}>
                      <label htmlFor={"isFiberOnIsra"} style={styles.dropdownLabel}>
                        Is de glasvezel bij binnenkomst afgewerkt op een aansluitpunt (ISRA)?
                      </label>
                      <RegularSelect
                        name="isFiberOnIsra"
                        onChange={this.onBooleanSelectChange}
                        value={accessOptions.isFiberOnIsra ? 1 : 0}
                        multiple={false}
                        disabled={inputsDisabled}
                      >
                        <option value="0">Nee</option>
                        <option value="1">Ja</option>
                      </RegularSelect>
                    </Col>
                  </Row>

                  <Row>
                    <Col s={12} className='input-field' style={styles.dropdown}>
                      <label htmlFor={"DoesItContinueToPatchPanel"} style={styles.dropdownLabel}>
                        Loopt de glasvezel van dit aansluitpunt door naar een patchpaneel?
                      </label>
                      <RegularSelect
                        name="doesItContinueToPatchPanel"
                        onChange={this.onBooleanSelectChange}
                        value={accessOptions.doesItContinueToPatchPanel ? 1 : 0}
                        multiple={false}
                        disabled={inputsDisabled}
                      >
                        <option value="0">Nee</option>
                        <option value="1">Ja</option>
                      </RegularSelect>
                    </Col>
                  </Row>

                  <Row>
                    <Col s={12} className='input-field' style={styles.dropdown}>
                      <label htmlFor={"selfConnecting"} style={styles.dropdownLabel}>
                        Ben je van plan de glasvezel zelf door te verbinden naar de ruimte waar de apparatuur moet
                        komen?
                      </label>
                      <RegularSelect
                        name="selfConnecting"
                        onChange={this.onBooleanSelectChange}
                        value={accessOptions.selfConnecting ? 1 : 0}
                        multiple={false}
                        disabled={inputsDisabled}
                      >
                        <option value="0">Nee</option>
                        <option value="1">Ja</option>
                      </RegularSelect>
                    </Col>
                  </Row>

                  <Row>
                    <Col s={12} className='input-field' style={styles.dropdown}>
                      <label htmlFor={"isPatchInSameRoomAsDevice"} style={styles.dropdownLabel}>
                        Is de glasvezel vanaf het patchpaneel afgewerkt in dezelfde ruimte als waar de klant zijn
                        apparatuur wil?
                      </label>
                      <RegularSelect
                        name="isPatchInSameRoomAsDevice"
                        onChange={this.onBooleanSelectChange}
                        value={accessOptions.isPatchInSameRoomAsDevice ? 1 : 0}
                        multiple={false}
                        disabled={inputsDisabled}
                      >
                        <option value="0">Nee</option>
                        <option value="1">Ja</option>
                      </RegularSelect>
                    </Col>
                  </Row>

                  <Row>
                    <Input
                      s={12}
                      ref="daysAvailable"
                      name="daysAvailable"
                      type="select"
                      label="Welke dag(en) van de week is de klant normaliter beschikbaar voor de installatie?"
                      onChange={this.onMultipleSelectChange}
                      value={this.state.accessOptions.daysAvailable || ''}
                      multiple={true}
                      placeholder="Selecteer"
                      disabled={inputsDisabled}
                    >
                      <option value="monday">Maandag</option>
                      <option value="tuesday">Dinsdag</option>
                      <option value="wednesday">Woensdag</option>
                      <option value="thursday">Donderdag</option>
                      <option value="friday">Vrijdag</option>
                    </Input>
                  </Row>

                  <Row>
                    <Col s={12} className='input-field' style={styles.dropdown}>
                      <label htmlFor={"morningAfternoon"} style={styles.dropdownLabel}>
                        Heeft de klant op die dag(en) een voorkeur voor de ochtend of de middag?
                      </label>
                      <RegularSelect
                        name="morningAfternoon"
                        onChange={this.onChangeText}
                        value={accessOptions.morningAfternoon}
                        multiple={false}
                        required
                        disabled={inputsDisabled}
                      >
                        <option value="">Selecteer een optie</option>
                        <option value="Ochtend">Ochtend (08:00 - 12:00)</option>
                        <option value="Middag">Middag (12:00 - 16:00)</option>
                      </RegularSelect>
                    </Col>
                  </Row>

                  <Row>
                    <Input
                      s={12}
                      value={this.state.accessOptions.deviceLocation || ''}
                      label="Waar en op welke verdieping bevindt zich de ruimte waar de apparatuur afgemonteerd moet worden?"
                      name="deviceLocation"
                      type="text"
                      onChange={this.onChangeText}
                      required
                      disabled={inputsDisabled}
                    />
                  </Row>

                  <Row>
                    <Col s={12} className='input-field' style={styles.dropdown}>
                      <label htmlFor={"newBuildLocation"} style={styles.dropdownLabel}>
                        Betreft het een nieuwbouwlocatie?
                      </label>
                      <RegularSelect
                        name="newBuildLocation"
                        onChange={this.onBooleanSelectChange}
                        value={accessOptions.newBuildLocation ? 1 : 0}
                        multiple={false}
                        disabled={inputsDisabled}
                      >
                        <option value="0">Nee</option>
                        <option value="1">Ja</option>
                      </RegularSelect>
                    </Col>
                  </Row>

                  {accessOptions.newBuildLocation &&
                  <Row>
                    <Col s={12} className="input-field porting-date-field">
                      <label htmlFor={"portingDate"}>Wanneer is de oplevering van het pand gepland?</label>
                      <DatePicker
                        name="newBuildLocationDate"
                        id={"newBuildLocationDate"}
                        selected={accessOptions.newBuildLocationDate ? new Date(accessOptions.newBuildLocationDate) : ''}
                        onChange={this.onChangeBuildLocationDate}
                        onChangeRaw={e => e.preventDefault()}
                        dateFormat="dd-MM-yyyy"
                        autoComplete="off"
                        required
                        disabled={inputsDisabled}
                        filterDate={this.isWeekday}
                        popperPlacement="bottom"
                        minDate={addDays(new Date(), 16)}
                        popperModifiers={{
                          flip: {
                            behavior: ["bottom"] // don't allow it to flip to be above
                          },
                          preventOverflow: {
                            enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                          },
                          hide: {
                            enabled: false // turn off since needs preventOverflow to be enabled
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  }

                  <Row>
                    <Col s={12} className='input-field' style={styles.dropdown}>
                      <label htmlFor={"dataCenter"} style={styles.dropdownLabel}>
                        Betreft het een datacenter?
                      </label>
                      <RegularSelect
                        name="dataCenter"
                        onChange={this.onBooleanSelectChange}
                        value={accessOptions.dataCenter ? 1 : 0}
                        multiple={false}
                        disabled={inputsDisabled}
                      >
                        <option value="0">Nee</option>
                        <option value="1">Ja</option>
                      </RegularSelect>
                    </Col>
                  </Row>

                  {accessOptions.dataCenter &&
                  <Row>
                    <Input
                      s={12}
                      value={accessOptions.dataCenterName || ''}
                      label="Op wiens naam staat de klantkast?"
                      name="dataCenterName"
                      type="text"
                      onChange={this.onChangeText}
                      required
                      disabled={inputsDisabled}
                    />
                  </Row>
                  }
                </Fragment>
                }

                {orderingAccessType === 'fiber' && quotationType === 1 &&
                <Fragment>
                  <Row>
                    <Input
                      s={12}
                      value={accessOptions.accessId || ''}
                      label="AccessID van de bestaande verbinding"
                      name="accessId"
                      type="text"
                      onChange={this.onChangeText}
                      required
                      disabled={inputsDisabled}
                    />
                  </Row>

                  <Row>
                    <Col s={12} className='input-field' style={styles.dropdown}>
                      <label htmlFor={"donorWsoCode"} style={styles.dropdownLabel}>
                        Code van de latende provider
                      </label>
                      <RegularSelect
                        name="donorWsoCode"
                        onChange={this.onChangeText}
                        value={accessOptions.donorWsoCode}
                        multiple={false}
                        required
                        disabled={inputsDisabled}
                      >
                        <option value="">Selecteer een optie</option>
                        <option value="ADES">ADES</option>
                        <option value="BBNL">BBNL</option>
                        <option value="COMS">COMS</option>
                        <option value="DSDA">DSDA</option>
                        <option value="DTWB">DTWB</option>
                        <option value="ESPR">ESPR</option>
                        <option value="GBNW">GBNW</option>
                        <option value="I4IP">I4IP</option>
                        <option value="INXI">INXI</option>
                        <option value="IPAC">IPAC</option>
                        <option value="IPON">IPON</option>
                        <option value="KPNZ">KPNZ</option>
                        <option value="ONEC">ONEC</option>
                        <option value="POCO">POCO</option>
                        <option value="PROX">PROX</option>
                        <option value="SGNT">SGNT</option>
                        <option value="T2ZM">T2ZM</option>
                        <option value="VFZM">VFZM</option>
                        <option value="VOIC">VOIC</option>
                        <option value="XENO">XENO</option>
                      </RegularSelect>
                    </Col>
                  </Row>

                  <Row>
                    <Col s={12} className='input-field' style={styles.dropdown}>
                      <label htmlFor={"isDuringOfficeHours"} style={styles.dropdownLabel}>
                        Kan de migratie tijdens kantooruren (8:00 tot 17:00) gebeuren?
                      </label>
                      <RegularSelect
                        name="isDuringOfficeHours"
                        onChange={this.onBooleanSelectChange}
                        value={accessOptions.isDuringOfficeHours ? 1 : 0}
                        multiple={false}
                        disabled={inputsDisabled}
                      >
                        <option value="0">Nee</option>
                        <option value="1">Ja</option>
                      </RegularSelect>
                    </Col>
                  </Row>
                </Fragment>
                }

                {orderingAccessType === 'fiber_vlan' &&
                <Fragment>
                  <Row>
                    <Input
                      s={12}
                      value={accessOptions.ipVpnOrderId || ''}
                      label="IP-VPN OrderID"
                      name="ipVpnOrderId"
                      type="number"
                      onChange={this.onChangeText}
                      disabled={inputsDisabled}
                    />
                  </Row>

                  <Row>
                    <Input
                      s={12}
                      value={accessOptions.cidr || ''}
                      label="Cidr"
                      name="cidr"
                      type="number"
                      onChange={this.onChangeText}
                      required
                      disabled={inputsDisabled}
                    />
                  </Row>

                  <Row>
                    <Input
                      s={12}
                      value={accessOptions.ipAddress || ''}
                      label="IP-adres"
                      name="ipAddress"
                      type="text"
                      onChange={this.onChangeText}
                      disabled={inputsDisabled}
                    />
                  </Row>

                  <Row>
                    <Col s={12} className='input-field' style={styles.dropdown}>
                      <label htmlFor={"subnetType"} style={styles.dropdownLabel}>
                        Subnet type
                      </label>
                      <RegularSelect
                        name="subnetType"
                        onChange={this.onChangeText}
                        value={accessOptions.subnetType}
                        multiple={false}
                        required
                        disabled={inputsDisabled}
                      >
                        <option value="Unknown">Onbekend</option>
                        <option value="Primary">Primair</option>
                        <option value="Secondary">Secundair</option>
                        <option value="StaticRoute">StaticRoute</option>
                      </RegularSelect>
                    </Col>
                  </Row>
                </Fragment>
                }

                { useNina &&
                <div>
                  { xdslConnectionTypes.includes(accessProductType) &&
                  <Row>
                    <Input
                        type="checkbox"
                        label="Wandcontactdoos"
                        name="isOutletRequired"
                        checked={this.state.accessOptions.isOutletRequired}
                        onChange={this.onChangeOutletRequired}
                        disabled={inputsDisabled}
                    />

                    <Input
                        type="checkbox"
                        label="Testen en labelen overslaan"
                        name="isSkipTestingLabeling"
                        checked={this.state.accessOptions.isSkipTestingLabeling}
                        onChange={this.onChangeSkipTestingLabeling}
                        disabled={inputsDisabled || isSkipLabelingBVDSLDisabled}
                    />
                  </Row>
                  }

                  { connectionType.CONNECTION_TYPE_FTTH === accessProductType && isPlacingBoxEnabled &&
                  <Row>
                    <Input
                        type="checkbox"
                        label="FTU kastje"
                        name="isPlacingBox"
                        checked={this.state.accessOptions.isPlacingBox}
                        onChange={this.onChangePlacingBox}
                        disabled={inputsDisabled}
                    />
                  </Row>
                  }
                </div>
                }

                {
                  isVerlengenBetaType && !isNewAction && !isTerminateAction &&
                  <div className="row" style={{marginTop: '-20px', marginBottom: '35px'}}>
                    <div className="col s12">
                      <input
                        id="sameDate"
                        name="generalDate"
                        className="input-field"
                        checked={!!accessOptions.generalDate}
                        type="checkbox"
                        onChange={this.onChangeGeneral}
                        disabled={inputsDisabled}
                      />
                      <label htmlFor="sameDate">
                        {accessOptions.action === telephoneNumberAction.OPHEFFEN_ACTION ? 'Optie ' : 'Datum '}
                        overal toepassen voor
                        {accessOptions.action === telephoneNumberAction.OPHEFFEN_ACTION && ' opheffen'}
                        {accessOptions.action === telephoneNumberAction.VERLENGEN_ACTION && ' verlengen'}
                        {accessOptions.action === telephoneNumberAction.UPGRADE_ACTION && ' upgrades'}
                      </label>
                    </div>
                  </div>
                }
              </form>
            </DialogBody>
          }
          dialogFooterChildren={
            <DialogFooter
              buttonLeft={(
                <DialogButton
                  buttonAction={() => onCancel()}
                  buttonText='Annuleren'
                  left={true}
                />
              )}
              buttonRight={
                <DialogButton
                  buttonAction={(event) => this.onSubmit(event)}
                  buttonText='Opnieuw versturen'
                />
              }
            />
          }
          onRequestClose={this.closeProductDetailsDialog}
        />

      </div>
    )
  }
}

export default AccessDialog;
