import React, {Component} from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from "material-ui";
import {connect} from "react-redux";

import productType from "../../../constants/productType";
import RatioOverflowTableWrapper
  from "../../../components/styled-components/ratio-overflow-table-wrapper/RatioOverflowTableWrapper";

class DataManagementProductsGridComputed extends Component {
  state = {
    selectedProduct: {},
  };

  generateHeaders = (isCustomCategory) => {
    return (
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow>
          <TableHeaderColumn style={{width: `${isCustomCategory ? "15%" : "40%"}`}}>
            <div style={{whiteSpace: 'break-word', fontWeight: 'bold'}}>Productomschrijving</div>
            <div className="subthread" style={{display: 'inline-block'}}/>
          </TableHeaderColumn>

          <TableHeaderColumn style={{width: "10%"}}>
            <div style={{fontWeight: 'bold'}}>Gekozen order actie</div>
            <div className="subthread" style={{display: 'inline-block'}}/>
          </TableHeaderColumn>

          <TableHeaderColumn>
            <div style={{fontWeight: 'bold'}}>Aantal</div>
            <div className="subthread" style={{display: 'inline-block'}}/>
          </TableHeaderColumn>

          {isCustomCategory &&
          <TableHeaderColumn>
            <span style={{fontWeight: 'bold'}}>Bruto prijs</span>
            <div className="subthread">maandelijks p/st</div>
          </TableHeaderColumn>
          }

          {isCustomCategory &&
          <TableHeaderColumn>
            <span style={{fontWeight: 'bold'}}>Bruto prijs</span>
            <div className="subthread">eenmalig p/st</div>
          </TableHeaderColumn>
          }

          {isCustomCategory &&
          <TableHeaderColumn>
            <span style={{fontWeight: 'bold'}}>Programmeren</span>
            <div className="subthread">eenmalig</div>
          </TableHeaderColumn>
          }

          {isCustomCategory &&
          <TableHeaderColumn>
            <span style={{fontWeight: 'bold'}}>Installeren</span>
            <div className="subthread">eenmalig</div>
          </TableHeaderColumn>
          }

          {isCustomCategory &&
          <TableHeaderColumn style={{position: 'unset'}}>
            <span style={{fontWeight: 'bold'}}>PM</span>
            <div className="subthread">eenmalig</div>
          </TableHeaderColumn>
          }

          <TableHeaderColumn style={{position: 'unset'}}>
            <span style={{fontWeight: 'bold'}}>Totale prijs</span>
            <div className="subthread">maandelijks</div>
          </TableHeaderColumn>

          <TableHeaderColumn style={{position: 'unset'}}>
            <span style={{fontWeight: 'bold'}}>Totale prijs</span>
            <div className="subthread">eenmalig</div>
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>
    )
  };


  calculateTotalMonthlyPrice = (product) => {
    let productAmount = product.only_once_per_quotation && product.only_once_type === productType.TYPE_ONCE_ALL ? 1 : product.amount;
    return productAmount * product.price_gross_monthly;
  };

  calculateTotalOneTimePrice = (product, isCustomCategory) => {
    let productAmount = product.only_once_per_quotation &&
    [productType.TYPE_ONCE_ALL, productType.TYPE_ONCE_ONE_TIME].includes(product.only_once_type) ? 1 : product.amount;
    if (!isCustomCategory) {
      return productAmount * product.price_gross_onetime;
    } else {
      return productAmount * (product.price_gross_onetime + product.setUpPrice + product.installationPrice + product.pmPrice);
    }
  };

  getActionName = (action) => {
    if (!action) {
      return 'Nieuw';
    }

    switch (action) {
      case 5:
        return 'Nieuw';
      case 1:
        return 'Porteren';
      case 4:
        return 'Simless porteren';
      case 6:
        return 'Up/downgraden';
      case 7:
        return 'Verlengen';
      case 8:
        return 'Opheffen';
      case 9:
        return 'Toevoeging';
      case 10:
        return 'Retentie';
      default:
        return 'Nieuw';
    }
  };

  render() {
    const {module} = this.props;
    const moduleName = module.name !== 'KPN IT Diensten' ? module.name : 'Microsoft IT Diensten';

    return (
      <div className="row">
        <div className="col s12 overviewkopbg">
          <div className="overviewkop">
            {moduleName}
          </div>

        </div>
        <RatioOverflowTableWrapper>
          <Table
            className="highlight without-margin-bottom"
            selectable={false}
            displayRowCheckbox={false}
          >
            {this.generateHeaders(module.isCustomCategory)}

            <TableBody displayRowCheckbox={false}>
              {module.products.map((product) =>
                <TableRow key={product.id}>
                  <TableRowColumn style={{
                    width: `${module.isCustomCategory ? "15%" : "40%"}`,
                    whiteSpace: 'normal',
                    wordWrap: 'break-word'
                  }}>
                    {product.name}
                  </TableRowColumn>

                  <TableRowColumn style={{width: "10%", whiteSpace: 'normal', wordWrap: 'break-word'}}>
                    {this.getActionName(product.action)}
                  </TableRowColumn>

                  <TableRowColumn>
                    {product.allow_decimal_quantity ? new Intl.NumberFormat('nl-NL', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(product.amount) : product.amount}

                    {product.only_once_per_quotation && product.only_once_type && (
                      <span>
                        {product.only_once_type === productType.TYPE_ONCE_ALL ?
                          ' (max 1 per offerte mogelijk)' : ' (eenmalige kosten 1x berekend)'
                        }
                      </span>)
                    }
                  </TableRowColumn>

                  {module.isCustomCategory && (
                    <TableRowColumn style={{textAlign: 'right'}}>
                      {
                        <span>
                          {new Intl.NumberFormat('nl-NL', {
                            style: 'currency',
                            currency: 'EUR'
                          }).format(product.price_gross_monthly)}
                        </span>
                      }
                    </TableRowColumn>)
                  }

                  {module.isCustomCategory && (
                    <TableRowColumn
                      style={product.fetchedFromGrexx ? {textAlign: 'right', color: 'red'} : {textAlign: 'right'}}>
                      {
                        <span>
                        {new Intl.NumberFormat('nl-NL', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(product.price_gross_onetime)}
                      </span>
                      }
                    </TableRowColumn>)
                  }

                  {module.isCustomCategory && (
                    <TableRowColumn
                      style={product.fetchedFromGrexx ? {textAlign: 'right', color: 'red'} : {textAlign: 'right'}}>
                      {
                        <span>
                        {new Intl.NumberFormat('nl-NL', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(product.setUpPrice)}
                      </span>
                      }
                    </TableRowColumn>)
                  }

                  {module.isCustomCategory && (
                    <TableRowColumn
                      style={product.fetchedFromGrexx ? {textAlign: 'right', color: 'red'} : {textAlign: 'right'}}>
                      {
                        <span>
                        {new Intl.NumberFormat('nl-NL', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(product.installationPrice)}
                      </span>
                      }
                    </TableRowColumn>)
                  }

                  {module.isCustomCategory && (
                    <TableRowColumn
                      style={product.fetchedFromGrexx ? {textAlign: 'right', color: 'red'} : {textAlign: 'right'}}>
                      {
                        <span>
                          {new Intl.NumberFormat('nl-NL', {
                            style: 'currency',
                            currency: 'EUR'
                          }).format(product.pmPrice)}
                        </span>
                      }
                    </TableRowColumn>)
                  }

                  <TableRowColumn
                    style={product.fetchedFromGrexx ? {textAlign: 'right', color: 'red'} : {textAlign: 'right'}}>
                    {new Intl.NumberFormat('nl-NL', {
                      style: 'currency',
                      currency: 'EUR'
                    }).format(this.calculateTotalMonthlyPrice(product))}
                  </TableRowColumn>

                  <TableRowColumn
                    style={product.fetchedFromGrexx ? {textAlign: 'right', color: 'red'} : {textAlign: 'right'}}>
                    {new Intl.NumberFormat('nl-NL', {
                      style: 'currency',
                      currency: 'EUR'
                    }).format(this.calculateTotalOneTimePrice(product, module.isCustomCategory))}
                  </TableRowColumn>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </RatioOverflowTableWrapper>
        <div className="line30" style={{marginBottom: '10px'}}/>
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps,)(DataManagementProductsGridComputed);