import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import Menu from '../../components/menu';
import Navbar from '../../components/navbar';
import Authorize from '../../utils/authorize';
import authenticationActions from '../../actions/authenticationActions';
import adminActions from "../../actions/adminActions";
import { hasRequiredRole } from '../../utils/authorization';
import Step from "../../containers/steps/step";

import UsersPanel from './Users/UsersPanel';
import UserPanel from './Users/UserPanel';
import Themes from './Themes';
import HardwareOptionsPanel from './HardwareOptions/HardwareOptionsPanel';
import PartnersServicesPanel from './PartnersServices/PartnersServicesPanel';
import CompensationPanel from "./Compensation/CompensationPanel";
import WordDocumentsPanel from "./WordDocuments/WordDocumentsPanel";
import OrganizationsPanel from "./Organizations/OrganizationsPanel";
import SettingsPanel from "./Settings/SettingsPanel";
import ProductsOverviewPanel from "./ProductsOverview/ProductsOverviewPanel";
import TagsManagementPanel from "./TagsManagement/TagsManagementPanel";
import DataManagementPanel from "./DataManagement/DataManagementPanel";
import TagCategoriesManagementPanel from "./TagCategoriesManagement/TagCategoriesManagementPanel";
import DownloadsPanel from "./Downloads/DownloadsPanel";
import PostcodeManagementPanel from "./PostcodeManagement/PostcodeManagementPanel";
import PostcodeCategoriesManagementPanel from "./PostcodeCategoriesManagement/PostcodeCategoriesManagementPanel";
import DigitalSigningGroupsPanel from "./DigitalSigning/DigitalSigningGroupsPanel";
import DigitalSigningFieldPanel from "./DigitalSigning/DigitalSigningFieldPanel";
import organization from "../../constants/organization";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import ProductsAdjustingPanel from "./ProductsAdjusting/ProductsAdjustingPanel";
import OrganizationProvidersLogosPanel from "./OrganizationProvidersLogos/OrganizationProvidersLogosPanel";
import Attachments from "./Attachments/Attachments";
import OrdersPanel from "./OrdersOverview/OrdersPanel";

class AdminPanel extends Component {

  redirectToHome = () => {
    this.props.history.push('/');
  };

  componentWillMount() {
    const hasAccess = hasRequiredRole(this.props.authentication.roles, 'ROLE_ADMIN') || hasRequiredRole(this.props.authentication.roles, 'ROLE_MANAGE_USERS');
    if (!hasAccess) {
      this.redirectToHome();
    }
  }

  goToAdminPage = () => {
    this.props.history.push('/admin');
  };

  logout = () => {
    this.props.authenticationActions.logout();
  };

  renderContent = () => {
    switch (this.props.currentSectionId) {
      case 'organizations':
        return <OrganizationsPanel />;
      case 'users':
        return <UsersPanel />;
      case 'user':
        return <UserPanel />;
      case 'settings':
        return <SettingsPanel />;
      case 'themes':
        return <Themes />;
      case 'hardware':
        return <HardwareOptionsPanel />;
      case 'services':
        return <PartnersServicesPanel />;
      case 'compensations':
        return <CompensationPanel/>;
      case 'word-documents':
        return <WordDocumentsPanel />;
      case 'products-overview':
        return <ProductsOverviewPanel />;
      case 'products-adjusting':
        return <ProductsAdjustingPanel />;
      case 'data-management':
        return <DataManagementPanel />;
      case 'tags-management':
        return <TagsManagementPanel />;
      case 'tag-categories-management':
        return <TagCategoriesManagementPanel />;
      case 'refused-postcode-management':
        return <PostcodeManagementPanel />;
      case 'postcode-categories-management':
        return <PostcodeCategoriesManagementPanel />;
      case 'downloads':
        return <DownloadsPanel />;
      case 'digital-signing':
        return <DigitalSigningGroupsPanel />;
      case 'digital-signing-field':
        return <DigitalSigningFieldPanel />;
      case 'terms-and-conditions':
        return <TermsAndConditions />;
      case 'attachments':
        return <Attachments />;
      case 'organization-providers-logos':
        return <OrganizationProvidersLogosPanel/>;
      case 'orders-overview':
        return <OrdersPanel/>;
      default:
        return null;
    }
  };

  setCurrentStep = (sectionId) => {
    this.props.adminActions.setCurrentSection(sectionId);
  };

  switchOrganization = async ({target}) => {
    const organizationId = target.value;
    await this.props.authenticationActions.switchOrganization(organizationId);
    const {switchOrganization} = this.props.authentication;

    if (switchOrganization) {
      window.location.reload();
    }
  }

  render() {
    const {currentSectionId} = this.props;
    const {name, company, theme, isSalesValue, isDoceri } = this.props.authentication;

    const currentOrganization = this.props.authentication.providers[0].organization;
    const clearMindUserException = currentOrganization.name === organization.CLEARMIND && !['clearmind@doceri.nl', 'fabian.olieberg@clearmind.nu'].includes(this.props.authentication.username);

    let roleChecks = {
      'users': ['ROLE_ADMIN', 'ROLE_MANAGE_USERS'],
      'settings': ['ROLE_ADMIN'],
      'themes': ['ROLE_ADMIN'],
      'hardware': ['ROLE_ADMIN'],
      'services': ['ROLE_ADMIN'],
      'word-documents': ['ROLE_ADMIN'],
      'products-overview': ['ROLE_ADMIN'],
      'products-adjusting': ['ROLE_ADMIN'],
      'downloads': ['ROLE_ADMIN'],
      'digital-signing': ['ROLE_ADMIN'],
      'digital-signing-field': ['ROLE_ADMIN'],
      'terms-and-conditions': ['ROLE_ADMIN'],
      'attachments': ['ROLE_ADMIN'],
      'organization-providers-logos': ['ROLE_ADMIN'],
      'orders-overview': ['ROLE_ADMIN']
    };

    if (!isSalesValue) {
      roleChecks['compensations'] = ['ROLE_ADMIN'];
    }

    if (isDoceri) {
      roleChecks['organizations'] = ['ROLE_ADMIN'];
      roleChecks['data-management'] = ['ROLE_ADMIN'];
      roleChecks['tags-management'] = ['ROLE_ADMIN'];
      roleChecks['tag-categories-management'] = ['ROLE_ADMIN'];
      roleChecks['refused-postcode-management'] = ['ROLE_ADMIN'];
      roleChecks['postcode-categories-management'] = ['ROLE_ADMIN'];
      roleChecks['digital-signing'] = ['ROLE_ADMIN'];
      roleChecks['digital-signing-field'] = ['ROLE_ADMIN'];
      roleChecks['terms-and-conditions'] = ['ROLE_ADMIN'];
      roleChecks['attachments'] = ['ROLE_ADMIN'];
      roleChecks['organization-providers-logos'] = ['ROLE_ADMIN'];
      roleChecks['orders-overview'] = ['ROLE_ADMIN'];
    }

    return (
      <div>
        <Navbar
          onAdminClick={this.goToAdminPage}
          onLogoutClick={this.logout}
          onSettingsClick={this.redirectToHome}
          alerts={this.props.alerts}
          switchableOrganizationList={this.props.authentication.switchableOrganizationList}
          switchOrganizationAction={this.switchOrganization}
          allowSwitch={this.props.authentication.allowSwitch}
        />
        <div className="valign-wrapper">
          <div className="valign">
            <div className="container maincontainer">
              <div className="section">
                <div className="row" style={{ marginBottom: '5px' }}>
                  <Menu fullName={name} company={company} theme={theme} />
                  <div className="col s12 m12 l9">
                    <div id="Steps">
                      <div>
                        {this.props.adminMenuItems.filter((item) => !clearMindUserException || !['word-documents', 'products-overview'].includes(item.id))
                            .map(menuItem => {
                          if (roleChecks.hasOwnProperty(menuItem.id)) {
                            return (
                                menuItem.displayName ?
                                    <Authorize allowedRoles={roleChecks[menuItem.id]}>
                                      <Step
                                          config={menuItem}
                                          key={menuItem.id}
                                          currentStep={currentSectionId}
                                          onClick={() => this.setCurrentStep(menuItem.id)}
                                      />
                                    </Authorize> : ''
                            )
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {this.renderContent()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication, adminMenu: {adminMenuItems, currentSectionId}, alerts }) => {
  return {
    authentication,
    adminMenuItems,
    currentSectionId,
    alerts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminActions: bindActionCreators(adminActions, dispatch),
    authenticationActions: bindActionCreators(authenticationActions, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminPanel));
