import React, {Component} from 'react';
import {Input, Row, Col} from 'react-materialize';
import connect from "react-redux/lib/connect/connect";
import addMonths from 'date-fns/addMonths';
import {formatDate, isElInput, sleep} from "../../../utils/utils";
import ReactDOM from "react-dom";

import DatePicker from "../../../components/datePicker";
import KpnDialog from "../../../containers/common/KpnDialog/KpnDialog";
import DialogHeader from "../../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";
import DialogFooter from "../../../containers/common/KpnDialog/DialogFooter";
import DialogButton from "../../../containers/common/KpnDialog/DialogButton";

import telephoneNumberAction from "../../../constants/telephoneNumberType";
import qType from "../../../constants/quotationType";

class HostedVoiceNumberDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      number: {
        id: null,
        startingNumber: '',
        firstName: '',
        middleName: '',
        lastName: '',
        street: '',
        houseNumber: '',
        houseNumberExtension: '',
        city: '',
        zipCode: '',
        listedInPhoneBook: false,
        flexiblePorting: false,
        contractEndDate: null,
        portingDate: null,
        contractTerminationDuration: null,
        label: ''
      }
    }
  };

  componentDidMount() {
    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);
    });

    const {hostedVoiceNumber} = this.props;
    this.setState({
      number: {
        ...hostedVoiceNumber,
        portingDate: formatDate(hostedVoiceNumber.portingDate),
        contractEndDate: formatDate(hostedVoiceNumber.contractEndDate),
        contractTerminationDuration: hostedVoiceNumber.contractTerminationDuration || 1
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.hostedVoiceNumber !== prevProps.hostedVoiceNumber) {
      const {hostedVoiceNumber} = this.props;
      this.setState({
        number: {
          ...hostedVoiceNumber,
          portingDate: formatDate(hostedVoiceNumber.portingDate),
          contractEndDate: formatDate(hostedVoiceNumber.contractEndDate),
          contractTerminationDuration: hostedVoiceNumber.contractTerminationDuration || 1
        }
      });
    }
  };

  onChange = ({target}) => {
    let number = {...this.state.number};
    let {name, value, type, checked} = target;

    if ('checkbox' === type) {
      value = checked;
    }

    if ('flexiblePorting' === name && checked === true) {
      number['portingDate'] = null;
      number['contractEndDate'] = null;
    }

    number[name] = value;

    this.setState({
      number
    });

    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);
    });
  };

  onChangePortingDate = (date) => {
    this.setState({
      number: {
        ...this.state.number,
        portingDate: formatDate(date)
      }
    });
  };

  onChangeContractEndDate = (date) => {
    this.setState({
      number: {
        ...this.state.number,
        contractEndDate: formatDate(date)
      }
    });

    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);

      let invalidElements = ReactDOM.findDOMNode(this.globalForm).getElementsByClassName('invalid');

      if (!invalidElements.length) {
        this.props.onSubmit(this.state.number);
      }
    });

  };

  checkInputsForValidity = (el) => {
    if (el && el.children) {
      Array.from(el.children).forEach((child) => {
        this.checkInputsForValidity(child);
      });
    }

    if (isElInput(el) === false) {
      return;
    }

    const parentElement = el.parentElement;

    if (el.checkValidity() === false) {
      el.classList.remove('valid');
      el.classList.add('invalid');

      if (parentElement.classList.contains('select-wrapper')) {
        parentElement.classList.remove('valid');
        parentElement.classList.add('invalid');
      }
    }

    if (el.getAttribute('required') !== null && el.checkValidity() === true) {
      el.classList.remove('invalid');
      el.classList.add('valid');

      if (parentElement.classList.contains('select-wrapper')) {
        parentElement.classList.remove('invalid');
        parentElement.classList.add('valid');
      }
    }
  };

  minDate = () => {
    const {number} = this.state;

    if (number.portingDate || (number.portingDate && number.contractTerminationDuration)) {
      return addMonths(new Date(number.portingDate), parseInt(number.contractTerminationDuration, 10));
    }

    return new Date();
  };

  getEndDateLabel = () => {
    const {number} = this.state;
    const {quotationType} = this.props;
    const isVerlengenQuotation = quotationType === qType.TYPE_VERLENGEN;
    const verlengenCondition = [7, 8].includes(number.action);

    if (isVerlengenQuotation && verlengenCondition) {
      return number.action === 7 ? 'Einddatum verlengde contract' : 'Per wanneer opheffen?';
    }

    return 'Einddatum nieuw contract';
  };

  render() {
    const {quotationType, inputsDisabled} = this.props;
    const {number} = this.state;
    const isTypePorteren = number.existing === 1;
    const isVerlengenAction = number.action === 7;
    const isVerlengenQuotation = quotationType === qType.TYPE_VERLENGEN;
    const isVerlengenBetaType = [qType.TYPE_VERLENGEN, qType.TYPE_EXISTING].includes(quotationType);
    const verlengenCondition = [6, 7, 8].includes(number.action);

    return (
      <div className="telephone-number-dialog">
        {<KpnDialog
          dialogState={this.props.dialogOpened}
          modal={false}
          overflowException={isVerlengenQuotation}
          dialogHeader={(
            <DialogHeader
              headerIcon="edit_location"
              headerText={`Bewerk ${number.startingNumber}`}
              closeHandler={this.props.onCancel}
            />
          )}

          dialogBody={
            <DialogBody>
              {
                <form ref={(ref) => (this.globalForm = ref)}>

                  {!(isVerlengenQuotation && verlengenCondition) &&
                  <Row>
                    <Input
                      s={4}
                      value={number.firstName}
                      onChange={this.onChange}
                      label="Voornaam"
                      name="firstName"
                      type="text"
                      disabled={inputsDisabled}
                    />
                    <Input
                      s={4}
                      value={number.middleName}
                      onChange={this.onChange}
                      label="Tussenvoegsels"
                      name="middleName"
                      type="text"
                      disabled={inputsDisabled}
                    />
                    <Input
                      s={4}
                      value={number.lastName}
                      onChange={this.onChange}
                      label="Achternaam *"
                      name="lastName"
                      type="text"
                      required
                      validate={true}
                      disabled={inputsDisabled}
                    />
                  </Row>
                  }

                  {!(isVerlengenQuotation && verlengenCondition) &&
                  <Row>
                    <Input
                      s={8}
                      value={number.street}
                      onChange={this.onChange}
                      label="Straat *"
                      name="street"
                      type="text"
                      required
                      validate={true}
                      disabled={inputsDisabled}
                    />
                    <Input
                      s={2}
                      value={number.houseNumber}
                      onChange={this.onChange}
                      label="Huisnummer *"
                      name="houseNumber"
                      type="text"
                      pattern="[0-9]*"
                      required
                      validate={true}
                      disabled={inputsDisabled}
                    />
                    <Input
                      s={2}
                      value={number.houseNumberExtension}
                      onChange={this.onChange}
                      label="toevoeging"
                      name="houseNumberExtension"
                      maxLength="4"
                      minLength="0"
                      pattern="[a-zA-Z0-9]+"
                      type="text"
                      validate={true}
                      disabled={inputsDisabled}
                    />
                  </Row>
                  }

                  {!(isVerlengenQuotation && verlengenCondition) &&
                  <Row>
                    <Input
                      s={2}
                      value={number.zipCode}
                      onChange={this.onChange}
                      label="Postcode *"
                      name="zipCode"
                      type="text"
                      pattern="[1-9][0-9]{3}[a-zA-Z]{2}"
                      required
                      validate={true}
                      disabled={inputsDisabled}
                    />
                    <Input
                      s={10}
                      value={number.city}
                      onChange={this.onChange}
                      label="Woonplaats *"
                      name="city"
                      type="text"
                      pattern="[a-zA-Z\s]+"
                      required
                      validate={true}
                      disabled={inputsDisabled}
                    />
                  </Row>
                  }

                  <Row>
                    {!number.flexiblePorting && isTypePorteren && !(isVerlengenQuotation && verlengenCondition) &&
                    <Col s={4} className="input-field porting-date-field">
                      <label htmlFor={"portingDate"}>Porteren wensdatum</label>
                      <DatePicker
                        name="portingDate"
                        id={"portingDate"}
                        selected={number.portingDate ? new Date(number.portingDate) : ''}
                        onChange={this.onChangePortingDate}
                        onChangeRaw={e => e.preventDefault()}
                        dateFormat="dd-MM-yyyy"
                        minDate={new Date()}
                        autoComplete="off"
                        required={!number.flexiblePorting}
                        disabled={inputsDisabled}
                      />
                    </Col>
                    }

                    {((number.flexiblePorting && isTypePorteren && !(isVerlengenQuotation && verlengenCondition)) || isVerlengenAction) &&
                    <Input
                      s={4}
                      value={number.portingTag}
                      onChange={this.onChange}
                      label="Porterings kenmerk"
                      name="portingTag"
                      pattern="[a-zA-Z0-9]{9}"
                      type="text"
                      validate={true}
                      disabled={inputsDisabled}
                    />
                    }

                    {!number.flexiblePorting &&
                    <Col s={4} className="input-field porting-date-field">
                      <label htmlFor={"contractEndDate"}>{this.getEndDateLabel()}</label>
                      <DatePicker
                        name="contractEndDate"
                        id={"contractEndDate"}
                        selected={number.contractEndDate ? new Date(number.contractEndDate) : ''}
                        onChange={this.onChangeContractEndDate}
                        onChangeRaw={e => e.preventDefault()}
                        dateFormat="dd-MM-yyyy"
                        minDate={this.minDate()}
                        autoComplete="off"
                        required={true}
                        disabled={inputsDisabled}
                      />
                    </Col>
                    }

                    {(!(isVerlengenQuotation && verlengenCondition) || isVerlengenAction) &&
                    <Input
                      s={4}
                      value={number.contractTerminationDuration}
                      onChange={this.onChange}
                      label="Opzegtermijn nieuw contract (maanden)"
                      name="contractTerminationDuration"
                      type="number"
                      min={1}
                      max={3}
                      required={true}
                      validate={true}
                      disabled={inputsDisabled}
                    />
                    }
                  </Row>

                  {!(isVerlengenQuotation && verlengenCondition) &&
                  <div>
                    <Input
                      s={4}
                      value={number.label}
                      onChange={this.onChange}
                      label="Label"
                      name="label"
                      type="text"
                      disabled={inputsDisabled}
                    />
                  </div>
                  }

                  {isTypePorteren && !(isVerlengenQuotation && verlengenCondition) &&
                  <>
                    <Row>
                      <Input
                        s={12}
                        value={1}
                        checked={number.flexiblePorting}
                        onChange={this.onChange}
                        label="Flexibel porteren"
                        name="flexiblePorting"
                        type="checkbox"
                        disabled={inputsDisabled}
                      />
                    </Row>
                  </>
                  }

                  {isVerlengenBetaType && verlengenCondition && number.action !== telephoneNumberAction.OPHEFFEN_ACTION &&
                  <div className="row" style={{marginTop: '-20px', marginBottom: '35px'}}>
                    <div className="col s12">
                      <input
                        id="sameDate"
                        name="generalDate"
                        className="input-field"
                        checked={!!number.generalDate}
                        type="checkbox"
                        onChange={this.onChange}
                        disabled={inputsDisabled}
                      />
                      <label htmlFor="sameDate">
                        {number.action === telephoneNumberAction.OPHEFFEN_ACTION ? 'Optie ' : 'Datum '}
                        overal toepassen voor
                        {number.action === telephoneNumberAction.OPHEFFEN_ACTION && ' opheffen'}
                        {number.action === telephoneNumberAction.VERLENGEN_ACTION && ' verlengen'}
                        {number.action === telephoneNumberAction.UPGRADE_ACTION && ' upgrades'}
                      </label>
                    </div>
                  </div>
                  }
                </form>
              }
            </DialogBody>
          }
          dialogFooter={
            <DialogFooter
              buttonLeft={(
                <DialogButton
                  buttonAction={this.props.onCancel}
                  buttonText='Annuleren'
                  left={true}
                />
              )}
              buttonRight={(
                <DialogButton
                  buttonAction={this.onSubmit}
                  buttonText='Opnieuw versturen'
                />
              )}
            />
          }
        />}
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {}
};

const mapDispatchToProps = () => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(HostedVoiceNumberDialog);
