import React, {Component} from 'react';

class ServicesStep extends Component {
  render() {
    return (
      <div>
        <h1> This is ServicesStep </h1>
      </div>
    )
  }
}

export default ServicesStep;