import {
    updateQuotationOptions,
    updateQuotationOptionsSuccess,
    updateQuotationOptionsError,
} from '../../isdnMigrationActions/updateQuotationOptions';
import {apiRoutes} from '../../../constants/apiRoutes';
import ApiService from '../../../services/apiService';
import RequestType from '../../../constants/requestType';

const updateQuotationOptionsThunk = (quotationId, type, productId) => async (
    dispatch,
) => {
    try {
        const url = apiRoutes.isdnMigrationRelated.patchQuotationOptions.replace(
            /{quotationId}/,
            quotationId,
        );

        const requestData = await ApiService.performRequest(
            RequestType.PATCH,
            url,
            {productId: +productId, type},
        );

        dispatch(updateQuotationOptionsSuccess(requestData));
    } catch (error) {
        dispatch(updateQuotationOptionsError(error));
    }
};

export default updateQuotationOptionsThunk;
