import React from 'react';

import KpnDialog from '../containers/common/KpnDialog/KpnDialog';
import DialogHeader from "../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../containers/common/KpnDialog/DialogBody";
import DialogFooter from "../containers/common/KpnDialog/DialogFooter";
import DialogButton from "../containers/common/KpnDialog/DialogButton";
import NewQuotationDialog from "../containers/common/NewQuotationDialog";
import RegularSelect from '../components/RegularSelect';
import GeneralDialog from "../containers/common/GeneralDialog";
import LoaderComponent from "../containers/common/LoaderComponent";

const DashboardDialogs = (props) => {
  let {
    isNewOpened,
    isDeleteOpened,
    isUndoOpened,
    isEditOpened,
    isTakeOverOpened,
    closeDialogFunc,
    createNewQuotationFunc,
    confirmEditionFunc,
    confirmDeletionFunc,
    confirmUndoFunc,
    confirmTakeOverFunc,
    confirmProjectManagerFunc,
    selectedRow,
    onSelectedRowChangeFunc,
    isSalesValue,
    providers,
    goToQuotationFunc,
    createNewQuotationVersionFunc,
    notLatestVersionWarningOpened,
    mostRecentVersion,
    isPitOrganization,
    authentication,
    isLeadOverview,
    loading,
    isYielderPartner
  } = props;

  return (
    <div>
      <NewQuotationDialog
        isNewOpened={isNewOpened}
        closeDialogFunc={closeDialogFunc}
        createNewQuotationFunc={createNewQuotationFunc}
        isSalesValue={isSalesValue}
        providers={providers}
        stepActions={props.stepActions}
        isPitOrganization={isPitOrganization}
        authentication={authentication}
        isLeadOverview={isLeadOverview}
        isYielderPartner={isYielderPartner}
      />


      <GeneralDialog
        dialogState={isTakeOverOpened}
        onRequestClose={() => closeDialogFunc('isTakeOverOpened')}
        modal={false}
        headerText={selectedRow.previousUser !== '' ? 'Offerte teruggeven aan gebruiker' : 'Offerte overnemen van gebruiker'}
        closeHandler={() => closeDialogFunc('isTakeOverOpened')}
        leftButtonAction={() => closeDialogFunc('isTakeOverOpened')}
        leftButtonText={"Annuleren"}
        leftButtonLeft={true}
        rightButtonAction={confirmTakeOverFunc}
        rightButtonText={"Bevestigen"}
      />

      <GeneralDialog
        width={'55%'}
        dialogState={notLatestVersionWarningOpened}
        onRequestClose={() => closeDialogFunc('notLatestVersionWarningOpened')}
        modal={false}
        headerText="LET OP: De offerte die u probeert te openen is niet de meest recente versie"
        closeHandler={() => closeDialogFunc('notLatestVersionWarningOpened')}
        dialogBodyContent={`Er is een nieuwe versie van de offerte die u probeert te openen. De meest recente versie is ${mostRecentVersion}. Wat wilt u doen?`}
        leftButtonAction={() => goToQuotationFunc(selectedRow)}
        leftButtonText={"Huidige bewerken"}
        leftButtonLeft={true}
        centerButtonAction={() => createNewQuotationVersionFunc(selectedRow, true)}
        centerButtonText={"Nieuw vanuit deze offerte"}
        centerButtonLeft={true}
        rightButtonAction={() => createNewQuotationVersionFunc(selectedRow, true, true)}
        rightButtonText={"Nieuw vanuit meest recente offerte"}
      />

      <GeneralDialog
        width={'55%'}
        dialogState={isEditOpened && ! selectedRow.isExpired && ! selectedRow.syncStatus}
        onRequestClose={() => closeDialogFunc('isEditOpened')}
        modal={false}
        headerText="LET OP: De offerte die u probeert te openen is niet de meest recente versie"
        closeHandler={() => closeDialogFunc('isEditOpened')}
        dialogBodyContent={`Er is een nieuwe versie van de offerte die u probeert te openen. De meest recente versie is ${mostRecentVersion}. Wat wilt u doen?`}
        dialogFooterChildren={(
          <div className="row">
            <div className="col s12" style={{ marginTop: '20px' }}>
              <DialogButton
                buttonAction={() => createNewQuotationVersionFunc(selectedRow, true, true)}
                buttonText='Maak een nieuwe versie op basis van de meest recente versie'
                style={{ width: '100%' }}
              />
            </div>
            <div className="col s12" style={{ marginTop: '20px' }}>
              <DialogButton
                buttonAction={() => goToQuotationFunc(selectedRow)}
                buttonText='Open gekozen offerte en maak geen nieuwe versie'
                style={{ width: '100%' }}
              />
            </div>
            <div className="col s12" style={{ marginTop: '20px' }}>
              <DialogButton
                buttonAction={() => createNewQuotationVersionFunc(selectedRow, true)}
                buttonText='Maak een nieuwste versie op basis van deze geselecteerde, zelfs als het een oudere is'
                style={{ width: '100%' }}
              />
            </div>
          </div>
        )}
      />

      {loading ?
        <LoaderComponent theme={authentication.theme}/> :
        <GeneralDialog
          dialogState={isEditOpened && ! selectedRow.isExpired && selectedRow.syncStatus}
          onRequestClose={() => closeDialogFunc('isEditOpened')}
          modal={false}
          headerText="Wilt u een nieuwe versie creëren?"
          closeHandler={() => closeDialogFunc('isEditOpened')}
          dialogBodyContent={'Wilt u van deze offerte een nieuwe versie creëren of de huidige versie bewerken?'}
          leftButtonAction={() => createNewQuotationVersionFunc(selectedRow)}
          leftButtonText={"Nieuwe versie creëren"}
          leftButtonLeft={true}
          rightButtonAction={() => goToQuotationFunc(selectedRow)}
          rightButtonText={"Huidige bewerken"}
        />
      }

      <GeneralDialog
        dialogState={isEditOpened && selectedRow.isExpired}
        onRequestClose={() => closeDialogFunc('isEditOpened')}
        modal={false}
        headerText="Offerte bewerken niet mogelijk"
        closeHandler={() => closeDialogFunc('isEditOpened')}
        dialogBodyContent={'Deze offerte is ouder dan 6 maanden. Daarom kunt u de offerte inzien, maar niet meer bewerken. Ook is orderen vanuit deze offerte niet meer mogelijk.'}
        rightButtonAction={() => goToQuotationFunc(selectedRow)}
        rightButtonText={'Begrepen'}
      />

      <GeneralDialog
        dialogState={isDeleteOpened}
        onRequestClose={() => closeDialogFunc('isDeleteOpened')}
        modal={true}
        headerIcon={"remove_circle_outline"}
        headerText={"Offerte verwijderen"}
        leftButtonAction={() => closeDialogFunc('isDeleteOpened')}
        leftButtonText={"Nee"}
        rightButtonAction={confirmDeletionFunc}
        rightButtonText={"Ja"}
        leftButtonLeft={false}
        rightButtonLeft={true}
      />

      <GeneralDialog
        dialogState={isUndoOpened}
        onRequestClose={() => closeDialogFunc('isUndoOpened')}
        modal={true}
        headerIcon="undo"
        headerText="Offerte opnieuw activeren"
        dialogBodyContent={"Door ervoor te kiezen voor het opnieuw activeren van de offerte maakt u het verwijderen weer ongedaan.De offerte zal voor de gebruiker ook weer zichtbaar worden in zijn of haar overzicht."}
        leftButtonAction={() => closeDialogFunc('isUndoOpened')}
        leftButtonText={"Nee"}
        leftButtonLeft={true}
        rightButtonAction={confirmUndoFunc}
        rightButtonText={"Ja"}
      />

      <GeneralDialog
        dialogState={props.isProjectManagerOpened}
        onRequestClose={() => closeDialogFunc('isProjectManagerOpened')}
        modal={true}
        headerText="Offerte toewijzen aan gebruiker"
        closeHandler={() => closeDialogFunc('isProjectManagerOpened')}
        dialogBodyContent={(
          <div className="col s12" style={{ marginBottom: '10%' }}>
            <label htmlFor={"assignUser"} style={{ fontSize: '14px' }}>Selecteer een gebruiker</label>
            <RegularSelect
              name="user"
              id={"assignUser"}
              onChange={(event) => {
                props.onProjectManagerChange(event)
              }}
              value={props.assignUser || ''}
              multiple={false}
            >
              <option value={''} name="username">
                {'Selecteer een gebruiker'}
              </option>
              {props.renderProjectManagerListOptions()}
            </RegularSelect>
          </div>
        )}
        leftButtonAction={() => closeDialogFunc('isProjectManagerOpened')}
        leftButtonText={"Annuleren"}
        leftButtonLeft={true}
        rightButtonAction={confirmProjectManagerFunc}
        rightButtonText={"Bevestigen"}
      />
    </div>
  );
};

export default DashboardDialogs;
