import {
  actionTypes
} from '../constants/actionTypes';

const initialState = {
    currentSoftAlert: '',
    currentHardAlert: '',
    allSoftAlerts: [],
    allHardAlerts: []
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.setCurrentSoftAlert:
            return {
                ...state,
                currentSoftAlert: action.payload
            };
        case actionTypes.setCurrentHardAlert:
            return {
                ...state,
                currentHardAlert: action.payload
            };
        case actionTypes.addSoftAlert:
            return {
                ...state,
                allSoftAlerts: [...state.allSoftAlerts, action.payload],
                currentSoftAlert: action.payload
            };
        case actionTypes.addHardAlert:
            return {
                ...state,
                allHardAlerts: [...state.allHardAlerts, action.payload],
                currentHardAlert: action.payload
            };
        case actionTypes.removeSpecificSoftAlert:
            let filteredSoftAlerts = [...state.allSoftAlerts].filter((softAlert) => softAlert !== action.payload);
            let previousSoftAlert = filteredSoftAlerts.length ? filteredSoftAlerts[filteredSoftAlerts.length-1] : '';

            return {
                ...state,
                allSoftAlerts: filteredSoftAlerts,
                currentSoftAlert: previousSoftAlert
            };
        case actionTypes.removeSpecificHardAlert:
            let filteredHardAlerts = [...state.allHardAlerts].filter((hardAlert) => hardAlert !== action.payload);
            let previousHardAlert = filteredHardAlerts.length ? filteredHardAlerts[filteredHardAlerts.length -1] : '';

            return {
                ...state,
                allHardAlerts: filteredHardAlerts,
                currentHardAlert: previousHardAlert
            };
        case actionTypes.resetAlerts:
            return {
                ...state,
                allSoftAlerts: [],
                allHardAlerts: [],
                currentSoftAlert: '',
                currentHardAlert: '',
            };
        case actionTypes.resetSoftAlerts:
            return {
                ...state,
                allSoftAlerts: [],
                currentSoftAlert: ''
            };
        case actionTypes.resetHardAlerts:
            return {
                ...state,
                allHardAlerts: [],
                currentHardAlert: ''
            };
        case actionTypes.removeLastSoftAlert:
            let softAlerts = [...state.allSoftAlerts];
            softAlerts.pop();
            return {
                ...state,
                allSoftAlerts: softAlerts,
                currentSoftAlert: softAlerts.length ? softAlerts[softAlerts.length - 1] : ''
            };
        case actionTypes.removeLastHardAlert:
            let hardAlerts = [...state.allHardAlerts];
            hardAlerts.pop();
            return {
                ...state,
                allHardAlerts: hardAlerts,
                currentHardAlert: hardAlerts.length ? hardAlerts[hardAlerts.length - 1] : ''
            };
        default:
            return state;
    }
}
