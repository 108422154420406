import React from 'react';

import DialogHeader from "../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../containers/common/KpnDialog/DialogBody";
import DialogButton from "../../containers/common/KpnDialog/DialogButton";
import KpnDialog from "../../containers/common/KpnDialog/KpnDialog";
import DialogFooter from "../../containers/common/KpnDialog/DialogFooter";
import {Input} from "react-materialize";

const getActionName = (action) => {
  switch (action) {
    case 5:
      return 'Nieuw';
    case 1:
      return 'Porteren';
    case 4:
      return 'Simless porteren';
    default:
      return 'Nieuw';
  }
};

const BulkDataDialog = (props) => {
  const {
    dialogOpened, onCancelDialog, onChangeAction, selectedBulkAction, saveBulkAction,
    onOutletRequiredSelect, isOuletRequiredDropDown, selectedOutletRequiredValue,
    onPlacingBoxSelect, selectedPlacingBoxValue, isPlacingBoxDropDown, isSkipTestingLabelingDropDown,
    onSkipTestingLabelingSelect, selectedSkipTestingLabelValue
  } = props;

  return (
    <KpnDialog
      dialogState={dialogOpened}
      modal={false}
      style={{minHeight: '350px'}}
      dialogHeader={(
        <DialogHeader
          headerIcon="edit_location"
          headerText={`${getActionName(selectedBulkAction)}: Data in bulk aanvullen`}
          closeHandler={onCancelDialog}
        />
      )}

      dialogBody={
        <DialogBody>
          <Input
            s={12}
            type="select"
            label="Voor welke actie wilt u een bulk aanpassing doorvoeren?*"
            onChange={onChangeAction}
            multiple={false}
            value={selectedBulkAction}
          >
            <option key={1} value="5">Nieuw</option>
            <option key={2} value="1">Porteren</option>
            <option key={3} value="4">Simmless porteren</option>
            <option key={4} value="11">DSL Verbindingen</option>
            <option key={4} value="12">FTTH Verbindingen</option>
          </Input>

          {isOuletRequiredDropDown &&
            <Input
                s={12}
                type="select"
                label="Voor welke actie wilt u een bulk aanpassing doorvoeren?*"
                onChange={onOutletRequiredSelect}
                multiple={false}
                value={selectedOutletRequiredValue}
            >
              <option key={1} value={-1}>Selecteer</option>
              <option key={2} value={0}>Geen bulk voor DSL</option>
              <option key={3} value={1}>Wandcontactdoos mee orderen</option>
            </Input>
          }
          {isPlacingBoxDropDown &&
            <Input
                s={12}
                type="select"
                label="Voor welke actie wilt u een bulk aanpassing doorvoeren?"
                onChange={onPlacingBoxSelect}
                multiple={false}
                value={selectedPlacingBoxValue}
            >
              <option key={1} value={0}>Selecteer</option>
              <option key={2} value={1}>FTU kastje</option>
            </Input>
          }
          {isSkipTestingLabelingDropDown &&
            <Input
                s={12}
                type="select"
                label="Voor welke actie wilt u een bulk aanpassing doorvoeren?"
                onChange={onSkipTestingLabelingSelect}
                multiple={false}
                value={selectedSkipTestingLabelValue}
            >
              <option key={1} value={-1}>Selecteer</option>
              <option key={2} value={0}>Geen bulk voor DSL</option>
              <option key={2} value={1}>Testen en labelen overslaan</option>
            </Input>
          }
        </DialogBody>
      }

      dialogFooter={
        <DialogFooter
          buttonLeft={(
            <DialogButton
              buttonAction={onCancelDialog}
              buttonText='Annuleren'
              left={true}
            />
          )}
          buttonRight={(
            <DialogButton
              buttonAction={saveBulkAction}
              buttonText='Select'
            />
          )}
        />
      }
    />
  );
};

export default BulkDataDialog;
