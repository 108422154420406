import React from 'react';
import Dialog from 'material-ui/Dialog';
import './KpnDialog.css';

const KpnDialog = ({dialogState, dialogHeader, dialogBody, dialogFooter, onRequestClose, modal, autoScrollBodyContent, width, fixedWidth, overflowException, style}) => {
    let contentStyle = {};
    let bodyStyle = style || {};

    if (fixedWidth) {
        contentStyle['width'] = fixedWidth;
    }
    if (width) {
        contentStyle['maxWidth'] = width;
    }

    if (overflowException) {
        bodyStyle['overflowY'] = 'initial';
    }

    return (
        <Dialog
            modal={modal}
            open={dialogState}
            onRequestClose={onRequestClose}
            autoScrollBodyContent={autoScrollBodyContent}
            className="kpn-dialog"
            contentStyle={contentStyle}
            bodyStyle={bodyStyle}
        >
            <div className="row">
                <div id="popup" className="col s12">
                    <div className="md-dialog" id="myDialogAlert">
                        <div className="md-dialog__shadow" />
                        <div className="md-dialog__surface">
                            {
                                dialogHeader && dialogHeader
                            }


                            <div className="md-dialog__body">
                                {
                                    dialogBody && dialogBody
                                }
                            </div>

                            {
                                dialogFooter && dialogFooter
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

KpnDialog.defaultProps = {
    modal: true,
    autoScrollBodyContent: true,
    onRequestClose: () => {},
}

export default  KpnDialog;
