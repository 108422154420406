import {actionTypes} from '../constants/actionTypes';
import {apiRoutes} from '../constants/apiRoutes';
import {toastr} from 'react-redux-toastr'

import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

const getAllTagCategories = (queryParams) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.tagCategoryRelated.getAll + (queryParams ? queryParams : '');
      let requestData = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getAllTagCategoriesSuccess(requestData));
      dispatch(savePaginationParameters(requestData))
    }
    catch ({message}) {
      toastr.error('Het is niet mogelijk de lijst met offertes op te halen', message);
    }
  }
};

const getAllTagCategoriesSuccess = ({tagCategories}) => {
  return {
    type: actionTypes.getAllTagCategories,
    payload: tagCategories
  };
};

const savePaginationParameters = ({currentPage, resultsCount, pages}) => {
  return {
    type: actionTypes.savePagination,
    payload: {
      currentPage,
      resultsCount,
      pages
    }
  }
};

const patchTagCategory = (categoryId, data) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.tagCategoryRelated.patchTagCategory.replace('{categoryId}', categoryId);

      const response = await ApiService.performRequest(RequestType.PATCH, url, data);

      toastr.success('Tag categorie bewerkt', '');
      dispatch(_patchTagSuccess(response));
    }
    catch ({message}) {
      toastr.error('Kan de tag niet updaten', message);
    }
  }
};

const _patchTagSuccess = ({tagCategory}) => {
  return {
    type: actionTypes.patchTagCategory,
    payload: tagCategory
  }
};

const addTagCategory = (data) => {
  return async () => {
    try {
      const url = apiRoutes.tagCategoryRelated.addTagCategory;

      await ApiService.performRequest(RequestType.POST, url, data);

      toastr.success('Tag categorie bewerkt', '');
    }
    catch ({message}) {
      toastr.error('Kan de tag niet updaten', message);
    }
  }
};

export default {
  getAllTagCategories,
  patchTagCategory,
  addTagCategory
}
