import React, {Component} from 'react';
import WordDocumentsGrid from "./WordDocumentsGrid";
import wordDocumentActions from "../../../actions/wordDocumentActions";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import WordDocumentsForm from "./WordDocumentsForm";
import KpnDialog from "../../../containers/common/KpnDialog/KpnDialog";
import DialogHeader from "../../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";
import DialogFooter from "../../../containers/common/KpnDialog/DialogFooter";
import DialogButton from "../../../containers/common/KpnDialog/DialogButton";
import FileDownload from "js-file-download";

class WordDocumentsPanel extends Component {
  constructor(props) {
    super(props);

    this.props.actions.getWordDocuments('');

    this.state = {
      formDialogOpened: false,
      deleteDialogOpened: false,
      selectedDocument: {},
      isLeadView: false,
    };
  }

  openDialog = (document) => {
    this.setState({
      formDialogOpened: true,
      selectedDocument: document
    })
  };

  closeDialog = () => {
    this.setState({
      formDialogOpened: false
    })
  };

  closeDeleteDialog = () => {
    this.setState({
      deleteDialogOpened: false
    });
  };

  onDelete = (document) => {
    this.setState({
      deleteDialogOpened: true,
      selectedDocument: document
    });
  };

  onDeleteSubmit = () => {
    this.props.actions.deleteWordDocument(this.state.selectedDocument.id);
    this.closeDeleteDialog();
  };

  onSubmit = (data) => {
    const oldData = {...data};
    delete data.id;
    if (oldData.id) {
      this.props.actions.editWordDocument(oldData.id, data).then(() => this.closeDialog());
    } else {
      this.props.actions.addWordDocument(data).then(() => this.closeDialog());
    }
  };

  downloadWordDocument = (document) => {
    const {id, name, documentType} = document;

    this.props.actions.downloadWordDocument(id).then((response) => {
      if(response === true) {
        FileDownload(this.props.wordDocuments.documentBlob, `${name}.${documentType}`);
      }
    });
  };

  changeDocumentsView = (oldView) => {
    this.setState({isLeadView: !oldView});
  };

  render() {
    const {allowLead, allowWebLead} = this.props.authentication;
    let {documents, newDocument} = this.props.wordDocuments;
    let {isLeadView} = this.state;
    let selectedType = isLeadView ? 'lead' : 'quotation';
    documents = documents.filter((el) => el.availabilityType === selectedType);

    return (
      <div>
        <div id="Forms" className="ratiotable paddingbottomnone">
          <h1 className="admin-subheader-text"><span className="ratio-section-title">Word documenten</span></h1>
          <button
              className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
              onClick={() => this.openDialog(newDocument)}
              type="submit"
              name="action">
            <i className="small material-icons left white-text">add</i>
            Voeg nieuw word document toe
          </button>
          {allowLead  && (
            <button
              className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
              onClick={() => this.changeDocumentsView(isLeadView)}
              type="submit"
              name="action">
              <i className="small material-icons left white-text">swap_vert</i>
              {isLeadView ? 'Offerte documenten' : 'Lead documenten'}
            </button>
          )}
        </div>

        <WordDocumentsGrid
          onEditClick={this.openDialog}
          onDeleteClick={this.onDelete}
          onDownloadClick={this.downloadWordDocument}
          rows={documents}
        />

        {this.state.formDialogOpened &&
          <WordDocumentsForm
            document={this.state.selectedDocument}
            onClose={this.closeDialog}
            onSubmit={this.onSubmit}
            providers={this.props.authentication.providers}
            allowLead={allowLead}
            allowWebLead={allowWebLead}
            hasQuoteBtn={this.props.authentication.hasQuoteBtn}
          />
        }

        <KpnDialog
          dialogState={this.state.deleteDialogOpened}
          onRequestClose={() => { this.closeDeleteDialog() }}
          modal={true}
          dialogHeader={(
            <DialogHeader
              headerIcon="remove_circle_outline"
              headerText="Bevestig verwijdering"
            />
          )}

          dialogBody={
            <DialogBody>
              <div className="col s12">
                Weet u zeker dat u dit document wilt verwijderen?<br /><br />
              </div>
            </DialogBody>
          }
          dialogFooter={
            <DialogFooter
              buttonLeft={(
                <DialogButton
                  buttonAction={() => { this.closeDeleteDialog() }}
                  buttonText='Nee'
                  left={true}
                />
              )}
              buttonRight={(
                <DialogButton
                  buttonAction={this.onDeleteSubmit}
                  buttonText='Ja'
                />
              )}
            />
          }
        />
      </div>
    )
  }
}

const mapStateToProps = ({wordDocuments, authentication}) => {
  return {
    wordDocuments,
    authentication
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(wordDocumentActions, dispatch)
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WordDocumentsPanel));
