import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUserInfo } from '../actions/authenticationActions';
import { Preloader } from 'react-materialize';
import './loadingComponent.css';

class LoadingComponent extends Component {
  componentDidMount() {
    this.props.getUserInfo();
  }

  render() {
    const { userInfoIsLoading, children } = this.props;
    if(!userInfoIsLoading) {
      return (
        <div className="loading-component">
          {children}
        </div>
      )
    }
    else {
      return (
        <div className="loading-component">
          <div className="preloader-box">
            <Preloader size='big'/>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  userInfoIsLoading: state.loading.userInfo,
});

export default withRouter(connect(mapStateToProps, { getUserInfo })(LoadingComponent));
