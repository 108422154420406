import {
  actionTypes
} from '../constants/actionTypes';

const initialState = {
  exampleFile: '',
  assortimentFile: '',
  allHardwareOptions: {
    access: [],
    mobile: [],
    voice: [],
  },
  allProducts: [],
  productsToLink: [],
  hardwareOptionCategories: [],
  customHardwareOptions: {
    access: [],
    mobiel: [],
    voice: [],
  },
  customHardwareSelections: {
    access: [],
    mobiel: [],
    voice: [],
    partner: [],
    it: [],
  },
  existingVoiceData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.hardwareDownloadExampleFile:
      return {
        ...state,
        exampleFile: action.payload
      };
    case actionTypes.hardwareDownloadAssortimentFile:
      return {
        ...state,
        assortimentFile: action.payload
      };
    case actionTypes.uploadBulk:
      return {
        ...state,
        allProducts: [...state.allProducts, ...action.payload]
      };
    case actionTypes.setHardwareProducts:
      return {
        ...state,
        ...action.payload
      };
    case actionTypes.hardwareOpenImportDialog:
      return {
        ...state,
        importDialogOpened: true
      };
    case actionTypes.hardwareCloseImportDialog:
      return {
        ...state,
        importDialogOpened: false
      };
    case actionTypes.hardwareToggleImportLoading:
      return {
        ...state,
        importLoading: !state.importLoading
      };
    case actionTypes.hardwareSetImportDialogData:
      return {
        ...state,
        importDialogData: action.payload
      };
    case actionTypes.setCustomHardwareOptions:
      return {
        ...state,
        customHardwareOptions: {
          ...state.customHardwareOptions, [action.payload.key]: action.payload.value
        }
      };
    case actionTypes.setCustomHardwareSelections:
      return {
        ...state,
        customHardwareSelections: {
          ...state.customHardwareSelections, ...action.payload
        }
      };
    case actionTypes.setHardwareProductsToLink:
        return {
            ...state,
            productsToLink: action.payload
        };
    case actionTypes.setHardwareOptionCategories:
        return {
            ...state,
            hardwareOptionCategories: action.payload
        };
    case actionTypes.getExistingVoiceData:
        return {
          ...state,
          existingVoiceData: action.payload
        };
    default:
      return state;
  }
}
