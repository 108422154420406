import React from "react";
import RatioTableStyle from "./RatioTableStyle";

const RatioOverflowTableWrapper = (props) => {
    return (
        <React.Fragment>
            <RatioTableStyle/>
            <div className="ratio-table-container">
                <div className="ratio-table-max-width">
                    {props.children}
                </div>
            </div>
        </React.Fragment>
    )
}

export default RatioOverflowTableWrapper;