import {
    actionTypes
} from '../constants/actionTypes';

const initialState = {
    messages: [],
};

export default (state = initialState, action) => {

    switch(action.type) {
        case actionTypes.getAllMessages:
            return {
                ...state,
                messages: action.response.messages
            };
        case actionTypes.sendMessage:
            return {
                ...state,
                messages: action.response.messages
            };
        default:
            return state;
    }
}
