import {
    actionTypes
} from '../constants/actionTypes';

const initialState = {
    allPostcodeCategories: [],
    pages: 1,
    currentPage: 1,
    resultsCount: 0,
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.getAllPostcodeCategories:
            return {
                ...state,
                allPostcodeCategories: action.payload
            };
        case actionTypes.savePagination:
            return {
                ...state,
                pages : action.payload.pages,
                currentPage: action.payload.currentPage,
                resultsCount: action.payload.resultsCount
            };
        case actionTypes.patchPostcodeCategory:
            return {
                ...state,
                allPostcodeCategories: state.allPostcodeCategories.map( (postcodeCategory) => postcodeCategory.id === action.payload.id ? action.payload : postcodeCategory )
            };
        default:
            return state;
    }
}
