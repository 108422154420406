import {
  actionTypes
} from '../constants/actionTypes'

const initialState = {
  document: {
    id: null,
    name: '',
    file: '',
    documentType: 'docx'
  },
  documentBlob: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getSignedDocument:
      if (action.response.length !== 0) {
        return {
          ...state,
          document: action.response,
        }
      }
    case actionTypes.addSignedDocument:
      return {
        ...state,
        document: action.response
      }
    case actionTypes.updateSignedDocument:
      return {
        ...state,
        document: action.response
      }
    case actionTypes.downloadSignedDocument:
      return {
        ...state,
        documentBlob: action.response
      }
    default:
      return state
  }
}
