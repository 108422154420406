import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import leadActions from '../../../actions/leadActions';

import LeadModule from "./leadModule";
import LoaderComponent from "../../../containers/common/LoaderComponent";

class LeadProductsStep extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modules: [],
      leadSelections: [],
      modulesLoading: true,
      leadSelectionsLoading: true,
      isExcludingRelationProducts: this.props.quotation.excludeRelationsProducts
    }
  }

  componentDidMount() {
    const leadId = this.props.quotation.id;

    this.props.leadActions.getProducts(leadId).then((response) => {
      if (response && response.data) {
        this.setState({modules: response.data});
      }
    }).then(() => {
      this.setState({modulesLoading: false})
    });

    this.props.leadActions.getSelections(leadId).then((response) => {
      if (response) {
        this.setState({leadSelections: response});
      }
    }).then(() => {
      this.setState({leadSelectionsLoading: false})
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.isExcludingRelationProducts !== this.state.isExcludingRelationProducts) {
      this.handleRelationsProducts();
    }
  };

  identifySelection = (product) => {
    if (product.isCustomProduct) {
      return this.state.leadSelections.find((el) => el.partnerItService === product.id);
    } else {
      return this.state.leadSelections.find((el) => el.product === product.id)
    }
  };

  updateSelection = (value, product) => {
    let leadId = this.props.quotation.id;
    let identifiedSelection = this.identifySelection(product);

    value = parseInt(value);
    if (isNaN(value)) {
      value = 0;
    }

    if (!identifiedSelection) {
      identifiedSelection = {
        id: '',
        product: null,
        partnerItService: null,
      };

      if (product.isCustomProduct) {
        identifiedSelection.partnerItService = product.id;
      } else {
        identifiedSelection.product = product.id;
      }
    }

    identifiedSelection.quantity = value;

    this.props.leadActions.updateSelections(leadId, identifiedSelection).then((response) => {
      if (response && response.selections) {
        this.setState({leadSelections: response.selections});
      }
    });
  };

  renderModules = () => {
    const {modules, leadSelections} = this.state;

    return modules.map((module) => {
      return (
        <LeadModule
          key={module.moduleName}
          module={module}
          leadSelections={leadSelections}
          updateSelection={this.updateSelection}
          identifySelection={this.identifySelection}
        />
      );
    });
  };

  toggleRelationsProducts = (name, value) => {
    this.setState({[name]: !value});
  }

  handleRelationsProducts = () => {
    const leadId = this.props.quotation.id;
    this.props.leadActions.setExcludeRelationsProducts(leadId, {isExcludingRelationProducts: this.state.isExcludingRelationProducts});
  };

  render() {
    const { theme } = this.props.authentication;
    let {modulesLoading, leadSelectionsLoading, isExcludingRelationProducts} = this.state;

    return (
      <>
        {modulesLoading || leadSelectionsLoading ?
          <LoaderComponent theme={theme}/> :
          <>
            <div className="ratiotable">
              <div className="row">
                <div className="col s12 overviewkopbg">
                  <div className="col s12 m12 overviewkop" style={{padding: '10px', display: 'flex'}}>
                    <button
                      className="btn doceri-btn-right ratio-btn-right doceri-btn-margin-right"
                      onClick={() => this.toggleRelationsProducts('isExcludingRelationProducts', isExcludingRelationProducts)}
                    >
                      {!isExcludingRelationProducts ? 'Gekoppelende producten niet meenemen in de berekening' : 'Gekoppelende producten terug zetten in de berekening'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {this.renderModules()}
          </>
        }
      </>
    );
  }
}

const mapStateToProps = ({quotation, authentication}) => {
  return {
    quotation: quotation.currentQuotation,
    authentication: authentication
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    leadActions: bindActionCreators(leadActions, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadProductsStep);
