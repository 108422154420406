import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

import {apiRoutes} from '../constants/apiRoutes';
import {toastr} from 'react-redux-toastr'
import {actionTypes} from "../constants/actionTypes";

const getQuotationDocuments = (quotationId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.quotationDocumentRelated.getQuotationDocuments.replace('{quotationId}', quotationId);
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getQuotationDocumentsSuccess(response));
    } catch (error) {
      toastr.error('Ophalen van documenten mislukt', error.message);
    }
  }
};

const getQuotationDocumentsSuccess = (response) => {
  return {
    type: actionTypes.getQuotationDocuments,
    response
  }
};

const addQuotationDocument = (quotationId, data) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationDocumentRelated.addQuotationDocument.replace('{quotationId}', quotationId);
      const response = await ApiService.performRequest(RequestType.POST_MULTIPART, url, data);

      if (response && response.success) {
        dispatch(addQuotationDocumentSuccess(response.document));
      }
    } catch (error) {
      toastr.error('Toevoegen mislukt. Probeer het opnieuw', error.message);
    }
  }
};

const addQuotationDocumentSuccess = (response) => {
  return {
    type: actionTypes.addQuotationDocument,
    response
  }
};

const downloadQuotationDocument = (quotationId, documentID) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationDocumentRelated.downloadQuotationDocument
        .replace('{quotationId}', quotationId).replace('{quotationDocumentId}', documentID);

      const response = await ApiService.performRequest(RequestType.GETFILE, url);

      dispatch(downloadQuotationDocumentSuccess(response));

      return true;
    } catch ({message}) {
      toastr.error('Kan word document niet downloaden', message);
    }
  }
};

const downloadQuotationDocumentSuccess = (response) => {
  return {
    type: actionTypes.downloadQuotationDocument,
    response
  }
};

export default {
  getQuotationDocuments,
  addQuotationDocument,
  downloadQuotationDocument
}
