import React from 'react';

const Location = props => {
    const {shouldBeDisabled, shouldDisplayWarning, isConnectionValid, israCheckInProgress, israCheckPassed, isFlightcase, shouldDisplayError} = props;

    return (
        <a
            className={"btn doceri-btn ratio-btn-locations " + (props.selectedLocationId === props.config.id ? 'active-location' : '') + (shouldBeDisabled || israCheckInProgress ? ' disabled' : '')}
            onClick={props.onClickFunc}>
            {israCheckInProgress ? <span className="refresh-icon"/>
                : shouldDisplayError
                    ?<i className="material-icons tiny left red-text">close</i>
                        : isFlightcase === false && israCheckPassed === false
                        ? <i className="material-icons tiny left red-text">location_off</i>
                        : isConnectionValid === false
                            ? <i className="material-icons tiny left red-text">settings_input_component</i>
                            : shouldDisplayWarning
                                ? <i className="tiny material-icons left green-text">error_outline</i>
                                : <i className="tiny material-icons left green-text">check</i>}
            <i className="material-icons right white-text">chevron_right</i>
            {props.config.name}
        </a>
    );
};

export default Location;