import React from 'react';

const DialogButton = ({ buttonAction, buttonText, left, right, style, buttonDisabled }) => {
  return (
    <button
      className={`btn doceri-btn-right ratio-btn-right-fullwidth${left ? ' left' : ''}${right ? ' right' : ''}${buttonDisabled ? ' disabled' : ''}`}
      onClick={buttonAction}
      type="submit"
      name="action"
      style={style}
    >
      {buttonText}
    </button>
  )
};

export default DialogButton;
