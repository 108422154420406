import {apiRoutes} from '../constants/apiRoutes';
import {toastr} from 'react-redux-toastr'

import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

const getQuestions = (quotationId, module, location) => {
  return async () => {
    try {
      let url = apiRoutes.quotationQuestionsRelated.getQuestions.replace('{quotationId}', quotationId)
        .replace('{module}', module).replace('{locationId}', location > 0 ? location : null);
      return await ApiService.performRequest(RequestType.GET, url);
    }
    catch ({message}) {
      toastr.error('Kan de vragen niet ophalen! ', message);
    }
  }
};

const getQuestionSelections = (quotationId, module, location) => {
  return async () => {
    try {
      let url = apiRoutes.quotationQuestionsRelated.getQuestionSelections.replace('{quotationId}', quotationId)
        .replace('{module}', module).replace('{locationId}', location > 0 ? location : null);
      return await ApiService.performRequest(RequestType.GET, url);
    }
    catch ({message}) {
      toastr.error('Kan de selectie van vragen niet ophalen! ', message);
    }
  }
};

const updateQuestionSelections = (quotationId, module, location, data) => {
  return async () => {
    try {
      let url = apiRoutes.quotationQuestionsRelated.updateQuestionSelections.replace('{quotationId}', quotationId)
        .replace('{module}', module).replace('{locationId}', location > 0 ? location : null);
      return await ApiService.performRequest(RequestType.PUT, url, data);
    }
    catch ({message}) {
      toastr.error('Kan de vraagselectie niet bijwerken! ', message);
    }
  }
};

export default {
  getQuestions,
  getQuestionSelections,
  updateQuestionSelections
}
