import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

import { actionTypes } from '../constants/actionTypes';
import { apiRoutes } from '../constants/apiRoutes';
import { toastr } from 'react-redux-toastr'

const getLogos = (queryParams, providerId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.organizationProvidersLogosRelated.getLogos;
      url = url.replace(/{providerId}/, providerId) + queryParams;

      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getLogosSuccess(response.logos))
      dispatch(setLogosPagination(response.currentPage, response.resultsCount, response.pages));

    } catch (error) {
      toastr.error('Ophalen van documenten mislukt', error.message);
    }
  }
};

const setLogosPagination = (currentPage, resultsCount, pages) => {
  return {
    type: actionTypes.setLogosPagination,
    payload: {
      pages,
      currentPage,
      resultsCount
    }
  }
};

const getLogosSuccess = (response) => {
  return {
    type: actionTypes.getLogos,
    response
  }
};

const setProvider = (provider) => {
  return {
    type: actionTypes.setOrganizationProviderFilter,
    payload: provider
  }
};

const addLogoFile = (data) => {
  return async () => {
    try {
      const url = apiRoutes.organizationProvidersLogosRelated.addLogoFile;

      return await ApiService.performRequest(RequestType.POST_MULTIPART, url, data);
    } catch (error) {
      toastr.error('Toevoegen mislukt. Probeer het opnieuw', error.message);
    }
  }
};

const editLogoFile = (fileId, data) => {
  return async () => {
    try {
      const url = apiRoutes.organizationProvidersLogosRelated.update.replace('{organizationProviderLogo}', fileId);

      return await ApiService.performRequest(RequestType.POST_MULTIPART, url, data);
    } catch (error) {
      toastr.error('Opslaan mislukt. Probeer het opnieuw', error.message);
    }
  }
};

const deleteLogoFile = (fileId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.organizationProvidersLogosRelated.update.replace('{organizationProviderLogo}', fileId);
      await ApiService.performRequest(RequestType.DELETE, url);

      dispatch(deleteLogoFileSuccess(fileId));
    } catch (error) {
      toastr.error('Verwijderen mislukt. Probeer het opnieuw', error.message);
    }
  }
};

const deleteLogoFileSuccess = (fileId) => {
  return {
    type: actionTypes.deleteLogoFile,
    fileId
  }
};

const downloadLogo = (fileId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.organizationProvidersLogosRelated.download.replace('{organizationProviderLogo}', fileId);
      const response = await ApiService.performRequest(RequestType.GETFILE, url);

      dispatch(downloadLogoFileSuccess(response));

      return true;
    } catch ({message}) {
      toastr.error('Kan word document niet downloaden', message);
    }
  }
};

const downloadLogoFileSuccess = (response) => {
  return {
    type: actionTypes.downloadLogoFile,
    response
  }
};


export default {
  getLogos,
  addLogoFile,
  editLogoFile,
  deleteLogoFile,
  downloadLogo,
  setProvider,
  setLogosPagination
}
