import {
    actionTypes
} from '../constants/actionTypes';

const initialState = {
    allPostcodes: [],
    pages: 1,
    currentPage: 1,
    resultsCount: 0,
    categoriesToLink: []
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.getAllPostcodes:
            return {
                ...state,
                allPostcodes: action.payload
            };
        case actionTypes.savePagination:
            return {
                ...state,
                pages : action.payload.pages,
                currentPage: action.payload.currentPage,
                resultsCount: action.payload.resultsCount
            };
        case actionTypes.setPostcodeCategoriesToLink:
            return {
                ...state,
                categoriesToLink: action.payload
            };
        case actionTypes.patchPostcode:
            return {
                ...state,
                allPostcodes: state.allPostcodes.map( (postcode) => postcode.id === action.payload.id ? action.payload : postcode )
            };
        default:
            return state;
    }
}
