import React, {Component} from 'react';
import {connect} from 'react-redux';
import RegularSelect from "../../../components/RegularSelect";
import {Input, Col} from 'react-materialize';
import '../optionsStyle.css';

class PartnerService extends Component {

    constructor(props) {
        super(props);
        let service = this.props.service;

        this.state = {
            serviceName: this.getPropValue(service.name, 'text'),
            serviceNumber: this.getPropValue(service.product_number, 'text'),
            serviceLabel: this.getPropValue(service.label, 'text'),
            serviceCategory: this.getPropValue(service.category, 'text'),
            serviceMandatory: this.getPropValue(service.mandatory, 'text') === 1 ? 'Ja' : 'Nee',
            showWhen0: this.getPropValue(service.showWhen0, 'number'),
            purchasePriceOneOff: this.getPropValue(service.purchase_price_one_off, 'number'),
            purchasePricePerMonth: this.getPropValue(service.purchase_price_per_month, 'number'),
            sellingPriceOneOff: this.getPropValue(service.selling_price_one_off, 'number'),
            sellingPricePerMonth: this.getPropValue(service.selling_price_per_month, 'number'),
            setUpPrice: this.getPropValue(service.set_up_price, 'number'),
            installationPrice: this.getPropValue(service.installation_price, 'number'),
            pmPrice: this.getPropValue(service.pm_price, 'number'),
            serviceProductLabel: (service.type === 'partner') ? 'Partner Dienst' : 'IT Dienst',
            serviceId: service.id
        };
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.service.id!==this.state.serviceId){
            let {
                name,
                product_number,
                label,
                category,
                mandatory,
                showWhen0,
                purchase_price_one_off,
                purchase_price_per_month,
                selling_price_one_off,
                selling_price_per_month,
                set_up_price,
                installation_price,
                pm_price,
                type,
                id
            } = nextProps.service;

            this.setState({
                serviceName: this.getPropValue(name, 'text'),
                serviceNumber: this.getPropValue(product_number, 'text'),
                serviceLabel: this.getPropValue(label, 'text'),
                serviceCategory: this.getPropValue(category, 'text'),
                serviceMandatory: this.getPropValue(mandatory, 'text') === 1 ? 'Ja' : 'Nee',
                showWhen0: this.getPropValue(showWhen0, 'number'),
                purchasePriceOneOff: this.getPropValue(purchase_price_one_off, 'number'),
                purchasePricePerMonth: this.getPropValue(purchase_price_per_month, 'number'),
                sellingPriceOneOff: this.getPropValue(selling_price_one_off, 'number'),
                sellingPricePerMonth: this.getPropValue(selling_price_per_month, 'number'),
                setUpPrice: this.getPropValue(set_up_price, 'number'),
                installationPrice: this.getPropValue(installation_price, 'number'),
                pmPrice: this.getPropValue(pm_price, 'number'),
                serviceProductLabel: (type === 'partner') ? 'Partner Dienst' : 'IT Dienst',
                serviceId: id
            });
        }
    }

    getPropValue = (value, type) => {
      if (value !== null && value !== undefined) {
          return value;
      }

      if (type === 'text') {
          return '';
      } else {
          return 0;
      }
    };

    onSaveCheck = (serviceId) => {
        if (this.props.allowEditOnService === serviceId) {
            this.props.onSave(serviceId, this.state)
        } else {
            return false;
        }
    };

    onChange = (target, serviceId) => {
        if (this.props.allowEditOnService === serviceId) {
            let {name, value} = target;

            this.setState({
                [name] : value
            });
        }

        return false;
    };

    onChangeShowWhen0 = ({target}) => {
        let { name } = target;
        let value;

        value = name === 'showWhen0';
        if (value) {
            value = !(value && this.state.showWhen0);
        } else {
            name = 'showWhen0';
            value = ! this.state.showWhen0;
        }

        this.setState({[name]: value});
    };

    onMandatoryChange = (value ,serviceId) => {
        if (this.props.allowEditOnService === serviceId) {
            this.setState({
                serviceMandatory : value
            });
        }

        return false;
    };

    render() {
        let { service, onEdit, onDelete } = this.props;
        let serviceId = service.id;

        return (
            <div className='col s12'>
            <div className='row'>
                <div key={`${serviceId}`} className='col s8'>
                    <div className="row">
                        <Input
                            s={3}
                            value={this.state.serviceName}
                            onChange={(event) => {this.onChange(event.target, serviceId)}}
                            label={this.state.serviceProductLabel}
                            name='serviceName'
                            type='text' />

                        <Input
                            s={2}
                            value={this.state.serviceNumber}
                            onChange={(event) => {this.onChange(event.target, serviceId)}}
                            label='ProductCode'
                            name='serviceNumber'
                            type='text' />

                        <Input
                            s={3}
                            value={this.state.serviceLabel}
                            onChange={(event) => {this.onChange(event.target, serviceId)}}
                            label='Label'
                            name='serviceLabel'
                            type='text' />

                        <Input
                            s={3}
                            value={this.state.serviceCategory}
                            onChange={(event) => {this.onChange(event.target, serviceId)}}
                            label='Categorie'
                            name='serviceCategory'
                            type='text' />
                        {
                            this.props.allowEditOnService === serviceId &&
                            <Col s={1} className="input-field inner-addon">
                                <label htmlFor={"serviceMandatory"}>Verplicht?</label>
                                <RegularSelect
                                    name="serviceMandatory"
                                    id={"serviceMandatory"}
                                    onChange={(e) => {
                                        this.onMandatoryChange(e.target.value, serviceId)
                                    }}
                                    value={this.state.serviceMandatory}
                                    multiple={false}
                                >
                                    <option
                                        key={0}
                                        value={"Ja"}
                                        name="obligation">
                                        {"Ja"}
                                    </option>
                                    <option
                                        key={1}
                                        value={"Nee"}
                                        name="obligation">
                                        {"Nee"}
                                    </option>
                                </RegularSelect>
                            </Col>
                        }
                        {
                            this.props.allowEditOnService !== serviceId &&
                            <Input
                                s={1}
                                value={this.state.serviceMandatory}
                                onChange={(event) => {this.onChange(event.target, serviceId)}}
                                label='Verplicht?'
                                name='serviceMandatory'
                                type='text' />
                        }

                    </div>

                    <div className="row">

                        <Input value={this.state.purchasePriceOneOff}
                               s={2}
                               onChange={(event) => {this.onChange(event.target, serviceId)}}
                               label='Inkoopprijs eenmalig'
                               name='purchasePriceOneOff'
                               type='number' />

                        <Input value={this.state.purchasePricePerMonth}
                               s={2}
                               onChange={(event) => {this.onChange(event.target, serviceId)}}
                               label='Inkoopprijs/mnd'
                               name='purchasePricePerMonth'
                               type='number' />

                        <Input value={this.state.sellingPriceOneOff}
                               s={2}
                               onChange={(event) => {this.onChange(event.target, serviceId)}}
                               label='Verkoopprijs eenmalig'
                               name='sellingPriceOneOff'
                               type='number' />

                        <Input value={this.state.sellingPricePerMonth}
                               s={2}
                               onChange={(event) => {this.onChange(event.target, serviceId)}}
                               label='Verkoopprijs/mnd'
                               name='sellingPricePerMonth'
                               type='number' />

                        <Input value={this.state.setUpPrice}
                               s={2}
                               onChange={(event) => {this.onChange(event.target, serviceId)}}
                               label='Programmeren'
                               name='setUpPrice'
                               type='number' />

                        <Input value={this.state.installationPrice}
                               s={1}
                               onChange={(event) => {this.onChange(event.target, serviceId)}}
                               label='Installeren'
                               name='installationPrice'
                               type='number' />

                        <Input value={this.state.pmPrice}
                               s={1}
                               onChange={(event) => {this.onChange(event.target, serviceId)}}
                               label='PM'
                               name='pmPrice'
                               type='number' />

                    </div>

                    <div className="row">
                        <Input
                          name='showWhen0'
                          type='checkbox'
                          checked={this.state.showWhen0}
                          label={'Zou moeten verschijnen wanneer 0'}
                          onChange={this.onChangeShowWhen0}
                        />
                        <Input
                          name='notShowWhen0'
                          type='checkbox'
                          checked={!this.state.showWhen0}
                          label={'Zou niet moeten verschijnen als 0'}
                          onChange={this.onChangeShowWhen0}
                        />
                    </div>
                </div>
                <div className="col s2">
                </div>
                <div className="col s2 buttonsBox">
                    <div className="row">
                        <button
                            className="btn doceri-btn-right kpn-btn-right productButton"
                            type="submit"
                            onClick={() => onDelete(serviceId)}
                            name="action">
                            <i className="small material-icons left white-text">delete</i>
                            Verwijderen
                        </button>
                    </div>
                    <div className="row">
                        <button
                            className="btn doceri-btn-right kpn-btn-right productButton"
                            type="submit"
                            onClick={() => onEdit(serviceId)}
                            name="action">
                            <i className="small material-icons left white-text">edit</i>
                            Wijzigen
                        </button>
                    </div>

                    <div className="row">
                        <button
                            className="btn doceri-btn-right kpn-btn-right productButton"
                            type="submit"
                            onClick={() => this.onSaveCheck(serviceId)}
                            name="action">
                            <i className="small material-icons left white-text">save</i>
                            Opslaan
                        </button>
                    </div>
                </div>
            </div>
                <div className="line30 clearfix">&nbsp;</div>
            </div>
        )
    };
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerService);

