import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";

import partnerServicesActions from "../../actions/partnerServicesActions";

import {Icon, Input} from "react-materialize";
import providerType, {yielderProviders} from "../../constants/providerType";

class MKBWorkplace extends Component {
    constructor(props) {
        super(props);

        this.state = {
            manualAdditionDialogOpened: false,
            products: this.props.partnerServices.mkbProducts || [],
            options: this.props.partnerServices.mkbOptions || [],
            selections: this.props.partnerServices.mkbSelections || [],
            securityCategories: this.props.partnerServices.securityCategories || [],
            securityOptions: this.props.partnerServices.securityOptions || [],
            securitySelections: this.props.partnerServices.securitySelections || [],
            securitySelectedCategories: this.props.partnerServices.securitySelectedCategories || [],
        }
    }

    componentDidMount() {
        this.updateSelections();

        const {currentQuotation} = this.props.quotation;
        const isYielderProvider = currentQuotation.provider === providerType.YIELDER;

        if (! isYielderProvider) {
            this.updateSecurityOptions();
        }
    }

    updateSelections = () => {
        const {currentQuotation} = this.props.quotation;
        const isYielderProvider = yielderProviders.includes(currentQuotation.provider);

        if (! isYielderProvider) {
            return;
        }

        this.props.partnerServicesActions.getMKBProducts(currentQuotation.id).then((response) => {
            if (response) {
                this.setState({
                    products: response.products,
                    options: response.options,
                    selections: response.selections
                });
            }
        });
    }

    updateSecurityOptions = () => {
        const {currentQuotation} = this.props.quotation;

        this.props.partnerServicesActions.getSecurityOptions(currentQuotation.id).then((response) => {
            if (response) {
                this.setState({
                    securityCategories: response.categories,
                    securityOptions: response.options,
                    securitySelections: response.selections,
                    securitySelectedCategories: response.selectedCategories
                });
            }
        })
    }

    renderProductSelections = (selectedProduct) => {
        const {products, options} = this.state;
        const {inputsDisabled} = this.props.quotation.currentQuotation;
        let productOptions = options[selectedProduct.productId] || [];
        let allowAddButton = this.allowNewOption(selectedProduct.productId);
        let hasProductOptions = true;

        let selectedOptions = selectedProduct.options;
        if (!selectedOptions.length) {
            selectedOptions = [{productId: null, amount: 0}];
        }
        if (!products.length) {
            hasProductOptions = false;
        }

        return (
            <div className="row">
                <div className="col s12">
                    <Input
                        s={12}
                        name="profile"
                        type="select"
                        label="MKB Cloud Werkplek Bundle"
                        disabled={inputsDisabled}
                        onChange={this.onChangeProfile(selectedProduct.productId)}
                        value={selectedProduct.productId}
                        multiple={false}
                    >
                        <option value={null}>Niets geselecteerd</option>
                        {
                            products.map((product) => {
                                return <option key={product.name} value={product.id}>{product.name}</option>
                            })
                        }
                    </Input>
                </div>

                {
                    hasProductOptions && selectedOptions.map((selectedOption) => this.renderProductOptions(selectedOption, productOptions, selectedProduct))
                }

                {
                    allowAddButton && (
                        <div className="col s12">
                            <button
                                name="addTelephoneNumber"
                                className={'btn ratio-btn white-text ratio-btn-right right ' + (inputsDisabled ? '' : 'doceri-btn-left')}
                                onClick={() => this.addNewOption(selectedProduct.productId)}
                                disabled={inputsDisabled}
                                style={{minWidth: '150px'}}
                            >
                                Nog een optie toevoegen
                                <Icon right>
                                    add
                                </Icon>
                            </button>
                        </div>
                    )
                }
            </div>
        )
    }

    onChangeProfile = (productId) => (event, value) => {
        value = value ? parseInt(value) : null;
        const {currentQuotation} = this.props.quotation;

        if (!productId && !value) {
            return;
        }

        if (productId === value) {
            return;
        }

        let data;
        if (!productId && value) {
            data = {
                operation: 'add',
                productId: value
            }
        } else if (productId && !value) {
            data = {
                operation: 'remove',
                productId: productId
            }
        } else {
            data = {
                operation: 'change',
                amount: 1,
                productId: value,
                oldProductId: productId
            }
        }

        this.props.partnerServicesActions.updateMKBProducts(currentQuotation.id, data).then(() => this.updateSelections())
    }

    addNewOption = (productId) => {
        let {selections} = this.state;
        let indexProfile = selections.findIndex((selection) => selection.productId === productId);
        if (indexProfile < 0) {
            return;
        }

        let options = [...selections[indexProfile].options, {productId: null, amount: 0}];
        let updateSelections = [...selections];
        updateSelections[indexProfile]['options'] = options;

        this.setState({selections: updateSelections});
    }

    renderProductOptions = (optionProduct, productOptions, selectedProduct) => {
        const shouldHaveAmount = optionProduct.productId;
        const optionClass = shouldHaveAmount ? "col s11" : "col s12";

        const {inputsDisabled} = this.props.quotation;

        return (
            <div className="col s12">
                {productOptions.map((productOption) => {
                    const {category, products} = productOption;

                    return (
                        <div className="col s12">
                            {
                                shouldHaveAmount && (
                                    <Input
                                        s={1}
                                        name='amount'
                                        type='number'
                                        label='Aantal'
                                        disabled={inputsDisabled}
                                        value={optionProduct.amount}
                                        onChange={this.onChangeOptionQuantity(optionProduct.productId, selectedProduct.productId)}
                                        onBlur={() => this.onSaveOptionQuantity(optionProduct.productId, selectedProduct.productId)}
                                        min={1}
                                        max={25}
                                    />
                                )
                            }
                            <Input
                                s={optionClass}
                                type="select"
                                name="option"
                                label={category.name}
                                disabled={inputsDisabled}
                                onChange={this.onChangeOption(selectedProduct.productId, optionProduct.productId)}
                                value={optionProduct.productId}
                                multiple={false}
                            >
                                <option value={null}>Niets geselecteerd</option>
                                {
                                    products.map((option) => {
                                        return <option key={option.name} value={option.id}>{option.name}</option>
                                    })
                                }
                            </Input>
                        </div>
                    );
                })}
            </div>
        );
    }

    onChangeOption = (parentId, optionId) => (event, value) => {
        const {currentQuotation} = this.props.quotation;
        value = value ? parseInt(value) : null;

        if (!optionId && !value) {
            return;
        }
        if (optionId === value) {
            return;
        }

        let data;
        if (!optionId && value) {
            data = {
                operation: 'add',
                amount: 1,
                productId: value,
                parentId: parentId
            };
        } else if (optionId && !value) {
            data = {
                operation: 'remove',
                amount: 0,
                productId: optionId,
                parentId: parentId
            };
        } else {
            data = {
                operation: 'change',
                amount: 1,
                productId: value,
                oldProductId: optionId,
                parentId: parentId
            };
        }

        this.props.partnerServicesActions.updateMKBOptions(currentQuotation.id, data).then(() => this.updateSelections())
    }

    allowNewOption = (productId) => {
        if (!productId) {
            return false;
        }

        let {products, selections, options} = this.state;

        let foundProduct = products.find((product) => product.id === productId);
        if (!foundProduct) {
            return false;
        }

        let indexProfile = selections.findIndex((selection) => selection.productId === productId);
        if (indexProfile < 0) {
            return false;
        }

        let profileSelections = selections[indexProfile];
        let selectionOptions = profileSelections.options;

        let productOptions = options[productId];
        if (!productOptions.length) {
            return false;
        }

        let maxOptionsAmount = 0;
        let currentOptionsAmount = selectionOptions.length;
        productOptions.forEach((optionList) => {
            maxOptionsAmount += optionList.products ? optionList.products.length : 0;
        });

        let optionAmountCondition = currentOptionsAmount < maxOptionsAmount;

        let currentSelectedAmount = 0;
        let maxAmount = foundProduct.max_subproducts || 999;
        selectionOptions.forEach((selectedOption) => {
            currentSelectedAmount += selectedOption.amount
        });
        let selectedAmountCondition = currentSelectedAmount < maxAmount;

        return optionAmountCondition && selectedAmountCondition;
    }

    allowOptionAmount = (productId, optionId, newAmount) => {
        let {products, selections} = this.state;

        let foundProduct = products.find((product) => product.id === productId);
        if (!foundProduct) {
            return false;
        }

        let minAmount = foundProduct.min_subproducts || 0;
        let maxAmount = foundProduct.max_subproducts || 999;

        let indexProfile = selections.findIndex((selection) => selection.productId === productId);
        if (indexProfile < 0) {
            return false;
        }

        let profileSelections = selections[indexProfile];
        let selectionOptions = profileSelections.options;

        let currentAmount = 0;
        let currentOptionAmount = 0;
        selectionOptions.forEach((option) => {
            if (!isNaN(option.amount)) {
                currentAmount += option.amount;

                if (option.productId === optionId) {
                    currentOptionAmount = option.amount;
                }
            }
        });

        let updatedAmount = currentAmount - currentOptionAmount + newAmount;

        return !(minAmount > updatedAmount || maxAmount < updatedAmount);
    }

    onChangeOptionQuantity = (optionId, productId) => (event, value) => {
        const {target} = event;
        let {min, max} = target;

        max = parseInt(max);
        value = parseInt(value);

        let amountAllowed = this.allowOptionAmount(productId, optionId, value);
        if (!amountAllowed) {
            return;
        }

        if (min > value || max < value) {
            return;
        }

        let {selections} = this.state;
        let indexProfile = selections.findIndex((selection) => selection.productId === productId);
        if (indexProfile < 0) {
            return;
        }

        let options = selections[indexProfile].options;
        let indexOption = options.findIndex((option) => option.productId === optionId);
        if (indexOption < 0) {
            return;
        }

        let updatedSelections = [...selections];
        updatedSelections[indexProfile]['options'][indexOption]['amount'] = value;

        this.setState({selections: updatedSelections});
    }

    onSaveOptionQuantity = (optionId, parentId) => {
        const {selections} = this.state;
        const quotationId = this.props.quotation.currentQuotation.id;

        let selectionProfile = selections.find((selection) => selection.productId = parentId);
        if (!selectionProfile) {
            return;
        }

        let optionProfile = null;
        selectionProfile.options.some((option) => {
            if (option.productId === optionId) {
                optionProfile = option;
                return true;
            }

            return false;
        });

        if (!optionProfile) {
            return;
        }

        const data = {
            operation: 'updateAmount',
            amount: optionProfile.amount,
            productId: optionId,
            parentId: parentId
        };

        this.props.partnerServicesActions.updateMKBOptions(quotationId, data).then(() => {
            this.updateSelections();
        });
    }

    onSelectCategory = ({target}) => {
        let {value, checked} = target;
        let selectedCategories = [];
        let {securitySelectedCategories} = this.state;

        value = parseInt(value);

        if (checked) {
            selectedCategories = [...securitySelectedCategories, value];
        } else {
            selectedCategories = securitySelectedCategories.filter((selectedCategory) => selectedCategory !== value);
        }

        this.setState({...this.state, securitySelectedCategories: selectedCategories});
    }

    getCategoryLabel = (profileCategory) => {
        const {securityCategories} = this.state;

        const foundCategory = securityCategories.find((category) => category.value === profileCategory);

        return foundCategory ? foundCategory.name : '';
    }

    renderCategorySelections = (securityCategory) => {
        const categoryLabel = this.getCategoryLabel(securityCategory);

        const {securitySelections, securityOptions, loader} = this.state;

        const {inputsDisabled} = this.props.quotation;

        const categoryProducts = securityOptions[securityCategory] || [];
        const selectedProducts = securitySelections[securityCategory] || [{id: null, amount: 0}];

        return (
            <div className="row">
                <div className="col s12 line tussenkop">
                    <h1 className={'ratio-section-title'}>
                        {categoryLabel} gewenste opties
                    </h1>
                </div>

                <div className="col s12">
                    {selectedProducts.map((selectedProduct) => {
                        return (
                            <div className="row">
                                {this.renderProductSelection(selectedProduct, securityCategory, categoryProducts)}
                            </div>
                        );
                    })}
                </div>

                <div className="col s12">
                    <button
                        name="addNewProfile"
                        className={'btn ratio-btn white-text ratio-btn-right right ' + (inputsDisabled ? '' : 'doceri-btn-left')}
                        onClick={() => this.addNewProduct(securityCategory)}
                        disabled={inputsDisabled}
                        style={loader ? {minWidth: '150px'} : null}
                    >
                        {loader ? (
                            <span className="progress secondaryBackgroundColor" style={{marginTop: '20px'}}>
                                <span className="indeterminate overviewkopbggroen"/>
                            </span>) : ('Nog een producten toevoegen')
                        }
                        <Icon right>
                            add
                        </Icon>
                    </button>
                </div>
            </div>
        );
    }

    renderProductSelection = (product, securityCategory, productOptions) => {
        const shouldHaveAmount = product.productId;
        const productClass = shouldHaveAmount ? "col s11" : "col s12";

        const {loader} = this.state;
        const {inputsDisabled} = this.props.quotation;

        let categoryOptions = productOptions[0] !== undefined ? productOptions[0]['products'] : [];

        return (
            <div className="col s12">
                {
                    shouldHaveAmount && (
                        <Input
                            s={1}
                            name='amount'
                            type='number'
                            label='Aantal'
                            disabled={inputsDisabled || loader}
                            value={product.amount}
                            onChange={this.onProductQuantityChangeCallback(product, securityCategory)}
                            onBlur={() => this.onProductQuantitySave(product.productId, securityCategory)}
                            min={1}
                            max={999}
                        />
                    )
                }
                <Input
                    s={productClass}
                    name="product"
                    type="select"
                    label="Producten"
                    disabled={inputsDisabled || loader}
                    onChange={this.onChangeProduct(product.productId, securityCategory)}
                    value={product.productId}
                    multiple={false}
                >
                    <option value={null}>Niets geselecteerd</option>
                    {
                        categoryOptions.map((productOption) => {
                            return <option key={productOption.name} value={productOption.id}>{productOption.name}</option>
                        })
                    }
                </Input>
            </div>
        );
    }

    addNewProduct = (productCategory) => {
        const {securitySelections} = this.state;
        const categorySelections = securitySelections[productCategory] || [];

        let updatedCategorySelections = [...categorySelections, {id: null, amount: 1}];
        let updatedProducts = securitySelections;

        updatedProducts[productCategory] = updatedCategorySelections;

        this.setState({securitySelections: updatedProducts});
    }

    onProductQuantityChangeCallback = (product, productCategory) => (event, value) => {
        const {target} = event;
        const {min, max} = target;

        if (min > value || max < value) {
            return;
        }

        const {securitySelections} = this.state;
        let updatedProducts = securitySelections;
        let categoryProducts = updatedProducts[productCategory];
        let updatedCategoryProducts = [...categoryProducts];
        let indexProduct = updatedCategoryProducts.findIndex((productItem) => productItem.productId === product.productId);
        if (indexProduct < 0) {
            return;
        }

        let updatedProduct = product;
        updatedProduct.amount = value;

        updatedCategoryProducts[indexProduct] = updatedProduct;
        updatedProducts[productCategory] = updatedCategoryProducts;

        this.setState({securitySelections: updatedProducts});
    }

    onProductQuantitySave = (productId, productCategory) => {
        const {securitySelections} = this.state;
        const quotationId = this.props.quotation.currentQuotation.id;
        const categoryProducts = securitySelections[productCategory];

        const foundProduct = categoryProducts.find((product) => product.productId === productId);
        if (! foundProduct) {
            return;
        }

        const data = {
            operation: 'updateAmount',
            product: foundProduct
        };

        this.setState({loader: true});
        this.props.partnerServicesActions.patchSecurityOptions(quotationId, data).then(() => {
            this.updateSecurityOptions();
            this.setState({loader: false});
        });
    }

    onChangeProduct = (productId, productCategory) => (event, value) => {
        value = value !== null ? parseInt(value) : null;

        if (!productId && !value) {
            return;
        }
        if (productId === value) {
            return;
        }

        let data;
        const {securitySelections} = this.state;
        const quotationId = this.props.quotation.currentQuotation.id;
        const categoryProducts = securitySelections[productCategory];

        if (!productId && value) {
            data = {
                operation: 'add',
                product: {
                    productId: value,
                    amount: 1
                }
            };

            this.setState({loader: true});
            this.props.partnerServicesActions.patchSecurityOptions(quotationId, data).then(() => {
                this.updateSecurityOptions();
                this.setState({loader: false});
            });

            return;
        }

        const foundProduct = categoryProducts.find((profile) => profile.productId === productId);
        if (! foundProduct) {
            return;
        }

        if (productId && !value) {
            data = {
                operation: 'remove',
                product: {
                    productId: productId
                }
            };

            this.setState({loader: true});
            this.props.partnerServicesActions.patchSecurityOptions(quotationId, data).then(() => {
                this.updateSecurityOptions();
                this.setState({loader: false});
            });

            return;
        }

        const alreadySelectedProduct = categoryProducts.find((profile) => profile.productId === value);
        if (alreadySelectedProduct) {
            return;
        }

        data = {
            operation: 'change',
            product: {
                newProductId: value,
                productId: productId
            }
        };

        this.setState({loader: true});
        this.props.partnerServicesActions.patchSecurityOptions(quotationId, data).then(() => {
            this.updateSecurityOptions();
            this.setState({loader: false});
        });
    }

    render() {
        const {company} = this.props.authentication;
        const {provider} = this.props.quotation.currentQuotation;
        const isYielderPartner = company.includes('Yielder Partners');
        const isNewYielderProvider = provider === providerType.YIELDER;
        const isYielderProvider = yielderProviders.includes(provider);

        let {selections, securityCategories, securitySelectedCategories} = this.state;

        let selectedProducts = selections;
        if (!selectedProducts.length) {
            selectedProducts = [{productId: null, amount: 0, options: []}];
        }

        return (
            <div className="row">
                <div className="col s12">
                    {
                        isYielderProvider && (
                            <div className="row">
                                <div id="Forms" className="col s12">
                                    {selectedProducts.map((product) => {
                                        return (
                                            <div className="row">
                                                <div className="col s12">
                                                    <h1 className={'ratio-section-title'}>
                                                      <span className="ratio-section-title">
                                                          <i className="small material-icons left ratio-section-title">call_merge</i>MKB Cloud Werkplek
                                                      </span> gewenste opties
                                                    </h1>
                                                </div>
                                                <div className="col s12">
                                                    {this.renderProductSelections(product)}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )
                    }

                    {!isYielderPartner && !isNewYielderProvider &&
                        <div className="row">
                            <div id="Forms" className="col s12">
                                {selectedProducts.map((product) => {
                                    return (
                                        <div className="row">
                                            <div className="col s12">
                                                <h1 className={'ratio-section-title'}>
                                              <span className="ratio-section-title">
                                                  <i className="small material-icons left ratio-section-title">call_merge</i>Security Assessment
                                              </span>
                                                </h1>
                                            </div>

                                            <div className="col s12">
                                                {
                                                    securityCategories.map((securityCategory) => {
                                                        return (
                                                            <Input
                                                                key={securityCategory.value}
                                                                name='providers'
                                                                type='checkbox'
                                                                checked={securitySelectedCategories.includes(securityCategory.value)}
                                                                value={securityCategory.value}
                                                                label={securityCategory.name}
                                                                onChange={this.onSelectCategory}
                                                            />
                                                        );
                                                    })
                                                }
                                            </div>

                                            <div className="col s12">
                                                {
                                                    securitySelectedCategories.map((selectedCategory) => {
                                                        return this.renderCategorySelections(selectedCategory)
                                                    })
                                                }
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    }

                </div>
            </div>
        )
    }
}

const mapStateToProps = ({quotation, partnerServices, authentication}) => {
    return {
        quotation,
        partnerServices,
        authentication
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        partnerServicesActions: bindActionCreators(partnerServicesActions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MKBWorkplace);
