import {actionTypes} from '../constants/actionTypes';
import {apiRoutes} from '../constants/apiRoutes';
import {toastr} from 'react-redux-toastr'

import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

const getAllPostcodes = (queryParams) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.postcodeRelated.getAll + (queryParams ? queryParams : '');
            let requestData = await ApiService.performRequest(RequestType.GET, url);

            dispatch(getAllPostcodesSuccess(requestData));
            dispatch(savePaginationParameters(requestData))
        } catch ({message}) {
            toastr.error('Het is niet mogelijk de lijst met offertes op te halen', message);
        }
    }
};

const getCategoriesToLink = () => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.postcodeRelated.getCategoriesToLink;
            let response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(setCategoriesToLink(response));
        } catch (error) {
            return {error};
        }
    }
};

const setCategoriesToLink = ({categories}) => {
    return {
        type: actionTypes.setPostcodeCategoriesToLink,
        payload: categories
    }
};

const getAllPostcodesSuccess = ({postcodes}) => {
    return {
        type: actionTypes.getAllPostcodes,
        payload: postcodes
    };
};

const savePaginationParameters = ({currentPage, resultsCount, pages}) => {
    return {
        type: actionTypes.savePagination,
        payload: {
            currentPage,
            resultsCount,
            pages
        }
    }
};

const patchPostcode = (postcodeId, data) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.postcodeRelated.patchPostcode.replace('{postcodeId}', postcodeId);

            const response = await ApiService.performRequest(RequestType.PATCH, url, data);

            toastr.success('Postcode bewerkt', '');
            dispatch(_patchPostcodeSuccess(response));
        } catch ({message}) {
            toastr.error('Kan de postcode niet updaten', message);
        }
    }
};

const _patchPostcodeSuccess = ({postcode}) => {
    return {
        type: actionTypes.patchPostcode,
        payload: postcode
    }
};

const addPostcode = (data) => {
    return async () => {
        try {
            const url = apiRoutes.postcodeRelated.addPostcode;

            await ApiService.performRequest(RequestType.POST, url, data);

            toastr.success('Postcode bewerkt', '');
        } catch ({message}) {
            toastr.error('Kan de postcode niet updaten', message);
        }
    }
};

const deletePostcode = (postcode) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.postcodeRelated.deletePostcode.replace('{postcodeId}', postcode.id);
            const response = await ApiService.performRequest(RequestType.DELETE, url);

            toastr.success('Postcode deleted', '');
            dispatch(deletePostcodeSuccess(postcode));
        } catch ({message}) {
            toastr.error('Failed to delete postcode', message);
        }
    }
};

const deletePostcodeSuccess = ({postcode}) => {
    return {
        type: actionTypes.deletePostcode,
        payload: postcode
    }
};

export default {
    getAllPostcodes,
    getCategoriesToLink,
    patchPostcode,
    addPostcode,
    deletePostcode
}
