export default {
  PORTING_ACTION: 1,
  RETAINING_ACTION: 2,
  CANCELING_ACTION: 3,
  SIMLESS_PORTING: 4,
  NEW: 5,
  UPGRADE: 6,
  VERLENGEN: 7,
  OPHEFFEN: 8,
  TOEVOEGING: 9,
  RETENTIE: 10,
}