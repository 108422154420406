import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

import { actionTypes } from '../constants/actionTypes';
import { apiRoutes } from '../constants/apiRoutes';
import { toastr } from 'react-redux-toastr'

const getApiStatus = (quotationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.apiStatusRelated.getAll.replace(/{quotationId}/, quotationId);
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getApiStatusSuccess(response));
    } catch (error) {
      toastr.error('We konden de API-statuslijst niet ophalen.', error.message);
    }
  }
};

const getApiStatusSuccess = (response) => {
  return {
    type: actionTypes.getApiStatus,
    payload: response
  }
};

export default {
  getApiStatus
}
