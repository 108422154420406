import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import _ from "lodash";
import {Input} from 'react-materialize'
import {Preloader} from "react-materialize";

import applicationActions from "../../../actions/applicationActions";

import './index.css';
import LoadingStyle from "./LoadingStyle";

class ApplicationsStep extends Component {
  constructor(props) {
    super(props);

    this.WINDOWS_OS = 'Windows';
    this.LINUX_OS = 'Linux';
    this.SMART_ADD_CONTROL = 'SMART_ADD_CONTROL';
    this.FSC_DASHBOARDSCHERM_OPTIES = 'FSC Dashboardscherm opties'
    this.FSC_DASHBOARD_OPTIES = 'FSC Dashboard opties'
    this.state = {
      showPcApplications: false,
      showMobileApplications: false,
      desiredOperatingSystem: null,
      selectedPcApplicationOptions: this.props.pcApplicationOptions,
      loadingOsButtons: [],
      showWindowsApplications: false,
      showAddSmartControl: false,
      platformHardwareProducts: [],
      platformModuleProducts: [],
      newHardwareProduct: false,
      flashSelections: [],
      selectedHardwareProduct: null,
      loading: false,
      addNewHardwareProducts: {}
    }
  }

  componentDidMount() {
    this.loadInitialData()
  }

  loadInitialData = () =>
    this.props.applicationActions.getApplicationData(this.props.quotation.id)
      .then(() => {
        const { data, flashData } = this.props.application.applicationData;
        let state = {desiredOperatingSystem: data.productType}

        if (this.state.showPcApplications || data.applicationType === 'PC') {
          let hardwareProducts = flashData.filter(x => x.hardware);

          let addNewHardwareProducts = {};
          hardwareProducts.forEach((el, index) => {
            addNewHardwareProducts[el.category] = !el.children.find((child) => child.quantity)
          })

          state = {
            ...state,
            showPcApplications: true,
            platformHardwareProducts: hardwareProducts,
            addNewHardwareProducts,
            platformModuleProducts: this.changeVisibility(flashData.filter(x => x.module))
          }
        }

        if (data.windowsApplicationType === this.SMART_ADD_CONTROL) {
          state = {...state, showAddSmartControl: true};
        }

        if (data.productType === this.WINDOWS_OS) {
          state = {...state, showWindowsApplications: true}
        }

        state = {...state, flashSelections: flashData}

        this.setState(state)
      })

  setPcApplications = () => {
    let state = {
      showPcApplications: !this.state.showPcApplications,
      showPcHardware: !this.state.showPcHardware
    };
    if (this.state.showMobileApplications) {
      state = {...state, showMobileApplications: !this.state.showMobileApplications};
    }

    if (this.state.showPcApplications) {
      state = {...state, showAddSmartControl: false}
    }

    this.setState(state, () => this.loadInitialData())
  }

  setMobileApplications = () => {
    let state = {showMobileApplications: !this.state.showMobileApplications};

    if (this.state.showPcApplications) {
      this.props.applicationActions.setDesiredApplicationSystem(this.props.quotation.id, 'remove-selections');
      state = {
        ...state,
        showPcApplications: false,
        showWindowsApplications: false,
        showAddSmartControl: false
      };
    }
    this.setState(state)
  }

  setAddSmartControl = () => {
    this.setState({
      showAddSmartControl: !this.state.showAddSmartControl,
      loading: true
    }, () => {
      this.props.applicationActions
          .setFlashRequiredWindowsApplicationProducts(this.props.quotation.id, this.state.showAddSmartControl ? this.SMART_ADD_CONTROL : 'remove-selections')
          .then(() => this.loadInitialData())
          .then(() => this.setState({loading: false}))
    });

  }

  updateSelection = (applicationType) => {
    let buttons = this.state.loadingOsButtons;
    buttons.push(applicationType);

    let state = {desiredOperatingSystem: applicationType, loadingOsButtons: buttons}
    if (this.state.desiredOperatingSystem === applicationType) {
      state = {...state, desiredOperatingSystem: null};

      if (this.state.desiredOperatingSystem === this.WINDOWS_OS) {
        state = {...state, showWindowsApplications: false, showAddSmartControl: false};
      }
    }

    if (state.desiredOperatingSystem === this.WINDOWS_OS) {
      state = {...state, showWindowsApplications: true};
    }

    if (state.desiredOperatingSystem === this.LINUX_OS) {
      state = {...state, showAddSmartControl: false, showWindowsApplications: false,
        platformModuleProducts: this.state.platformModuleProducts.map((el) => {
          el.children = el.children.map((child) => {
            child.quantity = 0;

            return child;
          })

          return el;
        })
      };
    }

    this.setState(state, () => {
      this.props.applicationActions.setDesiredApplicationSystem(this.props.quotation.id, this.state.desiredOperatingSystem ?? 'remove-selections')
        .then(() => this.setState({loadingOsButtons: []}))
    });

  }

  changeQuantity = (event, productId, category) => {
    let {value} = event.target;
    let platformModuleProducts = this.state.platformModuleProducts

    let categoryIndex = platformModuleProducts.findIndex(selection => selection.category === category);

    let productIndex = platformModuleProducts[categoryIndex].children.findIndex(child => child.id === productId);

    platformModuleProducts[categoryIndex].children[productIndex].quantity = Number(value);

    this.setState({platformModuleProducts: this.changeVisibility(platformModuleProducts)});

    this.addFlashProductQuantity(platformModuleProducts.filter(selection => selection.module));
  }

  changeVisibility = (data) => {
    data.map((el) => {
      el.visible = true;

      if (el.category === this.FSC_DASHBOARDSCHERM_OPTIES) {
        let parentCategory = data.find((data) => data.category === this.FSC_DASHBOARD_OPTIES);
        if (!parentCategory.children.find((child) => child.quantity > 0)) {
          el.visible = false;
        }
      }

      return el;
    })
    return data;
  }

  addFlashProductQuantity = (selections) => {
    this.props.applicationActions.addFlashProductQuantity(this.props.quotation.id, selections)
  }

  changeHardwareProduct = ({target: {value}}, previousProductId, categoryName) => {
    let hardwareProducts = this.state.platformHardwareProducts
      .map((category) => {
        if (category.category === categoryName) {
          category.children
            .filter((el) => el.id)
            .map((el) => {
              if (el.id === parseInt(value, 10)) {
                el.quantity += 1
              }

              if (el.id === previousProductId) {
                el.quantity -= 1
              }

              return el;
              })
        }
        return category;
      })

    this.props.applicationActions.updateHardwareProducts(this.props.quotation.id, hardwareProducts)
      .then(() => this.setState({
        platformHardwareProducts: hardwareProducts,
        addNewHardwareProducts: {
          ...this.state.addNewHardwareProducts,
          [categoryName]: false
        }
      }));
  }

  removeProduct = (e, product, category) => {
    let shouldAddNewProduct = false
    let hardwareProducts = this.state.platformHardwareProducts.map((el) => {
      if (el.category === category) {
        el.children = el.children.map((child) => {
         if (child.id === product.id) {
           child.quantity -= 1
         }

         return child;
       })
        shouldAddNewProduct = !el.children.find((child) => child.quantity)
      }

      return el;
    })

    this.props.applicationActions.updateHardwareProducts(this.props.quotation.id, hardwareProducts)
      .then(() => this.setState({
        hardwareProducts,
        addNewHardwareProducts: {
          ...this.state.addNewHardwareProducts,
          [category]: shouldAddNewProduct
        }
      }))
  }

  addNewSelection = (type) => this.setState({
    addNewHardwareProducts: {
      ...this.state.addNewHardwareProducts,
      [type]: true
    }
  });

  render() {
    let categories = this.state.platformHardwareProducts;
    const {inputsDisabled} = this.props.quotation;

    Object.keys(this.state.addNewHardwareProducts)
      .filter((el) => this.state.addNewHardwareProducts[el])
      .forEach((categoryName) => {
        categories = _.cloneDeep(categories).map((category) => {
          if (category.category === categoryName) {
            category.children = [
              ...category.children,
              { id: null, quantity: 1 }
            ]
          }

          return category
        })
      });

    return (
      <div className="application-tab">
        <div className="Forms">
          <div>
            <h1 className="ratio-section-title">
              <span className="ratio-section-title">
                <i className="small material-icons left ratio-section-title">
                    settings_applications
                </i>
                Applicatie instellingen
              </span>
            </h1>
            <div className="row application-buttons">
              <button
                className={"btn doceri-btn ratio-btn-steps" + (this.state.showMobileApplications ? ' active-btn' : '')}
                type="submit"
                onClick={this.setMobileApplications}
                disabled={inputsDisabled}
              >
                <i className="small material-icons left"/>
                Mobile Applications
              </button>
              <button
                className={"btn doceri-btn ratio-btn-steps" + (this.state.showPcApplications ? ' active-btn' : '')}
                type="submit"
                onClick={this.setPcApplications}
                disabled={inputsDisabled}
              >
                <i className="small material-icons left"/>
                PC Applications
              </button>
            </div>
          </div>
        </div>
        {
          this.state.showPcApplications &&
          <div className="Forms">
            <div>
              <h1 className="ratio-section-title">
                <span className="ratio-section-title">
                  <i className="small material-icons left">
                      computer
                  </i>
                  Gewenste besturingssystem
                </span>
              </h1>
              <div className="row application-buttons">
                <button
                  disabled={this.state.loadingOsButtons.length || inputsDisabled}
                  className={"btn doceri-btn ratio-btn-steps" + (this.state.desiredOperatingSystem === this.WINDOWS_OS ? ' active-btn' : '')}
                  type="submit"
                  onClick={() => this.updateSelection(this.WINDOWS_OS)}
                >
                  {
                    this.state.loadingOsButtons.includes(this.WINDOWS_OS) ?
                      <LoadingStyle theme={this.props.authentication.theme}>
                        <Preloader size='medium'/>
                      </LoadingStyle> :
                      'Windows'
                  }
                </button>
                <button
                  disabled={this.state.loadingOsButtons.length || inputsDisabled}
                  className={"btn doceri-btn ratio-btn-steps" + (this.state.desiredOperatingSystem === this.LINUX_OS ? ' active-btn' : '')}
                  type="submit"
                  onClick={() => this.updateSelection(this.LINUX_OS)}
                >
                  {
                    this.state.loadingOsButtons.includes(this.LINUX_OS) ?
                      <LoadingStyle theme={this.props.authentication.theme}>
                        <Preloader size='medium'/>
                      </LoadingStyle> :
                      'Linux'
                  }
                </button>
              </div>
            </div>
          </div>
        }
        {
          this.state.showWindowsApplications &&
          <div className="Forms">
            <div>
              <h1 className="ratio-section-title">
                <span className="ratio-section-title">
                  <i className="small material-icons left desired-wapp-icon">
                      windows
                  </i>
                  Gewenste Windows Applicaties
                </span>
              </h1>
            </div>
            <div className="row application-buttons">
              <button
                type="submit"
                className={"btn doceri-btn ratio-btn-steps" + (this.state.showAddSmartControl ? ' active-btn' : '')}
                onClick={() => this.setAddSmartControl(this.SMART_ADD_CONTROL)}
                disabled={inputsDisabled}
              >
                FSC Toevoegen
              </button>
              <button
                type="submit"
                className={"btn doceri-btn ratio-btn-steps"}
                disabled={inputsDisabled}
              >
                Other
              </button>
              <button
                type="submit"
                className={"btn doceri-btn ratio-btn-steps"}
                disabled={inputsDisabled}
              >
                Other
              </button>
            </div>
          </div>
        }
        {
          this.state.showAddSmartControl && !this.state.loading &&
          <>
            <div className="Forms">
              <div>
                <h1 className="ratio-section-title">
                  <span className="ratio-section-title">
                    <i className="small material-icons left ratio-section-title">
                        lens
                    </i>
                    Flash Smart Control
                  </span>
                </h1>
              </div>
              {
                this.state.platformModuleProducts.filter((el) => el.visible).map((x) => (
                    <div className="row">
                    <div className="category-label">{x.category}</div>
                    {
                      x.children.map((el) => {
                        let inputClass = 'm12';
                        if (x.category === 'FSC Module') {
                          inputClass = 'm6'
                        }

                        let categoryIndex = this.state.platformModuleProducts.findIndex(selection => selection.category === x.category);
                        let productIndex = this.state.platformModuleProducts[categoryIndex].children.findIndex(child => child.id === el.id);
                        return <div className={"col s12 " + inputClass}>
                          <Input
                            type="number"
                            label={el.name}
                            value={this.state.platformModuleProducts[categoryIndex].children[productIndex].quantity}
                            onChange={(e) => {
                              this.changeQuantity(e, el.id, x.category)
                            }}
                            max={50}
                            disabled={inputsDisabled}
                          />
                        </div>
                      })
                    }
                  </div>
                ))
              }
            </div>
            <div className="Forms">
              <div>
                <h1 className="ratio-section-title">
                  <span className="ratio-section-title">
                    <i className="small material-icons left ratio-section-title">lens</i>
                    Gewenste PC hardwawre
                  </span>
                </h1>
              </div>
              {
                categories.map((category, index) =>
                  category.children.map((hardware) => {

                    return [...Array(hardware.quantity)].map((selection) => (
                        <div key={index + '_' + selection} className="row">
                          <div className="col s12 m6 hardware-input-container">
                            <Input
                                s={8}
                                type="select"
                                name="applicationHardwareInput"
                                label={category.category}
                                className="ratio-input"
                                onChange={(e) => this.changeHardwareProduct(e, hardware.id, category.category)}
                                value={hardware.id}
                                disabled={inputsDisabled}
                            >
                              <option value={null}>Selecteer</option>
                              {
                                category.children.filter((el) => el.id).map((el) => (
                                    <option key={el.id} value={el.id}>{el.name}</option>))
                              }
                            </Input>
                          </div>
                          <div className="application-tab-hardware-buttons">
                            <button
                                className="btn doceri-btn ratio-btn-steps"
                                onClick={(e) => this.removeProduct(e, hardware, category.category)}
                                disabled={inputsDisabled}
                            >
                              Verwijder
                            </button>
                            <button
                              className="btn doceri-btn ratio-btn-steps"
                              onClick={() => this.addNewSelection(category.category)}
                              disabled={inputsDisabled}
                            >
                              {category.category + ' +'}
                            </button>
                          </div>
                        </div>
                    ))
                  })
                )
              }
            </div>
          </>
        }
      </div>
    )
  }
}

const mapStateToProps = ({quotation, application, authentication}) => {
  return {
    quotation: quotation.currentQuotation,
    application,
    authentication,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    applicationActions: bindActionCreators(applicationActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationsStep);
