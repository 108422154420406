import React, {Component} from 'react';
import {connect} from "react-redux";

import GeneralDialog from "../../../containers/common/GeneralDialog";

class DeleteData extends Component {
  render() {
    let {dialogOpened, onRequestClose, deleteData, type} = this.props;

    return (
      <GeneralDialog
        dialogState={dialogOpened}
        headerIcon="folder_open"
        headerText={`Alle ${type} opties leeggooien?`}
        headerSubtext=""
        closeHandler={onRequestClose}
        dialogBodyContent={(
          <div className="col s12">
            Weet u zeker dat u alle opties wilt verwijderen? Met deze keuze zullen alle opties worden
            verwijderd.<br/><br/>
          </div>
        )}
        leftButtonLeft={true}
        leftButtonAction={() => deleteData()}
        leftButtonText={'Ja, ik weet het zeker'}
        rightButtonAction={onRequestClose}
        rightButtonText={"Nee, annuleren"}
      />
    );
  }
};

const mapStateToProps = ({quotation}) => {
  return {
    quotation: quotation
  }
};

export default connect(mapStateToProps)(DeleteData);
