import configGeneral from "../config/general";
import providerType from "../constants/providerType";

const { providersList } = configGeneral;

export default class ProviderHelper {

    static hasModuleEnabled(quotation, moduleName) {
        let enabledModules = quotation.enabledModules;
        return enabledModules ? enabledModules.filter((el) => el.name === moduleName).length : false;
    };

    static getQuotationProviderName(quotationProviderEnum, accessPortfolio) {
        let provider = providersList.filter((el) => {return el.id === parseInt(quotationProviderEnum)});
        let providerName = provider.length ? provider[0].name : '';
        if (providerName &&  quotationProviderEnum === providerType.ODIDO && accessPortfolio) {
            return providerName + ' + ' + accessPortfolio.toUpperCase();
        }

        return providerName;
    };
}
