import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import {Col} from "react-materialize";
import Moment from 'react-moment';
import './scrollTopButton.css';
import RatioOverflowTableWrapper from "./styled-components/ratio-overflow-table-wrapper/RatioOverflowTableWrapper";

class InternalTable extends Component {
  getIcon(sortDirection) {
    switch (sortDirection) {
      case "ASC":
        return 'arrow_downward';
      case "DESC":
        return 'arrow_upward';
      case false:
        return 'swap_vert'
    }
  }
  
  generateHeader() {
    return (
      this.props.headersNames.map((headerName) => {
        return <TableHeaderColumn
          className="clickable"
          key={headerName}
          headername={headerName}
          style={this.getStyle(headerName)}
        >
          {headerName}
          <i className="material-icons right lightgrey">
            {this.getIcon(this.props.sortingState[this.props.getPropertyNameFromDisplayName(headerName)])}
          </i>
        </TableHeaderColumn>
      })
    );
  }

  getStyle = (property) => {
    const {showOrganizationsColumn} = this.props;

    switch (property) {
      case 'createdAt':
      case 'updatedAt':
      case 'Aangemaakt':
      case 'Gewijzigd':
      case 'laatst gewijzigd':
        return {width: '9%'};
      case 'providerName':
      case 'Portfolio':
        return {width: '6%'};
      case 'name':
      case 'Organisatie naam':
      case 'Naam offerte':
      case 'Naam order':
      case 'Contactpersoon':
      case 'Portfolios':
      case 'providersFormatted':
        return {width: showOrganizationsColumn ? '12%' : '15%'};
      case 'author':
      case 'Door wie gemaakt':
        return {width: '10%'};
      case 'companyName':
      case 'Bedrijfsnaam':
        return {width: showOrganizationsColumn ? '10%' : '12%'};
      default:
        return {}
    }
  };

  generateRowValue = (row, property, statusCondition) => {
    switch (property) {
      case 'status':
        if (row['pendingDataRetrieval']) {
          return <Col s={12} className="progressStatus">Data wordt opgehaald</Col>;
        } else {
          return statusCondition === null ? this.props.displayStatusInRow(row[property], row.id) :
            this.generateOrderingStatus(row['ordering_status']);
        }
      case 'createdAt':
        return <Moment format="YYYY-MM-DD">{new Date(row[property])}</Moment>;
      case 'updatedAt':
        return <Moment format="YYYY-MM-DD">{new Date(row[property])}</Moment>;
      case 'quotationSentAt':
        if (row[property]) {
          return <Moment format="DD-MM-yyyy HH:mm">{new Date(row[property])}</Moment>;
        }
      default:
        return row[property];
    }
  };

  generateRow(row) {
    let properties = this.props.propertiesToShow;
    let statusCondition = row['ordering_status'];
    let isVp = !!properties.find((el) => el === 'vp');
    const deleted = row['deleted_at'] || null;
    var date = new Date(row['expirationDate']);
    var today = new Date();

    return (
      <TableRow key={row.id}>
        {properties.filter((el) => el !== 'vp').map(property =>
            property === 'expirationDate' ?
              <TableRowColumn key={property} style={this.getStyle(property)}>
                <div style={{ color: date && date < today ? 'red' : 'orange' }}>
                  {row['expirationDate']}
                </div>
              </TableRowColumn>
              :
              <TableRowColumn key={property} style={this.getStyle(property)}>
                <div className={deleted ? 'ratio-section-title' : ''}>
                  {this.generateRowValue(row, property, statusCondition)}
                </div>
              </TableRowColumn>

        )}

        
        {this.props.actionsColumn ? <TableRowColumn>{this.props.actionsColumn(row)}</TableRowColumn> : ''}
        {isVp &&
          <TableRowColumn key={'vp'}>
            {row['vp']}
          </TableRowColumn>
        }
      </TableRow>
    );
  }
  
  generateOrderingStatus(status) {
    if (status === 'ALERT!' || status === 'Deels georderd - ALERT!') {
      return <Col s={12} className="dangerStatus"> {status} </Col>;
    }
    
    if (status === 'Deels georderd') {
      return <Col s={12}> {status} </Col>;
    }
    
    if (status === 'In behandeling') {
      return <Col s={12} className="progressStatus"> {status} </Col>;
    }
    
    if (status === 'Opgeleverd') {
      return <Col s={12} className="finishedStatus"> {status} </Col>;
    }
  }
  
  render() {
    return (
        <RatioOverflowTableWrapper>
          <Table
              onRowSelection={this.props.handleRowSelection}
              selectable={false}
          >
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow onCellClick={this.props.onHeaderClick}>
                {
                  this.generateHeader()
                }
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              {
                this.props.rows.map(row => this.generateRow(row))
              }
            </TableBody>
          </Table>
        </RatioOverflowTableWrapper>
    );
  }
}

InternalTable.propTypes = {
  headersNames: PropTypes.array.isRequired,
  propertiesToShow: PropTypes.array.isRequired,
  handleRowSelection: PropTypes.func,
  rows: PropTypes.array.isRequired,
  onHeaderClick: PropTypes.func,
  displayStatusInRow: PropTypes.func,
  sortingState: PropTypes.any,
  getPropertyNameFromDisplayName: PropTypes.func
  // actionsColumn: PropTypes.any,
};

export default InternalTable;
