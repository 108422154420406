import React from 'react';
import organization from "../constants/organization";

const DigitalSigningMenu = props => {
	let {contactPerson, phoneNumber, company, theme} = props;
	
	if (company === organization.SV) {
		company = 'KPN';
	}

	return (
		<div className="col digital-signing-menu-container" style={{display: 'flex'}}>
			<div className="logo-large-digital-signing">
				<img src={theme.logo} className="responsive-img app-header-logo-digital-signing" alt=""/>
			</div>

			<div className="digital-signing-menu-data" style={{marginLeft: '20px'}}>
				<h4 style={{paddingBottom: '2rem'}}>Offerte van {company}</h4>
				<h6 className="contact-person">Contact persoon: {contactPerson}</h6>
				<h6 className="mobile-number">Telefoonnummer: {phoneNumber}</h6>
			</div>
		</div>
	);
};

export default DigitalSigningMenu;
