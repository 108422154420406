import {
    actionTypes
} from '../constants/actionTypes';

const initialState = {
    allOrganizations: [],
    pages: 1,
    currentPage: 1,
    resultsCount: 0,
    organization: {
        id: null,
        name: '',
        quotationValidity: null,
        oneTimePartnerService: null,
        versioning: 0,
        readonlyOfferExport: false,
        allowDigitalSigning: false,
        email: '',
        hasTermsAndConditions: false
    }
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.getOrganization:
            return {
                ...state,
                organization: action.payload
            };
        case actionTypes.getAllOrganizations:
            return {
                ...state,
                allOrganizations: action.payload
            };
        case actionTypes.savePagination:
            return {
                ...state,
                pages : action.payload.pages,
                currentPage: action.payload.currentPage,
                resultsCount: action.payload.resultsCount
            };
        case actionTypes.editOrganization:
            return {
                ...state,
                allOrganizations: state.allOrganizations.map( (organization) => organization.id === action.payload.id ? action.payload : organization ),
                organization: action.payload
            };
        default:
            return state;
    }
}
