import React, { Component } from 'react';
import {Input, Col} from 'react-materialize';
import {connect} from "react-redux";
import RegularSelect from "../../../components/RegularSelect";
import "../optionsStyle.css";

class HardwareOption extends Component {

    constructor(props) {
        super(props);
        let hardwareOption = this.props.hardwareOption;

        this.state = {
            hardwareName: this.getPropValue(hardwareOption.name, 'text'),
            hardwareNumber: this.getPropValue(hardwareOption.product_number, 'text'),
            hardwareLabel: this.getPropValue(hardwareOption.label, 'text'),
            hardwareCategory: this.getPropValue(hardwareOption.category, 'text'),
            hardwareMandatory: this.getPropValue(hardwareOption.mandatory, 'text') === 1 ? 'Ja' : 'Nee',
            showWhen0: this.getPropValue(hardwareOption.showWhen0, 'number'),
            purchasePriceOneOff: this.getPropValue(hardwareOption.purchase_price_one_off, 'number'),
            purchasePricePerMonth: this.getPropValue(hardwareOption.purchase_price_per_month, 'number'),
            sellingPriceOneOff: this.getPropValue(hardwareOption.selling_price_one_off, 'number'),
            sellingPricePerMonth: this.getPropValue(hardwareOption.selling_price_per_month, 'number'),
            setUpPrice: this.getPropValue(hardwareOption.set_up_price, 'number'),
            installationPrice: this.getPropValue(hardwareOption.installation_price, 'number'),
            pmPrice: this.getPropValue(hardwareOption.pm_price, 'number'),
            hardwareProductLabel: (hardwareOption.type === 'access') ? 'Access' : ((hardwareOption.type === 'mobiel') ? 'Mobiel' : 'Voice'),
            hardwareId: hardwareOption.id,
        };
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.hardwareOption.id!==this.state.hardwareId){
            let {
                name,
                product_number,
                label,
                category,
                mandatory,
                showWhen0,
                purchase_price_one_off,
                purchase_price_per_month,
                selling_price_one_off,
                selling_price_per_month,
                set_up_price,
                installation_price,
                pm_price,
                type,
                id
            } = nextProps.hardwareOption;

            this.setState({
                hardwareName: this.getPropValue(name, 'text'),
                hardwareNumber: this.getPropValue(product_number, 'text'),
                hardwareLabel: this.getPropValue(label, 'text'),
                hardwareCategory: this.getPropValue(category, 'text'),
                hardwareMandatory: this.getPropValue(mandatory, 'text') === 1 ? 'Ja' : 'Nee',
                showWhen0: this.getPropValue(showWhen0, 'number'),
                purchasePriceOneOff: this.getPropValue(purchase_price_one_off, 'number'),
                purchasePricePerMonth: this.getPropValue(purchase_price_per_month, 'number'),
                sellingPriceOneOff: this.getPropValue(selling_price_one_off, 'number'),
                sellingPricePerMonth: this.getPropValue(selling_price_per_month, 'number'),
                setUpPrice: this.getPropValue(set_up_price, 'number'),
                installationPrice: this.getPropValue(installation_price, 'number'),
                pmPrice: this.getPropValue(pm_price, 'number'),
                hardwareProductLabel: (type === 'access') ? 'Access' :
                    ((type === 'mobiel') ? 'Mobiel' : 'Voice'),
                hardwareId: id
            });
        }
    }

    getPropValue = (value, type) => {
        if (value !== null && value !== undefined) {
            return value;
        }

        if (type === 'text') {
            return '';
        } else {
            return 0;
        }
    };

    onSaveCheck = (hardwareId) => {
        if (this.props.allowEditOnHardware === hardwareId) {
            this.props.onSave(hardwareId, this.state)
        } else {
            return false;
        }
    };

    onChange = (target, hardwareId) => {
        if (this.props.allowEditOnHardware === hardwareId) {
            let {name, value} = target;

            this.setState({
                [name] : value
            });
        }

        return false;
    };

    onChangeShowWhen0 = ({target}) => {
        let { name } = target;
        let value;

        value = name === 'showWhen0';
        if (value) {
            value = !(value && this.state.showWhen0);
        } else {
            name = 'showWhen0';
            value = ! this.state.showWhen0;
        }

        this.setState({[name]: value});
    };

    onMandatoryChange = (value ,hardwareId) => {
        if (this.props.allowEditOnHardware === hardwareId) {
            this.setState({
                hardwareMandatory : value
            });
        }

        return false;
    };

    render() {
        let { hardwareOption, onEdit, onDelete} = this.props;
        let hardwareId = hardwareOption.id;

        return (
            <div className='col s12'>
            <div className='row'>
                <div key={`${hardwareId}`} className='col s8'>
                    <div className="row">
                        <Input
                            s={3}
                            value={this.state.hardwareName}
                            onChange={(event) => {this.onChange(event.target, hardwareId)}}
                            label='Hardware'
                            name='hardwareName'
                            type='text' />

                        <Input
                            s={2}
                            value={this.state.hardwareNumber}
                            onChange={(event) => {this.onChange(event.target, hardwareId)}}
                            label='ProductCode'
                            name='hardwareNumber'
                            type='text' />

                        <Input
                            s={3}
                            value={this.state.hardwareLabel}
                            onChange={(event) => {this.onChange(event.target, hardwareId)}}
                            label='Label'
                            name='hardwareLabel'
                            type='text' />

                        <Input
                            s={3}
                            value={this.state.hardwareCategory}
                            onChange={(event) => {this.onChange(event.target, hardwareId)}}
                            label='Categorie'
                            name='hardwareCategory'
                            type='text' />
                        {
                            this.props.allowEditOnHardware === hardwareId &&
                            <Col s={1} className="input-field inner-addon">
                                <label htmlFor={"hardwareMandatory"}>Verplicht?</label>
                                <RegularSelect
                                    name="hardwareMandatory"
                                    id={"hardwareMandatory"}
                                    onChange={(e) => {
                                        this.onMandatoryChange(e.target.value, hardwareId)
                                    }}
                                    value={this.state.hardwareMandatory}
                                    multiple={false}
                                >
                                    <option
                                        key={0}
                                        value={"Ja"}
                                        name="obligation">
                                        {"Ja"}
                                    </option>
                                    <option
                                        key={1}
                                        value={"Nee"}
                                        name="obligation">
                                        {"Nee"}
                                    </option>
                                </RegularSelect>
                            </Col>
                        }
                        {
                            this.props.allowEditOnHardware !== hardwareId &&
                            <Input
                                s={1}
                                value={this.state.hardwareMandatory}
                                onChange={(event) => {this.onChange(event.target, hardwareId)}}
                                label='Verplicht?'
                                name='hardwareMandatory'
                                type='text' />
                        }

                    </div>

                    <div className="row">

                        <Input value={this.state.purchasePriceOneOff}
                               s={2}
                               onChange={(event) => {this.onChange(event.target, hardwareId)}}
                               label='Inkoopprijs eenmalig'
                               name='purchasePriceOneOff'
                               type='number' />

                        <Input value={this.state.purchasePricePerMonth}
                               s={2}
                               onChange={(event) => {this.onChange(event.target, hardwareId)}}
                               label='Inkoopprijs/mnd'
                               name='purchasePricePerMonth'
                               type='number' />

                        <Input value={this.state.sellingPriceOneOff}
                               s={2}
                               onChange={(event) => {this.onChange(event.target, hardwareId)}}
                               label='Verkoopprijs eenmalig'
                               name='sellingPriceOneOff'
                               type='number' />

                        <Input value={this.state.sellingPricePerMonth}
                               s={2}
                               onChange={(event) => {this.onChange(event.target, hardwareId)}}
                               label='Verkoopprijs/mnd'
                               name='sellingPricePerMonth'
                               type='number' />

                        <Input value={this.state.setUpPrice}
                               s={2}
                               onChange={(event) => {this.onChange(event.target, hardwareId)}}
                               label='Programmeren'
                               name='setUpPrice'
                               type='number' />

                        <Input value={this.state.installationPrice}
                               s={1}
                               onChange={(event) => {this.onChange(event.target, hardwareId)}}
                               label='Installeren'
                               name='installationPrice'
                               type='number' />

                        <Input value={this.state.pmPrice}
                               s={1}
                               onChange={(event) => {this.onChange(event.target, hardwareId)}}
                               label='PM'
                               name='pmPrice'
                               type='number' />

                    </div>

                    <div className="row">
                        <Input
                          name='showWhen0'
                          type='checkbox'
                          checked={this.state.showWhen0}
                          label={'Zou moeten verschijnen wanneer 0'}
                          onChange={this.onChangeShowWhen0}
                        />
                        <Input
                          name='notShowWhen0'
                          type='checkbox'
                          checked={!this.state.showWhen0}
                          label={'Zou niet moeten verschijnen als 0'}
                          onChange={this.onChangeShowWhen0}
                        />
                    </div>
                </div>
                <div className="col s2">
                </div>
                <div className="col s2 buttonsBox">
                    <div className="row">
                        <button
                            className="btn doceri-btn-right kpn-btn-right productButton"
                            type="submit"
                            onClick={() => onDelete(hardwareId)}
                            name="action">
                            <i className="small material-icons left white-text">delete</i>
                            Verwijderen
                        </button>
                    </div>
                    <div className="row">
                        <button
                            className="btn doceri-btn-right kpn-btn-right productButton"
                            type="submit"
                            onClick={() => onEdit(hardwareId)}
                            name="action">
                            <i className="small material-icons left white-text">edit</i>
                            Wijzigen
                        </button>
                    </div>

                    <div className="row">
                        <button
                            className="btn doceri-btn-right kpn-btn-right productButton"
                            type="submit"
                            onClick={() => this.onSaveCheck(hardwareId)}
                            name="action">
                            <i className="small material-icons left white-text">save</i>
                            Opslaan
                        </button>
                    </div>
                </div>
            </div>
                <div className="line30 clearfix">&nbsp;</div>
            </div>
        )
    };
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(HardwareOption);
