import React, {Component} from 'react';
import {Col, Input, Row} from 'react-materialize';
import LineSeperator from '../../../components/lineSeperator'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {DelayInput} from "react-delay-input";

import accessActions from "../../../actions/workflow/accessActions";
import locationActions from "../../../actions/locationActions";

class AccessLocationSdWan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sdWanOptions: this.props.locationSdWanOptions,
      sdWanProducts: []
    };
  }

  componentDidMount() {
    if (this.props.locationSdWanOptions.type) {
      this.getSdWanProducts();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locationSdWanOptions !== this.props.locationSdWanOptions) {
      this.setState({sdWanOptions: this.props.locationSdWanOptions});
    }

    if (prevProps.locationSdWanOptions.type !== this.props.locationSdWanOptions.type || prevProps.locationId !== this.props.locationId) {
      this.getSdWanProducts();
    }
  }

  getSdWanProducts = () => {
    this.props.accessActions.getSdWanProducts(this.props.locationId).then((response) => {
      this.setState({sdWanProducts: response.length ? response : []});
    });
  };

  changeSdWanProduct = ({target}) => {
    let product = null;
    let productId = parseInt(target.value);
    if (!isNaN(productId)) {
      product = this.state.sdWanProducts.find((product) => product.id === productId);
    } else {
      productId = -1;
    }

    this.props.accessActions.updateSdWanLocationProduct(this.props.locationId, productId);
    this.props.accessActions.updateSdWanLocationProductLocal(product);
  };

  changeOption = (property, value) => {
    this.props.accessActions.updateLocationSdWanProperty(this.props.locationId, {property: property, value: value});
  };

  updatePropertyAmount = ({target}) => {
    let {name, value} = target;
    this.props.accessActions.updateLocationSdWanProperty(this.props.locationId, {property: name, value: value})
      .then(() => {this.props.locationActions.getLocationSdWanOptions(this.props.locationId);});
  };

  onChangeSipTrunkPrice = ({target}, sipTrunkId) => {
    this.props.accessActions.updateSdWanSipTrunk(sipTrunkId, target.value);
  };

  renderProductOptions = () => {
    let options = [];
    options.push(<option key={-1} value="">Selecteer</option>);

    this.state.sdWanProducts.forEach((sdWanProduct) => {
      options.push(<option key={sdWanProduct.id} value={sdWanProduct.id}>{sdWanProduct.name}</option>);
    });

    return options;
  };

  generateSIPTrunks = () => {
    const {inputsDisabled} = this.props;
    return this.state.sdWanOptions.sdWanSipTrunks.map((sipTrunk, index) => {
      return (
        <div className="input-field inner-addon col s2" style={{marginTop: '20px'}}>
          <i className="glyphicon material-icons left lightgrey"
             style={{fontSize: "1.35em"}}>euro_symbol</i>
          <DelayInput
            type="text"
            name="sdWanSipTrunk"
            min={0}
            delayTimeout={1000}
            className="without-margin-bottom"
            onChange={event => this.onChangeSipTrunkPrice(event, sipTrunk.id)}
            value={sipTrunk.price}
            disabled={inputsDisabled}
          />
          <label className="active" style={{width:"100%", marginLeft: 0}}>{`Prijs SIP Trunk ${index + 1}`}</label>
        </div>
      );
    })
  };

  render() {
    let {
      type,
      product,
      ipVpn,
      internetAccess,
      serviceLevelType,
      businessAnywhere,
      businessAnywhereAmount,
      sipTrunk,
      sipTrunkAmount
    } = this.state.sdWanOptions;
    const {inputsDisabled} = this.props;

    return (
      <div>
        <Col s={12} className="linesplit" style={{marginBottom: 20}}>
          <LineSeperator/>
        </Col>
        <Col s={12} className="overviewkopbg">
          <div className="overviewkop">
            <i className="small material-icons left themeprimarycolor">playlist_add_check</i>
            SD WAN instellen
          </div>
        </Col>
        <div className="col s12 blokborder overviewkopbg">
          <Row>
            <div style={{marginLeft: '10px'}}>
              <button
                className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${type === 'ownInfra' ? "active" : ""}`}
                style={{marginRight: 15}}
                onClick={() => this.changeOption('type', 'ownInfra')}
                disabled={inputsDisabled}
              >
                Connectivity: Own infra
              </button>
              <button
                className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${type === '3rdParty' ? "active" : ""}`}
                onClick={() => this.changeOption('type', '3rdParty')}
                disabled={inputsDisabled}
              >
                Connectivity: 3rd party
              </button>
            </div>
          </Row>
          <Row style={{marginTop: '40px'}}>
            <Input
              s={12}
              name={`sdWan_product-${this.props.locationId}`}
              type='select'
              label="Kies de gewenste Vodafone Flex dienst"
              onChange={(event) => this.changeSdWanProduct(event)}
              value={product ? product.id : ''}
              multiple={false}
              disabled={inputsDisabled}
            >
              {this.renderProductOptions()}
            </Input>
          </Row>
          <Row>
            <Col s={12} className="linesplit" style={{marginBottom: 20}}>
              <LineSeperator/>
            </Col>
            <Col s={12}>
              SD WAN opties
            </Col>
          </Row>

          <Row>
            <div>
              <button
                className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${!ipVpn ? "active" : ""}`}
                style={{marginRight: 15}}
                onClick={() => this.changeOption('ipVpn', false)}
                disabled={inputsDisabled}
              >
                Geen IP-VPN gewenst
              </button>
              <button
                className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${ipVpn  ? "active" : ""}`}
                onClick={() => this.changeOption('ipVpn', true)}
                disabled={inputsDisabled}
              >
                IP-VPN toevoegen
              </button>
            </div>
          </Row>
          <Row>
            <div>
              <button
                className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${!internetAccess ? "active" : ""}`}
                style={{marginRight: 15}}
                onClick={() => this.changeOption('internetAccess', false)}
                disabled={inputsDisabled}
              >
                Geen Internet toegang gewenst
              </button>
              <button
                className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${internetAccess ? "active" : ""}`}
                onClick={() => this.changeOption('internetAccess', true)}
                disabled={inputsDisabled}
              >
                Internet toegang toevoegen
              </button>
            </div>
          </Row>
          <Row>
            <div>
              <button
                className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${!serviceLevelType ? "active" : ""}`}
                style={{marginRight: 15}}
                onClick={() => this.changeOption('serviceLevelType', 0)}
                disabled={inputsDisabled}
              >
                Service Level - kantoortijden
              </button>
              <button
                className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${serviceLevelType ? "active" : ""}`}
                onClick={() => this.changeOption('serviceLevelType', 1)}
                disabled={inputsDisabled}
              >
                Service Level - 24x7
              </button>
            </div>
          </Row>
          <Row>
            <div>
              <button
                className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${!businessAnywhere? "active" : ""}`}
                style={{marginRight: 15}}
                onClick={() => this.changeOption('businessAnywhere', false)}
                disabled={inputsDisabled}
              >
                Geen Business Anywhere gewenst
              </button>
              <button
                className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${businessAnywhere ? "active" : ""}`}
                onClick={() => this.changeOption('businessAnywhere', true)}
                disabled={inputsDisabled}
              >
                Business Anywhere toevoegen
              </button>
            </div>
            {
              businessAnywhere && (
                <div>
                  <Input
                    value={businessAnywhereAmount}
                    s={3}
                    type="number"
                    name="businessAnywhereAmount"
                    className="without-margin-bottom"
                    onChange={event => this.updatePropertyAmount(event)}
                    label="Aantal Business Anywhere"
                    disabled={inputsDisabled}
                  />
                </div>
              )
            }
          </Row>

          <Row>
            <Col s={12} className="linesplit" style={{marginBottom: 20}}>
              <LineSeperator/>
            </Col>
            <Col s={12}>
              SIP trunk opties
            </Col>
          </Row>
          <Row>
            <div>
              <button
                className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${!sipTrunk ? "active" : ""}`}
                style={{marginRight: 15}}
                onClick={() => this.changeOption('sipTrunk', false)}
                disabled={inputsDisabled}
              >
                Geen SIP trunk gewenst
              </button>
              <button
                className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${sipTrunk ? "active" : ""}`}
                onClick={() => this.changeOption('sipTrunk', true)}
                disabled={inputsDisabled}
              >
                SIP trunk toevoegen
              </button>
            </div>
            {
              sipTrunk && (
                <div>
                  <Input
                    value={sipTrunkAmount}
                    s={3}
                    type="number"
                    name="sipTrunkAmount"
                    className="without-margin-bottom"
                    onChange={event => this.updatePropertyAmount(event)}
                    label="Aantal gewenste SIP trunks"
                    disabled={inputsDisabled}
                  />
                </div>
              )
            }
          </Row>
          <Row>
            {this.generateSIPTrunks()}
          </Row>
        </div>
      </div>
    );
  }
}


const mapStateToProps = ({authentication}) => {
  return {
    authentication
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    accessActions: bindActionCreators(accessActions, dispatch),
    locationActions: bindActionCreators(locationActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessLocationSdWan);
