import {
  actionTypes
} from '../constants/actionTypes';

const initialState = {
  newTCFile: {
    name: '',
  },
  tcFiles: [],
  tcFileBlob: '',
  tcProductCategories: [],
  pages: 1,
  currentPage: 1,
  resultsCount: 0
};

export default (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.getOrganizationTermsAndConditionsFiles:
      return {
        ...state,
        tcFiles: action.response,
      };
    case actionTypes.deleteTermsAndConditionsFile:
      return {
        ...state,
        tcFiles: state.tcFiles.filter( tcFile => tcFile.id !== action.fileId )
      };
    case actionTypes.downloadTermsAndConditionsFile:
      return {
        ...state,
        tcFileBlob: action.response
      };

    case actionTypes.setTermsAndConditionsProviderFilter:
      return {
        ...state,
        provider: action.payload
      };
    case actionTypes.setTcFilesPagination:
      return {
        ...state,
        pages : action.payload.pages,
        currentPage: action.payload.currentPage,
        resultsCount: action.payload.resultsCount
      };
    case actionTypes.setTermsAndConditionsProductCategories:
      return {
        ...state,
        tcProductCategories: action.payload
      };
    default:
      return state;
  }
}
