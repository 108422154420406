import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import debounce from "lodash/debounce";
import { Input, Row} from "react-materialize";

import getQuotationOptionsThunk from "../../actions/workflow/isdnMigrationActions/getQuotationOptionsThunk";
import updateQuotationOptionsThunk from "../../actions/workflow/isdnMigrationActions/updateQuotationOptionsThunk";
import quotationActions from "../../actions/quotationActions";
import partnerServicesActions from "../../actions/partnerServicesActions";

import InputNumberWrapper from './InputNumberWrapper';

class PartnerServiceOptionWrapper extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selections: [],
    }
  }

  componentDidMount() {
    this.saveSelections = debounce(this.saveSelections, 1000);

    const selections = this.props.partnerServices.partnerServicesSelections;
    this.setState({
      selections: selections.filter(selection => selection.category === this.props.category)
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const selections = this.props.partnerServices.partnerServicesSelections;
    const oldSelections = prevProps.partnerServices.partnerServicesSelections;

    if (oldSelections !== selections) {
      this.setState({
        selections: [...selections.filter(selection => selection.category === this.props.category)]
      })
    }
  }

  saveSelections = (selections, quotationId, type) => {
    const {partnerServicesSelections} = this.props.partnerServices;
    const {category} = this.props;

    const allSelections = partnerServicesSelections.filter(selection => selection.category !== category);
    const newSelections = [...allSelections, ...selections];

    this.props.partnerServicesActions.persistPartnerServicesSelections(newSelections, quotationId, type).then(() => {
      this.props.partnerServicesActions.setPartnerServiceSelection(newSelections);
    });
  };

  addOption = () => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }
    const {selections} = this.state;

    selections.push({
      id: '',
      quantity: 1,
      category: ''
    });

    this.setState({selections});
  };

  dependencyCheck = (optionId) => {
    let { optionsList } = this.props;
    let selectedOption = optionsList.find(el => el.id === optionId);
    let childServices = selectedOption && selectedOption.partner_related_partner_products.length > 0 ?
      selectedOption.partner_related_partner_products.map(el => el.id) : [];

    if (childServices.length === 0) {
      return;
    }

    let { selections } = this.state;
    let selectionsIds = selections && selections.length > 0 ? selections.map((el) => el.id) : [];

    childServices.forEach((childService) => {
      if (selectionsIds.includes(childService)) {
        let foundIndex = selections.findIndex((selection) => { return childService === selection.id });
        selections.splice(foundIndex, 1);
      }
    });

    this.setState({ selections: selections });
  };

  removeOption = (optionId, index) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }

    this.dependencyCheck(optionId);
    let {selections} = this.state;
    selections.splice(index, 1);

    this.saveSelections(selections, this.props.quotation.currentQuotation.id, 'partner');
    this.setState({selections});
  };

  onChange = (event, index, oldOptionId) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }
    const {value} = event.target;
    const {category, allowDecimalQuantity} = this.props;
    this.dependencyCheck(oldOptionId);

    let {selections} = this.state;

    selections[index].id = parseInt(value);
    selections[index].category = category;

    const foundSelectedIndex = selections.findIndex((selection, idx) => selection.id === parseInt(value) && idx !== index);
    if (foundSelectedIndex !== -1) {

      if (selections[foundSelectedIndex].quantity >= 999) {
        return;
      }

      if (allowDecimalQuantity) {
        selections[index].quantity = (parseFloat(selections[index].quantity)
          + parseFloat(selections[foundSelectedIndex].quantity)).toFixed(2);
      } else {
        selections[index].quantity = parseInt(selections[index].quantity)
          + parseInt(selections[foundSelectedIndex].quantity);
      }

      selections.splice(foundSelectedIndex, 1);
    }

    this.setState({selections});

    this.saveSelections(selections, this.props.quotation.currentQuotation.id, 'partner');
  };

  changeQuantity = (event, index) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }

    let {value, min, max} = event.target;
    let {selections} = this.state;

    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    selections[index].quantity = parseInt(value).toString();

    this.setState({selections});

    //filter selections to not send null ids
    selections = selections.filter(selection => selection.id != null) || [];

    this.saveSelections(selections, this.props.quotation.currentQuotation.id, 'partner');
  }

  changeDecimalQuantity = (value, index) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }
    let {selections} = this.state;

    selections[index].quantity = value;

    this.setState({selections});

    //filter selections to not send null ids
    selections = selections.filter(selection => selection.id != null) || [];

    this.saveSelections(selections, this.props.quotation.currentQuotation.id, 'partner');
  };

  renderQuantityInput = (option, index) => {
    const {allowDecimalQuantity} = this.props;
    const quantityStep = allowDecimalQuantity ? 0.01 : 1;
    let optionQuantity = option.quantity ? option.quantity : 1;
    const {selections} = this.state;
    const {inputsDisabled} = this.props.quotation.currentQuotation;

    return (
      !allowDecimalQuantity ?
        <Input
          s={1}
          type="number"
          label={index === 0 ? 'aantal' : ''}
          style={{marginBottom: selections?.length > 1 ? '15px' : null}}
          value={optionQuantity}
          onChange={(e) => {
            this.changeQuantity(e, index)
          }}
          step={quantityStep}
          min={quantityStep}
          max={999}
          disabled={inputsDisabled}
        /> :
        <InputNumberWrapper
          s={1}
          label="aantal"
          optionQuantity={optionQuantity}
          index={index}
          changeDecimalQuantity={this.changeDecimalQuantity}
          step={quantityStep}
          min={quantityStep}
          max={999}
          precision={2}
          className={'col input-field s1'}
          inputsDisabled={inputsDisabled}
        />

    );
  };

  renderOptions = (optionId) => {
    let excludedServices = [];
    let { optionsList } = this.props;
    let selectedOption = optionsList.find(el => el.id === optionId);
    let selectionsIds = this.state.selections && this.state.selections.length > 0 ? this.state.selections.map((el) => el.id) : [];
    let parentServices = optionsList.filter((el) => {
      return el.partner_related_partner_products && el.partner_related_partner_products.length > 0 &&
        ! selectionsIds.includes(el.id)
    });

    parentServices.forEach((parentService) => {
      parentService.partner_related_partner_products.forEach((relatedProduct) => {
        excludedServices = [...excludedServices, relatedProduct];
      })
    });

    let excludedServicesIds = excludedServices.map((el) => el.id);
    let childServices = selectedOption && selectedOption.partner_related_partner_products.length > 0 ?
      selectedOption.partner_related_partner_products.map(el => el.id) : [];

    let filteredOptions = optionsList.filter((el) => !excludedServicesIds.includes(el.id) && !childServices.includes(el.id));

    // Sort alphabetically
    filteredOptions = filteredOptions.sort((a, b) => a.name.localeCompare(b.name));

    return filteredOptions.map((optionItem) =>
      <option key={optionItem.id} value={optionItem.id}>{optionItem.name}</option>
    )
  };

  render() {
    let {optionsList, category} = this.props;
    const {selections} = this.state;
    const {inputsDisabled} = this.props.quotation.currentQuotation;
    const {isVenecoOrganization} = this.props.authentication;

    if (!selections.length) {
      selections.push({
        id: "",
        quantity: 1,
        category: this.props.category
      });
    }

    return (
      <fieldset disabled={inputsDisabled} style={{ border:'none', padding: 'unset', margin: 'unset'}}>
        <div className="row">
          <h1 className={'ratio-section-title'} style={{marginTop: 0}}>
            <span className="ratio-section-title">
              <i className="small material-icons left ratio-section-title">call_merge</i>Partner diensten:
            </span> {this.props.category}
          </h1>
        </div>

        {selections.map((option, index) => {
          return (
            <>
              <Row key={`${index}`} style={{marginBottom: 0}}>
                {option.id > 0 && this.renderQuantityInput(option, index)}

                <Input
                    s={!option?.id ? 8 : 7}
                    name='product'
                    type='select'
                    label={index === 0 ? `Kies de gewenste ${category.toLowerCase()}` : ''}
                    className={selections?.length > 1 && !(selections?.length - 1 === index) ? "ratio-input" : ''}
                    value={option.id}
                    onChange={(e) => {
                      this.onChange(e, index, option.id)
                    }}
                    multiple={false}
                    disabled={inputsDisabled}
                >
                  <option value="">Selecteer</option>
                  {this.renderOptions(option.id)}
                </Input>

                <button
                    className='btn doceri-btn-right ratio-btn-right s1'
                    onClick={() => this.removeOption(option.id, index)}
                    style={{float: 'left', marginLeft: 10}}
                    disabled={inputsDisabled}
                >
                  Verwijder
                </button>

                {optionsList && selections.length - 1 === index ?
                    <button className="btn doceri-btn-right ratio-btn-right" onClick={this.addOption} disabled={inputsDisabled}>
                      {category} toevoegen
                    </button> : null
                }
              </Row>
              {isVenecoOrganization &&
                  <Row>
                    <div style={{color: 'red', marginLeft: '20px'}}>
                      <span style={{border: '1px solid', borderColor: 'red', padding: 5}}>LET OP! Aantallen moeten gelijk zijn aan het aantal mobiele nummers.</span>
                    </div>
                  </Row>
              }
            </>
          );
        })}
      </fieldset>
    )
  }
}

const mapStateToProps = ({quotation, quotationOptions, partnerServices, authentication}) => {
  return {
    quotation,
    quotationOptions,
    partnerServices,
    authentication
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getQuotationOptions: bindActionCreators(getQuotationOptionsThunk, dispatch),
    updateQuotationOptions: bindActionCreators(updateQuotationOptionsThunk, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
    partnerServicesActions: bindActionCreators(partnerServicesActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerServiceOptionWrapper);
