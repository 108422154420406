import React from 'react';
import PropTypes from 'prop-types';
import ColorPicker from '../../../../components/ColorPicker';
import WrapperStyled from './WrapperStyled';
import LabelStyled from '../LabelStyled';
import { colorNames } from '../../../../components/themes';

const ColorField = ({ label, ...rest }) => {
  return (
    <WrapperStyled>
      <ColorPicker {...rest} />
      <br />
      <LabelStyled>
        {colorNames.nl[label]}
      </LabelStyled>
    </WrapperStyled>
  )
}

ColorPicker.defaultProps = {
  label: '',
};

ColorPicker.propTypes = {
  label: PropTypes.string,
};

export default ColorField;
