import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { Col, Button, Input } from 'react-materialize';
import FormGroup from '../../../components/FormGroup';
import ColorField from './ColorField';
import EditorWrapper from './EditorWrapper';
import UploadsWrapper from './UploadsWrapper';
import ColorsFieldWrapper from './ColorsFieldWrapper';
import ColorsWrapper from './ColorsWrapper';
import ImageUploadStyled from './ImageUploadStyled';
import Thumb from './Thumb';
import IconStyled from './IconStyled';
import configGeneral from '../../../config/general';
import RowStyled from '../../../components/RowStyled';
import FloatContainer from '../../../components/FloatContainer';
import LabelStyled from './LabelStyled';

const { logo, background } = configGeneral;

class ThemesForm extends React.Component {
  getValue = value => {
    return value === null || value === undefined ? '' : value;
  };

  render() {
    return (
      <Formik
        enableReinitialize
        initialValues={this.props.data}
        onSubmit={this.props.handleSubmit}
        validate={values => {
          let errors = {};

          if (values.logo) {
            const errorsArr = [];
            if (!logo.allowedFileTypes.includes(values.logo.type)) {
              errorsArr.push(`Ongeldig bestandstype. Toegestane bestandstypes zijn: ${logo.allowedFileTypes.join(', ')}`);
            }
            if (values.logo.size > logo.maxFileSize) {
              errorsArr.push(`De bestandsgrootte is te groot. Bestandsgrootte moet kleiner zijn dan ${logo.maxFileSize / 1048576}MB.`);
            }
            if (errorsArr.length) {
              errors.logo = errorsArr.join(' ');
            }
          }

          if (values.background) {
            const errorsArr = [];
            if (!background.allowedFileTypes.includes(values.background.type)) {
              errorsArr.push(`Ongeldig bestandstype. Toegestane bestandstypes zijn: ${background.allowedFileTypes.join(', ')}.`);
            }
            if (values.background.size > background.maxFileSize) {
              errorsArr.push(`De bestandsgrootte is te groot. Bestandsgrootte moet kleiner zijn dan ${background.maxFileSize / 1048576}MB.`);
            }
            if (errorsArr.length) {
              errors.background = errorsArr.join(' ');
            }
          }

          return errors;
        }}
        render={({
          values,
          handleSubmit,
          setFieldValue,
          handleChange,
          errors,
        }) => {
          const showDeleteCheckbox = !!this.props.data.currentLogo;
          const showDeleteBackgroundCheckbox = !!this.props.data.currentBackground;

          const RadioButton = ({
            field: { name, value, onChange, onBlur },
            id,
            label,
            className,
            ...props
          }) => {
            return (
              <div className='col'>
                <input
                  name={name}
                  id={id}
                  type="radio"
                  value={id}
                  checked={id === value}
                  onChange={onChange}
                  onBlur={onBlur}
                  {...props}
                />
                <label htmlFor={id}>{label}</label>
              </div>
            );
          };

          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <FormGroup>
                <EditorWrapper>
                  <UploadsWrapper>
                    <ImageUploadStyled>
                      <input
                        name="logo"
                        type="file"
                        onChange={(event) => {
                          setFieldValue('logo', event.currentTarget.files[0]);
                        }}
                        accept="image/png"
                        disabled={values.removeLogoFile}
                      />
                      <Thumb
                        file={values.logo}
                        currentImage={values.currentLogo}
                        isValid={!errors.logo}
                        crossed={values.removeLogoFile}
                        label='Upload afbeelding'
                      />
                      <LabelStyled style={{ width: '114px' }}>
                        Logo
                      </LabelStyled>
                    </ImageUploadStyled>
                    <ImageUploadStyled>
                      <input
                        name="background"
                        type="file"
                        onChange={(event) => {
                          setFieldValue('background', event.currentTarget.files[0]);
                        }}
                        accept="image/png"
                        disabled={values.removeBackgroundFile}
                      />
                      <Thumb
                        file={values.background}
                        currentImage={values.currentBackground}
                        isValid={!errors.background}
                        crossed={values.removeBackgroundFile}
                        label='Upload afbeelding'
                        width="168px"
                      />
                      <LabelStyled style={{ width: '182px' }}>
                        Achtergrondafbeelding
                      </LabelStyled>
                    </ImageUploadStyled>
                  </UploadsWrapper>
                  <ColorsFieldWrapper>
                    <Field
                      name="palette"
                      render={fieldProps => (
                        <ColorsWrapper>
                          {Object.keys(values.palette).map(key => (
                            <ColorField key={key} label={key} value={values.palette[key]} onChange={(colorRGBA) => {
                              setFieldValue('palette', { ...values.palette, [key]: colorRGBA });
                            }} />
                          ))}
                        </ColorsWrapper>
                      )}
                    />
                  </ColorsFieldWrapper>
                </EditorWrapper>

                <Col s={12}>
                  <label htmlFor="background-opacity">Dekking van de achtergrondafbeelding: {values.backgroundOpacity}%</label>
                  <p className="range-field" style={{ maxWidth: '200px' }}>
                    <input
                      name="backgroundOpacity"
                      type="range"
                      id="background-opacity"
                      min="0"
                      max="100"
                      onChange={handleChange}
                      value={values.backgroundOpacity}
                    />
                  </p>

                  {showDeleteCheckbox ? (
                    <RowStyled>
                      <Input
                        s={12}
                        name="removeLogoFile"
                        label='Verwijder uw logo'
                        type="checkbox"
                        value=''
                        onChange={handleChange}
                        validate={false}
                      />
                    </RowStyled>
                  ) : null}

                  {showDeleteBackgroundCheckbox ? (
                      <RowStyled>
                      <Input
                      s={12}
                      name="removeBackgroundFile"
                      label='Verwijder je achtergrondafbeelding'
                      type="checkbox"
                      value=''
                      onChange={handleChange}
                      validate={false}
                      />
                      </RowStyled>
                  ) : null}
                  
                  <div>
                    {Object.keys(errors).map(key => (
                      <div key={key} style={{ color: 'red' }}>
                        <IconStyled size="small">error_outline</IconStyled>
                        &nbsp;<span style={{ verticalAlign: 'middle' }}>{errors[key]}</span>
                      </div>
                    ))}
                  </div>
                </Col>
              </FormGroup>

              <FloatContainer style={{ marginBottom: '20px' }}>
                <Button type="submit" style={{ float: 'right' }}>
                  Bewerken
                </Button>
              </FloatContainer>
            </form>
          );
        }}
      />
    );
  }
}

ThemesForm.propTypes = {
  data: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ThemesForm;
