import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import locationActions from '../../actions/locationActions';
import connectionsActions from '../../actions/connectionsActions';

import Location from './location';

class Locations extends Component {

    onLocationClick = (location) => {
        let currentStepId = this.props.steps.currentStepId;
        this.props.locationActions.setSelectedLocation(location);

        if (currentStepId === 2 && location.id !== -1)
            this.props.locationActions.openEditionDialog();
        if (currentStepId === 3 && location.id !== -1)
            this.props.locationActions.setQuotationLocations(this.props.quotation.currentQuotation.id, this.props.quotation.isLead);
    };

    checkIfLocationShouldHaveDisabledClass = (location) => {
        const { voicePerLocation, mobilePerLocation, hostedVoiceOptions, sdLanOptions } = this.props.quotation.currentQuotation;
        let currentStepId = this.props.steps.currentStepId;

        if (currentStepId === 2 && location.id === -1) //in second step global level option should be disabled
            return true;
        if ([1, 18].includes(currentStepId)) //in first step all should be disabled
            return true;
        if(currentStepId === 3 )
            return this.processOptionsForAccessStep();
        if(currentStepId === 4)
            return this.proccessOptionsForSdWanStep();

        if(currentStepId === 8) {
            if (!hostedVoiceOptions) {
                return true;
            }
            if (voicePerLocation && hostedVoiceOptions.hostedVoiceActive) {
                return false;
            }
            if (!hostedVoiceOptions.hostedVoiceActive && !hostedVoiceOptions.businessConnectActive) {
                return true;
            }
            if (!voicePerLocation && !hostedVoiceOptions.businessConnectActive) {
                return true;
            }

            const disableCondition = location.flight_case === true;
            if (disableCondition && this.props.locations.selectedLocation.id !== -1) {
              this.props.locationActions.setSelectedLocation(
                this.props.locations.allLocations.find(item => item.id === -1)
              );
            }

            return disableCondition;
        }
        if(currentStepId === 10 && !mobilePerLocation){
            return true;
        }
        if(currentStepId === 13){
            return true;
        }
        if(currentStepId === 14){
            return true;
        }
        if(currentStepId === 11){
            return !(sdLanOptions.isMerakiEnabled || sdLanOptions.isUbiquitiEnabled);
        }

        if(currentStepId === 7)
            return this.processOptionsForBusinessConnectStep();

        return false;
    };

    checkIfLocationIsraCheckInProgress = (locationId) => {
       return this.props.locations.israCheckInProgressLocationIds.includes(locationId);
    };

    processOptionsForAccessStep = () => {
        if(this.props.access.accessState.length === 0){
            return false;
        }

        const globalAccessFormState = this.props.access.accessState.find(x => x.locationID === 'global');
        if(globalAccessFormState.access_selection === 'unmanaged-access'){
            //return true;
        }

        return false;
    };

    proccessOptionsForSdWanStep = () => {
        const { isSdWanNeeded }= this.props.sdWan.globalSdWanState;
        return !isSdWanNeeded;
    };

    processOptionsForBusinessConnectStep = () => {
        if(this.props.access.accessState.length === 0){
            return true;
        }

        const globalAccessFormState = this.props.access.accessState.find(x => x.locationID === 'global');
        return globalAccessFormState.access_selection === 'unmanaged-access';
    };

    israCheckPassed = (location) => {
        if (location.id === 'global' || location.flight_case === false) {
            return true;
        }

        return location.isra_check_passed;
    };

    isConnectionValid = (location) => {
        if (location.id === -1) {
            return true;
        }

        if (location.fetched) {
            return true;
        }

        return location.connections && location.connections.length > 0
            && location.connections[0].selected_connections.length > 0
    };

    shouldDisplayWarning = (location) => {
        //some other errors check

        return false;
    };

    shouldDisplayError = (location) => {
        if (location.bulk_imported) {
            if ((!location.isra_check_complete && !this.props.locations.israCheckInProgressLocationIds.length) || (location.invalid)) {
                return true;
            }
        }
        return location.bulk_imported === true && location.postal_code_check_passed === false;
    };

    render() {
        let { searchPhrase } = this.props.locations;
        let currentlySelectedLocationId = this.props.locations ? this.props.locations.selectedLocation.id : null;

        return (
            <div id="Locationslist">
                {
                    this.props.locations.allLocations.filter(x => x.name.toLowerCase().includes(searchPhrase.toLowerCase())).map(x =>
                    <Location
                        selectedLocationId={currentlySelectedLocationId}
                        config={x}
                        shouldBeDisabled={this.checkIfLocationShouldHaveDisabledClass(x)}
                        key={x.id}
                        israCheckInProgress={this.checkIfLocationIsraCheckInProgress(x.id)}
                        israCheckPassed={this.israCheckPassed(x)}
                        isConnectionValid={this.isConnectionValid(x)}
                        isFlightcase={x.flight_case}
                        onClickFunc={() => this.onLocationClick(x)}
                        shouldDisplayWarning = {this.shouldDisplayWarning(x)}
                        shouldDisplayError = {this.shouldDisplayError(x)}
                    />
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = ({ locations, steps, access, sdWan, quotation, mobile, hostedVoice }) => {
    return {
        locations,
        steps,
        access,
        sdWan,
        quotation,
        mobile,
        hostedVoice
    }
}

const mapDispatchToProps = dispatch => {
    return {
        locationActions: bindActionCreators(locationActions, dispatch),
        connectionsActions: bindActionCreators(connectionsActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
