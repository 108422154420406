import React from 'react';

const RegularSelect = props => {
  return (
    <select
      {...props}
      style={{
        display: 'block',
        position: 'relative',
        width: '100%',
        height: '3rem',
        border: '1px solid #939393',
        opacity: 1,
        pointerEvents: 'initial',
        borderRadius: '4px'
      }}
    >
      {props.children}
    </select>
  );
};

export default RegularSelect;
