import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import DialogHeader from "../../common/KpnDialog/DialogHeader";
import DialogBody from "../../common/KpnDialog/DialogBody";
import KpnDialog from "../../common/KpnDialog/KpnDialog";
import DialogFooter from "../../common/KpnDialog/DialogFooter";
import DialogButton from "../../common/KpnDialog/DialogButton";
import {Input} from "react-materialize";
import digitalSigningGroupsActions from "../../../actions/digitalSigningGroupsActions";
import DigitalSigningGroupsGrid from "./DigitalSigningGroupsGrid";
import adminActions from "../../../actions/adminActions";
import digitalSigningFieldActions from "../../../actions/digitalSigningFieldActions";
import {toastr} from "react-redux-toastr";
import {groupOptions} from "../../../constants/groupOptions";

class DigitalSigningGroupsPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpened: false,
      selectedDigitalSigningGroup: {},
      nameError: false,
      descriptionError: false,
      textFieldSettingsError: false,
      dialogSigningDetailsOpened: false,
      digitalSigningDetails: {
        header: '',
        isHeaderBold: false,
        content: '',
        isContentBold: false,
      }
    };
  }

  componentDidMount() {
    this.props.actions.getAllDigitalSigningGroups();
    this.props.actions.getDigitalSigningDetails();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.digitalSigningDetails !== this.props.digitalSigningDetails) {
      this.setState({digitalSigningDetails: this.props.digitalSigningDetails.digitalSigningDetails});
    }
  }

  onEdit = (signingGroup) => {
    this.setState({
      dialogOpened: true,
      isEdit: true,
      selectedDigitalSigningGroup: signingGroup
    });
  };

  onDeleteClick = (signingGroup) => {
    this.setState({
      deleteDialogOpened: true,
      selectedDigitalSigningGroup: signingGroup
    });
  };

  onAddClick = (row) => {
    this.props.adminActions.setCurrentSection('digital-signing-field').then(() => {
      this.props.signingFieldActions.setCurrentSigningGroup(row);
    });
  };

  onDelete = () => {
    this.props.actions.deleteSigningGroup(this.state.selectedDigitalSigningGroup).then(() => {
      this.props.actions.getAllDigitalSigningGroups();
      this.closeDeleteDialog();
    });
  };

  onAdd = () => {
    this.setState({
      dialogOpened: true,
      isEdit: false,
      selectedDigitalSigningGroup: {}
    })
  };

  closeDialog = () => {
    this.setState({
      dialogOpened: false
    });
  };

  closeDeleteDialog = () => {
    this.setState({
      deleteDialogOpened: false
    });
  };

  onDigitalSignDetailsEdit = () => {
    this.setState({
      dialogSigningDetailsOpened: true
    });
  };

  closeSigningTextDialog = () => {
    this.setState({
      dialogSigningDetailsOpened: false
    });
  };

  saveSigningDetails = () => {
    this.props.actions.patchSigningDetails(this.state.digitalSigningDetails).then(() => {
      this.props.actions.getDigitalSigningDetails();
      this.closeSigningTextDialog();
    });
  };

  validFields = () => {
    const {selectedDigitalSigningGroup} = this.state;
    const {name, description, textFieldSettings } = selectedDigitalSigningGroup;
    const invalidField = (field) => typeof field === 'undefined' || field === '';

    if (invalidField(name) && invalidField(description)) {
      this.setState({nameError: true, descriptionError: true});
      return false;
    }

    if (invalidField(name)) {
      this.setState({nameError: true});
      return false;
    }

    if (invalidField(description)) {
      this.setState({descriptionError: true});
      return false;
    }

    if (invalidField(textFieldSettings)) {
      this.setState({textFieldSettingsError: true});
      return false;
    }

    return true;
  };

  submitDialog = () => {
    if (! this.validFields()) {
      toastr.error('De velden Naam/Omschrijving zijn verplicht in te vullen');
      return;
    }

    if (this.state.isEdit) {
      const {id, name, description, isDigitalSigning, fieldSettings, textFieldSettings} = this.state.selectedDigitalSigningGroup;
      return this.props.actions.patchDigitalSigningGroup(id, {
        name,
        description,
        isDigitalSigning,
        fieldSettings,
        textFieldSettings
      }).then(() => {
        this.closeDialog();
      });
    }

    return this.props.actions.addDigitalSigningGroup(this.state.selectedDigitalSigningGroup).then(() => {
      this.closeDialog();
      this.props.actions.getAllDigitalSigningGroups();
    });
  };

  onChange = ({target}) => {
    let {name, value} = target;

    name === 'name' ? this.setState({nameError: false}) : this.setState({descriptionError: false});

    if (name === 'textFieldSettings' && value !== '') {
      this.setState({ textFieldSettingsError: false });
    }

    if (name === 'isDigitalSigning') {
      value = target.checked;

      this.setState(prevState => ({
        selectedDigitalSigningGroup: {
          ...prevState.selectedDigitalSigningGroup,
          'fieldSettings': null
        }
      }));
    }

    this.setState(prevState => ({
      selectedDigitalSigningGroup: {
        ...prevState.selectedDigitalSigningGroup,
        [name]: value
      }
    }));
  };

  onChangeDetails = ({target}) => {
    let {name, value} = target;

    if (['isHeaderBold', 'isContentBold'].includes(name)) {
      value = target.checked;
    }

    this.setState({
      ...this.state,
      digitalSigningDetails: {
        ...this.state.digitalSigningDetails, [name]: value
      }
    });
  };

  onSortClick = (row) => {
    this.props.actions.sortSigningGroup(row)
      .then(() => this.props.actions.getAllDigitalSigningGroups());
  };

  getHeaderPropertyForName = (displayName) => {
    const index = this.tableHeaderNames.indexOf(displayName);
    return this.tableObjectProperties[index];
  };

  generateGroupOptions = (options) => {
    return (
      options.map(item =>
        <option value={item.value}>{item.label}</option>
      )
    );
  };

  tableHeaderNames = ['Groep', 'Omschrijving', 'Veld instellingen', 'Acties'];
  tableObjectProperties = ['name', 'description'];

  render() {
    const {allDigitalSigningGroups} = this.props.digitalSigningGroups;
    const isEdit = this.state.isEdit;

    return (
      <div>
        <div className="overviewkopbg ratiotable row">
          <div className="col s3">
            <div className="ratiotable paddingbottomnone">
              <h1 className="ratio-section-title">Custom velden</h1>
            </div>
          </div>
          <div className="col s9">
            <button
              className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
              onClick={() => this.onAdd()}
              type="submit"
              name="action">
              <i className="small material-icons left white-text">add</i>
              Nieuwe custom groep
            </button>
            <button
              className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
              onClick={() => this.onDigitalSignDetailsEdit()}
              type="submit"
              name="action">
              <i className="small material-icons left white-text">edit</i>
              Tekst digitaal ondertekenen pagina
            </button>
          </div>
        </div>

        <DigitalSigningGroupsGrid
          rows={allDigitalSigningGroups}
          getPropertyNameFromDisplayName={this.getHeaderPropertyForName}
          headerNames={this.tableHeaderNames}
          onEditClick={this.onEdit}
          onDeleteClick={this.onDeleteClick}
          onDelete={this.onDelete}
          onAddClick={this.onAddClick}
          onSortClick={this.onSortClick}
        />

        <KpnDialog
          dialogState={this.state.dialogOpened}
          modal={false}
          dialogHeader={(
            <DialogHeader
              headerIcon={isEdit ? 'edit' : 'add'}
              headerText={`${isEdit ? 'Custom groep bewerken' : 'Custom groep toevoegen'}`}
              closeHandler={this.closeDialog}
            />
          )}
          dialogBody={
            <DialogBody>
              <div className="row">
                <Input
                  s={12}
                  className={this.state.nameError ? 'invalid' : 'valid'}
                  value={this.state.selectedDigitalSigningGroup.name}
                  onChange={this.onChange}
                  required
                  label='Naam'
                  name='name'
                  type='text'/>
                <Input
                  s={12}
                  className={this.state.descriptionError ? 'invalid' : 'valid'}
                  value={this.state.selectedDigitalSigningGroup.description}
                  onChange={this.onChange}
                  required
                  label='Omschrijving'
                  name='description'
                  type='text'/>

                {/*<Input*/}
                {/*  s={12}*/}
                {/*  value={this.state.selectedDigitalSigningGroup.fieldSettings}*/}
                {/*  onChange={this.onChange}*/}
                {/*  multiple={false}*/}
                {/*  label="Veld instellingen"*/}
                {/*  name="fieldSettings"*/}
                {/*  type="select"*/}
                {/*>*/}
                {/*  <option key={-1} value="">Selecteer een optie</option>*/}

                {/*  {this.generateGroupOptions(groupOptions)}*/}
                {/*</Input>*/}

                <Input
                  s={12}
                  value={this.state.selectedDigitalSigningGroup.textFieldSettings}
                  onChange={this.onChange}
                  multiple={false}
                  label="Instellingen tekst veld"
                  name="textFieldSettings"
                  type="select"
                >
                  <option key={-1} value="">Selecteer een optie</option>
                  <option key={0} value={0}>Enkel veld</option>
                  <option key={1} value={1}>Vrij tekst veld</option>
                </Input>

                {this.props.authentication.allowDigitalSigning && (
                  <Input
                    s={12}
                    className="checkbox"
                    checked={this.state.selectedDigitalSigningGroup.isDigitalSigning}
                    onChange={this.onChange}
                    label="Velden bestemd voor Digitaal Ondertekenen"
                    name="isDigitalSigning"
                    type="checkbox"
                  />)
                }
              </div>
            </DialogBody>
          }
          dialogFooter={
            <DialogFooter
              buttonLeft={(
                <DialogButton
                  buttonAction={this.closeDialog}
                  buttonText='Sluiten'
                  left={true}
                />
              )}
              buttonRight={(
                <DialogButton
                  buttonAction={this.submitDialog}
                  buttonText='Indienen'
                />
              )}
            />
          }
        />

        <KpnDialog
          dialogState={this.state.deleteDialogOpened}
          modal={false}
          dialogHeader={(
            <DialogHeader
              headerIcon="close"
              headerText="Delete the signing group"
              closeHandler={this.closeDeleteDialog}
            />
          )}
          dialogBody={
            <DialogBody>
              Are you sure you want to delete the signing group?
            </DialogBody>
          }
          dialogFooter={
            <DialogFooter
              buttonLeft={(
                <DialogButton
                  buttonAction={this.closeDeleteDialog}
                  buttonText='No'
                  left={true}
                />
              )}
              buttonRight={(
                <DialogButton
                  buttonAction={this.onDelete}
                  buttonText='Yes'
                />
              )}
            />
          }
        />

        <KpnDialog
          dialogState={this.state.dialogSigningDetailsOpened}
          modal={false}
          dialogHeader={(
            <DialogHeader
              headerIcon="edit"
              headerText="Tekst digitaal ondertekenen pagina"
              closeHandler={this.closeSigningTextDialog}
            />
          )}
          dialogBody={
            <DialogBody>
              <div className="row" style={{marginTop: '20px'}}>
                <Input
                  s={12}
                  value={this.state.digitalSigningDetails.header}
                  onChange={this.onChangeDetails}
                  required
                  label='Tekstkoptekst'
                  name='header'
                  type='textarea'
                />
                <Input
                  s={12}
                  className="checkbox"
                  checked={this.state.digitalSigningDetails.isHeaderBold}
                  onChange={this.onChangeDetails}
                  label="Koptekst vet maken"
                  name="isHeaderBold"
                  type="checkbox"
                />
              </div>
              <div className="row" style={{marginTop: '40px'}}>
                <Input
                  s={12}
                  value={this.state.digitalSigningDetails.content}
                  onChange={this.onChangeDetails}
                  required
                  label='Tekst inhoud'
                  name='content'
                  type='textarea'
                />
                <Input
                  s={12}
                  className="checkbox"
                  checked={this.state.digitalSigningDetails.isContentBold}
                  onChange={this.onChangeDetails}
                  label="Inhoudstekst vet maken"
                  name="isContentBold"
                  type="checkbox"
                />
              </div>
            </DialogBody>
          }
          dialogFooter={
            <DialogFooter
              buttonRight={(
                <DialogButton
                  buttonAction={this.saveSigningDetails}
                  buttonText='Opslaan'
                />
              )}
            />
          }
        />
      </div>
    )
  }
}

const mapStateToProps = ({digitalSigningGroups, digitalSigningDetails, authentication}) => {
  return {
    digitalSigningGroups,
    digitalSigningDetails,
    authentication
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(digitalSigningGroupsActions, dispatch),
    adminActions: bindActionCreators(adminActions, dispatch),
    signingFieldActions: bindActionCreators(digitalSigningFieldActions, dispatch),
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DigitalSigningGroupsPanel));
