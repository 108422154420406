import React from 'react';

const LocationsHeader = (props) => {
    return (
            <div id="Locationfilters">
                <div className={'row'}>
                  <div className="col s6">
                    <div className="locationfiltersleft left">Locaties
                      <a href="#" onClick={props.onSortClick}>
                        <em className="material-icons right lightgrey">swap_vert</em>
                      </a>
                    </div>
                  </div>

                  <div className="col s6">
                    {/*<div>
                      <a className="small right marginright15" style={{fontSize: 10}} href="#" onClick={props.onDownloadLocationsFile}>Download XLS</a>
                    </div>*/}
                  </div>
                </div>
            </div>
    );
}

export default LocationsHeader;
