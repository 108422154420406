import React, { Component } from 'react';
import KpnDialog  from './common/KpnDialog/KpnDialog';
import DialogHeader from "./common/KpnDialog/DialogHeader";
import DialogBody from "./common/KpnDialog/DialogBody";
import DialogFooter from "./common/KpnDialog/DialogFooter";
import DialogButton from "./common/KpnDialog/DialogButton";

import store from '../store';
import { connect } from 'react-redux';

import {bindActionCreators} from "redux";
import authenticationActions from "../actions/authenticationActions";

class ExpiredSessionPopUp extends Component {
  componentWillMount() {
    this.props.authenticationActions.getUserInfo();
  }

  render() {
    return (
      <KpnDialog
        dialogState={this.props.authentication.expiredSession}
        dialogHeader={(
          <DialogHeader
            headerText="Uw sessie is verlopen"
          />
        )}
        dialogBody={
          <DialogBody>
            <div className="input-field col s12">
              Uw sessie is verlopen en wij hebben u uitgelogd. U dient opnieuw in te loggen om verder te gaan.
            </div>
          </DialogBody>
        }
        dialogFooter={
          <DialogFooter buttonRight={(
            <DialogButton
              buttonAction={this.props.authenticationActions.logout}
              buttonText='Opnieuw inloggen'
            />
          )}/>
        }
      />
    );
  }
}

const mapStateToProps = ({authentication}) => {
  return {
    authentication
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpiredSessionPopUp);