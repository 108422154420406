import { actionTypes } from '../constants/actionTypes';

const initialState = {
  presetDiscountsInvalid: false
};

export default (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.getPresetDiscountValidity:
      return { ...state, presetDiscountsInvalid: action.payload };
    default:
      return state;
  }
}
