import styled from "styled-components";

const normalizeRGB = (color) => {
  if (color.hasOwnProperty('r')) {
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
  } else {
    return color;
  }
};


export default styled.div`
  .spinner-layer {
    border-color: ${props => normalizeRGB(props.theme.secondaryColor)};
  }
`