import {
  actionTypes
} from '../constants/actionTypes';

const initialState = {
  orderDetails: {
    id: null,
    nameInitials: null,
    lastName: null,
    firstName: null,
    namePrefix: null,
    gender: null,
    birthDate: null,
    primaryIDNumber: null,
    primaryIDDocument: null,
    primaryIDNationality: null,
    primaryIDValidityDate: null,
    contactPersonNameInitials: null,
    contactPersonLastName: null,
    contactPersonFirstName: null,
    contactPersonNamePrefix: null,
    contactPersonGender: null,
    contactPersonBirthDate: null,
    contactPersonPhoneNumber: null,
    bankAccountNumber: null,
    paymentMethod: null,
    paymentTerm: null,
    customerType: null,
    commercialOffer: null,
    infoUsage: null,
    newsletter: null,
  },
  addressList: [],
  wizardOptions: {
    customerTypeOptions: [],
    identificationOptions: [],
    roleOptions: [],
    genderOptions: [],
    addressRoleOptions: [],
    addressContactOptions: [],
    paymentTermOptions: [],
    paymentMethodOptions: [],
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getOrderWizardDetails:
      return {
        ...state,
        orderDetails: action.payload
      };
    case actionTypes.getOrderWizardDetailOptions:
      return {
        ...state,
        wizardOptions: action.payload
      };
    case actionTypes.updateOrderWizardDetails:
      let {property, value} = action.payload;

      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          [property]: value
        }
      };
    case actionTypes.getOrderAddressList:
      return {
        ...state,
        addressList: action.payload
      };
    case actionTypes.addOrderAddress:
      return {
        ...state,
        addressList: [...state.addressList, action.payload]
      };
    case actionTypes.updateOrderAddress:
      let updatedAddress = action.payload;
      let addressList = [...state.addressList];
      let addressIndex = addressList.findIndex((address) => address.id === updatedAddress.id);
      if (addressIndex !== false) {
        addressList[addressList] = updatedAddress;
      }

      return {
        ...state,
        addressList: addressList
      };
    case actionTypes.removeOrderAddress:
      let filteredAddressList = [...state.addressList].filter((address) => address.id !== action.payload);

      return {
        ...state,
        addressList: filteredAddressList
      };
    default:
      return state;
  }
}
