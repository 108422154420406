import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import locationActions from "../../../actions/locationActions";

import Connection from './connection';
import GeneralDialog from "../../common/GeneralDialog";
import providerType, {odidoProviders} from "../../../constants/providerType";

class LocationForQuotation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      connectionEditDialogOpened: false,
      connectionDeleteDialogOpened: false,
      selectedConnectionId: 0,
      selectedConnection: {
        name: ''
      },
      addConnectionClicked: false,
      newConnectionName: '',
      emptyConnectionName: false
    };
  };

  showEditDialogForConnection = (connection) => {
    this.setState({
      connectionEditDialogOpened: true,
      selectedConnection: connection,
    });
  };

  showDeleteDialogForConnection = (id) => {
    this.setState({
      connectionDeleteDialogOpened: true,
      selectedConnectionId: id
    });
  };

  hideDialog = (visibilityPropertyName) => {
    this.setState({[visibilityPropertyName]: false});
  };

  deleteConnection = () => {
    this.props.onConnectionDelete(this.state.selectedConnectionId);
    this.hideDialog('connectionDeleteDialogOpened');
    this.setState({
      selectedConnectionId: 0
    });
  };

  addConnectionClicked = () => {
    let count = Object.keys(this.props.config.connections).length;
    let connectionName = '';

    if (count === 0) {
      connectionName = 'Primair';
    } else if (count === 1) {
      connectionName = 'Secundair';
    } else if (count === 2) {
      connectionName = 'Tertaire';
    }

    this.setState({addConnectionMode: true, newConnectionName: connectionName});
  };

  onNewConnectionNameChange = ({target}) => {
    let {value} = target;
    this.setState({newConnectionName: value});
  };

  createNewConnection = (e) => {
    e.preventDefault();

    if (this.state.newConnectionName.trim()) {
      this.setState({addConnectionMode: false, emptyConnectionName: false});

      if (this.props.config.fetched) {
        this.props.locationActions.locationIsraCheck(this.props.config)
          .then(() => this.props.onConnectionCreate(this.state.newConnectionName, this.props.locationId));
      } else {
        this.props.onConnectionCreate(this.state.newConnectionName, this.props.locationId);
      }

    } else {
      this.setState({emptyConnectionName: true});
    }
  };

  onSelectedConnectionNameChange = ({target}) => {
    const {value} = target;
    this.setState({
      selectedConnection: {
        ...this.state.selectedConnection,
        name: value
      }
    });
  };

  updateConnection = () => {
    const {selectedConnection} = this.state;
    this.props.onConnectionUpdate(selectedConnection, this.props.locationId);
    this.setState({connectionEditDialogOpened: false});
  };

  hasAvailableConnections = () => {
    const count = Object.keys(this.props.config.connections).length;
    const maxConn = this.props.config.max_connections;
    return count < maxConn;
  };

  render() {
    let {config, inputsDisabled, provider, isLead} = this.props;
    let isTMobile = [providerType.FLASH, ...odidoProviders].includes(provider);

    return (
      <div className="bordered overviewkopbg" style={{paddingRight: "15px", marginBottom: "30px"}}>
        <div className="row">
          <div className="col s12">
            <h1 className={'ratio-section-title'} style={{marginBottom: "10px"}}>
                <span className="ratio-section-title">
                  <i className="small material-icons left ratio-section-title">location_on</i>Locatie:&nbsp;
                </span>
              {config.name}
              <a href="#" onClick={this.props.onLocationEdit}
                 style={inputsDisabled ? {pointerEvents: 'none'} : {cursor: 'pointer'}}>
                <i
                  className={"small material-icons right " + (inputsDisabled ? "grey-text" : "themeprimarycolor")}>edit_location</i>
              </a>
              <a href="#" onClick={this.props.onLocationDelete}
                 style={inputsDisabled ? {pointerEvents: 'none'} : {cursor: 'pointer'}}>
                <i
                  className={"small material-icons right " + (inputsDisabled ? "grey-text" : "themeprimarycolor")}>location_off</i>
              </a>
            </h1>
          </div>
        </div>
        {
          !isLead && config.connections && config.connections.map(connection =>
            <div className="row">
              <Connection
                key={connection.name}
                connectionProperties={connection}
                onEdit={this.showEditDialogForConnection}
                onDelete={this.showDeleteDialogForConnection}
                inputsDisabled={inputsDisabled}
                isTMobile={isTMobile}
              />
            </div>
          )
        }
        {!isLead && (
          <div className="sendendform" style={{padding: '0'}}>
            {(config.id !== -1 && this.hasAvailableConnections() && !isTMobile) || config.fetched ? (
              <div className="row" style={{padding: '0'}}>
                <div className="col s12">
                  <button
                    onClick={this.addConnectionClicked}
                    className="btn doceri-btn-right ratio-btn-white-right left"
                    type="submit"
                    name="action"
                    disabled={inputsDisabled}
                  >
                    <i className="small material-icons left blauw">playlist_add</i> Aansluitpunt toevoegen
                  </button>
                </div>
              </div>
            ) : null}
            {
              this.state.addConnectionMode && (
                <form onSubmit={this.createNewConnection} className="row clearfix"
                      style={{paddingTop: '2rem', marginLeft: '-0.75rem'}}>
                  <fieldset disabled={inputsDisabled} style={{border: 'none', padding: 'unset', margin: 'unset'}}>
                    <div className="col s3">
                      <input
                        type="text"
                        value={this.state.newConnectionName}
                        required
                        onChange={this.onNewConnectionNameChange}
                        className={`validate ${this.state.emptyConnectionName ? "invalid" : ""}`}
                      />
                    </div>
                    <div className="col s3">
                      <button type="submit" className="btn doceri-btn-right ratio-btn-right left">
                        Aansluitpunt maken
                      </button>
                    </div>
                  </fieldset>
                </form>
              )
            }
          </div>
        )}

        <GeneralDialog
          dialogState={this.state.connectionDeleteDialogOpened}
          onRequestClose={() => this.hideDialog('connectionDeleteDialogOpened')}
          modal={true}
          headerIcon="add_location"
          headerText="Aansluitpunt:"
          headerSubtext="verwijderen?"
          dialogBodyContent={''}
          leftButtonLeft={true}
          leftButtonAction={() => this.hideDialog('connectionDeleteDialogOpened')}
          leftButtonText={'Nee'}
          rightButtonText={'Ja'}
          rightButtonAction={this.deleteConnection}
        />

        <GeneralDialog
          dialogState={this.state.connectionEditDialogOpened}
          onRequestClose={() => this.hideDialog('connectionEditDialogOpened')}
          modal={true}
          headerIcon="edit_location"
          headerText="Verbinding bewerken"
          dialogBodyContent={(
            <div className="col s12">
              <input
                type="text"
                value={this.state.selectedConnection.name}
                onChange={this.onSelectedConnectionNameChange}
                disabled={inputsDisabled}
              />
            </div>
          )}
          leftButtonLeft={true}
          leftButtonAction={() => this.hideDialog('connectionEditDialogOpened')}
          leftButtonText={'Annuleren'}
          rightButtonText={'Wijzigen'}
          rightButtonAction={this.updateConnection}
        />
      </div>
    );
  };
}

const mapStateToProps = ({locations}) => {
  return {
    locations
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    locationActions: bindActionCreators(locationActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationForQuotation);
