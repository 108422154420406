import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

import {actionTypes} from '../constants/actionTypes';
import {apiRoutes} from '../constants/apiRoutes';
import {toastr} from 'react-redux-toastr'

const getAllProducts = (organizationID, provider) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.dataManagementRelated.getProducts.replace(/{organizationID}/, organizationID).replace(/{provider}/, provider);
      let response = await ApiService.performRequest(RequestType.GET, url);

      if (response && response.success) {
        dispatch(getProductsSuccess(response['products']));
      }
    } catch (error) {
      toastr.error('Kon de producten niet ophalen. ', error.message);
    }
  }
};

const getProductsSuccess = (products) => {
  return {
    type: actionTypes.getDependencyProducts,
    payload: products
  }
};

const getProductsDependencies = (organizationID, provider,  data) => {
  return async () => {
    try {
      let url = apiRoutes.dataManagementRelated.computeDependencies.replace(/{organizationID}/, organizationID).replace(/{provider}/, provider);
      let response = await ApiService.performRequest(RequestType.POST, url, data);

      if (response && response.success) {
        return response;
      }
    } catch (error) {
      toastr.error('Kon de producten niet ophalen. ', error.message);
    }
  }
};

export default {
  getAllProducts,
  getProductsDependencies,
}
