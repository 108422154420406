import React, {Component} from 'react';
import {connect} from "react-redux";

import {Autocomplete} from 'react-materialize'

import GeneralDialog from "../../common/GeneralDialog";
import FileUpload from '../../../components/fileUpload';
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";

class AddLocationForQuotation extends Component {
  render() {
    let {
      dialogOpened,
      onRequestClose,
      matchingAddresses,
      handleLocationSearchChange,
      selectLocation,
      locationAddress,
      isRefusedPostcode,
      nameError,
      postCodeError,
      save,
      onDrop,
      getExampleFile,
      inputsDisabled
    } = this.props;

    // I think this needs to be moved to reducer
    let dropdownData = {};
    Object.keys(matchingAddresses).forEach(function (key) {
      let obj = matchingAddresses[key];

      let name = `${obj.postalCode} ${obj.houseNumber}${obj.houseNumberExtension}, ${obj.street}, ${obj.city}`;
      dropdownData[name] = obj;
    });

    return (
      <GeneralDialog
        dialogState={dialogOpened}
        headerIcon="add_location"
        headerText="Locatie:"
        headerSubtext="toevoegen"
        closeHandler={onRequestClose}
        dialogBodyContentRaw={(
          <DialogBody>
            <fieldset disabled={inputsDisabled} style={{border: 'none', padding: 'unset', margin: 'unset'}}>
              <div className="input-field col s12">
                <input id="locationname" type="text" ref="locationOptionalName"
                       className={`validate ${nameError ? "invalid" : ""}`}/>
                <label htmlFor="locationname">Locatie naam</label>
              </div>
              {nameError &&
              <div className="col s12 error-small" style={{marginTop: -20}}>Naam is verplicht.</div>
              }

              {/*<div className="input-field col s12">
                <FileUpload onDrop={onDrop} caption="Sleep uw bestand hier of klik voor upload."/>
                <label htmlFor="locationname">Bulk upload</label>
              </div>*/}

              <Autocomplete
                id="postcodehuisnr"
                title='Postcode en huisnr'
                data={dropdownData}
                onChange={e => handleLocationSearchChange(e.target.value)}
                onAutocomplete={(value) => selectLocation(value, dropdownData[value])}
                placeholder="1234DD 32B"
                value={locationAddress}
                className={`validate ${postCodeError ? "invalid" : ""}`}
                s={12}
                style={{marginTop: 20}}
              />
              {isRefusedPostcode &&
              <div className="col s12 error-small" style={{marginTop: -20}}>{this.props.matchingAddresses}</div>
              }
              {postCodeError &&
              <div className="col s12 error-small" style={{marginTop: -20}}>
                De juiste locatie kiezen in de dropdown na het ingeven van de postcode en huisnummer is verplicht.
              </div>
              }
            </fieldset>
          </DialogBody>
        )}
        rightButtonText={'Locatie toevoegen'}
        rightButtonAction={() => save(this.refs.locationOptionalName.value)}
        leftButtonLeft={true}
        leftButtonText={'Download vb Excel bulk-upload'}
        leftButtonAction={() => {
          getExampleFile(this.props.quotation.currentQuotation.isIsdnMigration)
        }}
      />
    );
  }
}

const mapStateToProps = ({quotation}) => {
  return {
    quotation: quotation
  }
};

export default connect(mapStateToProps)(AddLocationForQuotation);
