import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";

import quotationActions from "../../actions/quotationActions";
import discountActions from "../../actions/workflow/discountActions";

import GeneralDialog from "./GeneralDialog";
import DefaultDialogBody from "./KpnDialog/DefaultDialogBody";

class SyncConnectWiseQuotation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showResyncDialog: false
    }
  }

  showResyncDialog = (e) => {
    e.preventDefault();
    this.setState({showResyncDialog: true});
  };

  hideResyncDialog = () => {
    this.setState({showResyncDialog: false})
  };

  resyncQuotationConnectWise = () => {
    this.props.quotationActions.resyncConnectWise(this.props.quotationId)
      .then(() => {
        this.setState({
          showResyncDialog: false
        });
      });
  };

  render() {
    let className = this.props.className;
    if (this.props.renderAsButton) {
      className += ' btn doceri-btn-right ratio-btn-white-right';
    }

    return (
      <Fragment>
        <a
          href="#"
          className={className}
          style={{...this.props.style}}
          onClick={this.showResyncDialog}
        >
          Resync CW
          <i className={`material-icons left ${!this.props.renderAsButton && 'themeprimarycolor'}`}>
            refresh
          </i>
        </a>

        <GeneralDialog
          dialogState={this.state.showResyncDialog}
          closeHandler={this.hideResyncDialog}
          headerText='ConnectWise-offerte opnieuw synchroniseren'
          dialogBodyContent={<DefaultDialogBody text='Als u op Ja klikt, wordt de connectwise opnieuw gesynchroniseerd.'/>}
          leftButtonLeft={true}
          leftButtonAction={this.hideResyncDialog}
          leftButtonText={'Nee'}
          rightButtonAction={this.resyncQuotationConnectWise}
          rightButtonText={'Ja'}
          />
      </Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    quotationActions: bindActionCreators(quotationActions, dispatch),
    discountActions: bindActionCreators(discountActions, dispatch),
  };
};

SyncConnectWiseQuotation.propTypes = {
  quotationId: PropTypes.number.isRequired,
  renderAsButton: PropTypes.bool
};

export default connect(
  null,
  mapDispatchToProps,
)(SyncConnectWiseQuotation);
