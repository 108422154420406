import {
    actionTypes
} from '../constants/actionTypes';

const initialState = {
    orders: [],
    quotationOrderProducts: [],
    pages: 1,
    currentPage: 1,
    resultsCount: 0,
    currentSectionId: null,
    currentQuotationOrder: {}
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.getAdminOrdersData:
            return {
                ...state,
                orders: action.response.orders
            };
        case actionTypes.setCurrentQuotationAdminOrder:
            return {
                ...state,
                currentQuotationOrder: action.payload
            };
        case actionTypes.setCurrentAdminOrderSection:
            return {
                ...state,
                currentSectionId: action.payload
            };
        case actionTypes.savePagination:
            return {
                ...state,
                pages : action.payload.pages,
                currentPage: action.payload.currentPage,
                resultsCount: action.payload.resultsCount
            };
        case actionTypes.getQuotationAdminOrderProductsSuccess:
            return {
                ...state,
                quotationOrderProducts: action.payload
            };
        case actionTypes.setQuotationAdminStatus:
            let orderList = [...state.orders];
            let orderIndex = orderList.findIndex((order) => order.quotationNumber === action.payload.quotation);

            if (orderIndex >= 0) {
                orderList[orderIndex]['status'] = action.payload.status;
            }

            return {
                ...state,
                orders: orderList
            };
        case actionTypes.setProductOrderAdminStatus:
            let quotationOrderProductsList = [...(state.quotationOrderProducts?.orderSentProducts || [])];
            let quotationOrderProductsIndex = quotationOrderProductsList.findIndex((product) => product.id === action.payload.product);
            if (quotationOrderProductsIndex >= 0) {
                quotationOrderProductsList[quotationOrderProductsIndex]['status'] = action.payload.statusName;
            }

            return {
                ...state,
                quotationOrderProducts: quotationOrderProductsList
            };
        default:
            return state;
    }
}
