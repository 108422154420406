/* global _ */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toastr} from 'react-redux-toastr';
import stepActions from '../actions/stepsActions';
import locationActions from '../actions/locationActions';
import quotationActions from '../actions/quotationActions';

import InfoStep from '../steps/workflow/infoStep';
import AccessStep from '../steps/workflow/access/accessStep';
import DiscountStep from '../steps/workflow/discount/discountStep';
import OverviewStep from '../steps/workflow/overview/overviewStep';
import HostedVoiceStep from '../steps/workflow/hosted-voice/HostedVoiceStep';
import MobileStep from '../steps/workflow/mobile/MobileStep';
import PartnerServicesStep from '../steps/workflow/partner-services/PartnerServicesStep';
import ITServicesStep from '../steps/workflow/it-services/ITServicesStep';
import OrderingStep from '../steps/workflow/ordering/orderingStep';
import TelephonyStep from "../steps/workflow/telephony/TelephonyStep";
import CommunicationStep from "../steps/workflow/communication/CommunicationStep";
import LeadProductsStep from "../steps/workflow/lead-products/leadProductsStep";
import ApplicationsStep from "../steps/workflow/applications/applicationsStep";
import TransportStep from "../steps/workflow/transport/transportStep";
import InternetStep from "../steps/workflow/internet/internetStep";
import InterfaceStep from "../steps/workflow/interface/interfaceStep";
import ServicesStep from "../steps/workflow/services/servicesStep";
import OrderWizardStep from "../steps/workflow/order-wizard/orderWizardStep";

import LocationsForQuotation from './locations/locationManagement/locationsForQuotation';
import EditLocationForQuotation from './locations/locationManagement/editLocationForQuotation';
import ProviderHelper from "../helper/providerHelper";

import statuses from "../constants/quotationStatus";

class Content extends Component {
  constructor(props) {
    super(props);
    this.getLocationsForAutocomplete = _.throttle(
      this.getLocationsForAutocomplete.bind(this),
      1000,
    );
  }

  componentWillMount() {
    this.props.stepActions.setCurrentStep(1);
  }

  getContentForStep = () => {
    switch (this.props.steps.currentStepId) {
      case 1:
        return <InfoStep/>;
      case 2:
        return <LocationsForQuotation/>;
      case 3:
        return <AccessStep/>;
      case 5:
        return <DiscountStep/>;
      case 6:
        return <OverviewStep/>;
      case 8:
        return <HostedVoiceStep/>;
      case 10:
        return <MobileStep/>;
      case 13:
        return <PartnerServicesStep/>;
      case 14:
        return <ITServicesStep/>;
      case 15:
        return <OrderingStep/>;
      case 16:
        return <TelephonyStep/>;
      case 17:
        return <CommunicationStep/>;
      case 18:
        return <LeadProductsStep/>;
      case 19:
        return <ApplicationsStep/>;
      case 20:
        return <TransportStep/>;
      case 21:
        return <InternetStep/>;
      case 22:
        return <InterfaceStep/>;
      case 23:
        return <ServicesStep/>;
      case 24:
        return <OrderWizardStep/>;
      default:
        return null;
    }
  };

  handleLocationSearchChange = (locationAddress) => {
    this.props.locationActions.setSelectedLocationAddress(locationAddress);

    this.getLocationsForAutocomplete(locationAddress);
  };

  getLocationsForAutocomplete = (locationAddress) => {
    this.props.locationActions.getLocationAddressForAutocomplete(
      locationAddress,
    );
  };

  goToPreviousStep = (step) => {
    this.props.stepActions.setCurrentStep(step.id);
    this.props.stepActions.setAvailableToGoForward(true);
  };

  goToNextStep = (step) => {
    if (this.props.steps.availableToGoForward === false) {
      toastr.error(this.props.steps.availabilityMessage);
    } else if (step) {
      this.props.stepActions.setCurrentStep(step.id);
    }
  };

  goToOrderingStep = (step) => {
    const self = this;

    this.props.quotationActions.startOrderingQuotation(this.props.quotation.currentQuotation.id).then(() => {
      if (this.props.quotation.currentQuotation.ordering) {
        self.goToNextStep(step);
      }
    });
  };

  isAllowedToOrder = () => {
    return this.props.authentication.roles.includes('ROLE_SALES_SUPPORT') ||
        this.props.authentication.roles.includes('ROLE_SALES') || this.props.authentication.roles.includes('ROLE_ADMIN');
  };

  getAvailableSteps = (quotationTypeEnum = 0) =>
    this.props.steps.availableSteps.filter(
      (current) => {
        if(current.hasOwnProperty('availableForOrdering')) {
          const {ordering} = this.props.authentication;
          return ordering;
        } else if (current.hasOwnProperty('availableForRoles')) {
          const authRoles = this.props.authentication.roles;
          return current.availableForRoles.some(role => authRoles.includes(role)) && current.availableFor.includes(quotationTypeEnum);
        } else {
          return current.availableFor.includes(quotationTypeEnum);
        }
      }
    );

  renderNextStep = (nextStep) => {
    if (!nextStep) {
      return;
    }

    const {
      quotation: {currentQuotation, isSyncOpportunityEmail},
      steps: {availableToGoForward},
    } = this.props;

    const {inputsDisabled, status} = currentQuotation;
    const notAllowed = availableToGoForward === false;
    const hasStatusSent = [statuses.STATUS_SEND.value, statuses.STATUS_WON.value].includes(status);

    const disabledState = notAllowed || (inputsDisabled && !hasStatusSent)

    if (nextStep.hasOwnProperty('availableForOrdering') && nextStep.availableForOrdering) {
      if (ProviderHelper.hasModuleEnabled(currentQuotation, 'orderingEnabled') && this.isAllowedToOrder()) {
        return (
            <button
                className={'btn doceri-btn-right ' + (notAllowed ? 'disabled' : '')}
                onClick={() => this.goToOrderingStep(nextStep)}
                style={{float: 'right'}}
                name="forward"
                disabled={disabledState}
            >
              Start het order proces
            </button>
        );
      }
      return;
    }

    return(
      <button
        className={'btn doceri-btn-right ' + (availableToGoForward === false || !isSyncOpportunityEmail ? 'disabled' : '')}
        onClick={() => this.goToNextStep(nextStep)}
        style={{float: 'right'}}
        name="forward"
        disabled={inputsDisabled}
      >
        { 'Ga door naar ' + nextStep.displayName }
      </button>
    );
  };

  getAvailableStepsForProvider = (availableSteps) => {
    let provider = this.props.steps.provider ? parseInt(this.props.steps.provider) : this.props.quotation.currentQuotation.provider;
    let offerType = this.props.quotation.isLead ? 'lead' : 'standard';
    let steps = availableSteps.filter(
      (step) => {
        return step.availableForProvider.includes(provider) && step.availableForType.includes(offerType);
      }
    );

    return steps.length > 0 ? steps : availableSteps;
  };

  render() {
    const {
      quotation: {
        currentQuotation: {type},
      },
      steps: {currentStepId},
    } = this.props;
    const {isLead} = this.props.quotation;

    const availableSteps = this.getAvailableStepsForProvider(this.getAvailableSteps(type));
    const stepSettings = availableSteps.find((x) => x.id === currentStepId);
    const currentStepIndex = availableSteps.findIndex((x) => x.id === currentStepId);
    const previousStep = currentStepIndex > 0 ? availableSteps[currentStepIndex - 1] : null;
    const nextStep = currentStepIndex < availableSteps.length ? availableSteps[currentStepIndex + 1] : null;

    return (
      <div className={
        'col s12 m12 ' +
        (stepSettings.displayLocations ? 'l9' : 'l12')
      }>
        {/* condition due to fact, that in Discount and Total steps there should not be location list and content should be full page width */}

        {this.getContentForStep()}

        <div className={'row'}>
          <div
            className={
              'col ' + stepSettings.displayLocations ? 'l12' : 'l9'
            }
            style={{clear: 'both'}}
          >
            {previousStep ? (
              <button
                className="btn doceri-btn-right ratio-btn-right"
                onClick={() => this.goToPreviousStep(previousStep)}
                style={{float: 'left'}}
                name="back"
              >
                {'Ga terug naar ' + previousStep.displayName}
              </button>
            ) : null}
            {this.renderNextStep(nextStep)}
          </div>
        </div>

        <EditLocationForQuotation
          handleLocationSearchChange={this.handleLocationSearchChange}
          isLead={isLead}
        />
      </div>
    );
  }
}

const mapStateToProps = ({steps, locations, quotation, authentication}) => {
  return {
    steps,
    locations,
    quotation,
    authentication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    stepActions: bindActionCreators(stepActions, dispatch),
    locationActions: bindActionCreators(locationActions, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Content);
