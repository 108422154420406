import {actionTypes} from '../constants/actionTypes';

const initialState = {
    profileOptions: [],
    profileCategories: [],
    functionalitiesList: [],
    telephoneNumberOptions: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.getUccOptions:
            const payload = action.payload;
            return {
                ...state,
                profileOptions: payload.profileOptions,
                profileCategories: payload.profileCategories,
                functionalitiesList: payload.functionalitiesList,
                telephoneNumberOptions: payload.telephoneNumberOptions,
            };
        default:
            return state;
    }
}
