import React, { Component } from 'react';
import KpnDialog from "../../../containers/common/KpnDialog/KpnDialog";
import DialogHeader from "../../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";
import {Row, Col} from "react-materialize";
import FileUpload from "../../../components/fileUpload";

class DownloadForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            downloadFile: {
                id: this.props.document.id || null,
                name: this.props.document.name,
                category: this.props.document.category,
                file: '',
            }
        }
    }

    onDrop = (files) => {
        this.setState(prevState => ({
            downloadFile: {
                ...prevState.downloadFile,
                file: files[0]
            }
        }));
    };

    onSubmit = (e) => {
        e.preventDefault();

        this.props.onSubmit(this.state.downloadFile);
    };

    render() {
        const { downloadFile } = this.state;
        return (
            <div id="Forms">
                <KpnDialog
                    dialogState={true}
                    modal={true}
                    onRequestClose={this.props.onClose}
                    dialogHeader={(
                        <DialogHeader
                            headerIcon="person"
                            headerText="Upload new file"
                            closeHandler={this.props.onClose}
                        />
                    )}
                    dialogBody={
                        <DialogBody>
                            <form onSubmit={this.onSubmit}>
                                <Row>
                                    <Col s={12} className="input-field">
                                        <FileUpload
                                            onDrop={this.onDrop}
                                            caption="Sleep uw bestand hier of klik voor upload. Let op, dit overschrijft het oude bestand"
                                        />

                                        {downloadFile.file && <div>Geselecteerd bestand: {downloadFile.file.name}</div>}
                                    </Col>
                                </Row>

                                <button
                                    className='btn doceri-btn-right ratio-btn-right-fullwidth right'
                                    type="submit"
                                    name="action"
                                >
                                    Submit
                                </button>
                            </form>
                        </DialogBody>
                    }
                />
            </div>
        )
    }
}

export default DownloadForm;
