import React, {Component} from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from "material-ui";
import {DelayInput} from "react-delay-input";
import {toastr} from "react-redux-toastr";
import {connect} from "react-redux";
import RatioOverflowTableWrapper from "../../../components/styled-components/ratio-overflow-table-wrapper/RatioOverflowTableWrapper";

class DiscountProduct extends Component {

  constructor(props) {
    super(props);

    this.state = {
      monthlyMaxDiscount: 0,
      oneOffMaxDiscount: 0,
      discount: props.quotation.currentQuotation.customDiscount || false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const monthlyMaxDiscount = (parseInt(this.props.allowedDiscount, 10) / 100) * parseInt(this.props.productsTotals.monthlyCosts, 10);
    const oneOffMaxDiscount = (parseInt(this.props.allowedDiscount, 10) / 100) * parseInt(this.props.productsTotals.oneOff, 10);

    if (monthlyMaxDiscount !== this.state.monthlyMaxDiscount) {
      this.setState({
        monthlyMaxDiscount,
      });
    }

    if (oneOffMaxDiscount !== this.state.oneOffMaxDiscount) {
      this.setState({
        oneOffMaxDiscount,
      });
    }
  }

  generateHeaders = () => {
    return (
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow>
          <TableHeaderColumn style={{width: '40%'}}>
            <div style={{whiteSpace: 'break-word', fontWeight: 'bold'}}>Productomschrijving</div>
            <div className="subthread" style={{display: 'inline-block'}}/>
          </TableHeaderColumn>
          <TableHeaderColumn>
            <div style={{fontWeight: 'bold'}}>Aantal</div>
            <div className="subthread" style={{display: 'inline-block'}}/>
          </TableHeaderColumn>
          <TableHeaderColumn style={{position: 'unset'}}>
            <span style={{fontWeight: 'bold'}}>Totale prijs</span>
            <div className="subthread">maandelijks</div>
          </TableHeaderColumn>
          <TableHeaderColumn style={{position: 'unset'}}>
            <span style={{fontWeight: 'bold'}}>Totale prijs</span>
            <div className="subthread">eenmalig</div>
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>
    )
  };

  onChange = (event) => {
    let {name, value} = event.target;

    if (['priceOneOff', 'pricePerMonth'].includes(name)) {
      if (value < 0) {
        toastr.error('Validation error', 'Discount value cannot be lower than 0.');
        return;
      }

      if (! value) {
        value = 0;
      }
    }

    if (name === 'priceOneOff') {
      if (parseInt(value, 10) > this.state.oneOffMaxDiscount) {
        toastr.error('Validation error', 'Kortingsmandaat overschreden');
        return;
      }
    }

    if (name === 'pricePerMonth') {
      if (parseInt(value, 10) > this.state.monthlyMaxDiscount) {
        toastr.error('Validation error', 'Kortingsmandaat overschreden');
        return;
      }
    }

    this.props.onChangeDiscount(name, value);
  };

  onChangeCheckbox = () => {
    const discount = !this.state.discount;
    this.setState({discount});

    if (discount) {
      this.props.onChangeDiscount('name', 'KNVB actie - Een jaar gratis connectiviteit (maximum van € 800)');
    } else {
      this.props.onChangeDiscount('name', 'Delete');
    }
  }

  render() {
    const {authentication: {isSalesValue}, quotation: {currentQuotation, isLead}, inputsDisabled} = this.props;
    const connectionDiscount = isSalesValue && currentQuotation.contractDuration >= 36;

    let connectionSelected = false;
    if (!isLead) {
      currentQuotation.locations.forEach((location) => {
        location.connections.forEach((connection) => {
          if (connection.selected_connections.length) {
            connectionSelected = true;
          }
        })
      });
    }

    return (
        <>
          <div className="row">
            <div className="col s12 overviewkopbg flex">
              <div className="overviewkop s8">Factuur Kortingen</div>
              {
                  connectionDiscount && connectionSelected &&
                  <div className="s4 alignright additional-discount">
                    <input
                        type="checkbox"
                        className="filled-in"
                        id="filled-in-box"
                        checked={this.state.discount}
                        onChange={this.onChangeCheckbox}
                        disabled={inputsDisabled}
                    />
                    <label htmlFor="filled-in-box">KNVB actie - Een jaar gratis connectiviteit (maximum van
                      €800)</label>
                  </div>
              }
            </div>
            <RatioOverflowTableWrapper>
              <Table className="highlight without-margin-bottom"
                     selectable={false}
                     displayRowCheckbox={false}>
                {this.generateHeaders()}

                <TableBody displayRowCheckbox={false}>
                  <TableRow key="1">
                    <TableRowColumn style={{width: '40%', whiteSpace: 'normal', wordWrap: 'break-word'}}>
                      <div className="input-field inner-addon">
                        <DelayInput
                            type="text"
                            name="name"
                            delayTimeout={1000}
                            className="without-margin-bottom"
                            onChange={this.onChange}
                            disabled={inputsDisabled}
                            value={this.props.productData.discountProduct.name}
                        />
                      </div>
                    </TableRowColumn>
                    <TableRowColumn>1</TableRowColumn>
                    <TableRowColumn style={{textAlign: 'right'}}>
                      <div className="input-field inner-addon">
                        <i className="glyphicon material-icons left lightgrey"
                           style={{fontSize: "1.35em"}}>euro_symbol</i>
                        <DelayInput
                            type="text"
                            name="pricePerMonth"
                            min={0}
                            delayTimeout={1000}
                            className="without-margin-bottom"
                            onChange={this.onChange}
                            disabled={inputsDisabled}
                            value={this.props.productData.discountProduct.pricePerMonth}
                        />
                      </div>
                    </TableRowColumn>
                    <TableRowColumn style={{textAlign: 'right'}}>
                      <div className="input-field inner-addon">
                        <i className="glyphicon material-icons left lightgrey"
                           style={{fontSize: "1.35em"}}>euro_symbol</i>
                        <DelayInput
                            type="text"
                            name="priceOneOff"
                            min={0}
                            delayTimeout={1000}
                            className="without-margin-bottom"
                            onChange={this.onChange}
                            disabled={inputsDisabled}
                            value={this.props.productData.discountProduct.priceOneOff}
                        />
                      </div>
                    </TableRowColumn>
                  </TableRow>
                </TableBody>
              </Table>

          <div className="line30" style={{marginBottom: '10px'}}/>
            </RatioOverflowTableWrapper>
          </div>
        </>
    )
  }
}

const mapStateToProps = ({authentication, quotation}) => ({authentication, quotation});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DiscountProduct);
