import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toastr} from 'react-redux-toastr'

import stepsActions from '../actions/stepsActions';
import locationActions from '../actions/locationActions';
import quotationActions from "../actions/quotationActions";

import Step from './step';
import ProviderHelper from "../helper/providerHelper";
import Organization from "../constants/organization";
import quotationType from "../constants/quotationType";
import providerType from "../constants/providerType";

class Steps extends Component {

  componentDidUpdate(prevProps) {
    let prevQuotationData = prevProps.quotation.currentQuotation.pendingDataRetrieval;
    let currentQuotationData = this.props.quotation.currentQuotation.pendingDataRetrieval;

    if (prevQuotationData !== currentQuotationData && !currentQuotationData) {
      this.props.quotationActions.resetSoftAlerts();
    }
  }

  setCurrentStep = (stepId) => {
    const {availableSteps, currentStepId, availableToGoForward, availabilityMessage} = this.props.steps;

    if (currentStepId === stepId) {
      return;
    }

    const movingForward = availableSteps.findIndex(x => x.id === stepId) > availableSteps.findIndex(x => x.id === currentStepId);

    if (movingForward && !availableToGoForward) {
      toastr.error(availabilityMessage);
      return;
    }

    if (!this.props.quotation.isSyncOpportunityEmail) {
      toastr.error('U bent niet de gebruiker die de opportunity in SalesForce heeft aangemaakt en kunt daardoor deze offerte niet bewerken.');
      return;
    }

    this.props.locationActions.resetSelectedLocationToGlobal();
    if (!movingForward) {
      this.props.stepsActions.setAvailableToGoForward(true);
    }

    this.props.stepsActions.setCurrentStep(stepId);
  };

  isAllowedToOrder = () => {
    return this.props.authentication.roles.includes('ROLE_SALES_SUPPORT')
      || this.props.authentication.roles.includes('ROLE_ADMIN');
  };

  getAvailableSteps = (quotationTypeEnum = 0) => {
    const {quotation, authentication} = this.props;

    const {roles} = authentication;
    const organizationOrdering = authentication.ordering;
    const {currentQuotation} = quotation;
    const {ordering} = currentQuotation;
    let {availableSteps} = this.props.steps;

    return availableSteps.filter(
      (current) => {
        if(current.hasOwnProperty('availableForOrdering')) {
          if (ProviderHelper.hasModuleEnabled(currentQuotation, 'orderingEnabled')) {
            return ordering && organizationOrdering && this.isAllowedToOrder();
          }

          return false;
        } else if (current.hasOwnProperty('availableForRoles')) {
          return current.availableForRoles.some(role => roles.includes(role)) && current.availableFor.includes(quotationTypeEnum);
        } else {
          return current.availableFor.includes(quotationTypeEnum);
        }
      }
    );
  };

  getAvailableStepsForProvider = (availableSteps) => {
    const {quotation, authentication} = this.props;
    const stepsProvider = this.props.steps.provider;
    const {currentQuotation, isLead} = quotation;

    const {isWebLead, type, accessPortfolio} = currentQuotation;
    const {allowWebLead, isSalesValue} = authentication;

    let provider = stepsProvider ? parseInt(stepsProvider) : currentQuotation.provider;
    let offerType = isLead ? 'lead' : 'standard';
    let availSteps = availableSteps;

    if (provider === providerType.ODIDO && type === quotationType.TYPE_QA) {
      availSteps = availSteps.filter(step => !['Access', 'Mobiel'].includes(step.displayName));
    }

    if (provider === providerType.ODIDO && accessPortfolio === 'no-access') {
      availSteps = availSteps.filter(step => !'Access'.includes(step.displayName));
    }

    if (isSalesValue) {
      availSteps = availSteps.filter(step => !'Partner Diensten'.includes(step.displayName));
    }

    let steps = availSteps.filter(
      (step) => {
        return step.availableForProvider.includes(provider) && step.availableForType.includes(offerType);
      }
    );

    if (allowWebLead && isWebLead) {
      steps = steps.filter((step) => step.availableForWebLead !== false)
    }

    return steps.length > 0 ? steps : availableSteps;
  };

  render() {
    const {authentication, quotation} = this.props;
    const {company} = authentication;
    const {currentQuotation} = quotation;
    const currentStep = this.props.steps.currentStepId;

    let {type, initialized} = currentQuotation;
    const isVerlengenType = type === quotationType.TYPE_VERLENGEN;
    let availableSteps = this.getAvailableStepsForProvider(this.getAvailableSteps(type));

    if (company === Organization.PIT) {
      initialized = true
    }

    return (
      <div id="Steps">
        {
          availableSteps.map(step => (
            <Step
              config={step}
              key={step.id}
              currentStep={currentStep}
              disabled={!initialized && !isVerlengenType}
              onClick={() => this.setCurrentStep(step.id)}
            />
          ))
        }
      </div>
    );
  }
}

const mapStateToProps = ({steps, quotation, authentication}) => {
  return {
    steps,
    quotation,
    authentication
  }
};

const mapDispatchToProps = dispatch => {
  return {
    stepsActions: bindActionCreators(stepsActions, dispatch),
    locationActions: bindActionCreators(locationActions, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
