import {apiRoutes} from "../constants/apiRoutes";
import ApiService from "../services/apiService";
import RequestType from "../constants/requestType";
import {toastr} from "react-redux-toastr";

import {
  actionTypes
} from '../constants/actionTypes';

const getApplicationData = (quotationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationRelated.getApplicationData
        .replace(/{quotation}/, quotationId);

      const response = await ApiService.performRequest(RequestType.GET, url);
      dispatch(getApplicationDataSuccess(response));
    } catch ({message}) {
      toastr.error('Something went wrong.', message);
    }
  }
}

const getApplicationDataSuccess = (response) => {
  return {
    type: actionTypes.getApplicationData,
    payload: response
  }
}

const setDesiredApplicationSystem = (quotationId, applicationType) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationRelated.setDesiredApplicationSystem
        .replace(/{quotation}/, quotationId);

      const response = await ApiService.performRequest(RequestType.POST, url, {
        "type": applicationType
      });

      dispatch(setDesiredApplicationSystemDataSuccess(response));
    } catch ({message}) {
      toastr.error("Het is niet mogelijk een aansluitpunt toe te voegen", message);
    }
  }
}

const setDesiredApplicationSystemDataSuccess = ({message}) => {
  return {
    type: actionTypes.setDesiredApplicationSystem,
    payload: message
  }
}

const setFlashRequiredWindowsApplicationProducts = (quotationId, windowsApplicationType) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationRelated.setFlashRequiredWindowsApplicationProducts
        .replace(/{quotation}/, quotationId);

      const response = await ApiService.performRequest(RequestType.POST, url, {
        "windowsApplicationType": windowsApplicationType,
        "type": "Windows"
      })

      dispatch(setFlashRequiredWindowsApplicationProductsDataSuccess(response));

    } catch (message) {
      toastr.error("Het is niet mogelijk een aansluitpunt toe te voegen", message);
    }
  }
}

const setFlashRequiredWindowsApplicationProductsDataSuccess = ({message}) => {
  return {
    type: actionTypes.setFlashDefaultProducts,
    payload: message
  }
}

const addFlashProductQuantity = (quotationId, selections) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationRelated.addFlashProductQuantity
        .replace(/{quotation}/, quotationId);

      const response = await ApiService.performRequest(RequestType.POST, url, {
        'flashSelections': selections
      })

      dispatch(addFlashProductQuantityDataSuccess(response));
    } catch (message) {
      toastr.error("Het is niet mogelijk een aansluitpunt toe te voegen", message);
    }
  }
}

const addFlashProductQuantityDataSuccess = ({message}) => {
  return {
    type: actionTypes.addFlashProductQuantity,
    payload: message
  }
}

const updateHardwareProducts = (quotationId, products) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationRelated.updateHardwareProducts
        .replace(/{quotation}/, quotationId);

      const response = await ApiService.performRequest(RequestType.POST, url, products)

      dispatch(updateHardwareProductsSuccess(response));
    } catch (message) {
      toastr.error("Het is niet mogelijk een aansluitpunt toe te voegen", message);
    }
  }
}

const updateHardwareProductsSuccess = ({message}) => {
  return {
    type: actionTypes.updateHardwareProducts,
    payload: message
  }
}

export default {
  getApplicationData,
  setDesiredApplicationSystem,
  setFlashRequiredWindowsApplicationProducts,
  addFlashProductQuantity,
  updateHardwareProducts
}