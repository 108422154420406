import RequestType from "../../constants/requestType";
import ApiService from "../../services/apiService";

import {apiRoutes} from '../../constants/apiRoutes';
import {actionTypes} from '../../constants/actionTypes';
import {toastr} from 'react-redux-toastr'
import store from '../../store';
import quotationActions from '../quotationActions';
import locationActions from '../locationActions';
import hardwareActions from '../hardwareActions';

import FormBuilderService from "../../services/formBuilderService";
import {getLocationOptionsThunk} from "./isdnMigrationActions/getLocationOptionsThunk";
import _ from "lodash";

const getAccessStepState = (quotationId) => {
    return async (dispatch) => {
        try
        {
            let url = apiRoutes.accessRelated.get + `${quotationId}`;
            let response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(_getAccessStepStateSuccess(response));
        }
        catch ({message})
        {
            toastr.error('Het is niet mogelijk op de huidige stap op te halen', '');
        }
    }
};

const _getAccessStepStateSuccess = (accessState) => {
    return {
        type: actionTypes.getAccessState,
        payload: accessState
    };
};

const updateAccessGlobalSettings = (name, value, quotationId) => {
    return async (dispatch) => {
        try
        {
            dispatch(_updateAccessGlobalSettingsSuccess(name, value));
            if(quotationId){
                dispatch(_updateAccess(quotationId));
            }
        }
        catch ({message})
        {
            toastr.error('Het is niet mogelijk de stappen te wijzigen', message);
        }
    }
};

const _updateAccessGlobalSettingsSuccess = (name, value) => {
    return {
        type: actionTypes.updateAccessGlobalSettings,
        payload: {
            name,
            value
        }
    }
};

const updateConnection = (name, value, connectionId, locationId, quotationId) => {
    return async (dispatch) => {
        try
        {
            dispatch(updateConnectionData(name, value, connectionId, locationId));
            dispatch(locationActions.setQuotationLocations(quotationId));
            await dispatch(_updateAccess(quotationId, locationId));
        }
        catch ({message})
        {
            toastr.error('Het is niet mogelijk de stappen te wijzigen', message);
        }
    }
};

const updateConnectionData = (name, value, connectionId, locationId) => {
    return {
        type: actionTypes.updateAccessLocationConnection,
        payload: {
            name,
            value,
            connectionId,
            locationId
        }
    }
};

const getConnectionAllowed = (connectionId, productId) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.connectionsRelated.connectionAllowed.replace(/{connectionId}/, connectionId ).replace(/{productId}/, productId);
            let requestData = await ApiService.performRequest(RequestType.GET, url);

            dispatch(_getConnectionAllowed(requestData));
        }
        catch ({message}) {
            toastr.error('Er ging iets mis', message);
        }
    }
};

const _getConnectionAllowed = (data) => {
    // Append timestamp to be able to differentiate requests
    data.execId = new Date().getTime();

    return {
        type: actionTypes.connectionAllowed,
        payload: {
            data
        }
    }
};

const checkConnectionTypeRequired = (connectionId, productId) => {
    return async () => {
        try {
            const url = apiRoutes.connectionsRelated.checkConnectionTypeRequired.replace(/{connectionId}/, connectionId ).replace(/{productId}/, productId);
            return await ApiService.performRequest(RequestType.GET, url);
        }
        catch ({message}) {
            toastr.error('Er ging iets mis', message);
        }
    }
};

const checkUpgradeTypeRequired = (data) => {
    return async () => {
        try {
            const url = apiRoutes.connectionsRelated.checkUpgradeTypeRequired;
            return await ApiService.performRequest(RequestType.POST, url, data);
        }
        catch ({message}) {
            toastr.error('Er ging iets mis', message);
        }
    }
};

const updateLocation = (name, value, locationId, quotationId) => {
    return async (dispatch) => {
        try
        {
            dispatch(_updateLocationSuccess(name, value, locationId));
            dispatch(_updateAccess(quotationId, locationId));
        }
        catch ({message})
        {
          toastr.error('Het is niet mogelijk de stappen te wijzigen', message);
        }
    }
};

const _updateLocationSuccess = (name, value, locationId) => {
    return {
        type: actionTypes.updateAccessLocation,
        payload: {
            name,
            value,
            locationId
        }
    }
};

const getOptions = (quotationId) => {
    return async (dispatch) => {
        try
        {
            let availableExtraOptions = store.getState().locations.selectedLocation.allowed_hip;
            availableExtraOptions = availableExtraOptions ? 1 : 0;

            let url = apiRoutes.accessRelated.getOptions + `${quotationId}/` + `${availableExtraOptions}`;
            let response = await ApiService.performRequest(RequestType.GET, url);

            let globalProperties = response.find(x => x.locationID === 'global').form.properties;
            let globalFormOptions = FormBuilderService.prepareFormValuesAndCaptions(globalProperties);

            dispatch(_getGlobalOptionsSuccess(globalFormOptions));

            let locationsProperties = response.filter(x => x.locationID !== 'global');
            let locationPropertiesOptions = [];

            for(let locationProperties of locationsProperties) {
                for(let connectionProperties of locationProperties.connections) {
                    const formOptions = FormBuilderService.prepareFormValuesAndCaptions(connectionProperties.form.properties);
                    locationPropertiesOptions.push({
                        locationId: locationProperties.locationID,
                        connectionId: connectionProperties.connectionID,
                        allowedVLAN: connectionProperties.allowedVLAN,
                        hardwareOptions: connectionProperties.hardwareOptions,
                        formOptions
                    });
                }
            }

            dispatch(hardwareActions.getCustomHardwareOptions('access', quotationId));

            dispatch(_getLocationsOptionsSuccess(locationPropertiesOptions));
        }
        catch ({message})
        {
            toastr.error('Het is niet mogelijk de opties voor de stappen op te halen', '');
        }
    }
};

const _getGlobalOptionsSuccess = (options) => {
    return {
        type: actionTypes.getGlobalAccessOptions,
        payload: options
    };
};

const _getLocationsOptionsSuccess = (options) => {
    return {
        type: actionTypes.getLocationsAccessOptions,
        payload: options
    };
};

const _updateAccess = (quotationId, locationId = null) => {
    return async(dispatch) => {
        try {
          let {accessState} = store.getState().access;
          let url = apiRoutes.accessRelated.get + `${quotationId}`;
          let response = await ApiService.performRequest(RequestType.POST, url, accessState);

          dispatch(quotationActions.getQuotationSuccess(response));
          dispatch(getOptions(quotationId));

          if (locationId) {
            dispatch(getLocationOptionsThunk(locationId));
          }
        }
        catch ({message})
        {
          toastr.error('Het is niet mogelijk de instellingen voor access te wijzigen', message);
        }
    }
};

const getOrganizationHipAllow = (quotationId) => {
    return async(dispatch) => {
        try {
            let url = apiRoutes.accessRelated.getOrganizationHip.replace(`{quotationId}`, quotationId);
            let response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(getOrganizationHipSuccess(response));
        }
        catch ({message})
        {
            toastr.error('HIP konden niet worden opgehaald.');
        }
    }
};

const getOrganizationHipSuccess = (response) => {
    return {
        type: actionTypes.getOrganizationHip,
        payload: response
    }
};

const setExtraOptionsAvailability = (quotationId, availability, locationId) => {
    return async(dispatch) => {
        try {
            let hip = availability === true ? 1 : 0;
            let url = apiRoutes.accessRelated.setLocationHip.replace(`{quotationId}`, quotationId)
                .replace(`{locationId}`, locationId)
                .replace(`{availability}`, hip);
            let response = await ApiService.performRequest(RequestType.PATCH, url);
        }
        catch ({message})
        {
            toastr.error('Extra opties konden niet worden opgehaald.');
        }
    }
};

const retrieveExistingAccessData = (quotationId) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.accessRelated.retrieveExistingAccessData.replace(/{quotationId}/, quotationId);
            const response = await ApiService.performRequest(RequestType.GET, url);

            if (response) {
                dispatch(retrieveExistingAccessDataSuccess(response));
            }
        } catch (message) {
            toastr.error('Kan bestaande gegevens niet ophalen omdat ', message);
        }
    }
};

const retrieveExistingAccessDataSuccess = (result) => {
    return {
        type: actionTypes.getExistingAccessData,
        payload: result
    }
};

const updatePremiumWifiSelections = (quotationId, selections) => {
    return async () => {
        try {
            let url = apiRoutes.accessRelated.updatePremiumWifiSelections.replace(/{quotationId}/, quotationId);
            const responseData = await ApiService.performRequest(RequestType.PATCH, url, selections);
        } catch ({message}) {
            toastr.error('Het is niet mogelijk een premium wifi toe te voegen', message);
        }
    }
};

const updateFortiGateSelections = (quotationId, selections) => {
    return async () => {
        try {
            let url = apiRoutes.accessRelated.updateFortiGateSelections.replace(/{quotationId}/, quotationId);
            const responseData = await ApiService.performRequest(RequestType.PATCH, url, selections);
        } catch ({message}) {
            toastr.error('Het is niet mogelijk een fortigate toe te voegen', message);
        }
    }
};

const updateConnectionType = (data, connectionId) => {
    return async () => {
        try {
            let url = apiRoutes.connectionsRelated.updateConnectionType.replace(/{connection}/, connectionId);
            await ApiService.performRequest(RequestType.PATCH, url, data);
        } catch ({message}) {
            toastr.error('Kon het verbindingstype niet opslaan.', message);
        }
    }
};

const changeAccessType = (locationId, value) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.accessRelated.changeAccessType.replace(/{locationId}/, locationId);
            let response = await ApiService.performRequest(RequestType.PATCH, url, {value: value});

            if (response.success) {
                dispatch(updateSdWanLocationOptionsSuccess({property: 'sdWanActive', value: value}));
                dispatch(updateSdWanActiveSuccess(value));
            }
        } catch ({message}) {
            toastr.error('Het is niet mogelijk een toegangstype toe te voegen', message);
        }
    }
};

const getSdWanProducts = (locationId) => {
    return async () => {
        try {
            let url = apiRoutes.accessRelated.getSdWanProducts.replace(/{locationId}/, locationId);
            return await ApiService.performRequest(RequestType.GET, url);
        } catch ({message}) {
            toastr.error('Het is niet mogelijk een toegangstype toe te voegen', message);
        }
    }
};

const updateSdWanLocationProduct = (locationId, value) => {
    return async () => {
        try {
            let url = apiRoutes.accessRelated.updateSdWanLocationProduct.replace(/{locationId}/, locationId);
            await ApiService.performRequest(RequestType.PATCH, url, {product: value});
        } catch ({message}) {
            toastr.error('Het is niet mogelijk een toegangstype toe te voegen', message);
        }
    }
};

const updateLocationSdWanProperty = (locationId, data) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.accessRelated.updateLocationSdWanProperty.replace(/{locationId}/, locationId);
            let response = await ApiService.performRequest(RequestType.PATCH, url, data);

            if (response.success) {
                dispatch(updateSdWanLocationOptionsSuccess(data));
            }
        } catch ({message}) {
            toastr.error('Het is niet mogelijk een toegangstype toe te voegen', message);
        }
    }
};

const updateSdWanSipTrunk = (sipTrunkId, value) => {
    return async () => {
        try {
            let url = apiRoutes.accessRelated.updateSdWanSipTrunk.replace(/{sipTrunkId}/, sipTrunkId);
            await ApiService.performRequest(RequestType.PATCH, url, {value: value});
        } catch ({message}) {
            toastr.error('Het is niet mogelijk een toegangstype toe te voegen', message);
        }
    }
};

const updateSdWanLocationProductLocal = (product) => {
    return {
        type: actionTypes.updateSdWanLocationProduct,
        payload: product
    }
};

const updateSdWanLocationOptionsSuccess = (data) => {
    return {
        type: actionTypes.updateSdWanLocationOption,
        payload: data
    }
};

const updateSdWanActiveSuccess = (data) => {
    return {
        type: actionTypes.updateLocationSdWanActiveSuccess,
        payload: data
    }
};

const getUpgradeOptions = (quotationID, data) => {
    return async () => {
        try {
            const url = apiRoutes.accessRelated.getUpgradeAccessData.replace('{quotationId}', quotationID);
            await ApiService.performRequest(RequestType.POST, url, data);
        } catch (error) {
            const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;
            toastr.error('Something went wrong', message);
            throw error;
        }
    }
};

const updateDataRetrieval = (value) => {
    return {
        type: actionTypes.updateAccessDataRetrieval,
        payload: value
    };
};

const hasPendingUpgradeData = (quotationID) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.accessRelated.hasPendingUpgradeData.replace('{quotationId}', quotationID);
            let response = await ApiService.performRequest(RequestType.GET, url);
            let {pendingUpgradeData} = response;

            dispatch(updateDataRetrieval(pendingUpgradeData));
        } catch (error) {
            const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;
            toastr.error('Something went wrong', message);
            throw error;
        }
    }
};

export default {
    retrieveExistingAccessData,
    getAccessStepState,
    updateAccessGlobalSettings,
    updateConnection,
    updateLocation,
    getOptions,
    updateConnectionData,
    getConnectionAllowed,
    checkConnectionTypeRequired,
    checkUpgradeTypeRequired,
    setExtraOptionsAvailability,
    getOrganizationHipAllow,
    updatePremiumWifiSelections,
    updateFortiGateSelections,
    updateConnectionType,
    changeAccessType,
    getSdWanProducts,
    updateSdWanLocationProduct,
    updateLocationSdWanProperty,
    updateSdWanSipTrunk,
    updateSdWanLocationProductLocal,
    getUpgradeOptions,
    updateDataRetrieval,
    hasPendingUpgradeData
};
