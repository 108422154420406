import {apiRoutes} from '../../../constants/apiRoutes';
import ApiService from '../../../services/apiService';
import RequestType from '../../../constants/requestType';
import {
    getQuotationOptions,
    getQuotationOptionsSuccess,
    getQuotationOptionsError,
} from '../../isdnMigrationActions/getQuotationOptions';

const getQuotationOptionsThunk = (quotationId) => async (dispatch) => {
    try {
        dispatch(getQuotationOptions(quotationId));

        const url = apiRoutes.isdnMigrationRelated.getQuotationOptions.replace(
            /{quotationId}/,
            quotationId,
        );

        const response = await ApiService.performRequest(RequestType.GET, url);

        dispatch(getQuotationOptionsSuccess(response));
    } catch (error) {
        dispatch(getQuotationOptionsError(error));
    }
};

export default getQuotationOptionsThunk;
