import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

import { actionTypes } from '../constants/actionTypes';
import { apiRoutes } from '../constants/apiRoutes';
import { toastr } from 'react-redux-toastr'

import locationsActions from './locationActions';
import accessActions from "./workflow/accessActions";

const getConnectionsAvailabilityPerLocation = (locationId) => {
  return async (dispatch) => {
    try
    {
      let url = apiRoutes.connectionsRelated.getConnectionsPerLocation + locationId;
      let response = await ApiService.performRequest(RequestType.GET, url);
      dispatch(_getConnectionsAvailabilityPerLocationSuccess(response));
    }
    catch ({message})
    {
      dispatch(_getConnectionsAvailabilityPerLocationSuccess({results: []}))
      toastr.error('Postcodecheck mislukt', '');
    }
  }
}

const _getConnectionsAvailabilityPerLocationSuccess = ({results}) => {
  return {
    type: actionTypes.getConnectionsAvailabilityPerLocation,
    payload: results
  };
}

const getConnectionsListPerLocation = (locationId) => {
  return async (dispatch) => {
    try
    {
      let url = apiRoutes.connectionsRelated.getConnectionsListPerLocation + locationId;
      let response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(_getConnectionsListPerLocationSuccess(response));
    }
    catch ({message})
    {
      dispatch(_getConnectionsListPerLocationSuccess({results: []}));

      toastr.error('Postcodecheck mislukt', '');
    }
  }
}

const _getConnectionsListPerLocationSuccess = ({results}) => {
  return {
    type: actionTypes.getConnectionsListPerLocation,
    payload: results
  };
}

const deleteConnection = (connectionId, quotationId) => {
  return async (dispatch) => {
    try
    {
      let url = apiRoutes.connectionsRelated.deleteConnection + connectionId;
      const responseData = await ApiService.performRequest(RequestType.DELETE, url);

      //to refresh connection list per each location
      dispatch(locationsActions.setQuotationLocations(quotationId));

      toastr.success('Aansluitpunt verwijderd', '');
    }
    catch ({message})
    {
      toastr.error('Het niet niet mogelijk deze aansluitpunt te verwijderen', message);
    }
  }
};

const addKortingMonths = (connectionId, data) => {
  return async () => {
    try {
      const url = apiRoutes.connectionsRelated.addKortingMonths.replace(/{connection}/, connectionId);

      await ApiService.performRequest(RequestType.POST, url, data);
    } catch ({message}) {
      toastr.error('Something went wrong ', message);
    }
  }
}

const setOneTimeCostZero = (connectionId, data) => {
  return async () => {
    try {
      const url = apiRoutes.connectionsRelated.setOneTimeCostZero.replace(/{connection}/, connectionId);

      const response = await ApiService.performRequest(RequestType.POST, url, data);

      if (response.success) {
        return response.isOneTimeCostZero;
      }
    } catch ({message}) {
      toastr.error(message);
    }
  }
}

const createConnection = (connectionName, locationId, quotationId) => {
  return async (dispatch) => {
    try
    {
      const body = {
        location: locationId,
        name: connectionName
      };

      let url = apiRoutes.connectionsRelated.createConnection;
      const responseData = await ApiService.performRequest(RequestType.POST, url, [body]);

      if (responseData.hasOwnProperty('success') && responseData.success === false) {
        toastr.error('Validatiefout', responseData.message);
      } else {
        //to refresh connection list per each location
        dispatch(locationsActions.setQuotationLocations(quotationId));

        // check if there are still available connections points
        const countConnections = Object.keys(responseData.location.connections).length;
        const maxConnections = responseData.location.max_connections;
        if ( countConnections >= maxConnections-1) {
          toastr.success('Aansluitpunt toegevoegd', 'Er zijn op deze locatie niet meer aansluitpunten beschikbaar.');
        } else {
          toastr.success('Aansluitpunt toegevoegd', '');
        }
      }
    }
    catch ({message})
    {
      toastr.error('Het is niet mogelijk een aansluitpunt toe te voegen', message);
    }
  }
};

const updateConnection = (connection, locationId, quotationId) => {
  return async (dispatch) => {
    try
    {
      const body = {
        location: locationId,
        name: connection.name
      };

      let url = apiRoutes.connectionsRelated.updateConnection + connection.id;
      const responseData = await ApiService.performRequest(RequestType.PUT, url, [body]);

      //to refresh connection list per each location
      dispatch(locationsActions.setQuotationLocations(quotationId));

      toastr.success('Aansluitpunt met succes gewijzigd', '');
    }
    catch ({message})
    {
      toastr.error('Het is niet mogelijk een aansluitpunt toe te voegen', message);
    }
  }
};

const updateSmartDiscount = (connectionId, newValue) => {
  return async () => {
    try {
      const url = apiRoutes.discountRelated.updateSmartMigrationDiscount
        .replace(/{connectionId}/, connectionId);

      const response = await ApiService.performRequest(RequestType.PATCH, url, {smartDiscount : newValue});

      if (!response.success) {
        toastr.error('Het is niet mogelijk een aansluitpunt toe te voegen', '');
      }

      return response.amount;
    } catch ({message}) {
      toastr.error('Het is niet mogelijk een aansluitpunt toe te voegen', message);
    }
  }
};

const updateNtDependency = (connectionId, newValue, connectionSelectionId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.discountRelated.updateNtDependency.replace(/{connectionId}/, connectionId);
      const response = await ApiService.performRequest(RequestType.PATCH, url,
          {
            ntDependencyActive : newValue,
            connectionSelectionId: connectionSelectionId
          });

      if (!response.success) {
        toastr.error('Het is niet mogelijk een aansluitpunt toe te voegen', '');
      } else {
        dispatch(updateNTProperties(response));
      }
    } catch ({message}) {
      toastr.error('Het is niet mogelijk een aansluitpunt toe te voegen', message);
    }
  }
};

const updateNTProperties = (response) => {
  return {
    type: actionTypes.updateNtProperties,
    payload: response
  };
};

const getSmartDiscount = (connectionId) => {
  return async () => {
    try {
      const url = apiRoutes.discountRelated.getSmartMigrationDiscount
        .replace(/{connectionId}/, connectionId);

      const response = await ApiService.performRequest(RequestType.GET, url);

      if (!response.success) {
        return 0;
      }

      return response.amount;
    } catch ({message}) {
      toastr.error('Het is niet mogelijk een aansluitpunt toe te voegen', message);
    }
  }
};

const getNtDependency = (connectionId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.discountRelated.getNtDependency.replace(/{connectionId}/, connectionId);
      const response = await ApiService.performRequest(RequestType.GET, url);

      if (! response.success) {
        dispatch(updateNTProperties({success: true, dependencyStatus: false, allowsNt: false}));
      } else {
        dispatch(updateNTProperties(response));
      }
    } catch ({message}) {
      toastr.error('Het is niet mogelijk een aansluitpunt toe te voegen', message);
    }
  }
};

const getNtAllowed = (connectionId, productId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.discountRelated.getNtAllowed.replace(/{connectionId}/, connectionId);
      const response = await ApiService.performRequest(RequestType.POST, url, {connectionProduct : productId});

      if (! response.success) {
        dispatch(updateNTProperties({success: true, dependencyStatus: false, allowsNt: false}));
      } else {
        dispatch(updateNTProperties(response));
      }
    } catch ({message}) {
      toastr.error('Het is niet mogelijk een aansluitpunt toe te voegen', message);
    }
  }
};

const addConnectionSelection = (connectionId, product, quotationId, locationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.connectionsRelated.addConnectionSelection
          .replace(/{connection}/, connectionId);

      const response = await ApiService.performRequest(RequestType.POST, url, {
        product,
        locationId
      });

      if (response.success) {
        dispatch(accessActions.updateConnection(
            'selection_connection',
            response.selection.product_id,
            response.selection.connection_id,
            locationId,
            quotationId)
        );
      }

    } catch ({message}) {
      toastr.error('Het is niet mogelijk een aansluitpunt toe te voegen', message);
    }
  }
};

const getFtthConnectionCheck = (connectionId, productId) => {
  return async(dispatch) => {
    try {
      let url = apiRoutes.connectionsRelated.getFtthConnectionCheck.replace(`{connection}`, connectionId);
      let response = await ApiService.performRequest(RequestType.POST, url, {connectionProduct : productId});

      if (! response.success) {
        dispatch(updateFtthConnectionException({success: true, ftthException: false}));
      } else {
        dispatch(updateFtthConnectionException(response));
      }
    } catch ({message}) {
      toastr.error('Ftth-uitzondering kon niet worden gecontroleerd.');
    }
  }
};

const updateFtthConnectionException = (response) => {
  return {
    type: actionTypes.connectionHasFtthException,
    payload: response
  };
};


export default {
  getConnectionsAvailabilityPerLocation,
  getConnectionsListPerLocation,
  deleteConnection,
  createConnection,
  updateConnection,
  updateSmartDiscount,
  updateNtDependency,
  getSmartDiscount,
  getNtDependency,
  getNtAllowed,
  addConnectionSelection,
  addKortingMonths,
  setOneTimeCostZero,
  getFtthConnectionCheck,
  updateFtthConnectionException,
};