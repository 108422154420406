import {actionTypes} from '../../constants/actionTypes';
import {apiRoutes} from '../../constants/apiRoutes';
import {toastr} from 'react-redux-toastr'
import axios from "axios";

import RequestType from "../../constants/requestType";
import ApiService from "../../services/apiService";

import ObjectHelper from '../../services/objectHelper';
import _ from "lodash";

const getCustomersForAutocomplete = (quotationId, query, isLead, cancelToken = false) => {
    return async (dispatch) => {
        try {
            let url = isLead ? apiRoutes.customersRelated.findLeadCustomer : apiRoutes.customersRelated.findCustomer;
            url = url.replace(isLead ? '{leadId}' : '{quotationId}', quotationId) + '?keyword=' + query;
            let response = await ApiService.getCustomerSuggestions(url, cancelToken);

            dispatch(_getCustomersForAutocompleteSuccess(response.results));
        } catch (error) {
            if (! axios.isCancel(error)) {
                toastr.error('Ophalen van klantgegevens mislukt');
            }
        }
    }
};

const doSecondaryIrmaSearch = (quotationId) => {
    return async () => {
        try {
            let url = apiRoutes.customersRelated.doSecondaryIrmaSearch.replace('{quotationId}', quotationId);
            return await ApiService.performRequest(RequestType.GET, url);
        } catch (error) {
            toastr.error('Er is iets misgegaan bij het zoeken naar de klant in IRMA!');
        }
    }
};

const setSelectedCustomer = (customer, quotationId, isLead = false) => {
    return async (dispatch) => {
        try {
            const selectedCustomer = ObjectHelper.enrichCustomerWithAdditionalProperties(customer, quotationId);
            const url = isLead ? apiRoutes.customersRelated.createLeadCustomer : apiRoutes.customersRelated.createCustomer;
            const response = await ApiService.performRequest(RequestType.POST, url, [selectedCustomer]);

            dispatch(setSelectedCustomerInRedux(response));
            return response;
        } catch (error) {
            const validErrors = error.response?.data;

            if (validErrors?.hasOwnProperty('houseNumberExtension')) {
                validErrors.houseNumberExtension.forEach((e) => {
                    toastr.error(e);
                });
            }

            toastr.error('Het is niet mogelijk om een klant toe te voegen', '');
        }
    }
};

const updateCustomer = (customer, quotationId, isLead = false) => {
    return async () => {
        try {
            let url = isLead ? apiRoutes.customersRelated.updateLeadCustomer : apiRoutes.customersRelated.updateCustomer;
            url = url.replace(/{quotationId}/, quotationId) + customer.id;
            await ApiService.performRequest(RequestType.PUT, url, [customer]);
        } catch (error) {
            toastr.error('Het is niet mogelijk de klant op te slaan', '');
        }
    }
};

const setSelectedCustomerInRedux = (selectedCustomer) => {
    return {
        type: actionTypes.setSelectedCustomer,
        payload: selectedCustomer
    };
};

const _getCustomersForAutocompleteSuccess = (matchingCustomers) => {
    return {
        type: actionTypes.setCustomers,
        payload: matchingCustomers
    }
};

const setSelectedCustomerName = (customerName) => {
    return {
        type: actionTypes.setCustomerName,
        payload: customerName
    }
};

const updateContactInfo = (eventTarget) => {
    let configurationalParameters = eventTarget.name.split('.');
    let value = eventTarget.value;
    if (configurationalParameters[1] === 'email') {
        value = value.toLowerCase();
    }

    return {
        type: actionTypes.updateContactInfo,
        payload: {
            typeOfCustomer: configurationalParameters[0],
            propertyToChange: configurationalParameters[1],
            newValue: value
        }
    }
};

const updateTechnicalContact = (fullName, email, phoneNumber, title, isCopied = false) => {
    return {
        type: actionTypes.updateTechnicalContact,
        payload: {
            typeOfCustomer: 'technicalContact',
            newFullName: fullName,
            newEmail: email,
            newPhoneNumber: phoneNumber,
            newTitle: title,
            isCopied: isCopied
        }
    }
};

const updateProjectManagerContact = (fullName, email, phoneNumber, title, isCopied = false) => {
    return {
        type: actionTypes.updateProjectManagerContact,
        payload: {
            typeOfCustomer: 'projectManager',
            newFullName: fullName,
            newEmail: email,
            newPhoneNumber: phoneNumber,
            newTitle: title,
            isCopied: isCopied,
        }
    }
};

const updateContactInfoAdditionalProperties = ({name, value}) => {
    return {
        type: actionTypes.updateContactInfoAdditionalProperties,
        payload: {
            propertyToChange: name,
            newValue: value
        }
    }
};

const resetCustomerDetails = () => {
    return {
        type: actionTypes.clearSelectedCustomer
    }
};

const initialPrequal = (quotationId) => {
    return async () => {
        try {
            const url = apiRoutes.customersRelated.initialPrequal.replace('{quotationId}', quotationId);
            await ApiService.performRequest(RequestType.GET, url);
        } catch (error) {
        }
    }
};

const retrieveExistingData = (quotationId) => {
    return async () => {
        try {
            const url = apiRoutes.customersRelated.retrieveExistingData.replace('{quotationId}', quotationId);
            await ApiService.performRequest(RequestType.POST, url);
        } catch (error) {
            const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;
            toastr.error('Something went wrong', message);
            throw error;
        }
    }
};

const updateDataRetrievalLoader = (type, value) => {
    return {
        type: type === 'mobile' ? actionTypes.processingExistingMobileData : actionTypes.processingExistingVoiceData,
        payload: value
    }
};

const updateCustomerIrmaId = (quotationId, customerId, data) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.customersRelated.updateIrmaID.replace(/{quotationId}/, quotationId).replace(/{customerId}/, customerId);
            let response = await ApiService.performRequest(RequestType.PUT, url, data);

            if (response.success) {
                dispatch(setCustomerIrmaIdInRedux(data.irmaID))

                toastr.success('De IRMA ID is succesvol bijgewerkt.')
                return true;
            } else {
                toastr.error('er ging iets mis en kon de IRMA ID niet bijwerken', '');
                return false;
            }
        } catch (error) {
            toastr.error('er ging iets mis en kon de IRMA ID niet bijwerken', '');
        }
    }
};

const setCustomerIrmaIdInRedux = (payload) => {
    return {
        type: actionTypes.setCustomerIrmaID,
        payload: payload
    };
};

const updateCustomerOdidoBillingAccountCode = (quotationId, customerId, data) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.customersRelated.updateOdidoBillingAccountCode.replace(/{customer}/, customerId);

            let response = await ApiService.performRequest(RequestType.PUT, url, data)

            if (response.success) {
                dispatch(setCustomerOdidoBillingAccountCode(data.odidoBillingAccountCode))

                toastr.success('De odido billing account id is succesvol bijgewerkt.')
                return true;
            } else {
                toastr.error('er ging iets mis en kon de odido billing account id niet bijwerken', '');
                return false;
            }
        } catch (error) {
            toastr.error('er ging iets mis en kon de odido billing account id niet bijwerken', '');
        }
    }
}

const updateYipCustomerNumber = (quotationId, customerId, data) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.customersRelated.updateYipCustomerNumber.replace(/{customer}/, customerId);

            let response = await ApiService.performRequest(RequestType.PUT, url, data)

            if (response.success) {
                dispatch(setYipCustomerNumber(data.yipCustomerNumber))

                toastr.success('De Yip klant nummer is succesvol bijgewerkt.')
                return true;
            } else {
                toastr.error('er ging iets mis en kon de Yip klant nummer niet bijwerken', '');
                return false;
            }
        } catch (error) {
            toastr.error('er ging iets mis en kon de Yip klant nummer niet bijwerken', '');
        }
    }
}

const setYipCustomerNumber = (payload) => {
    return {
        type: actionTypes.setYipCustomerNumber,
        payload: payload
    }
}

const updateEnreachAccountId = (customerId, data) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.customersRelated.updateEnreachAccountId.replace(/{customer}/, customerId);
            let response = await ApiService.performRequest(RequestType.PUT, url, data)

            if (response.success) {
                dispatch(setEnreachAccountId(data.enreachAccountId))

                toastr.success('De Enreach ID is succesvol bijgewerkt.')
                return true;
            } else {
                toastr.error('er ging iets mis en kon de Enreach ID niet bijwerken', '');
                return false;
            }
        } catch (error) {
            toastr.error('er ging iets mis en kon de Enreach ID niet bijwerken', '');
        }
    }
}

const setEnreachAccountId = (payload) => {
    return {
        type: actionTypes.setEnreachAccountId,
        payload: payload
    }
}

const setCustomerOdidoBillingAccountCode = (payload) => {
    return {
        type: actionTypes.setCustomerOdidoBillingAccountCode,
        payload: payload
    }
}

const updateIsLead = (payload) => {
    return {
        type: actionTypes.updateIsLead,
        payload: payload
    };
};

const getInactiveCustomProducts = (quotationId) => {
    return async () => {
        try {
            const url = apiRoutes.quotationRelated.getInactiveCustomProducts
                .replace('{quotation}', quotationId);
            const response = await ApiService.performRequest(RequestType.GET, url);

            return response;
        } catch ({message}) {
            toastr.error('Something went wrong', message);
        }
    }
}

const updateInactiveCustomProducts = (quotationId, updatedCustomProducts) => {
    return async () => {
        try {
            const url = apiRoutes.quotationRelated.updateInactiveCustomProducts
                .replace('{quotation}', quotationId);

            return await ApiService.performRequest(RequestType.PUT, url, {
                'data': updatedCustomProducts
            });
        } catch ({message}) {
            toastr.error('Sommething went wrong', message);
        }
    }
}

const getQuotationsByKvkIrmaIds = (quotationId) => {
    return async () => {
        try {
            const url = apiRoutes.customersRelated.getQuotationsByKvkIrmaIds
                .replace('{quotation}', quotationId);

            return await ApiService.performRequest(RequestType.GET, url);

        } catch ({message}) {
            toastr.error('Something went wrong', message);
        }
    }
}

const syncCwCustomer = (customer) => {
    return async () => {
        try {
            const url = apiRoutes.connectWiseRelated.syncCwCustomer.replace(/{customer}/, customer.id);
            return await ApiService.performRequest(RequestType.PUT, url, {});
        } catch (error) {
            toastr.error(error.message);
        }
    }
}

const getOpportunitiesByCompanyRecID = (customer) => {
    return async () => {
        try {
            const url = apiRoutes.connectWiseRelated.getOpportunitiesByCompanyRecID.replace(/{customer}/, customer.id);
            return await ApiService.performRequest(RequestType.GET, url, {});
        } catch (error) {
            toastr.error(error.message);
        }
    }
}

const createCwOpportunity = (quotation) => {
    return async () => {
        try {
            const url = apiRoutes.connectWiseRelated.createCwOpportunity;
            return await ApiService.performRequest(RequestType.POST, url, quotation);
        } catch (error) {
            toastr.error(error.message);
        }
    }
}

const setSelectedQuotationForOpportunity = (quotationID, opportunity) => {
    return async () => {
        try {
            const url = apiRoutes.connectWiseRelated.setSelectedQuotationForOpportunity.replace(/{quotation}/, quotationID);
            return await ApiService.performRequest(RequestType.PUT, url, opportunity);
        } catch (error) {
            toastr.error(error.message);
        }
    }
}

export default {
    getCustomersForAutocomplete,
    setSelectedCustomer,
    setSelectedCustomerInRedux,
    setSelectedCustomerName,
    updateContactInfo,
    updateTechnicalContact,
    updateProjectManagerContact,
    updateContactInfoAdditionalProperties,
    updateCustomer,
    resetCustomerDetails,
    initialPrequal,
    retrieveExistingData,
    updateCustomerIrmaId,
    updateDataRetrievalLoader,
    updateIsLead,
    getInactiveCustomProducts,
    updateInactiveCustomProducts,
    getQuotationsByKvkIrmaIds,
    updateCustomerOdidoBillingAccountCode,
    updateYipCustomerNumber,
    syncCwCustomer,
    getOpportunitiesByCompanyRecID,
    createCwOpportunity,
    setSelectedQuotationForOpportunity,
    doSecondaryIrmaSearch,
    updateEnreachAccountId
}
