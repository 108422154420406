import React, {Component, Fragment} from 'react';
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";
import './index.css';
import GeneralDialog from "../../common/GeneralDialog";
import {Input} from "react-materialize";

const quotationStatusList = [
  {value: 0, name: 'Open'},
  {value: 1, name: 'Gewonnen'},
  {value: 2, name: 'Verloren'},
  {value: 3, name: 'Georderd'},
  {value: 4, name: 'Deels georderd'},
  {value: 5, name: 'Deels georderd - ALERT'},
  {value: 6, name: 'Verzonden'},
];

const orderProductStatusList = [
  {value: 'active', name: 'Order activatie'},
  {value: 'declined', name: 'Order afgewezen'},
  {value: 'success', name: 'Success'},
];

class StatusOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: this.props.currentStatus,
    }
  }

  onStatusChange = (e) => {
    let { value } = e.target;
    let status = value;
    if (this.props.isProductStatusChange) {
      let statusIndex =  orderProductStatusList.findIndex((productStatus) => productStatus.name === value);
      if (statusIndex >= 0) {
        status = orderProductStatusList[statusIndex].value;
      }
    }
    this.props.onStatusChange(status, true);

    this.setState({
      ...this.state,
      status: value
    })

  };

  render() {
    const { dialogOpen, onCancel, isProductStatusChange} = this.props;

    const title = isProductStatusChange ? 'Wijzig de productstatus van de bestelling' : 'Verander offerte status'
    const statusList = isProductStatusChange ? orderProductStatusList : quotationStatusList
    return (
      <div>
        <GeneralDialog
          dialogState={dialogOpen}
          closeHandler={onCancel}
          headerIcon={'fact_check'}
          headerText={title}
          overflowException={true}
          dialogBodyContentRaw={
            <DialogBody>
              <div className="statusList">
                <Input
                    type="select"
                    name="status"
                    label="Status"
                    style={{marginBottom: '20px'}}
                    onChange={this.onStatusChange}
                    value={this.state.status}
                >
                  {statusList.map((status) => (
                      <option value={isProductStatusChange ? status.name : status.value} key={status.value}>{status.name}</option>
                  ))}

                </Input>
              </div>
            </DialogBody>
          }
        />

      </div>
    )
  }
}

export default StatusOrder;
