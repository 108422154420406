import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-materialize';
import Wrapper from './Wrapper';
import Header from './Header';
import './FormGroup.css';

//const FormGroup = ({ icon, title, addon, children, ...rest }) => {
class FormGroup  extends React.Component {
    render() {

        const { icon, title, addon, children, ...rest } = this.props;

        return (
            <Wrapper {...rest}>
                <Row className="no-margin-bottom">
                    {icon || title ? (
                        <Header s={12}>
                            <div>
                                <h2>
                                    <span>
                                      <i className="small material-icons left geel">{icon}</i>
                                        &nbsp;
                                    </span>
                                    {title}
                                </h2>
                            </div>
                            <div>
                                {addon}
                            </div>
                        </Header>
                    ) : null}
                    {children}
                </Row>
            </Wrapper>
        )
    }
};

export default FormGroup;

FormGroup.defaultProps = {
  addon: null,
  icon: null,
  title: null,
};

FormGroup.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  addon: PropTypes.node,
  children: PropTypes.node,
};

