import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import accessActions from "../../../actions/workflow/accessActions";
import connectionActions from '../../../actions/connectionsActions';
import quotationActions from '../../../actions/quotationActions';
import quotationQuestionsActions from '../../../actions/quotationQuestionActions';

import AccessGlobalForm from './accessGlobalForm';
import AccessLocationForm from './accessLocationForm';

import getQuotationOptionsThunk from '../../../actions/workflow/isdnMigrationActions/getQuotationOptionsThunk';
import updateQuotationOptionsThunk from '../../../actions/workflow/isdnMigrationActions/updateQuotationOptionsThunk';
import {getLocationOptionsThunk} from '../../../actions/workflow/isdnMigrationActions/getLocationOptionsThunk';
import updateConnectionOptionsThunk from '../../../actions/workflow/isdnMigrationActions/updateConnectionOptionsThunk';

import LoaderComponent from '../../../containers/common/LoaderComponent';

import quotationType from "../../../constants/quotationType";

class AccessStep extends Component {
  state = {
    loading: true,
    existingAccessData: [],
    upgradingData: [],
  };

  componentDidMount() {
    let selectedLocationId = this.props.locations.selectedLocation.id;
    let {currentQuotation} = this.props.quotation;
    let quotationId = currentQuotation.id;

    this.props.connectionActions.getConnectionsListPerLocation(selectedLocationId);
    this.props.accessActions.getAccessStepState(quotationId);
    this.props.accessActions.getOptions(quotationId);

    if (currentQuotation.type === quotationType.TYPE_VERLENGEN) {
      this.props.accessActions.retrieveExistingAccessData(quotationId);
    }

    this.props.getQuotationOptions(quotationId).then(() => {this.setState({ loading: false })});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.existingAccessData !== this.props.existingAccessData) {
      this.setState({
        existingAccessData: this.props.existingAccessData
      });

      const orderIds = this.props.existingAccessData.map(el => el.order_id);
      this.props.quotationActions.getUpgradingData(this.props.quotation.currentQuotation.id, orderIds);
    }

    if (prevProps.quotation.upgradingData !== this.props.quotation.upgradingData) {
      this.setState({
        upgradingData: this.props.quotation.upgradingData
      });
    }
  }

  saveSelections = (selections, type) => {
    let quotationId = this.props.quotation.currentQuotation.id;
    if (type === 'premiumWifi') {
      this.props.accessActions.updatePremiumWifiSelections(quotationId, selections);
    } else {
      this.props.accessActions.updateFortiGateSelections(quotationId, selections);
    }
  };

  render() {
    let selectedLocation = this.props.locations.selectedLocation;
    let selectedLocationId = selectedLocation.id;

    let isGlobalLocationSelected = selectedLocationId <= 0;
    let globalFormState = this.props.access.accessState.find(x => x.locationID === 'global');
    let locationFormState = this.props.access.accessState.find(x => x.locationID === selectedLocationId);

    let quotation = this.props.quotation;
    let currentQuotation = this.props.quotation.currentQuotation;
    let quotationId = currentQuotation.id;
    let {inputsDisabled} = currentQuotation;

    const {getLocationOptions, location, updateConnectionOptions} = this.props;
    const {theme} = this.props.authentication;

    return (
      <div className="row">
        {this.state.loading ? <LoaderComponent theme={theme}/> :
          (
            <fieldset disabled={inputsDisabled} style={{border: 'none', padding: 'unset', margin: 'unset'}}>
              {
                isGlobalLocationSelected &&
                <AccessGlobalForm
                  formState={globalFormState}
                  quotation={quotation}
                  getQuotationOptions={this.props.getQuotationOptions}
                  quotationOptions={this.props.quotationOptions}
                  updateQuotationOptions={this.props.updateQuotationOptions}
                  saveSelections={this.saveSelections}
                  existingAccessData={this.state.existingAccessData}
                  upgradingData={this.state.upgradingData}
                />
              }
              {
                selectedLocationId > 0 && (
                  <AccessLocationForm
                    quotationId={quotationId}
                    formState={locationFormState}
                    getQuotationOptions={this.props.getQuotationOptions}
                    quotationOptions={this.props.quotationOptions}
                    updateQuotationOptions={this.props.updateQuotationOptions}
                    getLocationOptions={getLocationOptions}
                    location={location}
                    updateConnectionOptions={updateConnectionOptions}
                    existingAccessData={this.state.existingAccessData}
                    upgradingData={this.state.upgradingData}
                    changeAccessType={this.changeAccessType}
                  />
                )
              }
            </fieldset>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = ({locations, access, quotation, quotationOptions, authentication}) => {
  return {
    locations,
    quotation,
    access,
    quotationOptions,
    location: locations.selectedLocation,
    existingAccessData: access.existingAccessData,
    authentication
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    accessActions: bindActionCreators(accessActions, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
    quotationQuestionsActions: bindActionCreators(quotationQuestionsActions, dispatch),
    connectionActions: bindActionCreators(connectionActions, dispatch),
    getQuotationOptions: bindActionCreators(getQuotationOptionsThunk, dispatch),
    updateQuotationOptions: bindActionCreators(updateQuotationOptionsThunk, dispatch),
    getLocationOptions: bindActionCreators(getLocationOptionsThunk, dispatch),
    updateConnectionOptions: bindActionCreators(updateConnectionOptionsThunk, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessStep);
