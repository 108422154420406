import actionTypes from '../constants/actionTypes';

export const getLocationOffers = (locationId) => ({
    type: actionTypes.getLocationOffers.init,
    payload: {
        locationId,
    },
});
export const getLocationOffersSuccess = (data) => ({
    type: actionTypes.getLocationOffers.success,
    payload: {
        data,
    },
});

export const getLocationOffersError = (error) => ({
    type: actionTypes.getLocationOffers.error,
    payload: {
        error,
    },
    error: true,
});

export const getLocationOffer = (connectionId, locationId, offerId) => ({
    type: actionTypes.getLocationOffer.init,
    payload: {
        connectionId,
        locationId,
        offerId,
    },
});

export const getLocationOfferSuccess = (connectionId, offerId, data) => ({
    type: actionTypes.getLocationOffer.success,
    payload: {connectionId, offerId, data},
});

export const getLocationOfferError = (connectionId, error) => ({
    type: actionTypes.getLocationOffer.error,
    payload: {
        connectionId,
        error,
    },
    error: true,
});

export const getConnectionOptions = (connectionId) => ({
    type: actionTypes.getConnectionOptions.init,
    payload: {
        connectionId,
    },
});

export const getConnectionOptionsSuccess = (data) => ({
    type: actionTypes.getConnectionOptions.success,
    payload: {
        data,
    },
});

export const getConnectionOptionsError = (error) => ({
    type: actionTypes.getConnectionOptions.error,
    payload: {
        error,
    },
    error: true,
});

export const getLocationOptions = (locationId) => ({
    type: actionTypes.getLocationOptions.init,
    payload: {locationId},
});

export const getLocationOptionsSuccess = (data) => ({
    type: actionTypes.getLocationOptions.success,
    payload: {data},
});

export const getLocationOptionsError = (error) => ({
    type: actionTypes.getLocationOptions.error,
    payload: {error},
});

export const getConnectionAllowed = (data) => {
    // Append timestamp to be able to differentiate requests
    data.execId = new Date().getTime();

    return {
        type: actionTypes.connectionAllowedIsdn,
        payload: {
            data
        }
    }
};
