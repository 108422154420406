import React, {Component} from 'react';
import {Input, Row, Col} from "react-materialize";

import DialogBody from "../../../containers/common/KpnDialog/DialogBody";
import FileUpload from "../../../components/fileUpload";
import GeneralDialog from "../../common/GeneralDialog";

import ProviderHelper from "../../../helper/providerHelper";

class AttachmentsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attachmentDocument: {
        id: this.props.document.id || null,
        name: this.props.document.name,
        file: '',
        provider: this.props.document.provider || 0,
        isFileActive: this.props.document.isFileActive || false,
        productCategory: this.props.document.productCategory || '',
        documentType: this.props.document.documentType || 'docx'
      },
      uploadErrors: ''
    }
  }

  componentDidMount() {
    const {document, providers} = this.props;
    if (!document.provider && providers.length) {
      this.setState({attachmentDocument: {...this.state.attachmentDocument, provider: providers[0].provider}});
    }
  }

  onChange = ({target}) => {
    let {name, value, type, checked} = target;

    if ('checkbox' === type) {
      value = checked ? 1 : 0;
    }

    this.setState(prevState => ({
      attachmentDocument: {
        ...prevState.attachmentDocument,
        [name]: value
      }
    }));
  };

  onDrop = (files) => {
    const file = files[0];

    const {documentType} = this.state.attachmentDocument;

    if ((!documentType || documentType === 'docx') && file.type === 'application/pdf') {
      this.setState({uploadErrors: 'Upload een geldig docx-bestand!'});
      return;
    }
    if (documentType === 'pdf' && file.type !== 'application/pdf') {
      this.setState({uploadErrors: 'Upload een geldig pdf-bestand!'});
      return;
    }

    this.setState(prevState => ({
      attachmentDocument: {
        ...prevState.attachmentDocument,
        file: file
      },
      uploadErrors: ''
    }));
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.uploadErrors.length) {
      return;
    }

    this.props.onSubmit(this.state.attachmentDocument)
  };

  render() {
    const {providers, productCategories} = this.props;
    const {attachmentDocument, uploadErrors} = this.state;

    return (
      <GeneralDialog
        dialogState={true}
        closeHandler={this.props.onClose}
        headerText={'Bijlage'}
        headerIcon={'person'}
        dialogBodyContentRaw={
          <DialogBody>
            <form onSubmit={this.onSubmit}>
              <Row>
                <Input
                  required
                  s={12}
                  defaultValue={attachmentDocument.name}
                  onChange={this.onChange}
                  label="Naam"
                  name='name'
                  type='text'/>
              </Row>
              {!attachmentDocument.id &&
              <Row>
                <Input
                  s={12}
                  name={'provider'}
                  id={'provider'}
                  type="select"
                  multiple={false}
                  required={true}
                  label={'Provider'}
                  defaultValue={attachmentDocument.provider}
                  onChange={this.onChange}
                  className="validate">
                  <option value="-1" disabled={'disabled'} key={-1}>Kies provider</option>
                  {providers.filter((el => el.provider !== 1)).map((el) => {
                    return (
                      <option value={el.provider} key={el.provider}>
                        {ProviderHelper.getQuotationProviderName(el.provider)}
                      </option>
                    );
                  })}
                </Input>
              </Row>
              }

              { !attachmentDocument.id && (
                <Row style={{marginBottom: 35}}>
                  <Col s={12}>
                    <Input
                      s={12}
                      name={'documentType'}
                      id={'documentType'}
                      type="select"
                      multiple={false}
                      required={true}
                      label={'Type documentextensie'}
                      defaultValue={attachmentDocument.documentType}
                      onChange={this.onChange}
                      className="validate"
                    >
                      <option value="docx" key={'all-categories'}>Word-documenttype (.docx)</option>
                      <option value="pdf" key={'all-categories'}>PDF-documenttype (.pdf)</option>
                    </Input>
                  </Col>
                  <Col s={12} style={{padding: '0 1.5rem'}}>
                  <span style={{color: 'orange'}}>
                    Houd er rekening mee dat de Word-documenten ook sjablonen kunnen zijn die,
                    voordat ze naar pdf worden omgezet, door de exportservice worden verwerkt zoals contracten dat doen.
                  </span>
                  </Col>
                </Row>
              )}

              <Row>
                <Input
                  s={12}
                  name={'productCategory'}
                  id={'productCategory'}
                  type="select"
                  multiple={false}
                  required={true}
                  label={'Alleen van toepassing op deze productcategorie'}
                  defaultValue={attachmentDocument.productCategory}
                  onChange={this.onChange}
                  className="validate"
                >
                  <option value="" key={'all-categories'}>Toepassen op alle productcategorieën</option>
                  {productCategories.filter((productCategory) => productCategory.provider === parseInt(attachmentDocument.provider))
                    .map((productCategory) => {
                      return (
                        <option value={productCategory.value} key={productCategory.value}>
                          {productCategory.name}
                        </option>
                      );
                    })}
                </Input>
              </Row>

              <Row>
                <Input
                  type="checkbox"
                  label="Actief"
                  name="isFileActive"
                  id="isFileActive"
                  checked={attachmentDocument.isFileActive}
                  onChange={this.onChange}
                />
              </Row>

              {!attachmentDocument.id && (
                <Row>
                  <Col s={12} className="input-field">
                    <FileUpload
                      onDrop={this.onDrop}
                      caption="Sleep uw bestand hier of klik voor upload. Let op, dit overschrijft het oude bestand"
                    />

                    {attachmentDocument.file && <div>Geselecteerd bestand: {attachmentDocument.file.name}</div>}
                  </Col>
                  {uploadErrors && (
                    <Col s={12}>
                      <span style={{color: 'red'}}>{uploadErrors}</span>
                    </Col>
                  )}
                </Row>
              )}

              <button
                type="submit"
                disabled={uploadErrors.length}
                onClick={(e) => this.onSubmit(e)}
                className='btn doceri-btn-right ratio-btn-right-fullwidth right'
              >
                Submit
              </button>
            </form>
          </DialogBody>
        }
      />
    )
  }
}

export default AttachmentsForm;
