import {
    getLocationOptions,
    getLocationOptionsSuccess,
    getLocationOptionsError,
} from '../../isdnMigrationActions';
import {apiRoutes} from '../../../constants/apiRoutes';
import ApiService from '../../../services/apiService';
import RequestType from '../../../constants/requestType';

export const getLocationOptionsThunk = (locationId) => async (dispatch) => {
    try {
        dispatch(getLocationOptions(locationId));

        const url = apiRoutes.isdnMigrationRelated.getLocationOptions.replace(
            /{locationId}/,
            locationId,
        );

        const {data} = await ApiService.performRequest(RequestType.GET, url);

        dispatch(getLocationOptionsSuccess(data));
    } catch (error) {
        dispatch(getLocationOptionsError(error));
    }
};
