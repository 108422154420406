import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import {Col, Row} from 'react-materialize';

import quotationActions from "../../../actions/quotationActions";
import telephonyActions from '../../../actions/workflow/telephonyActions';
import stepsActions from "../../../actions/stepsActions";
import locationActions from "../../../actions/locationActions";

import LoaderComponent from '../../../containers/common/LoaderComponent';
import TelephonyLocationLevel from "./TelephonyLocationLevel";
import TelephonyContractLevel from "./TelephonyContractLevel";
import QuotationType from "../../../constants/quotationType";
import QuotationQuestionModule from "../../common/QuotationQuestionsTable/QuotationQuestionModule";


class TelephonyStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      profilesProcessing: false,
      showPerLocation: false,
      quotationType: this.props.quotation.currentQuotation.type,
      telephonyOptions: props.telephonyOptions,
      telephonyLocationOptions: props.telephonyLocationOptions,
      telephonyActive: false,
    }
  }

  componentWillMount() {
    this.props.locationActions.resetSelectedLocationToGlobal();
  }

  componentDidMount() {
    if (this.props.quotation.type === QuotationType.TYPE_QA) {
      this.setState({ loading: false });
      return;
    }

    this.setState({telephonyActive: this.props.telephonyOptions.telephonyActive});
    let locationId = this.props.locations.selectedLocation.id > 0 ? this.props.locations.selectedLocation.id : null;
    if (locationId) {
      this.props.telephonyActions.getTelephonyOfferProducts(this.props.quotationId, locationId).then(() => {
        this.setState({loading: false})
      });
    } else {
      this.setState({loading: false});
    }
  }

  componentDidUpdate(prevProps) {
    const isQAQuotation = this.props.quotation.type === QuotationType.TYPE_QA;
    const locationId = this.props.locations.selectedLocation.id > 0 ? this.props.locations.selectedLocation.id : null;

    if (prevProps.locations.selectedLocation.id !== this.props.locations.selectedLocation.id && !isQAQuotation) {
      if (locationId > 0) {
        this.props.telephonyActions.getTelephonyLocationOptions(this.props.quotationId, locationId);
      }
      this.props.telephonyActions.getTelephonyOfferProducts(this.props.quotationId, locationId);
      this.setState({showPerLocation: locationId > 0});
    }

    if (prevProps.telephonyOptions !== this.props.telephonyOptions) {
      this.setState({telephonyOptions: this.props.telephonyOptions});
    }

    if (prevProps.telephonyLocationOptions !== this.props.telephonyLocationOptions) {
      this.setState({telephonyLocationOptions: this.props.telephonyLocationOptions});
    }

    if (prevProps.telephonyOptions.telephonyActive !== this.props.telephonyOptions.telephonyActive) {
      this.setState({telephonyActive: this.props.telephonyOptions.telephonyActive});
    }
  }

  updateTelephonyOptions = (e) => {
    if (this.props.quotation.status) {
      return;
    }

    const {target} = e;
    let {name, value} = target;
    let {telephonyOptions} = this.state;

    if (name === 'reporting') {
      if (value) {
        let counterPartName = value === 'reportingLight' ? 'reportingPremium' : 'reportingLight';
        telephonyOptions = {...telephonyOptions, [counterPartName]: 0};
        name = value;
        value = 1;
      } else {
        name = telephonyOptions.reportingLight > 0 ? 'reportingLight' : 'reportingPremium';
        value = 0;
      }
    }

    this.props.telephonyActions.updateTelephonyOptions(this.props.quotationId, {...telephonyOptions, [name]: value});
  };

  updateTelephonyLocationOptions = (e) => {
    if (this.props.quotation.status) {
      return;
    }

    const {target} = e;
    let {name, value, type, min, max} = target;
    let {telephonyLocationOptions} = this.state;

    if (target.type === 'number' && value !== '' && !value.match(/^\d+$/)) {
      toastr.error("Can't specify negative values - to be translated");
      return;
    }

    if (type === 'number' && max) {
      min = isNaN(min) ? 0 : min;
      value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    }

    let quotationId = this.props.quotationId;
    let locationId = this.props.locations.selectedLocation.id;
    this.props.telephonyActions.updateTelephonyLocationOptions(quotationId, locationId, {
      ...telephonyLocationOptions,
      [name]: value
    });
  };

  updateTelephonyActive = (value) => {
    let {telephonyOptions} = this.state;
    this.props.telephonyActions.updateTelephonyOptions(this.props.quotationId, {
      ...telephonyOptions,
      telephonyActive: !value
    });
  };

  render() {
    let showPerLocation = this.state.showPerLocation;
    let telephonyOptions = this.state.telephonyOptions || {};
    let telephonyLocationOptions = this.state.telephonyLocationOptions || {};
    let telephonyActive = this.state.telephonyActive;
    let locationId = this.props.locations.selectedLocation.id;

    const {theme} = this.props.authentication;
    const { inputsDisabled, type} = this.props.quotation.currentQuotation;
    const isQAQuotation = type === QuotationType.TYPE_QA;

    return (
      <div>
        {this.state.loading ?
          <LoaderComponent theme={theme}/> :
          (isQAQuotation ? <QuotationQuestionModule module={'telephony'} location={locationId}/> :
              <React.Fragment>
                {!showPerLocation &&
                <div className="row" id="Forms">
                  <Col>
                    <Row>
                      <h1 className={'ratio-section-title'}>
                  <span className={'ratio-section-title'}>
                    <i className="small material-icons left ratio-section-title">phone_android</i>
                    Wenst u telefonie?
                  </span>
                      </h1>
                    </Row>
                    <Row>
                      <div>
                        <button
                          className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${telephonyActive ? "active" : ""}`}
                          onClick={() => this.updateTelephonyActive(telephonyActive)}
                          disabled={inputsDisabled}
                        >
                          Ja, activeer telefonie
                        </button>
                      </div>
                    </Row>
                  </Col>
                </div>
                }

                {!showPerLocation && telephonyActive &&
                <TelephonyContractLevel
                  onChangeCallback={this.updateTelephonyOptions}
                  telephonyOptions={telephonyOptions}
                />
                }

                {showPerLocation && telephonyActive &&
                <TelephonyLocationLevel
                  selectedMobileProducts={this.props.currentMobileProducts}
                  mobileProductOptions={this.props.currentMobileProductOptions}
                  mobileProducts={this.props.mobileProducts}
                  telephonyOptions={telephonyLocationOptions}
                  onChangeCallback={this.updateTelephonyLocationOptions}
                  acdTypes={this.props.quotation.currentQuotation.acdTypes}
                />
                }
              </React.Fragment>
          )
        }
      </div>
    )
  }
}

const mapStateToProps = ({quotation, mobile, authentication, locations, loading, telephony}) => {
  return {
    locations,
    quotation,
    quotationId: quotation.currentQuotation.id,
    mobileOptions: quotation.currentQuotation.mobileOptions,
    mobileProducts: mobile.mobileProducts,
    mobileDataProducts: mobile.mobileDataProducts,
    currentMobileProducts: mobile.currentMobileProducts,
    currentMobileProductOptions: mobile.currentMobileProductOptions,
    telephonyOptions: quotation.currentQuotation.telephonyOptions,
    authentication: authentication,
    telephoneNumbers: quotation.currentQuotation.telephone_numbers,
    telephonyLocationOptions: telephony.selectedOptions,
    loading
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    quotationActions: bindActionCreators(quotationActions, dispatch),
    telephonyActions: bindActionCreators(telephonyActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
    locationActions: bindActionCreators(locationActions, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TelephonyStep);
