import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom'

import {Input} from 'react-materialize';
import organizationActions from '../../../actions/organizationActions';
import FlashCalculationPanel from "./FlashCalculation/FlashCalculationPanel";

class SettingsPanel extends Component {
    updateOrganizationProperty = ({target}) => {
        let {name, value, checked} = target;

        if (name === 'oneTimePartnerService') {
            value = Array.from(target.selectedOptions).map(option => option.value);
        }

        this.props.organizationActions.editOrganization(this.props.organization.id,
            {[name]: name === 'versioning' ? checked : value}
        );
    };

    componentWillMount() {
        this.setState({
            dataLoaded: false,
        });

        this.props.organizationActions.getOrganizationDetails().then(() => {
            this.setState({
                dataLoaded: true,
            });
        });
    }

    render() {
        const quotationValidityDurations = [15, 30, 45, 60, 90];
        const organization = this.props.organization;

        let domain = organization.email ? organization.email.substring(organization.email.indexOf("@")) : '';
        let multipleSelect = '';

        if (this.state.dataLoaded) {
            multipleSelect = <Input
                                s={12}
                                ref="oneTimePartnerService"
                                name="oneTimePartnerService"
                                type="select"
                                label="Eenmalige Partner/IT diensten toevoegen is beschikbaar voor"
                                onChange={this.updateOrganizationProperty}
                                value={organization.oneTimePartnerService}
                                multiple={true}
                                placeholder="Select"
                            >
                                <option value="ROLE_ADMIN">Admin</option>
                                <option value="ROLE_SALES">Sales</option>
                                <option value="ROLE_SALES_SUPPORT">Sales support</option>
                                <option value="ROLE_MANAGE_USERS">Gebruikersbeheer</option>
                            </Input>;
        }

        return (
            <div>
                <div className="flex-space-between">
                    <div className="Forms">
                        <h1 className='ratio-section-title'>
                            <i className="small material-icons left">
                                settings
                            </i>
                            Instellingen
                        </h1>
                        <div>
                            <Input
                                s={12}
                                ref="quotationValidity"
                                name="quotationValidity"
                                type="select"
                                label="Geldigheid offerte"
                                onChange={this.updateOrganizationProperty}
                                value={organization.quotationValidity}
                                multiple={false}
                            >
                                <option value=""/>
                                {quotationValidityDurations.map((x) => (
                                    <option value={x} key={x}>{x} dagen</option>
                                ))}
                                <option value="end_of_month">Einde v.d. maand</option>
                                <option value="end_of_quarter">Einde kwartaal</option>
                            </Input>
                        </div>
                    </div>
                    <div className="Forms">
                        <h1 className='ratio-section-title'>
                            <i className="small material-icons left">
                                settings
                            </i>
                            Instellingen eigen producten en diensten
                        </h1>
                        <div>
                            {multipleSelect}
                        </div>
                    </div>
                    {
                        organization.allow_digital_signing &&
                        <div className="Forms">
                            <h1 className='ratio-section-title'>
                                <i className="small material-icons left">
                                    settings
                                </i>
                                Instelligen digitaal ondertekenen
                            </h1>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '10px', marginLeft: '15px'}}>
                                <span> Digital Ondertekenen URL: {organization.custom_web_url} </span>
                                <span> Uitgaande email adres: {organization.email} </span>
                                <span> Uitgaande email adres bevestigingen aan verkoper: support@doceri.nl </span>
                                <span> SMS afzender naam: {organization.formatted_name} </span>
                                <span> DNS en SPF records ingesteld op: DNS: {domain}</span>
                            </div>
                        </div>
                    }

                    {
                        this.props.authentication.isFlash && <FlashCalculationPanel/>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        organization: state.organizations.organization,
        authentication: state.authentication
    }
};

const mapDispatchToProps = (dispatch) => {
  return {
      organizationActions: bindActionCreators(organizationActions, dispatch),
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsPanel));
