import React, {Component} from 'react';
import {Col, Icon, Input, Row} from 'react-materialize';
import {bindActionCreators} from 'redux';
import {connect} from "react-redux";

import quotationActions from "../../../actions/quotationActions";
import telephonyActions from "../../../actions/workflow/telephonyActions";
import stepsActions from "../../../actions/stepsActions";

import LoaderComponent from '../../../containers/common/LoaderComponent';
import {sleep} from "../../../utils/utils";

class CommunicationLocationLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profilesProcessing: false,
      mobileProductOptions: this.props.mobileProductOptions || [],
      selectedMobileProducts: this.props.selectedMobileProducts || [],
      mobileFormErrors: {},
    };
  }

  componentDidMount() {
    if (!this.props.selectedMobileProducts.length) {
      this.setState({selectedMobileProducts: [{id: "", productId: ""}]})
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedMobileProducts !== this.props.selectedMobileProducts) {
      this.setState({selectedMobileProducts: this.props.selectedMobileProducts});
    }

    if (prevProps.mobileProductOptions !== this.props.mobileProductOptions) {
      this.setState({mobileProductOptions: this.props.mobileProductOptions})
    }
  }

  onChangeCallback = (selectionId, selectionProductId = false) => (event, value) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }

    let bundleIsChanged = !selectionProductId;
    const productId = selectionProductId ? selectionProductId : +value;
    let currentSelectedProducts = this.props.selectedMobileProducts;

    let deletedCurrentSelection = false;
    let newQuantity = 0;
    if (bundleIsChanged) {
      currentSelectedProducts.forEach((obj, index) => {
        if ((!selectionId || obj.id !== selectionId)
          && productId === currentSelectedProducts[index]['productId']) {

          if (selectionId) {
            const foundIndex = currentSelectedProducts.findIndex((productSelection) => productSelection && productSelection.id === selectionId);
            if (foundIndex !== -1) {
              newQuantity = currentSelectedProducts[index]['quantity'] += currentSelectedProducts[foundIndex].quantity;

              if (newQuantity <= 999) {
                currentSelectedProducts[index]['quantity'] = newQuantity;
              }
              delete currentSelectedProducts[foundIndex];
            } else {
              newQuantity = currentSelectedProducts[index]['quantity'] + 1;
              if (newQuantity <= 999) {
                currentSelectedProducts[index]['quantity'] = newQuantity;
              }
            }
          } else {
            newQuantity = currentSelectedProducts[index]['quantity'] + 1;
            if (newQuantity <= 999) {
              currentSelectedProducts[index]['quantity'] = newQuantity;
            }
          }

          deletedCurrentSelection = true;
        }
      });
    }

    if (!deletedCurrentSelection) {
      if (selectionId) {
        currentSelectedProducts.forEach((obj, index) => {
          if (obj.id === selectionId) {
            if (productId === 0) {
              delete currentSelectedProducts[index];
            } else {
              currentSelectedProducts[index]['productId'] = productId;
              currentSelectedProducts[index]['quantity'] = 1;
              if (selectionProductId) {
                currentSelectedProducts[index]['quantity'] = value;
              }
            }
          }
        });
      } else {
        const product = {
          id: "",
          productId: productId,
          quantity: 1
        };

        currentSelectedProducts.push(product);
      }
    }

    currentSelectedProducts = currentSelectedProducts.filter(obj => obj.productId !== null);
    this.setState({selectedMobileProducts: currentSelectedProducts});
    let selectedLocation = this.props.locations.selectedLocation.id;

    this.props.telephonyActions.updateMobileProducts(
      this.props.quotation.currentQuotation,
      selectedLocation,
      currentSelectedProducts,
      this._getLatestSelectedOptions(bundleIsChanged ? selectionId : false)
    )
  };

  onQuantityChangeCallback = (selectionId, selectionProductId = false) => (event, value) => {
    this.setState({profilesProcessing: true});
    const productId = selectionProductId ? selectionProductId : +value;
    let currentSelectedProducts = this.props.selectedMobileProducts;

    if (selectionId) {
      currentSelectedProducts.forEach((obj, index) => {
        if (obj.id === selectionId) {
          if (productId === 0) {
            delete currentSelectedProducts[index];
          } else {
            currentSelectedProducts[index]['productId'] = productId;
            currentSelectedProducts[index]['quantity'] = 1;
            if (selectionProductId) {
              value = Math.max(Number(event.target.min), Math.min(Number(event.target.max), Number(value)));

              currentSelectedProducts[index]['quantity'] = value;
            }
          }
        }
      });
    }

    currentSelectedProducts = currentSelectedProducts.filter(obj => obj.productId !== null);
    this.setState({selectedMobileProducts: currentSelectedProducts});

    sleep(2000).then(() => {
      this.setState({profilesProcessing: true});
      this.sendUpdateMobileProducts();
    });
  };

  sendUpdateMobileProducts = () => {
    let selectedLocation = this.props.locations.selectedLocation.id;

    this.props.telephonyActions.updateMobileProducts(this.props.quotation.currentQuotation, selectedLocation,
      this.getSelectedMobileProducts(), this._getLatestSelectedOptions()).then(() => {
      this.setState({profilesProcessing: false});
    });
  };

  getSelectedMobileProducts = () => {
    return this.state.selectedMobileProducts.filter(obj => obj.productId !== null && !obj.isOption);
  };

  _getLatestSelectedOptions = (bundleIsChanged = false) => {
    let allSelectedOptions = {};

    Object.keys(this.state.mobileProductOptions).map(selId => {
      if (bundleIsChanged != selId) {
        allSelectedOptions[selId] = this.getSelectedProducts(this.state.mobileProductOptions[selId]);
      }
    });

    return allSelectedOptions;
  };

  static applySelectedProduct = (acc, current) => {
    if (current.selectedProducts.length > 0) {
      let rObj = {};
      current.selectedProducts.filter(obj => obj.id !== -1).forEach(obj => {
        rObj[obj.id] = acc[obj.id] ? (acc[obj.id] + obj.quantity) : obj.quantity;
      });

      return {...acc, ...rObj};
    }

    return acc;
  };

  getSelectedProducts = (mobileOptions) => mobileOptions.reduce(CommunicationLocationLevel.applySelectedProduct, {});

  addNewProductSelection = () => {
    this.props.telephonyActions.addNewProductSelection({id: "", productId: "", quantity: 1});
  };

  renderExtraSelect = (option, index) => {
    const {inputsDisabled} = this.props.quotation.currentQuotation;
    const addButtonVisible = option.selectedProducts.length < option.products.length;

    return (
      <div key={index}>
        {this.renderOptionFields(option, index)}

        {addButtonVisible && (option.products.length !== option.selectedProducts.length) ? (
          <Row>
            <Col s={12}>
              <button
                className={'btn ratio-btn white-text ratio-btn-right right ' + (inputsDisabled ? '' : 'doceri-btn-left')}
                onClick={() => this.addOption(index, option)} style={{marginBottom: 30}}
                disabled={inputsDisabled}
              >
                Voeg optie toe
                <Icon right>
                  add
                </Icon>
              </button>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  };

  handleOptionsQuantityChange = (productId, min, max, selectionId, index) => (event, value) => {
    let quantity = +value;
    quantity = Math.max(Number(event.target.min), Math.min(Number(event.target.max), Number(quantity)));
    let allOptions = this.state.mobileProductOptions;
    let options = allOptions[selectionId];

    if (String(index).indexOf('-') !== -1) {
      index = index.substring(index.indexOf('-') + 1);
    }

    if (options[index]) {
      const foundIndex = options[index].selectedProducts.findIndex((selectedProduct) => selectedProduct && selectedProduct.id === productId);
      if (foundIndex !== -1) {
        options[index]['selectedProducts'][foundIndex]['quantity'] = quantity;
      }

      allOptions[selectionId] = options;
      this.setState({mobileProductOptions: allOptions});
    }

    this.sendUpdateMobileProducts();
  };

  handleOptionsChange = (productId, quantity = 1, max = null, selectionId, index, numberId = null) => (event, value) => {
    const id = +value;

    let allOptions = this.state.mobileProductOptions;
    let options = allOptions[selectionId];
    let newSelection = {
      id: id,
      quantity: quantity,
      telephone_number_id: numberId
    };

    if (String(index).indexOf('-') !== -1) {
      index = index.substring(index.indexOf('-') + 1);
    }

    if (options[index]) {
      const foundIndex = options[index].selectedProducts.findIndex((selectedProduct) => {
        const condition = selectedProduct && selectedProduct.id === productId;

        if (numberId) {
          return condition && selectedProduct.telephone_number_id === numberId;
        }

        return condition;
      });

      if (foundIndex !== -1) {
        delete options[index]['selectedProducts'][foundIndex];
      }
      options[index]['selectedProducts'].push(newSelection);

      allOptions[selectionId] = options;

      this.setState({mobileProductOptions: allOptions});
    }
    let selectedLocation = this.props.locations.selectedLocation.id;

    this.props.telephonyActions.updateMobileProducts(
      this.props.quotation.currentQuotation,
      selectedLocation,
      this.state.selectedMobileProducts,
      this._getLatestSelectedOptions()
    );
  };

  renderOptionFields = (option, index) => {
    const {selectedProducts = [{id: -1}]} = option || {};
    const {currentQuotation} = this.props.quotation;
    const {inputsDisabled} = currentQuotation;
    let value = {id: -1};

    const selectedProductsArray = selectedProducts && selectedProducts.length ? selectedProducts : [value];

    let rows = [];

    selectedProductsArray.forEach((value, indexField) => {
      let maxQuantity = 999;
      const selectWidth = value.id !== -1 ? 9 : 10;

      let row = (
        <Col key={`${option.category.name}-${indexField}-${value.id}`} s={12} style={{paddingRight: 0}}>
          <Row key={`${option.category.name}-${indexField}-${value.id}`} style={{marginBottom: 0}}>
            {value.id !== -1 && maxQuantity > 1 ? (
              <Input
                s={1}
                type="number"
                label="Aantal"
                value={value.quantity > 0 ? value.quantity : 1}
                disabled={inputsDisabled}
                onChange={this.handleOptionsQuantityChange(
                  value.id,
                  value.allowed_quantities ? value.allowed_quantities.min : 1,
                  maxQuantity,
                  option.selectionId,
                  index
                )}
                min={value.allowed_quantities ? value.allowed_quantities.min : 1}
                max={maxQuantity <= 999 ? maxQuantity : 999}
              />) : null}

            <Input
              s={selectWidth}
              type="select"
              label="Gewenste gebruikersfunctionaliteiten"
              onChange={this.handleOptionsChange(
                value.id,
                value.quantity,
                maxQuantity,
                option.selectionId,
                index
              )}
              multiple={false}
              disabled={inputsDisabled}
              value={value.id}
            >
              <option key={-1} value={-1}>
                Niets geselecteerd
              </option>
              {option.products.map(this.renderOptionProduct)}
            </Input>

            {value.id !== -1 && maxQuantity > 1 ? (
              <Col s={2}>
                <button
                  className="btn doceri-btn-right ratio-btn-right"
                  disabled={inputsDisabled}
                  onClick={() => this.removeOption(index, value.id, option.selectionId)}
                >
                  Verwijder optie
                  <Icon right>
                    close
                  </Icon>
                </button>
              </Col>) : null
            }
          </Row>
        </Col>
      );

      rows.push(row);
    });

    return rows;
  };

  removeOption = (index, productId, selectionId) => {
    let allOptions = this.state.mobileProductOptions;
    const options = allOptions[selectionId];

    if (String(index).indexOf('-') !== -1) {
      index = index.substring(index.indexOf('-') + 1);
    }

    if (options[index]) {
      const foundIndex = options[index].selectedProducts.findIndex((selectedProduct) => selectedProduct && selectedProduct.id === productId);
      options[index].selectedProducts.splice(foundIndex, 1);

      allOptions[selectionId] = options;
      this.setState({mobileProductOptions: allOptions});
    }

    if (productId !== -1) {
      let selectedLocation = this.props.locations.selectedLocation.id;
      this.props.telephonyActions.updateMobileProducts(this.props.quotation.currentQuotation, selectedLocation,
        this.state.selectedMobileProducts, this._getLatestSelectedOptions());
    }
  };

  renderOptionProduct = ({name, id}) => {
    return (<option key={id} value={id}>
      {name}
    </option>)
  };

  getChildSelections = (selection) => {
    const selections = [];
    selection.selectedProducts.forEach((selectedProduct) => {
      if (selectedProduct.selection_id && this.state.mobileProductOptions[selectedProduct.selection_id]) {
        this.state.mobileProductOptions[selectedProduct.selection_id].forEach((element) => {
          element.selectionId = selectedProduct.selection_id;
          element.bundleSelectionQuantity = selection.bundleSelectionQuantity
            ? selection.bundleSelectionQuantity * selectedProduct.quantity : selectedProduct.quantity;
          selections.push(element);
        })
      }
    });

    return selections;
  };

  addOption = (index, option) => {
    option.selectedProducts.push({id: -1});

    let options = this.state.mobileProductOptions;
    options[option.selectionId][index] = option;

    this.setState({mobileProductOptions: options})
  };

  removeProfile = (selectionId) => {
    let selectionProductId = false;
    let value = '0';
    let bundleIsChanged = !selectionProductId;
    const productId = selectionProductId ? selectionProductId : +value;
    let currentSelectedProducts = this.props.selectedMobileProducts;

    currentSelectedProducts.forEach((obj, index) => {
      if (obj.id === selectionId) {
        if (productId === 0) {
          delete currentSelectedProducts[index];
        } else {
          currentSelectedProducts[index]['productId'] = productId;
          currentSelectedProducts[index]['quantity'] = 1;
          if (selectionProductId) {
            currentSelectedProducts[index]['quantity'] = value;
          }
        }
      }
    });

    currentSelectedProducts = currentSelectedProducts.filter(obj => obj.productId !== null);
    this.setState({selectedMobileProducts: currentSelectedProducts});
    let selectedLocation = this.props.locations.selectedLocation.id;

    this.props.telephonyActions.updateMobileProducts(
      this.props.quotation.currentQuotation,
      selectedLocation,
      currentSelectedProducts,
      this._getLatestSelectedOptions(bundleIsChanged ? selectionId : false)
    )
  };

  render() {
    let selectedMobileProducts = this.state.selectedMobileProducts;
    if (!selectedMobileProducts.length) {
      selectedMobileProducts = [{id: "", productId: ""}];
    }

    let mobileProductOptions = this.state.mobileProductOptions;
    const {inputsDisabled} = this.props;
    const {theme} = this.props.authentication;


    return (
      <div>
        {this.state.loading
          ? <LoaderComponent theme={theme}/>
          : <React.Fragment>
            <div className="row" id="Forms">
              {selectedMobileProducts.map((selection, indexSelection) => {
                return (
                  <Col s={12} key={indexSelection}>
                    <Row style={{paddingTop: '20px'}}>
                      <div className="line tussenkop">Stel hier het gewenste profiel in</div>
                    </Row>

                    <Row>
                      {mobileProductOptions[selection.id]
                      && (selection.allowed_quantities === null
                        || (selection.allowed_quantities && selection.allowed_quantities.max > 1)
                      ) ? (
                        <Input
                          s={1}
                          name='amount'
                          type='number'
                          label='Aantal'
                          disabled={inputsDisabled}
                          value={selection.quantity > 0 ? selection.quantity : 1}
                          onChange={this.onQuantityChangeCallback(
                            selection.id,
                            selection.productId
                          )}
                          min={selection.allowed_quantities ? selection.allowed_quantities.min : 1}
                          max={selection.allowed_quantities ? selection.allowed_quantities.max : 999}
                        />) : null}
                      <Input
                        s={mobileProductOptions[selection.id]
                        && (selection.allowed_quantities === null
                          || (selection.allowed_quantities && selection.allowed_quantities.max > 1)
                        ) ? 11 : 12}
                        name={"selection_offer_product"}
                        type='select'
                        label="Profielen"
                        disabled={inputsDisabled}
                        onChange={this.onChangeCallback(selection.id)}
                        value={selection.productId}
                        multiple={false}>
                        <option value={0}>Niets geselecteerd</option>
                        {
                          this.props.mobileProducts.map((el) => {
                            return <option key={el.name} value={el.id}>{el.name}</option>
                          })
                        }
                      </Input>
                    </Row>

                    <Row>
                      {mobileProductOptions[selection.id] && mobileProductOptions[selection.id].map((option, index) => {
                        option.selectionId = selection.id;
                        option.bundleSelectionQuantity = selection.quantity;

                        let extraSelect = [];
                        extraSelect.push(this.renderExtraSelect(option, index));
                        this.getChildSelections(option).forEach((childOption, childIndex) => {

                          let parentQuantity = 0;
                          option.selectedProducts.forEach((product) => {
                            parentQuantity += product.quantity;
                          });

                          childOption.bundleSelectionQuantity = parentQuantity;
                          extraSelect.push(this.renderExtraSelect(childOption, index + '-' + childIndex));
                        });

                        return extraSelect;
                      })
                      }
                    </Row>

                    {selection.id !== '' &&
                    <Row>
                      <Col style={{float: 'right'}}>
                        <button
                          name="removeProfile"
                          className={'btn ratio-btn white-text ratio-btn-right right ' + (inputsDisabled ? '' : 'doceri-btn-left')}
                          onClick={() => this.removeProfile(selection.id)}
                          disabled={inputsDisabled}
                        >
                          Verwijder profiel
                          <Icon right>
                            close
                          </Icon>
                        </button>
                      </Col>
                    </Row>
                    }
                  </Col>);
              })
              }

              <Col s={12}>
                <div className="line tussenkop"/>
              </Col>

              <Col style={{float: 'right'}}>
                <Row>
                  <button
                    name="addMobileProfile"
                    className={'btn ratio-btn white-text ratio-btn-right right ' + (inputsDisabled ? '' : 'doceri-btn-left')}
                    onClick={this.addNewProductSelection}
                    disabled={inputsDisabled}
                  >
                    Nog een profiel toevoegen
                    <Icon right>
                      add
                    </Icon>
                  </button>
                </Row>
              </Col>
            </div>

          </React.Fragment>
        }
      </div>
    )
  }
}

const mapStateToProps = ({quotation, authentication, locations, loading}) => {
  return {
    locations,
    quotation,
    quotationId: quotation.currentQuotation.id,
    authentication: authentication,
    loading
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    telephonyActions: bindActionCreators(telephonyActions, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationLocationLevel);
