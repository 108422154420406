import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import OrderListTable from './orderListTable';
import authenticationActions from "../../actions/authenticationActions";
import {DelayInput} from "react-delay-input";
import Pagination from '../../components/pagination/pagination';
import ordersActions from "../../actions/ordersActions";
import LoaderComponent from "../common/LoaderComponent";

class Orders extends Component {
  sortInitialState = {
    quotationName: false,
    createdAt: false,
    updatedAt: false
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedRow: null,
      searchPhrase: '',
      allOrders: [],
      sorting: {
        ...this.sortInitialState
      },
      page: 1,
      loading: true
    };
  };

  componentDidMount() {
    this.getAllOrders();
  };

  componentWillMount() {
    const hasAccess = this.props.authentication.ordering || this.props.authentication.isAdminFromDoceri;
    if (!hasAccess) {
      this.redirectToHome();
    }
  }

  changePage = ({target}) => {
    const {value} = target;
    this.setState({page: value});

    const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false);
    const sortValue = this.state.sorting[sortField];

    this.getAllOrders(sortField, sortValue, value, this.state.searchPhrase);
  };

  getAllOrders = (sortField, direction, page, searchQuery) => {
    let queryParams = '';
    if (sortField && direction) {
      queryParams += '?sortField=' + sortField;
      queryParams += '&sortDirection=' + direction;
    }

    if (page) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?')
      queryParams += 'page=' + page
    }

    if (searchQuery) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?')
      queryParams += 'searchQuery=' + searchQuery;
    }

    this.props.ordersActions.getOrdersData(queryParams)
      .then(() => {this.setState({allOrders: this.props.orders.orders})})
      .then(() => {this.setState({loading: false});
    });
  };

  redirectToHome = () => {
    this.props.history.push('/');
  };

  onSearch = ({target}) => {
    let {value} = target;

    const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false);
    const sortValue = this.state.sorting[sortField];

    this.setState({searchPhrase: value});

    this.getAllOrders(sortField, sortValue, this.state.page, value)
  };

  getHeaderPropertyForName = (displayName) => {
    const index = this.tableHeaderNames.indexOf(displayName);
    return this.tableObjectProperties[index];
  };

  getSortDirection = (propertyName) => {
    const sortDirection = this.state.sorting[propertyName];
    switch (sortDirection) {
      case false:
        return "ASC";
      case "ASC":
        return "DESC";
      case "DESC":
        return false
    }
  };

  changeSortDirection = (propertyName, direction) => {
    this.setState({sorting: {...this.sortInitialState, [propertyName]: direction}});

    this.getAllOrders(propertyName, direction, this.state.page, this.state.searchPhrase)
  };

  onSortChange = ({target}) => {
    const headerName = target.getAttribute('headername');
    const propertyName = this.getHeaderPropertyForName(headerName);

    if (!propertyName) {
      return;
    }

    this.changeSortDirection(propertyName, this.getSortDirection(propertyName));
  };

  onViewOrdersClick = (row) => {
    this.props.ordersActions.setCurrentQuotationOrder(row);
    this.props.ordersActions.setCurrentSection('quotation-orders');
  };

  tableHeaderNames = [
    '#',
    'Quotation name',
    'Customer (IRMA/ODIDO/YIP ID)',
    'Provider',
    'Message',
    'Aangemaakt',
    'Laaste update',
    'Acties'
  ];
  tableObjectProperties = [
    'quotationNumber',
    'quotationName',
    'customer',
    'provider',
    'message',
    'createdAt',
    'updatedAt',
    'actions'
  ];

  render() {
    let {searchPhrase, allOrders} = this.state;
    const { theme } = this.props.authentication;

    return (
      <div>
        {this.state.loading ?
          <LoaderComponent theme={theme}/> :

          <div>
            <div className="col s12">
              <div id="Forms" className="row ratiotable paddingbottomnone">
                <div>
                  <h1><span className="ratio-section-title">Order overzicht</span></h1>
                </div>
              </div>

              <div className="row overviewkopbg ratiotable ordersSearchDiv">
                <div className="col s12 m3">
                  <div className="overviewkop">
                    <form>
                      <div className="input-field margingone inner-addon">
                        <i className="glyphicon tiny material-icons left lightgrey">search</i>

                        <DelayInput
                          delayTimeout={800}
                          placeholder="Klant zoeken..."
                          id="search"
                          type="search"
                          name="searchPhrase"
                          value={searchPhrase}
                          onChange={this.onSearch}
                        />
                      </div>
                    </form>
                  </div>
                </div>

                <div className="col s12 m9">
                  <div className="overviewkop quotation-btns-inline">
                    <button
                      className="btn doceri-btn-right ratio-btn-right"
                      onClick={() => this.redirectToHome()}
                    >
                      <i className="small material-icons left white-text">add_shopping_cart</i>
                      Terug naar offerteoverzicht
                    </button>
                  </div>
                </div>
             </div>

              <div className="row">
                <OrderListTable
                  propertiesToShow={this.tableObjectProperties}
                  rows={allOrders}
                  onViewOrdersClick={this.onViewOrdersClick}
                  onHeaderClick={this.onSortChange}
                  sortingState={this.state.sorting}
                  getPropertyNameFromDisplayName={this.getHeaderPropertyForName}
                />
              </div>
            </div>

            <div className="col s12 sendendform">
              <div className="col s12 m10">
                <Pagination
                  page={this.props.orders.currentPage}
                  pages={this.props.orders.pages}
                  changePage={this.changePage}
                />
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = ({authentication, orders}) => {
  return {
    authentication,
    orders,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch),
    ordersActions: bindActionCreators(ordersActions, dispatch),
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));
