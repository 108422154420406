import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import actions from '../../actions/locationActions';

class LocationsSearch extends Component {

    onSearchChange = ({target}) => {
        const {value} = target;
        this.props.actions.searchLocationsList(value);
    };

    render() {
        let {searchPhrase} = this.props.locations;

        return (
            <div id="Locationsearch">
                <form>
                    <div className={'row'}>
                      <div className={'col s12'}>
                        <div className="input-field margingone inner-addon">
                          <i className="glyphicon tiny material-icons left lightgrey">search</i>
                          <input id="search" type="search" onChange={this.onSearchChange} value={searchPhrase} />
                          <label htmlFor="search">
                          </label>
                        </div>
                      </div>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = ({locations}) => {
    return {
        locations
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationsSearch);
