import {
    actionTypes
} from '../constants/actionTypes';

const initialState = {
    allDigitalSigningFields: [],
    currentSigningGroup: {}
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.getDigitalSigningFields:
            return {
                ...state,
                allDigitalSigningFields: action.payload
            };
        case actionTypes.setCurrentSigningGroup:
            return {
                ...state,
                currentSigningGroup: action.payload
            };
        case actionTypes.updateDigitalSigningField:
        case actionTypes.deleteDigitalSigningField:
            return {
                ...state,
                allDigitalSigningFields: state.allDigitalSigningFields.map((signingField) => signingField.id === action.payload.id ? action.payload : signingField )
            };
        default:
            return state;
    }
}
