import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import authenticationActions from "../../../actions/authenticationActions";
import OrdersOverviewPanel from "./OrdersOverviewPanel";
import QuotationOrders from "./quotationOrderProducts";
import adminOrdersActions from "../../../actions/adminOrdersActions";

class Orders extends Component {
    renderContent = () => {
        switch (this.props.adminOrders.currentSectionId) {
            case 'quotation-orders':
                return <QuotationOrders />;
            case 'orders':
                return <OrdersOverviewPanel />;
            default:
                return <OrdersOverviewPanel />;
        }
    };

    logout = () => {
        this.props.authenticationActions.logout();
    };

    switchOrganization = async ({target}) => {
        const organizationId = target.value;
        await this.props.authenticationActions.switchOrganization(organizationId);
        const {switchOrganization} = this.props.authentication;

        if (switchOrganization) {
            window.location.reload();
        }
    }

    render() {
        return (
            <div>
                <div className="valign-wrapper">
                    <div className="valign">
                        <div className="container maincontainer">
                            <div className="section">
                                <div className="row">
                                    {this.renderContent()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({authentication, adminOrders, alerts}) => {
    return {
        authentication,
        adminOrders,
        alerts
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        authenticationActions: bindActionCreators(authenticationActions, dispatch),
        adminOrdersAction: bindActionCreators(adminOrdersActions, dispatch),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));
