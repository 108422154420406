import {apiRoutes} from '../../constants/apiRoutes';
import {actionTypes} from "../../constants/actionTypes";
import ApiService from "../../services/apiService";
import RequestType from "../../constants/requestType";
import {toastr} from 'react-redux-toastr';

const updateTelephonyOptions = (quotationId, telephonyOptions) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.telephonyRelated.patchTelephonyOptions.replace(/{quotationId}/, quotationId);
      const response = await ApiService.performRequest(RequestType.PATCH, url, telephonyOptions);

      if (response) {
        dispatch(updateTelephonyOptionsState(response));
      }
    } catch (error) {
      toastr.error(error.message);
    }
  }
};

const updateTelephonyOptionsState = (telephonyOptions) => {
  return {
    type: actionTypes.updateTelephonyOptions,
    payload: {
      telephonyOptions
    }
  }
};

const getTelephonyOfferProducts = (quotationId, locationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.telephonyRelated.getTelephonyProducts
        .replace(/{quotationId}/, quotationId)
        .replace(/{locationId}/, locationId);

      const requestData = await ApiService.performRequest(RequestType.GET, url);
      dispatch(_getMobileOfferProductsSuccess(requestData));
    } catch (error) {
      toastr.error('RATIO Backend error', error.message);
    }
  }
};

const _getMobileOfferProductsSuccess = (mobileProducts) => {
  return {
    type: actionTypes.getMobileOfferProducts,
    payload: mobileProducts
  }
};

const updateMobileProducts = (quotation, locationId, products, options = {}) => {
  return async (dispatch) => {
    const url = apiRoutes.telephonyRelated.patchOfferProducts
      .replace(/{quotationId}/, quotation.id)
      .replace(/{locationId}/, locationId);

    try {
      const requestData = await ApiService.performRequest(RequestType.PATCH, url, {
        "products": products,
        "options": options
      });
      dispatch(updateTelephonyProductOptionsSuccess(requestData));
      dispatch(getTelephonyOfferProducts(quotation.id, locationId));

      if (requestData.warning) {
        toastr.warning('Warning', requestData.warning);
      }
    } catch (error) {
      toastr.error('RATIO Backend error', error.message);
    }
  }
};

const updateTelephonyProductOptionsSuccess = (mobileProductOptions) => {
  return {
    type: actionTypes.updateMobileOfferProductOptions,
    payload: mobileProductOptions
  }
};

const addNewProductSelection = (selection) => {
  return {
    type: actionTypes.addNewMobileProductSelection,
    payload: selection
  }
};

const updateCommunicationOptions = (quotationId, communicationOptions) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.communicationRelated.patchCommunicationOptions.replace(/{quotationId}/, quotationId);
      const response = await ApiService.performRequest(RequestType.PATCH, url, communicationOptions);

      if (response) {
        dispatch(updateCommunicationOptionsState(response));
      }
    } catch (error) {
      toastr.error(error.message);
    }
  }
};

const updateCommunicationOptionsState = (communicationOptions) => {
  return {
    type: actionTypes.updateCommunicationOptions,
    payload: {
      communicationOptions
    }
  }
};

const getTelephonyLocationOptions = (quotationId, locationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.telephonyRelated.getTelephonyLocationOptions
        .replace(/{quotationId}/, quotationId)
        .replace(/{locationId}/, locationId);
      const response = await ApiService.performRequest(RequestType.GET, url);

      if (response && response.selectedOptions) {
        dispatch(updateTelephonyLocationOptionsState(response.selectedOptions));
      }
    } catch (error) {
      toastr.error(error.message);
    }
  }
};

const updateTelephonyLocationOptions = (quotationId, locationId, data) => {
  return async (dispatch) => {
    const url = apiRoutes.telephonyRelated.patchTelephonyLocationOptions
      .replace(/{quotationId}/, quotationId)
      .replace(/{locationId}/, locationId);

    try {
      const response = await ApiService.performRequest(RequestType.PATCH, url, data);
      if (response && response.selectedOptions) {
        dispatch(updateTelephonyLocationOptionsState(response.selectedOptions));
      }
    } catch (error) {
      toastr.error('RATIO Backend error', error.message);
    }
  }
};

const updateTelephonyLocationOptionsState = (telephonyLocationOptions) => {
  return {
    type: actionTypes.updateTelephonyLocationOptions,
    payload: {
      telephonyLocationOptions
    }
  }
};

export default {
  updateTelephonyOptions,
  updateCommunicationOptions,
  getTelephonyOfferProducts,
  updateMobileProducts,
  addNewProductSelection,
  getTelephonyLocationOptions,
  updateTelephonyLocationOptions
}
