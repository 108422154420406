import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

import {apiRoutes} from '../constants/apiRoutes';
import {toastr} from 'react-redux-toastr'
import {actionTypes} from "../constants/actionTypes";

const getAttachmentFiles = (queryParams, providerId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.attachmentsRelated.getAttachments.replace(/{providerId}/, providerId) + queryParams;
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getAttachmentFilesSuccess(response.attachments));
      dispatch(setAttachmentsPagination(response.currentPage, response.resultsCount, response.pages))
    } catch (error) {
      toastr.error("Kan de bijlagen niet ophalen!", error.message);
    }
  }
};

const getAttachmentFilesSuccess = (response) => {
  return {
    type: actionTypes.getAttachments,
    response
  }
};

const setAttachmentsPagination = (currentPage, resultsCount, pages) => {
  return {
    type: actionTypes.setAttachmentFilesPagination,
    payload: {
      pages,
      currentPage,
      resultsCount
    }
  }
};

const deleteAttachment = (fileID) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.attachmentsRelated.deleteAttachments.replace(/{attachment}/, fileID);

      await ApiService.performRequest(RequestType.DELETE, url);

      dispatch(deleteAttachmentFileSuccess(fileID))
    } catch (error) {
      toastr.error("Kan de bijlage niet verwijderen.", error.message);
    }
  }
};

const deleteAttachmentFileSuccess = (fileID) => {
  return {
    type: actionTypes.deleteAttachment,
    payload: fileID
  }
};

const downloadAttachmentFile = (fileID) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.attachmentsRelated.downloadAttachment.replace(/{attachmentID}/, fileID)
      const response = await ApiService.performRequest(RequestType.GETFILE, url);

      dispatch(downloadAttachmentFileSuccess(response));

      return true;
    } catch (error) {
      toastr.error("Kan de bijlage niet downloaden.", error.message);
    }
  }
};

const downloadAttachmentFileSuccess = (response) => {
  return {
    type: actionTypes.downloadAttachmentFile,
    payload: response
  }
};

const addAttachmentFile = (data) => {
  return async () => {
    try {
      let url = apiRoutes.attachmentsRelated.addAttachment;
      return await ApiService.performRequest(RequestType.POST_MULTIPART, url, data);
    } catch (error) {
      toastr.error("Het uploaden van de bijlage is mislukt.", error.message);
    }
  }
};

const updateAttachmentFile = (data) => {
  return async () => {
    try {
      let url = apiRoutes.attachmentsRelated.updateAttachment.replace(/{attachment}/, data.id);
      return await ApiService.performRequest(RequestType.PUT, url, data);
    } catch (error) {
      toastr.error("Kan de bijlage niet bijwerken.", error.message);
    }
  }
};

const getAttachmentProductCategories = () => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.attachmentsRelated.getAttachmentProductCategories;
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getAttachmentProductCategoriesSuccess(response.productCategories));
    } catch (error) {
      toastr.error("Kan de productcategorieën niet ophalen", error.message);
    }
  }
};

const getAttachmentProductCategoriesSuccess = (response) => {
  return {
    type: actionTypes.setAttachmentProductCategories,
    payload: response
  }
};


export default {
  deleteAttachment,
  addAttachmentFile,
  getAttachmentFiles,
  updateAttachmentFile,
  downloadAttachmentFile,
  getAttachmentProductCategories
}