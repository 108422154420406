import QuotationType from '../constants/quotationType';

export default class HostedVoiceHelper {

  static callCenterSoftwareFieldsNames = [
    'callCenterAgentOption1',
    'callCenterAgentOption2',
    'callCenterAgentOption3'
  ];

  static callCenterAgentsFieldsNames = [
    'callCenterAgentRoleOption1',
    'callCenterAgentRoleOption2',
    'callCenterAgentRoleOption3',
  ];

  static userFieldNames = [
    'receptionistRoleCount',
    'callCenterAgentRoleOption1',
    'callCenterAgentRoleOption2',
    'callCenterAgentRoleOption3',
    'managerAssistantOption1',
    'managerAssistantOption2',
    'managerAssistantOption3',
    'desktopIntegration1',
  ];

  static agentSoftwareFieldsNames = [
    'callCenterAgentOption1',
    'callCenterAgentOption2'
  ];

  static managerFieldNames = [
    'managerAssistantOption1',
    'managerAssistantOption2',
  ];

  static licenseFieldNames = [
    'endPointLicenses',
    'usersPerPublicSwitch',
    'myConsole',
    'contactCenterAgent',
    'endpointLicenceOption1',
    'endpointLicenceOption2',
    'endpointLicenceOption3'
  ];

  static callReportingFieldNames = [
    'callReportingOption1',
    'callReportingOption2',
    'callReportingOption3',
  ];

  static callReportingProductNumbers = [
    '155A00004N',
    '155A00005N',
    '155A00006N',
    '155A00014N',
  ];

  static webexLicenceFieldNames = [
    'webexLicenceOption1',
    'webexLicenceOption2',
    'webexLicenceOption3',
  ];

  static addonsFieldsNames = [
    ...HostedVoiceHelper.callCenterSoftwareFieldsNames,
    ...HostedVoiceHelper.callCenterAgentsFieldsNames,
    ...HostedVoiceHelper.userFieldNames,
    ...HostedVoiceHelper.managerFieldNames,
    ...HostedVoiceHelper.agentSoftwareFieldsNames,
    ...HostedVoiceHelper.licenseFieldNames,
    ...HostedVoiceHelper.webexLicenceFieldNames,
  ];

  static productRelations = {
    'usersPerPublicSwitch': '101A00001N',
    'callCenterAgentRoleOption1': '026A00055N',
    'callCenterAgentRoleOption2': '026A00056N',
    'callCenterAgentRoleOption3': '026A00057N',
    'callCenterAgentOption1': '026A00058B',
    'callCenterAgentOption2': '026A00059B',
    'callCenterAgentOption3': '026A00060B',
    'managerAssistantOption1': '026A00061N',
    'managerAssistantOption2': '026A00062N',
    'managerAssistantOption3': '026A00064B',
    'callRecordingOption1': '026A00249N',
    'callRecordingOption2': '026A00250N',
    'callRecordingOption3': '026A00251N',
    'callRecordingOption4': '026A00252N',
    'callReportingOption1': '155A00004N',
    'callReportingOption2': '155A00005N',
    'callReportingOption3': '155A00006N',
    'callReportingOption4': '155A00014N',
    'callMeNow': '026A00050N',
    'faxToEmail': '101A00003N',
    'interactiveVoiceRespond': '101A00004N',
    'departmentVoicemail': '101A00005N',
    'webexLicenceOption1': '160A00006N',
    'webexLicenceOption2': '160A00004N',
    'webexLicenceOption3': '160A00005N',
    'desktopIntegration1': '026A00091B',
  };

  static validateHostedVoiceOptions(quotation, hostedVoiceOptions, currentMobileProductOptions, name, value, existingData, microsoftBusinessVoice) {

    // Only validate standard quotations
    if(! [QuotationType.TYPE_STANDARD, QuotationType.TYPE_QA, QuotationType.TYPE_VERLENGEN, QuotationType.TYPE_KOOP, QuotationType.TYPE_HUUR, QuotationType.TYPE_VERGELIJKING].includes(quotation.type)) {
      return true;
    }

    let mobileOptionsCount = 0;
    Object.keys(currentMobileProductOptions).forEach(function (key) {
      currentMobileProductOptions[key].map(option => {
        if(option.category.product_number === 'VASTMOBOPT')  {
          mobileOptionsCount += option.selectedProducts.map(product => product.id !== -1 ? parseInt(product.quantity) : 0)
            .reduce((prev, next) => prev + next, 0);
        }
      });
    });

    let maxUsers = name === 'usersPerPublicSwitch' ? parseInt(value) : parseInt(hostedVoiceOptions.usersPerPublicSwitch);
    mobileOptionsCount = name === 'mobileOptionsCount' ? parseInt(value) : mobileOptionsCount;
    maxUsers = maxUsers + microsoftBusinessVoice;
    
    let desktopIntegrationBasic = name === 'managerAssistantOption3' ? value : hostedVoiceOptions.managerAssistantOption3;
    let desktopIntegrationStandard = name === 'desktopIntegration1' ? parseInt(value) : parseInt(hostedVoiceOptions.desktopIntegration1);
    let desktopIntegrationPremium = name === 'callCenterAgentOption3' ? parseInt(value) : parseInt(hostedVoiceOptions.callCenterAgentOption3);
    let receptionCount = name === 'receptionistRoleCount' ? value : hostedVoiceOptions.receptionistRoleCount;

    if (existingData.length > 0) {
      maxUsers = maxUsers + HostedVoiceHelper.getExistingProductCount(HostedVoiceHelper.productRelations.usersPerPublicSwitch, existingData);
      desktopIntegrationBasic = desktopIntegrationBasic + HostedVoiceHelper.getExistingProductCount(HostedVoiceHelper.productRelations.managerAssistantOption3, existingData);
      desktopIntegrationStandard = desktopIntegrationStandard + HostedVoiceHelper.getExistingProductCount(HostedVoiceHelper.productRelations.desktopIntegration1, existingData);
      desktopIntegrationPremium = desktopIntegrationPremium + HostedVoiceHelper.getExistingProductCount(HostedVoiceHelper.productRelations.callCenterAgentOption3, existingData);
      mobileOptionsCount = mobileOptionsCount + HostedVoiceHelper.getExistingProductCount('101A00002N', existingData) + HostedVoiceHelper.getExistingProductCount('101A00006N', existingData);
    }

    const agentsCount = HostedVoiceHelper.sumFields(hostedVoiceOptions, name, value, HostedVoiceHelper.callCenterAgentsFieldsNames, existingData);
    const agentSoftwareCount = HostedVoiceHelper.sumFields(hostedVoiceOptions, name, value, HostedVoiceHelper.agentSoftwareFieldsNames, existingData);
    const managerCount = HostedVoiceHelper.sumFields(hostedVoiceOptions, name, value, HostedVoiceHelper.managerFieldNames, existingData);
    const webexLicenceCount = HostedVoiceHelper.sumFields(hostedVoiceOptions, name, value, HostedVoiceHelper.webexLicenceFieldNames, existingData);

    return (agentsCount <= (maxUsers + mobileOptionsCount))
      && (receptionCount <= maxUsers + mobileOptionsCount)
      && (agentSoftwareCount <= agentsCount)
      && (desktopIntegrationPremium <= (maxUsers + mobileOptionsCount))
      && (desktopIntegrationStandard <= (maxUsers + mobileOptionsCount))
      && (desktopIntegrationBasic <= (maxUsers + mobileOptionsCount))
      && (managerCount <= maxUsers + mobileOptionsCount)
      && (webexLicenceCount <= (maxUsers + mobileOptionsCount));
  };

  static sumFields = (hostedVoiceOptions, name, value, fields, existingData) => {
    return fields.reduce((acc, fieldName) => {
      let existingAmount = 0;
      let productNumber = HostedVoiceHelper.productRelations[fieldName];
      if (existingData.length > 0) {
        existingAmount = HostedVoiceHelper.getExistingProductCount(productNumber, existingData);
      }

      return acc + ((name === fieldName)
          ? parseInt(value, 10) + existingAmount
          : parseInt(hostedVoiceOptions[fieldName], 10) + existingAmount
      );
    }, 0);
  };

  static getExistingProductCount = (productNumber, existingData) => {
    return existingData.filter((el) => el.product_id === productNumber && el.terminate === null).length;
  };
}
