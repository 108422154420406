import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import DialogHeader from "../../common/KpnDialog/DialogHeader";
import DialogBody from "../../common/KpnDialog/DialogBody";
import KpnDialog from "../../common/KpnDialog/KpnDialog";
import DialogFooter from "../../common/KpnDialog/DialogFooter";
import DialogButton from "../../common/KpnDialog/DialogButton";
import adminActions from "../../../actions/adminActions";
import {Input} from "react-materialize";
import digitalSigningFieldActions from "../../../actions/digitalSigningFieldActions";
import DigitalSigningFieldGrid from "./DigitalSigningFieldGrid";
import {toastr} from "react-redux-toastr";

class DigitalSigningFieldPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogOpened: false,
            deleteDialogOpened: false,
            selectedDigitalSigningField: {},
            nameError: false
        };
    }

    componentDidMount() {
        this.props.actions.getAllDigitalSigningFields();
    };

    onEdit = (signingField) => {
        this.setState({
            dialogOpened: true,
            isEdit: true,
            selectedDigitalSigningField: signingField
        });
    };

    onAdd = () => {
        this.setState({
            dialogOpened: true,
            isEdit: false,
            selectedDigitalSigningField: {}
        })
    };

    closeDialog = () => {
        this.setState({
            dialogOpened: false
        });
    };

    submitDialog = () => {
        if (this.state.isEdit) {
            const {id, name, description} = this.state.selectedDigitalSigningField;

            return this.props.actions.updateDigitalSigningField(id, {name, description}).then(() => {
                this.closeDialog();
            });
        }

        const {currentSigningGroup} = this.props.digitalSigningFields;
        const data = {id: currentSigningGroup.id, ...this.state.selectedDigitalSigningField};

        return this.props.actions.addDigitalSigningField(data).then(() => {
            this.closeDialog();
            this.props.actions.getAllDigitalSigningFields();
        });
    };

    tagExist = (tagName) => {
        const {allDigitalSigningFields, currentSigningGroup} = this.props.digitalSigningFields;

        return allDigitalSigningFields.some((el) => {
            const formattedField = (field) => field.toLowerCase().replace(/\s+/g, '');
            const isSameOrganization = el.organization === this.props.authentication.company;
            const isSameDigitalSigningGroup = el.signing_group.id === currentSigningGroup.id;

            return formattedField(el.name) === formattedField(tagName) && isSameOrganization && isSameDigitalSigningGroup;
        });
    };

    onChange = ({target}) => {
        let {name, value} = target;

        this.setState({nameError: false});

        if (this.tagExist(value)) {
            this.setState({nameError: true});
            toastr.error('Existing tag. The tag should be unique.');
            return;
        }

        this.setState(prevState => ({
            selectedDigitalSigningField: {
                ...prevState.selectedDigitalSigningField,
                [name]: value
            }
        }));
    };

    onDeleteClick = (signingField) => {
        this.setState({
            deleteDialogOpened: true,
            selectedDigitalSigningField: signingField
        });
    };

    closeDeleteDialog = () => {
        this.setState({
            deleteDialogOpened: false
        });
    };

    onDelete = () => {
        this.props.actions.deleteSigningField(this.state.selectedDigitalSigningField).then(() => {
            this.props.actions.getAllDigitalSigningFields();
            this.closeDeleteDialog();
        });
    };

    onSortClick = (row) => {
        this.props.actions.sortDigitalSigningFields(row)
            .then(() => this.props.actions.getAllDigitalSigningFields());
    };

    goBack = () => {
        this.props.adminActions.setCurrentSection('digital-signing');
    };

    getHeaderPropertyForName = (displayName) => {
        const index = this.tableHeaderNames.indexOf(displayName);
        return this.tableObjectProperties[index];
    };

    tableHeaderNames = ['Veldnaam', 'Omschrijving', 'Tag', 'Aangemaakt', 'Laatst gewijzigd', 'Laatst gewijzigd door', 'Acties'];
    tableObjectProperties = ['name', 'description', 'tag', 'createdAt', 'updatedAt', 'createdBy'];

    render() {
        const {id, name} = this.props.digitalSigningFields.currentSigningGroup;
        const {allDigitalSigningFields} = this.props.digitalSigningFields;
        const filteredSigningFields = allDigitalSigningFields.filter((el) => el.signing_group.id === id);
        const isEdit = this.state.isEdit;

        return (
            <div>
                <div className="overviewkopbg ratiotable row">
                    <div className="col s3">
                        <div className="ratiotable paddingbottomnone">
                            <h1 className="ratio-section-title">Custom velden: {name}</h1>
                        </div>
                    </div>
                    <div className="col s9">
                        <button
                            className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
                            onClick={() => this.onAdd()}
                            type="submit"
                            name="action">
                            <i className="small material-icons left white-text">add</i>
                            Nieuwe veld
                        </button>

                        <button
                            className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
                            onClick={() => this.goBack()}
                            type="submit"
                            name="action">
                            Terug naar Custom Velden
                        </button>
                    </div>
                </div>

                <DigitalSigningFieldGrid
                    rows={filteredSigningFields}
                    getPropertyNameFromDisplayName={this.getHeaderPropertyForName}
                    headerNames={this.tableHeaderNames}
                    onEditClick={this.onEdit}
                    onDeleteClick={this.onDeleteClick}
                    onDelete={this.onDelete}
                    onSortClick={this.onSortClick}
                />

                <KpnDialog
                    dialogState={this.state.dialogOpened}
                    modal={false}
                    dialogHeader={(
                        <DialogHeader
                            headerIcon={isEdit ? 'edit' : 'add'}
                            headerText={`${isEdit ? 'Edit' : 'Add'} custom veld toevoegen`}
                            closeHandler={this.closeDialog}
                        />
                    )}
                    dialogBody={
                        <DialogBody>
                            <div className="row">
                                <Input
                                    s={12}
                                    className={this.state.nameError ? 'invalid' : 'valid'}
                                    value={this.state.selectedDigitalSigningField.name}
                                    onChange={this.onChange}
                                    label='Name'
                                    name='name'
                                    type='text'/>
                                <Input
                                    s={12}
                                    value={this.state.selectedDigitalSigningField.description}
                                    onChange={this.onChange}
                                    label='Description'
                                    name='description'
                                    type='text'/>
                            </div>
                        </DialogBody>
                    }
                    dialogFooter={
                        <DialogFooter
                            buttonLeft={(
                                <DialogButton
                                    buttonAction={this.closeDialog}
                                    buttonText='Close'
                                    left={true}
                                />
                            )}
                            buttonRight={(
                                <DialogButton
                                    buttonAction={this.submitDialog}
                                    buttonText='Submit'
                                />
                            )}
                        />
                    }
                />

                <KpnDialog
                    dialogState={this.state.deleteDialogOpened}
                    modal={false}
                    dialogHeader={(
                        <DialogHeader
                            headerIcon="close"
                            headerText="Delete the signing field"
                            closeHandler={this.closeDeleteDialog}
                        />
                    )}
                    dialogBody={
                        <DialogBody>
                            Are you sure you want to delete the signing field?
                        </DialogBody>
                    }
                    dialogFooter={
                        <DialogFooter
                            buttonLeft={(
                                <DialogButton
                                    buttonAction={this.closeDeleteDialog}
                                    buttonText='No'
                                    left={true}
                                />
                            )}
                            buttonRight={(
                                <DialogButton
                                    buttonAction={this.onDelete}
                                    buttonText='Yes'
                                />
                            )}
                        />
                    }
                />
            </div>
        )
    }
}

const mapStateToProps = ({digitalSigningFields, digitalSigningLog, authentication}) => {
    return {
        digitalSigningFields,
        digitalSigningLog,
        authentication
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(digitalSigningFieldActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch)
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DigitalSigningFieldPanel));
