import React, {Component} from 'react';
import {Col, Input, Row} from 'react-materialize';

import debounce from 'lodash/debounce';
import {toastr} from 'react-redux-toastr'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import accessActions from "../../../actions/workflow/accessActions";
import connectionsActions from "../../../actions/connectionsActions";
import locationActions from "../../../actions/locationActions";
import {getLocationOptionsThunk} from "../../../actions/workflow/isdnMigrationActions/getLocationOptionsThunk";

import ProviderHelper from "../../../helper/providerHelper";
import Organization from "../../../constants/organization";
import providerType from "../../../constants/providerType";

import LineSeperator from '../../../components/lineSeperator'
import GeneralDialog from "../../../containers/common/GeneralDialog";

import CustomHardwareWithCategories from '../../common/CustomHardwareWithCategories';

class ConnectionFormForAccessLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ftthOccupiedDialogVisible: true,
      validationExecId: this.props.execId,
      connectionOptions: this.props.locationOptions[this.props.connection.id] ? this.props.locationOptions[this.props.connection.id].options : [],
      availableExtraOptions: false,
      smartDiscount: 0.00,
      ntDependencyActive: this.props.access.ntDependencyActive || false,
      allowsNt: this.props.access.allowsNt || false,
      isOneTimeCostZero: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locationOptions !== this.props.locationOptions) {
      if (this.props.locationOptions[this.props.connection.id]) {
        this.setState({
          connectionOptions: this.props.locationOptions[this.props.connection.id].options,
        });
      }
    }

    if (prevProps.access.allowsNt !== this.props.access.allowsNt) {
      this.setState({allowsNt: this.props.access.allowsNt});
    }
    if (prevProps.access.ntDependencyActive !== this.props.access.ntDependencyActive) {
      this.setState({ntDependencyActive: this.props.access.ntDependencyActive});
    }
  }

  componentDidMount() {
    this.debounsedUpdateAccessProducts = debounce(this.updateConnectionOptions, 1000);

    this.setState({
      availableExtraOptions: this.props.location.allowed_hip || false,
      isOneTimeCostZero: this.props.connection.isOneTimeCostZero
    });

    const {providers} = this.props.authentication;
    let organization = providers && providers.length ? providers[0].organization : null;
    let isKpnRetail = organization ? organization.name === Organization.KPN_RETAIL : false;

    if (isKpnRetail) {
      this.props.connectionsActions.getSmartDiscount(this.props.connection.id).then((response) => {
        if (response) {
          this.setState({ smartDiscount: response });
        }
      });
    }

    this.props.connectionsActions.getNtDependency(this.props.connection.id);
    this.props.connectionsActions.getFtthConnectionCheck(this.props.connection.id, null);
  }

  static applySelectedProduct = (acc, current) => {
    if (current.selectedProducts.length > 0) {
      let rObj = {};
      current.selectedProducts.filter(obj => obj.id !== -1).forEach(obj => {
        //product already selected => sum quantities
        rObj[obj.id] = acc[obj.id] ? (acc[obj.id] + obj.quantity) : obj.quantity;
      });

      return {...acc, ...rObj};
    }

    return acc;
  };

  getSelectedProducts = (connectionOptions) =>
    connectionOptions.reduce(ConnectionFormForAccessLocation.applySelectedProduct, []);

  handleHideFtthOccupiedDialog = () => {
    this.setState({ftthOccupiedDialogVisible: false});
  };

  renderFtthOccupiedDialog = () => (
    <GeneralDialog
      dialogState={this.state.ftthOccupiedDialogVisible}
      onRequestClose={this.handleHideFtthOccupiedDialog}
      modal={true}
      headerIcon="add_location"
      headerText="Deze locatie heeft reeds een FTTH-verbinding"
      dialogBodyContent={(
        <Row>
          Deze locatie heeft reeds een FTTH-verbinding. Er kan slechts 1 FTTH-verbinding actief zijn per
          adres.
          De bestaande diensten dienen opgeheven te worden.
        </Row>
      )}
      rightButtonText={"Ik heb dit begrepen"}
      rightButtonAction={this.handleHideFtthOccupiedDialog}
      />
  );

  renderLocationOfferProduct = ({name, id}) => (
    <option key={id} value={id}>
      {name}
    </option>
  );

  handleConnectionOptionsChange = (productId, quantity = 1, max = null, maxSubProducts = null) => (event, value) => {
    const id = +value;
    let allSelectedProducts = this.getSelectedProducts(this.state.connectionOptions);
    const {selectedProducts, kortingMonths} = this.props.connectionData.kortingCategory;

    if (selectedProducts && selectedProducts.length) {
      allSelectedProducts = {...allSelectedProducts, [selectedProducts[0].id]: selectedProducts[0].quantity};
    }

    if (maxSubProducts) {
      const quantities = Object.values(allSelectedProducts).reduce((a, b) => a + b, 0);

      if (quantities > maxSubProducts) {
        toastr.error('Er kunnen niet meer producten worden toegevoegd - maximum bereikt.');
        return;
      }
    }

    if (allSelectedProducts[`${productId}`]) {
      delete allSelectedProducts[`${productId}`];
    }
    if (id !== -1) {
      const maximum = Math.min(max, maxSubProducts);
      if (maximum) {
        allSelectedProducts[`${id}`] = !allSelectedProducts[`${id}`] ? Math.min(quantity, maximum) : Math.min(quantity + allSelectedProducts[`${id}`], maximum);
      } else {
        allSelectedProducts[`${id}`] = !allSelectedProducts[`${id}`] ? quantity : quantity + allSelectedProducts[`${id}`];
      }
    }

    this.updateConnectionOptions(
      this.props.quotationId,
      this.props.location.id,
      this.props.connection.id,
        allSelectedProducts,
      this.props.pinSelected,
    ).then(() => {
      if (selectedProducts && selectedProducts.length && kortingMonths) {
        const data = {
          target: {
            value: kortingMonths
          }
        }

        this.handleKortingMonthsChange(data);
      }
    });
  };

  handleKortingProductChange = ({target}) => {
    let {value} = target;
    let selectedProducts = this.getSelectedProducts(this.state.connectionOptions);

    if (value !== -1) {
      selectedProducts = {...selectedProducts, [value]: 1};
    }

    this.updateConnectionOptions(
        this.props.quotationId,
        this.props.location.id,
        this.props.connection.id,
        selectedProducts,
        this.props.connectionData.pinSelected
    );
  }

  handleKortingMonthsChange = ({target}) => {
    const {value} = target;
    const {kortingCategory} = this.props.connectionData;
    const kortingProductSelected = kortingCategory.selectedProducts[0].id;

    this.props.connectionsActions.addKortingMonths(this.props.connection.id, {value, kortingProductSelected})
      .then(() => this.props.getLocationOptions(this.props.selectedLocationId));
  }

  onOneTimeCostChange = ({ target }) => {
    const {value, name} = target;

    this.props.connectionsActions.setOneTimeCostZero(this.props.connection.id, {[name]: value})
      .then(response => this.setState({ isOneTimeCostZero: response }));
  }

  handleConnectionPinChange = () => {
    let allSelectedProducts = this.getSelectedProducts(this.state.connectionOptions);
    const {selectedProducts, kortingMonths} = this.props.connectionData.kortingCategory;

    if (selectedProducts && selectedProducts.length) {
      allSelectedProducts = {...allSelectedProducts, [selectedProducts[0].id]: selectedProducts[0].quantity};
    }

    this.updateConnectionOptions(
      this.props.quotationId,
      this.props.location.id,
      this.props.connection.id,
      allSelectedProducts,
      !this.props.connectionData.pinSelected
    ).then(() => {
      if (selectedProducts && selectedProducts.length && kortingMonths) {
        const data = {
          target: {
            value: kortingMonths
          }
        }

        this.handleKortingMonthsChange(data);
      }
    });
  };

  async updateConnectionOptions(quotationId,locationId, connectionId, selectedProducts, pin) {
    await this.props.updateConnectionOptions(
      quotationId,
      locationId,
      connectionId,
      {
        productIds: {...selectedProducts},
        pin: pin
      },
    );
  };

  changeSmartDiscount = ({target}) => {
    let {value} = target;
    value = parseInt(value);

    if (value > 26 || (value % 2 !== 0)) {
      return;
    }

    this.props.connectionsActions.updateSmartDiscount(this.props.connection.id, value).then((response) => {
      if (response) {
        this.setState({ smartDiscount: response });
      }
    });
  };

  changeNtDependency = ({target}) => {
    let {value} = target;
    const {id, connection_selection_id} = this.props.connection;
    value = value === 'true';

    this.props.connectionsActions.updateNtDependency(id, value, connection_selection_id);
  };

  handleConnectionProductQuantityChange = (productId, productName, min, max, maxSubProducts) => (event, value) => {
    let quantity = +value;
    const selectedProducts = this.getSelectedProducts(this.state.connectionOptions);

    if (maxSubProducts && quantity > selectedProducts[`${productId}`]) {
      const quantities = Object.values(selectedProducts).reduce((a, b) => a + b, 0);

      if (quantities > maxSubProducts) {
        toastr.error('Er kunnen niet meer producten worden toegevoegd - maximum bereikt.');
        return;
      }
    }

    if (min && value < min) {
      quantity = min;
      event.target.value = min;
      toastr.error(productName + ' kan niet minder dan ' + quantity + ' keer geselecteerd worden.');
    }

    if (max && value > max) {
      quantity = max;
      event.target.value = max;
      toastr.error(productName + ' kan niet meer dan ' + quantity + ' keer geselecteerd worden.');
    }

    selectedProducts[`${productId}`] = quantity;
    this.debounsedUpdateAccessProducts(
      this.props.quotationId,
      this.props.location.id,
      this.props.connection.id,
        selectedProducts,
    );
  };

  handleExtraIPOptionChange = (productId) => (event, value) => {
    let quantity = +value;
    let selectedProducts = this.getSelectedProducts(this.state.connectionOptions);
    let connectionId = this.props.connection.id;
    let locationId = this.props.selectedLocationId;
    let quotationId = this.props.quotationId;

    selectedProducts[`${productId}`] = quantity;
    this.debounsedUpdateAccessProducts(
      this.props.quotationId,
      this.props.location.id,
      this.props.connection.id,
      selectedProducts,
    );
    this.props.accessActions.updateConnection('ipAddressCount', quantity, connectionId, locationId, quotationId);
  };

  renderExtraIPOptionField = (option) => {
    let {selectedProducts, products} = option;
    let presetProduct = products && products.length ? products : [{id: -1}];
    if (! (selectedProducts && selectedProducts.length)) {
      selectedProducts = presetProduct;
    }

    let connection = this.props.connection;
    let ipAddressCount = connection.ipAddressCount;
    let ipAddressRange = connection.ipAddressRange;

    let rows = [];
    let countSelects = 0;

    option.selectedProducts.forEach(value => countSelects += value.quantity);

    selectedProducts.forEach((selectedProduct) => {
      let currentQuantity = ipAddressCount ? ipAddressCount : (selectedProduct.quantity ? selectedProduct.quantity : ipAddressCount);

      let row = (
        <div key={`${option.category.name}-${selectedProduct.id}`} className={'col s12'} style={{marginBottom: '10px'}}>
          <Input
            s={12}
            className={'ratio-input'}
            type="select"
            label={'Aantal IP-adressen'}
            onChange={this.handleExtraIPOptionChange(selectedProduct.id)}
            multiple={false}
            value={currentQuantity}
          >
            {this.renderExtraIpOptions(ipAddressRange, currentQuantity)}
          </Input>
        </div>
      );

      rows.push(row);
    });

    return rows;
  };

  renderExtraIpOptions = (ipRange, currentQuantity) => {
    let options = [];

    if (currentQuantity === 0) {
      options.push(<option key={-1} value={0}>Niets geselecteerd</option>);
    }
    if (currentQuantity > 0 && currentQuantity < 8) {
      options.push(<option key={`ip-${currentQuantity}`} value={currentQuantity}>{currentQuantity} IP-adressen</option>);
    }

    ipRange.forEach((ipCount) => {
      options.push(<option key={`ip-${ipCount}`} value={ipCount}>{ipCount === 16 ? '8x Extra Public IP-adressen (Extra Kosten)' : '8x Gratis Public IP-adressen'}</option>)
    });

    return options;
  };

  renderOptionFields = (option, index) => {
    let rows = [];
    const {isSalesValue} = this.props.authentication;
    const {selectedProducts = [{id: -1}]} = option || {};
    const isExtraIPOption = option.category.product_number === 'EXIPADROPT';

    if (isExtraIPOption && !isSalesValue) {
      rows = this.renderExtraIPOptionField(option);
      return rows;
    }

    let value = {id: -1};

    const selectedProductsArray = selectedProducts && selectedProducts.length ? selectedProducts : [value];
    const maxSubproducts = option.category.max_subproducts;

    let countSelects = 0;
    option.selectedProducts.forEach(value => countSelects += value.quantity);
    const addButtonVisible = maxSubproducts === null || (maxSubproducts > 1 && countSelects < maxSubproducts);

    selectedProductsArray.forEach((selectedProduct, idx, {length}) => {
      const removeButtonVisible = maxSubproducts === null || maxSubproducts > 1;
      const allowedQuantities = selectedProduct.allowed_quantities;
      const selectWidth = removeButtonVisible && allowedQuantities ? 7 : removeButtonVisible ? 8 : allowedQuantities ? 11 : 12;

      let row = (
        <div key={`${option.category.name}-${selectedProduct.id}`} className={'col s12'} style={{marginBottom: length - 1 === idx ? '10px' : ''}}>
          {allowedQuantities ?
            (<Input
              s={1}
              style={{marginBottom: selectedProductsArray?.length > 1 ? '15px' : null}}
              type="number"
              label={idx === 0 ? 'aantal' : ''}
              value={selectedProduct.quantity > 0 ? selectedProduct.quantity : 1}
              onChange={this.handleConnectionProductQuantityChange(
                selectedProduct.id,
                selectedProduct.name,
                  allowedQuantities ? allowedQuantities.min : 1,
                  allowedQuantities ? allowedQuantities.max : 1,
                  maxSubproducts
              )}
              disabled={!allowedQuantities}
              min={allowedQuantities ? allowedQuantities.min : 1}
              max={allowedQuantities ? allowedQuantities.max : 1}
            />) : null
          }

          <Input
            s={selectWidth}
            className={selectedProductsArray?.length > 1 ? 'ratio-input' : ''}
            type="select"
            label={idx === 0 ? option.category.name : ''}
            onChange={this.handleConnectionOptionsChange(
              selectedProduct.id,
              selectedProduct.quantity,
              allowedQuantities ? allowedQuantities.max : null,
              maxSubproducts
            )}
            multiple={false}
            value={selectedProduct.id}
          >
            <option key={-1} value={-1}>
              Niets geselecteerd
            </option>
            {option.products.map(this.renderLocationOfferProduct)}
          </Input>

          {removeButtonVisible ?
            (<Col>
              <button
                className="btn doceri-btn-right ratio-btn-right"
                onClick={() => this.removeOption(selectedProduct.id, index)}
              >
                Verwijder
                <i className="tiny material-icons right white-text">clear</i>
              </button>
            </Col>) : null
          }

          {addButtonVisible && length - 1 === idx ?
            (<Col style={{ float: "right" }}>
              <button
                className="btn doceri-btn-right ratio-btn-right"
                onClick={() => this.addOption(index, option)}
              >
                extra optie
                <i className="tiny material-icons right white-text">add</i>
              </button>
            </Col>) : null
          }
        </div>
      );

      rows.push(row);
    });

    return rows;
  };

  addOption = (index, option) => {
    option.selectedProducts.push({id: -1});

    let options = this.state.connectionOptions;
    option[index] = option;

    this.setState({connectionOptions: options})
  };

  setHipOptions = () => {
    let availability = !this.state.availableExtraOptions;
    const quotationId = this.props.quotationId;
    const locationId = this.props.location.id;

    this.props.accessActions.setExtraOptionsAvailability(quotationId, availability, locationId).then(() => {
      this.props.locationActions.updateSelectedLocationInRedux('allowed_hip', availability);
      this.props.accessActions.getOptions(quotationId);
      this.props.connectionsActions.getConnectionsAvailabilityPerLocation(locationId);
    });

    this.setState({
      availableExtraOptions: availability
    });
  };

  removeOption = (productId, index) => {
    if ( productId === -1 ) {
      let options = this.state.connectionOptions;

      if (options[index]) {
        const foundIndex = options[index].selectedProducts.findIndex((selectedProduct) => selectedProduct.id === productId);
        options[index].selectedProducts.splice(foundIndex, 1);

        this.setState({connectionOptions: options});
      }
    }

    const selectedProducts = this.getSelectedProducts(this.state.connectionOptions);

    if (selectedProducts[`${productId}`]) {
      delete selectedProducts[`${productId}`];

      this.updateConnectionOptions(
          this.props.quotationId,
          this.props.location.id,
          this.props.connection.id,
          selectedProducts,
          this.props.pinSelected,
      );
    }
  };

  renderExtraSelect = (option, index) => {
    return (
      <Row key={index}>
        {this.renderOptionFields(option, index)}
      </Row>
    );
  };

  renderProductOptions = (product) => {
    let options = [];

    options.push(
        <option key={-1} value="">Selecteer</option>
    );

    options.push(
      <option key={product.id} value={product.order_id}>
        {product.upgrade_product_name ? product.upgrade_product_name : product.product_name}
      </option>
    );

    return options;
  };

  render() {
    const props = this.props;
    const {providers, isSalesValue} = this.props.authentication;
    const {connection, errors, currentLocation, isVerlengenType, existingAccessData, location } = props;
    const error = errors.find(error => error.connectionID === connection.id && error.success === false);
    const {kortingCategory} = this.props.connectionData;
    const {contractDuration, provider, inputsDisabled, isOwnBrand} = this.props.quotation;
    const isVodafoneQuotation = provider === providerType.VODAFONE;
    const is60MonthsQuotationContract = contractDuration === 60;
    const organization = providers && providers.length ? providers[0].organization : null;
    const isKpn = organization ? [Organization.KPN_RETAIL, Organization.SALES_VALUE].includes(organization.name) : false;
    const kortingProductValue = kortingCategory && kortingCategory.selectedProducts && kortingCategory.selectedProducts.length ? kortingCategory.selectedProducts[0].id : -1;
    const isKpnRetail = organization ? organization.name === Organization.KPN_RETAIL : false;
    const kortingMonthsValue = kortingCategory && kortingCategory.kortingMonths ? kortingCategory.kortingMonths : -1;

    let availability = this.state.availableExtraOptions;
    let allowedHip = this.props.access.organizationHip;
    let hasNLS3 = currentLocation.nls3;
    let isComplexAddress = currentLocation.is_complex_address;

    let containerName = `Connection with ID ${connection.id}`;
    if (connection.name === 'Primair' && isVerlengenType && location.fetched) {
      containerName = `Producten toevoegen aan een bestaande locatie`
    } else if (connection.name) {
      containerName = `Aansluitpunt ${connection.name}`
    }

    let displayNoOptionWarning = false;
    if (isOwnBrand) {
      displayNoOptionWarning = !props.hasConnectionOptions('selection_connection', connection.id);
    }

    return (
      <div>
        <Col s={12} className="linesplit" style={{marginBottom: 20}}>
          <LineSeperator/>
        </Col>
        <Col s={12} className="overviewkopbg">
          <div className="overviewkop">
            <i className="small material-icons left themeprimarycolor">playlist_add_check</i>
            { containerName }
          </div>
        </Col>
        <div className="col s12 blokborder overviewkopbg">
          <Row>
            <Input
              s={allowedHip || isKpnRetail ? 9 : 12}
              name={"selection_connection" + `-${connection.id}`}
              type='select'
              label="Connectie"
              onChange={(event) => props.onInputChange(event, connection.id)}
              value={connection.product ? connection.location_order_id : connection.selection_connection || ''}
              multiple={false}
              disabled={!!connection.product || inputsDisabled}
            >
              {
                connection.product ?
                  this.renderProductOptions(connection.product) :
                  props.generateDropdownOptions('selection_connection', connection.id)
              }
            </Input>
            <div className="col s3">
              { allowedHip &&
              <button
                className="btn doceri-btn-right s2"
                style={{float: 'right'}}
                name="activateHIPOptions"
                onClick={(event) => {this.setHipOptions()}}
                disabled={inputsDisabled}
              >
                { !availability ? 'Kies uit HIP verbindingen' : 'Kies uit menukaart verbindingen' }
              </button>
              }
            </div>
            { isKpnRetail &&
            <div className="input-field col s3">
              <i
                className="tiny material-icons left"
                style={{position: 'absolute', pointerEvents: 'none', paddingTop: '14px', paddingLeft: '5px'}}
              >
                euro_symbol
              </i>
              <input
                id='smartDiscount'
                type="number"
                value={this.state.smartDiscount}
                onChange={(e) => {this.changeSmartDiscount(e)}}
                min={0}
                max={26}
                step={2}
                style={{ paddingLeft: '25px' }}
                disabled={inputsDisabled}
              />
              <label htmlFor='smartDiscount'>Smart Migrations korting</label>
            </div>
            }
            {error && (<Col s={12}>
              <div className="error-small" style={{marginTop: -20}}>{error.message}</div>
                { (availability && allowedHip) &&
                  <div className="error-small">
                    De gekozen verbinding is geen menukaart verbinding. U wijkt dus af van de standaard.
                  </div>
                }
            </Col>)
            }
            {!error && (availability && allowedHip) &&
              <Col s={12}>
                <div className="error-small" style={{ marginTop: -20 }}>
                  De gekozen verbinding is geen menukaart verbinding. U wijkt dus af van de standaard.
                </div>
              </Col>
            }
            {displayNoOptionWarning && (
              <Col s={12}>
                <div className="error-small">Geen proposities beschikbaar op deze locatie volgens de huidig geldende business rules</div>
              </Col>
            )}
            {hasNLS3 && !isKpn && (
              <Col s={12}>
                <div className="error-small">Voor deze locatie is NLS3 van toepassing, er kunnen geen DSL producten besteld worden</div>
              </Col>
            )}
            { isComplexAddress && (
              <Col s={12}>
                <div className="error-small">
                  Er is gekozen voor ISRA = Complex adres. Dat houdt in dat er GEEN filtering op de beschikbare verbindingen is doorgevoerd in Ratio. Alles is beschikbaar gesteld
                </div>
              </Col>
            )
            }
          </Row>

          {connection.selection_connection && this.state.allowsNt && (
            <Row>
              <Input
                s={12}
                type="select"
                label="NT Levering en Installatie gewenst"
                name="ntDependency"
                onChange={(e) => {this.changeNtDependency(e)}}
                multiple={false}
                value={this.state.ntDependencyActive}
                disabled={inputsDisabled}
              >
                <option key={1} value={false}>Nee</option>
                <option key={2} value={true}>Ja</option>
              </Input>
            </Row>
          )}

          {isVodafoneQuotation && is60MonthsQuotationContract && connection.selection_connection &&
            <Row>
              <Input
                s={12}
                name="isOneTimeCostZero"
                type="select"
                label="Eenmalige installatiekosten op €0 zetten?"
                value={this.state.isOneTimeCostZero}
                onChange={this.onOneTimeCostChange}
                multiple={false}
                disabled={inputsDisabled}
              >
                <option key={0} value={false}>Nee</option>
                <option key={1} value={true}>Ja</option>
              </Input>
            </Row>
          }

          {this.state.connectionOptions.map((index, option) => this.renderExtraSelect(index, option))}

          {isSalesValue && connection.selection_connection &&
            <Row style={{marginTop: '30px'}}>
              <Input
                s={8}
                type="select"
                label="Kortings product toevoegen"
                onChange={this.handleKortingProductChange}
                multiple={false}
                value={kortingProductValue}
                disabled={inputsDisabled}
              >
                <option key={-1} value={-1}>
                  Niets geselecteerd
                </option>
                {kortingCategory ? kortingCategory.products.map(this.renderLocationOfferProduct) : []}
              </Input>

              <Input
                s={2}
                type="select"
                label="Aantal maanden korting"
                className={`validate ${kortingProductValue > 0 && kortingMonthsValue < 0 ? 'invalid' : ''}`}
                onChange={this.handleKortingMonthsChange}
                multiple={false}
                disabled={inputsDisabled || (kortingCategory ? !kortingCategory.selectedProducts.length : null)}
                value={kortingMonthsValue}
              >
                <option key={-1} value={-1}>Niets geselecteerd</option>
                <option key={1} value={3}>3 maanden</option>
                <option key={2} value={4}>4 maanden</option>
              </Input>
            </Row>
          }

          {ProviderHelper.hasModuleEnabled(this.props.quotation, 'customHardwareWithCategories') ?
            <CustomHardwareWithCategories type="access" connectionId={connection.id}/> : ''
          }

          {!connection.product && props.connectionData.pinAvailable ? <Row>
            <div className="col s12 height-50px reactnopaddingmargin">
              <Input
                key={this.props.selectedLocationId}
                s={12}
                name={"pinSelected"}
                type='checkbox'
                className='filled-in'
                label="Zakelijk pinnen"
                checked={props.connectionData.pinSelected ? 1 : 0}
                value={1}
                onChange={this.handleConnectionPinChange}
                disabled={inputsDisabled}
              />
            </div>
          </Row> : null}

        </div>
        {error && error.isFtthTaken ? this.renderFtthOccupiedDialog() : null}
      </div>
    );
  }
}


const mapStateToProps = ({locationOptions, access, authentication, connections}) => {
  return {
    access,
    locationOptions: locationOptions.data.connections,
    authentication,
    connections
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    locationActions: bindActionCreators(locationActions, dispatch),
    accessActions: bindActionCreators(accessActions, dispatch),
    connectionsActions: bindActionCreators(connectionsActions, dispatch),
    getLocationOptions: bindActionCreators(getLocationOptionsThunk, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionFormForAccessLocation);
