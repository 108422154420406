import React from 'react';

const DialogHeader = ({ headerIcon, headerText, headerSubtext, closeHandler, hasVerlengenAction, style }) => {
  return (
    <header className="md-dialog__header">
      <div className="md-dialog__header-title">
        <div className=" col s12 left-align" style={{marginBottom: hasVerlengenAction ? 20 : 0}}>
          <h1 style={style}>
            <span className="themeprimarycolor">
              {headerIcon && <i className="small material-icons left themeprimarycolor">{headerIcon}</i>}
              {headerText ? headerText : ''}
              {closeHandler ? (
                <button type="button" onClick={closeHandler} className="button-link right">
                  <i className="small material-icons themeprimarycolor" >close</i>
                </button>
              ) : null}
            </span> {headerSubtext}
          </h1>
        </div>
      </div>
    </header>
  );
};

export default DialogHeader;