import decode from 'jwt-decode';

export default class AuthenticationService {

    static getToken() {
        return localStorage.getItem('token')
    }

    static setToken(token) {
        localStorage.setItem('token', token)
    }

    static loggedIn() {
        const token = this.getToken();
        return !!token && !this.isTokenExpired(token);
    }

    static isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) 
            {
                return true;
            }
            else
            {
                return false;
            }
        }
        catch (err) {
            return false;
        }
    }

    static logout() {
        localStorage.removeItem('token');
    }

    static getProfile() {
        return decode(this.getToken());
    }

    static setTheme(theme) {
        const themeString = theme ? JSON.stringify(theme) : JSON.stringify({});
        localStorage.setItem('theme', themeString);
    }

    static getThemeFromLocalStorage() {
        const themeFromLS = localStorage.getItem('theme');
        const isValid = themeFromLS && themeFromLS.charAt(0) === '{' && themeFromLS.charAt(themeFromLS.length - 1) === '}';
        return themeFromLS && isValid ? JSON.parse(themeFromLS) : null;
    }
}