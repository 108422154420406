import React, {Component} from 'react';
import {connect} from 'react-redux';

import {DelayInput} from "react-delay-input";
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from "material-ui";
import styled from "styled-components";
import {Input} from "react-materialize";

import GeneralDialog from "../../../containers/common/GeneralDialog";

const CustomLabel = styled.div(
  {
    'label::before': {
      top: '9px !important',
      left: '7px'
    }
  }
);

class QuotationQuestionTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isModuleCollapsed: false,
      dropdownDialogOpened: false,
      selectedQuestion: null,
      specialConditions: []
    }
  }

  generateHeaders = () => {
    return (
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow>
          <TableHeaderColumn style={{width: '10%'}}>
            <div style={{fontWeight: 'bold'}}>Aantal</div>
            <div className="subthread" style={{display: 'inline-block'}}/>
          </TableHeaderColumn>
          <TableHeaderColumn style={{width: "60%"}}>
            <div style={{whiteSpace: 'break-word', fontWeight: 'bold'}}>Vraagstuk</div>
            <div className="subthread" style={{display: 'inline-block'}}/>
          </TableHeaderColumn>
          { this.props.showQALicences && (
            <TableHeaderColumn style={{position: 'unset'}}>
              <div style={{whiteSpace: 'break-word', fontWeight: 'bold'}}>Licentie</div>
              <div className="subthread" style={{display: 'inline-block'}}/>
            </TableHeaderColumn>
          )}
          { this.props.showQAPrices && (
            <TableHeaderColumn style={{position: 'unset'}}>
              <span style={{fontWeight: 'bold'}}>Eenmalige kosten</span>
              <div className="subthread"/>
            </TableHeaderColumn>
          )}
          { this.props.showQAPrices && (
            <TableHeaderColumn style={{position: 'unset'}}>
              <span style={{fontWeight: 'bold'}}>Prijs per maand</span>
              <div className="subthread"/>
            </TableHeaderColumn>
          )}
        </TableRow>
      </TableHeader>
    )
  };

  calculateTotalMonthlyPrice = (question, product) => {
    if (Array.isArray(product) && !product.length) {
      return 0;
    }

    let identifiedSelection = this.props.identifySelection(question.id);
    let currentAmount = identifiedSelection && identifiedSelection.quantity ? identifiedSelection.quantity : 1;

    return currentAmount * product.price_gross_monthly + product.price_gross_monthly_only_once;
  };

  calculateTotalOneTimePrice = (question, product) => {
    if (Array.isArray(product) && !product.length) {
      return 0;
    }

    let identifiedSelection = this.props.identifySelection(question.id);
    let currentAmount = identifiedSelection && identifiedSelection.quantity ? identifiedSelection.quantity : 1;

    return currentAmount * product.price_gross_onetime + product.price_gross_onetime_only_once;
  };

  onChange = (event, question) => {
    let {value, type, name, selectedIndex, options} = event.target;
    let product = null;
    let isCustomProduct = false;

    if (type === 'checkbox') {
      value = event.target.checked ? 1 : 0;
    }

    if (name === 'productSelector' && value !== '') {
      product = value;
      value = 1;

      isCustomProduct = options[selectedIndex].dataset['iscustom'] === 'true';

      this.setState({dropdownDialogOpened: false, selectedQuestion: null});
    }

    this.props.updateSelection(value, product, question, isCustomProduct);
  };

  onChangeDropdownCheckBox = ({target}, question) => {
    let {checked} = target;
    let identifiedSelection = this.props.identifySelection(question.id);

    this.setState({dropdownDialogOpened: !!checked, selectedQuestion: checked ? question : null});

    if (!checked && identifiedSelection && identifiedSelection.productID) {
      this.props.updateSelection(0, identifiedSelection.productID, question);
    }
  };

  renderAmount = (question) => {
    let identifiedSelection = this.props.identifySelection(question.id);
    let currentAmount = identifiedSelection ? identifiedSelection.quantity : 0;

    if (question.type === 'BOOLEAN') {
      return (
        <CustomLabel>
          <input
            name={'quantity'}
            className="input-field questionCheckBox"
            checked={currentAmount > 0}
            id={question.id}
            type="checkbox"
            onChange={event => this.onChange(event, question)}
            disabled={this.props.inputsDisabled}
          />
          <label htmlFor={question.id}/>
        </CustomLabel>
      );
    }

    if (question.type === 'DROPDOWN') {
      return (
        <CustomLabel>
          <input
            name={'dropdownCheckBox'}
            className="input-field questionCheckBox"
            checked={!!(identifiedSelection && (identifiedSelection.productID || identifiedSelection.customProductID))}
            id={question.id}
            type="checkbox"
            onChange={event => this.onChangeDropdownCheckBox(event, question)}
            disabled={this.props.inputsDisabled}
          />
          <label htmlFor={question.id}/>
        </CustomLabel>
      );
    }

    return (
      <DelayInput
        value={currentAmount}
        type="number"
        name="quantity"
        className="without-margin-bottom delayInputForLead"
        delayTimeout={1000}
        min={0}
        style={{width: '80%', height: '1.5rem'}}
        onChange={event => this.onChange(event, question)}
        disabled={this.props.inputsDisabled}
      />
    );
  };

  generateRelationProducts = (question) => {
    return question.dropdownOptions.map((option) => <option data-isCustom={option.isCustomProduct} value={option.id}>{option.name}</option>);
  };

  toggleCollapse = (name, value) => {
    this.setState({[name]: !value});
  };

  renderModuleTable = (module) => {
    return (
      <Table
        className="highlight without-margin-bottom questions-table"
        wrapperStyle={{overflow: 'initial'}}
        selectable={false}
        displayRowCheckbox={false}
      >
        {this.generateHeaders()}
        <TableBody displayRowCheckbox={false}>
          {
            module.questionData && module.questionData.map((questionSet) => this.renderProduct(module, questionSet, 'question'))
          }
        </TableBody>
      </Table>
    );
  };

  shouldRenderQuestionMessage = (type, currentSelection, condition) => {
    if (type === 'blocking' && currentSelection && currentSelection.isApproved) {
      return false;
    }
    if (!condition.conditionType) {
      return false;
    }

    let renderCondition = false;
    if (condition.conditionType === '>') {
      renderCondition = currentSelection ? currentSelection.quantity > condition.value : condition.value < 0;
    }
    if (condition.conditionType === '<') {
      renderCondition = currentSelection ? currentSelection.quantity < condition.value : condition.value > 0;
    }

    return renderCondition;
  };

  renderQuestionErrorMessages = (question) => {
    let currentSelection = this.props.identifySelection(question.id);
    let {blockCondition, commentCondition, warningCondition, specialCondition} = question;
    let processedSpecialCondition = {
      isBlocking: false,
      specialConditionMessage: ''
    };

    if (specialCondition) {
      processedSpecialCondition = this.handleSpecialConditions(specialCondition, currentSelection);
    }

    let isBlocking = this.shouldRenderQuestionMessage('blocking', currentSelection, blockCondition);

    if (isBlocking || processedSpecialCondition.isBlocking) {
      this.props.addInvalidQuestion(question.id)
    } else {
      this.props.removeInvalidQuestion(question.id)
    }

    return (
        <div className="question-error-messages-container">
          {
            isBlocking &&
            <span className="block-message">{question.blockMessage}</span>
          }
          {
            this.shouldRenderQuestionMessage('warning', currentSelection, warningCondition) &&
            <span className="warning-message">{question.warningMessage}</span>
          }
          {
            this.shouldRenderQuestionMessage('comment', currentSelection, commentCondition) &&
            <span className="comment-message">{question.commentMessage}</span>
          }
          {
            processedSpecialCondition.specialConditionMessage &&
            <span className={processedSpecialCondition.isBlocking ? 'block-message' : 'warning-message'}>
              {processedSpecialCondition.specialConditionMessage}
            </span>
          }
        </div>
    );
  };

  handleSpecialConditions = (specialCondition, currentSelection) => {
    let processedSpecialCondition = {
      isBlocking: false,
      specialConditionMessage: ''
    };
    let currentAmount = currentSelection ? currentSelection.quantity : 0;
    let { specialConditions } = this.state;

    if (! specialCondition) {
      return processedSpecialCondition;
    }

    if (!specialConditions.includes(specialCondition)) {
      this.setState({specialConditions: [...specialConditions, specialCondition]})
    }

    if (specialCondition === 'SPECIAL10') {
      let dependencyAmount = 0;
      ['DETRON61', 'DETRON63'].forEach((relation) => {
        let relationSelection = this.props.identifySelectionByRelation(relation);
        dependencyAmount += relationSelection ? relationSelection.quantity : 0;
      });

      dependencyAmount = Math.ceil(dependencyAmount/20);

      processedSpecialCondition.isBlocking = currentAmount > dependencyAmount;
      processedSpecialCondition.specialConditionMessage = `Je hebt minimaal ${dependencyAmount} basisstations nodig.`
    }

    return processedSpecialCondition;
  };

  renderProduct = (module, questionSet, type) => {
    let {question, product} = questionSet;

    return (
      <TableRow key={question.id + type}>
        <TableRowColumn style={{height: '28px', fontSize: '0.8rem', width: '10%', textAlign: 'center', overflow: 'visible'}}>
          {
            this.renderAmount(question)
          }
        </TableRowColumn>
        <TableRowColumn style={{width: "60%", whiteSpace: 'normal', wordWrap: 'break-word', height: '28px', fontSize: '13px'}}>
          <div className="question-container">
            {question.question}
            {this.renderQuestionErrorMessages(question)}
          </div>
        </TableRowColumn>
        { this.props.showQALicences && (
          <TableRowColumn style={{whiteSpace: 'normal', wordWrap: 'break-word', height: '28px', fontSize: '13px'}}>
            {question.licence}
          </TableRowColumn>
        )}
        { this.props.showQAPrices && (
          <TableRowColumn style={{textAlign: 'right', height: '28px', fontSize: '0.8rem'}}>
            {new Intl.NumberFormat('nl-NL', {
              style: 'currency',
              currency: 'EUR'
            }).format(this.calculateTotalOneTimePrice(question, product))}
          </TableRowColumn>
        )}
        { this.props.showQAPrices && (
          <TableRowColumn style={{textAlign: 'right', height: '28px', fontSize: '0.8rem'}}>
            {new Intl.NumberFormat('nl-NL', {
              style: 'currency',
              currency: 'EUR'
            }).format(this.calculateTotalMonthlyPrice(question, product))}
          </TableRowColumn>
        )}
      </TableRow>
    );
  };

  renderQuestionDropdown = () => {
    let question = this.state.selectedQuestion;
    if (!question) {
      return ;
    }

    let identifiedSelection = this.props.identifySelection(question.id);

    let currentSelected = identifiedSelection ? identifiedSelection.productID || identifiedSelection.customProductID : '';

    return (
      <CustomLabel>
        <Input
          s={12}
          name="productSelector"
          type="select"
          onChange={event => this.onChange(event, question)}
          value={currentSelected}
          multiple={false}
          disabled={this.props.inputsDisabled}
        >
          <option value="">{question.specialCondition === 'SPECIAL8' ? 'Geen CRM' : 'Kies een optie'}</option>
          {this.generateRelationProducts(question)}
        </Input>
      </CustomLabel>
    );
  };

  closeDropDownDialog = () => {
    this.setState({dropdownDialogOpened: false, selectedQuestion: null});
  };

  renderSpecialConditionHeaderMessage = () => {
    let {specialConditions} = this.state;

    if (specialConditions.includes('SPECIAL11')) {
      let special11QSelection = this.props.questionSelections.find((el) => el.specialCondition === 'SPECIAL11');

      if (special11QSelection) {
        return;
      }

      return (
          <div className="col s12 overviewkop" style={{fontSize: '14px', marginBottom: '10px', color: 'red'}}>
            Warning: Er dient minimaal 1 gebruikersprofiel te worden gekozen
          </div>
      );
    }

    if (specialConditions.includes('SPECIAL12')) {
      let special12QSelection = this.props.questionSelections.find((el) => el.specialCondition === 'SPECIAL12');

      if (special12QSelection) {
        return;
      }

      return (
          <div className="col s12 overviewkop" style={{fontSize: '14px', marginBottom: '10px', color: 'red'}}>
            Er dient minimaal 1 vaste nummer te worden gekozen
          </div>
      );
    }
  }

  render() {
    let {module} = this.props;
    let {isModuleCollapsed, dropdownDialogOpened, selectedQuestion} = this.state;

    return (
      <div className="ratiotable">
        <GeneralDialog
          dialogState={dropdownDialogOpened}
          style={{minHeight: 400}}
          onRequestClose={this.closeDropDownDialog}
          closeHandler={this.closeDropDownDialog}
          headerIcon="edit"
          overlfowException={true}
          modal={false}
          headerText={selectedQuestion ? selectedQuestion.question : ''}
          dialogBodyContent={this.renderQuestionDropdown()}
        />

        <div className="row">
          <div className="col s12 overviewkopbg">
            <div className="col s12 m8 overviewkop" style={{display: 'flex', padding: '10px', justifyContent: 'space-between', width: '100%'}}>
              <h4>
                <span className="ratio-section-title">
                  {module.category}
                </span>
              </h4>
              <button
                  className="btn doceri-btn-right ratio-btn-right doceri-btn-margin-right"
                  onClick={() => this.toggleCollapse('isModuleCollapsed', isModuleCollapsed)}
                  disabled={this.props.inputsDisabled}
              >
                <i className="small material-icons left white-text">unfold_more</i>
                {isModuleCollapsed ? 'Uitklappen' : 'Inklappen'}
              </button>
            </div>
            {
              this.renderSpecialConditionHeaderMessage()
            }
          </div>
          {
            !isModuleCollapsed && this.renderModuleTable(module)
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {}
};

const mapDispatchToProps = () => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotationQuestionTable);
