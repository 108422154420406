import {
    actionTypes
} from '../constants/actionTypes';

const initialState = {
    allDigitalSigningGroups: [],
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.getDigitalSigningGroups:
            return {
                ...state,
                allDigitalSigningGroups: action.payload
            };
        case actionTypes.patchDigitalSigningGroup:
        case actionTypes.deleteDigitalSigningGroup:
            return {
                ...state,
                allDigitalSigningGroups: state.allDigitalSigningGroups.map((signingGroup) => signingGroup.id === action.payload.id ? action.payload : signingGroup )
            };
        default:
            return state;
    }
}
