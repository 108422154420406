import React from 'react';
import {range} from "lodash";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import RegularSelect from "./RegularSelect";

const DatePickerComponent = props => {
  const years = props.noMinDate ? range(new Date('01-01-1920').getFullYear(), getYear(new Date()) + 15, 1) : range(new Date().getFullYear(), getYear(new Date()) + 15, 1);
  const months = [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December',
  ];

  return (
    <DatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
      }) => (
        <div style={{margin: 10, display: 'flex', justifyContent: 'center'}}>
          <RegularSelect
            name="datepicker"
            onChange={({ target: { value } }) => changeYear(value)}
            value={getYear(date)}
            multiple={false}
          >
            {years.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </RegularSelect>

          <RegularSelect
            name="datepicker"
            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
            value={months[getMonth(date)]}
            multiple={false}
          >
            {months.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </RegularSelect>
        </div>
      )}
        {...props}
    />
  );
};

export default DatePickerComponent;
