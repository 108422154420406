import React, {Component, Fragment} from 'react';
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";
import './index.css';
import GeneralDialog from "../../common/GeneralDialog";
import {Paper, TextField} from "material-ui";
import {Button} from "react-materialize";

class ChatDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: [],
      message: '',
    }
  }

  componentDidMount() {
    const {messages} = this.props;
    if (messages) {
      this.setState({
        messages: messages
      });
    }

  }

  onSubmit = (e) => {
    e.preventDefault();
    const message = (new FormData(e.target).get('message'));
    this.props.onMessageSubmit(e);

    const newMessage = {
      createdAt: 'Now',
      message: message,
      quotationId: '',
      userId: this.props.currentUserId,
      username: '',
      id: Math.floor(100000000 + Math.random() * 900000000)
    };
    this.setState({
      messages:  [newMessage, ...this.state.messages],
      message: ''
    })


  };

  setMessageValue = (e) => {
    this.setState({
      ...this.state.message,
      message: e.target.value
    })
  }


  render() {
    const {headerText, dialogOpen, onCancel, currentUserId} = this.props;
    const { messages } = this.state

    return (
      <div className="telephone-number-dialog">
        <GeneralDialog
          dialogState={dialogOpen}
          headerText={headerText}
          closeHandler={onCancel}
          dialogBodyContentRaw={
            <DialogBody>
              <Paper className="chat-body">
                {messages.map((message) =>
                      message && message.userId === currentUserId ?
                          <>
                            <div className="sender" key={message.id}>
                              <div className="message-blue">
                                <div>
                                  <div>
                                    <p className="message-content">{message.message}</p>
                                  </div>
                                </div>
                                <div className="message-time-receiver">{message.createdAt}</div>
                              </div>
                            </div>
                          </>
                      :
                          <>
                            <div className="receiver" key={message.id}>
                              <div className="receiver-body">
                                <div className="message-name">{message.username}</div>
                                <div className="message-orange">
                                  <div>
                                    <div>
                                      <p className="message-content">{message.message}</p>
                                    </div>
                                  </div>
                                  <div className="message-time-sender">{message.createdAt}</div>
                                </div>
                              </div>
                            </div>
                          </>
                )}
              </Paper>
              <>
                <form onSubmit={this.onSubmit} className="form" autoComplete="off" noValidate>
                  <TextField className="message-text" value={this.state.message} onChange={this.setMessageValue} label={'Message text'} name='message'/>
                  <Button>
                    <i className="small material-icons left themeprimarycolor">send</i>
                  </Button>
                </form>
              </>
            </DialogBody>
          }
        />

      </div>
    )
  }
}

export default ChatDialog;
