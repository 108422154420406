import { withTheme, injectGlobal } from 'styled-components';

const GlobalStyles = ({ theme }) => {
  injectGlobal`
    html {
      font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif";
    }
    body {
      position: relative;
      color: #4c4c4c;
      background-color: rgb(245, 245, 245);
      &:after {
        content: " ";
        display: block;
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: ${theme.backgroundOpacity || theme.backgroundOpacity === 0 ? theme.backgroundOpacity : 1};
        background-image: url("${theme.background}");
        background-color: ${theme.backgroundColor};
        background-size: cover;
      }
    }
    #root {
      position: relative;
      z-index: 2;
      overflow-x: hidden;
    }
    
    .doceri-btn-right, .btn {
      background-color: ${theme.primaryColor};
    }
    
      .doceri-btn-right:hover, btn:hover {
        background-color: ${theme.secondaryColor};
      }
    
    .select-wrapper ul li span {
      color: ${theme.mainTextColor} !important;
    }

    .select-wrapper ul li.disabled span {
      color: rgba(0, 0, 0, 0.3) !important;
      cursor: pointer;
    }
    
    .dropdown-content li > a, .dropdown-content li > span {
      color: ${theme.mainTextColor} !important;
    }
    
    .select-dropdown li.optgroup {      
      background: #eee;
      cursor: not-allowed;
    }
    
    .ratio-input > input.select-dropdown {
      margin-bottom: 15px !important;
    {
  `;

  return null;
};

export default withTheme(GlobalStyles);
