import React, {Component} from 'react';
import organizationProviderLogoActions from "../../../actions/organizationProviderLogoActions"
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import KpnDialog from "../../../containers/common/KpnDialog/KpnDialog";
import DialogHeader from "../../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";
import DialogFooter from "../../../containers/common/KpnDialog/DialogFooter";
import DialogButton from "../../../containers/common/KpnDialog/DialogButton";
import FileDownload from "js-file-download";
import OrganizationProvidersLogosGrid from "./OrganizationProvidersLogosGrid";
import OrganizationProvidersLogosForm from "./OrganizationProvidersLogosForm";
import {Input} from "react-materialize";
import ProviderHelper from "../../../helper/providerHelper";
import Pagination from "../../../components/pagination/pagination";

class OrganizationProvidersLogosPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formDialogOpened: false,
      deleteDialogOpened: false,
      selectedLogo: {},
      provider: this.props.organizationProviderLogo.provider,
      loading: false
    };
  }

  componentDidMount() {
    this.setState({provider: this.props.authentication.providers[0].provider})
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.provider !== this.state.provider) {
      this.getAllLogos();
    }
  }

  getAllLogos = (page) => {
    this.setState({loading: true});
    const {provider} = this.state;

    let queryParams = '';

    if (page) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?');
      queryParams += 'page=' + page
    }

    this.props.organizationProviderLogoActions.getLogos(queryParams, provider).then(() => this.setState({loading: false}));
  };

  openDialog = (logo) => {
    this.setState({
      formDialogOpened: true,
      selectedLogo: logo
    })
  };

  closeDialog = () => {
    this.setState({
      formDialogOpened: false
    })
  };

  closeDeleteDialog = () => {
    this.setState({
      deleteDialogOpened: false
    });
  };

  onDelete = (logo) => {
    this.setState({
      deleteDialogOpened: true,
      selectedLogo: logo
    });
  };

  onDeleteSubmit = () => {
    this.props.organizationProviderLogoActions.deleteLogoFile(this.state.selectedLogo.id);
    this.closeDeleteDialog();
  };

  onSubmit = (data) => {
    const oldData = {...data};
    delete data.id;
    if (oldData.id) {
      this.props.organizationProviderLogoActions.editLogoFile(oldData.id, data)
        .then(() => this.closeDialog())
        .then(() => this.getAllLogos());
    } else {
      this.props.organizationProviderLogoActions.addLogoFile(data)
        .then(() => this.closeDialog())
        .then(() => this.getAllLogos());
    }
  };

  downloadLogo = (file) => {
    const {id, name, documentType} = file;

    this.props.organizationProviderLogoActions.downloadLogo(id).then((response) => {
      if(response === true) {
        FileDownload(this.props.organizationProviderLogo.logoFileBlob, `${name}.${documentType}`);
      }
    });
  };

  handleChangeProvider = ({target}) => {
    const provider = target.value;

    this.setState({ provider: provider});
    this.props.organizationProviderLogoActions.setProvider(provider);
  };

  changePage = ({target}) => {
    const {value} = target;
    this.setState({page: value});


    this.getAllLogos(value);
  };

  render() {
    let {logos, newLogo, currentPage, pages} = this.props.organizationProviderLogo;

    const {providers} = this.props.authentication;

    return (
      <div>
        <div id="Forms" className="ratiotable paddingbottomnone">
          <h1 className="admin-subheader-text"><span className="ratio-section-title">Email Logos</span></h1>
          <button
            className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
            onClick={() => this.openDialog(newLogo)}
            type="submit"
            name="action">
            <i className="small material-icons left white-text">add</i>
            Voeg nieuw logo toe
          </button>
        </div>

        <div className="overviewkopbg ratiotable row">
          <div className="col s6">
            <div style={{ top: '25px', position: 'relative'}}>
              <Input
                type="select"
                id="provider"
                name="provider"
                required={true}
                multiple={false}
                defaultValue={-1}
                className="validate"
                label="Provider"
                onChange={(e) => this.handleChangeProvider(e)}
                value={this.state.provider}
              >
                <option value={-1} disabled={'disabled'} key={-1}>Kies provider</option>
                {
                  providers.filter((el => el.provider !== 1)).map((el) => {
                    return (
                      <option value={el.provider} key={el.provider}>
                        {ProviderHelper.getQuotationProviderName(el.provider)}
                      </option>
                    );
                  })
                }
              </Input>
            </div>
          </div>
        </div>

        <OrganizationProvidersLogosGrid
          onEditClick={this.openDialog}
          onDeleteClick={this.onDelete}
          onDownloadClick={this.downloadLogo}
          rows={logos}
          organizationId={this.props.authentication.organizationId}
        />

        <div className="col s12 sendendform">
          <div className="col s12 m10">
            <Pagination page={currentPage}
                        pages={pages}
                        changePage={this.changePage}
            />
          </div>
        </div>

        {this.state.formDialogOpened &&
        <OrganizationProvidersLogosForm
          logo={this.state.selectedLogo}
          onClose={this.closeDialog}
          onSubmit={this.onSubmit}
          providers={this.props.authentication.providers}
        />
        }

        <KpnDialog
          dialogState={this.state.deleteDialogOpened}
          onRequestClose={() => { this.closeDeleteDialog() }}
          modal={true}
          dialogHeader={(
            <DialogHeader
              headerIcon="remove_circle_outline"
              headerText="Bevestig verwijdering"
            />
          )}

          dialogBody={
            <DialogBody>
              <div className="col s12">
                {
                  this.state.selectedLogo?.default ?
                    "U staat op het punt een actief bestand te verwijderen. Weet u zeker dat u wilt doorgaan?" :
                    "U staat op het punt een inactief bestand te verwijderen. Weet u zeker dat u wilt doorgaan?"
                }
                <br /><br/>
              </div>
            </DialogBody>
          }
          dialogFooter={
            <DialogFooter
              buttonLeft={(
                <DialogButton
                  buttonAction={() => { this.closeDeleteDialog() }}
                  buttonText='Nee'
                  left={true}
                />
              )}
              buttonRight={(
                <DialogButton
                  buttonAction={this.onDeleteSubmit}
                  buttonText='Ja'
                />
              )}
            />
          }
        />
      </div>
    )
  }
}

const mapStateToProps = ({authentication, organizationProviderLogo}) => {
  return {
    authentication,
    organizationProviderLogo
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    organizationProviderLogoActions: bindActionCreators(organizationProviderLogoActions, dispatch)
  }
};

export default withRouter(connect(
  mapStateToProps, mapDispatchToProps
)(OrganizationProvidersLogosPanel));
