import statuses from "./quotationStatus";

const countryCodes = {
  'Afghanistan': 'AFG',
  'Albania': 'ALB',
  'Algeria': 'DZA',
  'American Samoa': 'ASM',
  'Andorra': 'AND',
  'Angola': 'AGO',
  'Anguilla': 'AIA',
  'Antarctica': 'ATA',
  'Antigua and Barbuda': 'ATG',
  'Argentina': 'ARG',
  'Armenia': 'ARM',
  'Aruba': 'ABW',
  'Australia': 'AUS',
  'Austria': 'AUT',
  'Azerbaijan': 'AZE',
  'Bahamas': 'BHS',
  'Bahrain': 'BHR',
  'Bangladesh': 'BGD',
  'Barbados': 'BRB',
  'Belarus': 'BLR',
  'Belgium': 'BEL',
  'Belize': 'BLZ',
  'Benin': 'BEN',
  'Bermuda': 'BMU',
  'Bhutan': 'BTN',
  'Bolivia': 'BOL',
  'Bonaire, Sint Eustatius and Saba': 'BES',
  'Bosnia and Herzegovina': 'BIH',
  'Botswana': 'BWA',
  'Bouvet Island': 'BVT',
  'Brazil': 'BRA',
  'British Indian Ocean Territory': 'IOT',
  'Brunei Darussalam': 'BRN',
  'Bulgaria': 'BGR',
  'Burkina Faso': 'BFA',
  'Burundi': 'BDI',
  'Cabo Verde': 'CPV',
  'Cambodia': 'KHM',
  'Cameroon': 'CMR',
  'Canada': 'CAN',
  'Cayman Islands': 'CYM',
  'Central African Republic': 'CAF',
  'Chad': 'TCD',
  'Chile': 'CHL',
  'China': 'CHN',
  'Christmas Island': 'CXR',
  'Cocos': 'CCK',
  'Colombia': 'COL',
  'Comoros': 'COM',
  'Congo (the Democratic Republic of the)': 'COD',
  'Congo': 'COG',
  'Cook Islands': 'COK',
  'Costa Rica': 'CRI',
  'Croatia': 'HRV',
  'Cuba': 'CUB',
  'Curacao': 'CUW',
  'Cyprus': 'CYP',
  'Czechia': 'CZE',
  "Cote d'Ivoire": 'CIV',
  'Denmark': 'DNK',
  'Djibouti': 'DJI',
  'Dominica': 'DMA',
  'Dominican Republic': 'DOM',
  'Ecuador': 'ECU',
  'Egypt': 'EGY',
  'El Salvador': 'SLV',
  'Equatorial Guinea': 'GNQ',
  'Eritrea': 'ERI',
  'Estonia': 'EST',
  'Eswatini': 'SWZ',
  'Ethiopia': 'ETH',
  'Falkland Islands': 'FLK',
  'Faroe Islands': 'FRO',
  'Fiji': 'FJI',
  'Finland': 'FIN',
  'France': 'FRA',
  'French Guiana': 'GUF',
  'French Polynesia': 'PYF',
  'French Southern Territories': 'ATF',
  'Gabon': 'GAB',
  'Gambia': 'GMB',
  'Georgia': 'GEO',
  'Germany': 'DEU',
  'Ghana': 'GHA',
  'Gibraltar': 'GIB',
  'Greece': 'GRC',
  'Greenland': 'GRL',
  'Grenada': 'GRD',
  'Guadeloupe': 'GLP',
  'Guam': 'GUM',
  'Guatemala': 'GTM',
  'Guernsey': 'GGY',
  'Guinea': 'GIN',
  'Guinea-Bissau': 'GNB',
  'Guyana': 'GUY',
  'Haiti': 'HTI',
  'Heard Island and McDonald Islands': 'HMD',
  'Holy See': 'VAT',
  'Honduras': 'HND',
  'Hong Kong': 'HKG',
  'Hungary': 'HUN',
  'Iceland': 'ISL',
  'India': 'IND',
  'Indonesia': 'IDN',
  'Iran': 'IRN',
  'Iraq': 'IRQ',
  'Ireland': 'IRL',
  'Isle of Man': 'IMN',
  'Israel': 'ISR',
  'Italy': 'ITA',
  'Jamaica': 'JAM',
  'Japan': 'JPN',
  'Jersey': 'JEY',
  'Jordan': 'JOR',
  'Kazakhstan': 'KAZ',
  'Kenya': 'KEN',
  'Kiribati': 'KIR',
  'North Korea': 'PRK',
  'South Korea': 'KOR',
  'Kuwait': 'KWT',
  'Kyrgyzstan': 'KGZ',
  "Lao People's Democratic Republic": 'LAO',
  'Latvia': 'LVA',
  'Lebanon': 'LBN',
  'Lesotho': 'LSO',
  'Liberia': 'LBR',
  'Libya': 'LBY',
  'Liechtenstein': 'LIE',
  'Lithuania': 'LTU',
  'Luxembourg': 'LUX',
  'Macao': 'MAC',
  'Madagascar': 'MDG',
  'Malawi': 'MWI',
  'Malaysia': 'MYS',
  'Maldives': 'MDV',
  'Mali': 'MLI',
  'Malta': 'MLT',
  'Marshall Islands': 'MHL',
  'Martinique': 'MTQ',
  'Mauritania': 'MRT',
  'Mauritius': 'MUS',
  'Mayotte': 'MYT',
  'Mexico': 'MEX',
  'Micronesia': 'FSM',
  'Moldova': 'MDA',
  'Monaco': 'MCO',
  'Mongolia': 'MNG',
  'Montenegro': 'MNE',
  'Montserrat': 'MSR',
  'Morocco': 'MAR',
  'Mozambique': 'MOZ',
  'Myanmar': 'MMR',
  'Namibia': 'NAM',
  'Nauru': 'NRU',
  'Nepal': 'NPL',
  'Netherlands': 'NLD',
  'New Caledonia': 'NCL',
  'New Zealand': 'NZL',
  'Nicaragua': 'NIC',
  'Niger': 'NER',
  'Nigeria': 'NGA',
  'Niue': 'NIU',
  'Norfolk Island': 'NFK',
  'Northern Mariana Islands': 'MNP',
  'Norway': 'NOR',
  'Oman': 'OMN',
  'Pakistan': 'PAK',
  'Palau': 'PLW',
  'Palestine': 'PSE',
  'Panama': 'PAN',
  'Papua New Guinea': 'PNG',
  'Paraguay': 'PRY',
  'Peru': 'PER',
  'Philippines': 'PHL',
  'Pitcairn': 'PCN',
  'Poland': 'POL',
  'Portugal': 'PRT',
  'Puerto Rico': 'PRI',
  'Qatar': 'QAT',
  'Republic of North Macedonia': 'MKD',
  'Romania': 'ROU',
  'Russian Federation': 'RUS',
  'Rwanda': 'RWA',
  'Reunion': 'REU',
  'Saint Barthelemy': 'BLM',
  'Saint Helena, Ascension and Tristan da Cunha': 'SHN',
  'Saint Kitts and Nevis': 'KNA',
  'Saint Lucia': 'LCA',
  'Saint Martin': 'MAF',
  'Saint Pierre and Miquelon': 'SPM',
  'Saint Vincent and the Grenadines': 'VCT',
  'Samoa': 'WSM',
  'San Marino': 'SMR',
  'Sao Tome and Principe': 'STP',
  'Saudi Arabia': 'SAU',
  'Senegal': 'SEN',
  'Serbia': 'SRB',
  'Seychelles': 'SYC',
  'Sierra Leone': 'SLE',
  'Singapore': 'SGP',
  'Sint Maarten': 'SXM',
  'Slovakia': 'SVK',
  'Slovenia': 'SVN',
  'Solomon Islands': 'SLB',
  'Somalia': 'SOM',
  'South Africa': 'ZAF',
  'South Georgia and the South Sandwich Islands': 'SGS',
  'South Sudan': 'SSD',
  'Spain': 'ESP',
  'Sri Lanka': 'LKA',
  'Sudan': 'SDN',
  'Suriname': 'SUR',
  'Svalbard and Jan Mayen': 'SJM',
  'Sweden': 'SWE',
  'Switzerland': 'CHE',
  'Syrian Arab Republic': 'SYR',
  'Taiwan': 'TWN',
  'Tajikistan': 'TJK',
  'Tanzania': 'TZA',
  'Thailand': 'THA',
  'Timor-Leste': 'TLS',
  'Togo': 'TGO',
  'Tokelau': 'TKL',
  'Tonga': 'TON',
  'Trinidad and Tobago': 'TTO',
  'Tunisia': 'TUN',
  'Turkey': 'TUR',
  'Turkmenistan': 'TKM',
  'Turks and Caicos Islands': 'TCA',
  'Tuvalu': 'TUV',
  'Uganda': 'UGA',
  'Ukraine': 'UKR',
  'United Arab Emirates': 'ARE',
  'United Kingdom of Great Britain and Northern Ireland': 'GBR',
  'United States Minor Outlying Islands': 'UMI',
  'United States of America': 'USA',
  'Uruguay': 'URY',
  'Uzbekistan': 'UZB',
  'Vanuatu': 'VUT',
  'Venezuela': 'VEN',
  'Viet Nam': 'VNM',
  'Virgin Islands (British)': 'VGB',
  'Virgin Islands (U.S.)': 'VIR',
  'Wallis and Futuna': 'WLF',
  'Western Sahara': 'ESH',
  'Yemen': 'YEM',
  'Zambia': 'ZMB',
  'Zimbabwe': 'ZWE',
  'Aland Islands': 'ALA',
};

export default countryCodes;

export const countries = Object.entries(countryCodes).map(entry => entry[0]);