import createReducer from '../createReducer.util';
import actionTypes from '../../constants/actionTypes';

const handlers = {
    [actionTypes.getLocationOptions.init]: (state) => ({
        ...state,
        loading: true,
    }),

    [actionTypes.getLocationOptions.success]: (state, {payload}) => ({
        ...state,
        loading: false,
        loaded: true,
        data: payload.data,
    }),

    [actionTypes.getLocationOptions.error]: (state, {payload}) => ({
        ...state,
        loading: false,
        loaded: false,
        error: payload.error,
    }),
};

const initialState = {
    loading: false,
    loaded: false,
    data: {connections: {}},
    error: null,
};

const getLocationOptionsReducer = createReducer(initialState, handlers);

export default getLocationOptionsReducer;
