import {
    actionTypes
} from '../constants/actionTypes';

const initialState = {
    discountTables: [],
    discountErrors: [],
    extraKwtCodes: [],
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.getDiscountState:
            return {
                ...state,
                discountTables: action.payload,
            };
        case actionTypes.updateDiscountStateSuccess:
            const { name, newValue, productsKey, productId, isSwapstock } = action.payload;
            let copiedTables = Object.assign({}, state.discountTables);
            let productToChange = copiedTables.data.find(x => x.name === productsKey).products.find(x => x.id === productId && x.is_swapstock === isSwapstock);

            const isPercentage = null !== name.match(/percentage/);
            const type = null !== name.match(/monthly/) ? 'monthly' : 'onetime';
            const productPrice = productToChange['price_gross_' + type];

            if (isPercentage) {
                productToChange['discount_percentage_' + type] = newValue;
                productToChange['discount_gross_' + type] = (productPrice - productPrice * (1-newValue/100)).toFixed(2);
            } else {
                productToChange['discount_gross_' + type] = newValue;
                productToChange['discount_percentage_' + type] = ((1 -(productPrice - newValue)/productPrice)*100).toFixed(2);
            }

            return {
                ...state,
                discountTables: copiedTables,
                discountState: action.payload
            };
        case actionTypes.getDiscountErrors:
            return {
                ...state,
                discountErrors: action.payload
            };
        case actionTypes.resetDiscountErrors:
            return {
                ...state,
                discountErrors: [],
            };
        case actionTypes.updateTotalsState:
            return {
                ...state,
                discountTables: {
                    ...state.discountTables,
                    totals: [...action.payload.totals]

                }
            };
        case actionTypes.updateKwtStateSuccess:
            const value = action.payload.newValue;

            return {
                ...state,
                kwtCode: {value, disabled: state.kwtCode.disabled}
            };

        case actionTypes.updateExtraKwtStateSuccess:

            return {
                ...state,
                discountTables: {
                    ...state.discountTables,
                    extraKwtCodes: action.payload.newValue
                }
            };
        default:
            return state;
    }
}
