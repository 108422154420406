import React, {Component} from 'react';
import {withRouter} from 'react-router'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import DigitalSigningMenu from '../components/digitalSigningMenu';
import quotationActions from '../actions/quotationActions';
import digitalSigningLogActions from '../actions/digitalSigningLogActions';
import authenticationActions from '../actions/authenticationActions';
import LoaderComponent from "./common/LoaderComponent";
import DigitalSigningOverview from "./DigitalSigning/digitalSigningOverview";
import DigitalSigningContent from "./DigitalSigning/digitalSigningContent";
import DigitalSigningConfirmation from "./DigitalSigning/digitalSigningConfirmation";
import DigitalSigningSignature from "./DigitalSigning/digitalSigningSignature";
import digitalSigningGroupActions from "../actions/digitalSigningGroupsActions";
import digitalSigningFieldActions from "../actions/digitalSigningFieldActions";
import styled from "styled-components";

import termsAndConditionsActions from "../actions/termsAndConditionsActions";

class DigitalSigning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quotationProducts: [],
      digitalSigningGroups: [],
      digitalSigningFields: [],
      passedConfirmation: false,
      wordDocuments: [],
      attachments: [],
      tcFiles: [],
      isOrganizationTermsAndConditionsEnabled: false,
      isDigitalSigningLogTermsSigned: false,
      isSubmitButtonDisabled: false
    };
  };

  componentDidMount() {
    let uuid = this.props.match.params.uuid;

    this.getDigitalSigningLog(uuid);
    this.props.digitalSigningLogActions.retrieveAttachmentDocuments(uuid).then((response) => {
      if (response && response.success) {
        this.setState({wordDocuments: response.documents, attachments: response.newDocuments});
      }
    });
    this.props.termsAndConditionsActions.retrieveTermsAndConditionsFiles(uuid).then((response) => {
      if (response && response.success) {
        this.setState({tcFiles: response.tcFiles});
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.digitalSigningLog.isSmsCodeConfirmed !== this.props.digitalSigningLog.isSmsCodeConfirmed) {
      this.setState({ passedConfirmation: this.props.digitalSigningLog.isSmsCodeConfirmed });
    }
  }

  redirectToHome = () => {
    this.props.history.push('/');
  };

  getDigitalSigningLog = (uuid) => {
    this.props.digitalSigningLogActions.getDigitalSigningLog(uuid).then((response) => {
      if (!response) {
        this.redirectToHome();
      }

      let digitalSigningLog = this.props.digitalSigningLog;

      this.setState({
        quotationProducts: digitalSigningLog.quotationSelectedProducts,
        digitalSigningGroups: digitalSigningLog.digitalSigningGroups,
        digitalSigningFields: digitalSigningLog.digitalSigningFields,
        passedConfirmation: digitalSigningLog.isSmsCodeConfirmed,
        isOrganizationTermsAndConditionsEnabled: digitalSigningLog.isTermsAndConditionsEnabled,
        isSubmitButtonDisabled: digitalSigningLog.isSubmitButtonDisabled,
        isDigitalSigningLogTermsSigned: digitalSigningLog.isDigitalSigningLogTermsSigned
      });
    });
  };

  signTermsAndConditions = ({target: { checked} }) => {
    const uuid = this.props.match.params.uuid;

    this.props.termsAndConditionsActions.signTermsAndConditions(uuid, checked)
        .then((response) => {
          if (response) {
            this.setState({isDigitalSigningLogTermsSigned: checked});
          }
        })
  }

  render() {
    const PreloaderParent = styled.div`
          .preloader-wrapper {
            width: 35px; 
            height: 35px; 
            display: flex;
          }
          
          .spinner-layer {
             border-color: white;
           }
        `;
    const {theme} = this.props.authentication;
    const {name, phoneNumber, shouldDisable} = this.props.digitalSigningLog.signingLog;
    const {quotationOrganization} = this.props.digitalSigningLog;
    const {processingDigitalSigningLogData} = this.props.loading;
    const organizationName = quotationOrganization ? quotationOrganization.name : null;

    let passedConfirmation = this.state.passedConfirmation;

    return (
      <div>
        {processingDigitalSigningLogData ?
          <LoaderComponent theme={theme}/> :
          <div>
            <div className="valign-wrapper">
              <div className="valign">
                <div className="container maincontainer">
                  <div className="section">
                    <div className="row" style={{marginBottom: '5px'}}>
                      <DigitalSigningMenu
                        contactPerson={name}
                        phoneNumber={phoneNumber}
                        company={organizationName}
                        theme={theme}
                      />
                    </div>

                    {passedConfirmation &&
                      <div className="row" style={{overflowX: 'visible', display: 'flex'}}>
                        <div className={'col s12 m12 l12'}>
                          <DigitalSigningConfirmation theme={theme}/>
                        </div>
                      </div>
                    }

                    {
                      shouldDisable && (
                        <fieldset style={{border: 'none', padding: 'unset', margin: 'unset'}}>
                          <div className={'Forms digitalSigningForm'}>
                            <div>
                              <h4 style={{paddingBottom: '2rem'}}>
                                <b>
                                  Geachte klant,
                                </b>
                              </h4>
                            </div>

                            <div className="row">
                              <span>
                                De offerte die u probeert goed te keuren is verouderd.
                              </span>
                            </div>

                            <div className="row">
                              <span>
                                Er is een nieuwere versie van de ontvangen offerte gemaakt.
                              </span>
                            </div>

                            <div className="row">
                              <span>
                                Wacht op de e-mail voor de nieuwere versie!
                              </span>
                            </div>

                            <div className="row">
                              <span>
                                Met vriendelijke groet,
                              </span>
                            </div>
                            <div className="row">
                              <img src={theme.logo}
                                   className="responsive-img app-header-logo-digital-signing" alt=""/>
                            </div>
                          </div>
                        </fieldset>
                        )
                    }

                    {!passedConfirmation && !shouldDisable &&
                        <div className="row digital-signing-content" style={{overflowX: 'visible'}}>
                          <div className={'col s12 m12 l12'}>
                            <DigitalSigningContent
                                digitalSigningDetails={this.props.digitalSigningLog.digitalSigningDetails}
                                digitalSigningGroups={this.state.digitalSigningGroups}
                                digitalSigningFields={this.state.digitalSigningFields}
                                signingLog={this.props.digitalSigningLog.signingLog}
                                attachmentDocuments={this.state.wordDocuments}
                                newAttachmentDocuments={this.state.attachments}
                                tcFiles={this.state.tcFiles}
                                PreloaderParent={PreloaderParent}
                            />
                          </div>
                          <div className={'col s12 m12 l12'}>
                            <DigitalSigningOverview
                                quotationProducts={this.state.quotationProducts}
                          />
                        </div>
                      </div>
                    }

                    {!passedConfirmation && !shouldDisable &&
                      <div className="row">
                        <div className={'col s12 m12 l12'}>
                          <DigitalSigningSignature
                            digitalSigningGroups={this.state.digitalSigningGroups}
                            digitalSigningFields={this.state.digitalSigningFields}
                            signingLog={this.props.digitalSigningLog.signingLog}
                            PreloaderParent={PreloaderParent}
                          />
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = ({digitalSigningLog, quotation, authentication, loading, digitalSigningGroups, digitalSigningFields}) => {
  return {
    digitalSigningLog,
    quotation,
    authentication,
    loading,
    digitalSigningGroups,
    digitalSigningFields
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    digitalSigningLogActions: bindActionCreators(digitalSigningLogActions, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
    authenticationActions: bindActionCreators(authenticationActions, dispatch),
    digitalSigningGroupActions: bindActionCreators(digitalSigningGroupActions, dispatch),
    digitalSigningFieldActions: bindActionCreators(digitalSigningFieldActions, dispatch),
    termsAndConditionsActions: bindActionCreators(termsAndConditionsActions, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DigitalSigning));
