import React, {Component} from 'react';
import {Input} from 'react-materialize'

class CustomHardwareOptions extends Component {

  render() {
    const {
      hardwareList,
      removeHardware,
      changeHardware,
      changeHardwareQuantity,
      hardware,
      index,
      category,
      inputsDisabled,
      selections
    } = this.props;

    return (
      <React.Fragment>
        {hardware.id &&
        <Input
          style={{marginBottom: selections?.length > 1 ? '15px' : null}}
          s={1}
          type="number"
          label={index === 0 ? 'aantal' : ''}
          value={hardware.quantity > 0 ? hardware.quantity : 1}
          onChange={(e) => {
            changeHardwareQuantity(e, index)
          }}
          min={1}
          max={999}
          disabled={inputsDisabled}
        />
        }

        <Input
          className={selections?.length > 1 && !(hardwareList.length && selections?.length - 1 === index) ? "ratio-input" : ''}
          s={hardware.id ? 7 : 8}
          name='product'
          type='select'
          label={index === 0 ? `Kies de gewenste ${category ? category.toLowerCase() : 'hardware'}` : ''}
          value={hardware.id}
          onChange={(e) => {
            changeHardware(e, index)
          }}
          disabled={inputsDisabled}
          multiple={false}
        >
          <option value="">Selecteer</option>
          {
            hardwareList.map(hardwareItem =>
              <option key={hardwareItem.id} value={hardwareItem.id}>
                {hardwareItem.name}
              </option>
            )
          }
        </Input>

        <div className="col">
          <button
            className="btn doceri-btn-right ratio-btn-right s2"
            onClick={() => removeHardware(index)}
            disabled={inputsDisabled}
          >
            Verwijder
            <i className="tiny material-icons right white-text">clear</i>
          </button>
        </div>
      </React.Fragment>
    )
  }
}

export default CustomHardwareOptions;
