import React, { Component } from 'react';
import DigitalSigningCustomFields from "./digitalSigningCustomFields";

class DigitalSigningCustomGroups extends Component {
    render() {
        const {digitalCustomGroup, digitalCustomFields, excludesDigitalSigning} = this.props;
        const customFieldsForGroup = digitalCustomFields.filter(option => option.signing_group.id === digitalCustomGroup.id);
        const needsTextSettings = digitalCustomGroup.textFieldSettings && digitalCustomGroup.textFieldSettings === 1;

        return (
            <div key={digitalCustomGroup.id}>
                <h1 className={'ratio-section-title'}>
                    <span className="ratio-section-title">
                        <i className="small material-icons left ratio-section-title">assignment_turned_in</i>
                    </span>{' '}
                    {digitalCustomGroup.name}
                </h1>

                <div>
                    {customFieldsForGroup.map((digitalCustomField, index) =>
                        <DigitalSigningCustomFields
                            digitalCustomField={digitalCustomField}
                            key={index}
                            excludesDigitalSigning={excludesDigitalSigning || false}
                            needsTextSettings={needsTextSettings}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default DigitalSigningCustomGroups;
