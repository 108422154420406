import {actionTypes} from '../constants/actionTypes';
import {apiRoutes} from '../constants/apiRoutes';
import {toastr} from 'react-redux-toastr'

import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

const getAllPostcodeCategories = (queryParams) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.postcodeCategoryRelated.getAll + (queryParams ? queryParams : '');
            let requestData = await ApiService.performRequest(RequestType.GET, url);

            dispatch(getAllPostcodeCategoriesSuccess(requestData));
            dispatch(savePaginationParameters(requestData))
        }
        catch ({message}) {
            toastr.error('Het is niet mogelijk de lijst met offertes op te halen', message);
        }
    }
};

const getAllPostcodeCategoriesSuccess = ({postcodeCategories}) => {
    return {
        type: actionTypes.getAllPostcodeCategories,
        payload: postcodeCategories
    };
};

const savePaginationParameters = ({currentPage, resultsCount, pages}) => {
    return {
        type: actionTypes.savePagination,
        payload: {
            currentPage,
            resultsCount,
            pages
        }
    }
};

const patchPostcodeCategory = (categoryId, data) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.postcodeCategoryRelated.patchPostcodeCategory.replace('{categoryId}', categoryId);

            const response = await ApiService.performRequest(RequestType.PATCH, url, data);

            toastr.success('Postcode categorie bewerkt', '');
            dispatch(_patchPostcodeSuccess(response));
        }
        catch ({message}) {
            toastr.error('Kan de postcode niet updaten', message);
        }
    }
};

const _patchPostcodeSuccess = ({postcodeCategory}) => {
    return {
        type: actionTypes.patchPostcodeCategory,
        payload: postcodeCategory
    }
};

const addPostcodeCategory = (data) => {
    return async () => {
        try {
            const url = apiRoutes.postcodeCategoryRelated.addPostcodeCategory;

            await ApiService.performRequest(RequestType.POST, url, data);

            toastr.success('Postcode categorie bewerkt', '');
        }
        catch ({message}) {
            toastr.error('Kan de postcode niet updaten', message);
        }
    }
};

const deletePostcodeCategory = (postcodeCategory) => {
    return async (dispatch) => {
        try
        {
            const url = apiRoutes.postcodeCategoryRelated.deletePostcodeCategory.replace('{categoryId}', postcodeCategory.id);
            const response = await ApiService.performRequest(RequestType.DELETE, url);

            toastr.success('Postcode category deleted', '');
            dispatch(deletePostcodeCategorySuccess(postcodeCategory));
        }
        catch ({message}) {
            toastr.error('Failed to delete postcode category', message);
        }
    }
};

const deletePostcodeCategorySuccess = ({postcodeCategory}) => {
    return {
        type: actionTypes.deletePostcodeCategory,
        payload: postcodeCategory
    }
};

export default {
    getAllPostcodeCategories,
    patchPostcodeCategory,
    addPostcodeCategory,
    deletePostcodeCategory
}
