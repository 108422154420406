import {actionTypes} from '../constants/actionTypes';

const initialState = {
    users: [],
    compensations: [],
    selectedUser: {},
    flashCalculations: []
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.getUsers:
            return {
                ...state,
                users: action.payload
            };
        case actionTypes.getCompensation:
            return {
                ...state,
                compensations: action.payload

            };
        case actionTypes.updateCompensation:
            let compensations = state.compensations.map(compensation => {
                if(compensation.id === action.payload.id && compensation.provider === action.payload.provider) {
                    compensation = action.payload
                }

                return compensation;
            });
            return {
                ...state,
                compensations
            };

        case actionTypes.addUser:
            return {
                ...state,
                users: [...state.users, action.payload]
            };
        case actionTypes.deleteUser:
            return {
                ...state,
                users: state.users.filter( user => user.id !== action.payload )
            };
        case actionTypes.editUser:
            return {
                ...state,
                users: state.users.map( (user) => user.id === action.payload.id ? action.payload : user )
            };
        case actionTypes.getCategories:
            return {
                ...state,
                categories: action.payload
            };
        case actionTypes.setCurrentUser:
            return {
                ...state,
                selectedUser: action.payload
            };
        case actionTypes.getFlashCalculations:
            return {
                ...state,
                flashCalculations: action.payload
            };
        default:
            return state;
    }
}
