import React, { Component } from 'react';

class Maintenance extends Component {
    render() {
        return (
            <article>
                <h1>We'll be back soon!</h1>
                <div>
                    <p>Sorry for the inconvenience but we're performing some maintenance at the moment.</p>
                </div>
            </article>
        );
    }
}

export default Maintenance;
