import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Pagination from "../../../components/pagination/pagination";
import {DelayInput} from "react-delay-input";
import DialogHeader from "../../common/KpnDialog/DialogHeader";
import DialogBody from "../../common/KpnDialog/DialogBody";
import KpnDialog from "../../common/KpnDialog/KpnDialog";
import DialogFooter from "../../common/KpnDialog/DialogFooter";
import DialogButton from "../../common/KpnDialog/DialogButton";
import {Input} from "react-materialize";
import PostcodeCategoriesManagementGrid from "./PostcodeCategoriesManagementGrid";
import postcodeCategoryActions from "../../../actions/postcodeCategoryActions";

class PostcodeCategoriesManagementPanel extends Component {
    sortInitialState = {
        name: false,
        createdAt: false,
        updatedAt: false
    };

    constructor(props) {
        super(props);

        this.state = {
            sorting: {
                ...this.sortInitialState
            },
            page: 1,
            searchPhrase: '',
            dialogOpened: false,
            selectedPostcodeCategory: {}
        };
    }

    componentDidMount() {
        this.getAllPostcodeCategories();
    };

    getAllPostcodeCategories = (sortField, direction, page, searchQuery) => {
        let queryParams = '';
        if (sortField && direction) {
            queryParams += '?sortField=' + sortField;
            queryParams += '&sortDirection=' + direction;
        }

        if (page) {
            queryParams += (queryParams.match(/\?/) ? '&' : '?')
            queryParams += 'page=' + page
        }
        if (searchQuery) {
            queryParams += (queryParams.match(/\?/) ? '&' : '?')
            queryParams += 'searchQuery=' + searchQuery;
        }

        this.props.actions.getAllPostcodeCategories(queryParams);
    };

    changePage = ({target}) => {
        const {value} = target;
        this.setState({page: value});

        const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false);
        const sortValue = this.state.sorting[sortField];

        this.getAllPostcodeCategories(sortField, sortValue, value, this.state.searchPhrase);
    };

    onSearch = ({target}) => {
        let {name, value} = target;

        const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false);
        const sortValue = this.state.sorting[sortField];

        this.setState({searchPhrase: value});

        this.getAllPostcodeCategories(sortField, sortValue, this.state.page, value)
    };

    onSortChange = ({target}) => {
        const headerName = target.getAttribute('headername');
        const propertyName = this.getHeaderPropertyForName(headerName);

        if (!propertyName) {
            return;
        }

        this.changeSortDirection(propertyName, this.getSortDirection(propertyName));
    };

    onEdit = (postcode) => {
        this.setState({
            dialogOpened: true,
            isEdit: true,
            selectedPostcodeCategory: postcode
        });
    };

    onAdd = () => {
        this.setState({
            dialogOpened: true,
            isEdit: false,
            selectedPostcodeCategory: {}
        })
    };

    closeDialog = () => {
        this.setState({
            dialogOpened: false
        });
    };

    submitDialog = () => {
        if (this.state.isEdit) {
            const {id, name, message} = this.state.selectedPostcodeCategory;
            this.props.actions.patchPostcodeCategory(id, {name, message}).then(() => {
                this.closeDialog();
            });
        } else {
            this.props.actions.addPostcodeCategory(this.state.selectedPostcodeCategory).then(() => {
                this.closeDialog();
                this.getAllPostcodeCategories();
            });
        }
    };

    onChange = ({target}) => {
        let {name, value} = target;

        this.setState(prevState => ({
            selectedPostcodeCategory: {
                ...prevState.selectedPostcodeCategory,
                [name]: value
            }
        }));
    };

    onDelete = (postcodeCategory) => {
        this.setState({
            selectedPostcodeCategory: postcodeCategory
        });

        this.props.actions.deletePostcodeCategory(postcodeCategory).then(() => {
            this.getAllPostcodeCategories();
        });
    };

    getSortDirection = (propertyName) => {
        const sortDirection = this.state.sorting[propertyName];
        switch (sortDirection) {
            case false:
                return "ASC";
            case "ASC":
                return "DESC";
            case "DESC":
                return false
        }
    };

    changeSortDirection = (propertyName, direction) => {
        this.setState({sorting: {...this.sortInitialState, [propertyName]: direction}});

        this.getAllPostcodeCategories(propertyName, direction, this.state.page, this.state.searchPhrase);
    };

    getHeaderPropertyForName = (displayName) => {
        const index = this.tableHeaderNames.indexOf(displayName);
        return this.tableObjectProperties[index];
    };

    tableHeaderNames = ['Categorie', 'Aangemaak', 'Bericht aan gebruiker', 'Laatst gewijzigd', 'Created by', 'Acties'];
    tableObjectProperties = ['name', 'createdAt', 'message', 'updatedAt', 'updatedBy', ''];

    render() {
        const {allPostcodeCategories, currentPage, pages} = this.props.postcodeCategories;
        const isEdit = this.state.isEdit;

        return (
            <div>
                <div id="Forms" className="ratiotable paddingbottomnone">
                    <h1 className="admin-subheader-text">
                        <span className="ratio-section-title">Postcode categories management</span>
                    </h1>
                </div>

                <div className="overviewkopbg ratiotable row">
                    <div className="col s3">
                        <div className="overviewkop">
                            <form>
                                <div className="input-field margingone inner-addon">
                                    <i className="glyphicon tiny material-icons left lightgrey">search</i>
                                    <DelayInput delayTimeout={800} id="search" type="search" name="searchPhrase"
                                                value={this.state.searchPhrase} onChange={this.onSearch}/>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col s9">
                        <button
                            className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
                            onClick={() => this.onAdd()}
                            type="submit"
                            name="action">
                            <i className="small material-icons left white-text">add</i>
                            Nieuwe postcode categorie
                        </button>
                    </div>
                </div>

                <PostcodeCategoriesManagementGrid
                    rows={allPostcodeCategories}
                    onHeaderClick={this.onSortChange}
                    sortingState={this.state.sorting}
                    getPropertyNameFromDisplayName={this.getHeaderPropertyForName}
                    headerNames={this.tableHeaderNames}
                    onEditClick={this.onEdit}
                    onDeleteClick={this.onDelete}
                />

                <KpnDialog
                    dialogState={this.state.dialogOpened}
                    modal={false}
                    dialogHeader={(
                        <DialogHeader
                            headerIcon={isEdit ? 'edit' : 'add'}
                            headerText={`${isEdit ? 'Edit' : 'Add'} postcode categorie`}
                            closeHandler={this.closeDialog}
                        />
                    )}
                    dialogBody={
                        <DialogBody>
                            <div className="row">
                                <Input
                                    s={12}
                                    value={this.state.selectedPostcodeCategory.name}
                                    onChange={this.onChange}
                                    label='Name'
                                    name='name'
                                    type='text'/>

                                <Input
                                    s={12}
                                    value={this.state.selectedPostcodeCategory.message}
                                    onChange={this.onChange}
                                    label='Message'
                                    name='message'
                                    type='text'/>
                            </div>
                        </DialogBody>
                    }
                    dialogFooter={
                        <DialogFooter
                            buttonLeft={(
                                <DialogButton
                                    buttonAction={this.closeDialog}
                                    buttonText='Close'
                                    left={true}
                                />
                            )}
                            buttonRight={(
                                <DialogButton
                                    buttonAction={this.submitDialog}
                                    buttonText='Submit'
                                />
                            )}
                        />
                    }
                />

                <div className="col s12 sendendform">
                    <div className="col s12 m10">
                        <Pagination page={currentPage}
                                    pages={pages}
                                    changePage={this.changePage}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({postcodeCategories}) => {
    return {
        postcodeCategories
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(postcodeCategoryActions, dispatch)
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostcodeCategoriesManagementPanel));
