import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";

import PropTypes from 'prop-types';
import CustomHardware from './CustomHardware';

import HardwareDependencyHelper from "../../helper/hardwareDependencyHelper";

class CustomHardwareWithCategories extends Component {
  static defaultProps = {isGrouped: true};

  render() {
    const {customHardwareOptions, customHardwareSelections, type, connectionId, inputsDisabled, isUsingExistingData, isVerlengenType} = this.props;
    let hardwareList = customHardwareOptions[this.props.type].filter(customHardwareOption => customHardwareOption.connection_id === connectionId);
    let categories = [...new Set(hardwareList.map(hardware => hardware.category))];

    let alreadySelected = HardwareDependencyHelper.getAlreadySelectedHardware(customHardwareSelections, type, connectionId);

    // Sort alphabetically
    categories = categories.sort();
    hardwareList = hardwareList.sort((a, b) => a.name.localeCompare(b.name));
    // Hide category RAT-2393
    categories = categories.filter((el) => {
      return !(el === 'Mobiele toestellen' && isUsingExistingData && isVerlengenType);
    });

    // Move Overige hardware category to last
    if (categories.indexOf('Overige hardware') > -1) {
      categories = categories.filter(category => category !== 'Overige hardware');
      categories.push('Overige hardware')
    }

    return (
      categories.map(category => {
        let hardwareListForCategory = hardwareList.filter(hardware => hardware.category === category && HardwareDependencyHelper.hasRelationDependencyMet(hardware, alreadySelected));

        if (!this.props.isGrouped) {
          return (
            <div id="Forms" className="col s12">
              <div className="col s12">
                <div>
                  <h1 className={'ratio-section-title'}>
                      <span className="ratio-section-title">
                          <i className="small material-icons left ratio-section-title">settings_input_hdmi</i>Hardware:
                      </span> gewenste {category}
                  </h1>
                </div>
              </div>

              <CustomHardware
                key={category}
                type={type}
                connectionId={connectionId}
                hardwareList={hardwareListForCategory}
                customHardwareSelections={customHardwareSelections}
                category={category}
                inputsDisabled={inputsDisabled}
              />
            </div>
          )
        }

        return (
          <CustomHardware
            key={category}
            type={type}
            connectionId={connectionId}
            hardwareList={hardwareListForCategory}
            customHardwareSelections={customHardwareSelections}
            category={category}
            inputsDisabled={inputsDisabled}
          />
        )
      })
    )
  }
}


CustomHardwareWithCategories.propTypes = {
  type: PropTypes.string.isRequired,
  connectionId: PropTypes.number,
  isGrouped: PropTypes.bool.isRequired
};

const mapStateToProps = ({hardware}) => {
  return {
    customHardwareOptions: hardware.customHardwareOptions || [],
    customHardwareSelections: hardware.customHardwareSelections || [],
  }
};

export default connect(mapStateToProps)(CustomHardwareWithCategories);
