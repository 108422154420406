import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Preloader } from 'react-materialize';
import Wrapper from './Wrapper';
import ThumbBox from './ThumbBox';
import CrossMark from './CrossMark';

class Thumb extends React.Component {
  state = {
    loading: false,
    thumb: undefined,
    currentImageAvailable: false,
  };

  /* componentWillReceiveProps(nextProps) {
    if (!nextProps.file) { return; }

    this.setState({ loading: true }, () => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result });
      };

      reader.readAsDataURL(nextProps.file);
    });
  } */

  componentDidMount() {
    if (this.props.currentImage) {
      this.checkImage(this.props.currentImage);
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.file) { return; }

    if (prevProps.file !== this.props.file) {
      this.setState({ loading: true }, () => {
        let reader = new FileReader();
  
        reader.onloadend = () => {
          this.setState({ loading: false, thumb: reader.result });
        };
  
        reader.readAsDataURL(this.props.file);
      });
    }
  }

  checkImage = (imageSrc) => {
    var img = new Image();
    img.onload = () => {
      this.setState({ currentImageAvailable: true });
    }; 
    img.onerror = () => {
      this.setState({ currentImageAvailable: false });
    };
    img.src = imageSrc;
  };

  render() {
    const { file, isValid, currentImage, crossed, label, width, height } = this.props;
    const { loading, thumb } = this.state;

    if (!file) {
      if (currentImage) {
        return (
          <Wrapper isValid={isValid}>
            <ThumbBox height={height}>
              <img
                src={currentImage}
                alt='logo'
                height={100}
                width={100}
                style={{ display: 'block', width: 'auto', height: '100%' }}
              />
            </ThumbBox>
            {crossed ? (<CrossMark>✕</CrossMark>) : null}
          </Wrapper>
        );
      }

      return (
        <Wrapper isValid={isValid}>
          <ThumbBox empty height={height}>
            <Icon small>file_upload</Icon>
            <span className="upload-label" style={{ width, wordSpacing: '100px' }}>
              {label}
            </span>
          </ThumbBox>
        </Wrapper>
      );
    }

    return (
      <Wrapper isValid={isValid}>
        <ThumbBox height={height}>
          {loading ? (
            <Preloader size='small'/>
          ) : (
            <img
              src={thumb}
              alt={file.name}
              height={100}
              width={100}
              style={{ display: 'block', width: 'auto', height: '100%' }}
            />
          )}
        </ThumbBox>
      </Wrapper>
    );
  }
}

Thumb.defaultProps = {
  currentImage: '',
  isValid: true,
  crossed: false,
  label: 'Upload een afbeelding',
  width: '100px',
  height: '100px',
};

Thumb.propTypes = {
  currentImage: PropTypes.string,
  isValid: PropTypes.bool,
  crossed: PropTypes.bool,
  label: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default Thumb;
