import React, { Component } from 'react';
import actions from '../actions/authenticationActions';
import {toastr} from 'react-redux-toastr'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

class PasswordForgotten extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: ''
        };
    }

    handleChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    };

    requestToken = async () => {
        let status = await actions.requestPasswordReset(this.state.username);
        if(status.success){
            toastr.success('Password sent');
            this.props.history.push('/login');
        }
    };


    render() {
        const { authentication } = this.props;

        return (
            <div className="valign-wrapper verticalvalign">
                <div className="valign">
                    <div className="container logincontainer">
                        <div className="section">
                            <div className="row">
                                <div id="Login" className="col s12">
                                    <div className="logocontainer-login col s12">
                                        <img src={authentication.theme.logo} width="200" height="200" alt="" />
                                    </div>
                                    {
                                        (
                                            <div>
                                                <div className="input-field col s12">
                                                    <input id="email" name="username" onChange={this.handleChange} onKeyPress={this.handleKeyPress} type="email" className="validate" />
                                                    <label htmlFor="email">e-mail</label>
                                                </div>
                                                <div className="col s6 offset-s6">
                                                    <button onClick={this.requestToken} className="btn doceri-btn-right ratio-btn-right" type="submit" name="action">verstuur wachtwoord</button>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({quotation, authentication}) => {
    return {
        authentication,
    }
};

export default connect(mapStateToProps)(PasswordForgotten);
