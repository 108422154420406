import ApiService from "../services/apiService";
import axios from "axios";

export const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

export const isElInput = (input) => !!(input && input.tagName && (input.tagName.toLowerCase() === 'input' || input.tagName.toLowerCase() === 'select'));

const polls = {};
const doPoll = async (resolve, reject, url, config) => {
  try {
    const response = await axios.get(ApiService.ROOT_URL + url, config);

    if (response.status === 200) {
      delete polls[url]
      return resolve(response.data);
    }

    if (response.status === 202) {
      polls[url] = setTimeout(() => doPoll(resolve, reject, url, config), 2000);
    }
  } catch (e) {
    delete polls[url]

    if (e.response.status !== 404) {
      return reject(e);
    }
  }
}

export const poll = (url, options = {}) => {
  const token = localStorage.getItem('token');
  const config = { headers: {'Authorization' : `Bearer ${token}`}, ...options };
  url = url.replace(/\/$/g, '');
  return new Promise((resolve, reject) => doPoll(resolve, reject, url, config));
}

export const uniquePoll = (url, options = {}) => {
  url = url.replace(/\/$/g, '');

  if (url in polls) {
    return Promise.reject()
  }

  return poll(url, options);
}

export const formatDate = (date) => {
  if (!date) {
    return '';
  }

  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};