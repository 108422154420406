import React, { Component } from 'react';
import {Input, Row, Col} from "react-materialize";

import KpnDialog from "../../../containers/common/KpnDialog/KpnDialog";
import DialogHeader from "../../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";

import FileUpload from "../../../components/fileUpload";
import ProviderHelper from "../../../helper/providerHelper";


class TermsAndConditionsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pdfDocument: {
                id: this.props.document.id || null,
                name: this.props.document.name,
                file: '',
                provider: this.props.document.provider || 0,
                isFileActive: this.props.document.isFileActive || false,
                productCategory: this.props.document.productCategory || ''
            },
            uploadErrors: ''
        }
    }

    componentDidMount() {
        const {document, providers} = this.props;
        if (! document.provider && providers.length) {
            this.setState({pdfDocument: {...this.state.pdfDocument, provider: providers[0].provider}});
        }
    }

    onChange = ({target}) => {
        let {name, value, type, checked} = target;

        if ('checkbox' === type) {
            value = checked ? 1 : 0;
        }

        this.setState(prevState => ({
            pdfDocument: {
                ...prevState.pdfDocument,
                [name]: value
            }
        }));
    };

    onDrop = (files) => {
        const file = files[0];

        if (file.type !== 'application/pdf') {
            this.setState({uploadErrors: 'Upload een geldig pdf-bestand!'});
            return;
        }

        this.setState(prevState => ({
            pdfDocument: {
                ...prevState.pdfDocument,
                file: file
            },
            uploadErrors: ''
        }));
    };

    onSubmit = (e) => {
        e.preventDefault();

        this.props.onSubmit(this.state.pdfDocument)
    };

    render() {
        const { pdfDocument } = this.state;
        const {providers, productCategories} = this.props;

        return (
            <KpnDialog
                dialogState={true}
                modal={true}
                onRequestClose={this.props.onClose}
                dialogHeader={(
                    <DialogHeader
                        headerIcon="person"
                        headerText="Terms and conditions"
                        closeHandler={this.props.onClose}
                    />
                )}
                dialogBody={
                    <DialogBody>
                        <form onSubmit={this.onSubmit}>
                            <Row>
                                <Input
                                    required
                                    s={12}
                                    defaultValue={pdfDocument.name}
                                    onChange={this.onChange}
                                    label="Naam"
                                    name='name'
                                    type='text'/>
                            </Row>
                            {!pdfDocument.id &&
                                <Row>
                                    <Input
                                      s={12}
                                      name={'provider'}
                                      id={'provider'}
                                      type="select"
                                      multiple={false}
                                      required={true}
                                      label={'Provider'}
                                      defaultValue={pdfDocument.provider}
                                      onChange={this.onChange}
                                      className="validate">
                                        <option value="-1" disabled={'disabled'} key={-1}>Kies provider</option>
                                        {providers.filter((el => el.provider !== 1 )).map((el) => <option value={el.provider} key={el.provider}>{ProviderHelper.getQuotationProviderName(el.provider)}</option>)}
                                    </Input>
                                </Row>
                            }

                            <Row>
                                <Input
                                  s={12}
                                  name={'productCategory'}
                                  id={'productCategory'}
                                  type="select"
                                  multiple={false}
                                  required={true}
                                  label={'Alleen van toepassing op deze productcategorie'}
                                  defaultValue={pdfDocument.productCategory}
                                  onChange={this.onChange}
                                  className="validate"
                                >
                                    <option value=""  key={'all-categories'}>Toepassen op alle productcategorieën</option>
                                    {productCategories.filter((productCategory) => productCategory.provider === parseInt(pdfDocument.provider))
                                      .map((productCategory) => {
                                          return (
                                            <option value={productCategory.value} key={productCategory.value}>
                                                {productCategory.name}
                                            </option>
                                          );
                                      })}
                                </Input>
                            </Row>

                            <Row>
                                <Input
                                  type="checkbox"
                                  label="Actief"
                                  name="isFileActive"
                                  id="isFileActive"
                                  checked={pdfDocument.isFileActive}
                                  onChange={this.onChange}
                                />
                            </Row>

                            {!pdfDocument.id &&
                            <Row>
                                <Col s={12} className="input-field">
                                    <FileUpload
                                        onDrop={this.onDrop}
                                        caption="Sleep uw bestand hier of klik voor upload. Let op, dit overschrijft het oude bestand"
                                    />

                                    {pdfDocument.file && <div>Geselecteerd bestand: {pdfDocument.file.name}</div>}
                                </Col>
                                {this.state.uploadErrors && (
                                    <Col s={12}>
                                        <span style={{color: 'red'}}>{this.state.uploadErrors}</span>
                                    </Col>
                                )}
                            </Row>
                            }

                            <button
                                className='btn doceri-btn-right ratio-btn-right-fullwidth right'
                                type="submit"
                                onClick={(e) => this.onSubmit(e)}
                            >
                                Submit
                            </button>
                        </form>
                    </DialogBody>
                }
            />
        )
    }
}

export default TermsAndConditionsForm;
