import React, {Component} from 'react';
import PostcodeManagementGrid from "./PostcodeManagementGrid";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Pagination from "../../../components/pagination/pagination";
import {DelayInput} from "react-delay-input";
import postcodeActions from "../../../actions/postcodeActions";
import DialogHeader from "../../common/KpnDialog/DialogHeader";
import DialogBody from "../../common/KpnDialog/DialogBody";
import KpnDialog from "../../common/KpnDialog/KpnDialog";
import DialogFooter from "../../common/KpnDialog/DialogFooter";
import DialogButton from "../../common/KpnDialog/DialogButton";
import {Input} from "react-materialize";
import adminActions from "../../../actions/adminActions";

class PostcodeManagementPanel extends Component {
    sortInitialState = {
        name: false,
        createdAt: false,
        updatedAt: false,
        category: false
    };

    constructor(props) {
        super(props);

        this.state = {
            sorting: {
                ...this.sortInitialState
            },
            page: 1,
            searchPhrase: '',
            dialogOpened: false,
            selectedPostcode: {}
        };
    }

    componentDidMount() {
        this.getAllPostcodes();
        this.props.actions.getCategoriesToLink();
    };

    getAllPostcodes = (sortField, direction, page, searchQuery) => {
        let queryParams = '';
        if (sortField && direction) {
            queryParams += '?sortField=' + sortField;
            queryParams += '&sortDirection=' + direction;
        }

        if (page) {
            queryParams += (queryParams.match(/\?/) ? '&' : '?')
            queryParams += 'page=' + page
        }
        if (searchQuery) {
            queryParams += (queryParams.match(/\?/) ? '&' : '?')
            queryParams += 'searchQuery=' + searchQuery;
        }

        this.props.actions.getAllPostcodes(queryParams);
    };

    changePage = ({target}) => {
        const {value} = target;
        this.setState({page: value});

        const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false);
        const sortValue = this.state.sorting[sortField];

        this.getAllPostcodes(sortField, sortValue, value, this.state.searchPhrase);
    };

    onSearch = ({target}) => {
        let {name, value} = target;

        const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false);
        const sortValue = this.state.sorting[sortField];

        this.setState({searchPhrase: value});

        this.getAllPostcodes(sortField, sortValue, this.state.page, value)
    };

    onSortChange = ({target}) => {
        const headerName = target.getAttribute('headername');
        const propertyName = this.getHeaderPropertyForName(headerName);

        if (!propertyName) {
            return;
        }

        this.changeSortDirection(propertyName, this.getSortDirection(propertyName));
    };

    onEdit = (postcode) => {
        this.setState({
            dialogOpened: true,
            isEdit: true,
            selectedPostcode: postcode
        });
    };

    onAdd = () => {
        this.setState({
            dialogOpened: true,
            isEdit: false,
            selectedPostcode: {}
        })
    };

    getPostcodeCategories = () => {
        this.props.adminActions.setCurrentSection('postcode-categories-management');
    };

    closeDialog = () => {
        this.setState({
            dialogOpened: false
        });
    };

    submitDialog = () => {
        if (this.state.isEdit) {
            const {id, name, category, description} = this.state.selectedPostcode;
            const postcode = name;

            this.props.actions.patchPostcode(id, {postcode, category, description}).then(() => {
                this.closeDialog();
            });
        } else {
            this.props.actions.addPostcode(this.state.selectedPostcode).then(() => {
                this.closeDialog();
                this.getAllPostcodes();
            });
        }
    };

    onChange = ({target}) => {
        let {name, value} = target;

        if (this.state.isEdit && name === 'postcode') {
            name = 'name';
        }

        this.setState(prevState => ({
            selectedPostcode: {
                ...prevState.selectedPostcode,
                [name]: value
            }
        }));
    };

    onDelete = (postcode) => {
        this.setState({
            selectedPostcode: postcode
        });

        this.props.actions.deletePostcode(postcode).then(() => {
            this.getAllPostcodes();
        });
    };

    getSortDirection = (propertyName) => {
        const sortDirection = this.state.sorting[propertyName];
        switch (sortDirection) {
            case false:
                return "ASC";
            case "ASC":
                return "DESC";
            case "DESC":
                return false
        }
    };

    changeSortDirection = (propertyName, direction) => {
        this.setState({sorting: {...this.sortInitialState, [propertyName]: direction}});

        this.getAllPostcodes(propertyName, direction, this.state.page, this.state.searchPhrase)
    };

    getHeaderPropertyForName = (displayName) => {
        const index = this.tableHeaderNames.indexOf(displayName);
        return this.tableObjectProperties[index];
    };

    tableHeaderNames = ['Categorie', 'Omschrijving', 'Postcode', 'Aangemaak', 'Laatst gewijzigd', 'Laatst gewijzigd door', 'Acties'];
    tableObjectProperties = ['category', 'description', 'postcode', 'createdAt', 'updatedAt', 'updatedBy', ''];

    render() {
        const {allPostcodes, categoriesToLink, currentPage, pages} = this.props.postcodes;

        const isEdit = this.state.isEdit;

        return (
            <div>
                <div id="Forms" className="ratiotable paddingbottomnone">
                    <h1 className="admin-subheader-text">
                        <span className="ratio-section-title">Postcodes management</span>
                    </h1>
                </div>

                <div className="overviewkopbg ratiotable row">
                    <div className="col s3">
                        <div className="overviewkop">
                            <form>
                                <div className="input-field margingone inner-addon">
                                    <i className="glyphicon tiny material-icons left lightgrey">search</i>
                                    <DelayInput delayTimeout={800} id="search" type="search" name="searchPhrase"
                                                value={this.state.searchPhrase} onChange={this.onSearch}/>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col s9">
                        <button
                            className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
                            onClick={() => this.onAdd()}
                            type="submit"
                            name="action">
                            <i className="small material-icons left white-text">add</i>
                            Nieuwe postcode
                        </button>
                        <button
                            className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
                            onClick={() => this.getPostcodeCategories()}
                            type="submit"
                            name="action">
                            <i className="small material-icons left white-text">edit</i>
                            Beheer categorieën
                        </button>
                    </div>
                </div>

                <PostcodeManagementGrid
                    rows={allPostcodes}
                    onHeaderClick={this.onSortChange}
                    sortingState={this.state.sorting}
                    getPropertyNameFromDisplayName={this.getHeaderPropertyForName}
                    headerNames={this.tableHeaderNames}
                    onEditClick={this.onEdit}
                    onDeleteClick={this.onDelete}
                />

                <KpnDialog
                    dialogState={this.state.dialogOpened}
                    modal={false}
                    dialogHeader={(
                        <DialogHeader
                            headerIcon={isEdit ? 'edit' : 'add'}
                            headerText={`${isEdit ? 'Edit' : 'Add'} postcode`}
                            closeHandler={this.closeDialog}
                        />
                    )}
                    dialogBody={
                        <DialogBody>
                            <div className="row">
                                <Input
                                    s={12}
                                    value={this.state.selectedPostcode.name}
                                    onChange={this.onChange}
                                    label='Postcode'
                                    name='postcode'
                                    type='text'/>
                                <Input
                                    s={12}
                                    value={this.state.selectedPostcode.description}
                                    onChange={this.onChange}
                                    label='Description'
                                    name='description'
                                    type='text'/>
                                <Input
                                    s={12}
                                    name="category"
                                    type="select"
                                    label="Category"
                                    value={this.state.selectedPostcode.category}
                                    onChange={this.onChange}
                                    multiple={false}
                                    required
                                    className="validate"
                                >
                                    <option value="">Select category</option>
                                    {categoriesToLink.map((el) => <option
                                        value={el.id}
                                        key={el.id}>{el.name}</option>)}
                                </Input>
                            </div>
                        </DialogBody>
                    }
                    dialogFooter={
                        <DialogFooter
                            buttonLeft={(
                                <DialogButton
                                    buttonAction={this.closeDialog}
                                    buttonText='Close'
                                    left={true}
                                />
                            )}
                            buttonRight={(
                                <DialogButton
                                    buttonAction={this.submitDialog}
                                    buttonText='Submit'
                                />
                            )}
                        />
                    }
                />

                <div className="col s12 sendendform">
                    <div className="col s12 m10">
                        <Pagination page={currentPage}
                                    pages={pages}
                                    changePage={this.changePage}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({postcodes}) => {
    return {
        postcodes
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(postcodeActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch)
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostcodeManagementPanel));
