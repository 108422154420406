import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Sticky from 'react-stickynode';


import Locations from './locations';
import LocationSearch from './locationSearch';
import LocationsHeader from './locationsHeader';

import locationActions from '../../actions/locationActions';

class LocationsList extends Component {

    onSortClick = () => {
        this.props.locationActions.changeSortingOfLocations();
    };

    render() {
        return (
                <div className="locationsList">
                    <Sticky top='#Menu'>
                        <div id="Locations">
                            <LocationsHeader
                              onSortClick={this.onSortClick}
                              onDownloadLocationsFile={this.props.onDownloadLocationsFile}
                            />

                            <LocationSearch />

                            <Locations />
                        </div>
                    </Sticky>
                </div>

        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        locationActions: bindActionCreators(locationActions, dispatch)
    }
};

export default connect(null, mapDispatchToProps)(LocationsList)
