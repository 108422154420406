import {apiRoutes} from "../constants/apiRoutes";
import ApiService from "../services/apiService";
import RequestType from "../constants/requestType";
import {toastr} from "react-redux-toastr";
import {actionTypes} from "../constants/actionTypes";

const getOrdersData = (queryParams) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.ordersRelated.getAll + (queryParams ? queryParams : '');
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getAllOrdersSuccess(response));
      dispatch(savePaginationParameters(response))
    } catch ({message}) {
      toastr.error('Het is niet mogelijk de lijst met offertes op te halen', message);
    }
  }
};

const getAllOrdersSuccess = (response) => {
  return {
    type: actionTypes.getOrdersData,
    response
  };
};

const savePaginationParameters = ({currentPage, resultsCount, pages}) => {
  return {
    type: actionTypes.savePagination,
    payload: {
      currentPage,
      resultsCount,
      pages
    }
  };
};

const setCurrentSection = (sectionId) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.setCurrentOrderSection,
      payload: sectionId
    });
  }
};

const setCurrentQuotationOrder = (quotationOrder) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.setCurrentQuotationOrder,
      payload: quotationOrder
    });
  }
};

const getQuotationOrderProducts = (quotation, queryParams) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.ordersRelated.getQuotationOrderProducts
        .replace('{quotation}', quotation)
        + (queryParams ? queryParams : '');
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getQuotationOrderProductsSuccess(response));
    } catch ({message}) {
      toastr.error('Something went wrong', message);
    }
  }
};

const getQuotationOrderProductsSuccess = (response) => {
  return {
    type: actionTypes.getQuotationOrderProductsSuccess,
    payload: response
  };
};

const updateOrderSentProduct = (orderSentProductId, data) => {
  return async () => {
    try {
      const url = apiRoutes.ordersRelated.updateOrderSentProduct.replace('{order}', orderSentProductId);

      await ApiService.performRequest(RequestType.PATCH, url, data);
    } catch ({message}) {
      toastr.error('Something went wrong', message);
    }
  }
};

export default {
  getOrdersData,
  setCurrentSection,
  setCurrentQuotationOrder,
  getQuotationOrderProducts,
  updateOrderSentProduct
};
