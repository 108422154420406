import {allTypes} from "../constants/hardwareType";

export default class HardwareDependencyHelper {

  static hasRelationDependencyMet = (hardware, alreadySelected) => {
    if (! hardware.related_partner_products.length) {
      return true;
    }

    let identifiedDependencies = hardware.related_partner_products.filter((relatedPartnerId) => alreadySelected.includes(relatedPartnerId));

    return identifiedDependencies.length || alreadySelected.includes(hardware.id);
  };

  static getAlreadySelectedHardware = (customHardwareSelections, type = '', connectionId) => {
    let alreadySelected = [];
    allTypes.forEach((hardwareType) => {
      let accessCondition = hardwareType === 'access' && type === 'access';
      if (customHardwareSelections.hasOwnProperty(hardwareType)) {
        let currentSelections = accessCondition ? customHardwareSelections[hardwareType].filter(selection => selection.connectionId === connectionId) : customHardwareSelections[hardwareType];
        currentSelections.forEach((selection) => {
          if (selection.id) {
            alreadySelected.push(selection.id)
          }
        });
      }
    });

    return alreadySelected;
  }
}
