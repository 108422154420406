import {
  actionTypes
} from '../constants/actionTypes';

const initialState = {
  pcApplicationOption: '',
  applicationData: null
};

export default (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.setDesiredApplicationSystem:
      return {
        ...state,
        currentPcApplicationOptions: action.payload
      };
    case actionTypes.getApplicationData:
      return {
        ...state,
        applicationData: action.payload
      };
    default:
      return state;
  }
}