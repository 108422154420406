import styled from 'styled-components';
import { Col } from 'react-materialize';

export default styled(Col).attrs({
  s: 12,
})`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  @media only screen and (max-width: 599px) {
    justify-content: center;
  }
`;
