import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom'

import adminActions from "../../../../actions/adminActions";

import FlashCalculationTable from "./FlashCalculationTable";

class FlashCalculationPanel extends Component {

    componentDidMount() {
        this.props.adminActions.getFlashCalculations()
    };

    updateFlashCalculation = (flashCalculation) => {
        this.props.adminActions.updateFlashCalculation(flashCalculation);
    };

    render() {

        return (
            <div>
                <div className="Forms">
                    <h1 className="ratio-section-title">
                        <i className="small material-icons left">
                            settings
                        </i>
                        Calculatie instelligen
                    </h1>
                    <FlashCalculationTable
                        rows={this.props.admin.flashCalculations}
                        updateFlashCalculation={this.updateFlashCalculation}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({authentication, admin}) => {
    return {
        authentication,
        admin
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        adminActions: bindActionCreators(adminActions, dispatch)
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FlashCalculationPanel));
