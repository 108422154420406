import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import FileDownload from "js-file-download";
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from "material-ui/Table";
import {Icon, Input} from "react-materialize";
import RatioOverflowTableWrapper
  from "../../../components/styled-components/ratio-overflow-table-wrapper/RatioOverflowTableWrapper";
import './index.css';

import AttachmentsForm from "./AttachmentsForm";

import ProviderHelper from "../../../helper/providerHelper";
import Pagination from "../../../components/pagination/pagination";
import GeneralDialog from "../../common/GeneralDialog";

import organizationActions from "../../../actions/organizationActions";
import attachmentActions from "../../../actions/attachmentActions";

const HeadersNames = [
  'Naam',
  'Provider',
  'Actief',
  'Type',
  'Aangemaakt',
  'Aangemaakt door',
  'Laatst gewijzigd door',
  'Acties'
];

class Attachments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadFile: {
        id: null,
        name: null,
        file: '',
      },
      newDocument: {
        id: null,
        name: null,
        file: '',
      },
      attachmentFiles: [],
      formDialogOpened: false,
      selectedDocument: null,
      provider: null,
      deleteDialogOpened: false
    }
  }

  componentDidMount() {
    this.setState({provider: this.props.authentication.providers[0].provider});
    this.props.attachmentActions.getAttachmentProductCategories();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevState.provider !== this.state.provider) {
          this.retrieveAttachmentFiles();
      }
  }

  retrieveAttachmentFiles = (page) => {
    this.setState({loading: true});
    const {provider} = this.state;

    let queryParams = '';

    if (page) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?');
      queryParams += 'page=' + page
    }

    this.props.attachmentActions.getAttachmentFiles(queryParams, provider);
  };

  generateIsFileActiveText = (isFileActive) => {
    return isFileActive ? 'Actief' : 'Inactief';
  };

  generateRow = (row) => {
    return (
      <TableRow key={row.id}>
        <TableRowColumn>{row.name}</TableRowColumn>
        <TableRowColumn style={{width: "10%"}}>{ProviderHelper.getQuotationProviderName(row.provider)}</TableRowColumn>
        <TableRowColumn style={{width: '10%'}}>{this.generateIsFileActiveText(row.isFileActive)}</TableRowColumn>
        <TableRowColumn style={{width: '10%'}}>{row.documentType}</TableRowColumn>
        <TableRowColumn style={{width: "10%"}}>{new Date(row.created_at).toLocaleString('nl-NL')}</TableRowColumn>
        <TableRowColumn style={{width: "10%"}}>{row.createdBy ? row.createdBy : ''}</TableRowColumn>
        <TableRowColumn style={{width: "10%"}}>{new Date(row.updated_at).toLocaleString('nl-NL')}</TableRowColumn>
        <TableRowColumn>
          <button className="btn table-actions" onClick={() => this.downloadAttachmentFile(row)}>
            <Icon>cloud_download</Icon>
          </button>
          <button className="btn table-actions" onClick={() => this.onEditClick(row)}>
            <Icon>edit</Icon>
          </button>
          <button className="btn table-actions" onClick={() => this.onDeleteClick(row)}>
            <Icon>delete</Icon>
          </button>
        </TableRowColumn>
      </TableRow>
    )
  };

  generateHeader = () => {
    return (
      HeadersNames.map((headerName) => {
        if (['Aangemaakt door', 'Laatst gewijzigd door', 'Actief', 'Provider', 'Aangemaakt', 'Laatst bewerkt', 'Type'].includes(headerName)
        ) {
          return <TableHeaderColumn key={headerName} style={{width: "10%"}}>{headerName}</TableHeaderColumn>
        }

        return <TableHeaderColumn key={headerName}>{headerName}</TableHeaderColumn>
      })
    );
  };

  onSubmit = (data) => {
    if (data.id) {
      this.props.attachmentActions.updateAttachmentFile(data)
        .then(() => this.closeDialog())
        .then(() => this.retrieveAttachmentFiles());
    } else {
      this.props.attachmentActions.addAttachmentFile(data)
        .then(() => this.closeDialog())
        .then(() => this.retrieveAttachmentFiles());
    }
  };

  downloadAttachmentFile = (row) => {
    this.props.attachmentActions.downloadAttachmentFile(row.id).then((response) => {
      if (response) {
        FileDownload(this.props.attachments.attachmentFileBlob, `${row.name}.${row.documentType}`);
      }
    });
  };

  onEditClick = (row) => {
    this.openDialog(row)
  };

  onDeleteSubmit = () => {
    this.props.attachmentActions.deleteAttachment(this.state.selectedDocument.id).then(() => this.retrieveAttachmentFiles());
    this.closeDeleteDialog();
  };

  closeDeleteDialog = () => {
    this.setState({deleteDialogOpened: false});
  };

  onDeleteClick = (attachment) => {
    this.setState({deleteDialogOpened: true, selectedDocument: attachment});
  };

  openDialog = (attachment) => {
    this.setState({formDialogOpened: true, selectedDocument: attachment})
  };

  closeDialog = () => {
    this.setState({formDialogOpened: false});
  };

  handleChangeProvider = ({target}) => {
    const provider = target.value;

    this.setState({provider: provider});
  };

  changePage = ({target}) => {
    const {value} = target;
    this.setState({page: value});


    this.retrieveAttachmentFiles(value);
  };

  render() {
    const {authentication, attachments} = this.props;
    const {providers} = authentication;
    const {attachmentFiles, currentPage, pages, attachmentProductCategories} = attachments;
    const {newDocument, provider, formDialogOpened, selectedDocument, deleteDialogOpened} = this.state;

    return (
      <div className="terms-and-conditions-container">
        <div id="Forms" className="ratiotable paddingbottomnone">
          <h1 className="admin-subheader-text"><span className="ratio-section-title">Bijlagebestanden</span></h1>
          <button
            className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
            onClick={() => this.openDialog(newDocument)}
            type="submit"
            name="action">
            <i className="small material-icons left white-text">add</i>
            Voeg nieuw bijlage document toe
          </button>
        </div>
        <div className="Forms">
          <div className="ratiotable row">
            <div className="col s6">
              <div style={{top: '25px', position: 'relative'}}>
                <Input
                  type="select"
                  id="provider"
                  name="provider"
                  required={true}
                  multiple={false}
                  defaultValue={-1}
                  className="validate"
                  label="Provider"
                  onChange={(e) => this.handleChangeProvider(e)}
                  value={provider}
                >
                  <option value={-1} disabled={'disabled'} key={-1}>Kies provider</option>
                  {
                    providers.filter((el => el.provider !== 1)).map((el) => {
                      return (
                        <option value={el.provider} key={el.provider}>
                          {ProviderHelper.getQuotationProviderName(el.provider)}
                        </option>
                      );
                    })
                  }
                </Input>
              </div>
            </div>
          </div>

          <RatioOverflowTableWrapper>
            <div className="valign-wrapper">
              <Table selectable={false}>
                <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                  <TableRow>
                    {this.generateHeader()}
                  </TableRow>
                </TableHeader>
                <TableBody displayRowCheckbox={false}>
                  {attachmentFiles?.map(row => this.generateRow(row))}
                </TableBody>
              </Table>
            </div>
          </RatioOverflowTableWrapper>

          {formDialogOpened && (
            <AttachmentsForm
              document={selectedDocument}
              onClose={this.closeDialog}
              onSubmit={this.onSubmit}
              providers={providers}
              productCategories={attachmentProductCategories}
            />
          )}

          <div className="col s12 sendendform">
            <div className="col s12 m10">
              <Pagination
                page={currentPage}
                pages={pages}
                changePage={this.changePage}
              />
            </div>
          </div>

          {selectedDocument && (
              <GeneralDialog
                dialogState={deleteDialogOpened}
                closeHandler={this.closeDeleteDialog}
                headerText={'Bevestig verwijdering'}
                headerIcon={'remove_circle_outline'}
                dialogBodyContent={
                  <div className="col s12">
                    {`U staat op het punt een ${selectedDocument.isFileActive ? 'actief' : 'inactief'} bestand te verwijderen. Weet u zeker dat u wilt doorgaan?`}
                  </div>
                }
                leftButtonAction={this.closeDeleteDialog}
                leftButtonText={'Nee'}
                leftButtonLeft={true}
                rightButtonAction={this.onDeleteSubmit}
                rightButtonText={'Ja'}
              />
            )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({organizations, authentication, attachments}) => {
  return {
    organizations,
    authentication,
    attachments
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    organizationActions: bindActionCreators(organizationActions, dispatch),
    attachmentActions: bindActionCreators(attachmentActions, dispatch)
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Attachments));