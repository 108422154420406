import React from 'react';
import {
    Table,
    TableBody,
} from 'material-ui/Table';
import RatioOverflowTableWrapper from "../../../components/styled-components/ratio-overflow-table-wrapper/RatioOverflowTableWrapper";

const TotalTables = (props) => {
    const { title, headers, rows, index } = props;
    return (
        <React.Fragment>
            <div className="col s12 overviewkopbg">
                <div className="overviewkop">{title}<span className="right bodynormal"></span></div>
            </div>
            <RatioOverflowTableWrapper>
                <Table className="highlight without-margin-bottom" selectable={false} key={index}>
                    {
                        headers
                    }
                    <TableBody displayRowCheckbox={false}>
                        {
                            rows
                        }
                    </TableBody>
                </Table>
            </RatioOverflowTableWrapper>
        </React.Fragment>
    );
};

export default TotalTables;
