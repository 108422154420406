import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import Moment from "react-moment";
import RatioOverflowTableWrapper from "../../components/styled-components/ratio-overflow-table-wrapper/RatioOverflowTableWrapper";

class OrderListTable extends Component {
  messageColors = {
    complete: '#008000',
    alert: '#FF0000',
    info: '#FFA500',
  }

  getIcon(sortDirection){
    switch (sortDirection){
      case "ASC":
        return 'arrow_downward';
      case "DESC":
        return 'arrow_upward';
      case false:
        return 'swap_vert'
    }
  }

  generateHeader() {
    return (
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow onCellClick={this.props.onHeaderClick}>
          <TableHeaderColumn className="clickable" style={{width: '5%'}}>
            #
            <i className="material-icons right lightgrey">
              {this.getIcon(this.props.sortingState[this.props.getPropertyNameFromDisplayName('#')])}
            </i>
          </TableHeaderColumn>

          <TableHeaderColumn className="clickable" headername="Quotation name" style={{width: '20%'}}>
            Offerte naam
            <i className="material-icons right lightgrey">
              {this.getIcon(this.props.sortingState[this.props.getPropertyNameFromDisplayName('Quotation name')])}
            </i>
          </TableHeaderColumn>

          <TableHeaderColumn className="clickable" style={{width: '20%'}} headername="Customer (IRMA/ODIDO/YIP ID)">
            Klant (IRMA/ODIDO/YIP ID)
            <i className="material-icons right lightgrey">
              {this.getIcon(this.props.sortingState[this.props.getPropertyNameFromDisplayName('Customer (IRMA/ODIDO/YIP ID)')])}
            </i>
          </TableHeaderColumn>

          <TableHeaderColumn className="clickable" style={{width: '10%'}} headername="Provider">
            Provider
            <i className="material-icons right lightgrey">
              {this.getIcon(this.props.sortingState[this.props.getPropertyNameFromDisplayName('Provider')])}
            </i>
          </TableHeaderColumn>

          <TableHeaderColumn className="clickable" style={{width: '20%'}} headername="Message">
            Order status
            <i className="material-icons right lightgrey">
              {this.getIcon(this.props.sortingState[this.props.getPropertyNameFromDisplayName('Message')])}
            </i>
          </TableHeaderColumn>

          <TableHeaderColumn className="clickable" headername="Aangemaakt" style={{width: '15%'}}>
            Aangemaakt
            <i className="material-icons right lightgrey">
              {this.getIcon(this.props.sortingState[this.props.getPropertyNameFromDisplayName('Aangemaakt')])}
            </i>
          </TableHeaderColumn>

          <TableHeaderColumn className="clickable" headername="Laaste update" style={{width: '15%'}}>
            Laaste update
            <i className="material-icons right lightgrey">
              {this.getIcon(this.props.sortingState[this.props.getPropertyNameFromDisplayName('Laaste update')])}
            </i>
          </TableHeaderColumn>

          <TableHeaderColumn className="clickable" style={{width: '10%'}}>Details</TableHeaderColumn>
        </TableRow>
      </TableHeader>
    );
  }

  generateRow(row) {
    const messageColor = this.messageColors[row.messageColorStatus];

    return (
      <TableRow key={row.id}>
        <TableRowColumn style={{width: '5%'}}>{row.quotationNumber}</TableRowColumn>
        <TableRowColumn style={{width: '20%'}}>{row.quotationName}</TableRowColumn>
        <TableRowColumn style={{width: '20%'}}>{row.customer}</TableRowColumn>
        <TableRowColumn style={{width: '10%'}}>{row.provider}</TableRowColumn>
        <TableRowColumn style={{width: '20%', color: messageColor, fontWeight: 500}}>{row.message}</TableRowColumn>
        <TableRowColumn style={{width: '15%'}}>{<Moment format="YYYY-MM-DD h:mmA">{ new Date(row.createdAt) }</Moment>}</TableRowColumn>
        <TableRowColumn style={{width: '15%'}}>{<Moment format="YYYY-MM-DD h:mmA">{ new Date(row.updatedAt) }</Moment>}</TableRowColumn>
        <TableRowColumn style={{width: '10%'}}>
          <a href="#" onClick={() => this.props.onViewOrdersClick(row)}>
            <i className="small material-icons left themeprimarycolor">remove_red_eye</i>
          </a>
        </TableRowColumn>
      </TableRow>
    );
  }

  render() {
    return (
        <RatioOverflowTableWrapper>
          <Table
              wrapperStyle={{ overflow: 'auto' }}
              onRowSelection={this.props.handleRowSelection}
              selectable={false}
              bodyStyle={{overflow: 'unset'}}
          >
            {this.generateHeader()}

            <TableBody displayRowCheckbox={false}>
              {this.props.rows.map(row => this.generateRow(row))}
            </TableBody>
          </Table>
        </RatioOverflowTableWrapper>
    );
  }
}

OrderListTable.propTypes = {
  propertiesToShow: PropTypes.array.isRequired,
  handleRowSelection: PropTypes.func,
  rows: PropTypes.array.isRequired,
  onHeaderClick: PropTypes.func,
  sortingState: PropTypes.any,
  getPropertyNameFromDisplayName: PropTypes.func
};

export default OrderListTable;
