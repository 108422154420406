import React, { Component } from 'react';
import { Input } from 'react-materialize'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import locationActions from '../../../actions/locationActions';
import KpnDialog  from '../../../containers/common/KpnDialog/KpnDialog';
import DialogHeader from "../../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";
import DialogFooter from "../../../containers/common/KpnDialog/DialogFooter";
import DialogButton from "../../../containers/common/KpnDialog/DialogButton";

class israAddressesDialog extends Component {

  constructor(props) {
    super(props);

    this.value = null;
    this.type = null;
    this.label = "Aanvullende adres gegevens selecteren";
    this.header = "Kies aub de gewenste adres toevoeging";
    this.buttonText = "Locatie toevoegen";
  }

  updateIsra = ({target}) => {
    this.value = target.value;
  }

  closeIsraAddressDialog = () => {
    this.props.locationActions.closeIsraDialog();
  }

  render() {
    let { dialogOpened, addresses, saveIsra, addressesType } = this.props;
    const { selectedLocation } = this.props.locations;
    const israAddresseses = [];
    const israValues = [];

    if (addresses !== undefined) {
      switch (addressesType) {
        case 'extensions':
          addresses.forEach((address, index) => {
            israAddresseses.push(<optgroup key={`key-${index}`} label={address.distributionPoint}></optgroup>);
            address.options.forEach((key) => {
              israValues.push(key);
              israAddresseses.push(<option key={`key-${key.toString()}`} value={key}>{key}</option>);
            })
          });
          this.label = "Aanvullende adres gegevens selecteren";
          this.header = "Kies aub de gewenste adres toevoeging";
          this.buttonText = "Locatie toevoegen";
          break;
        default:
          addresses.forEach((key) => {
            israValues.push(key);
            israAddresseses.push(<option key={`key-${key.toString()}`} value={key}>{key}</option>)
          });

          let locAddress = '';
          if (selectedLocation.isra_postal_code && selectedLocation.isra_house_number) {
             locAddress = `${selectedLocation.isra_postal_code || ''} ${selectedLocation.isra_house_number || ''}${selectedLocation.isra_house_number_extension || ''}`;
          } else {
             locAddress = `${selectedLocation.postal_code || ''} ${selectedLocation.house_number || ''}${selectedLocation.house_number_extension || ''}`;
          }

          this.header = `Er zijn meerdere ISRA's gevonden op ${locAddress}`;
          this.label = "Kies a.u.b. het gewenste ISRA-punt voor deze locatie.";
          this.buttonText = "Alternatief ISRA opslaan";
          break;
      }
    }

    israAddresseses.push(<option key={`key-complex-address`} value={'complexAddress'}>{'Complex Adres'}</option>);
    israValues.push('complexAddress');

    this.value = israValues.find(e => !!e)
    this.type = addressesType;

    return (
      <KpnDialog
        dialogState={dialogOpened}
        modal
        dialogHeader={(
          <DialogHeader
            headerText={`${this.header}:`}
            closeHandler={this.closeIsraAddressDialog}
          />
        )}
        dialogBody={
          <DialogBody>
            <div className="input-field">
              {israAddresseses.length > 0 && (
                <div>
                  <Input
                    s={12}
                    onChange={this.updateIsra}
                    name="israAddress"
                    type='select'
                    label={this.label}
                  >
                    {israAddresseses}
                  </Input>
                </div>
              )}
            </div>
          </DialogBody>
        }
        dialogFooter={
          <DialogFooter
            buttonRight={(
              <DialogButton
                buttonAction={() => saveIsra(this.value, this.type)}
                buttonText={this.buttonText}
              />
            )}
          />
        }
      />
    );
  }
};

const mapStateToProps = ({ locations }) => {
    return {
        locations,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        locationActions: bindActionCreators(locationActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(israAddressesDialog);
