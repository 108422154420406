import {toastr} from 'react-redux-toastr'
import {apiRoutes} from '../constants/apiRoutes';
import {actionTypes} from '../constants/actionTypes';

import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

const getAllProducts = (queryParameters, providerID) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.productsRelated.getAllProducts.replace(/{providerID}/, providerID) + queryParameters;
      let response = await ApiService.performRequest(RequestType.GET, url);

      if (response) {
        dispatch(setAllProducts(response));
        dispatch(setProductPaginationParameters(response));
      }
    } catch (message) {
      toastr.error('Kan de productlijst niet ophalen omdat ', message);
    }
  }
};

const setAllProducts = ({products}) => {
  return {
    type: actionTypes.setAllProducts,
    payload: products || [123]
  }
};

const setProductPaginationParameters = ({currentPage, resultsCount, pages}) => {
  return {
    type: actionTypes.setProductPagination,
    payload: {
      pages,
      currentPage,
      resultsCount
    }
  }
};

const setProvider = (provider) => {
  return {
    type: actionTypes.setProductsProvider,
    payload: provider
  }
};

const getProductDetails = (productID) => {
  return async () => {
    try {
      const url = apiRoutes.productsRelated.getProductDetails.replace(/{productID}/, productID);
      return await ApiService.performRequest(RequestType.GET, url);
    } catch (message) {
      toastr.error('Kon de productgegevens niet ophalen omdat', message);
    }
  }
};


const updateProductDetails = (productDetails) => {
  return async () => {
    try {
      const productID = productDetails.id;
      const url = apiRoutes.productsRelated.updateProductDetails.replace(/{productID}/, productID);
      return await ApiService.performRequest(RequestType.PATCH, url, productDetails);
    } catch (message) {
      toastr.error('Kon de productdetails niet bijwerken omdat ', message);
    }
  }
};

const getProductOdidoDetails = (productID) => {
  return async () => {
    try {
      const url = apiRoutes.productsRelated.getProductOdidoDetails.replace(/{productID}/, productID);
      return await ApiService.performRequest(RequestType.GET, url);
    } catch (message) {
      toastr.error('Kon de productgegevens niet ophalen omdat', message);
    }
  }
};

const getExtendedProductOdidoDetails = (productID) => {
  return async () => {
    try {
      const url = apiRoutes.productsRelated.getExtendedProductOdidoDetails.replace(/{productOdidoDetailsID}/, productID);
      return await ApiService.performRequest(RequestType.GET, url);
    } catch (message) {
      toastr.error('Kon de productgegevens niet ophalen omdat', message);
    }
  }
};

const updateProductOdidoDetails = (productDetails) => {
  return async () => {
    try {
      const productID = productDetails.id;
      const url = apiRoutes.productsRelated.updateProductOdidoDetails.replace(/{productOdidoDetailsID}/, productID);
      return await ApiService.performRequest(RequestType.PATCH, url, productDetails);
    } catch (message) {
      toastr.error('Kon de productdetails niet bijwerken omdat ', message);
    }
  }
};

export default {
  setProvider,
  getAllProducts,
  getProductDetails,
  updateProductDetails,
  getProductOdidoDetails,
  getExtendedProductOdidoDetails,
  updateProductOdidoDetails
};
