import {
    actionTypes
} from '../constants/actionTypes';

const initialState = {
    allTagCategories: [],
    pages: 1,
    currentPage: 1,
    resultsCount: 0,
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.getAllTagCategories:
            return {
                ...state,
                allTagCategories: action.payload
            };
        case actionTypes.savePagination:
            return {
                ...state,
                pages : action.payload.pages,
                currentPage: action.payload.currentPage,
                resultsCount: action.payload.resultsCount
            };
        case actionTypes.patchTagCategory:
            return {
                ...state,
                allTagCategories: state.allTagCategories.map( (tagCategory) => tagCategory.id === action.payload.id ? action.payload : tagCategory )
            };
        default:
            return state;
    }
}
