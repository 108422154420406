import React, {Component} from 'react';
import {Input, Preloader} from "react-materialize";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {poll} from "../../utils/utils";
import {toastr} from "react-redux-toastr";

import ReactTable from 'react-table';
import {AutoSizer, Column, Table} from "react-virtualized";
import "react-table/react-table.css";
import 'react-virtualized/styles.css';

import quotationActions from "../../actions/quotationActions";
import stepsActions from "../../actions/stepsActions";
import mobileActions from "../../actions/workflow/mobileActions";
import accessActions from "../../actions/workflow/accessActions";

import RegularSelect from "../../components/RegularSelect";
import quotationType from "../../constants/quotationType";
import orderState from "../../constants/orderState";
import disabledOrderStates from "../../constants/disabledOrderStates";
import organization from "../../constants/organization";
import {apiRoutes} from "../../constants/apiRoutes";

class ExistingAccessData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      overallStatus: {
        no_action: false,
        extend: false,
        upgrade: false,
        terminate: false,
        retention: false,
      },
      loading: false
    }
  }

  componentDidMount() {
    const {quotationId} = this.props;
    this.props.quotationActions.getAccessProducts(quotationId).then(() => this.fixHeaderCheckbox());
    this.props.accessActions.hasPendingUpgradeData(quotationId).then(() => this.continuePoll());
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data,
      });
      this.fixHeaderCheckbox()
    }
  }

  switchKeys(product, column, data = null) {
    let value = data ? data[column] : !product[column];
    if (value === false) {
      value = null;
    }

    product['terminate'] = product['terminate'] === false ? false : null;
    product['upgrade'] = product['upgrade'] === false ? false : null;
    product['extend'] = product['extend'] === false ? false : null;
    product['no_action'] = product['no_action'] === false ? false : null;
    product['retention'] = product['retention'] === false ? false : null;
    product[column] = value;

    if (!product['terminate'] && !product['upgrade'] && !product['extend'] && !product['no_action'] && !product['retention']) {
      product['no_action'] = true;
    }
  }

  toggleSelect(column, data) {
    let c2 = null;
    if (data['terminate'] === false) {
      c2 = 'terminate'
    }
    if (data['upgrade'] === false) {
      c2 = 'upgrade'
    }
    if (data['extend'] === false) {
      c2 = 'extend'
    }
    if (data['no_action'] === false) {
      c2 = 'no_action'
    }
    if (data['retention'] === false) {
      c2 = 'retention';
    }

    this.switchKeys(data, column);
    data[c2] = false;
    let payload = {category: false, data};

    this.setState({loading: true});

    this.props.quotationActions.updateAccessProducts(payload, this.props.quotationId, true)
      .then(() => this.props.quotationActions.getAccessProducts(this.props.quotationId))
      .then(() => this.props.getQuotationOptions(this.props.quotationId))
      .then(() => this.fixHeaderCheckbox())
      .then(() => this.setState({loading: false}))
  };

  fixHeaderCheckbox() {
    let extendCount = 0;
    let upgradeCount = 0;
    let terminateCount = 0;
    let noActionCount = 0;
    let retentionCount = 0;
    let products = this.state.data.filter((el) => !disabledOrderStates.includes(el.order_state));
    let productsCount = products.length;

    products.forEach((el) => {
      if (el.extend) {
        extendCount++;
      } else if (el.upgrade) {
        upgradeCount++;
      } else if (el.terminate) {
        terminateCount++;
      } else if (el.no_action) {
        noActionCount++;
      } else if (el.retention) {
        retentionCount++;
      }
    });

    this.setState({
      overallStatus: {
        extend: extendCount === productsCount,
        upgrade: upgradeCount === productsCount,
        terminate: terminateCount === productsCount,
        no_action: noActionCount === productsCount,
        retention: retentionCount === productsCount
      }
    })
  }

  toggleSelectAll(column, original = null) {
    this.setState({loading: true});

    let {overallStatus, data} = this.state;
    let quotationId = this.props.quotationId;
    let isUpgradeAction = column === 'upgrade';

    this.switchKeys(typeof original !== 'boolean' ? original : overallStatus, column);

    if (typeof original === 'boolean') {
      data = data.filter((el) => !disabledOrderStates.includes(el.order_state))
        .map((el) => {
          if (el[column] !== false) {
            this.switchKeys(el, column, original ? null : overallStatus);
          }

          return el;
        });
    }

    if (isUpgradeAction) {
      let orderIds = data.filter((accessOrder) => !accessOrder.upgrade_data_retrieved && accessOrder.upgrade)
        .map((accessOrder) => accessOrder.order_id);
      if (orderIds.length) {
        this.initiateUpgradeDataRetrieval(quotationId, orderIds);
      }
    }

    this.props.quotationActions.updateAccessProducts({category: true, data}, quotationId, true)
      .then(() => this.props.accessActions.retrieveExistingAccessData(quotationId))
      .then(() => this.props.getQuotationOptions(quotationId))
      .then(() => this.setState({loading: false, overallStatus}))
      .then(() => this.fixHeaderCheckbox())
  }

  initiateUpgradeDataRetrieval = (quotationID, data) => {
    this.setDataRetrievalAlert();
    this.props.accessActions.updateDataRetrieval(data);

    let {pendingDataRetrieval, quotationId, existingAccessData} = this.props;
    let isPollEmpty = !(pendingDataRetrieval && pendingDataRetrieval.length);
    const orderIds = existingAccessData.filter((accessOrder) => accessOrder.upgrade).map(accessOrder => accessOrder.order_id);

    const url = apiRoutes.accessRelated.getUpgradeAccessData.replace('{quotationId}', quotationID);
    this.props.accessActions.getUpgradeOptions(quotationID, data)
      .then(() => {
        if (isPollEmpty) {
          poll(url).then(() => {
            this.checkDataRetrievalStatus(true);
            this.props.quotationActions.getUpgradingData(quotationId, orderIds);
            this.props.accessActions.retrieveExistingAccessData(quotationId);
            this.props.accessActions.updateDataRetrieval([]);
          });
        }
      });
  };

  continuePoll = () => {
    let {pendingDataRetrieval, quotationId, existingAccessData} = this.props;
    if (!pendingDataRetrieval || (pendingDataRetrieval && !pendingDataRetrieval.length)) {
      return;
    }

    this.setDataRetrievalAlert();

    const orderIds = existingAccessData.filter((accessOrder) => accessOrder.upgrade).map(accessOrder => accessOrder.order_id);
    const url = apiRoutes.accessRelated.getUpgradeAccessData.replace('{quotationId}', quotationId);

    poll(url)
      .then(() => {
        this.checkDataRetrievalStatus(true);
        this.props.quotationActions.getUpgradingData(quotationId, orderIds);
        this.props.accessActions.retrieveExistingAccessData(quotationId);
        this.props.accessActions.updateDataRetrieval([]);
      })
      .catch((e) => toastr.error('Kan bestaande gegevens niet ophalen', e.message));
  };

  checkDataRetrievalStatus = (knownStatus) => {
    let {pendingDataRetrieval} = this.props;

    if ((pendingDataRetrieval && !pendingDataRetrieval.length) || knownStatus) {
      this.props.quotationActions.resetSoftAlerts();
      return;
    }

    this.setDataRetrievalAlert();
  };

  setDataRetrievalAlert = () => {
    let alertMessage = 'We zijn nog druk bezig alle bestaande orders op te halen. Je kunt gewoon door met je offerte, maar alle bestaande producten zijn misschien nog niet geladen';
    this.props.quotationActions.addSoftAlert(alertMessage);
  };

  renderProductOptions = (data) => {
    let options = [];

    options.push(<option value="" key={0}/>);

    if (data && data.upgrade === true) {
      const upgradingData = this.props.upgradeData;

      const products = upgradingData.filter((item) => item.order_id === data.order_id);
      products.forEach(product => {
        options.push(
          <option
            key={product.id}
            value={product.product_id}
          >
            {product.product_name}
          </option>
        );
      });
    }

    if (data && data.retention === true) {
      options = this.props.generateRetentionOptions(true);
    }

    return options;
  };

  onChange = (e, index, data) => {
    let {value} = e.target;

    if (value !== "") {
      let upgradingData;
      let product;
      if (data.upgrade) {
        upgradingData = this.props.upgradeData;
        product = upgradingData.find((item) => item.order_id === data.order_id && item.product_id === value);
      }
      if (data.retention) {
        upgradingData = this.props.generateRetentionOptions(false);
        product = upgradingData.find((item) => item.product_id === value);
      }

      data['upgrade_product_id'] = product.product_id;
      data['upgrade_product_name'] = product.product_name;

      let payload = {category: false, data};

      this.props.quotationActions.updateAccessProducts(payload, this.props.quotationId, true)
        .then(() => this.props.quotationActions.getAccessProducts(this.props.quotationId))
        .then(() => this.props.accessActions.checkUpgradeTypeRequired(payload).then((response) => {
          if (response && response.success) {
            this.props.triggerConnectionType(response.connectionId);
          }
        }));
    }
  };

  renderProductCell = (order, index, inputsDisabled) => {
    let {
      upgrade,
      retention,
      parent_id,
      quantity,
      product_name,
      upgrade_product_id,
      product_id,
      upgrade_data_retrieved,
      order_id
    } = order;

    if (!upgrade && (!retention || (retention && parent_id))) {
      return (
        <div>
          <span style={{fontStyle: `${!parent_id ? 'normal' : 'italic'}`}}>
            {quantity > 1 && `(${quantity}x) `} {product_name}
          </span>
        </div>
      );
    }

    if (upgrade) {
      let {pendingDataRetrieval} = this.props;
      let pendingDataCondition = !!pendingDataRetrieval.find((pendingOrderId) => parseInt(pendingOrderId) === parseInt(order_id));

      if (upgrade_data_retrieved && !pendingDataCondition) {
        const {upgradeData} = this.props;
        let availableUpgradeOptions = upgradeData.filter((upgradeProduct) => upgradeProduct.order_id === order_id);
        if (!availableUpgradeOptions.length) {
          return <span className="no-upgrade-data-text">Up/Downgrade niet mogelijk</span>;
        }
      } else {
        return <span className="waiting-upgrade-data-text">Up/Downgrade opties worden opgehaald</span>
      }
    }

    return (
      <div>
        <RegularSelect
          s={12}
          name="product"
          onChange={(event) => this.onChange(event, index, order)}
          value={upgrade_product_id || product_id}
          disabled={inputsDisabled}
          multiple={false}
        >
          {this.renderProductOptions(order)}
        </RegularSelect>
      </div>
    )
  };

  render() {
    const {data, inputsDisabled} = this.props;
    const {company} = this.props.authentication;
    const isVerlengenType = this.props.quotation.currentQuotation.type === quotationType.TYPE_VERLENGEN;

    let statuses = {
      no_action: 'Geen actie',
      extend: 'Verlengen',
      upgrade: 'Up/downgraden',
      terminate: 'Opzeggen',
      retention: 'Retentie'
    };
    let overallStatus = this.state.overallStatus;

    if ([organization.SV, organization.KPN_RETAIL].includes(company)) {
      delete statuses.terminate;
      delete statuses.extend;
      delete statuses.upgrade;
    } else {
      delete statuses.retention;
    }

    let columns = [
      {
        id: 'product_name',
        accessor: 'product_name',
        Cell: ({original, index}) => {
          return (
            <span>
              {!original.parent_id ?
                `${original.postal_code || ''} ${original.house_number || ''}${original.house_number_extension || ''}` :
                null}
            </span>
          )
        },
        Header: 'Adres',
        headerClassName: 'highlight',
        headerStyle: {textAlign: 'left'},
        maxWidth: 200,
        textAlign: 'left',
        fontWeight: 'bold',
        sortable: false,
      },
      {
        id: 'product',
        Cell: ({original, index}) => {
          return this.renderProductCell(original, index, inputsDisabled);
        },
        Header: 'Product',
        headerClassName: 'highlight',
        headerStyle: {textAlign: 'left'},
        maxWidth: 400,
        textAlign: 'left',
        fontWeight: 'bold',
        resizable: false,
        sortable: false,
      },
      {
        id: 'irma_order_status',
        accessor: 'irma_order_status',
        Cell: ({original}) => {
          return (
            <span>
              {original.order_state || ''}
            </span>
          )
        },
        Header: 'Irma Order status',
        headerClassName: 'highlight',
        headerStyle: {textAlign: 'left'},
        maxWidth: 200,
        textAlign: 'left',
        fontWeight: 'bold',
        sortable: false,
      },
    ];

    for (let checkboxKey in statuses) {

      columns.push({
        id: checkboxKey,
        accessor: "",
        Cell: ({original, index}) => {
          const hasParent = !!original.parent_id;
          const hasTerminateState = original.order_state === orderState.ORDER_STATE_TERMINATE;

          let parent = null;
          if (hasParent) {
            parent = data.find(el => el.order_id === original.parent_id);
          }

          if (hasTerminateState && checkboxKey !== 'no_action') {
            return <span/>;
          }
          if (original.upgrade === false && checkboxKey === 'upgrade') {
            return <span/>;
          }

          let disableCondition = isVerlengenType && hasParent && checkboxKey !== 'terminate' && parent && parent[checkboxKey] !== true;

          return (
            <Input
              type="checkbox"
              name={`${checkboxKey}-${original.id}`}
              label=" "
              className="checkbox"
              disabled={disableCondition || inputsDisabled}
              checked={original[checkboxKey] === true}
              onChange={() => isVerlengenType ? this.toggleSelectAll(checkboxKey, original) : this.toggleSelect(checkboxKey, original)}
            />
          );
        },
        Header: () => {
          return (
            <div>
              <div>
                <Input
                  type="checkbox"
                  label={statuses[checkboxKey]}
                  name={`all_${checkboxKey}`}
                  className="checkbox"
                  disabled={inputsDisabled}
                  checked={overallStatus[checkboxKey]}
                  onChange={() => this.toggleSelectAll(checkboxKey, overallStatus[checkboxKey])}
                />
              </div>
            </div>
          );
        },
        sortable: false,
        resizable: false,
        className: 'col-checkbox',
        headerClassName: 'head-checkbox',
        minWidth: 40,
      });
    }

    return (
      <div style={{height: data.length > 10 ? '600px' : (data.length * 50 + 40 + 'px')}}>
        {this.state.loading &&
        <div className="loader-component" style={{zIndex: 99, top: "10%"}}>
          <div className="overlay" style={{backgroundColor: `rgba(255, 255, 255, 0.3)`}}/>
          <Preloader size='medium'/>
        </div>
        }
        {
          isVerlengenType ?
            <AutoSizer>
              {({width, height}) => (
                <Table
                  width={width}
                  height={height}
                  headerHeight={40}
                  rowHeight={50}
                  rowCount={data.length}
                  rowGetter={({index}) => data[index]}
                  disableSort={true}
                  className='striped'
                >
                  <Column
                    label='Adres'
                    width={width / 4}
                    cellRenderer={({rowData}) => (
                      <span>
                        {!rowData.parent_id ?
                          `${rowData.postal_code || ''} ${rowData.house_number || ''}${rowData.house_number_extension || ''}` :
                          null}
                      </span>
                    )}
                  />
                  <Column
                    width={width / 2}
                    label='Product'
                    cellRenderer={({rowData, rowIndex}) => (
                      this.renderProductCell(rowData, rowIndex, inputsDisabled)
                    )}
                  />
                  <Column
                    width={width / 5}
                    label={
                      <div className="irma-status-label">
                        IRMA<br/>Order status
                      </div>
                    }
                    dataKey='translated_action'
                    cellRenderer={({rowData}) => {
                      return <div>{rowData.translated_action}</div>
                    }}
                  />
                  {
                    Object.keys(statuses).map((checkboxKey) => (
                      <Column
                        width={width / 5}
                        label='Acties'
                        headerRenderer={() => (
                          <div className="header-cell-container">
                            <span className="header-identifier">{statuses[checkboxKey]}</span>
                            <Input
                              type="checkbox"
                              label={<div className="check-all-label">Alles</div>}
                              name={`all_${checkboxKey}`}
                              className="checkbox"
                              disabled={inputsDisabled}
                              checked={overallStatus[checkboxKey]}
                              onChange={() => this.toggleSelectAll(checkboxKey, overallStatus[checkboxKey])}
                            />
                          </div>
                        )}
                        cellRenderer={({rowData}) => {
                          const hasParent = !!rowData.parent_id;

                          if (disabledOrderStates.includes(rowData.order_state)) {
                            return <span/>;
                          }

                          let parent = null;
                          if (hasParent) {
                            parent = data.find(el => el.order_id === rowData.parent_id);
                          }

                          return (
                            <div style={{'marginTop': '9px'}}>
                              <Input
                                type="checkbox"
                                name={`${checkboxKey}-${rowData.id}`}
                                label=" "
                                className="checkbox"
                                disabled={inputsDisabled}
                                checked={rowData[checkboxKey] === true}
                                onChange={() => isVerlengenType ? this.toggleSelectAll(checkboxKey, rowData) : this.toggleSelect(checkboxKey, rowData)}
                              />
                            </div>
                          );
                        }}
                      />
                    ))
                  }
                </Table>
              )}
            </AutoSizer> :
            <ReactTable
              data={data}
              resolveData={data => data.map(row => row)}
              columns={columns}
              minRows={0}
              defaultPageSize={999}
              showPagination={false}
              style={{border: 'none'}}
              className="responsive-table -striped"
              getTbodyProps={() => {
                return {
                  style: {
                    overflow: 'visible',
                    textAlign: 'left',
                  }
                }
              }}
            />
        }
      </div>
    );
  }
}

const mapStateToProps = ({quotation, hardware, mobile, authentication, access}) => {
  return {
    quotationId: quotation.currentQuotation.id,
    customHardwareOptions: hardware.customHardwareOptions,
    devicePaymentOptions: mobile.devicePaymentOptions,
    pendingDataRetrieval: access.pendingDataRetrieval,
    existingAccessData: access.existingAccessData,
    quotation,
    authentication: authentication,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    accessActions: bindActionCreators(accessActions, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
    mobileActions: bindActionCreators(mobileActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ExistingAccessData);
