import React from 'react';
import Authorize from '../utils/authorize';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const Navbar = props => {
  const softAlertStyle = {display: 'flex', justifyContent: 'center', paddingLeft: '35px', width: '86%', color: '#d70000'};
  const hardAlertStyle = {display: 'flex', justifyContent: 'center', paddingLeft: '35px', width: '86%', backgroundColor: '#d70000', color: 'white'};

  let needsAlert = props.alerts.allHardAlerts.length || props.alerts.allSoftAlerts;
  let alertType = props.alerts.allHardAlerts.length && props.alerts.currentHardAlert ? 'hard' : 'soft';
  let currentAlert = alertType === "soft" ? props.alerts.currentSoftAlert : props.alerts.currentHardAlert;

  const switchableOrganizationList = props.switchableOrganizationList;
  const organizationList = switchableOrganizationList.map(organization => ({id: organization.id, name: organization.name}));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <nav>
        <div className="nav-wrapper">
          {
            needsAlert && currentAlert &&
            <div className="left" style={alertType === 'soft' ? softAlertStyle : hardAlertStyle}>
              {currentAlert}
            </div>
          }
          <div className="right icons" style={{display: 'flex'}}>
            {props.allowSwitch &&
                <div style={{width: 'min-content'}}>
                  <IconButton
                      style={{padding: '0px', backgroundColor: 'transparent'}}
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                  >
                    <i className="material-icons left">autorenew</i>
                  </IconButton>
                  <Menu
                      id="long-menu"
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: 48 * 4.5,
                          width: '20ch',
                        },
                      }}
                  >
                    {organizationList.map((organization) => (
                        <MenuItem key={organization.id} value={organization.id}
                                  onClick={props.switchOrganizationAction}>
                          {organization.name}
                        </MenuItem>
                    ))}
                  </Menu>
                </div>
            }

            {props.onSettingsClick && <a href="javascript:void(0)" onClick={props.onSettingsClick}><i
              className="material-icons left">format_list_bulleted</i></a>}
            {props.onAdminClick && (
              <Authorize allowedRoles={['ROLE_ADMIN', 'ROLE_MANAGE_USERS']}>
                <a href="javascript:void(0)" onClick={props.onAdminClick}><i
                  className="material-icons left">settings</i></a>
              </Authorize>
            )}
            {props.onLogoutClick && <a href="javascript:void(0)" onClick={props.onLogoutClick}><i
              className="material-icons left">launch</i></a>}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
