import React, { Component } from 'react';
import { Provider } from 'react-redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import store from './store';
import LoadingComponent from './containers/loadingComponent';

import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';

import Main from './containers/main';
import DigitalSigning from './containers/digitalSigning';
import Login from './containers/loginForm';
import PasswordForgotten from './containers/PasswordForgotten';
import ExpiredSessionPopUp from './containers/ExpiredSessionPopUp';
import Dashboard from './containers/dashboard';
import AdminPanel from './containers/Admin/AdminPanel';
import Orders from './containers/Orders/orders';
import Forbidden from './components/forbidden';
import ThemeStyled from './components/ThemeStyled';

import ScrollTopButton from './components/scrollTopButton'

const InnerRouter = () => (
    <div>
        <Switch>
            <Route path="/quotation/:id" exact component={Main} />
            <Route path="/lead/:id" exact component={Main} />
            <Route path="/admin" component={AdminPanel} />
            <Route path="/" exact component={Dashboard} />
            <Route path="/orders" exact component={Orders} />
            <Route path="/forbidden" exact component={Forbidden} />
            <Route path="/offerte-ondertekenen/:uuid" exact component={DigitalSigning} />
            <Redirect to="/" />
        </Switch>
    </div>
);

const OutterRouter = () => (
  <div>
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path='/forgotten-password' exact component={PasswordForgotten} />
      <Route path="/forbidden" exact component={Forbidden} />
      <Route path="/offerte/:uuid" exact component={DigitalSigning} />
      <Redirect to="/login" />
    </Switch>
  </div>
);

class AppRouter extends Component {
  render() {

    const { authentication } = this.props;

    return (
      <MuiThemeProvider>
          <ThemeStyled>
              {<ExpiredSessionPopUp />}
              <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates={true}
                position="top-center"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar={false}
              />
              <BrowserRouter>
                <LoadingComponent>
                  {authentication.isLoggedIn ? (<InnerRouter />) : (<OutterRouter/>)}
                </LoadingComponent>
              </BrowserRouter>
              <ScrollTopButton />
          </ThemeStyled>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication
  }
}

const AppRouterContainer = connect(mapStateToProps)(AppRouter);

const App = () => (
  <Provider store={store}>
    <AppRouterContainer />
  </Provider>
);

export default App;
