import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom'

import adminActions from '../../../actions/adminActions';

import CompensationGrid from "./CompensationGrid";

class CompensationPanel extends Component {

  state = {
    isEdition: false,
    userDialogOpened: false,
    createDialogOpened: false,
    userId: 0,
    compensations: []
  };

  componentDidMount() {
    this.props.adminActions.getCompensation().then(() => {
      this.setState({compensations: this.props.compensations});
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.compensations !== this.props.compensations) {
      this.setState({compensations: this.props.compensations});
    }
  }

  onAddClick = () => {
    let i = 0;
    let nextFourEnabled = this.state.compensations.map(el => {
      if (!el.display && i < 4) {
        el.display = true;
        i++;
      }
      return el;
    });

    this.setState({compensations: nextFourEnabled});
  };

  updateCompensation = (compensation) => {
    this.props.adminActions.updateCompensation(compensation);
    this.props.adminActions.saveCompensation(compensation);
  };

  render() {
    const {compensations} = this.state;

    return (
      <div>
        <div id="Forms" className="ratiotable paddingbottomnone">
          <h1 className="admin-subheader-text"><span className="ratio-section-title">Vergoeding</span></h1>
          <button
            className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
            onClick={() => this.onAddClick()}
            type="submit"
            name="action">
            <i className="small material-icons left white-text">add</i>
            Extra set vergoedingen ingeven
          </button>
        </div>

        <CompensationGrid
          rows={compensations}
          updateCompensation={this.updateCompensation}
          providers={this.props.authentication.providers}
        />
      </div>
    );
  }
}

const mapStateToProps = ({admin, authentication}) => {
  return {
    compensations: admin.compensations,
    authentication: authentication
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminActions: bindActionCreators(adminActions, dispatch)
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompensationPanel));
