import {
  actionTypes
} from '../constants/actionTypes';

const initialState = {
  newAttachmentFile: {
    name: '',
  },
  attachmentFiles: [],
  attachmentFileBlob: '',
  attachmentProductCategories: [],
  pages: 1,
  currentPage: 1,
  resultsCount: 0
};

export default (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.getAttachments:
      return {
        ...state,
        attachmentFiles: action.response,
      };
    case actionTypes.setAttachmentFilesPagination:
      return {
        ...state,
        pages : action.payload.pages,
        currentPage: action.payload.currentPage,
        resultsCount: action.payload.resultsCount
      };
    case actionTypes.deleteAttachment:
      let {fileID} = action.payload;
      return {
        ...state,
        attachmentFiles: state.attachmentFiles.filter((attachment) => attachment.id !== fileID)
      };
    case actionTypes.downloadAttachmentFile:
      return {
        ...state,
        attachmentFileBlob: action.payload
      };
    case actionTypes.setAttachmentProductCategories:
      return {
        ...state,
        attachmentProductCategories: action.payload
      };
    default:
      return state;
  }
}
