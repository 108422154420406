import React, {Component} from 'react';
import {connect} from "react-redux";

import FileUpload from '../../../components/fileUpload';
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";
import GeneralDialog from "../../../containers/common/GeneralDialog";

class BulkUpload extends Component {
  render() {
    let {dialogOpened, onRequestClose, save, onDrop, droppedFile, mobile} = this.props;

    return (
      <GeneralDialog
        dialogState={dialogOpened}
        headerIcon="folder_open"
        headerText="Bulk:"
        headerSubtext="upload"
        closeHandler={onRequestClose}
        dialogBodyContentRaw={(
          <DialogBody>
            {mobile &&
            <div className="col s12">
              Let op! Wanneer je een bulkbestand upload worden de profielen/telefoonnummers opgeteld
              bij het aantal dat je reeds hebt ingevuld in Ratio. Wanneer je beide invult, krijg je dus
              ook het dubbele aantal terug.<br/><br/>
            </div>
            }
            <div className="input-field col s12">
              <FileUpload onDrop={onDrop} droppedFile={droppedFile}
                          caption="Sleep uw bestand hier of klik voor upload."/>
            </div>
          </DialogBody>
        )}
        rightButtonText={'Uploaden'}
        rightButtonAction={() => save()}
      />
    );
  }
};

const mapStateToProps = ({quotation}) => {
  return {
    quotation: quotation
  }
};

export default connect(mapStateToProps)(BulkUpload);
