import React, {Component} from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow} from "material-ui";
import GroupProductRow from "./GroupProductRow";
import {isEmpty} from 'lodash';

import KpnDialog from "../../../containers/common/KpnDialog/KpnDialog";
import DialogHeader from "../../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";
import DialogFooter from "../../../containers/common/KpnDialog/DialogFooter";
import DefaultDialogBody from "../../../containers/common/KpnDialog/DefaultDialogBody";
import DialogButton from "../../../containers/common/KpnDialog/DialogButton";
import RatioOverflowTableWrapper from "../../../components/styled-components/ratio-overflow-table-wrapper/RatioOverflowTableWrapper";

class GroupProducts extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dialogOpened: false,
      dialogConfirmed: false,
      inCampus: false
    }
  }

  componentDidMount() {
    this.setState({ inCampus: this.props.inCampus });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inCampus !== this.props.inCampus) {
      this.setState({ inCampus: this.props.inCampus });
    }
  }

  _handleDeleteDialogClose = () => {
    this.setState({
      dialogOpened: false,
      dialogConfirmed: true,
    })
  };

  generateHeaders = (productGroup) => {
    return (
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow>
          <TableHeaderColumn style={{width: `${productGroup.isCustomCategory ? "15%" : "30%"}`}}>
            <div style={{whiteSpace: 'break-word', fontWeight: 'bold'}}>Productomschrijving</div>
            <div className="subthread" style={{display: 'inline-block'}}/>
          </TableHeaderColumn>
          <TableHeaderColumn style={{width: "10%"}}>
            <div style={{fontWeight: 'bold'}}>Gekozen order actie</div>
            <div className="subthread" style={{display: 'inline-block'}}/>
          </TableHeaderColumn>
          <TableHeaderColumn>
            <div style={{fontWeight: 'bold'}}>Aantal</div>
            <div className="subthread" style={{display: 'inline-block'}}/>
          </TableHeaderColumn>
          <TableHeaderColumn>
            <span style={{fontWeight: 'bold'}}>Bruto prijs</span>
            <div className="subthread">maandelijks p/st</div>
          </TableHeaderColumn>
          <TableHeaderColumn>
            <span style={{fontWeight: 'bold'}}>Bruto prijs</span>
            <div className="subthread">eenmalig p/st</div>
          </TableHeaderColumn>
          <TableHeaderColumn style={{width: '15%'}}>
            <span style={{fontWeight: 'bold'}}>Korting</span>
            <div className="subthread">maandelijks p/st</div>
          </TableHeaderColumn>
          <TableHeaderColumn style={{width: '15%'}}>
            <span style={{fontWeight: 'bold'}}>Korting</span>
            <div className="subthread">eenmalig p/st</div>
          </TableHeaderColumn>
          <TableHeaderColumn>
            <span style={{fontWeight: 'bold'}}>Totale prijs</span>
            <div className="subthread">maandelijks</div>
          </TableHeaderColumn>
          <TableHeaderColumn>
            <span style={{fontWeight: 'bold'}}>Totale prijs</span>
            <div className="subthread">eenmalig</div>
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>
    )
  };

  generateProductRow = (productInGroup, productGroup, groupDiscounts, isCombinationDiscount) => {
    const productKey = productInGroup.isSwapstock ? productInGroup.id + "_SWAPSTOCK" : productInGroup.id;

    return (
      <GroupProductRow
        key={productKey}
        product={productInGroup}
        groupDiscounts={groupDiscounts || {}}
        isCustomCategory={productGroup.isCustomCategory}
        productGroup={productGroup}
        editable={!isCombinationDiscount}
        isVerlengenType={this.props.isVerlengenType}
      />
    );
  };

  toggleInCampus = ({target}) => {
    this.setState({ inCampus: target.checked });

    this.props.changeInCampus(target.checked);
  };

  renderBlock = (isCombinationDiscount = false) => {
    const {productGroup, isKpnRetail} = this.props;
    const {name, products} = productGroup;
    const productGroupName = name !== 'KPN IT Diensten' ? name : 'Microsoft IT Diensten';
    const groupDiscounts = isCombinationDiscount ? productGroup.combinationDiscounts : productGroup.discounts;

    return (
      <div className="row">
        <div className="col s12 overviewkopbg">
          <div className="overviewkop">{productGroupName}{isCombinationDiscount ? ' Combinatievoordeel' : ''}
            {
              productGroupName === 'Access opties' && isKpnRetail &&
              (
                <div className="s4 alignright additional-discount" style={{float: 'right'}}>
                  <input
                    type="checkbox"
                    className="filled-in"
                    id="inCampus"
                    checked={this.state.inCampus}
                    onChange={this.toggleInCampus}
                  />
                  <label htmlFor="inCampus">Bedrijventerreinen</label>
                </div>
              )
            }
          </div>
          {
            productGroupName === 'Access opties' && isKpnRetail &&
            (
              <div className="col s3" style={{float: 'right'}}>
                <input
                  name="inCampus"
                  className="input-field"
                  checked={!! this.state.inCampus}
                  id="inCampus"
                  type="checkbox"
                  onChange={this.toggleInCampus}
                />
                <label htmlFor="inCampus" style={{float: 'right'}}>Bedrijventerreinen</label>
              </div>
            )
          }
        </div>
        <RatioOverflowTableWrapper>
          <Table className="highlight without-margin-bottom"
                 selectable={false}
                 displayRowCheckbox={false}>
            {this.generateHeaders(productGroup)}
            <TableBody displayRowCheckbox={false}>
              {products.map((product) => (this.generateProductRow(product, productGroup, groupDiscounts, isCombinationDiscount)))}
            </TableBody>
          </Table>
          <div className="line30" style={{ marginBottom: '10px' }}/>
        </RatioOverflowTableWrapper>

        <KpnDialog
          dialogState={this.state.dialogOpened}
          onRequestClose={this._handleDeleteDialogClose}
          dialogHeader={<DialogHeader
            headerText='Meraki'
            headerSubtext='kortingen'
            headerIcon='add_location'
            closeHandler={this._handleDeleteDialogClose}
          />}
          dialogBody={
            <DialogBody>
              <DefaultDialogBody
                text='Weet u zeker dat u het standaard kortingsbedrag voor Meraki producten wilt aanpassen?'/>
            </DialogBody>
          }
          dialogFooter={
            <DialogFooter
              buttonLeft={<DialogButton left={true}
                                        buttonAction={this._handleDeleteDialogClose}
                                        buttonText='Nee'/>}
              buttonRight={<DialogButton buttonAction={this._handleDeleteDialogClose}
                                         buttonText='Ja'/>}
            />
          }
        />
      </div>
    );
  };

  render() {
    const {productGroup} = this.props;

    return <React.Fragment>
      {productGroup.maxDiscount > 0 ? this.renderBlock(false) : null}
      {!isEmpty(productGroup.combinationDiscounts) ? this.renderBlock(true) : null}
    </React.Fragment>;
  }
}

export default GroupProducts;
