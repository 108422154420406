import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import {Col, Row} from 'react-materialize';

import quotationActions from "../../../actions/quotationActions";
import telephonyActions from '../../../actions/workflow/telephonyActions';
import stepsActions from "../../../actions/stepsActions";
import locationActions from "../../../actions/locationActions";

import LoaderComponent from '../../../containers/common/LoaderComponent';
import CommunicationLocationLevel from "./CommunicationLocationLevel";
import CommunicationContractLevel from "./CommunicationContractLevel";

class CommunicationStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      profilesProcessing: false,
      showPerLocation: false,
      quotationType: this.props.quotation.currentQuotation.type,
      communicationOptions: props.communicationOptions,
      communicationActive: false,
    }
  }

  componentWillMount() {
    this.props.locationActions.resetSelectedLocationToGlobal();
  }

  componentDidMount() {
    this.props.telephonyActions.getTelephonyOfferProducts(this.props.quotationId, null).then(() => {this.setState({loading: false})});
    this.setState({ communicationActive: this.props.communicationOptions.communicationActive });
  }

  componentDidUpdate(prevProps) {
    const locationId = this.props.locations.selectedLocation.id > 0 ? this.props.locations.selectedLocation.id : null;

    if (prevProps.locations.selectedLocation.id !== this.props.locations.selectedLocation.id) {
      this.props.telephonyActions.getTelephonyOfferProducts(this.props.quotationId, locationId);
      this.setState({ showPerLocation: locationId > 0 });
    }

    if (prevProps.communicationOptions !== this.props.communicationOptions) {
      this.setState({ communicationOptions: this.props.communicationOptions});
    }

    if (prevProps.communicationOptions.communicationActive !== this.props.communicationOptions.communicationActive) {
      this.setState({ communicationActive: this.props.communicationOptions.communicationActive});
    }
  }

  updateCommunicationOptions = (e) => {
    if (this.props.quotation.status) {
      return;
    }

    const {target} = e;
    let {name, value, type, min, max} = target;
    let { communicationOptions } = this.state;

    if (target.type === 'number' && value !== '' && !value.match(/^\d+$/)) {
      toastr.error("Can't specify negative values - to be translated");
      return;
    }

    if (type === 'number' && max) {
      min = isNaN(min) ? 0 : min;
      value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    }

    this.props.telephonyActions.updateCommunicationOptions(this.props.quotationId, {...communicationOptions, [name]: value});
  };

  updateCommunicationActive = (value) => {
    let { communicationOptions } = this.state;
    this.props.telephonyActions.updateCommunicationOptions(this.props.quotationId, {...communicationOptions,communicationActive: !value});
  };

  render() {
    let showPerLocation = this.state.showPerLocation;
    let communicationOptions = this.state.communicationOptions || {};
    let communicationActive = this.state.communicationActive;

    const { theme } = this.props.authentication;
    const {inputsDisabled} = this.props.quotation.currentQuotation;

    return (
      <div>
        {this.state.loading
          ? <LoaderComponent theme={theme}/>
          : <React.Fragment>

            {!showPerLocation &&
            <div className="row" id="Forms">
              <Col>
                <Row>
                  <h1 className={'ratio-section-title'}>
                  <span className={'ratio-section-title'}>
                    <i className="small material-icons left ratio-section-title">phone_android</i>
                    Wenst u Communicatie?
                  </span>
                  </h1>
                </Row>
                <Row>
                  <div>
                    <button
                      className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${ communicationActive ? "active" : ""}`}
                      onClick={() => this.updateCommunicationActive(communicationActive)}
                      disabled={inputsDisabled}
                    >
                      Ja, activeer Communicatie
                    </button>
                  </div>
                </Row>
              </Col>
            </div>
            }

            {!showPerLocation && communicationActive &&
            <CommunicationContractLevel
              onChangeCallback={this.updateCommunicationOptions}
              communicationOptions={communicationOptions}
              inputsDisabled={inputsDisabled}
            />

            }

            {showPerLocation && communicationActive &&
            <CommunicationLocationLevel
              selectedMobileProducts={this.props.currentMobileProducts}
              mobileProductOptions={this.props.currentMobileProductOptions}
              mobileProducts={this.props.mobileProducts}
              inputsDisabled={inputsDisabled}
            />
            }
          </React.Fragment>
        }
      </div>
    )
  }
}

const mapStateToProps = ({quotation, mobile, authentication, locations, loading}) => {
  return {
    locations,
    quotation,
    quotationId: quotation.currentQuotation.id,
    mobileOptions: quotation.currentQuotation.mobileOptions,
    mobileProducts: mobile.mobileProducts,
    mobileDataProducts: mobile.mobileDataProducts,
    currentMobileProducts: mobile.currentMobileProducts,
    currentMobileProductOptions: mobile.currentMobileProductOptions,
    communicationOptions: quotation.currentQuotation.communicationOptions,
    authentication: authentication,
    loading
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    quotationActions: bindActionCreators(quotationActions, dispatch),
    telephonyActions: bindActionCreators(telephonyActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
    locationActions: bindActionCreators(locationActions, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationStep);
