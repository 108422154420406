const connectionTypes = {
    CONNECTION_TYPE_FIBER: 'Fiber',
    CONNECTION_TYPE_ADSL2_PLUS: 'ADSL2+',
    CONNECTION_TYPE_ADSL: 'ADSL',
    CONNECTION_TYPE_VDSL2: 'VDSL2',
    CONNECTION_TYPE_BVDSL: 'BVDSL',
    CONNECTION_TYPE_VDSL_OVER_POTS_BONDED_VECTORED: 'VDSL over POTS - bonded vectored',
    CONNECTION_TYPE_VDSL_OVER_POTS_VECTORED: 'VDSL over POTS - vectored',
    CONNECTION_TYPE_SDSL: 'SDSL',
    CONNECTION_TYPE_FTTH: 'FTTH',
    CONNECTION_TYPE_FTTB: 'FTTB',
    CONNECTION_TYPE_VPLUS: 'Vplus'
};

export default connectionTypes;

export const xdslConnectionTypes = [
    connectionTypes.CONNECTION_TYPE_ADSL2_PLUS,
    connectionTypes.CONNECTION_TYPE_ADSL,
    connectionTypes.CONNECTION_TYPE_BVDSL,
    connectionTypes.CONNECTION_TYPE_SDSL,
    connectionTypes.CONNECTION_TYPE_VDSL2,
    connectionTypes.CONNECTION_TYPE_VDSL_OVER_POTS_BONDED_VECTORED,
    connectionTypes.CONNECTION_TYPE_VDSL_OVER_POTS_VECTORED,
    connectionTypes.CONNECTION_TYPE_VPLUS
];