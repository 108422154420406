import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom'

import { Row, Col } from 'react-materialize';
import authenticationActions from '../../../actions/authenticationActions';
import Form from './form';

class Themes extends Component {
  handleSubmit = (data) => {
    const dataNormalized = { ...data };
    dataNormalized.backgroundOpacity /= 100;
    delete dataNormalized.currentLogo;
    delete dataNormalized.currentInvoiceLogo;
    delete dataNormalized.currentBackground;

    this.props.authenticationActions.updateTheme(dataNormalized);
  };

  render() {
      const { authentication: { theme } } = this.props;
      const customerTheme = { ...theme };

      customerTheme.palette = {
          primaryColor: theme.primaryColor,
          secondaryColor: theme.secondaryColor,
      };

      customerTheme.currentLogo = theme.logo;
      customerTheme.currentInvoiceLogo = theme.invoiceLogo;
      customerTheme.currentBackground = theme.background;
      customerTheme.logo = null;
      customerTheme.invoiceLogo = null;
      customerTheme.background = null;
      customerTheme.removeLogoFile = false;
      customerTheme.removeBackgroundFile = false;
      if (!customerTheme.backgroundOpacity && customerTheme.backgroundOpacity !== 0) {
          customerTheme.backgroundOpacity = 1;
      } else {
          customerTheme.backgroundOpacity *= 100;
      }

    return (
      <div>
        <div id="Forms" className="ratiotable paddingbottomnone">
          <h1 className="admin-subheader-text">
            <span className="ratio-section-title">Themes</span>
          </h1>
        </div>

        <Row>
            <Form data={customerTheme} handleSubmit={this.handleSubmit} />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication }) => {
  return {
    authentication
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch),
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Themes));
