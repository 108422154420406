import Logo from '../assets/images/ratio-logo.png';
import Background from '../assets/images/Doceri-background.png';
import Favicon from '../assets/images/favicon-doceri.svg';

export default {
  pageTitle: 'Doceri | Ratio',
  favicon: Favicon,
  background: Background,
  logo: Logo,
  primaryColor: '#F4960E',
  backgroundOpacity: 0.2,
  secondaryColor: '#353535',
  theadTextColor: '#000',
  mainTextColor: '#353535',
  formValidColor: '#4CAF50'
}
