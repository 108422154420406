import createReducer from "../createReducer.util";
import actionTypes from "../../constants/actionTypes";

const handlers = {
    [actionTypes.getQuotationOptions.init]: (state) => ({
        ...state,
        loading: true,
    }),

    [actionTypes.getQuotationOptions.success]: (state, {payload}) => ({
        ...state,
        loading: false,
        loaded: true,
        data: payload.response,
    }),

    [actionTypes.getQuotationOptions.error]: (state, {payload}) => ({
        ...state,
        loading: false,
        loaded: false,
        error: payload.error,
    }),

    [actionTypes.updateQuotationOptions.success]: (state, {payload}) => ({
        ...state,
        loading: false,
        loaded: true,
        data: payload.data,
    }),
};

const initialState = {
    loading: false,
    loaded: false,
    data: {
        pin: {},
        vpn: {},
        extra: {},
        backUp: {}
    },
    error: null,
};

const getQuotationOptionsReducer = createReducer(initialState, handlers)

export default getQuotationOptionsReducer;
