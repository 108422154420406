import React, {Component} from 'react';
import DialogBody from "./KpnDialog/DialogBody";
import GeneralDialog from "./GeneralDialog";

class OwnBrandVerlengenUsageDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            yipCustomerNumber: ''
        }
    }

    changeYipCustomerNumber = ({target: {value}}) => {
        this.setState({yipCustomerNumber: value})
    }

    render() {
        let {yipCustomerNumber} = this.state;
        let {dialogState, closeDialog, updateCustomerProperty} = this.props;

        return (
            <GeneralDialog
                dialogState={dialogState}
                closeHandler={() => closeDialog}
                headerText="De volgende velden dienen juist gevuld te worden."
                dialogBodyContentRaw={(
                    <DialogBody>
                        <div className="row">
                            <div className="input-field col s12 m12">
                                <span>
                                    Om bestaande orders te kunnen aanpassen hebben wij de YIP klant nummer nodig van de gekozen klant. Aub het correcte YIP klant nummer ingeven zodat we de meest recente data kunnen ophalen voor deze klant.
                                </span>
                            </div>
                        </div>
                        <div className="row"/>
                        <div className="row">
                            <div className="input-field col s12 m12">
                                <input
                                    id="yipCustomerNumber"
                                    type="text"
                                    required
                                    name="yipCustomerNumber"
                                    value={yipCustomerNumber}
                                    onChange={this.changeYipCustomerNumber}
                                    placeholder="YIP klant nummer"
                                />
                                <label htmlFor="yipCustomerNumber">YIP klant nummer</label>
                            </div>
                        </div>
                    </DialogBody>
                )}
                rightButtonAction={() => updateCustomerProperty(yipCustomerNumber)}
                rightButtonText="Opslaan"
            />
        )
    }
}

export default OwnBrandVerlengenUsageDialog;