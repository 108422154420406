import React from 'react';
import KpnDialog from "./KpnDialog/KpnDialog";
import DialogHeader from "./KpnDialog/DialogHeader";
import DialogBody from "./KpnDialog/DialogBody";
import DialogFooterFlex from "./KpnDialog/DialogFooterFlex";
import DialogButton from "./KpnDialog/DialogButton";

const dialogStyles = {
    footer: {
        buttonContainer: { minWidth: '200px' },
    },
};

class OdidoVerlengenUsageDialog extends React.Component {
    constructor(props) {
        super();
        this.state = {
            odidoBillingAccountCode: ''
        }
    }

    changeOdidoBillingAccountCode = ({target: {value}}) => {
        this.setState({odidoBillingAccountCode: value})
    }

    render() {
        let { verlengenDialogOpen, closeVerlengenUsageDialog, updateOdidoillingAccountCode } = this.props;
        let odidoBillingAccountCode = this.state.odidoBillingAccountCode;

        return (
            <KpnDialog
                dialogState={verlengenDialogOpen}
                onRequestClose={() => closeVerlengenUsageDialog()}
                modal={false}
                dialogHeader={(
                    <DialogHeader
                        headerText="De volgende velden dienen juist gevuld te worden."
                        closeHandler={() => closeVerlengenUsageDialog()}
                    />
                )}
                dialogBody={
                    <DialogBody>
                        <div className="row">
                            <div className="input-field col s12 m12">
                                <span>
                                    Om bestaande orders te kunnen aanpassen hebben wij de odido billing account nodig van de gekozen klant. Aub het correcte odido billing account ingeven zodat we de meest recente data kunnen ophalen voor deze klant.
                                </span>
                            </div>
                        </div>
                        <div className="row"/>
                        <div className="row">
                            <div className="input-field col s12 m12">
                                <input
                                    id="odidoBillingAccountCode"
                                    type="text"
                                    required
                                    name="odidoBillingAccountCode"
                                    value={odidoBillingAccountCode}
                                    onChange={this.changeOdidoBillingAccountCode}
                                    placeholder="Klant billing account code"
                                />
                                <label htmlFor="odidoBillingAccountCode">Billing account code</label>
                            </div>
                        </div>
                    </DialogBody>
                }
                dialogFooter={
                    <DialogFooterFlex>
                        <div className={"col s12 m12"}>
                            <div style={dialogStyles.footer.buttonContainer}>
                                <DialogButton
                                    buttonAction={() => updateOdidoillingAccountCode(odidoBillingAccountCode)}
                                    buttonText='Opslaan'
                                />
                            </div>
                        </div>
                    </DialogFooterFlex>
                }
            >

            </KpnDialog>
        )
    }
}

export default OdidoVerlengenUsageDialog;