import React from 'react';
import {Col, Icon, Input, Row} from "react-materialize";

import providerType from "../../../constants/providerType";

const MobileProfiles = ({
                          isVerlengenType,
                          inputsDisabled,
                          showMobileSelections,
                          showMobileProducts,
                          showMobileYears,
                          isUsingExistingData,
                          isVodafoneQuotation,
                          hasActiveEndDateOptions,
                          selectedMobileProducts,
                          mobileOptions,
                          mobileProducts,
                          provider,
                          onQuantityChangeCallback,
                          onQuantitySaveAction,
                          onChangeCallback,
                          renderExtraSelect,
                          getChildSelections,
                          addNewProductSelection,
                          hasEndDateOptions,
                          updateEndDateOptions,
                          profilesLoader,
                            isOdidoPortfolio
                        }) => (
  <React.Fragment>
        {showMobileSelections && showMobileProducts && showMobileYears && ((!isVerlengenType || (isVerlengenType && !isUsingExistingData) || (isVerlengenType && isOdidoPortfolio))) &&
        selectedMobileProducts.map((selection, indexSelection) => {

          if (isOdidoPortfolio) {
            // get currently selected profile name
            const index = mobileProducts.findIndex(product => product.id === selection.productId);
            const currentProfile = mobileProducts[index];
            const profileOptions = mobileOptions[selection.id];
            const hasMobileOptions = profileOptions && profileOptions.length;

            if (index !== -1 && currentProfile && hasMobileOptions) {
              let priorityOptions = [];
              let idsToFilter = [];

              profileOptions.forEach((option) => {
                if (option.category.display_order !== null) {
                  priorityOptions.push(option);
                  idsToFilter.push(option.category.id);
                }
              });

              priorityOptions.sort((a, b) => a.category.display_order > b.category.display_order ? 1 :
                (b.category.display_order > a.category.display_order ? -1 : 0));

              let filtered = profileOptions.filter((option) => !idsToFilter.includes(option.category.id));
              mobileOptions[selection.id] = priorityOptions.concat(filtered);
            }
          }

            return (
                <Col s={12} key={indexSelection}>
                    <Row style={{paddingTop: '20px'}}>
                        <div className="line tussenkop">Stel hier het gewenste profiel in</div>
                    </Row>

                    <Row>
                        {mobileOptions[selection.id]
                        && (selection.allowed_quantities === null || (selection.allowed_quantities && selection.allowed_quantities.max > 1)) ?
                            (
                                <Input
                                    s={1}
                                    name='amount'
                                    type='number'
                                    label='Aantal'
                                    disabled={inputsDisabled || profilesLoader}
                                    value={isVerlengenType ? (selection.newQuantity > 0 ? selection.newQuantity : 1) : (selection.quantity > 0 ? selection.quantity : 1)}
                                    onChange={onQuantityChangeCallback(selection.id, selection.productId)}
                                    onBlur={onQuantitySaveAction}
                                    min={selection.allowed_quantities ? selection.allowed_quantities.min : 1}
                                    max={selection.allowed_quantities ? selection.allowed_quantities.max : 999}
                                />) : null}
                        <Input
                            s={mobileOptions[selection.id] && (selection.allowed_quantities === null || (selection.allowed_quantities && selection.allowed_quantities.max > 1)) ? 11 : 12}
                            name={"selection_offer_product"}
                            type='select'
                            label="Profielen"
                            disabled={inputsDisabled || profilesLoader}
                            onChange={onChangeCallback(selection.id)}
                            value={selection.productId}
                            multiple={false}>
                            <option value={0}>Niets geselecteerd</option>
                            {
                                mobileProducts.filter((p) => p.isDataOnly !== true).map((el) => {
                                    return <option key={el.name} value={el.id}>{el.name}</option>
                                })
                            }
                        </Input>
                    </Row>

                    {mobileOptions[selection.id] && mobileOptions[selection.id].map((option, index) => {
                        // put the selection id on the option
                        option.selectionId = selection.id;
                        option.bundleSelectionQuantity = isVerlengenType ? selection.newQuantity : selection.quantity;

                        let extraSelect = [];
                        extraSelect.push(renderExtraSelect(option, index));

                        if (provider !== providerType.VODAFONE) {
                            getChildSelections(option).map((childOption, childIndex) => {

                                let parentQuantity = 0;
                                option.selectedProducts.map(product => {
                                    parentQuantity += product.quantity;
                                });

                                childOption.bundleSelectionQuantity = parentQuantity;
                                extraSelect.push(renderExtraSelect(childOption, index + '-' + childIndex));
                            });
                        }

                        return extraSelect;
                    })}

                    {isVodafoneQuotation && mobileOptions[selection.id] && hasEndDateOptions(mobileOptions[selection.id]) &&
                    (
                        <Input
                            s={12}
                            name={"hasActiveEndDateOptions"}
                            type='select'
                            label="Wil je de einddatum voor alle sim only profielen gelijk laten zetten?"
                            disabled={inputsDisabled}
                            onChange={(event) => updateEndDateOptions(event)}
                            value={hasActiveEndDateOptions}
                            multiple={false}
                        >
                            <option value={true}>Ja</option>
                            <option value={false}>Nee</option>
                        </Input>
                    )}
                </Col>);
        })
        }

        <Col s={12}>
            <div className="line tussenkop"/>
        </Col>

        {showMobileSelections && showMobileProducts && showMobileYears && (
            <Col s={12}>
                <button
                    name="addMobileProfile"
                    className={'btn ratio-btn white-text ratio-btn-right right ' + (inputsDisabled ? '' : 'doceri-btn-left')}
                    onClick={addNewProductSelection}
                    disabled={inputsDisabled}
                    style={profilesLoader ? {minWidth: '150px'} : null}
                >
                    {
                        profilesLoader ?
                            (
                                <span className="progress secondaryBackgroundColor" style={{marginTop: '20px'}}>
                <span className="indeterminate overviewkopbggroen"/>
              </span>
                            ) : (isVerlengenType ? 'Mobiele profiel toevoegen' : 'Nog een profiel toevoegen')
                    }
                    <Icon right>
                        add
                    </Icon>
                </button>
            </Col>
        )
        }
  </React.Fragment>
);

export default MobileProfiles
