import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import Moment from "react-moment";

class QuotationOrderProductsTable extends Component {
  getIcon(sortDirection){
    switch (sortDirection){
      case "ASC":
        return 'arrow_downward';
      case "DESC":
        return 'arrow_upward';
      case false:
        return 'swap_vert'
    }
  }

  generateHeader() {
    return (
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow onCellClick={this.props.onHeaderClick}>
          <TableHeaderColumn className="clickable" style={{width: '5%'}}>#</TableHeaderColumn>

          <TableHeaderColumn className="clickable" headername="Product" style={{width: '30%'}}>
            Product naam
            <i className="material-icons right lightgrey">
              {this.getIcon(this.props.sortingState[this.props.getPropertyNameFromDisplayName('Product')])}
            </i>
          </TableHeaderColumn>

          <TableHeaderColumn className="clickable" headername="Product Code">
              Product Code
              <i className="material-icons right lightgrey">
                  {this.getIcon(this.props.sortingState[this.props.getPropertyNameFromDisplayName('Product Code')])}
              </i>
          </TableHeaderColumn>

          <TableHeaderColumn className="clickable" headername="OrderID" style={{width: '8%'}}>
            OrderID
            <i className="material-icons right lightgrey">
              {this.getIcon(this.props.sortingState[this.props.getPropertyNameFromDisplayName('OrderID')])}
            </i>
          </TableHeaderColumn>

          <TableHeaderColumn className="clickable">Product groep</TableHeaderColumn>

          <TableHeaderColumn className="clickable" headername="Status">
            Status
            <i className="material-icons right lightgrey">
              {this.getIcon(this.props.sortingState[this.props.getPropertyNameFromDisplayName('Status')])}
            </i>
          </TableHeaderColumn>

          <TableHeaderColumn className="clickable" headername="Last update">
            Laatste update
            <i className="material-icons right lightgrey">
              {this.getIcon(this.props.sortingState[this.props.getPropertyNameFromDisplayName('Last update')])}
            </i>
          </TableHeaderColumn>

          <TableHeaderColumn className="clickable" style={{width: '8%'}}>Details</TableHeaderColumn>
        </TableRow>
      </TableHeader>
    );
  }

  generateRow(row) {
    const colors = this.props.colors(row.status, row.isPending);

    return (
      <TableRow key={row.id}>
        <TableRowColumn style={{width: '5%'}}>{row.id}</TableRowColumn>
        <TableRowColumn style={{width: '30%'}}>{row.productName}</TableRowColumn>
        <TableRowColumn>{row.productNumber}</TableRowColumn>
        <TableRowColumn style={{width: '8%', color: colors?.orderIdColor, fontWeight: 500}}>{row.orderId}</TableRowColumn>
        <TableRowColumn>{row.productGroup}</TableRowColumn>
        <TableRowColumn style={{color: colors?.statusColor, fontWeight: 500}}>{row.status}</TableRowColumn>
        <TableRowColumn>{<Moment format="YYYY-MM-DD h:mmA">{ new Date(row.lastUpdate)}</Moment>}</TableRowColumn>
        <TableRowColumn style={{width: '8%'}}>
          <a href="#" onClick={() => this.props.onViewProductDetailsClick(row)}>
            <i className="small material-icons left themeprimarycolor">remove_red_eye</i>
          </a>
        </TableRowColumn>
      </TableRow>
    );
  }

  render() {
    return (
      <Table
        wrapperStyle={{ overflow: 'auto' }}
        onRowSelection={this.props.handleRowSelection}
        selectable={false}
        bodyStyle={{overflow: 'unset'}}
      >
        {this.generateHeader()}

        <TableBody displayRowCheckbox={false}>
          {this.props.rows.map(row => this.generateRow(row))}
        </TableBody>
      </Table>
    );
  }
}

QuotationOrderProductsTable.propTypes = {
  headersNames: PropTypes.array.isRequired,
  propertiesToShow: PropTypes.array.isRequired,
  handleRowSelection: PropTypes.func,
  rows: PropTypes.array.isRequired,
  onHeaderClick: PropTypes.func,
  sortingState: PropTypes.any,
  getPropertyNameFromDisplayName: PropTypes.func
};

export default QuotationOrderProductsTable;
