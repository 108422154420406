import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";

import quotationActions from "../../actions/quotationActions";
import discountActions from "../../actions/workflow/discountActions";

import GeneralDialog from "./GeneralDialog";
import DefaultDialogBody from "./KpnDialog/DefaultDialogBody";

class RefreshQuotation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showRefreshDialog: false
    }
  }

  showRefreshDialog = (e) => {
    e.preventDefault();
    this.setState({showRefreshDialog: true});
  };

  hideRefreshDialog = () => {
    this.setState({showRefreshDialog: false})
  };

  refreshQuotationPrices = () => {
    this.props.quotationActions.refreshQuotationPrices(this.props.quotationId)
      .then(() => {
        this.props.discountActions.getDiscountState(this.props.quotationId, false)

        this.setState({
          showRefreshDialog: false
        });
      });
  };

  render() {
    let className = this.props.className;
    if (this.props.renderAsButton) {
      className += ' btn doceri-btn-right ratio-btn-white-right';
    }

    return (
      <Fragment>
        <a
          href="#"
          className={className}
          style={{...this.props.style}}
          onClick={this.showRefreshDialog}
        >
          <i className={`material-icons left ${!this.props.renderAsButton && 'themeprimarycolor'}`}>
            refresh
          </i>
        </a>

        <GeneralDialog
          dialogState={this.state.showRefreshDialog}
          closeHandler={this.hideRefreshDialog}
          headerText='Offerte verversen?'
          dialogBodyContent={<DefaultDialogBody text='Door op ja te klikken worden alle prijzen van deze offerte ververst.'/>}
          leftButtonLeft={true}
          leftButtonAction={this.hideRefreshDialog}
          leftButtonText={'Nee'}
          rightButtonAction={this.refreshQuotationPrices}
          rightButtonText={'Ja'}
          />
      </Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    quotationActions: bindActionCreators(quotationActions, dispatch),
    discountActions: bindActionCreators(discountActions, dispatch),
  };
};

RefreshQuotation.propTypes = {
  quotationId: PropTypes.number.isRequired,
  renderAsButton: PropTypes.bool
};

export default connect(
  null,
  mapDispatchToProps,
)(RefreshQuotation);
