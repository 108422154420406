import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hasRequiredRole, hasAllRequiredRoles } from '../utils/authorization';

const Authorize = ({ children, currentUserRoles, allowedRoles, needAll, unauthorizedRender }) => {
  let hasRequiredRoles = false;

  if (needAll) {
    hasRequiredRoles = hasAllRequiredRoles(currentUserRoles, allowedRoles);
  } else {
    hasRequiredRoles = hasRequiredRole(currentUserRoles, allowedRoles);
  }

  if(!hasRequiredRoles) return unauthorizedRender;

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
}

Authorize.defaultProps = {
  needAll: false,
  unauthorizedRender: null,
};

Authorize.propTypes = {
  currentUserRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  needAll: PropTypes.bool,
  unauthorizedRender: PropTypes.node,
};

const mapStateToProps = (state, ownProps) => ({
  currentUserRoles: state.authentication.roles,
});
 
export default connect(mapStateToProps)(Authorize);
