import {actionTypes} from '../constants/actionTypes';
import {apiRoutes} from '../constants/apiRoutes';
import {toastr} from 'react-redux-toastr'

import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

const getAllTags = (queryParams) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.tagRelated.getAll + (queryParams ? queryParams : '');
      let requestData = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getAllTagsSuccess(requestData));
      dispatch(savePaginationParameters(requestData))
    }
    catch ({message}) {
      toastr.error('Het is niet mogelijk de lijst met offertes op te halen', message);
    }
  }
};

const getCategoriesToLink = () => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.tagRelated.getCategoriesToLink;
      let response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(setCategoriesToLink(response));
    } catch (error) {
      return {error};
    }
  }
};

const setCategoriesToLink = ({categories}) => {
  return {
    type: actionTypes.setTagCategoriesToLink,
    payload: categories
  }
};

const getAllTagsSuccess = ({tags}) => {
  return {
    type: actionTypes.getAllTags,
    payload: tags
  };
};

const savePaginationParameters = ({currentPage, resultsCount, pages}) => {
  return {
    type: actionTypes.savePagination,
    payload: {
      currentPage,
      resultsCount,
      pages
    }
  }
};

const patchTag = (tagId, data) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.tagRelated.patchTag.replace('{tagId}', tagId);

      const response = await ApiService.performRequest(RequestType.PATCH, url, data);

      toastr.success('Tag bewerkt', '');
      dispatch(_patchTagSuccess(response));
    }
    catch ({message}) {
      toastr.error('Kan de tag niet updaten', message);
    }
  }
};

const _patchTagSuccess = ({tag}) => {
  return {
    type: actionTypes.patchTag,
    payload: tag
  }
};

const addTag = (data) => {
  return async () => {
    try {
      const url = apiRoutes.tagRelated.addTag;

      await ApiService.performRequest(RequestType.POST, url, data);

      toastr.success('Tag bewerkt', '');
    }
    catch ({message}) {
      toastr.error('Kan de tag niet updaten', message);
    }
  }
};

export default {
  getAllTags,
  getCategoriesToLink,
  patchTag,
  addTag
}
