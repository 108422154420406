import './assets/css/Default-theme.css';
import './assets/css/KPN-Theme.css';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './store';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import ThemeStyled from './components/ThemeStyled';
import themes from './components/themes';
import GlobalStyles from './components/GlobalStyles';

import RatioApp from './RatioApp';


import {bindActionCreators} from "redux";
import authenticationActions from "./actions/authenticationActions";
import Maintenance from "./components/Maintenance";


class AppRouter extends Component {
  componentWillMount() {
    this.props.authenticationActions.getUserInfo();
  }

  getStyledTheme = () => {
    const { authentication: { theme } } = this.props;

    return themes.normalizeTheme(theme);
  };

  componentDidMount() {
    const { theme, isLoggedIn } = this.props.authentication;

    if (!isLoggedIn) {
      this.props.authenticationActions.getOrganizationInfo();
    }

    document.title = theme.pageTitle;

    let favicon = document.querySelector('link[rel="shortcut icon"]');
    favicon.setAttribute('type', 'image/x-icon');
    favicon.setAttribute('href', theme.favicon);
  }

  render() {
    const styledTheme = this.getStyledTheme() || {};
    const maintenanceMode = Number(process.env.REACT_APP_MAINTENANCE_MODE);
    return (
      <ThemeProvider theme={styledTheme}>
        <ThemeStyled>
          <GlobalStyles />
            {
                maintenanceMode ? <Maintenance/> : <RatioApp/>
            }
        </ThemeStyled>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch)
  };
};

const AppRouterContainer = connect(mapStateToProps, mapDispatchToProps)(AppRouter);

const App = () => (
  <Provider store={store}>
      <AppRouterContainer />
  </Provider>
);

export default App;
