const actionTypes = {
    //authentication related
    loginSuccess: 'LOGIN_SUCCESS',
    loginFailed: 'LOGIN_FAILED',
    logoutSuccess: 'LOGOUT_SUCCESS',
    setUserDetails: 'SET_USER_DETAILS',
    setUserInfoIsLoading: 'SET_USER_INFO_IS_LOADING',
    loginCheckSuccess: 'LOGIN_CHECK_SUCCESS',
    setExpiredSession: 'SET_EXPIRED_SESSION',
    updateThemeSuccess: 'UPDATE_THEME_SUCCESS',
    getSwitchableOrganizationsSuccess: 'GET_SWITCHABLE_ORGANIZATIONS_SUCCESS',
    switchOrganizationSuccess: 'SWITCH_ORGANIZATION_SUCCESS',

    //alert related
    setCurrentSoftAlert: 'SET_CURRENT_SOFT_ALERT',
    setCurrentHardAlert: 'SET_CURRENT_HARD_ALERT',
    addSoftAlert: 'ADD_SOFT_ALERT',
    addHardAlert: 'ADD_HARD_ALERT',
    removeSpecificSoftAlert: 'REMOVE_SPECIFIC_SOFT_ALERT',
    removeSpecificHardAlert: 'REMOVE_SPECIFIC_HARD_ALERT',
    resetAlerts: 'RESET_ALERTS',
    resetSoftAlerts: 'RESET_SOFT_ALERTS',
    resetHardAlerts: 'RESET_HARD_ALERTS',
    removeLastSoftAlert: 'REMOVE_LAST_SOFT_ALERT',
    removeLastHardAlert: 'REMOVE_LAST_HARD_ALERT',
    setAlertActive: 'SET_ALERT_ACTIVE',

    updateOverviewData: 'UPDATE_OVERVIEW_DATA',
    updateIsLead: 'UPDATE_IS_LEAD',

    //steps related
    setCurrentStep: 'SET_CURRENT_STEP',
    setQuotationProvider: 'SET_QUOTATION_PROVIDER',
    setDesiredApplicationSystem: 'SET_DESIRED_APPLICATION_SYSTEM',
    setFlashDefaultProducts: 'SET_FLASH_DEFAULT_PRODUCTS',
    addFlashProductQuantity: 'ADD_FLASH_PRODUCT_QUANTITY',
    updateHardwareProducts: 'UPDATE_HARDWARE_PRODUCTS',
    getApplicationData: 'GET_APPLICATION_DATA',
    setQuotationType: 'SET_QUOTATION_TYPE',
    setAvailableToGoForward: 'SET_AVAILABLE_TO_GO_FORWARD',
    setAvailabilityMessage: 'SET_AVAILABILITY_MESSAGE',
    setCustomers: 'SET_CUSTOMERS',
    setSelectedCustomer: 'SET_SELECTED_CUSTOMER',
    setIsSyncOpportunityEmail: 'SET_IS_SYNC_OPPORTUNITY_EMAIL',
    setCustomerIrmaID: 'SET_CUSTOMER_IRMA_ID',
    setCustomerName: 'SET_CUSTOMER_NAME',
    updateContactInfo: 'UPDATE_CONTACT_INFO',
    updateTechnicalContact: 'UPDATE_TECHNICAL_CONTACT',
    updateProjectManagerContact: 'UPDATE_PROJECT_MANAGER_CONTACT',
    updateContactInfoAdditionalProperties:
        'UPDATE_CONTACT_INFO_ADDITIONAL_PROPERTIES',
    setCustomerLocations: 'SET_CUSTOMER_LOCATIONS',
    goToPreviousStep: 'GO_TO_PREVIOUS_STEP',
    goToNextStep: 'GO_TO_NEXT_STEP',
    setCustomerOdidoBillingAccountCode: 'SET_CUSTOMER_ODIDO_BILLING_ACCOUNT_CODE',
    setYipCustomerNumber: 'SET_YIP_CUSTOMER_NUMBER',
    setEnreachAccountId: 'SET_ENREACH_ACCOUNT_ID',

    //location related
    setSelectedLocation: 'SET_SELECTED_LOCATION',
    resetSelectedLocation: 'RESET_SELECTED_LOCATION',
    updateSelectedLocation: 'UPDATE_SELECTED_LOCATION',
    setLocationsAsInvalid: 'SET_LOCATION_AS_INVALID',
    setQuotationId: 'SET_QUOTATION_ID',
    updateQuotationProperty: 'UPDATE_QUOTATION_PROPERTY',
    createNewQuotation: 'CREATE_NEW_QUOTATION',
    setCustomerForQuotation: 'SET_CUSTOMER_FOR_QUOTATION',
    updateExistingLocationInDb: 'UPDATE_EXISTING_LOCATION_IN_DB',
    addNewLocationInDb: 'ADD_NEW_LOCATION_IN_DB',
    removeLocation: 'REMOVE_LOCATION',
    uploadBulk: 'UPLOAD_BULK',
    setLocationAddress: 'SET_LOCATION_ADDRESS',
    isRefusedPostcode: 'IS_REFUSED_POSTCODE',
    setAddresses: 'SET_ADDRESSES',
    closeEditionDialog: 'CLOSE_EDITION_DIALOG',
    openEditionDialog: 'OPEN_EDITION_DIALOG',
    setIsraServiceErrors: 'SET_ISRA_SERVICE_ERRORS',
    openIsraDialog: 'OPEN_ISRA_DIALOG',
    closeIsraDialog: 'CLOSE_ISRA_DIALOG',
    setIsraDialogData: 'SET_ISRA_DIALOG_DATA',
    toggleIsraLoading: 'TOGGLE_ISRA_LOADING',

    searchLocationsList: 'SEARCH_LOCATION_LIST',
    setLocationAddressToEmptyString: 'SET_LOCATION_ADDRESS_TO_EMPTY_STRING',
    changeSortingOfLocations: 'CHANGE_SORTING_OF_LOCATIONS',
    fillGlobalLocationProperties: 'FILL_GLOBAL_LOCATION_PROPERTIES',
    updateIsraLocation: 'UPDATE_ISRA_LOCATION',
    downloadExampleFile: 'DOWNLOAD_EXAMPLE_FILE',
    locationIsraCheckSuccess: 'LOCATION_ISRA_CHECK_SUCCESS',
    locationIsraCheckError: 'LOCATION_ISRA_CHECK_ERROR',
    connectionAllowed: 'CONNECTION_ALLOWED',
    updateNtProperties: 'UPDATE_NT_PROPERTIES',
    connectionHasFtthException: 'CONNECTION_HAS_FTTH_EXCEPTION',
    connectionAllowedIsdn: 'CONNECTION_ALLOWED_ISDN',

    //quotations related
    getQuotationSuccess: 'GET_QUOTATION_SUCCESS',
    getQuotationChangesSuccess: 'GET_QUOTATION_CHANGES_SUCCESS',
    updateQuotationSuccess: 'UPDATE_QUOTATION_SUCCESS',
    setOrdering: 'SET_QUOTATION_ORDERING',
    getAllQuotations: 'GET_ALL_QUOTATIONS',
    deleteQuotation: 'DELETE_QUOTATION',
    getXML: 'GET_XML',
    getDealSheet: 'GET_DEALSHEET',
    getChecklist: 'GET_CHECKLIST',
    getKwtXls: 'GET_KWT_XLS',
    getMobileBulkExampleFile: 'GET_MOBILE_BULK_XLS',
    getVoiceBulkExampleFile: 'GET_VOICE_BULK_XLS',
    clearSelectedCustomer: 'CLEAR_SELECTED_CUSTOMER', // when creating new quotation, previously selected customer should be made empty
    getQuotationLocationsFile: 'GET_LOCATIONS_XLS',
    savePagination: 'SAVE_PAGINATION',
    uploadAKBSuccess: 'UPLOAD_AKB_SUCCESS',
    bulkImportSuccess: 'BULK_IMPORT_SUCCESS',
    saveAKBTelephoneNumberSuccess: 'SAVE_AKB_TELEPHONE_NUMBER_SUCCESS',
    saveAllAKBTelephoneNumbersSuccess: 'SAVE_ALL_AKB_TELEPHONE_NUMBERS_SUCCESS',
    saveTelephoneNumbersSuccess: 'SAVE_TELEPHONE_NUMBERS_SUCCESS',
    getTelephoneNumbersSuccess: 'GET_AKB_TELEPHONE_NUMBERS_SUCCESS',
    toggleFlagForAllAKBNumbersSuccess: 'AKB_TOGGLE_FLAG_FOR_ALL_AKB_NUMBERS',
    deleteAKBTelephoneNumberSuccess: "DELETE_AKB_TELEPHONE_NUMBER_SUCCESS",
    deleteAllMobileOptions: "DELETE_ALL_MOBILE_OPTIONS",
    updatePhoneNumber: "UPDATE_PHONE_NUMBER",
    updatePhoneNumberActionStatus: "UPDATE_PHONE_NUMBER_ACTION_STATUS",
    getPhoneNumbers: "GET_PHONE_NUMBER",
    updateAccessProducts: "UPDATE_ACCESS_PRODUCTS",
    getAccessProducts: "GET_ACCESS_PRODUCTS",
    updateVoiceProducts: "UPDATE_VOICE_PRODUCTS",
    getVoiceProducts: "GET_VOICE_PRODUCTS",
    patchMobileSettingsData: 'PATCH_MOBILE_SETTINGS_DATA',
    getUpgradingData: 'GET_UPGRADING_DATA',
    sendDynamicsDataSuccess: 'SEND_DYNAMICS_DATA_SUCCESS',
    sendEmailToCustomerSuccess: 'SEND_EMAIL_TO_CUSTOMER_SUCCESS',
    setSyncStatusSuccess: 'SET_SYNC_STATUS_SUCCESS',
    setSalesValueMessage: 'SET_SALES_VALUE_MESSAGE',
    getPresetDiscountValidity: 'GET_PRESET_DISCOUNT_VALIDITY',

    //admin panel related
    getOrganization: 'GET_ORGANIZATION',
    getAllOrganizations: 'GET_ALL_ORGANIZATIONS',
    editOrganization: 'EDIT_ORGANIZATION',
    getDiscountProduct: 'GET_DISCOUNT_PRODUCT',
    editDiscountProduct: 'EDIT_DISCOUNT_PRODUCT',
    getUsers: 'GET_USERS',
    deleteUser: 'DELETE_USER',
    editUser: 'EDIT_USER',
    addUser: 'ADD_USER',
    getCategories: 'GET_CATEGORIES',
    getCompensation: 'GET_COMPENSATIONS',
    updateCompensation: 'UPDATE_COMPENSATIONS',
    setCurrentUser: 'SET_CURRENT_USER',
    getFlashCalculations: 'GET_FLASH_CALCULATIONS',
    updateFlashCalculation: 'UPDATE_FLASH_CALCULATION',

    // products related
    setAllProducts: 'SET_ALL_PRODUCTS',
    setProductPagination: 'SET_PRODUCT_PAGINATION',
    setProductsProvider: 'SET_PRODUCT_PROVIDER',

    //tags related
    getAllTags: 'GET_ALL_TAGS',
    setTagCategoriesToLink: 'SET_TAG_CATEGORIES_TO_LINK',
    patchTag: 'PATCH_TAG',
    addTag: 'ADD_TAG',
    getAllTagCategories: 'GET_ALL_TAG_CATEGORIES',
    patchTagCategory: 'PATCH_TAG_CATEGORY',

    //order wizard related
    getOrderWizardDetails: 'GET_ORDER_WIZARD_DETAILS',
    updateOrderWizardDetails: 'UPDATE_ORDER_WIZARD_DETAILS',
    getOrderWizardDetailOptions: 'GET_ORDER_WIZARD_DETAIL_OPTIONS',
    getOrderAddressList: 'GET_ORDER_ADDRESS_LIST',
    addOrderAddress: 'ADD_ORDER_ADDRESS',
    updateOrderAddress: 'UPDATE_ORDER_ADDRESS',
    removeOrderAddress: 'REMOVE_ORDER_ADDRESS',

    //postcode related
    getAllPostcodes: 'GET_ALL_POSTCODES',
    setPostcodeCategoriesToLink: 'SET_POSTCODE_CATEGORIES_TO_LINK',
    patchPostcode: 'PATCH_POSTCODE',
    addPostcode: 'ADD_POSTCODE',
    deletePostcode: 'DELETE_POSTCODE',
    getAllPostcodeCategories: 'GET_ALL_POSTCODE_CATEGORIES',
    patchPostcodeCategory: 'PATCH_POSTCODE_CATEGORY',
    deletePostcodeCategory: 'DELETE_POSTCODE_CATEGORY',

    //admin menu items
    setCurrentAdminSection: 'SET_CURRENT_ADMIN_SECTION',
    getAdminMenuItems: 'GET_ADMIN_MENU_ITEMS',

    // access step related
    updateAccessGlobalSettings: 'UPDATE_ACCESS_GLOBAL_SETTINGS',
    updateAccessLocationConnection: 'UPDATE_ACCESS_LOCATION_CONNECTION',
    updateAccessLocation: 'UPDATE_ACCESS_LOCATION',
    getAccessState: 'GET_ACCESS_STATE',
    getGlobalAccessOptions: 'GET_GLOBAL_ACCESS_OPTIONS',
    getLocationsAccessOptions: 'GET_LOCATIONS_ACCESS_OPTIONS',
    getOrganizationHip: 'GET_ORGANIZATION_HIP',
    updateAsUnmangedAccess: 'UPDATE_AS_UNMANAGED',
    getExistingAccessData: 'GET_EXISTING_ACCESS_DATA',
    updateLocationSdWanOptions: 'UPDATE_LOCATION_SD_WAN_OPTIONS',
    updateSdWanLocationOption: 'UPDATE_SD_WAN_LOCATION_OPTION',
    updateSdWanLocationProduct: 'UPDATE_SD_WAN_LOCATION_PRODUCT',
    updateLocationSdWanActiveSuccess: 'UPDATE_SD_WAN_ACTIVE',
    updateAccessDataRetrieval: 'UPDATE_ACCESS_DATA_RETRIEVAL',

    //connections related
    getConnectionsAvailabilityPerLocation:
        'GET_CONNECTIONS_AVAILABILITY_PER_LOCATION',
    getConnectionsListPerLocation: 'GET_CONNECTION_LIST_PER_LOCATION',

    // sdWan related
    getSdWanSettings: 'GET_SDWAN_SETTINGS',
    updateSdWanGlobalSettings: 'UPDATE_SDWAN_GLOBAL_SETTINGS',
    getSdWanGlobalOptions: 'GET_SDWAN_GLOBAL_OPTIONS',
    updateSdWanLocationSettings: 'UPDATE_SDWAN_LOCATION_SETTINGS',
    getSdWanLocationOptions: 'GET_SDWAN_LOCATION_OPTIONS',
    setIsSdWanNeeded: 'SET_IS_SD_WAN_NEEDED',

    //discount related
    getDiscountState: 'GET_DISCOUNT_STATE',
    getDiscountErrors: 'GET_DISCOUNT_ERRORS',
    updateDiscountStateSuccess: 'UPDATE_DISCOUNT_STATE_SUCCESS',
    updateTotalsState: 'UPDATE_TOTALS_STATE',
    resetDiscountErrors: 'RESET_DISCOUNT_ERRORS',
    updateKwtStateSuccess: 'UPDATE_KWTCODE_STATE_SUCCESS',
    updateExtraKwtStateSuccess: 'UPDATE_EXTRA_KWTCODE_STATE_SUCCESS',
    updateInCampusSuccess: 'UPDATE_IN_CAMPUS',

    // word document related
    getWordDocuments: 'GET_WORD_DOCUMENTS',
    addWordDocument: 'ADD_WORD_DOCUMENT',
    editWordDocument: 'EDIT_WORD_DOCUMENT',
    deleteWordDocument: 'DELETE_WORD_DOCUMENT',
    downloadWordDocument: 'DOWNLOAD_WORD_DOCUMENT',
    downloadConvertedWordDocument: 'DOWNLOAD_CONVERTED_WORD_DOCUMENT',

    // quotation document related
    getQuotationDocuments: 'GET_QUOTATION_DOCUMENTS',
    addQuotationDocument: 'ADD_QUOTATION_DOCUMENT',
    downloadQuotationDocument: 'DOWNLOAD_QUOTATION_DOCUMENT',

    // signed document related
    getSignedDocument: 'GET_SIGNED_DOCUMENT',
    addSignedDocument: 'ADD_SIGNED_DOCUMENT',
    downloadSignedDocument: 'DOWNLOAD_SIGNED_DOCUMENT',
    deleteSignedDocument: 'DELETE_SIGNED_DOCUMENT',
    updateSignedDocument: 'UPDATE_SIGNED_DOCUMENT',

    // organization providers logos related
    getLogos: 'GET_LOGO_FILES',
    deleteLogoFile: 'DELETE_LOGO_FILE',
    downloadLogoFile: 'DOWNLOAD_LOGO_FILE',
    setOrganizationProviderFilter: 'SET_ORGANIZATION_PROVIDER_FILTER',
    setLogosPagination: 'SET_LOGO_PAGINATION',

    //terms and conditions related
    deleteTermsAndConditionsFile: 'DELETE_TERMS_AND_CONDITIONS_FILE',
    getOrganizationTermsAndConditionsFiles: 'GET_TERMS_AND_CONDITIONS_FILES',
    downloadTermsAndConditionsFile: 'DOWNLOAD_TERMS_AND_CONDITION_FILES',
    addOrganizationTermsAndConditionFile: 'ADD_ORGANIZATION_TERMS_AND_CONDITION_FILES',
    setTcFilesPagination: 'SET_TERMS_AND_CONDITIONS_PAGINATION',
    setTermsAndConditionsProviderFilter: 'SET_ORGANIZATION_PROVIDER_FILTER',
    setTermsAndConditionsProductCategories: 'SET_TERMS_AND_CONDITIONS_PRODUCT_CATEGORIES',

    // attachments related
    getAttachments: 'GET_ATTACHMENT_FILES',
    addAttachmentFile: 'ADD_ATTACHMENT_FILE',
    deleteAttachment: 'DELETE_ATTACHMENT_FILE',
    downloadAttachmentFile: 'DOWNLOAD_ATTACHMENT_FILE',
    setAttachmentFilesPagination: 'SET_ATTACHMENTS_PAGINATION',
    setAttachmentProviderFilter: 'SET_ATTACHMENT_PROVIDER_FILTER',
    setAttachmentProductCategories: 'SET_ATTACHMENTS_PRODUCT_CATEGORIES',

    // downloads related
    getDownloads: 'GET_DOWNLOADS',
    downloadRatioGuideFile: 'DOWNLOAD_RATIO_GUIDE_FILE',
    editRatioGuideFile: 'EDIT_RATIO_GUIDE_FILE',

    // digital signing groups related
    getDigitalSigningGroups: 'GET_DIGITAL_SIGNING_GROUPS',
    addDigitalSigningGroup: 'ADD_DIGITAL_SIGNING_GROUP',
    patchDigitalSigningGroup: 'PATCH_DIGITAL_SIGNING_GROUP',
    deleteDigitalSigningGroup: 'DELETE_DIGITAL_SIGNING_GROUP',

    // digital signing fields related
    getDigitalSigningFields: 'GET_DIGITAL_SIGNING_FIELDS',
    setCurrentSigningGroup: 'SET_CURRENT_SIGNING_GROUP',
    updateDigitalSigningField: 'UPDATE_DIGITAL_SIGNING_FIELD',
    deleteDigitalSigningField: 'DELETE_DIGITAL_SIGNING_FIELD',

    // digital signing logs related
    getDigitalSigningLog: 'GET_DIGITAL_SIGNING_LOG',
    getQuotationSelectedProducts: 'GET_QUOTATION_SELECTED_PRODUCTS',
    getDigitalSigningGroupsSuccess: 'GET_DIGITAL_SIGNING_GROUPS_SUCCESS',
    getDigitalSigningFieldsSuccess: 'GET_DIGITAL_SIGNING_FIELDS_SUCCESS',
    getQuotationOrganization: 'GET_QUOTATION_ORGANIZATION',
    setProcessingDigitalSigningLogData: 'SET_PROCESSING_DIGITAL_SIGNING_LOG_DATA',
    getDigitalSigningLogDataFailed: 'GET_DIGITAL_SIGNING_LOG_DATA_FAILED',
    sendSmsCodeSuccess: 'SEND_SMS_CODE_SUCCESS',
    confirmSmsCodeSuccess: 'CONFIRM_SMS_CODE_SUCCESS',
    downloadPDFDocumentSuccess: 'DOWNLOAD_PDF_DOCUMENT_SUCCESS',
    downloadAttachmentFilesSuccess: 'DOWNLOAD_ATTACHMENT_DOCUMENT_SUCCESS',
    setDigitalSigningDetails: 'SET_DIGITAL_SIGNING_DETAILS',
    setIsTermsAndConditions: 'SET_IS_TERMS_AND_CONDITIONS',
    setIsDigitalSigningLogSigned: 'SET_IS_DIGITAL_SIGNING_LOG_SIGNED',
    setIsSubmitButtonDisabled: 'SET_IS_SUBMIT_BUTTON_DISABLED',

    //digital signing details related
    getDigitalSigningDetails: 'GET_DIGITAL_SIGNING_DETAILS',
    patchDigitalSigningDetails: 'PATCH_DIGITAL_SIGNING_DETAILS',

    // ordering related
    getOrderingState: 'GET_ORDERING_STATE',
    getSelectedProducts: 'GET_SELECTED_PRODUCTS',
    setOrderContainVoipFlexiblePortingNumbers: 'SET_ORDER_CONTAIN_VOIP_FLEXIBLE_PORTING_NUMBERS',
    updateOrderingState: 'UPDATE_ORDERING_STATE',
    updateOrderingProducts: 'UPDATE_ORDERING_PRODUCTS',
    updateOrderingStateMessage: 'UPDATE_ORDERING_STATE_MESSAGE',
    updateYielderOrderingStateMessage: 'UPDATE_YIELDER_ORDERING_STATE_MESSAGE',
    getOrderingProductsWithoutActions: 'GET_ORDERING_PRODUCTS_WITHOUT_ACTIONS',
    sendOrder: 'SEND_ORDER',
    sendOrderFail: 'SEND_ORDER_FAIL',
    sendOrderLoading: "SEND_ORDER_LOADING",
    getOrderingTelephoneNumber: 'GET_ORDERING_TELEPHONE_NUMBER',
    getVamoProductSelection: 'GET_VAMO_PRODUCT_SELECTION',
    saveVamoProductData: 'SAVE_VAMO_PRODUCT_DATA',
    saveOrderingTelephoneNumber: 'SAVE_ORDERING_TELEPHONE_NUMBER',
    getOrderingHostedVoiceNumber: 'GET_ORDERING_HOSTED_VOICE_NUMBER',
    saveOrderingHostedVoiceNumber: 'SAVE_ORDERING_HOSTED_VOICE_NUMBER',
    saveOrderingHostedVoiceNumberFail: 'SAVE_ORDERING_HOSTED_VOICE_NUMBER_FAIL',
    getOrderingAccessOptions: 'GET_ORDERING_ACCESS_OPTIONS',
    saveOrderingAccessOptionsSuccess: "SAVE_ORDERING_ACCESS_OPTIONS_SUCCESS",
    saveOrderingAccessOptionsFail: "SAVE_ORDERING_ACCESS_OPTIONS_FAIL",
    getOrdersData: "GET_ORDERS_DATA",
    saveMatchingAddons: "SAVE_MATCHING_ADDONS",
    setCurrentOrderSection: 'SET_CURRENT_ORDER_SECTION',
    setCurrentQuotationOrder: 'SET_CURRENT_QUOTATION_ORDER',
    getQuotationOrderProductsSuccess: 'GET_QUOTATION_ORDER_PRODUCTS_SUCCESS',
    updateOrderingSentProductComment: 'UPDATE_ORDERING_SENT_PRODUCTS_COMMENT',

    //hosted voice related
    updateHostedVoiceOptions: 'UPDATE_HOSTED_VOICE_OPTIONS',
    getHostedVoiceHardware: 'GET_HOSTED_VOICE_HARDWARE',
    updateLocationCallChargeTypeSuccess:
        'UPDATE_LOCATION_CALL_CHARGE_TYPE_SUCCESS',
    updateLocationSipTrunkSuccess: 'UPDATE_LOCATION_SIP_TRUNK_SUCCESS',
    processingExistingVoiceData: 'PROCESSING_EXISTING_VOICE_DATA',
    getVamoVoiceProductsCount: 'GET_VAMO_KANTOR_MICROSOFT_PRODUCTS_COUNT',

    // UCC related
    getUccOptions: 'GET_UCC_OPTIONS',

    //Mbk related
    getMbkProductsSuccess: 'GET_MBK_PRODUCTS_SUCCESS',
    getMbkProducts: 'GET_MBK_PRODUCTS',
    getSelectMbkProductsSuccess: 'GET_SELECT_MBK_PRODUCTS_SUCCESS',
    getSelectMbkProducts: 'GET_SELECT_MBK_PRODUCTS',
    getSecurityOptions: 'GET_SECURITY_OPTIONS',

    //telephonyRelated
    updateTelephonyOptions: 'UPDATE_TELEPHONY_OPTIONS',
    getTelephonyLocationOptions: 'GET_TELEPHONY_LOCATION_OPTIONS',
    updateTelephonyLocationOptions: 'UPDATE_TELEPHONY_LOCATION_OPTIONS',

    //communicationRelated
    updateCommunicationOptions: 'UPDATE_COMMUNICATION_OPTIONS',

    //business connections related
    updateBusinessConnectionOptions: 'UPDATE_BUSINESS_CONNECTION_OPTIONS',
    updateCallChargeState: 'UPDATE_CALL_CHARGE_STATE',

    //mobile related
    updateMobileOptions: 'UPDATE_MOBILE_OPTIONS',
    addNewMobileProductSelection: 'ADD_MOBILE_PRODUCT_SELECTION',
    getExistingMobileData: 'GET_EXISTING_MOBILE_DATA',
    getUpgradingMobileData: 'GET_UPGRADING_MOBILE_DATA',
    updateMobileDataRetrieval: 'UPDATE_MOBILE_DATA_RETRIEVAL',
    processingExistingMobileData: 'PROCESSING_EXISTING_MOBILE_DATA',
    updateHasActiveAddons: 'UPDATE_HAS_ACTIVE_ADDONS',
    updateHasActiveEndDateOptions: 'UPDATE_HAS_ACTIVE_END_DATE_OPTIONS',
    getExceptionEndDateProducts: 'GET_EXCEPTION_END_DATE_PRODUCTS',
    updateProfilesLoader: 'UPDATE_PROFILES_LOADER',
    getQuotationOperators: 'GET_QUOTATION_OPERATORS',
    updateQuotationOperators: 'UPDATE_QUOTATION_OPERATORS',
    getAvailableTelephoneNumbers: 'GET_AVAILABLE_TELEPHONE_NUMBERS',

    //sdlan realated
    updateSdLanOptions: 'UPDATE_SDLAN_OPTIONS',
    updateSdLanLocationOptionsSuccess: 'UPDATE_SDLAN_LOCATION_OPTIONS_SUCCESS',
    createSdLanStackSuccess: 'CREATE_SDLAN_STACK_SUCCESS',
    updateSdLanStackSuccess: 'UPDATE_SDLAN_STACK_SUCCESS',
    deleteSdLanStackSuccess: 'DELETE_SDLAN_STACK_SUCCESS',

    //isdn migration related
    getLocationOffers: {
        init: 'GET LOCATION OFFERS',
        success: 'GET LOCATION OFFERS SUCCESS',
        error: 'GET LOCATION OFFERS ERROR',
    },
    getLocationOffer: {
        init: 'GET LOCATION OFFER',
        success: 'GET LOCATION OFFER SUCCESS',
        error: 'GET LOCATION OFFER ERROR',
    },
    getConnectionOptions: {
        init: 'GET CONNECTION OPTIONS',
        success: 'GET CONNECTION OPTIONS SUCCESS',
        error: 'GET CONNECTION OPTIONS ERROR',
    },

    updateConnectionOptions: {
        init: 'UPDATE CONNECTION OPTIONS',
        success: 'UPDATE CONNECTION OPTIONS SUCCESS',
        error: 'UPDATE CONNECTION OPTIONS ERROR',
    },
    getLocationOptions: {
        init: 'GET LOCATION OPTIONS',
        success: 'GET LOCATION OPTIONS SUCCESS',
        error: 'GET LOCATION OPTIONS ERROR',
    },
    getQuotationOptions: {
        init: 'GET QUOTATION OPTIONS',
        success: 'GET QUOTATION OPTIONS SUCCESS',
        error: 'GET QUOTATION OPTIONS ERROR',
    },
    updateQuotationOptions: {
        init: 'UPDATE QUOTATION OPTIONS',
        success: 'UPDATE QUOTATION OPTIONS SUCCESS',
        error: 'UPDATE QUOTATION OPTIONS ERROR',
    },
    updateLocationOptions: {
        init: 'UPDATE LOCATION OPTIONS',
        success: 'UPDATE LOCATION OPTIONS SUCCESS',
        error: 'UPDATE LOCATION OPTIONS ERROR',
    },
    updateConnection: {
        init: 'UPDATE CONNECTION',
        success: 'UPDATE CONNECTION SUCCESS',
        error: 'UPDATE CONNECTION ERROR',
    },
    updateCallChargeType: {
        success: "UPDATE CALL CHARGE TYPE SUCCESS",
        error: "UPDATE CALL CHARGE TYPE ERROR"
    },
    updateHostedVoiceNumbers: {
        init: 'UPDATE_HOSTED_VOICE_NUMBER_INIT',
        success: 'UPDATE_HOSTED_VOICE_NUMBER_SUCCESS',
        error: 'UPDATE_HOSTED_VOICE_NUMBER_ERROR',
        delete: 'DELETE_HOSTED_VOICE_NUMBER'
    },

    setConnectionOption: 'SET CONNECTION OPTION',

    // RATIO specific
    getExistingVoiceData: 'GET_EXISTING_VOICE_DATA',
    hardwareDownloadExampleFile: 'HARDWARE_DOWNLOAD_EXAMPLE_FILE',
    hardwareDownloadAssortimentFile: 'HARDWARE_DOWNLOAD_ASSORTIMENT_FILE',
    hardwareUploadBulk: 'HARDWARE_UPLOAD_BULK',
    hardwareOpenImportDialog: 'HARDWARE_OPEN_IMPORT_DIALOG',
    hardwareCloseImportDialog: 'HARDWARE_CLOSE_IMPORT_DIALOG',
    hardwareToggleImportLoading: 'HARDWARE_TOGGLE_IMPORT_LOADING',
    hardwareSetImportDialogData: 'HARDWARE_SET_IMPORT_DIALOG_DATA',
    setHardwareProducts: 'SET_HARDWARE_PRODUCTS',
    setCustomHardwareOptions: 'SET_CUSTOM_HARDWARE_OPTIONS',
    setCustomHardwareSelections: 'SET_CUSTOM_HARDWARE_SELECTIONS',
    addHardwareOptionSuccess: 'ADD_NEW_HARDWARE',
    setHardwareProductsToLink: 'GET_HARDWARE_OPTIONS_PRODUCTS_TO_LINK',
    setHardwareOptionCategories: 'GET_HARDWARE_OPTION_CATEGORIES',

    partnerServicesDownloadExampleFile: 'PARTNER_SERVICES_DOWNLOAD_EXAMPLE_FILE',
    partnerServicesDownloadAssortimentFile: 'PARTNER_SERVICES_DOWNLOAD_ASSORTIMENT_FILE',
    partnerServicesUploadBulk: 'PARTNER_SERVICES_UPLOAD_BULK',
    partnerServicesOpenImportDialog: 'PARTNER_SERVICES_OPEN_IMPORT_DIALOG',
    partnerServicesCloseImportDialog: 'PARTNER_SERVICES_CLOSE_IMPORT_DIALOG',
    partnerServicesToggleImportLoading: 'PARTNER_SERVICES_TOGGLE_IMPORT_LOADING',
    partnerServicesSetImportDialogData: 'PARTNER_SERVICES_SET_IMPORT_DIALOG_DATA',
    setPartnerServicesProducts: 'SET_PARTNER_SERVICES_PRODUCTS',
    setPartnerServicesOptions: 'SET_PARTNER_SERVICES_OPTIONS',
    setITServicesOptions: 'SET_IT_SERVICES_OPTIONS',
    setPartnerServiceSelections: 'SET_PARTNER_SERVICE_SELECTIONS',
    setITServiceSelections: 'SET_IT_SERVICE_SELECTIONS',
    addPartnerServiceSuccess: 'ADD_NEW_PARTNER_SERVICE',
    setProductsToLink: 'GET_PARTNER_SERVICES_PRODUCTS_TO_LINK',
    setServiceOptionCategories: 'GET_SERVICE_OPTION_CATEGORIES',
    getProductTreeData: 'GET_PRODUCT_TREE_DATA',
    getProductsForOverview: 'GET_PRODUCTS_FOR_OVERVIEW',

    updateMobileOfferYears: 'UPDATE_MOBILE_OFFER_YEARS',
    getMobileOfferProducts: 'GET_MOBILE_OFFER_PRODUCTS',
    updateMobileOfferProducts: 'UPDATE_MOBILE_OFFER_PRODUCTS',
    updateMobileOfferProductOptions: 'UPDATE_MOBILE_OFFER_PRODUCT_OPTIONS',

    // api status related
    getApiStatus: 'GET_API_STATUS',

    // products dependency related
    getDependencyProducts: 'GET_DEPENDENCY_PRODUCTS',

    //admin orders
    getAdminOrdersData: 'GET_ALL_ADMIN_ORDERS',
    setCurrentQuotationAdminOrder: 'SET_CURRENT_QUOTATION_ADMIN_ORDER',
    setCurrentAdminOrderSection: 'SET_CURRENT_ADMIN_ORDER_SECTION',
    getQuotationAdminOrderProductsSuccess: 'GET_QUOTATION_ADMIN_ORDER_PRODUCTS_SUCCESS',
    setQuotationAdminStatus: 'SET_QUOTATION_ADMIN_STATUS',
    setProductOrderAdminStatus: 'SET_PRODUCT_ORDER_ADMIN_STATUS',

    //messages
    getAllMessages: 'GET_ALL_MESSAGES',
    sendMessage: 'SEND_MESSAGE_SUCCESS',
};

export {actionTypes};

export default actionTypes;
