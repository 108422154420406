export default {
    WR: 'WilroffReitsma',
    WR_TELDACOM: 'WilroffReitsma Teldacom',
    PIT: 'Partners in Telecom',
    BIB: '123zakelijkinternet.nl',
    CLEARMIND: 'Clear Mind IT Consultancy',
    KPN_RETAIL: 'KPN Retail',
    SV: 'Sales Value',
    DB: 'DB+'
}
