import React, {Component} from 'react';
import {connect} from 'react-redux';
import {toastr} from "react-redux-toastr";
import {bindActionCreators} from 'redux';

import {Input} from "react-materialize";

import locationActions from "../../../actions/locationActions";

import SipTrunk from './SipTrunk';

const emptySipTrunkData = {
  speechChannelsCount: null
};

const emptySipTrunkErrors = {
  speechChannelsCount: null,
};

class BusinessConnectionOptions extends Component {
  constructor() {
    super();
    this.state = {
      isNewSipTrunk: true,
      newSipTrunkData: emptySipTrunkData,
      newSipTrunkDataErrors: emptySipTrunkErrors,
    };
  }

  componentDidUpdate(prevProps) {
    const newSipTrunk = prevProps.isNewSipTrunk !== this.props.isNewSipTrunk && this.props.isNewSipTrunk

    if (this.props.location.id !== prevProps.location.id || newSipTrunk) {
      this.setState({
        isNewSipTrunk: true,
        newSipTrunkData: emptySipTrunkData,
        newSipTrunkDataErrors: emptySipTrunkErrors,
      });
    }
  }

  canAddNewSipTrunkCheck = () => {
    const {location} = this.props;

    return location.sip_trunks.length < location.connections.length
  };

  handleClickAddSipTrunk = () => {
    if (this.canAddNewSipTrunkCheck()) {
      this.setState({
        isNewSipTrunk: true,
        newSipTrunkData: emptySipTrunkData,
        newSipTrunkDataErrors: emptySipTrunkErrors,
      });

      this.props.onNewSipTrunk();
    } else {
      toastr.error('Count of sip trunks cannot be higher than connections count.');
    }
  };

  validateInput = (name, value) => {
    let error = null;

    switch (name) {
      case 'speechChannelsCount':
        // TODO: check validity here and update error object
        break;
      default:
        break;
    }

    return error;
  };

  handleChange = (data, sipTrunkId, isNew) => {
    if (isNew) {
      const errors = {};
      Object.keys(data).forEach((key) => {
        errors[key] = this.validateInput(key, data[key]);
      });

      this.setState({
        newSipTrunkData: {...this.state.newSipTrunkData, ...data},
        newSipTrunkDataErrors: {
          ...this.state.newSipTrunkDataErrors,
          ...errors,
        },
      });

      this.handleClickSaveNewSipTrunk();
    } else {
      this.props.onChangeCallback(data, sipTrunkId);
    }
  };

  handleClickSaveNewSipTrunk = () => {
    const {location, onClickSaveNewSipTrunk} = this.props;

    if (location.sip_trunks.length < location.connections.length) {
      const newSipTrunkData = this.state.newSipTrunkData;
      this.setState({
        // isNewSipTrunk: false,
        newSipTrunkData: emptySipTrunkData,
        newSipTrunkDataErrors: emptySipTrunkErrors,
      });
      onClickSaveNewSipTrunk(newSipTrunkData);
    } else {
      toastr.error('Count of sip trunks cannot be higher than connections count.');
    }
  };

  handleClickDeleteSipTrunk = (sipTrunkId) => {
    const {onClickDeleteSipTrunk} = this.props;

    onClickDeleteSipTrunk(sipTrunkId);

    this.setState({
      isNewSipTrunk: false,
      newSipTrunkData: emptySipTrunkData,
      newSipTrunkDataErrors: emptySipTrunkErrors,
    });
  };

  isNewSipTrunkValid = () => {
    const {newSipTrunkDataErrors} = this.state;

    return (
      !newSipTrunkDataErrors.speechChannelsCount
    );
  };


  render() {
    const {location, onChangeCallback, callChargeType, quotation, inputsDisabled} = this.props;
    const sipTrunks = location.sip_trunks;
    const callChargeTypeInt = parseInt(callChargeType, 10);
    const isChargeTypeCombined = callChargeTypeInt === 3;
    const locationCallChargeTypeInt = location.locationCallChargeType ? parseInt(location.locationCallChargeType, 10) : 0;
    const locationCallChargeType = isChargeTypeCombined ? locationCallChargeTypeInt : callChargeTypeInt;

    return (
      <div className="row">
        <div className="bordered col s12" style={{marginBottom: '20px', display: 'none'}}>
          <div className="col s12">
            <div>
              <h1 className={'ratio-section-title'}>
                <span className="ratio-section-title">
                    <i className="small material-icons left ratio-section-title">settings_phone</i>Belverkeer op deze locatie:
                </span> belverkeer afkopen
              </h1>
            </div>
          </div>

          <div className="row">
            <Input
              s={4}
              name="locationCallChargeType"
              type='select'
              label="Wilt u het belverkeer afkopen?"
              onChange={onChangeCallback}
              value={locationCallChargeType}
              multiple={false}
              disabled={!isChargeTypeCombined || inputsDisabled}
            >
              <option value={0}/>
              <option value={1}>Nee</option>
              <option value={2}>Ja</option>
            </Input>
          </div>
        </div>

        {sipTrunks.map((row, idx) => (
          <SipTrunk
            key={row.id !== undefined ? row.id : idx * -1}
            data={row}
            onChange={(e, sipTrunkId) => {
              this.handleChange(e, sipTrunkId, false);
            }}
            isNew={false}
            isNewValid={this.isNewSipTrunkValid()}
            onClickAdd={this.handleClickAddSipTrunk}
            onClickDelete={this.handleClickDeleteSipTrunk}
            isMigration={quotation.isMigration}
            isLast={sipTrunks.length === ++idx}
            inputsDisabled={inputsDisabled}
          />
        ))}

        {!quotation.isMigration && this.state.isNewSipTrunk && !sipTrunks.length && (
          <SipTrunk
            data={this.state.newSipTrunkData}
            onChange={(e, sipTrunkId) => {
              this.handleChange(e, sipTrunkId, true);
            }}
            isNew={this.state.isNewSipTrunk}
            isNewValid={this.isNewSipTrunkValid()}
            onClickDelete={this.handleClickDeleteSipTrunk}
            isMigration={quotation.isMigration}
            inputsDisabled={inputsDisabled}
            isLast={true}
            onClickAdd={this.handleClickAddSipTrunk}
          />
        )}
      </div>
    )
  };
}

const mapDispatchToProps = dispatch => {
  return {
    locationActions: bindActionCreators(locationActions, dispatch)
  }
};

const mapStateToProps = ({locations}) => {
  return {
    locations,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessConnectionOptions);
