import React, { Component } from 'react';
import DiscountOverview from '../discount/discountOverview';
import styled from "styled-components";

class OverviewStep extends Component {
    render() {
        const PreloaderParent = styled.div`
          .preloader-wrapper {
            width: 35px; 
            height: 35px; 
            display: flex;
          }
          
          .spinner-layer {
             border-color: rgba(241,90,90,1);
           }
        `;

        return (
            <DiscountOverview title="Overzicht" subTitle="" editable={false} PreloaderParent={PreloaderParent}/>
        );
    }
}

export default OverviewStep;
