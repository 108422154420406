import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import debounce from "lodash/debounce";
import {bindActionCreators} from "redux";

import partnerServicesActions from "../../actions/partnerServicesActions";

import AddPartnerService from "../../containers/Admin/PartnersServices/AddPartnerService";

class AddOneTimeProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      manualAdditionDialogOpened: false
    }
  }

  componentDidMount() {
    this.saveSelection = debounce(this.saveSelection, 1000);
  }

  _handleManualAdditionDialogOpen = () => {
    this.setState({manualAdditionDialogOpened: true});
  };

  _handleManualAdditionDialogClose = () => {
    this.setState({manualAdditionDialogOpened: false});
  };

  saveSelection = (selection, quotationId, type) => {
    const selections = type === 'it'
      ? this.props.partnerServices.itServicesSelections
      : this.props.partnerServices.partnerServicesSelections;
    const newSelections = [...selections, ...[selection]];
    const mapper = {
      it: 'setITServiceSelection',
      partner: 'setPartnerServiceSelection',
    };

    this.props.partnerServicesActions.persistPartnerServicesSelections(newSelections, quotationId, type).then(() => {
      this.props.partnerServicesActions[mapper[this.props.type]](newSelections);
    });

  };

  _onAddClick = (data) => {
    data = {newServiceData: {...data, ...{quotation_id: this.props.quotation.currentQuotation.id}}};

    const response = this.props.partnerServicesActions.addPartnerService(data, this.props.quotation.currentQuotation.provider, true);

    response.then((data) => {
      const mapper = {
        it: 'getITServicesOptions',
        partner: 'getPartnerServicesOptions',
      };

      this.props.partnerServicesActions[mapper[this.props.type]](this.props.quotation.currentQuotation.id);

      const product = data.product;
      const selection = {
        id: product.id,
        quantity: 1,
        category: product.category
      };
      this.saveSelection(selection, this.props.quotation.currentQuotation.id, this.props.type);

      this.setState({manualAdditionDialogOpened: false});
    });
  };

  render() {
    const {inputsDisabled} = this.props.quotation.currentQuotation;

    return (
      <div>
        <fieldset disabled={inputsDisabled} style={{border: 'none', padding: 'unset', margin: 'unset'}}>
          <button className="btn doceri-btn-right ratio-btn-right" onClick={this._handleManualAdditionDialogOpen}>
            Eenmalige producten toevoegen
          </button>
        </fieldset>

        <AddPartnerService
          dialogOpened={this.state.manualAdditionDialogOpened}
          onRequestClose={this._handleManualAdditionDialogClose}
          tab={"partner en IT Diensten"}
          type={this.props.type}
          onClickFunc={this._onAddClick}
          productsToLink={this.props.partnerServices.productsToLink}
          categories={this.props.partnerServices.serviceOptionCategories.categories}
          oneTime={true}
          inputsDisabled={inputsDisabled}
        />
      </div>
    )
  }
}

const mapStateToProps = ({quotation, partnerServices, authentication}) => {
  return {
    quotation,
    partnerServices,
    authentication,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    partnerServicesActions: bindActionCreators(partnerServicesActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOneTimeProduct);
