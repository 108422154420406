import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import quotationQuestionActions from '../../../actions/quotationQuestionActions';
import stepsActions from "../../../actions/stepsActions";
import quotationActions from "../../../actions/quotationActions";

import QuotationQuestionTable from "./QuotationQuestionTable";
import LoaderComponent from "../../../containers/common/LoaderComponent";

class QuotationQuestionModule extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modules: [],
      questionSelections: [],
      modulesLoading: true,
      questionSelectionsLoading: true,
      invalidQuestions: []
    }
  }

  componentDidMount() {
    let quotationId = this.props.quotation.id;
    let { module, location } = this.props;

    this.getQuestionData(quotationId, module, location);
  }

  componentDidUpdate(prevProps) {
    let quotationId = this.props.quotation.id;
    let { module, location } = this.props;

    if (prevProps.module !== this.props.module || prevProps.location !== this.props.location) {
      this.getQuestionData(quotationId, module, location);
    }
  }

  getQuestionData = (quotationId, module, location) => {
    this.props.quotationQuestionActions.getQuestions(quotationId, module, location).then((response) => {
      if (response && response.data) {
        this.setState({modules: response.data});
      }
    }).then(() => {
      this.setState({modulesLoading: false})
    });

    this.props.quotationQuestionActions.getQuestionSelections(quotationId, module, location).then((response) => {
      if (response) {
        this.checkFailedApprovalSelections(response);
        this.setState({questionSelections: response});
      }
    }).then(() => {
      this.setState({questionSelectionsLoading: false})
    });
  };

  checkFailedApprovalSelections = (selections) => {
    let failedApprovalMessage = 'De configuratie van Eenvoud uit de Cloud lijkt niet te kloppen. Check nogmaals alle waarden met rode meldingen';
    let failedApprovalQuestions = selections.filter((selection) => !selection.isApproved);

    if (failedApprovalQuestions.length) {
      this.props.quotationActions.addSoftAlert(failedApprovalMessage);
    } else {
      this.props.quotationActions.removeSpecificSoftAlert(failedApprovalMessage);
    }
  };

  identifySelection = (questionId) => {
    return this.state.questionSelections.find((el) => el.questionID === questionId);
  };

  identifySelectionByRelation = (relation) => {
    return this.state.questionSelections.find((el) => el.relation === relation);
  };

  updateSelection = (value, productID, question, isCustomProduct) => {
    let quotationId = this.props.quotation.id;
    let { module, location } = this.props;
    let identifiedSelection = this.identifySelection(question.id);

    value = parseInt(value);
    if (isNaN(value)) {
      value = 0;
    }

    if (!identifiedSelection) {
      identifiedSelection = {
        id: '',
        questionID: question.id
      };
    } else {
      if (productID === null) {
        if (isCustomProduct) {
          productID = identifiedSelection.customProductID

        } else {
          productID = identifiedSelection.productID;
        }
      }
    }

    identifiedSelection.quantity = value;
    identifiedSelection.productID = isCustomProduct ? '' : productID;
    identifiedSelection.customProductID = isCustomProduct ? productID : '';

    this.props.quotationQuestionActions.updateQuestionSelections(quotationId, module, location, identifiedSelection).then((response) => {
      if (response && response.selections) {
        this.checkFailedApprovalSelections(response.selections);
        this.setState({questionSelections: response.selections});
      }
    });
  };

  renderModules = () => {
    let {modules, questionSelections} = this.state;
    let {showQAPrices, showQALicences} = this.props.authentication;
    if (Array.isArray(modules) && !modules.length) {
      return this.renderNoQuestionsSection();
    }

    return Object.keys(modules).map((categoryKey) => {
      return (
        <QuotationQuestionTable
          key={modules[categoryKey]['category']}
          module={modules[categoryKey]}
          questionSelections={questionSelections}
          updateSelection={this.updateSelection}
          identifySelection={this.identifySelection}
          identifySelectionByRelation={this.identifySelectionByRelation}
          showQAPrices={showQAPrices}
          showQALicences={showQALicences}
          addInvalidQuestion={this.addInvalidQuestion}
          removeInvalidQuestion={this.removeInvalidQuestion}
          disabledInputs={this.props.quotation.inputsDisabled}
        />
      );
    });
  };

  renderNoQuestionsSection = () => {
    return  (
      <fieldset style={{border: 'none', padding: 'unset', margin: 'unset'}}>
        <div className={'Forms'}>
          <div>
            <h1 className={'ratio-section-title'}>
              <span className="ratio-section-title">
                  <i className="small material-icons left ratio-section-title">
                      info
                  </i>
              </span>{' '}
              Er zijn geen vragen gevonden!
            </h1>
          </div>

          <div className={'row'}>
            <div className="input-field col s12 height-50px">
              Op dit moment zijn er geen vragen voor deze rubriek. Probeer het later opnieuw of neem contact op met een beheerder.
            </div>
          </div>
        </div>
      </fieldset>
    );
  };

  addInvalidQuestion = (questionId) => {
    let failedApprovalMessage = 'De configuratie van Eenvoud uit de Cloud lijkt niet te kloppen. Check nogmaals alle waarden met rode meldingen';

    this.setState({invalidQuestions: [...this.state.invalidQuestions, questionId]});

    this.props.stepsActions.setAvailabilityMessage(failedApprovalMessage);
    this.props.stepsActions.setAvailableToGoForward(false);
  };

  removeInvalidQuestion = (questionId) => {
    let invalidQuestions = [...this.state.invalidQuestions];
    let index = invalidQuestions.indexOf(questionId);
    let failedApprovalMessage = 'De configuratie van Eenvoud uit de Cloud lijkt niet te kloppen. Check nogmaals alle waarden met rode meldingen';
    let missingInputMessage = 'Niet alle verplichte velden zijn ingevuld!';

    if (index !== -1) {
      invalidQuestions = invalidQuestions.filter((id) => id !== questionId);

      this.setState({invalidQuestions});
      this.props.stepsActions.setAvailabilityMessage(!invalidQuestions.length ? missingInputMessage: failedApprovalMessage);
      this.props.stepsActions.setAvailableToGoForward(!invalidQuestions.length);
    }
  };

  render() {
    const { theme } = this.props.authentication;
    let {modulesLoading, leadSelectionsLoading} = this.state;
    return (
      <div>
        {
          modulesLoading || leadSelectionsLoading ? (<LoaderComponent theme={theme}/>) : (this.renderModules())
        }
      </div>
    );
  }
}

const mapStateToProps = ({quotation, authentication}) => {
  return {
    quotation: quotation.currentQuotation,
    authentication: authentication
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    quotationQuestionActions: bindActionCreators(quotationQuestionActions, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotationQuestionModule);
