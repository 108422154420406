import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import OrderListTable from './OrderListTable';
import authenticationActions from "../../../actions/authenticationActions";
import {DelayInput} from "react-delay-input";
import Pagination from '../../../components/pagination/pagination';
import LoaderComponent from "../../common/LoaderComponent";
import adminOrdersActions from "../../../actions/adminOrdersActions";
import ChatDialog from "./ChatDialog";
import messagesActions from "../../../actions/messagesActions";
import StatusOrder from "./StatusOrder";

class OrdersOverviewPanel extends Component {
  sortInitialState = {
    quotationName: false,
    createdAt: false,
    updatedAt: false
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedRow: null,
      searchPhrase: '',
      allOrders: [],
      sorting: {
        ...this.sortInitialState
      },
      page: 1,
      loading: true,
      chatOrderSelected: null,
      chatDialog: false,
      messages: [],
      changeOrderStatusDialog: false,
      currentOrderStatus: null
    };
  };

  componentDidMount() {
    this.getAllOrders();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.adminOrders.orders !== this.props.adminOrders.orders) {
      this.setState({orders: this.props.adminOrders.orders});
    }
  };

  componentWillMount() {
    const hasAccess = this.props.authentication.ordering || this.props.authentication.isAdminFromDoceri;
    if (!hasAccess) {
      this.redirectToHome();
    }
  }

  changePage = ({target}) => {
    const {value} = target;
    this.setState({page: value});

    const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false);
    const sortValue = this.state.sorting[sortField];

    this.getAllOrders(sortField, sortValue, value, this.state.searchPhrase);
  };

  getAllOrders = (sortField, direction, page, searchQuery) => {
    let queryParams = '';
    if (sortField && direction) {
      queryParams += '?sortField=' + sortField;
      queryParams += '&sortDirection=' + direction;
    }

    if (page) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?')
      queryParams += 'page=' + page
    }

    if (searchQuery) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?')
      queryParams += 'searchQuery=' + searchQuery;
    }

    this.props.adminOrdersActions.getAdminOrdersData(queryParams)
        .then(() => {this.setState({allOrders: this.props.adminOrders.orders})})
        .then(() => {this.setState({loading: false});
        });
  };

  redirectToHome = () => {
    this.props.history.push('/');
  };

  onSearch = ({target}) => {
    let {value} = target;

    const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false);
    const sortValue = this.state.sorting[sortField];

    this.setState({searchPhrase: value});

    this.getAllOrders(sortField, sortValue, this.state.page, value)
  };

  getHeaderPropertyForName = (displayName) => {
    const index = this.tableHeaderNames.indexOf(displayName);
    return this.tableObjectProperties[index];
  };

  getSortDirection = (propertyName) => {
    const sortDirection = this.state.sorting[propertyName];
    switch (sortDirection) {
      case false:
        return "ASC";
      case "ASC":
        return "DESC";
      case "DESC":
        return false
    }
  };

  changeSortDirection = (propertyName, direction) => {
    this.setState({sorting: {...this.sortInitialState, [propertyName]: direction}});

    this.getAllOrders(propertyName, direction, this.state.page, this.state.searchPhrase)
  };

  onSortChange = ({target}) => {
    const headerName = target.getAttribute('headername');
    const propertyName = this.getHeaderPropertyForName(headerName);

    if (!propertyName) {
      return;
    }

    this.changeSortDirection(propertyName, this.getSortDirection(propertyName));
  };

  onViewOrdersClick = (row) => {
    this.props.adminOrdersActions.setAdminCurrentQuotationOrder(row);
    this.props.adminOrdersActions.setAdminCurrentSection('quotation-orders');
  };

  onOpenChatDialogClick = async (row) => {
    await this.props.messagesActions.getMessages(row.quotationNumber)

    this.setState({
      chatOrderSelected: row,
      chatDialog: true,
      messages: this.props.messages.messages
    });
  };

  closeChatDialog = () => {
    this.setState({chatDialog: false});
  };

  sendMessage = (event) => {
    const message = (new FormData(event.target).get('message'));
    const {quotationNumber} = this.state.chatOrderSelected;

    this.props.messagesActions.sendMessage(quotationNumber, message);
    this.setState({messages: this.props.messages.messages})
  }

  onOpenStatusDialogClick = async (row) => {
    this.setState({
      currentOrderStatus: row.status,
      changeOrderStatusDialog: true,
      selectedRow: row
    });
  };

  closeStatusDialog = () => {
    this.setState({changeOrderStatusDialog: false});
  };

  changeOrderStatus = (status) => {
    const {quotationNumber} = this.state.selectedRow;
    this.props.adminOrdersActions.changeAdminOrderStatus(quotationNumber, status, false);
  }

  tableHeaderNames = [
    '#',
    'Quotation name',
    'Customer (IRMA ID)',
    'Message',
    'Aangemaakt',
    'Laaste update',
    'Acties'
  ];
  tableObjectProperties = [
    'quotationNumber',
    'quotationName',
    'customer',
    'message',
    'createdAt',
    'updatedAt',
    'actions'
  ];

  render() {
    let {searchPhrase, allOrders, chatDialog, changeOrderStatusDialog, currentOrderStatus} = this.state;
    const { theme, userId } = this.props.authentication;

    return (
        <div>
          {this.state.loading ?
              <LoaderComponent theme={theme}/> :

              <div>
                <div className="col s12">
                  <div id="Forms" className="row ratiotable paddingbottomnone">
                    <div>
                      <h1><span className="ratio-section-title">Order overzicht</span></h1>
                    </div>
                  </div>

                  <div className="row overviewkopbg ratiotable ordersSearchDiv">
                    <div className="col s12 m3">
                      <div className="overviewkop">
                        <form>
                          <div className="input-field margingone inner-addon">
                            <i className="glyphicon tiny material-icons left lightgrey">search</i>

                            <DelayInput
                                delayTimeout={800}
                                placeholder="Klant zoeken..."
                                id="search"
                                type="search"
                                name="searchPhrase"
                                value={searchPhrase}
                                onChange={this.onSearch}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <OrderListTable
                        propertiesToShow={this.tableObjectProperties}
                        rows={allOrders}
                        onViewOrdersClick={this.onViewOrdersClick}
                        onHeaderClick={this.onSortChange}
                        sortingState={this.state.sorting}
                        getPropertyNameFromDisplayName={this.getHeaderPropertyForName}
                        openChatDialog={this.onOpenChatDialogClick}
                        openStatusDialogClick={this.onOpenStatusDialogClick}
                    />
                  </div>

                  {chatDialog &&
                      <ChatDialog
                          dialogOpen={chatDialog}
                          headerText={'Chat - Order'}
                          onCancel={this.closeChatDialog}
                          messages={this.state.messages}
                          currentUserId={userId}
                          onMessageSubmit={this.sendMessage}
                      />
                  }

                  {changeOrderStatusDialog &&
                      <StatusOrder
                          dialogOpen={changeOrderStatusDialog}
                          onCancel={this.closeStatusDialog}
                          currentStatus={currentOrderStatus}
                          onStatusChange={this.changeOrderStatus}
                          isProductStatusChange={false}
                      />
                  }
                </div>

                <div className="col s12 sendendform">
                  <div className="col s12 m10">
                    <Pagination
                        page={this.props.adminOrders.currentPage}
                        pages={this.props.adminOrders.pages}
                        changePage={this.changePage}
                    />
                  </div>
                </div>
              </div>
          }
        </div>
    );
  }
}

const mapStateToProps = ({authentication, adminOrders, messages}) => {
  return {
    authentication,
    adminOrders,
    messages
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch),
    adminOrdersActions: bindActionCreators(adminOrdersActions, dispatch),
    messagesActions: bindActionCreators(messagesActions, dispatch)
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrdersOverviewPanel));
