import React, {Component} from 'react';

import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import "@syncfusion/ej2-base/styles/material.css";

class InputNumberWrapper extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: this.props.optionQuantity || ''
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.optionQuantity !== this.props.optionQuantity) {
      this.setState({
        value: this.props.optionQuantity || ''
      })
    }
  }

  handleChange = (event) => {
    const {value} = event;
    if (!isNaN(value)) {
      this.setState({value});
      this.props.changeDecimalQuantity(value, this.props.index);
    }
  };

  render() {
    return (
      <div className="col input-field s1">
        {this.props.index === 0 ? <label htmlFor="numeric-text-box-component">aantal</label> : ''}

        <NumericTextBoxComponent
          id='numeric-text-box-component'
          style={{marginBottom: 0}}
          validateDecimalOnType={true}
          decimals={2}
          format='n2'
          value={this.state.value}
          min={this.props.min}
          max={this.props.max}
          onChange={this.handleChange}
          step={0.01}
          disabled={this.props.inputsDisabled || false}
        />
      </div>
    );
  }
}

export default InputNumberWrapper;