import React from 'react';
import {Input, Row, Col} from 'react-materialize';
import DatePicker from "../../../components/datePicker";
import addMonths from 'date-fns/addMonths';
import {
  porting,
  nieuw,
  simlessPorting,
  opheffen,
  retaining,
  canceling,
  verlengen,
  upgrade,
  reoder,
  reoderPorting
} from "../../../constants/telephoneNumberType";

const MobileTelephoneNumberForm = (props) => {
  const {
    telephoneNumber,
    portingDetails,
    onChange,
    onChangePortingDetails,
    onSavePortingDetails,
    onChangeContractStartDate,
    onChangeUpgradeWishDate,
    onChangeContractEndDate,
    onChangePortingDate,
    handleChange,
    onChangeContractTerminationDate,
    isExceptionAction,
    isVerlengenBeta,
    isVerlengenBetaType,
    verlengenCondition,
    useNina,
    inputsDisabled,
    isOdidoProvider,
    isYielderProvider,
    networkData
  } = props;

  const renderSpecificOptions = () => {
    const options = {
      //guideListing: 'Gegevens opnemen in telefoongids',
      flexiblePorting: 'Flexibel porteren',
      isESimType: 'Orderen als eSim',
      isFlexiblePortingVoiceMail: 'Porteren met behoud van Voicemail',
      isFlexiblePortingVoiceMailFixedIp: 'Porteren met behoud van Voicemail en Vast IP',
      //blockPaidServices: 'Servicenummer blokkeren',
      //blockCallRoaming: 'Roaming blokkeren',
      //blockCallAbroad: 'Internationaal bellen blokkeren',
      //blockDataRoaming: 'Dataroaming blokkeren',
      //blockNumberRecognition: 'Nummerherkenning uit zetten',
    };

    return Object.keys(options).map((el, i, {length}) => {
      if (options[el] === 'Flexibel porteren' && [porting, simlessPorting].includes(telephoneNumber.action)) {
        return <div key={el} className="row">
          <div className="col s12">
            <input
              name={el}
              className="input-field"
              checked={!!telephoneNumber[el]}
              id={el}
              type="checkbox"
              onChange={onChange}
              disabled={inputsDisabled}
            />
            <label htmlFor={el}>{options[el]}</label>
          </div>
        </div>
      }
      
      if (!['Flexibel porteren', 'Orderen als eSim'].includes(options[el]) && [simlessPorting].includes(telephoneNumber.action) && useNina) {
        return <div key={el} className="row" style={{marginBottom: i + 1 === length ? '50px' : null}}>
          <div className="col s12">
            <input
              name={el}
              className="input-field"
              checked={!!telephoneNumber[el]}
              id={el}
              type="checkbox"
              onChange={onChange}
              disabled={inputsDisabled}
            />
            <label htmlFor={el}>{options[el]}</label>
          </div>
        </div>
      }

      if (options[el] === 'Orderen als eSim' && [nieuw, porting].includes(telephoneNumber.action) && (useNina || isOdidoProvider)) {
        return <div key={el} className="row" style={{marginBottom: i + 1 === length ? '50px' : null}}>
          <div className="col s12">
            <input
                name={el}
                className="input-field"
                checked={!!telephoneNumber[el]}
                id={el}
                type="checkbox"
                onChange={onChange}
                disabled={inputsDisabled}
            />
            <label htmlFor={el}>{options[el]}</label>
          </div>
        </div>
      }
    });
  };

  const getMinimumContractEndDate = () => {
    const today = new Date();

    return new Date(today.setMonth(today.getMonth() + 1));
  };

  const minDate = () => {
    return addMonths(new Date(telephoneNumber.portingDate), parseInt(telephoneNumber.contract_termination_duration, 10));
  };

  return (
    <>
      {!(isVerlengenBetaType && verlengenCondition) && !isYielderProvider &&
        <div className="row">
          <div className="col">Specfieke technische data</div>
        </div>
      }

      <div className="row">
        {[porting, nieuw].includes(telephoneNumber.action) && !isExceptionAction && !telephoneNumber.isESimType && !isYielderProvider &&
          <Input
            s={4}
            value={telephoneNumber.simNumber || ''}
            label={"SIMkaart nummer"}
            name="simNumber"
            type="text"
            pattern="[0-9]{8,19}$"
            onChange={onChange}
            placeholder="Tussen 8 en 19 cijfers"
            required={true}
            disabled={inputsDisabled}
          />
        }
        
        {[porting, simlessPorting].includes(telephoneNumber.action) && !telephoneNumber.flexiblePorting && !isExceptionAction && !isYielderProvider &&
          <div className="col input-field porting-date-field">
            <label htmlFor={"portingDate"}>Porteren wensdatum</label>
            <DatePicker
              name="portingDate"
              id={"portingDate"}
              selected={telephoneNumber.portingDate ? new Date(telephoneNumber.portingDate) : ''}
              onChange={onChangePortingDate}
              onChangeRaw={e => e.preventDefault()}
              dateFormat="yyyy/MM/dd"
              minDate={new Date()}
              disabled={inputsDisabled}
              autoComplete="off"
              required={(parseInt(telephoneNumber.action) === porting || parseInt(telephoneNumber.action) === simlessPorting) && !telephoneNumber.flexiblePorting}
            />
          </div>
        }

        {![opheffen].includes(telephoneNumber.action) &&  !isOdidoProvider && !isYielderProvider &&
          <div className="col input-field porting-date-field" style={{marginBottom: [simlessPorting].includes(telephoneNumber.action) ? '30px' : null}}>
            <label htmlFor={"contractEndDate"}>{isVerlengenBeta ? 'Einddatum verlengde contract' : 'Einddatum nieuw contract'}</label>
            <DatePicker
              name="contractEndDate"
              id={"contractEndDate"}
              selected={telephoneNumber.contractEndDate ? new Date(telephoneNumber.contractEndDate) : ''}
              onChange={onChangeContractEndDate}
              onChangeRaw={e => e.preventDefault()}
              dateFormat="yyyy/MM/dd"
              minDate={minDate()}
              // filterDate={isWeekday}
              autoComplete="off"
              required={true}
              disabled={inputsDisabled}
            />
          </div>
        }

        {[retaining, canceling, nieuw, verlengen, reoder, reoderPorting].includes(telephoneNumber.action) && !isExceptionAction && !isVerlengenBeta && !isYielderProvider &&
          <div className="col input-field porting-date-field">
            <label htmlFor={"wishDate"}>Gewenste startdatum</label>
            <DatePicker
              name="contractStartDate"
              id={"contractStartDate"}
              selected={telephoneNumber.contractStartDate ? new Date(telephoneNumber.contractStartDate) : ''}
              dateFormat="yyyy/MM/dd"
              minDate={!telephoneNumber.flexiblePorting && telephoneNumber.portingDate ? new Date(telephoneNumber.portingDate) : new Date()}
              autoComplete="off"
              onChange={onChangeContractStartDate}
              onChangeRaw={e => e.preventDefault()}
              required={true}
              disabled={inputsDisabled}
            />
          </div>
        }

        {[upgrade].includes(telephoneNumber.action) &&
          <div className="col input-field porting-date-field">
            <label htmlFor={"upgradeWishDate"}>Gewenste upgrade datum</label>
            <DatePicker
              name="upgradeWishDate"
              id={"upgradeWishDate"}
              selected={telephoneNumber.upgrade_wish_date ? new Date(telephoneNumber.upgrade_wish_date) : ''}
              dateFormat="yyyy/MM/dd"
              minDate={new Date()}
              autoComplete="off"
              onChange={onChangeUpgradeWishDate}
              onChangeRaw={e => e.preventDefault()}
              required={true}
              disabled={inputsDisabled}
            />
          </div>
        }

        {![upgrade, opheffen].includes(telephoneNumber.action) && (telephoneNumber.action === verlengen && isVerlengenBeta) && !isOdidoProvider &&
          <>
            <Input
              s={4}
              value={telephoneNumber.contractNoticePeriod || ''}
              label={isVerlengenBeta ? 'Opzegtermijn' : 'Opzegtermijn nieuw contract'}
              name="contractNoticePeriod"
              type="text"
              pattern="[0-3]{1}$"
              onChange={onChange}
              required={false}
              disabled={inputsDisabled}
            />

            {!isVerlengenBeta &&
              <Input
                s={4}
                value={telephoneNumber.vamoid || ''}
                label="Vamoid"
                name="vamoid"
                type="text"
                onChange={onChange}
                required={telephoneNumber.vamoid}
                disabled={inputsDisabled}
              />
            }
          </>
        }

        {[opheffen].includes(telephoneNumber.action) && !isOdidoProvider && !isYielderProvider &&
          <div style={{marginTop: '10'}}>
            <Input
              type="select"
              name="terminateAsSoonPossible"
              label="Zo spoedig mogelijk opheffen?"
              onChange={handleChange}
              value={telephoneNumber.terminateAsSoonPossible}
              disabled={inputsDisabled}
            >
              <option value="Nee" key={0}>Nee</option>
              <option value="Ja" key={1}>Ja</option>
            </Input>

            {/*{telephoneNumber.terminateAsSoonPossible !== 'Ja' &&*/}
              {/*<div>*/}
                {/*<Input*/}
                    {/*s={4}*/}
                    {/*value={telephoneNumber.contractLength || ''}*/}
                    {/*label="Opzegtermijn in maanden"*/}
                    {/*name="contractLength"*/}
                    {/*type="text"*/}
                    {/*onChange={handleChange}*/}
                {/*/>*/}
              {/*</div>*/}
            {/*}*/}

            {telephoneNumber.terminateAsSoonPossible !== 'Ja' &&
              <div className="col input-field porting-date-field">
                <label htmlFor={"contractTerminationDate"}>Per wanneer opheffen?</label>
                <DatePicker
                  name="contractTerminationDate"
                  id={"contractTerminationDate"}
                  selected={telephoneNumber.contract_termination_date ? new Date(telephoneNumber.contract_termination_date) : ''}
                  dateFormat="yyyy/MM/dd"
                  minDate={getMinimumContractEndDate()}
                  autoComplete="off"
                  onChange={onChangeContractTerminationDate}
                  onChangeRaw={e => e.preventDefault()}
                  required={true}
                  disabled={inputsDisabled}
                />
              </div>
            }
          </div>
        }
      </div>


      {[porting].includes(telephoneNumber.action) && isOdidoProvider && portingDetails !== null &&
        <div className="row">
          <div className="col">Donor overbrengen</div>
          <Input
            s={4}
            value={portingDetails.donorPhoneNumber || ''}
            label="Telefoonnummer"
            name="donorPhoneNumber"
            type="text"
            onChange={onChangePortingDetails}
            onBlur={onSavePortingDetails}
            required={true}
            disabled={inputsDisabled}
          />
          <Input
            s={4}
            value={portingDetails.donorCustomerNumber || ''}
            label="Klant nummer"
            name="donorCustomerNumber"
            type="text"
            onChange={onChangePortingDetails}
            onBlur={onSavePortingDetails}
            required={true}
            disabled={inputsDisabled}
          />
          <Input
            type="select"
            name="donorContractHandling"
            label="Contractafhandeling"
            onChange={onChangePortingDetails}
            value={portingDetails.donorContractHandling}
            disabled={inputsDisabled}
          >
            <option value={null} key={0}/>
            <option value={'Early Termination'} key={1}>Early Termination</option>
            <option value={'Continuation'} key={2}>Continuation</option>
          </Input>
          <Input
            type="select"
            name="donorSubscriptionType"
            label="Soort abonnement"
            onChange={onChangePortingDetails}
            value={portingDetails.donorSubscriptionType}
            disabled={inputsDisabled}
          >
            <option value={null} key={0}/>
            <option value={'Postpaid'} key={1}>Postpaid</option>
          </Input>
          <Input
            type="select"
            name="donorCustomerType"
            label="Klant type"
            onChange={onChangePortingDetails}
            value={portingDetails.donorCustomerType}
            disabled={inputsDisabled}
          >
            <option value={null} key={0}/>
            <option value={'BUS'} key={1}>BUS</option>
            <option value={'RES'} key={2}>RES</option>
            <option value={'REBUS'} key={3}>REBUS</option>
            <option value={'PREPAID'} key={4}>PREPAID</option>
          </Input>
        </div>
      }

      {[porting].includes(telephoneNumber.action) && isYielderProvider && portingDetails !== null &&
          <div className="row">
            <Input
                s={4}
                value={telephoneNumber.firstName || ''}
                onChange={onChange}
                label="Voornaam"
                name="firstName"
                type="text"
                disabled={inputsDisabled}
            />
            <Input
                s={4}
                value={telephoneNumber.insertion || ''}
                onChange={onChange}
                label="Tussenvoegsels"
                name="insertion"
                type="text"
                disabled={inputsDisabled}
            />
            <Input
                s={4}
                value={telephoneNumber.lastName || ''}
                onChange={onChange}
                label="Achternaam"
                name="lastName"
                type="text"
                disabled={inputsDisabled}
            />
            {
              [null, ''].includes(portingDetails.serviceProvider) && (
                    <Input
                        s={4}
                        type="select"
                        name="serviceProvider"
                        label="Verlatende provider"
                        onChange={onChangePortingDetails}
                        value={portingDetails.serviceProvider}
                        disabled={inputsDisabled}
                    >
                      <option value={null} key={0}/>
                      {networkData.serviceProviders.map((serviceProvider) => <option
                          value={serviceProvider.code}>{serviceProvider.name}</option>)}
                    </Input>
                )
            }
            <Input
                s={4}
                value={portingDetails.donorPhoneNumber || ''}
                label={'Huidig mobiel nummer'}
                name="donorPhoneNumber"
                type="text"
                onChange={onChangePortingDetails}
                onBlur={onSavePortingDetails}
                required={true}
                disabled={inputsDisabled}
            />
            {/*<Input
                s={4}
                value={telephoneNumber.operator}
                label={'Operator'}
                name="operator"
                type="select"
                onChange={onChange}
                disabled={inputsDisabled}
            >
              <option value="-1" key="-1">Selecteer</option>
              <option value="KPN" key="1">KPN</option>
              <option value="Vodafone" key="2">Vodafone</option>
            </Input>*/}
            <Input
                s={4}
                value={portingDetails.donorCustomerNumber || ''}
                label={'Klantnr. verlatende provider'}
                name="donorCustomerNumber"
                type="text"
                onChange={onChangePortingDetails}
                onBlur={onSavePortingDetails}
                required={true}
                disabled={inputsDisabled}
            />
            <Input
                s={4}
                value={telephoneNumber.simNumber || ''}
                label={'Nieuwe SIMkaartnummer'}
                name="simNumber"
                type="text"
                pattern="[0-9]{8,22}$"
                onChange={onChange}
                placeholder="Tussen 8 en 22 cijfers"
                required={true}
                disabled={inputsDisabled}
            />
            {
              [null, ''].includes(portingDetails.donorOperator) && (
                    <Input
                        s={4}
                        type="select"
                        name="donorOperator"
                        label="Donor SPN"
                        onChange={onChangePortingDetails}
                        value={portingDetails.donorOperator}
                        disabled={inputsDisabled}
                    >
                      <option value={null} key={0}/>
                      {networkData.operators.map((operator) => <option value={operator.code}>{operator.name}</option>)}
                    </Input>
                )
            }
            <Input
                s={8}
                type="select"
                name="portingScenario"
                label="Portingscenario"
                onChange={onChangePortingDetails}
                value={portingDetails.portingScenario}
                disabled={inputsDisabled}
            >
              <option value={null} key={0}/>
              <option value={1} key={1}>Porting per contract einddatum</option>
              <option value={2} key={2}>Porting met vroegtijdige beëindiging van contract</option>
              <option value={3} key={3}>Porting na beëindiging van contract</option>
              <option value={4} key={4}>Porting met contractvoortzetting</option>
            </Input>
            <div className="col s4 input-field porting-date-field">
              <label htmlFor={"portingDate"}>Gewenste porteerdatum</label>
              <DatePicker
                  name="portingDate"
                  id={"portingDate"}
                  selected={telephoneNumber.portingDate ? new Date(telephoneNumber.portingDate) : ''}
                  onChange={onChangePortingDate}
                  onChangeRaw={e => e.preventDefault()}
                  dateFormat="yyyy/MM/dd"
                  minDate={new Date()}
                  disabled={inputsDisabled}
                  autoComplete="off"
                  required={(parseInt(telephoneNumber.action) === porting || parseInt(telephoneNumber.action) === simlessPorting) && !telephoneNumber.flexiblePorting}
              />
            </div>
          </div>
      }

      {[retaining, canceling, nieuw, verlengen, reoder, reoderPorting].includes(telephoneNumber.action) && isYielderProvider &&
          <div className="row">
            <Input
                s={4}
                value={telephoneNumber.firstName || ''}
                onChange={onChange}
                label="Voornaam"
                name="firstName"
                type="text"
                disabled={inputsDisabled}
            />
            <Input
                s={4}
                value={telephoneNumber.insertion || ''}
                onChange={onChange}
                label="Tussenvoegsels"
                name="insertion"
                type="text"
                disabled={inputsDisabled}
            />
            <Input
                s={4}
                value={telephoneNumber.lastName || ''}
                onChange={onChange}
                label="Achternaam"
                name="lastName"
                type="text"
                required
                disabled={inputsDisabled}
            />
            <Input
                s={4}
                value={telephoneNumber.number || ''}
                onChange={onChange}
                label="Huidig mobiel nummer"
                name="number"
                type="text"
                required
                disabled={inputsDisabled}
            />
            <Input
                s={4}
                value={telephoneNumber.email || ''}
                onChange={onChange}
                label="Huidig mobiel nummer"
                name="number"
                type="text"
                required
                disabled={inputsDisabled}
            />
            <Input
                s={4}
                value={telephoneNumber.operator}
                label={'Operator'}
                name="operator"
                type="select"
                onChange={onChange}
                disabled={inputsDisabled}
            >
              <option value="-1" key="-1">Selecteer</option>
              <option value="KPN" key="1">KPN</option>
              <option value="Vodafone" key="2">Vodafone</option>
            </Input>
            <div className="col s4 input-field porting-date-field">
              <label htmlFor={"wishDate"}>Gewenste startdatum</label>
              <DatePicker
                  name="contractStartDate"
                  id={"contractStartDate"}
                  selected={telephoneNumber.contractStartDate ? new Date(telephoneNumber.contractStartDate) : ''}
                  dateFormat="yyyy/MM/dd"
                  minDate={!telephoneNumber.flexiblePorting && telephoneNumber.portingDate ? new Date(telephoneNumber.portingDate) : new Date()}
                  autoComplete="off"
                  onChange={onChangeContractStartDate}
                  onChangeRaw={e => e.preventDefault()}
                  required={true}
                  disabled={inputsDisabled}
              />
            </div>
          </div>
      }

      <div className="row">
        {![upgrade, verlengen, opheffen].includes(telephoneNumber.action) && !isExceptionAction && !isOdidoProvider && !isYielderProvider &&
            <Input
                s={4}
                value={telephoneNumber.customerNumber || ''}
                label="Klantnummer"
                name="customerNumber"
                type="text"
                onChange={onChange}
                disabled={inputsDisabled}
                required={telephoneNumber.action === porting}
            />
        }

        {![opheffen].includes(telephoneNumber.action) && !isExceptionAction && !isVerlengenBeta && !isOdidoProvider && !isYielderProvider &&
            <Input
                s={4}
                value={telephoneNumber.label || ''}
                label="Label"
            name="label"
            type="text"
            onChange={onChange}
            disabled={inputsDisabled}
          />
        }
      </div>

      {(isVerlengenBetaType && verlengenCondition) && !isOdidoProvider && !isYielderProvider &&
        <div className="row" style={{marginTop: '-20px', marginBottom: '35px'}}>
          <div className="col s12">
            <input
              id="sameDate"
              name="generalDate"
              className="input-field"
              checked={!!telephoneNumber.generalDate}
              type="checkbox"
              onChange={onChange}
              disabled={inputsDisabled}
            />
            <label htmlFor="sameDate">
              {opheffen ? 'Optie ' : 'Datum '}overal toepassen voor{opheffen && ' opheffen'}{verlengen && ' verlengen'}{upgrade && ' upgrades'}
            </label>
          </div>
        </div>
      }

      {![opheffen].includes(telephoneNumber.action) && !isExceptionAction && !isVerlengenBeta && !isYielderProvider &&
        <div className="row">
          <div className="col">Specfieke opties</div>
        </div>
      }

      <div>
        {![opheffen].includes(telephoneNumber.action) && !isExceptionAction && !isYielderProvider && renderSpecificOptions()}
      </div>
    </>
  );
};

export default MobileTelephoneNumberForm;
