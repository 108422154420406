import styled from 'styled-components';

export default styled.div`
  display: inline-block;
  .sketch-picker {
    position: relative;
    z-index: 3;
  }
  input {
    height: 1rem;
    margin-bottom: 0;
  }
`;
