import React from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table';
import RatioOverflowTableWrapper from "../../../components/styled-components/ratio-overflow-table-wrapper/RatioOverflowTableWrapper";

const HeadersNames = ['Gebruikersnaam', 'E-mailadres', 'Rollen', 'Laatste inlog', 'Acties'];

const UsersGrid = (props) => {

    const generateRow = (row) => {
        return (
            <TableRow key={row.username}>
                <TableRowColumn>{row.username}</TableRowColumn>
                <TableRowColumn>{row.email}</TableRowColumn>
                <TableRowColumn>{row.roles.join(',')}</TableRowColumn>
                <TableRowColumn>{row.last_login}</TableRowColumn>
                <TableRowColumn>
                    <button className="btn" onClick={() => props.onEditClick(row)}>
                        Wijzigen
                    </button>
                    <button className="btn" onClick={() => props.onDeleteClick(row)}>
                        Verwijderen
                    </button>
                </TableRowColumn>
            </TableRow>
        );
    }

    const generateHeader = () => {
        return (
            HeadersNames.map(headerName =>
                <TableHeaderColumn key={headerName} >{headerName}</TableHeaderColumn>
            )
        );
    }

    return (
    <RatioOverflowTableWrapper>
        <div className="valign-wrapper">
            <Table
                wrapperStyle={{
                    width: '100%',
                    height: '100%'
                }}
                selectable={false}
            >
                <TableHeader displaySelectAll={false} adjustForCheckbox={false} >
                    <TableRow>
                        {
                            generateHeader()
                        }
                    </TableRow>
                </TableHeader>
                <TableBody displayRowCheckbox={false}>
                    {
                        props.rows.map(row =>
                            generateRow(row)
                        )
                    }
                </TableBody>
            </Table>
        </div>
    </RatioOverflowTableWrapper>
    );
};

UsersGrid.propTypes = {
    onEditClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    rows: PropTypes.array.isRequired
}

export default UsersGrid;