import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Col, Input, Row} from 'react-materialize'

import accessActions from "../../../actions/workflow/accessActions";
import stepsActions from '../../../actions/stepsActions';
import quotationActions from "../../../actions/quotationActions";
import locationActions from '../../../actions/locationActions';

import QuotationType from "../../../constants/quotationType";

import ProviderHelper from "../../../helper/providerHelper";
import ExistingAccessData from "../../common/ExistingAccessData";
import LoaderComponent from "../../../containers/common/LoaderComponent";
import providerType, {odidoProviders} from "../../../constants/providerType";

class AccessGlobalForm extends Component {
  state = {
    changeAccessManagementDialogOpened: false,
    flightCaseChangeDialogState: false,
    premiumWifiSelections: [],
    fortiGateSelections: [],
    fortiGateActive: false,
    loading: false
  };

  isAvailableToGoForward() {
    const locationsWithoutSelectedConnection = this.props.locations.allLocations.filter(location => (
      location.id > 0 && location.connections.length > 0 && location.connections[0].selected_connections.length === 0
    ));

    const currentQuotationIsMigration = this.props.quotation.isMigration;

    return (!currentQuotationIsMigration || (locationsWithoutSelectedConnection === 0));
  }

  componentDidMount() {
    let {quotationOptions} = this.props;

    this.props.stepsActions.setAvailableToGoForward(this.isAvailableToGoForward());

    if (quotationOptions.loaded) {
      let wifiSelections = quotationOptions.data.premiumWifi.currentValues ?
        quotationOptions.data.premiumWifi.currentValues : [];
      let foritGateSelections = quotationOptions.data.fortiGate.currentValues ?
        quotationOptions.data.fortiGate.currentValues : [];

      this.setState({
        premiumWifiSelections: wifiSelections.length === 0 ? [{id: '', quantity: 1}] : wifiSelections,
        fortiGateSelections: foritGateSelections.length === 0 ? [{id: '', quantity: 1}] : foritGateSelections,
        fortiGateActive: this.props.quotation.currentQuotation.fortiGateActive
      });
    }
  }

  componentDidUpdate(prevProps) {
    let {quotationOptions} = prevProps;

    if (quotationOptions) {
      if (quotationOptions.data.premiumWifi.currentValues !== this.props.quotationOptions.data.premiumWifi.currentValues) {
        let wifiSelections = this.props.quotationOptions.data.premiumWifi.currentValues;
        this.setState({ premiumWifiSelections: wifiSelections.length === 0 ? [{id: '', quantity: 1}] : wifiSelections});
      }

      if (quotationOptions.data.fortiGate.currentValues !== this.props.quotationOptions.data.fortiGate.currentValues) {
        let foritGateSelections = this.props.quotationOptions.data.fortiGate.currentValues;
        this.setState({ fortiGateSelections: foritGateSelections.length === 0 ? [{id: '', quantity: 1}] : foritGateSelections});
      }
    }

    if (prevProps.quotation.currentQuotation.fortiGateActive !== this.props.quotation.currentQuotation.fortiGateActive) {
      this.setState({ fortiGateActive: this.props.quotation.currentQuotation.fortiGateActive });
    }
  }

  updateQuotationProperty = ({target}) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }
    this.props.quotationActions.updateQuotationProperty(target);
    this.updateQuotation();
  };

  updateQuotation() {
    if (this.updateQuotationTimeout) {
      clearTimeout(this.updateQuotationTimeout);
    }

    this.updateQuotationTimeout = setTimeout(() => {
      this.props.quotationActions.updateQuotation(this.props.quotation.currentQuotation);
    }, 1000);
  }

  handleOptionsChange = (type, value) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }
    this.props.updateQuotationOptions(
      this.props.quotation.currentQuotation.id,
      type,
      value
    );
  };

  addSelectionOption = ({target}) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }

    let type = target.name;
    const selections = type === 'premiumWifi' ? this.state.premiumWifiSelections : this.state.fortiGateSelections;
    selections.push({ id: '', quantity: 1});

    if (type === 'premiumWifi') {
      this.setState({ premiumWifiSelections: selections });
    } else {
      this.setState({ fortiGateSelections: selections });
    }
  };

  removeOption = (index, type) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }

    const selections = type === 'premiumWifi' ? this.state.premiumWifiSelections : this.state.fortiGateSelections;
    selections.splice(index, 1);

    if (type === 'premiumWifi') {
      this.setState({ premiumWifiSelections: selections });
    } else {
      this.setState({ fortiGateSelections: selections });
    }

    this.props.saveSelections(selections, type);
  };

  onChange = (event, index, type) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }

    const {value} = event.target;
    let selections = type === 'premiumWifi' ? this.state.premiumWifiSelections : this.state.fortiGateSelections;

    selections[index].id = parseInt(value);
    selections[index].quantity = 1;

    if (type === 'premiumWifi') {
      this.setState({ premiumWifiSelections: selections });
    } else {
      this.setState({ fortiGateSelections: selections });
    }

    this.props.saveSelections(selections, type);
  };

  changeQuantity = (event, product, type) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }

    const {value} = event.target;
    let selections = type === 'premiumWifi' ? this.state.premiumWifiSelections : this.state.fortiGateSelections;
    selections = selections.map((selection) => {
      if (selection.id === product) {
        selection.quantity = value
      }
      return selection;
    });

    //filter selections to not send null ids
    selections = selections.filter(selection => selection.id != null) || [];

    if (type === 'premiumWifi') {
      this.setState({ premiumWifiSelections: selections });
    } else {
      this.setState({ fortiGateSelections: selections });
    }

    this.props.saveSelections(selections, type);
  };

  setConnectionProductsToVerlengen = () => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }

    const quotationId = this.props.quotation.currentQuotation.id;

    this.setState({loading: true});

    this.props.quotationActions.setConnectionProductsToVerlengen(quotationId)
      .then(() => this.props.accessActions.retrieveExistingAccessData(quotationId))
      .then(() => this.props.getQuotationOptions(quotationId))
      .then(() => this.setState({loading:false}));
  };

  renderOption = (option) => (
    <option key={option.id} value={option.id}>
      {option.name}
    </option>
  );

  renderMigration() {
    return <div id="Forms">
      <h1>
        <span className="themeprimarycolor">Migratie offerte: </span>
        Access instellen themeprimarycolor locatie
      </h1>
      Stel per locatie de gewenste verbinding of verbindingen in.
    </div>
  }

  getIpVPNSelectedValue() {
    let {quotationOptions, existingAccessData} = this.props;

    let ipVpnSelectedValue = null
    let disabled = false
    let message = null
    let accessOrders = existingAccessData.filter((el) => !el.parent_id && el.product_group !== 'Connectivity');

    if (quotationOptions.data.vpn.currentValue !== null) {
      ipVpnSelectedValue = quotationOptions.data.vpn.currentValue.id
    }

    let ipVpnProduct = accessOrders.find((el) => ['RV0004O604', 'RV0004O104', 'RV0004O004'].includes(el.product_id))

    if (ipVpnProduct) {
      disabled = true
      ipVpnSelectedValue = quotationOptions.data.vpn.options.find((el) => el.product_number === ipVpnProduct.product_id).id

      if (ipVpnProduct.terminate) {
        ipVpnSelectedValue = null
        message = 'Er is gekozen de huidige IP-VPN product op te heffen'
      }
    }

    return {
      value: ipVpnSelectedValue,
      disabled,
      message
    };
  }

  getExtraOptionValue() {
    let {quotationOptions} = this.props;
    let extraOptionValue = null
    let disabled = false

    if (quotationOptions.data['extra-option'].currentValue !== null) {
      extraOptionValue = quotationOptions.data['extra-option'].currentValue.id
    }

    let extraOptions = this.props.existingAccessData.filter((el) => el.product_id === '143A00001B')
    if (extraOptions && extraOptions.length) {
      extraOptionValue = quotationOptions.data['extra-option'].options?.id
      disabled = true

      let terminateOrders = extraOptions.filter((el) => el.terminate)
      if (terminateOrders.length === extraOptions.length) {
        extraOptionValue = null
        disabled = false
      }
    }

    return {
      value: extraOptionValue,
      disabled
    };
  }

  getZakelijkValue() {
    let {quotationOptions, existingAccessData} = this.props;
    let disabled = false
    let value = null

    if (quotationOptions.data.pin.currentValue !== null) {
      value = quotationOptions.data.pin.currentValue.id
    }

    let order = existingAccessData.find((el) => ['QS0201P206', 'QS0202P206'].includes(el.product_id));
    if (order) {
      value = quotationOptions.data.pin.options.find((el) => el.product_number === order.product_id).id
      disabled = true

      if (order.terminate) {
        value = null
        disabled = false
      }
    }

    return {
      value,
      disabled
    };
  }

  getBackupValue() {
    let {quotationOptions, existingAccessData} = this.props;
    let disabled = false
    let message = null
    let value = null

    if (quotationOptions.data.backUp.currentValue !== null) {
      value = quotationOptions.data.backUp.currentValue.id
    }

    let order = existingAccessData.find((el) => el.product_id === '082J00001B');
    if (order) {
      disabled = true
      value = quotationOptions.data.backUp.options.find((el) => el.product_number === order.product_id).id

      if (order.terminate) {
        value = null
        message = 'Er is gekozen de huidige 4G Backup product op te heffen'
      }
    }

    return {
      value,
      disabled,
      message
    };
  }

  getFortiGateValue() {
    let {existingAccessData} = this.props;
    let disabled = false
    let message = null
    let value = this.state.fortiGateActive

    let order = existingAccessData.find((el) => el.product_id === '129A00000N');
    if (order) {
      disabled = true
      value = true

      if (order.terminate) {
        value = false
        message = 'Er is gekozen de huidige Fortigate Base product op te heffen'
      }
    }

    return {
      value,
      disabled,
      message
    };
  }

  renderExtraOptions = (extraOption) => {
    let options = [];
    options.push(<option key="-1" value={null}>Nee</option>);

    if (extraOption) {
      options.push(<option key={extraOption.id} value={extraOption.id}>Ja</option>);
    }

    return options;
  };

  renderForm(renderAccessManagementDialog) {
    let isSalesValue = this.props.authentication.isSalesValue;
    let {quotationOptions} = this.props;
    let {premiumWifiSelections, fortiGateSelections} = this.state;
    let {currentQuotation} = this.props.quotation;
    let {accessPortfolio, inputsDisabled} = currentQuotation;
    const odidoIsraCondition = currentQuotation.provider === providerType.ODIDO && accessPortfolio === 'irma';
    const israCondition = [providerType.KPN].includes(currentQuotation.provider);
    let providerCondition = odidoIsraCondition || israCondition;
    let isPremiumWifiNeeded = providerCondition && currentQuotation.contractDuration >= 36 && !isSalesValue;
    let isFortiGateNeeded = providerCondition && currentQuotation.contractDuration >= 12 && !isSalesValue;
    let selectedWifis = premiumWifiSelections.map((option) => option.id);
    let selectedFortiGate = fortiGateSelections.map((option) => option.id);
    let accessOrders = this.props.existingAccessData.filter((el) => !el.parent_id && el.product_group !== 'Connectivity');
    let isVerlengen = currentQuotation.type === QuotationType.TYPE_VERLENGEN;

    let {value: ipVpnSelectedValue, disabled: ipVpnDisabled, message: ipVpnMessage} = this.getIpVPNSelectedValue();
    let {value: extraOptionValue, disabled: extraOptionDisabled} = this.getExtraOptionValue();
    let {value: zakelijkValue, disabled: zakelijkDisabled} = this.getZakelijkValue();
    let {value: backupValue, disabled: backupDisabled, message: backupMessage} = this.getBackupValue();
    let {value: fortigateValue, disabled: fortigateDisabled, message: fortigateMessage} = this.getFortiGateValue();

    return <div className="col s12">
      {
        isVerlengen &&
        <div>
          <div id="Forms" className="row">
            <div className="col s12">
              <div className="row" style={{margin: 0, display: 'flex', alignItems: 'center'}}>
                <div className="col s12 m6">
                  <h1 className={'ratio-section-title'} style={{margin: '22px 0'}}>
                  <span className={'ratio-section-title'}>
                      <i className="small material-icons left ratio-section-title">settings_input_component</i>
                      Actieve Access orders wijzigen
                  </span>
                  </h1>
                </div>

                <div className="col s12 m6">
                  <button
                    className="btn doceri-btn-right ratio-btn-right"
                    onClick={this.setConnectionProductsToVerlengen}
                    type="submit"
                    name="action"
                  >
                    Alle bestaande access producten verlengen
                  </button>
                </div>
              </div>
            </div>

            <Col s={12}>
              <Row style={{margin: 0}}>
                <div className="line tussenkop">
                  Kies hier per product welke actie u wilt doen <br/>
                  Glasvezel is (nog) niet via Ratio aan te passen (up/downgrade/opheffen) <br/>
                  Controleer goed welke opties al van toepassing zijn bij het instellen van de contractsopties van Access.<br></br>
                </div>
              </Row>

              <Row>
                <ExistingAccessData
                  data={accessOrders}
                  inputsDisabled={inputsDisabled}
                  upgradeData={this.props.upgradingData}
                  getQuotationOptions={this.props.getQuotationOptions}
                  generateRetentionOptions={() => {}}
                />
              </Row>
            </Col>
          </div>
        </div>
      }

      {!isSalesValue && ProviderHelper.hasModuleEnabled(this.props.quotation.currentQuotation, 'ipVpn') ?
        <div className="row">
          <div className="col s12 overviewkopbg" style={{marginTop: 20}}>
            <div className="overviewkop">
              <i className="small material-icons left themeprimarycolor">playlist_add_check</i>IP VPN
            </div>
          </div>
          <div className="col s12 blokborder overviewkopbg">
            <div className="row reactnopaddingmargin">
              {quotationOptions.loaded && (
                <Input
                  s={6}
                  name="selection_vpn"
                  type="select"
                  label="IP-VPN"
                  disabled={quotationOptions.data.vpn.disabled || ipVpnDisabled || inputsDisabled}
                  onChange={(e) => this.handleOptionsChange('vpn', e.target.value)}
                  multiple={false}
                  value={ipVpnSelectedValue}
                >
                  <option key="-1" value={null}>Nee</option>
                  {quotationOptions.data.vpn.options.map(this.renderOption)}
                </Input>
              )}
            </div>
            {ipVpnMessage && <div style={{color: 'red', marginBottom: '8px'}}>{ipVpnMessage}</div>}
          </div>
          <br /><br />
        </div> : ''
      }

      {
        isPremiumWifiNeeded && (
          <div className="row">
            <div className="col s12 overviewkopbg" style={{marginTop: 20}}>
              <div className="overviewkop">
                <i className="small material-icons left themeprimarycolor">playlist_add_check</i>Premium Wifi
              </div>
            </div>
            <div className="col s12 blokborder overviewkopbg" style={{paddingBottom: 20}}>
              {
                quotationOptions.loaded && (
                  premiumWifiSelections.map((option, index) => {
                    return (
                      <div className="row reactnopaddingmargin">
                        <div>
                          {
                            option.id !== '' &&
                            <Input
                              s={1}
                              type="number"
                              label="Aantal"
                              value={option.quantity}
                              onChange={(e) => {this.changeQuantity(e, option.id, 'premiumWifi')}}
                              min={1}
                              max={999}
                              disabled={inputsDisabled}
                            />
                          }
                          <Input
                            s={8}
                            key={index}
                            name='premiumWifi'
                            type='select'
                            label={`Kies de gewenste Premium Wifi`}
                            value={option.id}
                            onChange={(e) => {this.onChange(e, index, 'premiumWifi')}}
                            multiple={false}
                            disabled={inputsDisabled}
                          >
                            <option value="">Selecteer</option>
                            {quotationOptions.data.premiumWifi.options
                              .filter((wifiOption) => !selectedWifis.includes(wifiOption.id) || wifiOption.id === option.id)
                              .map(this.renderOption)
                            }
                          </Input>
                        </div>
                        <button
                          className='btn doceri-btn-right ratio-btn-right s1'
                          onClick={() => this.removeOption(index, 'premiumWifi')}
                          style={{padding: '0px 15px'}}
                          disabled={inputsDisabled}
                        >
                          Verwijder optie
                        </button>
                      </div>
                    )
                  })
                )
              }

              { premiumWifiSelections.length < quotationOptions.data.premiumWifi.options.length && (
                <div className="row reactnopaddingmargin">
                  <button
                    onClick={this.addSelectionOption}
                    className="btn doceri-btn-right ratio-btn-white-right right"
                    type="submit"
                    name="premiumWifi"
                    disabled={inputsDisabled}
                  >
                    <i className="small material-icons left blauw">playlist_add</i> Premium Wifi toevoegen
                  </button>
                </div>)
              }
            </div>
          </div>
        )
      }

      { isFortiGateNeeded && (
        <div className="row">
          <div className="col s12 overviewkopbg" style={{marginTop: 20}}>
            <div className="overviewkop">
              <i className="small material-icons left themeprimarycolor">playlist_add_check</i>FortiGate
            </div>
          </div>
          <div className="col s12 overviewkopbg" style={{ paddingTop: '20px', borderTop: '0px' }}>
            <div className="row reactnopaddingmargin">
              <div>
                <Input
                  s={6}
                  key={'FortiGateActive'}
                  name='fortiGateActive'
                  type='select'
                  label={`Wil je FortiGate inschakelen?`}
                  value={fortigateValue}
                  onChange={(e) => {
                    this.updateQuotationProperty(e)
                  }}
                  multiple={false}
                  disabled={fortigateDisabled || inputsDisabled}
                >
                  <option key={0} value={false}>Nee</option>
                  <option key={1} value={true}>Ja</option>
                </Input>
              </div>
            </div>
            {fortigateValue && (
              <div>
                {quotationOptions.loaded && (fortiGateSelections.map((option, index) => {
                  return (
                    <div className="row reactnopaddingmargin">
                      <div>
                        {option.id !== '' &&
                          <Input
                            s={1}
                            type="number"
                            label="Aantal"
                            value={option.quantity}
                            onChange={(e) => {this.changeQuantity(e, option.id, 'fortiGate')}}
                            min={1}
                            max={999}
                            disabled={inputsDisabled}
                          />
                        }

                        <Input
                          s={8}
                          key={index}
                          name='premiumWifi'
                          type='select'
                          label={`Fortigate optie toevoegen`}
                          value={option.id}
                          onChange={(e) => {this.onChange(e, index, 'fortiGate')}}
                          multiple={false}
                          disabled={inputsDisabled}
                        >
                          <option value="">Selecteer</option>
                          {quotationOptions.data.fortiGate.options
                            .filter((fortiGateOption) => !selectedFortiGate.includes(fortiGateOption.id) || fortiGateOption.id === option.id)
                            .map(this.renderOption)
                          }
                        </Input>
                      </div>

                      <button
                        className='btn doceri-btn-right ratio-btn-right s1'
                        onClick={() => this.removeOption(index, 'fortiGate')}
                        style={{padding: '0px 15px'}}
                        disabled={inputsDisabled}
                      >
                        Verwijder optie
                      </button>
                    </div>
                  )}))
                }

                { fortiGateSelections.length < quotationOptions.data.fortiGate.options.length && (
                  <div className="row reactnopaddingmargin" style={{ paddingBottom: "20px" }}>
                    <button
                      onClick={this.addSelectionOption}
                      className="btn doceri-btn-right ratio-btn-white-right right"
                      type="submit"
                      name="fortiGate"
                      disabled={inputsDisabled}
                    >
                      <i className="small material-icons left blauw">playlist_add</i> FortiGate toevoegen
                    </button>
                  </div>)
                }
              </div>
            )}

            {fortigateMessage && <div style={{color: 'red', marginBottom: '8px'}}>{fortigateMessage}</div>}
          </div>
          <br /><br />
        </div>
      )
      }

      {!isSalesValue && ProviderHelper.hasModuleEnabled(this.props.quotation.currentQuotation, 'extraOption') ?
        <div className="row">
          <div className="col s12 overviewkopbg" style={{marginTop: 20}}>
            <div className="overviewkop">
              <i className="small material-icons left themeprimarycolor">playlist_add_check</i>Extra Veilig Internet
            </div>
          </div>
          <div className="col s12 blokborder overviewkopbg">
            <div className="row reactnopaddingmargin">
              {quotationOptions.loaded && (
                <Input
                  s={6}
                  name="selection_extra_option"
                  type="select"
                  label={quotationOptions.data['extra-option'].disabled ? 'Extra veilig internet kan niet in combinatie met IP VPN' : 'Extra Veilig Internet'}
                  disabled={quotationOptions.data['extra-option'].disabled || extraOptionDisabled || inputsDisabled}
                  onChange={(e) => this.handleOptionsChange('extra-option', e.target.value)}
                  multiple={false}
                  value={extraOptionValue}
                >
                  {this.renderExtraOptions(quotationOptions.data['extra-option'].options)}
                </Input>
              )}
            </div>
          </div>
          <br /><br />
        </div> : ''
      }

      {!isSalesValue && quotationOptions.data['backUp'].options.length && ProviderHelper.hasModuleEnabled(this.props.quotation.currentQuotation, 'backUp') ?
        <div className="row">
          <div className="col s12 overviewkopbg" style={{marginTop: 20}}>
            <div className="overviewkop">
              <i className="small material-icons left themeprimarycolor">playlist_add_check</i>4G Back-up
            </div>
          </div>
          <div className="col s12 blokborder overviewkopbg">
            <div className="row reactnopaddingmargin">
              {quotationOptions.loaded && (
                <Input
                  s={6}
                  name="selection_4g_back_up"
                  type="select"
                  label="4G Back-up"
                  onChange={(e) => this.handleOptionsChange('backUp', e.target.value)}
                  multiple={false}
                  value={backupValue}
                  disabled={backupDisabled || inputsDisabled}
                >
                  <option key="-1" value={null}>Nee</option>
                  <option key={quotationOptions.data['backUp'].options[0].id} value={quotationOptions.data['backUp'].options[0].id}>
                    Ja
                  </option>
                </Input>
              )}
            </div>
            {backupMessage && <div style={{color: 'red', marginBottom: '8px'}}>{backupMessage}</div>}
          </div>
          <br /><br />
        </div> : ''
      }

      {ProviderHelper.hasModuleEnabled(this.props.quotation.currentQuotation, 'selectionPin') ?
        <div className="row">
          <div className="col s12 overviewkopbg" style={{marginTop: 20}}>
            <div className="overviewkop">
              <i className="small material-icons left themeprimarycolor">playlist_add_check</i>Zakelijk pinnen op locatie
            </div>
          </div>
          <div className="col s12 blokborder overviewkopbg">
            <div className="row reactnopaddingmargin">
              {quotationOptions.loaded && (
                <Input
                  s={6}
                  name="selection_pin"
                  type="select"
                  label="Zakelijk pinnen"
                  onChange={(e) => this.handleOptionsChange('pin', e.target.value)}
                  multiple={false}
                  value={zakelijkValue}
                  disabled={zakelijkDisabled || inputsDisabled}
                >
                  <option key="-1" value={null}>Nee</option>
                  {quotationOptions.data.pin.options.map(this.renderOption)}
                </Input>
              )}
            </div>
          </div>
        </div> : ''
      }
    </div>;
  }

  render() {
    let {formState} = this.props;
    const currentQuotation = this.props.quotation;
    const renderAccessManagementDialog = this.state.changeAccessManagementDialogOpened;
    const isMigration = currentQuotation.type === QuotationType.TYPE_MIGRATION;
    const {theme} = this.props.authentication;

    return (
      <div>
        {formState ? isMigration ? this.renderMigration() : (this.state.loading ? <LoaderComponent theme={theme}/> : this.renderForm(renderAccessManagementDialog)) : ''}
      </div>
    );
  }
}

const mapStateToProps = ({access, locations, authentication}) => {
  return {
    access,
    locations,
    authentication
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    accessActions: bindActionCreators(accessActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
    locationActions: bindActionCreators(locationActions, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessGlobalForm);
