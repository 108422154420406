import React from 'react';
import beautify from 'xml-beautifier';

import DialogHeader from "../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../containers/common/KpnDialog/DialogBody";
import DialogButton from "../../containers/common/KpnDialog/DialogButton";
import KpnDialog from "../../containers/common/KpnDialog/KpnDialog";
import DialogFooter from "../../containers/common/KpnDialog/DialogFooter";
import DefaultDialogBody from "../../containers/common/KpnDialog/DefaultDialogBody";

const OrderXmlDialog = (props) => {
  const {dialogOpened, onCancelDialog, ordersXmlString, isOdidoProvider} = props;

  return (
    <KpnDialog
      dialogState={dialogOpened}
      modal={false}
      dialogHeader={(
        <DialogHeader
          headerText={isOdidoProvider ? "Quotation order payload" : "Quotation order XML"}
          closeHandler={onCancelDialog}
        />
      )}

      dialogBody={
        <DialogBody>
          {!ordersXmlString || ordersXmlString.length <= 0 ?
            <DefaultDialogBody text={'The quotation order XML is empty...'}/> :

            isOdidoProvider ? (
              ordersXmlString && ordersXmlString.map((orderXml) => (
                <textarea key={orderXml} name="orderXml" style={{height: 700}}>
                  {JSON.stringify(orderXml, null, 4)}
                </textarea>
              ))) :
              (ordersXmlString && ordersXmlString.map(orderXml => (
                <textarea key={orderXml} name="orderXml" style={{height: 700}}>
                  {beautify(`${orderXml}`)}
                </textarea>
              )))
          }
        </DialogBody>
      }

      dialogFooter={
        <DialogFooter
          buttonRight={(
            <DialogButton
              buttonAction={onCancelDialog}
              buttonText="Close"
            />
          )}
        />
      }
    />
  );
};

export default OrderXmlDialog;
