import { actionTypes } from '../constants/actionTypes';

const setCurrentStep = (stepId) => {
    return {
        type: actionTypes.setCurrentStep,
        payload: stepId
    };
};

const setQuotationProvider = (providerId) => {
    return {
        type: actionTypes.setQuotationProvider,
        payload: providerId
    }
};

const setAvailableToGoForward = (valueToSet) => {
    return {
        type: actionTypes.setAvailableToGoForward,
        payload: valueToSet
    };
};

const updateIsLead = (payload) => {
    return {
        type: actionTypes.updateIsLead,
        payload: payload
    };
};

const setAvailabilityMessage = (payload) => {
    return {
        type: actionTypes.setAvailabilityMessage,
        payload: payload
    };
};

export default {
    setCurrentStep,
    setAvailableToGoForward,
    setQuotationProvider,
    updateIsLead,
    setAvailabilityMessage
}
