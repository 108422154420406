import React from 'react';

import PropTypes from 'prop-types';
import {Row, Col, Input} from "react-materialize";

import './SipTrunk.css';

const SipTrunk = ({data, onChange, isNew, isNewValid, onClickAdd, onClickDelete, isMigration, isLast, inputsDisabled}) => {
  const numbers1_60 = Array.apply(null, Array(60)).map((item, i) => i + 1);

  const onChangeCallback = (e) => {
    const {name, value} = e.target;
    const valueInt = parseInt(value, 10);
    const changedData = {[name]: valueInt};

    onChange(changedData, data.id);
  };

  return (
    <Col s={12} className="form-section bordered overviewkopbg">
      <Col s={12}>
        <div>
          <h1 className={'ratio-section-title'}>
            <span className="ratio-section-title">
              <i className="small material-icons left ratio-section-title">settings_phone</i>Bedrijf Connect:
            </span> {data.connectionName || "gewenste opties"}
          </h1>
        </div>
      </Col>

      <Row>
        <Col s={6} style={{padding: '0'}}>
          <Input
            label="Aantal spraakkanalen"
            s={12}
            name="speechChannelsCount"
            type='select'
            value={data.speechChannelsCount !== null ? parseInt(data.speechChannelsCount, 10) : 0}
            onChange={onChangeCallback}
            multiple={false}
            disabled={inputsDisabled}
          >
            <option value='0'>Selecteer een optie</option>
            {numbers1_60.map(i => (
              <option key={i} value={i}>{i}</option>
            ))}
          </Input>
        </Col>

        <Col s={6} style={{padding: '0'}}>
          <a
            className="btn doceri-btn doceri-btn-right ratio-btn-steps btn-no-margins"
            onClick={() => {
              onClickDelete(data.id);
            }}
            name="deleteSipTrunk"
          >
            Bedrijf Connect Kanaal verwijderen
            <i className="small material-icons right">close</i>
          </a>
        </Col>

        {
          !isNew && isLast && (
            <button
              className="btn doceri-btn-right ratio-btn-right"
              style={{marginBottom: "15px", marginRight: "15px"}}
              onClick={() => onClickAdd()}
              name="addSipTrunk"
              disabled={inputsDisabled}
            >
              Bedrijf Connect Kanaal toevoegen
              <i className="small material-icons right white-text">add</i>
            </button>
          )
        }
      </Row>
    </Col>
  );
};

SipTrunk.propTypes = {
  data: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  isNewValid: PropTypes.bool.isRequired,
  onClickAdd: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  isMigration: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
};

export default SipTrunk;
