import React, {Component} from 'react';
import {Input} from 'react-materialize'
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";

import quotationActions from "../../actions/quotationActions";

class AKBTelephoneNumber extends Component {
  constructor(props) {
    super(props);

    this.state = {
      telephoneNumber: this.props.telephoneNumber
    }
  }

  renderActionOptions = () => {
    const actions = ['porteren', 'behouden', 'opzeggen'];

    return actions.map((el, idx) => {
      return <option value={idx + 1} key={el}>{el}</option>
    })
  };

  onChange = (e) => {
    const {name, value} = e.target;

    this.setState({
      telephoneNumber: {
        ...this.state.telephoneNumber,
        [name]: value
      }
    });

    const response = this.props.quotationActions.saveAKBTelephoneNumber(this.props.telephoneNumber.id, this.state.telephoneNumber);

    response.then((val) => {
      if (val && val.error) {
        this.setState({
          telephoneNumber: this.props.telephoneNumber
        });
      }
    });
  };

  render() {
    const {telephoneNumber, inputsDisabled} = this.props;

    return (
      <div className="row">
        <Input
          style={{marginTop: 0, marginBottom: 0}}
          label="Telefoonnummer"
          type="text"
          disabled={!telephoneNumber.custom || inputsDisabled}
          value={telephoneNumber.number}
        />

        <Input
          s={6}
          l={2}
          name="action"
          type='select'
          label="Maak een keuze"
          onChange={this.onChange}
          value={this.state.telephoneNumber.action}
          multiple={false}
          disabled={inputsDisabled}
        >
          {this.renderActionOptions()}
        </Input>
      </div>
    );
  };
}

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
  return {
    quotationActions: bindActionCreators(quotationActions, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AKBTelephoneNumber);
