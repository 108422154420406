import styled from 'styled-components';

export default styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.7);
`;
