import React from 'react';
import Authorize from '../utils/authorize';
import {format} from "date-fns";

const Menu = props => {
    let {fullName, company, theme, createdAt, updatedAt, isQuotationClicked} = props;
    const quotationCreatedAt = createdAt ? format(new Date(createdAt), 'dd-MM-yyyy') : null;
    const quotationUpdatedAt = updatedAt ? format(new Date(updatedAt), 'dd-MM-yyyy') : null;
    const isYielderPartner = company.includes('Yielder Partners');

    return (
        <div id="Menu" className="col s2 m2 l3">
            <div className={'row'}>
              <div className="col s12 m12 l4">
                <div className="logo-large">
                  <img src={theme.logo} className="responsive-img app-header-logo" alt="" />
                </div>
              </div>

              <div className="col s8 menuhide">
                <div>
                  <h4 style={{color: isYielderPartner ? 'white' : ''}}>{fullName}</h4>
                  <h6 className={isYielderPartner ? 'company-title-yielder' : 'company-title'}>Bedrijf: {company}</h6>
                </div>

                <div>
                  {props.onSettingsClick && <a href="javascript:void(0)" onClick={props.onSettingsClick} ><i className="material-icons left lightgrey">format_list_bulleted</i></a>}
                  {props.onAdminClick && (
                    <Authorize allowedRoles={['ROLE_ADMIN', 'ROLE_MANAGE_USERS']}>
                      <a href="javascript:void(0)" onClick={props.onAdminClick}><i className="material-icons left lightgrey">settings</i></a>
                    </Authorize>
                  )}
                  {props.onLogoutClick && <a href="javascript:void(0)" onClick={props.onLogoutClick}><i className="material-icons left lightgrey">launch</i></a>}
                </div>

                {isQuotationClicked &&
                  <div>
                    <div style={{color: isYielderPartner ? 'white' : ''}}>Aangemaakt: {quotationCreatedAt}</div>
                    <div style={{color: isYielderPartner ? 'white' : ''}}>Laatst gewijzigd: {quotationUpdatedAt}</div>
                  </div>
                }
              </div>
            </div>
        </div>
    );
};

export default Menu;
