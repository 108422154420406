import React, {Component} from 'react';
import RegularSelect from "../../../components/RegularSelect";
import KpnDialog from '../../../containers/common/KpnDialog/KpnDialog';
import DialogHeader from "../../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";
import DialogFooter from "../../../containers/common/KpnDialog/DialogFooter";
import DialogButton from "../../../containers/common/KpnDialog/DialogButton";
import {Input, Col} from 'react-materialize';
import {connect} from "react-redux";
import DatePicker from "../../../components/datePicker";
import {formatDate} from "../../../utils/utils";

class AddHardwareOption extends Component {

    defaultHardwareTypes = ['Selecteer een hardware type', 'Access', 'Voice', 'Mobiel'];

    defaultLinkingTypes = ['Selecteer een dienst koppelen', 'Linked', 'Unlinked'];

    initialObj = {
        productId: null,
        type: null,
        name: null,
        label: null,
        customID_A: null,
        productNumber: null,
        purchasePriceOneOff: null,
        purchasePricePerMonth: null,
        sellingPricePerMonth: null,
        sellingPriceOneOff: null,
        setUpPrice: null,
        installationPrice: null,
        pmPrice: null,
        servicePrice: null,
        managedRouterPrice: null,
        mandatory: null,
        showWhen0: false,
        category: null,
        endOfSale: null,
        productName: null,
        linking: null,
        id: null,
        supplier: null,
        relatedProducts: null,
        relatedPartnerProducts: null,
        notAddOneTimeCostForVerlengenAction: false,
        notAddOneTimeCostForUpgradeAction: false,
        notAddMandatoryProductForVerlengenAction: false,
        notAddMandatoryProductForUpgradeAction: false
    };

    constructor(props) {
        super(props);

        this.state = this.initialObj;
    }

    onChange = ({target}) => {
        let {name, value} = target;

        if (['system_id', 'supplier'].includes(name)) {
            return;
        }

        this.setState({
            [name]: value
        });
    };

    toggleCheckbox = ({target: { name }}) => {
        this.setState({ [name]: !this.state[name] });
    }

    onChangeProduct = ({target}, partner) => {
        const options = [...target.selectedOptions].map((o) => o.value);
        let toSet = {
            relatedProducts: options
        };
        if (partner) {
            toSet = {
                relatedPartnerProducts: options
            }
        }

        this.setState(toSet);
    };

    onChangeEndOfSale = (date) => {
        this.setState({
            endOfSale: formatDate(date)
        });
    };

    renderHardwareTypesOptions = () => {
        return this.defaultHardwareTypes.map((el) => {
            return <option
                value={el === 'Selecteer een hardware type' ? null : el.toLowerCase()}
                key={el}
                name="linkType">
                {el !== '' && el !== null ? el : 'Selecteer een hardware type'}
            </option>
        });
    };

    renderLinkingTypesOptions = () => {
        return this.defaultLinkingTypes.map((el) => {
            return <option
                value={el === 'Selecteer een hardware koppelen' ? null : el}
                key={el}
                name="linkType">
                {el !== '' && el !== null ? this.translateFields(el) : 'Selecteer een hardware koppelen'}
            </option>
        });
    };

    translateFields = (field) => {
        if (field === 'Unlinked') {
            return 'Onverbonden';
        }

        if (field === 'Linked') {
            return 'Gekoppelde';
        }

        return field;
    };

    renderProductsToLink = (partner) => {
        let products = this.props.productsToLink;
        if (partner) {
            products = products.partnerProducts;
        } else {
            products = products.products;
        }

        if (products === undefined) {
            return (
              <option value={''} name={partner ? "relatedPartnerProducts" : "relatedProducts"}>
                  {'Er zijn geen beschikbare producten voor uw organisatie'}
              </option>
            );
        }

        return products.map((product) => {
            return (
              <option
                value={product.name === 'Koppel aan product' ? null : product.id}
                name={partner ? "relatedPartnerProducts" : "relatedProducts"}
              >
                  {this.formatProductName(product)}
              </option>
            )
        });
    };

    formatProductName = (product) => {
        if (!product.name) {
            return 'Koppel aan product';
        }

        return product.product_number ? `${product.name} ${product.product_number}` : product.name;
    };

    renderCategories = (categoryList) => {
        let type = this.state.type.toLowerCase();
        if (type === null || type === '') {
            return <option
                value={''}
                name="category">
                {'Kies het soort vóór'}
            </option>
        }

        if (categoryList === undefined) {
            return <option
                value={''}
                name="category">
                {'Er zijn geen beschikbare categorieën op dit moment'}
            </option>;
        }
        const categories = categoryList[type] || [];
        return categories.map((el) => {
            return <option
                value={el === 'Kies een categorie' ? null : el}
                name="category">
                {el !== '' && el !== null ? el : 'Kies een categorie'}
            </option>
        });
    };

    render() {
        let {
            dialogOpened,
            onRequestClose,
            tab,
            onClickFunc,
            categories,
            isQAQuotation
        } = this.props;

        return (
            <KpnDialog
                dialogState={dialogOpened}
                dialogHeader={(
                    <DialogHeader
                        headerIcon="add"
                        headerText="Voeg hardware toe"
                    />
                )}
                dialogBody={
                    <DialogBody>
                        <div className="manualServiceAddWrapper">
                            <div className="top-of-modal-container">
                                <div className="row">
                                    <Col s={6} l={12} className="input-field inner-addon">
                                        <label htmlFor={"type"}>Hardware optie type</label>
                                        <RegularSelect
                                            name="type"
                                            id={"hardwareType"}
                                            onChange={(event) => {
                                                this.onChange(event)
                                            }}
                                            value={this.state.type}
                                            multiple={false}
                                        >
                                            {this.renderHardwareTypesOptions()}
                                        </RegularSelect>
                                    </Col>
                                </div>
                                <div className="row">
                                    <Col s={6} l={12} className="input-field inner-addon">
                                        <label htmlFor={"linking"}>Hardware optie koppelen</label>
                                        <RegularSelect
                                            name="linking"
                                            id={"linkingType"}
                                            onChange={(event) => {
                                                this.onChange(event)
                                            }}
                                            value={this.state.linking}
                                            multiple={false}
                                        >
                                            {this.renderLinkingTypesOptions()}
                                        </RegularSelect>
                                    </Col>
                                </div>
                            </div>
                            <div className="middle-of-modal-container">
                                {this.state.linking !== null && this.state.linking !== 'Unlinked' &&
                                <div>
                                    <div className="row">
                                        <Input type="select"
                                               s={6}
                                               l={12}
                                               name="relatedProducts"
                                               id={"relatedProducts"}
                                               value={this.state.relatedProducts}
                                               placeholder={'Selecteer het te koppelen product'}
                                               onChange={(event) => this.onChangeProduct(event)}
                                               multiple={true}
                                               label='Te koppelen product'
                                        >
                                            {this.renderProductsToLink()}
                                        </Input>
                                    </div>
                                    <div className="row">
                                        <Input type="select"
                                               s={6}
                                               l={12}
                                               name="relatedPartnerProducts"
                                               id={"relatedPartnerProducts"}
                                               value={this.state.relatedPartnerProducts}
                                               placeholder={'Selecteer het te koppelen product'}
                                               onChange={(event) => this.onChangeProduct(event, true)}
                                               multiple={true}
                                               label='Te koppelen partner product'
                                        >
                                            {this.renderProductsToLink(true)}
                                        </Input>
                                    </div>
                                </div>
                                }
                            </div>
                            {this.state.linking !== null &&
                            <div className="bottom-of-modal-container">
                                <div className="row">
                                    <Input
                                        s={12}
                                        value={this.state.name}
                                        onChange={(event) => {
                                            this.onChange(event)
                                        }}
                                        label={'Hardware optie naam'}
                                        name='name'
                                        type='text'/>
                                </div>
                                <div className="row">
                                    <Input
                                        s={6}
                                        value={this.state.productNumber}
                                        onChange={(event) => {
                                            this.onChange(event)
                                        }}
                                        label='Productcode'
                                        name='productNumber'
                                        type='text'/>
                                    <Input
                                        s={6}
                                        value={this.state.customID_A}
                                        onChange={(event) => {
                                            this.onChange(event)
                                        }}
                                        label='CustomID_A'
                                        name='customID_A'
                                        type='text'/>
                                </div>
                                <div className="row">
                                    <Col s={6} l={6} className="input-field inner-addon">
                                        <label htmlFor={"category"}>Categorie</label>
                                        <RegularSelect
                                            name="category"
                                            id={"category"}
                                            onChange={(event) => {
                                                this.onChange(event)
                                            }}
                                            value={this.state.category}
                                            multiple={false}
                                            validate={true}
                                            required={true}
                                        >
                                            <option value={''} name="category">
                                                {'Selecteer een categorie'}
                                            </option>
                                            {this.renderCategories(categories)}
                                        </RegularSelect>
                                    </Col>
                                    <Input
                                        s={6}
                                        l={6}
                                        value={this.state.label}
                                        onChange={(event) => {
                                            this.onChange(event)
                                        }}
                                        label='Label'
                                        name='label'
                                        type='text'/>
                                </div>
                                <div className="row">
                                    <Col s={6} className="input-field inner-addon">
                                        <label htmlFor={"mandatory"}>Is dit product verplicht?</label>
                                        <RegularSelect
                                            name="mandatory"
                                            id={"mandatory"}
                                            onChange={(event) => {
                                                this.onChange(event)
                                            }}
                                            value={this.state.mandatory}
                                            multiple={false}
                                            validate={true}
                                            required={true}
                                        >
                                            <option
                                                value={null}
                                                name="obligation">
                                                {"Selecteer verplichting"}
                                            </option>
                                            <option
                                                value={1}
                                                name="obligation">
                                                {"Ja"}
                                            </option>
                                            <option
                                                value={0}
                                                name="obligation">
                                                {"Nee"}
                                            </option>
                                        </RegularSelect>
                                    </Col>
                                    <Col s={6} className="input-field porting-date-field">
                                        <label htmlFor={"endOfSale"}>End verkoopdatum</label>
                                        <DatePicker
                                            name="endOfSale"
                                            id={"endOfSale"}
                                            selected={Date.parse(this.state.endOfSale)}
                                            onChange={this.onChangeEndOfSale}
                                            onChangeRaw={e => e.preventDefault()}
                                            dateFormat="yyyy-MM-dd"
                                            autoComplete="off"
                                            popperPlacement="bottom"
                                            popperModifiers={{
                                                preventOverflow: {
                                                    enabled: false
                                                },
                                                hide: {
                                                    enabled: false
                                                }
                                            }}
                                        />
                                    </Col>
                                </div>
                                <div className="row" style={{marginTop: '25px'}}>
                                    <Input
                                      s={6}
                                      l={6}
                                      value={this.state.id}
                                      onChange={(event) => {
                                          this.onChange(event)
                                      }}
                                      label='System ID'
                                      name='system_id'
                                      type='text'
                                      readOnly={true}
                                    />
                                    <Input
                                      s={6}
                                      l={6}
                                      value={this.state.supplier}
                                      onChange={(event) => {
                                          this.onChange(event)
                                      }}
                                      label='Leverancier'
                                      name='supplier'
                                      type='text'
                                      readOnly={true}
                                    />
                                </div>
                                <div className="bottom-modal-euro-title-container">
                                    <h1 className="ratio-section-title">
                                        <span className="ratio-section-title">
                                            <i className="small material-icons left ratio-section-title">euro</i>
                                                Prijzen en kosten
                                        </span>
                                    </h1>
                                </div>
                                <div className="row">
                                    <Input
                                        s={6}
                                        value={this.state.purchasePriceOneOff}
                                        onChange={(event) => {
                                            this.onChange(event)
                                        }}
                                        label='Inkoopprijs eenmalig'
                                        name='purchasePriceOneOff'
                                        type='number'/>
                                    <Input value={this.state.purchasePricePerMonth}
                                           s={6}
                                           onChange={(event) => {
                                               this.onChange(event)
                                           }}
                                           label='Inkoopprijs/mnd'
                                           name='purchasePricePerMonth'
                                           type='number'/>
                                </div>
                                <div className="row">
                                    <Input value={this.state.sellingPriceOneOff}
                                           s={6}
                                           onChange={(event) => {
                                               this.onChange(event)
                                           }}
                                           label='Verkoopprijs eenmalig'
                                           name='sellingPriceOneOff'
                                           type='number'/>

                                    <Input value={this.state.sellingPricePerMonth}
                                           s={6}
                                           onChange={(event) => {
                                               this.onChange(event)
                                           }}
                                           label='Verkoopprijs/mnd'
                                           name='sellingPricePerMonth'
                                           type='number'/>
                                </div>
                                <div className="row">
                                    <Input value={this.state.setUpPrice}
                                           s={6}
                                           onChange={(event) => {
                                               this.onChange(event)
                                           }}
                                           label='Programmeren'
                                           name='setUpPrice'
                                           type='number'/>

                                    <Input value={this.state.installationPrice}
                                           s={6}
                                           onChange={(event) => {
                                               this.onChange(event)
                                           }}
                                           label='Installeren'
                                           name='installationPrice'
                                           type='number'/>
                                </div>
                                <div className="row">
                                    <Input value={this.state.pmPrice}
                                           s={6}
                                           onChange={(event) => {
                                               this.onChange(event)
                                           }}
                                           label='Project Management'
                                           name='pmPrice'
                                           type='number'/>
                                </div>
                                <div className="row upper-checkboxes-container">
                                    <div className="checkboxes-group">
                                        <Input checked={this.state.notAddOneTimeCostForVerlengenAction}
                                               onChange={this.toggleCheckbox}
                                               name='notAddOneTimeCostForVerlengenAction'
                                               type='checkbox'
                                               label={'Eenmalige kosten niet meenemen bij Verlenging'}
                                        />
                                        <Input checked={this.state.notAddOneTimeCostForUpgradeAction}
                                               onChange={this.toggleCheckbox}
                                               name='notAddOneTimeCostForUpgradeAction'
                                               type='checkbox'
                                               label={'Eenmalige kosten niet meenemen bij Down/Upgrade'}
                                        />
                                    </div>
                                    <div className="checkboxes-group">
                                        <Input checked={this.state.notAddMandatoryProductForVerlengenAction}
                                               onChange={this.toggleCheckbox}
                                               name='notAddMandatoryProductForVerlengenAction'
                                               type='checkbox'
                                               label={'Product niet automatisch toevoegen bij Verlenging'}
                                        />
                                        <Input checked={this.state.notAddMandatoryProductForUpgradeAction}
                                               onChange={this.toggleCheckbox}
                                               name='notAddMandatoryProductForUpgradeAction'
                                               type='checkbox'
                                               label={'Product niet automatisch toevoegen bij Down/Upgrade'}
                                        />
                                    </div>
                                </div>
                                {
                                    isQAQuotation &&
                                    <div className="row bottom-checkboxes-container">
                                        <Input
                                            name='showWhen0'
                                            type='checkbox'
                                            checked={this.state.showWhen0}
                                            label={'Zou moeten verschijnen wanneer 0'}
                                            onChange={this.toggleCheckbox}
                                        />
                                        <Input
                                            name='showWhen0'
                                            type='checkbox'
                                            checked={!this.state.showWhen0}
                                            label={'Zou niet moeten verschijnen als 0'}
                                            onChange={this.toggleCheckbox}
                                        />
                                    </div>
                                }
                            </div>}
                        </div>
                    </DialogBody>
                }
                dialogFooter={
                    <DialogFooter
                        buttonLeft={(
                            <DialogButton
                                buttonAction={onRequestClose}
                                buttonText='Annuleren'
                                left={true}
                            />
                        )}
                        buttonRight={(
                            <DialogButton
                                buttonAction={() => onClickFunc(this.state)}
                                buttonText='Hardware toevoegen'
                            />
                        )}
                    />
                }
            />
        );
    }
}

const mapStateToProps = ({quotation}) => {
    return {
        quotation: quotation
    }
};

export default connect(mapStateToProps)(AddHardwareOption);
