import actionTypes from "../../constants/actionTypes";

export const updateQuotationOptions = (locationId) => ({
    type: actionTypes.updateQuotationOptions.init,
    payload: {
        locationId,
    },
});
export const updateQuotationOptionsSuccess = (data) => ({
    type: actionTypes.updateQuotationOptions.success,
    payload: {
        data,
    },
});

export const updateQuotationOptionsError = (error) => ({
    type: actionTypes.updateQuotationOptions.error,
    payload: {
        error,
    },
    error: true,
});
