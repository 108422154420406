import React, {Component} from 'react';
import {format} from "date-fns";
import ReactDOM from "react-dom";
import {isElInput, sleep} from "../../utils/utils";
import {Col, Input} from "react-materialize";

import KpnDialog from "../../containers/common/KpnDialog/KpnDialog";
import DialogHeader from "../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../containers/common/KpnDialog/DialogBody";
import DefaultDialogBody from "../../containers/common/KpnDialog/DefaultDialogBody";
import DialogFooter from "../../containers/common/KpnDialog/DialogFooter";
import DialogButton from "../../containers/common/KpnDialog/DialogButton";

import DatePicker from "../../components/datePicker";
import qType from "../../constants/quotationType";

class OrderingUserNamesDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userNames: null,
      failedValidation: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userNames !== prevProps.userNames || !this.state.userNames) {
      this.setState({
        userNames: this.props.userNames
      });
    }
  }

  componentDidMount() {
    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);
    });
  }

  handleChange = (e, index) => {
    const {name, accessKey, value} = e.target;

    if (accessKey === 'username' && value !== '' && value.match(/[a-zA-Z]+!/)) {
      this.setState({failedValidation: true});
      return;
    }

    let userNames = {...this.state.userNames};
    let currentProductArray = userNames.hasOwnProperty(name) ? userNames[name] : [];
    currentProductArray[index] = value;
    userNames[name] = currentProductArray;

    this.setState({
      userNames
    });

    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);
    });
  };

  getFilteredProducts = () => {
    let {complexProducts, isComplex, module} = this.props;
    let products;

    if (complexProducts && isComplex) {
      products = complexProducts;
    } else {
      products = module.length ? module : []
    }

    if (products.length === 0) {
      return [];
    }

    return products.filter((el) => el.product_number === this.props.selectedProduct);
  };

  handleGeneralDateOnChange = (e, index, product) => {
    const {checked, name} = e.target;
    let usernameProducts = {...this.state.userNames};
    let contractDateField = `${product.product_number}_contractEndDate`;
    let contractNoticeField = `${product.product_number}_contractNoticePeriod`;
    let value = usernameProducts.hasOwnProperty(contractDateField) ? usernameProducts[contractDateField][index] : null;
    let contractNoticeValue = usernameProducts.hasOwnProperty(contractNoticeField) ? usernameProducts[contractNoticeField][index] : '1';
    let filteredProducts = this.getFilteredProducts();
    let currentProductsGeneralDateArray = usernameProducts.hasOwnProperty(name) ? usernameProducts[name] : [];
    let currentProductsContractEndDateArray = usernameProducts.hasOwnProperty(contractDateField) ? usernameProducts[contractDateField] : [];
    let currentProductsContractNoticeArray = usernameProducts.hasOwnProperty(contractNoticeField) ? usernameProducts[contractNoticeField] : [];
    let requiredFields = [name, contractDateField, contractNoticeField];

    requiredFields.forEach((el) => {
      if (!usernameProducts.hasOwnProperty(el)) {
        usernameProducts[el] = [];
      }
    });

    filteredProducts.forEach((el, elIndex) => {
      if (el.action === product.action) {
        if (checked) {
          currentProductsContractEndDateArray[elIndex] = value;
          currentProductsContractNoticeArray[elIndex] = contractNoticeValue;
        }

        currentProductsGeneralDateArray[elIndex] = checked;
      } else {
        currentProductsGeneralDateArray[elIndex] = usernameProducts[name][elIndex] || null;
        currentProductsContractEndDateArray[elIndex] = usernameProducts[contractDateField][elIndex] || null;
        currentProductsContractNoticeArray[elIndex] = usernameProducts[contractNoticeField][elIndex] || null;
      }
    });

    usernameProducts[contractDateField] = currentProductsContractEndDateArray;
    usernameProducts[name] = currentProductsGeneralDateArray;
    usernameProducts[contractNoticeField] = currentProductsContractNoticeArray;

    this.setState({userNames: usernameProducts});
  };

  handleContractEndDateChange = (date, name, index, product) => {
    const generalDateField = `${product.product_number}_generalDate`;
    let userNames = {...this.state.userNames};
    let currentProductArray = userNames.hasOwnProperty(name) ? userNames[name] : [];
    let currentProductsGeneralDateArray = userNames.hasOwnProperty(generalDateField) ? userNames[generalDateField] : [];
    let value = format(new Date(date), 'yyyy-MM-dd');

    currentProductArray[index] = value;

    let contractNoticePeriod = name.replace('_contractEndDate', '_contractNoticePeriod');
    if (!userNames.hasOwnProperty(contractNoticePeriod)) {
      userNames[contractNoticePeriod] = [];
    }

    if (!userNames[contractNoticePeriod][index]) {
      userNames[contractNoticePeriod][index] = '1'
    }

    if (currentProductsGeneralDateArray.length > 0 && currentProductsGeneralDateArray[index]) {
      let filteredProducts = this.getFilteredProducts(product.action);
      filteredProducts.forEach((el, elIndex) => {
        if (el.action === product.action) {
          currentProductArray[elIndex] = value;

          if (!userNames[contractNoticePeriod][elIndex]) {
            userNames[contractNoticePeriod][elIndex] = '1'
          }
        }
      });
    }

    userNames[name] = currentProductArray;

    this.setState({userNames});

    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);
    });
  };

  closeValidationDialog = () => {
    this.setState({failedValidation: false});
  };

  onSubmit = (e) => {
    e.preventDefault();

    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);

      let invalidElements = ReactDOM.findDOMNode(this.globalForm).getElementsByClassName('invalid');

      if (!invalidElements.length) {
        this.props.onSubmit({...this.state.userNames});
      }
    });
  };

  checkInputsForValidity = (el) => {
    if (el && el.children) {
      Array.from(el.children).forEach((child) => {
        this.checkInputsForValidity(child);
      });
    }

    if (isElInput(el) === false) {
      return;
    }

    const parentElement = el.parentElement;

    if (el.checkValidity() === false) {
      el.classList.remove('valid');
      el.classList.add('invalid');

      if (parentElement.classList.contains('select-wrapper')) {
        parentElement.classList.remove('valid');
        parentElement.classList.add('invalid');
      }
    }

    if (el.checkValidity() === true) {
      el.classList.remove('invalid');
      el.classList.add('valid');

      if (parentElement.classList.contains('select-wrapper')) {
        parentElement.classList.remove('invalid');
        parentElement.classList.add('valid');
      }
    }
  };

  getValue = (name, index) => {
    const {userNames} = this.state;

    if (!userNames) {
      return '';
    }

    if (!userNames.hasOwnProperty(name)) {
      return '';
    }

    if (index in userNames[name]) {
      return userNames[name][index];
    }

    return '';
  };

  isVoipUser = (productNumber) => {
    const {voipUserProducts} = this.props;

    return voipUserProducts.length ? voipUserProducts.includes(productNumber) : false;
  };

  render() {
    const {dialogOpened, onCancel, module, userNameSelected, selectedProduct, quotationType, complexProducts, isComplex, inputsDisabled} = this.props;
    let products = module.length ? module : [];
    let counter = [];
    const isVerlengenQuotation = quotationType === qType.TYPE_VERLENGEN;

    if (selectedProduct) {
      products = products.filter((el) => el.product_number === selectedProduct);
    }
    if (complexProducts && isComplex) {
      products = complexProducts;
    }

    return (
      <div className="telephone-number-dialog">
        {<KpnDialog
          dialogState={dialogOpened}
          modal={false}
          overflowException={isVerlengenQuotation}
          dialogHeader={(
            <DialogHeader
              headerIcon="edit_location"
              headerText={`Bewerk gegevens`}
              closeHandler={onCancel}
            />
          )}
          dialogBody={
            <DialogBody>
              {
                this.state.failedValidation && (
                  <KpnDialog
                    dialogState={true}
                    dialogHeader={(
                      <DialogHeader
                        headerText="Validatie mislukt"
                        closeHandler={this.closeValidationDialog}
                      />
                    )}
                    dialogBody={
                      <DialogBody>
                        <DefaultDialogBody
                          text="Gebruikersnaam is ongeldig. Kies minimaal 3 en maximaal 60 karakters met kleine letters, koppelstreepjes, underscores, punten en/of cijfers"/>
                      </DialogBody>
                    }
                    dialogFooter={
                      <DialogFooter
                        buttonRight={
                          <DialogButton
                            buttonAction={this.closeValidationDialog}
                            buttonText="Ik begrijp het"
                          />}
                      />
                    }
                    onRequestClose={this.closeValidationDialog}
                  />
                )
              }
              <form ref={(ref) => (this.globalForm = ref)}>
                {products && products.filter((el) => el.product_number === selectedProduct).map((product, index) => {

                    if (!counter.hasOwnProperty(product.id)) {
                      counter[product.id] = 0;
                    }
                    counter[product.id]++;

                    const formIndex = userNameSelected;
                    const fieldName = product.product_number;
                    const productAction = product.action;
                    const isTerminateAction = productAction === 8;
                    const isVerlengenAction = productAction === 7;
                    const isUpgradeAction = productAction === 6;
                    const verlengenCondition = isVerlengenQuotation && [6, 7, 8].includes(productAction);

                    let labelExtra = fieldName === '101A00004N' ? 'PakketID' : 'Gebruikersnamen';

                    if (selectedProduct && !(isVerlengenQuotation && verlengenCondition)) {
                      return (
                        <div className='form-group'>
                          {
                            index === userNameSelected &&
                            <div className="form-group">
                              <Input
                                key={index}
                                accessKey={'username'}
                                s={this.isVoipUser(product.product_number) ? 5 : 6}
                                label={`${labelExtra} ${product.name} #${userNameSelected + 1}`}
                                name={fieldName}
                                onChange={(e) => this.handleChange(e, formIndex)}
                                value={this.getValue(fieldName, formIndex)}
                                disabled={inputsDisabled}
                                required
                              />

                              <Input
                                s={6}
                                value={this.getValue(fieldName + '_label', formIndex) || ''}
                                onChange={(e) => this.handleChange(e, formIndex)}
                                label={`${product.name} #${userNameSelected + 1} Label`}
                                name={`${fieldName}_label`}
                                type="text"
                                disabled={inputsDisabled}
                              />
                            </div>
                          }
                        </div>
                      );
                    }

                    return (
                      <div className='form-group'>
                        {
                          index === userNameSelected && !(isVerlengenQuotation && verlengenCondition) &&
                          <Input
                            key={index}
                            accessKey={'username'}
                            s={this.isVoipUser(product.product_number) ? 5 : 6}
                            label={`${labelExtra} ${product.name} #${userNameSelected + 1}`}
                            name={fieldName}
                            onChange={(e) => this.handleChange(e, formIndex)}
                            value={this.getValue(fieldName, formIndex)}
                            disabled={inputsDisabled}
                            required
                          />
                        }

                        {this.isVoipUser(product.product_number) && index === userNameSelected && !(isVerlengenQuotation && verlengenCondition) &&
                        <div className='form-group'>
                          <Input
                            key={`${index}_vamoid`}
                            s={3}
                            label={`Vamoid #${userNameSelected + 1}`}
                            name={`${fieldName}_vamoid`}
                            onChange={(e) => this.handleChange(e, formIndex)}
                            disabled={inputsDisabled}
                            value={this.getValue(fieldName + '_vamoid', formIndex) || ''}
                          />

                          <Input
                            key={`${index}_email`}
                            s={4}
                            label={`Email #${userNameSelected + 1}`}
                            name={`${fieldName}_email`}
                            type='email'
                            pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,9}$"
                            onChange={(e) => this.handleChange(e, formIndex)}
                            disabled={inputsDisabled}
                            value={this.getValue(fieldName + '_email', formIndex) || ''}
                          />
                        </div>
                        }

                        {index === userNameSelected && !(isVerlengenQuotation && verlengenCondition) &&
                        <div className="form-group">
                          <Input
                            s={12}
                            value={this.getValue(fieldName + '_label', formIndex) || ''}
                            onChange={(e) => this.handleChange(e, formIndex)}
                            label={`${product.name} #${userNameSelected + 1} Label`}
                            name={`${fieldName}_label`}
                            type="text"
                            disabled={inputsDisabled}
                          />
                        </div>
                        }

                        {index === userNameSelected && (isTerminateAction || isVerlengenAction) &&
                        <div className="form-group">
                          <Col s={4} className="input-field">
                            <label
                              htmlFor={"contractEndDate"}>{isVerlengenAction ? 'Einddatum verlengde contract' : 'Per wanneer opheffen?'}</label>
                            <DatePicker
                              name={`${fieldName}_contractEndDate`}
                              id={"contractEndDate"}
                              selected={this.getValue(fieldName + '_contractEndDate', formIndex) ? new Date(this.getValue(fieldName + '_contractEndDate', formIndex)) : ''}
                              onChange={(e) => this.handleContractEndDateChange(e, fieldName + '_contractEndDate', formIndex, product)}
                              onChangeRaw={e => e.preventDefault()}
                              minDate={new Date()}
                              dateFormat="dd-MM-yyyy"
                              autoComplete="off"
                              required={true}
                              disabled={inputsDisabled}
                            />
                          </Col>
                          {
                            isVerlengenAction &&
                            <Input
                              s={4}
                              value={this.getValue(fieldName + '_contractNoticePeriod', formIndex) || '1'}
                              onChange={(e) => this.handleChange(e, formIndex)}
                              label="Opzegtermijn nieuw contract (maanden)"
                              name={`${fieldName}_contractNoticePeriod`}
                              type="number"
                              min={1}
                              max={3}
                              required={true}
                              validate={true}
                              disabled={inputsDisabled}
                            />
                          }
                          <Col s={12}>
                            {
                              (isVerlengenQuotation && verlengenCondition) && productAction !== isTerminateAction &&
                              <div className="row"
                                   style={{marginTop: isVerlengenAction ? '-20px' : '20px', marginBottom: '35px'}}>
                                <div className="col s12">
                                  <input
                                    id="sameDate"
                                    accessKey="sameDate"
                                    name={`${fieldName}_generalDate`}
                                    className="input-field"
                                    checked={this.getValue(fieldName + '_generalDate', formIndex) || false}
                                    type="checkbox"
                                    onChange={(e) => this.handleGeneralDateOnChange(e, formIndex, product)}
                                    disabled={inputsDisabled}
                                  />
                                  <label htmlFor="sameDate">
                                    {productAction === isTerminateAction ? 'Optie ' : 'Datum '}
                                    overal toepassen voor
                                    {productAction === isTerminateAction && ' opheffen'}
                                    {productAction === isVerlengenAction && ' verlengen'}
                                    {productAction === isUpgradeAction && ' upgrades'}
                                  </label>
                                </div>
                              </div>
                            }
                          </Col>
                        </div>
                        }
                      </div>
                    )
                  }
                )}
              </form>
            </DialogBody>
          }
          dialogFooter={
            <DialogFooter
              buttonLeft={(
                <DialogButton
                  buttonAction={onCancel}
                  buttonText='Annuleren'
                  left={true}
                />
              )}
              buttonRight={(
                !this.props.loading && <DialogButton
                  buttonAction={this.onSubmit}
                  buttonText='Opslaan'
                />
              )}
            />
          }
        />}
      </div>
    )
  }
}

export default OrderingUserNamesDialog;