import {
  actionTypes
} from '../constants/actionTypes';

const initialState = {
  digitalSigningDetails: {
    header: '',
    isHeaderBold: false,
    content: '',
    isContentBold: false,
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getDigitalSigningDetails:
      return {
        ...state,
        digitalSigningDetails: action.payload
      };
    case actionTypes.patchDigitalSigningDetails:
      return {
        ...state,
        digitalSigningDetails: {
          ...state.digitalSigningDetails,
          [action.payload.property]: action.payload.value
        }
      };
    default:
      return state;
  }
}
