import RequestType from "../../constants/requestType";
import ApiService from "../../services/apiService";

import { apiRoutes } from '../../constants/apiRoutes';
import { actionTypes } from '../../constants/actionTypes';
import { toastr } from 'react-redux-toastr';

import store from '../../store';
import _ from 'lodash'

const getDiscountState = (quotationId, editable = false, isLead = false) => {
    return async (dispatch) => {
        try {
            let url;
            if (isLead) {
                url = editable ? apiRoutes.discountRelated.getLeadDiscounts.replace(/{leadId}/, quotationId)
                  : apiRoutes.discountRelated.getLeadOverview + quotationId;
            } else {
                url = editable ? apiRoutes.discountRelated.getDiscounts.replace(/{quotationId}/, quotationId)
                  : apiRoutes.discountRelated.getOverview + quotationId;
            }
            let response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(getDiscountStateSuccess(response));
        }
        catch ({ message }) {
            toastr.error('Het is niet mogelijk op de huidige stap op te halen', '');
        }
    }
};

const getDiscountStateSuccess = (discountState) => {
    return {
        type: actionTypes.getDiscountState,
        payload: discountState
    };
};

const getDiscountErrors = (discountErrors) => {
    return {
        type: actionTypes.getDiscountErrors,
        payload: discountErrors
    };
};

const resetDiscountErrors = () => {
    return {
        type: actionTypes.resetDiscountErrors
    }
};

const updateDiscount = (form, offer, quotationId, isLead) => {
    return async (dispatch) => {
        try {
            let url = isLead ? apiRoutes.discountRelated.updateLeadDiscount : apiRoutes.discountRelated.update;
                url = isLead ? url.replace(/{leadId}/, quotationId) : url.replace(/{quotationId}/, quotationId);
                url = url.replace(/{offerId}/, (offer.id === undefined ? 0 : offer.id));

            await ApiService.performRequest(RequestType.PATCH, url, form);
            dispatch(resetDiscountErrors());
        }
        catch ({response}) {
            if(response.status === 400) {
              dispatch(getDiscountErrors([response.data]));
            }
        }
    }
};

const updateDiscountState = (name, newValue, productsKey, product, quotationId) => {
    return async (dispatch) => {
        try {
            let { discountTables } = store.getState().discount;

            const isSwapstock = product.is_swapstock;
            const productId = product.id;
            const priceOnetime = product.price_gross_onetime;
            const priceMonthly = product.price_gross_monthly;
            const isPercentage = null !== name.match(/percentage/);
            const isMonthly = null !== name.match(/monthly/);

            if (false === isMonthly && (isNaN(priceOnetime) || priceOnetime === 0)) {
                return;
            }

            if (isMonthly && (isNaN(priceMonthly) || priceMonthly === 0)) {
                return;
            }

            let paymentKey = '';

            if (isPercentage) {
                paymentKey = isMonthly ? 'Monthly costs' : 'One time cost';
            } else {
                paymentKey = isMonthly ? 'Monthly costs' : 'One time cost';
            }

            const newDiscount = isPercentage
                ?calculateDiscountFromPercentage(newValue, discountTables, paymentKey, productId)
                :newValue;


            if (isPercentage) {
                dispatch(updateDiscountStateSuccess(name, newValue, productsKey, productId, isSwapstock));
            } else {
                dispatch(updateDiscountStateSuccess(name, newDiscount, productsKey, productId, isSwapstock));
            }

            if (isNaN(newValue) || '' === newValue) {
                return;
            }

            const newTotals = discountTables.totals.find(x => x.name === paymentKey).products.map((product) => {
                if(product.id === productId){
                    product.discount = newDiscount * product.amount;
                }
                return product;
            });

            newTotals[newTotals.length - 1].price = newTotals.reduce((acc, x) => {
                if(x.name !== 'Totaal'){
                    acc+= x.price - x.discount
                }
                return acc;
            }, 0);

            newTotals[newTotals.length - 1].discount = newTotals.reduce((acc, x) => {
                if(x.name !== 'Totaal'){
                    acc+=  x.discount
                }
                return acc;
            }, 0);


            dispatch(updateTotalsState(paymentKey, [discountTables.totals.find(x => x.name !== paymentKey), {name: paymentKey, products: newTotals}]));

            let url = apiRoutes.discountRelated.update
                .replace('quotationId', quotationId)
                .replace('productId', productId);

            url += "?isSwapstock=" + (isSwapstock ? "1" : "0");

            const discountPercentageMonthly = isNaN(product.discount_percentage_monthly) ? 0 : product.discount_percentage_monthly;
            const discountPercentageOnetime = isNaN(product.discount_percentage_onetime) ? 0 : product.discount_percentage_onetime;

            const requestData = {
                discountMonthly: isPercentage ? priceMonthly - (priceMonthly * (1-newValue/100)) : product.discount_gross_monthly,
                discountOnetime: isPercentage ? priceOnetime - (priceOnetime * (1-newValue/100)) : product.discount_gross_onetime,
                discountPercentageMonthly: isPercentage
                    ? discountPercentageMonthly
                    : priceMonthly === 0
                        ? 0
                        : ((1 - (priceMonthly - newValue)/priceMonthly)*100),
                discountPercentageOnetime: isPercentage
                    ? discountPercentageOnetime
                    : priceOnetime === 0
                        ? 0
                        : ((1 - (priceOnetime - newValue)/priceOnetime)*100),
            };

            await ApiService.performRequest(RequestType.PATCH, url, requestData);

            dispatch(getDiscountErrors([]));

        }
        catch ({response}) {
            dispatch(getDiscountErrors([response.data]));
        }
    };
};

const calculateDiscountFromPercentage= (percentageString, discountTables, paymentKey, productId) =>
{
    const percentageDiscount = parseFloat(percentageString)/100;
    const totals = discountTables.totals.find(x => x.name === paymentKey);
    const product = totals.products.find(x => x.id === productId);

    return (product.piecePrice * percentageDiscount).toFixed(2);

};

const updateTotalsState = (key, totals) => {
    return {
        type: actionTypes.updateTotalsState,
        payload: {
            key,
            totals
        }
    }
};

const updateDiscountStateSuccess = (name, newValue, productsKey, productId, isSwapstock) => {
    return {
        type: actionTypes.updateDiscountStateSuccess,
        payload: {
            name,
            newValue,
            productsKey,
            productId,
            isSwapstock
        }
    };
};

const updateKwtCodeStateSuccess = (newValue) => {
    return {
        type: actionTypes.updateDiscountStateSuccess,
        payload: {
            newValue,
        }
    };
};

const updateExtraKwtCodeStateSuccess = (newValue) => {
    return {
        type: actionTypes.updateExtraKwtStateSuccess,
        payload: {
            newValue,
        }
    };
};

const updateKwtCode = (quotationId, kwtCodeValue) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.discountRelated.updateKwtCode
                .replace(/{quotationId}/, quotationId);
            let body = {
                kwtCode : kwtCodeValue
            };
            await ApiService.performRequest(RequestType.PATCH, url, body);
            dispatch(updateKwtCodeStateSuccess(kwtCodeValue));
        }
        catch ({response}) {
        }
    }
};

const updateExtraKwtCode = (quotationId, extraKwtCodeValues) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.discountRelated.updateKwtCode
                .replace(/{quotationId}/, quotationId);

            let body = {extraKwtCodes: extraKwtCodeValues};
            await ApiService.performRequest(RequestType.PATCH, url, body);
            dispatch(updateExtraKwtCodeStateSuccess(extraKwtCodeValues));
        }
        catch ({response}) {
        }
    }
};

const updateCombinationDiscount = (quotationId, newValue) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.discountRelated.updateCombinationDiscount
        .replace(/{quotationId}/, quotationId);

      await ApiService.performRequest(RequestType.PATCH, url, {combinationDiscount : newValue});
      dispatch(updateKwtCodeStateSuccess(newValue));
    }
    catch ({response}) {
    }
  }
};

const updateInCampusDiscount = (quotationId, newValue) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.discountRelated.updateInCampus
              .replace(/{quotationId}/, quotationId);

            await ApiService.performRequest(RequestType.PATCH, url, {inCampus : newValue});
            dispatch(updateInCampusSuccess(newValue));
        }
        catch ({response}) {
        }
    }
};

const updateInCampusSuccess = (newValue) => {
    return {
        type: actionTypes.updateInCampusSuccess,
        payload: newValue,
    };
};

const syncSalesForceOpportunity = (quotation) => {
    return async () => {
        try {
            let url = apiRoutes.discountRelated.syncSalesForceOpportunity.replace(/{quotation}/, quotation);
            return await ApiService.performRequest(RequestType.POST, url);
        } catch (error) {
            const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;

            toastr.error('Synchroniseren met SalesForce mislukt', message);
            throw message;
        }
    }
};

const getSalesValueOpportunityValues = (quotation) => {
    return async () => {
        try {
            let url = apiRoutes.discountRelated.getSalesValueOpportunityValues.replace(/{quotation}/, quotation);

            return await ApiService.performRequest(RequestType.GET, url);
        } catch ({ message }) {
            toastr.error('Something went wrong', message);
        }
    }
};

const sendSalesValueDocument = (quotation) => {
    return async () => {
        try {
            const url = apiRoutes.discountRelated.sendSalesValueDocument.replace(/{quotation}/, quotation);
            return await ApiService.performRequest(RequestType.POST, url);
        } catch (error) {
            const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;

            toastr.error('Synchroniseren met SalesForce mislukt', message);
            throw message;
        }
    }
};

const changeSalesValueOpportunityStatus = (quotation) => {
    return async () => {
        try {
            const url = apiRoutes.discountRelated.changeSalesValueOpportunityStatus.replace(/{quotation}/, quotation);
            return await ApiService.performRequest(RequestType.POST, url);
        } catch (error) {
            const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;

            toastr.error('Synchroniseren met SalesForce mislukt', message);
            throw message;
        }
    }
};

const saveNewPrice = (quotation, data) => {
    return async () => {
        try {
            let url = apiRoutes.discountRelated.savePrice.replace(/{quotation}/, quotation);
            return await ApiService.performRequest(RequestType.PATCH, url, data);
        } catch (error) {
            const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;

            toastr.error('Something went wrong ', message);
        }
    }
};

const getConnectWiseMissingProducts = (quotationId) => {
    return async () => {
        try {
            let url = apiRoutes.discountRelated.connectWiseMissingProducts.replace(/{quotation}/, quotationId);
            return await ApiService.performRequest(RequestType.GET, url);
        } catch (error) {
            const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;

            toastr.error('Something went wrong ', message);
        }
    }
}
const updateAuthorizedSignatory = (quotationId, data) => {
    return async () => {
        try {
            let url = apiRoutes.discountRelated.updateAuthorizedSignatory.replace(/{quotation}/, quotationId);
            return await ApiService.performRequest(RequestType.POST, url, data);
        } catch (error) {
            const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;

            toastr.error('Something went wrong ', message);
        }
    }
}

const updateRequiredKwtOption = (quotationId, data) => {
    return async () => {
        try {
            let url = apiRoutes.discountRelated.updateRequiredKwtCode.replace(/{quotationId}/, quotationId);
            return await ApiService.performRequest(RequestType.PATCH, url, data);
        } catch (error) {
            const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;

            toastr.error('Something went wrong ', message);
        }
    }
}

export default {
    getDiscountState,
    updateDiscountState,
    updateDiscount,
    updateKwtCode,
    updateCombinationDiscount,
    updateInCampusDiscount,
    syncSalesForceOpportunity,
    getSalesValueOpportunityValues,
    sendSalesValueDocument,
    changeSalesValueOpportunityStatus,
    saveNewPrice,
    getConnectWiseMissingProducts,
    updateRequiredKwtOption,
    updateExtraKwtCode,
    updateAuthorizedSignatory
};
