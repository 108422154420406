import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import actions from '../actions/authenticationActions'
import {Link} from 'react-router-dom'

class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            code: '',
            rememberMe: true,
            type: 'password',
            passwordIcon: 'visibility',
            switchOrganization: false,
            checkedCode: false,
            organization: '',
            switchableOrganizations: [],
        };
    }
    componentDidMount() {
        const el = document.getElementById('email');
        if (el) {
            setTimeout(() => { el.removeAttribute('readonly'); }, 500);
        }
    }

    handleChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    };

    _onLogin = async () => {
        let {username, password} = this.state;
        const result = await this.props.actions.loginCheck(username, password);
        const switchOrganization = result.switchOrganization;
        this.setState({switchOrganization: switchOrganization})
    };

    _on2FALogin = () => {
        let {username, password, code, organization} = this.state;

        this.props.actions.login(username, password, code, organization);
    };

    handleLoginKeyPress = ({key}) => {
        if (key === 'Enter')
            this._onLogin();
    };

    handle2FALoginKeyPress = ({key}) => {
        if (key === 'Enter' && !this.state.switchOrganization)
            this._on2FALogin();
    };

    onShowPassword = () => {
        this.state.type === "password"
            ? setTimeout(this.showPassword, 500)
            : this.setState({type: 'password', passwordIcon: 'visibility'});
    };

    showPassword = () => {
        this.setState({type: 'text', passwordIcon: 'visibility_off'})
    };

    checkCode = async () => {
        this.setState({checkedCode: true})

        if (this.state.switchOrganization) {
            await this.props.actions.getSwitchableOrganizations();
            const {switchableOrganizationList} = this.props.authentication;
            this.setState({'switchableOrganizations': switchableOrganizationList})
        }
    }

    handleOrganizationClick = async ({target}) => {
        await this.setState({...this.state, organization: target.value})
        this._on2FALogin();
    }

    render() {
        const { authentication } = this.props;
        return (
            <div className="valign-wrapper verticalvalign">
                <div className="valign">
                    <div className="container logincontainer">
                        <div className="section">
                            <div className="row">
                                <div id="Login" className="col s12">
                                    <div className="logocontainer-login col s12">
                                        <img src={authentication.theme.logo} width="200" height="200" alt=""/>
                                    </div>
                                    {
                                        authentication.isLoginChecked ? (

                                            this.state.switchOrganization && this.state.checkedCode? (
                                                <div>
                                                    <div className="col btn-switch-section">
                                                        { this.state.switchableOrganizations.map((organization) =>
                                                            <div className="col left">
                                                                <button onClick={this.handleOrganizationClick}
                                                                        value={organization['id']}
                                                                        className="btn doceri-btn-switch ratio-btn"
                                                                        type="submit" name="action">{organization['name']}
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className="input-field col s12">
                                                        <input id="code" type="text" autoFocus={true} name="code"
                                                               value={this.state.code} onChange={this.handleChange}
                                                               onKeyPress={this.handle2FALoginKeyPress}/>
                                                        <label htmlFor="code">Vul uw sms code in</label>
                                                    </div>
                                                    <div className="col s6 offset-s6">
                                                        <button onClick={this.state.switchOrganization ? this.checkCode : this._on2FALogin}
                                                                className="btn doceri-btn-right ratio-btn-right"
                                                                type="submit" name="action">inloggen
                                                        </button>
                                                    </div>
                                                </div>
                                            )

                                        ) : (
                                            <div>
                                                <div className="input-field col s12">
                                                    <input autocomplete="off" readOnly id="email" name="username"
                                                           onChange={this.handleChange}
                                                           onKeyPress={this.handleLoginKeyPress} type="email"
                                                           className="validate"/>
                                                    <label htmlFor="email">e-mail</label>
                                                </div>
                                                <div className="input-field col s12 m12">
                                                     <span>
                                                <label htmlFor="password">wachtwoord</label>
                                            <input id="password"
                                                   type={this.state.type}
                                                   className="validate left"
                                                   name="password"
                                                   onKeyPress={this.handleLoginKeyPress}
                                                   onChange={this.handleChange}/>
                                            <i className="glyphicon material-icons clickable password-show"
                                               onClick={this.onShowPassword}>{this.state.passwordIcon}</i>
                                            </span>
                                                </div>
                                                <div className="col s6 offset-s6">
                                                    <button onClick={this._onLogin} className="btn doceri-btn-right ratio-btn-right" type="submit" name="action">inloggen</button>
                                                </div>
                                                <div className="col s6 offset-s6 alignright">
                                                    <Link to="/forgotten-password">
                                                        <small>wachtwoord vergeten</small>
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({quotation, authentication}) => {
    return {
        authentication,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
