import React, {Component, Fragment} from 'react';
import connect from "react-redux/es/connect/connect";
import {DelayInput} from "react-delay-input";
import {Button, Icon} from 'react-materialize';

import {bindActionCreators} from "redux";
import {sleep} from "../../../utils/utils";

import hostedVoiceActions from "../../../actions/workflow/hostedVoiceActions";
import stepsActions from "../../../actions/stepsActions";
import quotationActions from "../../../actions/quotationActions";

import AKBTelephoneNumber from '../../common/AKBTelephoneNumber';
import HostedVoiceNumber from "./HostedVoiceNumber";
import HostedVoiceAddons from "./HostedVoiceAddons";

import DeleteData from "../info/DeleteData";
import BulkUpload from "../info/BulkUpload";
import FileDownload from "js-file-download";

import GeneralDialog from "../../../containers/common/GeneralDialog";
import DefaultDialogBody from "../../../containers/common/KpnDialog/DefaultDialogBody";

import providerType from "../../../constants/providerType";

class HostedVoiceOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'numbers': props.hostedVoiceOptions.numbers,
      'formErrors': {},
      bulkUploadDialogOpened: false,
      successBulkImport: false,
      importedRows: 0,
      uploadErrors: {},
      deleteDataDialogOpened: false,
    };
  }

  componentDidMount() {
    this.canProceedToNextStep();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.hostedVoiceOptions.numbers) !== JSON.stringify(this.props.hostedVoiceOptions.numbers)) {

      this.setState({
        numbers: this.props.hostedVoiceOptions.numbers
      });

      this.canProceedToNextStep();
    }
  }

  closeUploadErrorsDialog = () => {
    this.setState({uploadErrors: {}});
  };

  getExampleFile = () => {
    this.props.quotationActions.getVoiceBulkExampleFile(this.props.quotationId).then((success) => {
      if(success === true) {
        this.downloadExampleFile()
      }
    });
  };

  downloadExampleFile = () => {
    FileDownload(this.props.quotation.fixedBulkExampleFile, 'Bulk-upload-ratio-vaste-telefoonnummers.xlsx');
  };

  addNumber = () => {
    if (this.props.quotationStatus) {
      return;
    }
    const newNumber = {
      numberType: 0,
      blocks: 1,
      existing: 1,
      hostedVoiceOptions: this.props.hostedVoiceOptions.id,
      count: 1,
      startingNumber: '',
      prefix: '',
    };

    this.props.hostedVoiceActions.updateHostedVoiceOptions(this.props.quotationId, this.props.locations.selectedLocation.id, {
      numbers: [
        ...this.props.hostedVoiceOptions.numbers,
        {...newNumber}
      ]
    }).then(() => {
      this.setState({
        numbers: this.props.hostedVoiceOptions.numbers
      });

      this.canProceedToNextStep();
    });
  };

  removeNumber = (index, id) => {
    if (this.props.quotationStatus) {
      return;
    }

    this.props.hostedVoiceActions.removeHostedVoiceNumber(this.props.quotationId, this.props.locations.selectedLocation.id, {
      numberId: id
    }).then(() => {
      this.setState({
        numbers: this.props.hostedVoiceOptions.numbers
      });

      this.canProceedToNextStep();
    });
  };

  changeNumber = (number, index) => {
    if (this.props.quotationStatus) {
      return;
    }

    let changedNumbers = [...this.state.numbers];
    changedNumbers[index] = number;
    this.setState({
      numbers: changedNumbers
    });

    if (number.valid) {
      changedNumbers[index] = {
        id: number.id,
        existing: number.existing,
        blocks: number.blocks,
        numberType: number.numberType,
        startingNumber: number.startingNumber,
        hostedVoiceOptions: this.props.hostedVoiceOptions.id,
        count: 1,
        prefix: number.prefix,
        portingDate: number.portingDate,
        contractEndDate: number.contractEndDate
      };

      this.props.hostedVoiceActions.updateHostedVoiceOptions(this.props.quotationId, this.props.locations.selectedLocation.id, {
        numbers: changedNumbers
      })
        .then(() => {
          this.setState({
            numbers: this.props.hostedVoiceOptions.numbers
          });
          this.canProceedToNextStep();
        });
    } else {
      this.canProceedToNextStep();
    }
  };

  canProceedToNextStep = () => {
    sleep(500).then(() => {
      this.validateAllPhoneNumbers();
      let stepValid = Object.keys(this.state.formErrors).length === 0;
      this.props.stepsActions.setAvailableToGoForward(stepValid);
    });
  };

  validateAllPhoneNumbers = () => {
    this.setState({
      formErrors: {}
    });
    (this.state.numbers || []).forEach((number, index) => {
      if (number.isFetched) {
        return;
      }
      if (number.count > 0 && parseInt(number.existing) !== 0) {
        this.validateField('startingNumber', index, number.startingNumber, number.prefix ? 7 : 10);
        this.validateField('portingDate', index, number.portingDate, 10);
        this.validateField('contractEndDate', index, number.contractEndDate, 10);
      }
      if (number.error) {
        this.addNumberFieldError(index, 'startingNumber');
      }
    });
  };


  validateField = (fieldName, index, value, length) => {
    let isValid = true;
    const dateRegex = /^(?:(19|20)[0-9]{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]))$/;

    switch (fieldName) {
      case 'startingNumber':
        isValid = (value.length === length && value.match(/^\d+$/));
        break;
      default:
        return isValid;
    }

    if (!isValid) {
      this.addNumberFieldError(index, fieldName);
    } else {
      this.removeNumberFieldError(index, fieldName);
    }

    return isValid;
  };

  removeNumberFieldError = (index, field) => {
    let {formErrors} = this.state;
    if (formErrors) {
      if (formErrors[index]) {
        //remove error only from fieldName(send as parameter)
        if (field) {
          const elemIndex = formErrors[index].indexOf(field);
          if (elemIndex !== -1) {
            formErrors[index].splice(elemIndex, 1);
          }

          if (!formErrors[index].length) {
            delete formErrors[index];
          }
        } else {
          delete formErrors[index];
        }
      }

      this.setState({
        formErrors: formErrors
      });
    }
  };

  addNumberFieldError = (index, field) => {
    let {formErrors} = this.state;

    if (!formErrors[index]) {
      formErrors[index] = [];
    }

    formErrors[index].push(field);

    this.setState({
      formErrors: formErrors
    });
  };

  _handleDialogOpen = () => {
    if (this.props.quotationStatus) {
      return;
    }
    this.setState({bulkUploadDialogOpened: true});
  };

  _handleDialogClose = () => {
    this.setState({bulkUploadDialogOpened: false});
  };

  save = () => {
    if (this.props.quotationStatus) {
      return;
    }

    this.props.quotationActions.bulkImport(this.props.quotationId, this.props.locations.selectedLocation.id,
        this.state.file, 'fixed', false).then((response) => {
        if(response && response.importedRows) {
          this.setState({
            successBulkImport:true,
            importedRows: response.importedRows
          })
        }

        if(response && response.errors) {
            this.setState({uploadErrors: response.errors});
        }

        this._handleDialogClose();
    });
  };

  _onDrop = (files) => {
    let file = files[0];

    if (file) {
      this.setState({file: file});
    }
  };

  _handleDeleteDataDialogOpen = () => {
    this.setState({deleteDataDialogOpened: true});
  };

  _handleDeleteDataDialogClose = () => {
    this.setState({deleteDataDialogOpened: false});
  };

  deleteAllData = () => {
    if (this.props.quotationStatus) {
      return;
    }
    this.props.quotationActions.deleteAllMobileOptions(this.props.quotationId, -1, 'voip');

    this._handleDeleteDataDialogClose();
  };

  closeSuccessDialog = () => {
    this.setState({
      successBulkImport: false,
      importedRows: 0
    });
  };

  renderSuccessMessage = (rowsImported) => {
    return (
      <GeneralDialog
        dialogState={this.state.successBulkImport}
        headerIcon="folder_open"
        headerText="Gelukt!"
        closeHandler={this.closeSuccessDialog}
        dialogBodyContent={(
          <div className="input-field col s12" style={{ margin: '10px 0 20px' }}>
            Uw bulk-upload is succesvol toegevoegd. Er zijn {rowsImported} telefoonnummers toegevoegd.
          </div>
        )}
        rightButtonAction={this.closeSuccessDialog}
        rightButtonText={'Ik heb dit begrepen'}
      />
    );
  };

  renderErrorMessage = (errors) => {
    return (
      <GeneralDialog
        dialogState={errors.length}
        onRequestClose={this.closeUploadErrorsDialog}
        headerText="Er zit iets niet helemaal goed in uw bulk upload"
        closeHandler={this.closeUploadErrorsDialog}
        dialogBodyContent={(
          <DefaultDialogBody
            text={"Er zijn fouten opgetreden in de volgende regels in excel: " +
            errors + '. Kijkt u deze aub goed na. De bulk upload is mislukt.'} />
        )}
        rightButtonAction={this.closeUploadErrorsDialog}
        rightButtonText={"Ik heb dit begrepen"}
      />
    );
  };

  render() {
    const {
      hostedVoiceOptions,
      onChangeCallback,
      onQuantitySaveAction,
      usersSumValid,
      quotation,
      locations,
      receptionTypes,
      authentication,
      isVerlengenType,
      inputsDisabled,
      onChangeCrmCaraSoloOptions
    } = this.props;

    const telephoneNumbers = this.props.telephoneNumbers.filter(element => element.type === 'fixed');
    let numbersList = this.state.numbers || [];

    return (<div className="row">
      {this.state.successBulkImport &&
        this.renderSuccessMessage(this.state.importedRows)
      }

      {this.state.uploadErrors.length &&
        this.renderErrorMessage(this.state.uploadErrors)
      }

      {(hostedVoiceOptions.hostedVoiceActive || hostedVoiceOptions.businessConnectActive) && (
        <div id="Forms" className="col s12">
          <div className="row">
            <div className="col s12">
              <h1 className={'ratio-section-title'}>
                <span className="ratio-section-title">
                    <i className="small material-icons left ratio-section-title">settings_phone</i>Telefoonnummer:
                </span> gewenste opties
              </h1>
            </div>
          </div>
            {
              numbersList.map((element, index) => {
                if (!element.isFetched) {
                  return <HostedVoiceNumber
                    number={element}
                    key={index}
                    index={index}
                    removeNumber={this.removeNumber}
                    changeNumber={this.changeNumber}
                    numbersMatrix={hostedVoiceOptions.numbersMatrix}
                    validateField={this.validateField}
                    formErrors={this.state.formErrors}
                    inputsDisabled={inputsDisabled}
                    provider={this.props.quotation.currentQuotation.provider}
                  />
                }
              })
            }

        <div className="row">
            <Button
                type="submit"
                waves="light"
                className="doceri-btn-right"
                style={{marginLeft: 20}}
                onClick={() => this._handleDeleteDataDialogOpen()}
                disabled={inputsDisabled}
            >
              Delete
              <Icon right>
                delete
              </Icon>
            </Button>

            <DeleteData
                dialogOpened={this.state.deleteDataDialogOpened}
                onRequestClose={this._handleDeleteDataDialogClose}
                deleteData={this.deleteAllData}
                type="Vaste telefonie"
            />

            <button className="btn doceri-btn-right ratio-btn-right" style={{marginLeft: 20}} onClick={this.addNumber} disabled={inputsDisabled}>
              Nummers toevoegen
            </button>

          {!authentication.isSalesValue && quotation.currentQuotation.provider === providerType.KPN &&
            <Fragment>
              <Button
                type="submit"
                waves="light"
                className="doceri-btn-right"
                style={{marginLeft: 20}}
                onClick={() => this._handleDialogOpen()}
                disabled={inputsDisabled}
              >
                Bulk Upload
                <Icon right>
                  save
                </Icon>
              </Button>

              <Button
                type="submit"
                waves="light"
                className="doceri-btn-right"
                style={{marginLeft: 20}}
                onClick={() => this.getExampleFile()}
                disabled={inputsDisabled}
              >
                Download bulk template
                <Icon right>
                  save
                </Icon>
              </Button>

              <BulkUpload
                dialogOpened={this.state.bulkUploadDialogOpened}
                onRequestClose={this._handleDialogClose}
                onDrop={this._onDrop}
                droppedFile={this.state.file}
                save={this.save}
              />
            </Fragment>
          }

        </div>
          {this.props.authentication.isSalesValue && telephoneNumbers.map((element) => {
            return (
              <AKBTelephoneNumber
                key={element.id}
                telephoneNumber={element}
                inputsDisabled={inputsDisabled}
              />
            )})
          }
        </div>
      )}

      {(hostedVoiceOptions.hostedVoiceActive && ((locations.selectedLocation.id < 0 && (!hostedVoiceOptions.businessConnectActive || !quotation.voicePerLocation)) || locations.selectedLocation.id > 0)) && (
        <div>
          <div id="Forms" className="col s12">
            <div className="row">
              <div className="col s12">
                <h1 className={'ratio-section-title'}>
                    <span className="ratio-section-title">
                        <i className="small material-icons left ratio-section-title">settings_phone</i>Telefooncentrale:
                    </span> gewenste opties
                </h1>
              </div>
            </div>

            {hostedVoiceOptions.hostedVoiceActive && (hostedVoiceOptions.callChargeType > 0) &&
            (quotation.currentQuotation.provider === 0 ?
                <div>
                  <div className="row">
                    <div className="input-field col s6 m6 inner-addon">
                      <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                      <DelayInput
                        delayTimeout={500}
                        id="callmenow"
                        type="number"
                        min={0}
                        max={999}
                        className="validate"
                        name="callMeNow"
                        value={Number(hostedVoiceOptions.callMeNow).toString()}
                        onChange={onChangeCallback}
                        onBlur={onQuantitySaveAction}
                        disabled={inputsDisabled}
                      />
                      <label htmlFor="callmenow">Aantal ‘Bel-me-nu’</label>
                    </div>
                    <div className="input-field col s6 m6 inner-addon">
                      <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                      <DelayInput
                        delayTimeout={500}
                        id="faxtoemail"
                        type="number"
                        min={0}
                        max={999}
                        className="validate"
                        name="faxToEmail"
                        value={Number(hostedVoiceOptions.faxToEmail).toString()}
                        onChange={onChangeCallback}
                        onBlur={onQuantitySaveAction}
                        disabled={inputsDisabled}
                      />
                      <label htmlFor="faxtoemail">Aantal fax naar e-mail</label>
                    </div>
                    <div className="input-field col s6 m6 inner-addon">
                      <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                      <DelayInput
                        delayTimeout={500}
                        id="interactivevoicerespond"
                        type="number"
                        min={0}
                        max={999}
                        className="validate"
                        name="interactiveVoiceRespond"
                        value={Number(hostedVoiceOptions.interactiveVoiceRespond).toString()}
                        onChange={onChangeCallback}
                        onBlur={onQuantitySaveAction}
                        disabled={inputsDisabled}
                      />
                      <label htmlFor="interactivevoicerespond">Aantal Keuzemenu pakket</label>
                    </div>
                    <div className="input-field col s6 m6 inner-addon">
                      <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                      <DelayInput
                        delayTimeout={500}
                        id="departmentvoicemail"
                        type="number"
                        min={0}
                        max={999}
                        className="validate"
                        name="departmentVoicemail"
                        value={Number(hostedVoiceOptions.departmentVoicemail).toString()}
                        onChange={onChangeCallback}
                        onBlur={onQuantitySaveAction}
                        disabled={inputsDisabled}
                      />
                      <label htmlFor="departmentvoicemail">Afdelingsvoicemail</label>
                    </div>
                  </div>
                </div> :
                (quotation.currentQuotation.provider === 2 &&
                  <div>
                    <div className="row">
                      <div className="input-field col s6 m6 inner-addon">
                        <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                        <DelayInput
                          delayTimeout={500}
                          id="userTool"
                          type="number"
                          min={0}
                          max={999}
                          className="validate"
                          name="userTool"
                          value={Number(hostedVoiceOptions.userTool).toString()}
                          onChange={onChangeCallback}
                          onBlur={onQuantitySaveAction}
                          disabled={inputsDisabled}
                        />
                        <label htmlFor="userTool">One Net gebruikerstool</label>
                      </div>
                      <div className="input-field col s6 m6 inner-addon">
                        <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                        <DelayInput
                          delayTimeout={500}
                          id="dayNightSchedule"
                          type="number"
                          min={0}
                          max={999}
                          className="validate"
                          name="dayNightSchedule"
                          value={Number(hostedVoiceOptions.dayNightSchedule).toString()}
                          onChange={onChangeCallback}
                          onBlur={onQuantitySaveAction}
                          disabled={inputsDisabled}
                        />
                        <label htmlFor="dayNightSchedule">Dag/nachtstand (één per locatie)</label>
                      </div>
                      <div className="input-field col s6 m6 inner-addon">
                        <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                        <DelayInput
                          delayTimeout={500}
                          id="voiceMail"
                          type="number"
                          min={0}
                          max={999}
                          className="validate"
                          name="voiceMail"
                          value={Number(hostedVoiceOptions.voiceMail).toString()}
                          onChange={onChangeCallback}
                          onBlur={onQuantitySaveAction}
                          disabled={inputsDisabled}
                        />
                        <label htmlFor="voiceMail">Voicemail</label>
                      </div>
                      <div className="input-field col s6 m6 inner-addon">
                        <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                        <DelayInput
                          delayTimeout={500}
                          id="app"
                          type="number"
                          min={0}
                          max={999}
                          className="validate"
                          name="app"
                          value={Number(hostedVoiceOptions.app).toString()}
                          onChange={onChangeCallback}
                          onBlur={onQuantitySaveAction}
                          disabled={inputsDisabled}
                        />
                        <label htmlFor="app">One App</label>
                      </div>
                    </div>
                  </div>
                )
            )
            }
          </div>
          {hostedVoiceOptions.hostedVoiceActive &&
            <HostedVoiceAddons
              hostedVoiceOptions={hostedVoiceOptions}
              onChangeCallback={onChangeCallback}
              onQuantitySaveAction={onQuantitySaveAction}
              vamoAmountString={this.props.vamoAmountString}
              usersSumValid={usersSumValid}
              quotation={quotation}
              receptionTypes={receptionTypes}
              isSalesValue={this.props.authentication.isSalesValue}
              isVerlengenType={isVerlengenType}
              inputsDisabled={inputsDisabled}
              onChangeCrmCaraSoloOptions={onChangeCrmCaraSoloOptions}
              crmCaraSoloRequiredErrorMessage={this.props.crmCaraSoloRequiredErrorMessage}
            />
          }
        </div>
      )}
    </div>);
  };
}

const mapStateToProps = ({quotation, authentication, locations, mobile}) => {
  return {
    quotation,
    quotationId: quotation.currentQuotation.id,
    telephoneNumbers: quotation.currentQuotation.telephone_numbers,
    currentMobileProductOptions: mobile.currentMobileProductOptions,
    authentication: authentication,
    locations: locations,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    quotationActions: bindActionCreators(quotationActions, dispatch),
    hostedVoiceActions: bindActionCreators(hostedVoiceActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(HostedVoiceOptions);
