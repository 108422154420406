import React, {Component} from 'react';
import {Col, Icon, Input, Row} from 'react-materialize';
import {bindActionCreators} from 'redux';
import {connect} from "react-redux";

import quotationActions from "../../../actions/quotationActions";
import locationActions from "../../../actions/locationActions";
import telephonyActions from "../../../actions/workflow/telephonyActions";
import stepsActions from "../../../actions/stepsActions";

import LoaderComponent from '../../../containers/common/LoaderComponent';
import {sleep} from "../../../utils/utils";
import {DelayInput} from "react-delay-input";

class TelephonyLocationLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profilesProcessing: false,
      mobileProductOptions: this.props.mobileProductOptions || [],
      selectedMobileProducts: this.props.selectedMobileProducts || [],
      mobileFormErrors: {},
      acdLimit: 0,
    };
  }

  componentDidMount() {
    if (! this.props.selectedMobileProducts.length) {
      this.setState({ selectedMobileProducts: [{id: "", productId: ""}]});
    }

    let limit = this.getAcdLimit(this.props.selectedMobileProducts);
    this.setState({ acdLimit: limit });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedMobileProducts !== this.props.selectedMobileProducts) {
      let profiles = this.props.selectedMobileProducts;
      let limit = this.getAcdLimit(profiles);
      this.setState({ selectedMobileProducts: profiles, acdLimit: limit });
    }

    if (prevProps.mobileProductOptions !== this.props.mobileProductOptions) {
      this.setState({ mobileProductOptions: this.props.mobileProductOptions })
    }
  }

  getAcdLimit = (profiles) => {
    if (! profiles.length) {
      return 0;
    }

    return profiles.reduce((previous, current) => previous + current.quantity, 0);
  };

  onChangeCallback = (selectionId, selectionProductId = false) => (event, value) => {
    if (this.props.quotation.currentQuotation.inputsDisabled) {
      return;
    }

    let bundleIsChanged = !selectionProductId;
    const productId = selectionProductId ? selectionProductId : +value;
    let currentSelectedProducts = this.props.selectedMobileProducts;

    let deletedCurrentSelection = false;
    let newQuantity = 0;
    if (bundleIsChanged) {
      currentSelectedProducts.forEach((obj, index) => {
        if ((!selectionId || obj.id !== selectionId)
          && productId === currentSelectedProducts[index]['productId']) {

          if (selectionId) {
            const foundIndex = currentSelectedProducts.findIndex((productSelection) => productSelection && productSelection.id === selectionId);
            if (foundIndex !== -1) {
              newQuantity = currentSelectedProducts[index]['quantity'] += currentSelectedProducts[foundIndex].quantity;

              if (newQuantity <= 999) {
                currentSelectedProducts[index]['quantity'] = newQuantity;
              }
              delete currentSelectedProducts[foundIndex];
            } else {
              newQuantity = currentSelectedProducts[index]['quantity'] + 1;
              if (newQuantity <= 999) {
                currentSelectedProducts[index]['quantity'] = newQuantity;
              }
            }
          } else {
            newQuantity = currentSelectedProducts[index]['quantity'] + 1;
            if (newQuantity <= 999) {
              currentSelectedProducts[index]['quantity'] = newQuantity;
            }
          }

          deletedCurrentSelection = true;
        }
      });
    }

    if (!deletedCurrentSelection) {
      if (selectionId) {
        currentSelectedProducts.forEach((obj, index) => {
          if (obj.id === selectionId) {
            if (productId === 0) {
              delete currentSelectedProducts[index];
            } else {
              currentSelectedProducts[index]['productId'] = productId;
              currentSelectedProducts[index]['quantity'] = 1;
              if (selectionProductId) {
                currentSelectedProducts[index]['quantity'] = value;
              }
            }
          }
        });
      } else {
        const product = {
          id: "",
          productId: productId,
          quantity: 1
        };

        currentSelectedProducts.push(product);
      }
    }

    currentSelectedProducts = currentSelectedProducts.filter(obj => obj.productId !== null);
    this.setState({ selectedMobileProducts: currentSelectedProducts });
    let selectedLocation = this.props.locations.selectedLocation.id;

    this.props.telephonyActions
      .updateMobileProducts(
        this.props.quotation.currentQuotation,
        selectedLocation,
        currentSelectedProducts,
        this._getLatestSelectedOptions(bundleIsChanged ? selectionId : false)
      )
      .then(() => this.props.locationActions.setQuotationLocations(this.props.quotationId))
      .then(() => {
        let updatedLocation = this.props.locations.allLocations.find((el) => el.id === selectedLocation);
        this.props.locationActions.setSelectedLocation(updatedLocation, true)
      })
  };

  onQuantityChangeCallback = (selectionId, selectionProductId = false) => (event, value) => {
    this.setState({ profilesProcessing: true });
    const productId = selectionProductId ? selectionProductId : +value;
    let currentSelectedProducts = this.props.selectedMobileProducts;

    if (selectionId) {
      currentSelectedProducts.forEach((obj, index) => {
        if (obj.id === selectionId) {
          if (productId === 0) {
            delete currentSelectedProducts[index];
          } else {
            currentSelectedProducts[index]['productId'] = productId;
            currentSelectedProducts[index]['quantity'] = 1;
            if (selectionProductId) {
              value = Math.max(Number(event.target.min), Math.min(Number(event.target.max), Number(value)));

              currentSelectedProducts[index]['quantity'] = value;
            }
          }
        }
      });
    }

    currentSelectedProducts = currentSelectedProducts.filter(obj => obj.productId !== null);
    this.setState({ selectedMobileProducts: currentSelectedProducts });

    sleep(2000).then(() => {
      this.setState({ profilesProcessing: true });
      this.sendUpdateMobileProducts();
    });
  };

  sendUpdateMobileProducts = () => {
    let selectedLocation = this.props.locations.selectedLocation.id;

    this.props.telephonyActions.updateMobileProducts(this.props.quotation.currentQuotation, selectedLocation, this.getSelectedMobileProducts(), this._getLatestSelectedOptions())
      .then(() => this.props.locationActions.setQuotationLocations(this.props.quotationId))
      .then(() => {
        let updatedLocation = this.props.locations.allLocations.find((el) => el.id === selectedLocation);
        this.props.locationActions.setSelectedLocation(updatedLocation, true)
      })
      .then(() => { this.setState({ profilesProcessing: false }); });
  };

  getSelectedMobileProducts = () => {
    return this.state.selectedMobileProducts.filter(obj => obj.productId !== null && !obj.isOption);
  };

  _getLatestSelectedOptions = (bundleIsChanged = false) => {
    let allSelectedOptions = {};

    Object.keys(this.state.mobileProductOptions).map(selId => {
      if (bundleIsChanged != selId) {
        allSelectedOptions[selId] = this.getSelectedProducts(this.state.mobileProductOptions[selId]);
      }
    });

    return allSelectedOptions;
  };

  static applySelectedProduct = (acc, current) => {
    if (current.selectedProducts.length > 0) {
      let rObj = {};
      current.selectedProducts.filter(obj => obj.id !== -1).forEach(obj => {
        rObj[obj.id] = acc[obj.id] ? (acc[obj.id] + obj.quantity) : obj.quantity;
      });

      return {...acc, ...rObj};
    }

    return acc;
  };

  getSelectedProducts = (mobileOptions) => mobileOptions.reduce(TelephonyLocationLevel.applySelectedProduct, {});

  addNewProductSelection = () => {
    this.props.telephonyActions.addNewProductSelection({id: "", productId: "", quantity: 1});
  };

  renderExtraSelect = (option, index) => {
    let selectedProductsQuantity = 0;
    let { inputsDisabled } = this.props.quotation.currentQuotation;
    option.selectedProducts.forEach((value) => {
      selectedProductsQuantity += value.quantity ? parseInt(value.quantity) : 0;
    });

    const addButtonVisible = option.selectedProducts.length < option.products.length;

    return (
      <div key={index}>
        {this.renderOptionFields(option, index)}

        {addButtonVisible && (option.products.length !== option.selectedProducts.length) ? (
          <Row>
            <Col s={12}>
              <button
                className={'btn ratio-btn white-text ratio-btn-right right ' + (inputsDisabled ? '' : 'doceri-btn-left')}
                onClick={() => this.addOption(index, option)} style={{marginBottom: 30}}
                disabled={inputsDisabled}
              >
                Voeg optie toe
                <Icon right>
                  add
                </Icon>
              </button>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  };

  handleOptionsQuantityChange = (productId, min, max, selectionId, index) => (event, value) => {
    let quantity = +value;
    quantity = Math.max(Number(event.target.min), Math.min(Number(event.target.max), Number(quantity)));
    let allOptions = this.state.mobileProductOptions;
    let options = allOptions[selectionId];

    if (String(index).indexOf('-') !== -1) {
      index = index.substring(index.indexOf('-') + 1);
    }

    if (options[index]) {
      const foundIndex = options[index].selectedProducts.findIndex((selectedProduct) => selectedProduct && selectedProduct.id === productId);
      if (foundIndex !== -1) {
        options[index]['selectedProducts'][foundIndex]['quantity'] = quantity;
      }

      allOptions[selectionId] = options;
      this.setState({mobileProductOptions: allOptions});
    }

    this.sendUpdateMobileProducts();
  };

  handleOptionsChange = (productId, quantity = 1, max = null, selectionId, index, numberId = null) => (event, value) => {
    const id = +value;

    let allOptions = this.state.mobileProductOptions;
    let options = allOptions[selectionId];
    let newSelection = {
      id: id,
      quantity: quantity,
      telephone_number_id: numberId
    };

    if (String(index).indexOf('-') !== -1) {
      index = index.substring(index.indexOf('-') + 1);
    }

    if (options[index]) {
      const foundIndex = options[index].selectedProducts.findIndex((selectedProduct) => {
        const condition = selectedProduct && selectedProduct.id === productId;

        if (numberId) {
          return condition && selectedProduct.telephone_number_id === numberId;
        }

        return condition;
      });

      if (foundIndex !== -1) {
        delete options[index]['selectedProducts'][foundIndex];
      }
      options[index]['selectedProducts'].push(newSelection);

      allOptions[selectionId] = options;

      this.setState({mobileProductOptions: allOptions});
    }
    let selectedLocation = this.props.locations.selectedLocation.id;

    this.props.telephonyActions.updateMobileProducts(
      this.props.quotation.currentQuotation,
      selectedLocation,
      this.state.selectedMobileProducts,
      this._getLatestSelectedOptions()
    );
  };

  renderOptionFields = (option, index) => {
    const {selectedProducts = [{id: -1}]} = option || {};
    const {currentQuotation} = this.props.quotation;
    let value = {id: -1};
    let { inputsDisabled } = currentQuotation;

    const selectedProductsArray = selectedProducts && selectedProducts.length ? selectedProducts : [value];

    let rows = [];

    selectedProductsArray.forEach((value, indexField) => {
      let maxQuantity = 999;
      const selectWidth = value.id !== -1 ? 9 : 10;

      let row = (
        <Col key={`${option.category.name}-${indexField}-${value.id}`} s={12} style={{paddingRight: 0}}>
          <Row key={`${option.category.name}-${indexField}-${value.id}`} style={{marginBottom: 0}}>
            {value.id !== -1 && maxQuantity > 1 ? (
              <Input
                s={1}
                type="number"
                label="Aantal"
                value={value.quantity > 0 ? value.quantity : 1}
                disabled={inputsDisabled}
                onChange={this.handleOptionsQuantityChange(
                  value.id,
                  value.allowed_quantities ? value.allowed_quantities.min : 1,
                  maxQuantity,
                  option.selectionId,
                  index
                )}
                min={value.allowed_quantities ? value.allowed_quantities.min : 1}
                max={maxQuantity <= 999 ? maxQuantity : 999}
              />) : null}

            <Input
              s={selectWidth}
              type="select"
              label="Gewenste gebruikersfunctionaliteiten"
              onChange={this.handleOptionsChange(
                value.id,
                value.quantity,
                maxQuantity,
                option.selectionId,
                index
              )}
              multiple={false}
              disabled={inputsDisabled}
              value={value.id}
            >
              <option key={-1} value={-1}>
                Niets geselecteerd
              </option>
              {option.products.map(this.renderOptionProduct)}
            </Input>

            {value.id !== -1 && maxQuantity > 1 ? (
              <Col s={2}>
                <button
                  className="btn doceri-btn-right ratio-btn-right"
                  disabled={inputsDisabled}
                  onClick={() => this.removeOption(index, value.id, option.selectionId)}
                >
                  Verwijder optie
                  <Icon right>
                    close
                  </Icon>
                </button>
              </Col>) : null
            }
          </Row>
        </Col>
      );

      rows.push(row);
    });

    return rows;
  };

  removeOption = (index, productId, selectionId) => {
    let allOptions = this.state.mobileProductOptions;
    const options = allOptions[selectionId];

    if (String(index).indexOf('-') !== -1) {
      index = index.substring(index.indexOf('-') + 1);
    }

    if (options[index]) {
      const foundIndex = options[index].selectedProducts.findIndex((selectedProduct) => selectedProduct && selectedProduct.id === productId);
      options[index].selectedProducts.splice(foundIndex, 1);

      allOptions[selectionId] = options;
      this.setState({mobileProductOptions: allOptions});
    }

    if (productId !== -1) {
      let selectedLocation = this.props.locations.selectedLocation.id;
      this.props.telephonyActions.updateMobileProducts(this.props.quotation.currentQuotation, selectedLocation,
        this.state.selectedMobileProducts, this._getLatestSelectedOptions());
    }
  };

  renderOptionProduct = ({name, id}) => {
    return (<option key={id} value={id}>
      {name}
    </option>)
  };

  getChildSelections = (selection) => {
    const selections = [];
    selection.selectedProducts.forEach((selectedProduct) => {
      if (selectedProduct.selection_id && this.state.mobileProductOptions[selectedProduct.selection_id]) {
        this.state.mobileProductOptions[selectedProduct.selection_id].forEach((element) => {
          element.selectionId = selectedProduct.selection_id;
          element.bundleSelectionQuantity = selection.bundleSelectionQuantity
            ? selection.bundleSelectionQuantity * selectedProduct.quantity : selectedProduct.quantity;
          selections.push(element);
        })
      }
    });

    return selections;
  };

  addOption = (index, option) => {
    option.selectedProducts.push({id: -1});

    let options = this.state.mobileProductOptions;
    options[option.selectionId][index] = option;

    this.setState({mobileProductOptions: options})
  };

  removeProfile = (selectionId) => {
    let selectionProductId = false;
    let value = '0';
    let bundleIsChanged = !selectionProductId;
    const productId = selectionProductId ? selectionProductId : +value;
    let currentSelectedProducts = this.props.selectedMobileProducts;

    currentSelectedProducts.forEach((obj, index) => {
      if (obj.id === selectionId) {
        if (productId === 0) {
          delete currentSelectedProducts[index];
        } else {
          currentSelectedProducts[index]['productId'] = productId;
          currentSelectedProducts[index]['quantity'] = 1;
          if (selectionProductId) {
            currentSelectedProducts[index]['quantity'] = value;
          }
        }
      }
    });

    currentSelectedProducts = currentSelectedProducts.filter(obj => obj.productId !== null);
    this.setState({ selectedMobileProducts: currentSelectedProducts });
    let selectedLocation = this.props.locations.selectedLocation.id;

    this.props.telephonyActions.updateMobileProducts(
      this.props.quotation.currentQuotation,
      selectedLocation,
      currentSelectedProducts,
      this._getLatestSelectedOptions(bundleIsChanged ? selectionId : false)
    )
  };

  render() {
    const { quotation: { currentQuotation: { inputsDisabled } }, authentication: { theme }, onChangeCallback } = this.props;
    const { selectedLocation } = this.props.locations;

    let { selectedMobileProducts, mobileProductOptions, acdLimit } = this.state;
    let {telephonyOptions} = this.props;

    if (!selectedMobileProducts.length) {
      selectedMobileProducts = [{id: "", productId: ""}];
    }

    return (
      <div>
        {this.state.loading
          ? <LoaderComponent theme={theme} />
          :  <React.Fragment>
            <div className="row" id="Forms">
              { selectedMobileProducts.map((selection, indexSelection) => {
                return (
                  <Col s={12} key={indexSelection}>
                    <Row style={{paddingTop: '20px'}}>
                      <div className="line tussenkop">Stel hier het gewenste profiel in</div>
                    </Row>

                    <Row>
                      {mobileProductOptions[selection.id]
                      && (selection.allowed_quantities === null
                        || selection.allowed_quantities && selection.allowed_quantities.max > 1
                      ) ? (
                        <Input
                          s={1}
                          name='amount'
                          type='number'
                          label='Aantal'
                          disabled={inputsDisabled} value={selection.quantity > 0 ? selection.quantity : 1}
                          onChange={this.onQuantityChangeCallback(
                            selection.id,
                            selection.productId
                          )}
                          min={selection.allowed_quantities ? selection.allowed_quantities.min : 1}
                          max={selection.allowed_quantities ? selection.allowed_quantities.max : 999}
                        />) : null}
                      <Input
                        s={mobileProductOptions[selection.id]
                        && (selection.allowed_quantities === null
                          || selection.allowed_quantities && selection.allowed_quantities.max > 1
                        ) ? 11 : 12}
                        name={"selection_offer_product"}
                        type='select'
                        label="Profielen"
                        disabled={inputsDisabled}
                        onChange={this.onChangeCallback(selection.id)}
                        value={selection.productId}
                        multiple={false}
                      >
                        <option value={0}>Niets geselecteerd</option>
                        {
                          this.props.mobileProducts.map((el) => {
                            return <option key={el.name} value={el.id}>{el.name}</option>
                          })
                        }
                      </Input>
                    </Row>

                    <Row>
                      {mobileProductOptions[selection.id] && mobileProductOptions[selection.id].map((option, index) => {
                          option.selectionId = selection.id;
                          option.bundleSelectionQuantity = selection.quantity;

                          let extraSelect = [];
                          extraSelect.push(this.renderExtraSelect(option, index));
                            this.getChildSelections(option).map((childOption, childIndex) => {

                              let parentQuantity = 0;
                              option.selectedProducts.map(product => {
                                parentQuantity += product.quantity;
                              });

                              childOption.bundleSelectionQuantity = parentQuantity;
                              extraSelect.push(this.renderExtraSelect(childOption, index + '-' + childIndex));
                            });

                          return extraSelect;
                        })
                      }
                    </Row>

                    { selection.id !== '' &&
                      <Row>
                        <Col style={{float: 'right'}}>
                          <button
                            name="removeProfile"
                            className={'btn ratio-btn white-text ratio-btn-right right ' + (inputsDisabled ? '' : 'doceri-btn-left')}
                            onClick={() => this.removeProfile(selection.id)}
                            disabled={inputsDisabled}
                          >
                            Verwijder profiel
                            <Icon right>
                              close
                            </Icon>
                          </button>
                        </Col>
                      </Row>
                    }
                  </Col>);
              })
              }

                <Col s={12}>
                  <div className="line tussenkop"/>
                </Col>

              <Col style={{float: 'right'}}>
                <Row>
                  <button
                    name="addMobileProfile"
                    className={'btn ratio-btn white-text ratio-btn-right right ' + (inputsDisabled ? '' : 'doceri-btn-left')}
                    onClick={this.addNewProductSelection}
                    disabled={inputsDisabled}
                  >
                    Nog een profiel toevoegen
                    <Icon right>
                      add
                    </Icon>
                  </button>
                </Row>
              </Col>
            </div>

            <div id="Forms" style={{paddingBottom: "10px"}}>
              <Row>
                <Col s={12}>
                  <div>
                    <h1 className={'ratio-section-title'}>
                    <span className="ratio-section-title">
                      <i className="small material-icons left ratio-section-title">settings_phone</i>
                      Per bedrijf:
                    </span> gewenste opties
                    </h1>
                  </div>
                </Col>
              </Row>

              <Row>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <DelayInput
                    delayTimeout={500}
                    id="faxToEmail"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="faxToEmail"
                    value={Number(telephonyOptions.faxToEmail).toString()}
                    onChange={onChangeCallback}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="faxToEmail">Aantal fax naar e-mail</label>
                </div>
                <Input
                  s={6}
                  type="select"
                  label="AD Connect"
                  name="adConnect"
                  onChange={onChangeCallback}
                  multiple={false}
                  value={Number(telephonyOptions.adConnect).toString()}
                  disabled={inputsDisabled}
                >
                  <option key={-1} value={-1}>
                    Niets geselecteerd
                  </option>
                  <option key={1} value={0}>Nee</option>
                  <option key={2} value={1}>Ja</option>
                </Input>
              </Row>
            </div>

            <div id="Forms" style={{paddingBottom: "10px"}}>
              <Row>
                <Col s={12}>
                  <div>
                    <h1 className={'ratio-section-title'}>
                    <span className="ratio-section-title">
                      <i className="small material-icons left ratio-section-title">settings_phone</i>
                      Telefonie:
                    </span> gewenste opties
                    </h1>
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="col s12">
                  <span>ACD</span>
                  <br/><br/>
                </div>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <DelayInput
                    delayTimeout={500}
                    id="acdUsers"
                    type="number"
                    min={0}
                    max={acdLimit - telephonyOptions.acdLightOfPremium}
                    className="validate"
                    name="acdUsers"
                    value={Number(telephonyOptions.acdUsers).toString()}
                    onChange={onChangeCallback}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="acdUsers">Aantal ACD Light gebruikers</label>
                </div>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <DelayInput
                    delayTimeout={500}
                    id="acdLightOfPremium"
                    type="number"
                    min={0}
                    max={acdLimit - telephonyOptions.acdUsers}
                    className="validate"
                    name="acdLightOfPremium"
                    value={Number(telephonyOptions.acdLightOfPremium).toString()}
                    onChange={onChangeCallback}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="acdUsers">Aantal ACD Premium gebruikers</label>
                </div>
              </Row>
              <Row>
                <div className="col s12">
                  <span>Group options</span>
                  <br/><br/>
                </div>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <DelayInput
                    delayTimeout={500}
                    id="huntGroups"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="huntGroups"
                    value={Number(telephonyOptions.huntGroups).toString()}
                    onChange={onChangeCallback}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="huntGroups">Aantal Huntgroup groepen</label>
                </div>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <DelayInput
                    delayTimeout={500}
                    id="crmUsers"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="crmUsers"
                    value={Number(telephonyOptions.crmUsers).toString()}
                    onChange={onChangeCallback}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="crmUsers">Aantal Who's Calling (CRM) gebruikers</label>
                </div>
              </Row>
              <Row>
                <div className="col s12">
                  <span>Audio conference</span>
                  <br/><br/>
                </div>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <DelayInput
                    delayTimeout={500}
                    id="audioConferenceNumbers"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="audioConferenceNumbers"
                    value={Number(telephonyOptions.audioConferenceNumbers).toString()}
                    onChange={onChangeCallback}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="audioConferenceNumbers">Aantal Audio Conference Numbers</label>
                </div>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <DelayInput
                    delayTimeout={500}
                    id="extraConferenceParticipants"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="extraConferenceParticipants"
                    value={Number(telephonyOptions.extraConferenceParticipants).toString()}
                    onChange={onChangeCallback}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="extraConferenceParticipants">Aantal extra Conference Participanten</label>
                </div>
              </Row>
              <Row>
                <div className="col s12">
                  <span>IVR</span>
                  <br/><br/>
                </div>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <DelayInput
                    delayTimeout={500}
                    id="ivr"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="ivr"
                    value={Number(telephonyOptions.ivr).toString()}
                    onChange={onChangeCallback}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="ivr">Aantal IVR</label>
                </div>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <DelayInput
                    delayTimeout={500}
                    id="ivrLayers"
                    type="number"
                    min={0}
                    max={10}
                    className="validate"
                    name="ivrLayers"
                    value={Number(telephonyOptions.ivrLayers).toString()}
                    onChange={onChangeCallback}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="ivrLayers">Aantal IVR lagen</label>
                </div>
              </Row>
              <Row>
                <div className="col s12">
                  <span>All-in Receptionist agent</span>
                  <br/><br/>
                </div>
                <div className="input-field col s12 m12 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <DelayInput
                    delayTimeout={500}
                    id="allInReceptionAgents"
                    type="number"
                    min={0}
                    max={selectedLocation.telephonySelections ? selectedLocation.telephonySelections.receptionistMaxAgent : 0}
                    className="validate"
                    name="allInReceptionAgents"
                    value={Number(telephonyOptions.allInReceptionAgents).toString()}
                    onChange={onChangeCallback}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="allInReceptionAgents">Aantal All-in Receptionist agenten</label>
                </div>
              </Row>
            </div>
          </React.Fragment>
        }
      </div>
    )
  }
}

const mapStateToProps = ({quotation, authentication, locations, loading}) => {
  return {
    locations,
    quotation,
    quotationId: quotation.currentQuotation.id,
    authentication: authentication,
    loading
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    telephonyActions: bindActionCreators(telephonyActions, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
    locationActions: bindActionCreators(locationActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TelephonyLocationLevel);
