import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Icon, Input} from "react-materialize";

import dataManagementActions from "../../../actions/dataManagementActions";

import ProviderHelper from "../../../helper/providerHelper";

import DataManagementProductsGrid from './DataManagementProductsGrid'
import DataManagementProductsGridComputed from "./DataManagementProductsGridComputed";

import '../index.css';
import providerType from "../../../constants/providerType";

class DataManagementProductsForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modules: this.props.dataManagement.allProducts,
      provider: this.props.authentication.providers.length ? this.props.authentication.providers[0].provider : 0,
      organization: this.props.authentication.organizationId,
      selectedCustomProducts: [],
      selectedPortfolioProducts: [],
      computedProductList: [],
      locationData: {
        postalCode: '',
        houseNumber: '',
        houseNumberExtension: '',
      },
      contractLength: 12,
    };
  }

  componentDidMount() {
    let {provider, organization} = this.state;
    this.props.dataManagementActions.getAllProducts(organization, provider);
  };

  componentDidUpdate(prevProps, prevState) {
    let {provider, organization} = this.state;
    if (prevState.provider !== provider) {
      this.props.dataManagementActions.getAllProducts(organization, provider);
    }

    if (prevProps.dataManagement.allProducts !== this.props.dataManagement.allProducts) {
      this.setState({modules: this.props.dataManagement.allProducts});
    }
  }

  handleChangeProvider = ({target: {value}}) => {
    this.setState({
      provider: value,
      selectedCustomProducts: [],
      selectedPortfolioProducts: [],
      computedProductList: []
    });
  };

  changeContractLength = ({target: {value}}) => {
    this.setState({contractLength: value});
  };

  updateLocation = ({target}) => {
    let {name, value} = target;
    let {locationData} = this.state;

    locationData[name] = value;

    this.setState({locationData: locationData});
  };

  identifySelection = (product) => {
    let {selectedCustomProducts, selectedPortfolioProducts} = this.state;

    if (product.isCustomProduct) {
      return selectedCustomProducts.find((el) => el.id === product.id);
    } else {
      return selectedPortfolioProducts.find((el) => el.id === product.id)
    }
  };

  updateSelection = (value, product) => {
    let {selectedCustomProducts, selectedPortfolioProducts} = this.state;

    let isNew;
    let isCustomProduct = product.isCustomProduct;
    let identifiedSelection = this.identifySelection(product);

    value = parseInt(value);
    if (isNaN(value)) {
      value = 0;
    }

    if (!identifiedSelection) {
      isNew = true;
      identifiedSelection = {
        id: product.id,
        quantity: value,
        action: null
      };
    } else {
      isNew = false;
      identifiedSelection.quantity = value;
    }

    if (!isNew) {
      if (isCustomProduct) {
        selectedCustomProducts = selectedCustomProducts.filter((customProduct) => customProduct.id !== product.id);
      } else {
        selectedPortfolioProducts = selectedPortfolioProducts.filter((portfolioProduct) => portfolioProduct.id !== product.id);
      }
    }

    if (isCustomProduct) {
      selectedCustomProducts = [...selectedCustomProducts, identifiedSelection];
    } else {
      selectedPortfolioProducts = [...selectedPortfolioProducts, identifiedSelection];
    }

    this.setState({
      selectedPortfolioProducts: selectedPortfolioProducts,
      selectedCustomProducts: selectedCustomProducts
    });
  };

  resetDependencies = () => {
    this.setState({selectedCustomProducts: [], selectedPortfolioProducts: [], computedProductList: []});
  };

  getProductDependencies = () => {
    let {provider, organization, locationData, contractLength, selectedCustomProducts, selectedPortfolioProducts} = this.state;
    let dataToSubmit = {
      location: locationData,
      contractLength: contractLength,
      products: {
        customProducts: selectedCustomProducts,
        regularProducts: selectedPortfolioProducts
      }
    };

    this.props.dataManagementActions.getProductsDependencies(organization, provider, dataToSubmit).then((response) => {
      if (response && response.success) {
        this.setState({computedProductList: response.data});
      }
    });
  };

  renderModules = () => {
    const {modules} = this.state;

    return modules.map((module) => {
      return (
        <DataManagementProductsGrid
          key={module.moduleName}
          module={module}
          updateSelection={this.updateSelection}
          identifySelection={this.identifySelection}
        />
      );
    });
  };

  generateContractDurationOptions = (options) => {
    const contractDurationOptions = options.map(item => <option value={item}>{item} maanden</option>);

    if (this.state.provider === providerType.DBIT) {
      contractDurationOptions.unshift(<option value={1}>1 maand/eenmalig</option>);
    }

    return contractDurationOptions;
  };

  render() {
    const {isSalesValue, providers} = this.props.authentication;

    let {provider, locationData, computedProductList} = this.state;
    let organizationProviders = providers.filter((el => el.provider !== 1));
    let submitDisabled = !(locationData.postalCode && locationData.houseNumber);

    const kpnContractDurations = [12, 24, 36, 60];
    const vodafoneContractDurations = [12, 24, 36, 48, 60];
    const salesValueContractDurations = [24, 36];
    const tMobileContractDurations = [1, 12, 24, 36, 48, 60];

    const isTMobileProvider = provider === providerType.TMOBILE;
    const isVodafoneProvider = provider === providerType.VODAFONE;

    let availableContractDurations;
    if (isSalesValue) {
      availableContractDurations = salesValueContractDurations
    } else {
      availableContractDurations = isVodafoneProvider ? vodafoneContractDurations : kpnContractDurations;
    }
    if (isTMobileProvider) {
      availableContractDurations = tMobileContractDurations;
    }

    return (
      <div style={{marginTop: '40px'}}>
        <div id="Forms" className="ratiotable paddingbottomnone">
          <h1 className="admin-subheader-text">
            <span className="ratio-section-title">Gegevens afhankelijkheid</span>
          </h1>
        </div>

        <div className="overviewkopbg ratiotable row" style={{paddingTop: '10px'}}>
          <div className="col s1">
            <div className="overviewkop">
              <form>
                <Input
                  name="provider"
                  id="provider"
                  type="select"
                  label="Provider"
                  multiple={false}
                  required={true}
                  defaultValue={0}
                  onChange={(e) => this.handleChangeProvider(e)}
                  className="validate"
                >
                  <option value="-1" disabled={'disabled'} key={-1}>Kies provider</option>
                  {
                    organizationProviders.map((organizationProvider) => {
                      return (
                        <option value={organizationProvider.provider} key={organizationProvider.provider}>
                          {ProviderHelper.getQuotationProviderName(organizationProvider.provider)}
                        </option>
                      );
                    })
                  }
                </Input>
              </form>
            </div>
          </div>

          <div className="col s2">
            <div className="overviewkop">
              <form>
                <Input
                  id="contractLength"
                  name="contractLength"
                  type="select"
                  label="Contractduur *"
                  multiple={false}
                  required={true}
                  defaultValue={12}
                  onChange={(e) => this.changeContractLength(e)}
                  className="validate"
                >
                  {this.generateContractDurationOptions(availableContractDurations)}
                </Input>
              </form>
            </div>
          </div>

          <div className="col s6">
            <div className="overviewkop">
              <form>
                <div className="input-field col s4">
                  <input
                    required
                    type="text"
                    name="postalCode"
                    value={locationData.postalCode || ''}
                    onChange={this.updateLocation}
                    pattern="[1-9][0-9]{3}[a-zA-Z]{2}"
                    className="validate"
                    minLength="6"
                    maxLength="6"
                  />
                  <label htmlFor="postalCode">Postcode *</label>
                </div>
                <div className="input-field col s4">
                  <input
                    required
                    type="text"
                    name="houseNumber"
                    value={locationData.houseNumber || ''}
                    onChange={this.updateLocation}
                    pattern="[0-9]+"
                    className="validate"
                  />
                  <label htmlFor="houseNumber">huisnr. *</label>
                </div>
                <div className="input-field col s4">
                  <input
                    type="text"
                    name="houseNumberExtension"
                    value={locationData.houseNumberExtension || ''}
                    onChange={this.updateLocation}
                    minLength="0"
                    maxLength="20"
                    pattern="[a-zA-Z0-9]{20}"
                  />
                  <label htmlFor="houseNumberExtension">toev.</label>
                </div>
              </form>
            </div>
          </div>

          <div className="col s3">
            <div className="overviewkop">
              <Button
                type="submit"
                waves="light"
                className="doceri-btn-right"
                onClick={() => this.resetDependencies()}
                style={{marginLeft: 20}}
              >
                Tabel resetten
                <Icon right>
                  refresh
                </Icon>
              </Button>
              <Button
                type="submit"
                waves="light"
                className="doceri-btn-right"
                onClick={() => this.getProductDependencies()}
                disabled={submitDisabled}
              >
                Berekenen
                <Icon right>
                  build
                </Icon>
              </Button>
            </div>
          </div>
        </div>

        <div className="row">
          {this.renderModules()}
        </div>

        <div className="row">
          {computedProductList.map((module) => <DataManagementProductsGridComputed module={module}/>)}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({authentication, dataManagement}) => {
  return {
    authentication,
    dataManagement
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    dataManagementActions: bindActionCreators(dataManagementActions, dispatch),
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataManagementProductsForm));
