import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom'

import adminActions from '../../../actions/adminActions';
import partnerServicesActions from '../../../actions/partnerServicesActions';

import FileDownload from "js-file-download";
import ImportPartnerServices from "./ImportPartnerServices";
import DeleteHardwareAndServices from "../DeleteHardwareAndServices";
import PartnerServicesForm from "./PartnerServicesForm";
import PartnerService from "./PartnerService";
import AddPartnerService from "./AddPartnerService";
import {Input} from "react-materialize";
import ProviderHelper from "../../../helper/providerHelper";

class PartnerServicesPanel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            importDialogOpened: false,
            importFailureDialogOpened: false,
            importErrorMessage: '',
            deleteDialogOpened: false,
            manualAdditionDialogOpened: false,
            allowEditOnService: null,
            serviceProvider: 0,
            setToInactive: 0,
            copyProduct: 0
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.serviceProvider !== this.state.serviceProvider) {
            this.getData();
        }
    };

    componentDidMount() {
        this.getData();
    }

    getData() {
        let providerId = this.state.serviceProvider;
        this.props.partnerServicesActions.getAllPartnerServicesProducts(providerId);
        this.props.partnerServicesActions.getProductsToLink(providerId);
        this.props.partnerServicesActions.getServiceOptionCategories();
    }

    onDeleteClick = (id) => {
        const response = this.props.partnerServicesActions.removePartnerService(id, this.state.serviceProvider);
        response.then(() => {
            this.setState({
                deleteDialogOpened: false,
            });
        });
    };

    onEditClick = (serviceId) => {
        this.setState({
            allowEditOnService: serviceId,
        });
    };

    onSaveClick = (id, data) => {
        let serviceData = {
            serviceData: data
        };

        this.props.partnerServicesActions.updateService(id, serviceData, this.state.serviceProvider);

    };


    goToDashboard = () => {
        this.props.history.push('/');
    };

    getExampleFile = () => {
        this.props.partnerServicesActions.getExampleFile().then(() => this.downloadExampleFile());
    };

    getAssortimentFile = () => {
        this.props.partnerServicesActions.getAssortimentFile(this.state.serviceProvider).then(() => this.downloadAssortimentFile());
    };

    downloadExampleFile = () => {
        FileDownload(this.props.partnerServices.exampleFile, 'KPN-EEN-Partner-IT-Diensten.xlsx');
    };

    downloadAssortimentFile = () => {
        FileDownload(this.props.partnerServices.assortimentFile, 'KPN-EEN-assortiment-partnerservices.xlsx');
    };

    _handleLocationDialogOpen = () => {
        this.setState({importDialogOpened: true});
    };

    _handleLocationDialogClose = () => {
        this.setState({importDialogOpened: false});
    };

    _changeOptions = ({target}) => {
        const {name, value} = target;
        this.setState({[name]: value});
    };

    _handleDeletionDialogOpen = () => {
        this.setState({deleteDialogOpened: true});
    };

    _handleDeletionDialogClose = () => {
        this.setState({deleteDialogOpened: false});
    };

    _handleManualAdditionDialogOpen = () => {
        this.setState({manualAdditionDialogOpened: true});
    };

    _handleManualAdditionDialogClose = () => {
        this.setState({manualAdditionDialogOpened: false});
    };

    _onClick = () => {
        const response = this.props.partnerServicesActions.removePartnerServices(this.state.serviceProvider);
        response.then(() => {
            this.setState({
                deleteDialogOpened: false,
            });
        });
    };

    _onAddClick = (data) => {
        data = {newServiceData : data} ;
        const response = this.props.partnerServicesActions.addPartnerService(data, this.state.serviceProvider);
        response.then(() => {
            this.setState({
                manualAdditionDialogOpened: false,
            });
        });
    };

    _onDrop = (files) => {
        let file = files[0];

        if (!file)
            return;

        const response = this.props.partnerServicesActions.uploadBulk(file, this.state.serviceProvider,
            {setToInactive: this.state.setToInactive, copyProduct: this.state.copyProduct});
        response.then((val) => {
            if (val && val.error) {
                this.setState({
                    importErrorMessage: val.error.response.data,
                    importFailureDialogOpened: true
                })
            } else {
                this.setState({file: file});
            }
        });

        this._handleLocationDialogClose();
    };

    handleChangeProvider(event) {
        this.setState({serviceProvider: event.target.value});
    };

    render() {
        let {linked, unlinked} = this.props.partnerServices.allProducts;
        let linkedOptionsExist = linked && linked.length;
        let unlinkedOptionsExist = unlinked && unlinked.length;

        return (
            <div className="col s12">
                <div id="Forms" className="ratiotable paddingbottomnone">
                    <h1>
                        <span className="ratio-section-title">Partner en IT Diensten</span>
                        <div className="hardware-provider">
                            <Input
                                name={'serviceProvider'}
                                id={'serviceProvider'}
                                type="select"
                                multiple={false}
                                required={true}
                                defaultValue={0}
                                onChange={(e) => this.handleChangeProvider(e)}
                                className="validate">
                                <option value="-1" disabled={'disabled'} key={-1}>Kies provider</option>
                                {this.props.authentication.providers.filter((el => el.provider !== 1)).map((el) => <option
                                    value={el.provider}
                                    key={el.provider}>{ProviderHelper.getQuotationProviderName(el.provider)}</option>)}
                            </Input>
                        </div>
                    </h1>
                </div>
                <div className="row">
                    <div className="col s12 admin-top-buttons">
                        <button
                            className="btn doceri-btn-right ratio-btn-right"
                            onClick={() => this.getAssortimentFile()}
                            type="submit"
                            name="action">
                            <i className="small material-icons left white-text">save_alt</i>
                            Download assortiment
                        </button>

                        <button
                            className="btn doceri-btn-right ratio-btn-right"
                            onClick={() => this._handleLocationDialogOpen()}
                            type="submit"
                            name="action">
                            <i className="small material-icons left white-text">add</i>
                            Bulkupload diensten
                        </button>

                        <button
                            className="btn doceri-btn-right kpn-btn-right"
                            onClick={() => this._handleManualAdditionDialogOpen()}
                            type="submit"
                            name="action">
                            <i className="small material-icons left white-text">add_circle_outline</i>
                            Voeg dienst toe
                        </button>

                        <button
                            className="btn doceri-btn-right ratio-btn-right"
                            onClick={() => this._handleDeletionDialogOpen()}
                            type="submit"
                            name="action">
                            <i className="small material-icons left white-text">delete</i>
                            Verwijder alles
                        </button>
                    </div>
                </div>

                {linkedOptionsExist &&
                <div className="row mainProductRow">
                    <h1 style={{borderBottom: '1px solid black'}}>Gekoppelde options</h1>

                    {this.props.partnerServices.allProducts.linked.map((product, index) => {
                        return <PartnerServicesForm
                            key={index}
                            product={product}
                            onDelete={this.onDeleteClick}
                            onSave={this.onSaveClick}
                            onEdit={this.onEditClick}
                        />
                    })}
                </div>
                }

                {unlinkedOptionsExist &&
                <div className="row">

                    <h1 style={{borderBottom: '1px solid black'}}>Ongekoppelde options</h1>

                    {this.props.partnerServices.allProducts.unlinked.map((service, index) => {
                        return (
                            <div className="row">
                                <PartnerService
                                    key={index}
                                    service={service}
                                    isLinked={false}
                                    allowEditOnService={this.state.allowEditOnService}
                                    onDelete={this.onDeleteClick}
                                    onSave={this.onSaveClick}
                                    onEdit={this.onEditClick}
                                />
                            </div>);
                    })}

                </div>
                }

                <ImportPartnerServices dialogOpened={this.state.importDialogOpened}
                                       onRequestClose={this._handleLocationDialogClose}
                                       changeOptions={this._changeOptions}
                                       save={this.save}
                                       onDrop={this._onDrop}
                                       droppedFile={this.state.file}
                />

                <AddPartnerService dialogOpened={this.state.manualAdditionDialogOpened}
                                   onRequestClose={this._handleManualAdditionDialogClose}
                                   tab={"partner en IT Diensten"}
                                   onClickFunc={this._onAddClick}
                                   productsToLink={this.props.partnerServices.productsToLink.products}
                                   categories={this.props.partnerServices.serviceOptionCategories.categories}
                />

                <DeleteHardwareAndServices dialogOpened={this.state.deleteDialogOpened}
                                           onRequestClose={this._handleDeletionDialogClose}
                                           tab={"partner en IT Diensten"}
                                           onClickFunc={this._onClick}
                />

            </div>
        );
    }
}

const mapStateToProps = ({authentication, partnerServices}) => {
    return {
        authentication: authentication,
        partnerServices: partnerServices,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        adminActions: bindActionCreators(adminActions, dispatch),
        partnerServicesActions: bindActionCreators(partnerServicesActions, dispatch)
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerServicesPanel));
