import {actionTypes} from '../constants/actionTypes';
import {apiRoutes} from '../constants/apiRoutes';
import {toastr} from 'react-redux-toastr'

import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

const getAllDigitalSigningFields = (quotation) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.digitalSigningFieldRelated.getAll.replace('{quotation}', quotation);
            let requestData = await ApiService.performRequest(RequestType.GET, url);

            dispatch(getAllDigitalSigningFieldsSuccess(requestData));
        } catch ({message}) {
            toastr.error('Het is niet mogelijk de lijst met offertes op te halen', message);
        }
    }
};

const getAllDigitalSigningFieldsSuccess = ({digitalSigningFields}) => {
    return {
        type: actionTypes.getDigitalSigningFields,
        payload: digitalSigningFields
    };
};

const updateDigitalSigningField = (field, data) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.digitalSigningFieldRelated.updateDigitalSigningField.replace('{field}', field);
            const response = await ApiService.performRequest(RequestType.PATCH, url, data);

            dispatch(_patchSigningFieldSuccess(response));
        } catch ({message}) {
            toastr.error('The signing field failed updated', message);
        }
    }
};

const _patchSigningFieldSuccess = ({signingField}) => {
    return {
        type: actionTypes.updateDigitalSigningField,
        payload: signingField
    }
};

const addDigitalSigningField = (data) => {
    return async () => {
        try {
            const url = apiRoutes.digitalSigningFieldRelated.addDigitalSigningField;
            await ApiService.performRequest(RequestType.POST, url, data);

            toastr.success('Digital signing field bewerkt', '');
        } catch ({message}) {
            toastr.error('Kan de signing field niet updaten', message);
        }
    }
};

const deleteSigningField = (signingField) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.digitalSigningFieldRelated.deleteDigitalSigningField.replace(/{field}/, signingField.id);
            await ApiService.performRequest(RequestType.DELETE, url);

            dispatch(deleteSigningFieldSuccess(signingField));
        } catch (error) {
            toastr.error('Failed to delete the signing field', error);
            return {error};
        }
    }
};

const deleteSigningFieldSuccess = (signingField) => {
    return {
        type: actionTypes.deleteDigitalSigningField,
        payload: signingField
    }
};

const setCurrentSigningGroup = (signingGroup) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.setCurrentSigningGroup,
            payload: signingGroup
        });
    }
};

const sortDigitalSigningFields = (data) => {
    return async () => {
        try {
            const url = apiRoutes.digitalSigningFieldRelated.sortDigitalSigningField;
            await ApiService.performRequest(RequestType.POST, url, data);
        } catch ({message}) {
            toastr.error('Sorting digital signing fields failed', message);
        }
    }
};

export default {
    getAllDigitalSigningFields,
    addDigitalSigningField,
    updateDigitalSigningField,
    deleteSigningField,
    setCurrentSigningGroup,
    sortDigitalSigningFields
}
