import {
  actionTypes
} from '../constants/actionTypes';

const initialState = {
  apiStatusList: [],
};

export default (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.getApiStatus:
      return {
        ...state,
        apiStatusList: action.payload
      };
    default:
      return state;
  }
}
