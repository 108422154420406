import React, {Component} from 'react';
import DataManagementGrid from "./DataManagementGrid";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import adminActions from "../../../actions/adminActions";
import DataManagementProductsGrid from "./DataManagementProductsForm";

class DataManagementPanel extends Component {
  tableHeaderNames = ['Categorie', 'Omschrijving', 'Acties'];
  rows = [
      {name: 'Tag management', description: 'Manage word tags'},
      {name: 'Postcodes uitsluiten', description: 'Zorgt ervoor dat bepaalde postcodes worden geweigerd'}
  ];

  onEdit = (row) => {
      if (row.name === 'Tag management') {
          this.props.adminActions.setCurrentSection('tags-management');
      } else {
          this.props.adminActions.setCurrentSection('refused-postcode-management');
      }
  };

  render() {
    return (
      <div>
        <div id="Forms" className="ratiotable paddingbottomnone">
          <h1 className="admin-subheader-text">
            <span className="ratio-section-title">Data management</span>
          </h1>
        </div>

        <DataManagementGrid
          headerNames={this.tableHeaderNames}
          rows={this.rows}
          onEditClick={this.onEdit}
        />

        <DataManagementProductsGrid />
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {
      adminActions: bindActionCreators(adminActions, dispatch)
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataManagementPanel));
