import styled from 'styled-components';
import { SketchPicker } from 'react-color';

export default styled(SketchPicker)`
  div[title=transparent] {
    overflow: hidden;
    :before {
      content: '✕';
      display: block;
      position: absolute;
      color: #D0021B;
      width: 100%;
      height: 100%;
      line-height: 100%;
      font-size: 16px;
      text-align: center;
    }
  }
`;