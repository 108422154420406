import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Checkbox} from "material-ui";
import organizationActions from "../../../actions/organizationActions";
import FileDownload from "js-file-download";
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from "material-ui/Table";
import {Icon, Input} from "react-materialize";
import RatioOverflowTableWrapper
    from "../../../components/styled-components/ratio-overflow-table-wrapper/RatioOverflowTableWrapper";
import TermsAndConditionsForm from "./TermsAndConditionsForm";
import termsAndConditionsActions from "../../../actions/termsAndConditionsActions";
import './index.css';
import ProviderHelper from "../../../helper/providerHelper";
import Pagination from "../../../components/pagination/pagination";
import KpnDialog from "../../common/KpnDialog/KpnDialog";
import DialogHeader from "../../common/KpnDialog/DialogHeader";
import DialogBody from "../../common/KpnDialog/DialogBody";
import DialogFooter from "../../common/KpnDialog/DialogFooter";
import DialogButton from "../../common/KpnDialog/DialogButton";

const HeadersNames = [
    'Naam',
    'Provider',
    'Actief',
    'Aangemaakt',
    'Aangemaakt door',
    'Laatst gewijzigd door',
    'Acties'
];

class TermsAndConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            downloadFile: {
                id: null,
                name: null,
                file: '',
            },
            newDocument: {
                id: null,
                name: null,
                file: '',
            },
            isOrganizationTermsAndConditionsEnabled: false,
            termsAndConditionFiles: [],
            formDialogOpened: false,
            selectedDocument: null,
            provider: null,
            deleteDialogOpened: false
        }
    }

    componentDidMount() {
        this.setState({provider: this.props.authentication.providers[0].provider})
        this.props.termsAndConditionsActions.getTermsAndConditionProductCategories();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.provider !== this.state.provider) {
            this.retrieveOrganizationTermsAndConditionsFiles();
        }
    }

    componentWillMount() {
        this.props.organizationActions.getOrganizationDetails()
            .then(() => this.setState({isOrganizationTermsAndConditionsEnabled: this.props.organizations.organization.has_terms_and_conditions}))
    }

    retrieveOrganizationTermsAndConditionsFiles = (page) => {
        this.setState({loading: true});
        const {provider} = this.state;

        let queryParams = '';

        if (page) {
            queryParams += (queryParams.match(/\?/) ? '&' : '?');
            queryParams += 'page=' + page
        }

        this.props.termsAndConditionsActions.getOrganizationTermsAndConditionsFiles(queryParams, provider);
    }

    generateIsFileActiveText = (isFileActive) => {
        return isFileActive ? 'Actief' : 'Inactief';
    }

    generateRow = (row) => {
        return (
            <TableRow key={row.id}>
                <TableRowColumn>{row.name}</TableRowColumn>
                <TableRowColumn style={{width: "10%"}}>{ProviderHelper.getQuotationProviderName(row.provider)}</TableRowColumn>
                <TableRowColumn style={{width: '10%'}}>{this.generateIsFileActiveText(row.isFileActive)}</TableRowColumn>
                <TableRowColumn style={{width: "10%"}}>{new Date(row.created_at).toLocaleString('nl-NL')}</TableRowColumn>
                <TableRowColumn style={{width: "10%"}}>{row.createdBy ? row.createdBy : ''}</TableRowColumn>
                <TableRowColumn style={{width: "10%"}}>{new Date(row.updated_at).toLocaleString('nl-NL')}</TableRowColumn>
                <TableRowColumn>
                    <button className="btn table-actions" onClick={() => this.downloadActiveTermsAndConditionsFile(row)}>
                        <Icon>cloud_download</Icon>
                    </button>
                    <button className="btn table-actions" onClick={() => this.onEditClick(row)}>
                        <Icon>edit</Icon>
                    </button>
                    <button className="btn table-actions" onClick={() => this.onDeleteClick(row)}>
                        <Icon>delete</Icon>
                    </button>
                </TableRowColumn>
            </TableRow>
        )
    }

    generateHeader = () => {
        return (
            HeadersNames.map((headerName) => {
                if (['Aangemaakt door', 'Laatst gewijzigd door', 'Actief', 'Provider', 'Aangemaakt', 'Laatst bewerkt'].includes(headerName)
                ) {
                    return <TableHeaderColumn key={headerName} style={{width: "10%"}}>{headerName}</TableHeaderColumn>
                }

                return <TableHeaderColumn key={headerName}>{headerName}</TableHeaderColumn>
            })
        );
    }

    onSubmit = (data) => {
        this.props.termsAndConditionsActions.addOrganizationTermsAndConditionFile(data)
          .then(() => this.closeDialog())
          .then(() => this.retrieveOrganizationTermsAndConditionsFiles());
    }

    onCheckTermsAndConditions = ( {target: { checked }}) => {
        this.setState({isOrganizationTermsAndConditionsEnabled: checked}, () => {
            this.enableTermsAndConditions();
        })
    }

    enableTermsAndConditions = () => {
        this.props.termsAndConditionsActions.enableOrganizationTermsAndConditions(this.state.isOrganizationTermsAndConditionsEnabled)
            .then((response) => {
                if (response) {
                    this.setState({isOrganizationTermsAndConditionsEnabled: response.value})
                }
            })
    }

    downloadActiveTermsAndConditionsFile = (row) => {
        this.props.termsAndConditionsActions.downloadTermsAndConditionsFile(row.id).then((response) => {
              if (response) {
                  FileDownload(this.props.termsAndConditions.tcFileBlob, row.name + '.pdf');
              }
          });
    }

    onEditClick = (row) => {
        this.openDialog(row)
    }

    onDeleteSubmit = () => {
        this.props.termsAndConditionsActions.deleteTermsAndConditionsFile(this.state.selectedDocument.id);
        this.closeDeleteDialog();
    };

    closeDeleteDialog = () => {
        this.setState({
            deleteDialogOpened: false
        });
    };

    onDeleteClick = (tcFile) => {
        this.setState({
            deleteDialogOpened: true,
            selectedDocument: tcFile
        });
    };

    openDialog = (document) => {
        this.setState({
            formDialogOpened: true,
            selectedDocument: document
        })
    };

    closeDialog = () => {
        this.setState({
            formDialogOpened: false
        })
    };

    handleChangeProvider = ({target}) => {
        const provider = target.value;

        this.setState({ provider: provider});
    };

    changePage = ({target}) => {
        const {value} = target;
        this.setState({page: value});


        this.retrieveOrganizationTermsAndConditionsFiles(value);
    };

    render() {
        let {tcFiles, currentPage, pages, tcProductCategories} = this.props.termsAndConditions;
        const {providers} = this.props.authentication;

        return (
            <div className="terms-and-conditions-container">
                <div id="Forms" className="ratiotable paddingbottomnone">
                    <h1 className="admin-subheader-text"><span className="ratio-section-title">Algemene voorwaarden</span></h1>
                    <button
                        className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
                        onClick={() => this.openDialog(this.state.newDocument)}
                        type="submit"
                        name="action">
                        <i className="small material-icons left white-text">add</i>
                        Voeg nieuw word document toe
                    </button>
                </div>
                <div className="Forms">
                    <h1 className='ratio-section-title'>
                        <i className="small material-icons left">
                            settings
                        </i>
                        Instelligen algemene voorwaarden
                    </h1>
                    <div className="terms-and-conditions-actions">
                        <div>
                            <Checkbox
                                checked={this.state.isOrganizationTermsAndConditionsEnabled}
                                label="Activeer/Deactiveer algemene voorwaarden"
                                onCheck={this.onCheckTermsAndConditions}
                                className="enable-terms-checkbox"
                            />
                        </div>
                    </div>

                    <div className="ratiotable row">
                        <div className="col s6">
                            <div style={{ top: '25px', position: 'relative'}}>
                                <Input
                                  type="select"
                                  id="provider"
                                  name="provider"
                                  required={true}
                                  multiple={false}
                                  defaultValue={-1}
                                  className="validate"
                                  label="Provider"
                                  onChange={(e) => this.handleChangeProvider(e)}
                                  value={this.state.provider}
                                >
                                    <option value={-1} disabled={'disabled'} key={-1}>Kies provider</option>
                                    {
                                        providers.filter((el => el.provider !== 1)).map((el) => {
                                            return (
                                              <option value={el.provider} key={el.provider}>
                                                  {ProviderHelper.getQuotationProviderName(el.provider)}
                                              </option>
                                            );
                                        })
                                    }
                                </Input>
                            </div>
                        </div>
                    </div>

                    <RatioOverflowTableWrapper>
                        <div className="valign-wrapper">
                            <Table selectable={false}>
                                <TableHeader displaySelectAll={false} adjustForCheckbox={false} >
                                    <TableRow>
                                        {this.generateHeader()}
                                    </TableRow>
                                </TableHeader>
                                <TableBody displayRowCheckbox={false}>
                                    {tcFiles?.map(row => this.generateRow(row))}
                                </TableBody>
                            </Table>
                        </div>
                    </RatioOverflowTableWrapper>

                    {
                        this.state.formDialogOpened &&
                            <TermsAndConditionsForm
                                document={this.state.selectedDocument}
                                onClose={this.closeDialog}
                                onSubmit={this.onSubmit}
                                providers={this.props.authentication.providers}
                                productCategories={tcProductCategories}
                            />
                    }

                    <div className="col s12 sendendform">
                        <div className="col s12 m10">
                            <Pagination page={currentPage}
                                        pages={pages}
                                        changePage={this.changePage}
                            />
                        </div>
                    </div>
                    <KpnDialog
                      dialogState={this.state.deleteDialogOpened}
                      onRequestClose={() => { this.closeDeleteDialog() }}
                      modal={true}
                      dialogHeader={(
                        <DialogHeader
                          headerIcon="remove_circle_outline"
                          headerText="Bevestig verwijdering"
                        />
                      )}

                      dialogBody={
                          <DialogBody>
                              <div className="col s12">
                                  {
                                      this.state.selectedDocument?.isFileActive ?
                                        "U staat op het punt een actief bestand te verwijderen. Weet u zeker dat u wilt doorgaan?" :
                                        "U staat op het punt een inactief bestand te verwijderen. Weet u zeker dat u wilt doorgaan?"
                                  }
                                  <br /><br />
                              </div>
                          </DialogBody>
                      }
                      dialogFooter={
                          <DialogFooter
                            buttonLeft={(
                              <DialogButton
                                buttonAction={() => { this.closeDeleteDialog() }}
                                buttonText='Nee'
                                left={true}
                              />
                            )}
                            buttonRight={(
                              <DialogButton
                                buttonAction={this.onDeleteSubmit}
                                buttonText='Ja'
                              />
                            )}
                          />
                      }
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({organizations, authentication, termsAndConditions}) => {
    return {
        organizations,
        authentication,
        termsAndConditions
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        organizationActions: bindActionCreators(organizationActions, dispatch),
        termsAndConditionsActions: bindActionCreators(termsAndConditionsActions, dispatch)
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions));