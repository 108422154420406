import React, { Component } from 'react';
import {connect} from "react-redux";
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
/*import reactTextEditorToolBarOptions from "../constants/reactTextEditorToolBarOptions";*/

class RatioTextEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
      content: null
    };
  }

  componentDidMount() {
    this.initializeBlocks(this.props.data);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.initializeBlocks(this.props.data);
    }
  }

  initializeBlocks = (data) => {
    const html = data.value;
    const contentBlock = htmlToDraft(html);
    const {contentBlocks, entityMap} = contentBlock;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    this.setState({editorState});
  };

  onEditorStateChange = (editorState) => {
    const contentState = editorState.getCurrentContent();
    this.saveContent(contentState);
    this.setState({
      editorState,
    });
  };

  saveContent = (content) => {
    this.setState({content: convertToRaw(content)})
  };

  updateProperty = () => {
    const { content } = this.state;
    let htmlValue = draftToHtml(content);
    let data = {
      target: {
        name: '',
        value: htmlValue
      }
    };

    this.props.updateFunction(data, this.props.data.id);
  };

  render() {
    const { editorState } = this.state;

    return (
      <div className="input-field" style={{border: 'solid 1px #939393'}}>
        <Editor
          editorState={editorState}
          wrapperClassName="ratio-wrapper-editor"
          editorClassName="ratio-editor"
          onEditorStateChange={this.onEditorStateChange}
          onBlur={this.updateProperty}
          //toolbar={reactTextEditorToolBarOptions}
          toolbar={{
            options: ['inline', 'list'],
            inline: {
              inDropdown: false,
              options: ['bold', 'italic', 'underline'],
            },
            list: {
              inDropdown: false,
              options: ['unordered'],
            }
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RatioTextEditor);
