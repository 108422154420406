import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './pagination.css';

class Pagination extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1
        };

        this.setPage = this.setPage.bind(this);
    }

    setPage(event) {
        let value = parseInt(event.target.value, 10) || 1;
        value = Math.min(value, this.props.pages);

        this.setState({page: value});
    }

    /**
     * Generates the list of pages used for pagination
     *
     * @returns {[]}
     */
    generatePaginationPages() {
        const pagesPaddingLeft = 1;
        const pagesPaddingRight = 2;

        let options = [];
        let previousPageIsSkipped = false;
        for (let i = 1; i <= this.props.pages; i++) {

            // Check if the pages should be skipped
            if (![1, this.props.pages, this.props.page].includes(i) && // first, last & current pages should always be shown
                (
                    i < (this.props.page - pagesPaddingLeft) || // padding left
                    i > (this.props.page + pagesPaddingRight)   // padding right
                )
            ) {
                if (!previousPageIsSkipped) {
                    // We only need to add the dots once, no matter how many pages are skipped
                    previousPageIsSkipped = true;

                    options.push(
                        <li key={i}>...</li>
                    )
                }
            } else {
                // Show the normal pages
                previousPageIsSkipped = false;

                options.push(
                    <li key={i}
                        className={"waves-effect" + (this.props.page === i ? ' active' : '')}
                        onClick={() => this.props.changePage({target: {value: i}})}
                    >
                        <a href="#!">{i}</a>
                    </li>)
            }
        }

        return options;
    }

    render() {
        // Don't show the pagination if there's no pages to be shown
        if (!this.props.page || !this.props.pages || this.props.pages === 1) {
            return null;
        }

        return (
            <div className="pagination-wrapper">
                <div className="pagination-page-wrapper">
                    <span>Ga direct naar pagina</span>
                    <input type="number" min="1" max={this.props.pages} value={this.state.page} onChange={this.setPage}/>
                    <a href="#!" onClick={() => this.props.changePage({target: {value: this.state.page}})}>
                        <i className="material-icons">chevron_right</i>
                    </a>
                </div>

                <ul className="pagination" style={{'textAlign': 'center'}}>
                    <li className={"waves-effect " + (this.props.page === 1 ? 'disabled' : '')}
                        onClick={() => this.props.page > 1 ? this.props.changePage({target: {value: this.props.page - 1}}) : ''}
                    >
                        <a href="#!">
                            <i className="material-icons">chevron_left</i>
                        </a>
                    </li>

                    {this.generatePaginationPages()}

                    <li
                        className={"waves-effect " + (this.props.page === this.props.pages ? 'disabled' : '')}
                        onClick={() => this.props.page !== this.props.pages ? this.props.changePage({target: {value: this.props.page + 1}}) : ''}
                    >
                        <a href="#!">
                            <i className="material-icons">chevron_right</i>
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

Pagination.propTypes = {
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    changePage: PropTypes.func.isRequired
};

export default Pagination;