import React, {Component} from 'react';
import {poll} from "../../utils/utils";
import {bindActionCreators} from "redux";
import {toastr} from "react-redux-toastr";
import connect from "react-redux/es/connect/connect";
import {Input, Row, Col, Preloader} from "react-materialize";

import ReactTable from 'react-table';
import "react-table/react-table.css";
import 'react-virtualized/styles.css';
import {AutoSizer, Column, Table} from "react-virtualized";

import stepsActions from "../../actions/stepsActions";
import quotationActions from "../../actions/quotationActions";
import mobileActions from "../../actions/workflow/mobileActions";

import LoaderComponent from "../../containers/common/LoaderComponent";

import {apiRoutes} from "../../constants/apiRoutes";
import orderState from "../../constants/orderState";
import organization from "../../constants/organization";
import disabledOrderStates from "../../constants/disabledOrderStates";

class ExistingMobileData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      upgradingData: this.props.upgradingData,
      overallStatus: {
        extend: false,
        upgrade: false,
        terminate: false,
        no_action: false,
        addition: false,
        addition_vamo: false,
        addition_data_sim: false,
        retention: false,
      },
      loading: false,
      isDataSaved: this.props.isDataSaved,
      dataRetrieval: false,
      dataProcessing: false,
    }
  }

  componentDidMount() {
    const {quotationId} = this.props;
    this.props.quotationActions.getPhoneNumbers(quotationId)
      .then(() => this.fixHeaderCheckbox());

    this.props.mobileActions.hasPendingUpgradeData(quotationId)
      .then(() => this.continuePoll());

    this.setState({dataRetrieval: this.props.dataRetrievalInProcess})
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data,
      });
    }

    if (prevProps.upgradingData !== this.props.upgradingData) {
      this.setState({
        upgradingData: this.props.upgradingData,
      });
    }

    if (prevProps.isDataSaved !== this.props.isDataSaved) {
      this.setState({
        isDataSaved: this.props.isDataSaved,
      });
    }

    if (prevProps.dataRetrievalInProcess !== this.props.dataRetrievalInProcess) {
      this.setState({dataRetrieval: this.props.dataRetrievalInProcess})
    }
  }

  switchKeys(product, column, data = null) {
    let value = data ? data[column] : !product[column];
    if (value === false) {
      value = null;
    }

    if (['addition_vamo', 'addition_data_sim'].includes(column)) {
      product[column] = value;
      product['addition'] = product['addition_vamo'] || product['addition_data_sim'];
      product['terminate'] = product['terminate'] === false ? false : null;

      if (!product['terminate'] && !product['upgrade'] && !product['extend'] && !product['no_action'] && !product['retention']) {
        product['no_action'] = true;
      }

      return;
    }

    product['terminate'] = product['terminate'] === false ? false : null;
    product['upgrade'] = product['upgrade'] === false ? false : null;
    product['extend'] = product['extend'] === false ? false : null;
    if (column === 'terminate') {
      product['addition_vamo'] = product['addition_vamo'] === false ? false : null;
      product['addition_data_sim'] = product['addition_data_sim'] === false ? false : null;
    }
    product['retention'] = product['retention'] === false ? false : null;

    if (product['no_action']) {
      product['no_action'] = product['no_action'] === false ? false : null;
    }

    product[column] = value;
    product['addition'] = product['addition_vamo'] || product['addition_data_sim'];

    if (!product['terminate'] && !product['upgrade'] && !product['extend'] && !product['no_action'] && !product['retention']) {
      product['no_action'] = true;
    }
  }

  toggleSelect(column, data) {
    this.props.toggleIsDataSaved(false);

    let c2 = null;
    let modifiedData = [];
    modifiedData.push(data);

    if (data['terminate'] === false) {
      c2 = 'terminate';
    }
    if (data['upgrade'] === false) {
      c2 = 'upgrade';
    }
    if (data['extend'] === false) {
      c2 = 'extend';
    }
    if (data['no_action'] === false) {
      c2 = 'no_action';
    }
    if (data['addition_vamo'] === false) {
      c2 = 'addition_vamo';
    }
    if (data['addition_data_sim'] === false) {
      c2 = 'addition_data_sim';
    }
    if (data['retention'] === false) {
      c2 = 'retention';
    }

    this.switchKeys(data, column);
    data[c2] = false;
    let payload = {category: false, data};
    let toggleFailed = false;
    this.setState({dataProcessing: true});

    this.props.quotationActions.updatePhoneNumbers(payload, this.props.quotationId, true)
      .then((response) => {
        if (!response || (response && response.message)) {
          toggleFailed = true;
        } else {
          this.props.quotationActions.getPhoneNumbers(this.props.quotationId)
        }
      })
      .then(() => {
        if (this.props.mobileOfferYear) {
          this.props.mobileActions.mobileOfferProducts(this.props.mobileOfferYear, this.props.quotationId, this.props.locationId)
        }
      })
      .then(() => this.setState({dataProcessing: false}))
      .then(() => this.props.toggleIsDataSaved(true))
      .then(() => {
        if (!toggleFailed) {
          this.setState({data});
        } else {
          this.revertKeys(modifiedData, data);
        }
      }).then(() => this.fixHeaderCheckbox());
  };

  fixHeaderCheckbox() {
    let extendCount = 0;
    let upgradeCount = 0;
    let terminateCount = 0;
    let noActionCount = 0;
    let additionVamoCount = 0;
    let additionDataSimCount = 0;
    let retentionCount = 0;
    let products = this.state.data.filter((el) => el.type === 'mobile' && !disabledOrderStates.includes(el.order_state));
    let productsCount = products.length;

    products.forEach((el) => {
      if (el.extend) {
        extendCount++;
      } else if (el.upgrade) {
        upgradeCount++;
      } else if (el.terminate) {
        terminateCount++;
      } else if (el.no_action) {
        noActionCount++;
      } else if (el.retention) {
        retentionCount++;
      }

      if (el.addition_data_sim) {
        additionDataSimCount++;
      }
      if (el.addition_vamo) {
        additionVamoCount++;
      }
    });

    this.setState({
      overallStatus: {
        extend: extendCount === productsCount,
        upgrade: upgradeCount === productsCount,
        terminate: terminateCount === productsCount,
        no_action: noActionCount === productsCount,
        addition_vamo: additionVamoCount === productsCount,
        addition_data_sim: additionDataSimCount === productsCount,
        retention: retentionCount === productsCount,
      }
    })
  }

  toggleSelectAll(column, original = null) {
    this.setState({dataProcessing: true});
    this.props.toggleIsDataSaved(false);

    let {overallStatus} = this.state;
    let quotationId = this.props.quotation.currentQuotation.id;
    let {mobileOfferYear} = this.props.currentQuotation;
    let selectedLocation = this.props.selectedLocation;
    let isUpgradeAction = column === 'upgrade';

    this.switchKeys(typeof original !== 'boolean' ? original : overallStatus, column);

    let data = this.state.data.filter((el) => el.id === original.id);
    if (typeof original === 'boolean') {
      data = this.state.data
        .filter((el) => el.type === 'mobile' && !disabledOrderStates.includes(el.order_state))
        .map((el) => {
          if (el[column] !== false) {
            this.switchKeys(el, column, overallStatus);
          }
          return el;
        })
    }

    let orderIds = [];
    if (isUpgradeAction) {
      orderIds = data.filter((phoneOrder) => !phoneOrder.upgrade_data_retrieved && phoneOrder.upgrade)
        .map((phoneOrder) => phoneOrder.order_id);
      if (orderIds.length) {
        this.initiateUpgradeDataRetrieval(quotationId, orderIds);
      }
    }

    this.props.quotationActions.updatePhoneNumbers({category: true, data}, quotationId, true)
      .then(() => {
        if (!(isUpgradeAction && orderIds.length)) {
          this.props.getMobileProducts(mobileOfferYear, selectedLocation)
        }
      })
      .then(() => this.setState({dataProcessing: false, overallStatus}))
      .then(() => this.props.toggleIsDataSaved(true))
      .then(() => this.fixHeaderCheckbox());
  }

  revertKeys = (modifiedData, data) => {
    let reversedData = data.map((el) => {
      let identified = modifiedData.find((modified) => modified.id === el.id);
      return identified ? identified : el;
    });

    this.setState({data: reversedData});
  };

  initiateUpgradeDataRetrieval = (quotationID, data) => {
    this.setDataRetrievalAlert();
    this.props.quotationActions.updatePhoneNumberActionStatus(data);
    this.props.mobileActions.updateDataRetrieval(data);

    let {pendingDataRetrieval, selectedLocation, currentQuotation} = this.props;
    let {mobileOfferYear} = currentQuotation;
    let isPollEmpty = !(pendingDataRetrieval && pendingDataRetrieval.length);

    const url = apiRoutes.mobileRelated.getUpgradeMobileData.replace('{quotationId}', quotationID);
    this.props.mobileActions.getUpgradeOptions(quotationID, data)
      .then(() => {
        if (isPollEmpty) {
          poll(url).then(() => {
            this.checkDataRetrievalStatus(true);
            this.props.getMobileProducts(mobileOfferYear, selectedLocation, true);
          });
        }
      });
  };

  continuePoll = () => {
    let {pendingDataRetrieval, selectedLocation, currentQuotation} = this.props;
    let {mobileOfferYear} = currentQuotation;
    let quotationId = currentQuotation.id;
    if (!pendingDataRetrieval || (pendingDataRetrieval && !pendingDataRetrieval.length)) {
      return;
    }

    this.setDataRetrievalAlert();

    const url = apiRoutes.mobileRelated.getUpgradeMobileData.replace('{quotationId}', quotationId);

    poll(url)
      .then(() => {
        this.checkDataRetrievalStatus(true);
        this.props.getMobileProducts(mobileOfferYear, selectedLocation);
        this.props.mobileActions.updateDataRetrieval([]);
      })
      .catch((e) => toastr.error('Kan bestaande gegevens niet ophalen', e.message));
  };

  checkDataRetrievalStatus = (knownStatus) => {
    let {pendingDataRetrieval} = this.props;

    if ((pendingDataRetrieval && !pendingDataRetrieval.length) || knownStatus) {
      this.props.quotationActions.resetSoftAlerts();
      return;
    }

    this.setDataRetrievalAlert();
  };

  setDataRetrievalAlert = () => {
    let alertMessage = 'We zijn nog druk bezig alle bestaande orders op te halen. Je kunt gewoon door met je offerte, maar alle bestaande producten zijn misschien nog niet geladen';
    this.props.quotationActions.addSoftAlert(alertMessage);
  };

  getProductName = (product) => {
    if (product.type === 'mobile addons') {
      return <div style={{fontStyle: 'italic'}}>{product.product_name}</div>
    }

    return <div>{product.product_name}</div>
  };

  render() {
    const {inputsDisabled, mainActionsView} = this.props;
    const {company} = this.props.authentication;

    let data = this.state.data;
    let statuses;

    const isVerlengenType = this.props.quotation.currentQuotation.type === 5;
    const dataProfiles = data.filter(el => !el.parent_id);
    const dataAddons = data.filter(el => !!el.parent_id);
    const defaultExpandedRows = dataProfiles.map((element, index) => ({index: true}));

    dataAddons.sort((a, b) => a.product_name.localeCompare(b.product_name));

    if (mainActionsView) {
      statuses = {
        no_action: 'Geen actie',
        extend: 'Verlengen',
        upgrade: 'Up/downgraden',
        terminate: 'Opzeggen',
        retention: 'Retentie'
      };

      if (!isVerlengenType) {
        delete statuses.no_action;
      }

      if ([organization.SV, organization.KPN_RETAIL].includes(company)) {
        delete statuses.terminate;
        delete statuses.extend;
        delete statuses.upgrade;
      } else {
        delete statuses.retention;
      }
    } else {
      statuses = {
        addition_vamo: 'VAMO',
        addition_data_sim: 'Data sim'
      }
    }

    let overallStatus = this.state.overallStatus;

    let columns = [
      {
        expander: true,
        show: false,
      },
      {
        id: 'number',
        accessor: 'number',
        Cell: ({original, index}) => {
          return (
            <span>
              {!original.parent_id ? original.phone_number : null}
            </span>
          )
        },
        Header: 'Telefoonnummer',
        headerClassName: 'highlight',
        headerStyle: {textAlign: 'left'},
        maxWidth: 200,
        textAlign: 'left',
        fontWeight: 'bold',
      },
      {
        id: 'product_name',
        accessor: 'product_name',
        Cell: ({original, index}) => {
          return (
            <span style={{
              fontStyle: `${!original.parent_id ? 'normal' : 'italic'}`
            }}>
              {original.quantity > 1 && `(${original.quantity}x) `}
              {original.product_name}
            </span>
          )
        },
        Header: 'Product',
        headerClassName: 'highlight',
        headerStyle: {textAlign: 'left'},
        maxWidth: 400,
        textAlign: 'left',
        fontWeight: 'bold',
        resizable: false,
        sortable: true,
      },
    ];

    if (isVerlengenType && mainActionsView) {
      columns.push({
        id: 'irma_order_status',
        accessor: 'irma_order_status',
        Cell: ({original}) => {
          return (<span>{original.order_state || ''}</span>)
        },
        Header: 'IRMA Order status',
        headerClassName: 'highlight',
        headerStyle: {textAlign: 'left'},
        maxWidth: 200,
        textAlign: 'left',
        fontWeight: 'bold',
        sortable: false,
      });
    }

    for (let checkboxKey in statuses) {
      columns.push({
        id: checkboxKey,
        accessor: "",
        Cell: ({original, index}) => {
          const hasParent = !!original.parent_id;
          const hasTerminateState = original.order_state === orderState.ORDER_STATE_TERMINATE;

          if (hasParent && isVerlengenType) {
            return <span/>;
          }
          if (hasTerminateState && checkboxKey !== 'no_action') {
            return <span/>;
          }
          if (original.upgrade === false && checkboxKey === 'upgrade') {
            return <span/>;
          }

          return (
            <Input
              type="checkbox"
              name={`${checkboxKey}-${original.id}`}
              label=" "
              className="checkbox"
              disabled={inputsDisabled}
              checked={original[checkboxKey] === true}
              onChange={() => isVerlengenType ? this.toggleSelectAll(checkboxKey, original) : this.toggleSelect(checkboxKey, original)}
            />
          );
        },
        Header: () => {
          return (
            <div>
              <div>
                <Input
                  type="checkbox"
                  label={statuses[checkboxKey]}
                  name={`all_${checkboxKey}`}
                  className="checkbox"
                  disabled={inputsDisabled}
                  checked={overallStatus[checkboxKey]}
                  onChange={() => this.toggleSelectAll(checkboxKey, overallStatus[checkboxKey])}
                />
              </div>
            </div>
          );
        },
        sortable: false,
        resizable: false,
        className: 'col-checkbox',
        headerClassName: 'head-checkbox',
        minWidth: 40,
      });
    }

    const {theme} = this.props.authentication;

    return (
      <div style={{height: '600px'}}>
        {this.state.loading ?
          <LoaderComponent theme={theme}/> :
          <Col s={12} style={{height: '600px'}}>
            {(this.state.dataRetrieval || this.state.dataProcessing) &&
            (
              <div className="loader-component" style={{zIndex: 99, top: "10%"}}>
                <div className="overlay" style={{backgroundColor: `rgba(255, 255, 255, 0.3)`}}/>
                <Preloader size='medium'/>
              </div>
            )
            }

            {
              isVerlengenType ?
                <AutoSizer>
                  {({width, height}) => (
                    <Table
                      width={width}
                      height={height}
                      headerHeight={40}
                      rowHeight={40}
                      rowCount={data.length}
                      rowGetter={({index}) => data[index]}
                      className='striped'
                    >
                      <Column
                        label='Telefoonnummer'
                        dataKey='phone_number'
                        width={width / 4}
                      />
                      <Column
                        width={width / 2}
                        label='Product'
                        dataKey='product_name'
                        cellRenderer={({rowData}) => {return this.getProductName(rowData)}}
                      />
                      {
                        mainActionsView && (
                          <Column
                            width={width / 5}
                            label={<div className="irma-status-label">IRMA<br/>Order status</div>}
                            dataKey='translated_action'
                            cellRenderer={({rowData}) => {
                              return <div>{rowData.translated_action}</div>
                            }}
                          />
                        )
                      }
                      {
                        Object.keys(statuses).map((checkboxKey) => (
                          <Column
                            width={width / 5}
                            label='Acties'
                            headerRenderer={() => (
                              <div className="header-cell-container">
                                <span className="header-identifier">{statuses[checkboxKey]}</span>
                                <Input
                                  type="checkbox"
                                  label={<div className="check-all-label">Alles</div>}
                                  name={`all_${checkboxKey}`}
                                  className="checkbox"
                                  disabled={inputsDisabled}
                                  checked={overallStatus[checkboxKey]}
                                  onChange={() => this.toggleSelectAll(checkboxKey, overallStatus[checkboxKey])}
                                />
                              </div>
                            )}
                            cellRenderer={({rowData}) => {
                              const hasParent = !!rowData.parent_id;

                              if (disabledOrderStates.includes(rowData.order_state)) {
                                return <span/>;
                              }
                              if (hasParent && isVerlengenType) {
                                return <span/>;
                              }

                              return (
                                <div style={{'marginTop': '8px'}}>
                                  <Input
                                    type="checkbox"
                                    name={`${checkboxKey}-${rowData.id}`}
                                    label=" "
                                    className="checkbox"
                                    disabled={inputsDisabled}
                                    checked={rowData[checkboxKey] === true}
                                    onChange={() => this.toggleSelectAll(checkboxKey, rowData)}
                                  />
                                </div>
                              );
                            }}
                          />
                        ))
                      }
                    </Table>
                  )}
                </AutoSizer> :
                <Row>
                  <ReactTable
                    data={dataProfiles}
                    resolveData={dataProfiles => dataProfiles.map(row => row)}
                    columns={columns}
                    minRows={0}
                    defaultPageSize={999}
                    showPagination={false}
                    style={{border: 'none'}}
                    className="responsive-table -striped"
                    getTbodyProps={() => {
                      return {
                        style: {
                          overflow: 'visible',
                          textAlign: 'left',
                        }
                      }
                    }}
                    expanded={defaultExpandedRows}
                    onSortedChange={(newSorted, column, shiftKey) => {
                      const sortData = newSorted[0] || [];

                      if (sortData.id && sortData.id === 'product_name') {
                        dataAddons.sort((a, b) => sortData.desc === true ?
                          a.product_name.localeCompare(b.product_name) :
                          b.product_name.localeCompare(a.product_name)
                        );
                      }
                    }}
                    SubComponent={row => {
                      if (isVerlengenType) {
                        const addons = dataAddons.filter(el => el.parent_id === row.original.order_id);

                        return (
                          <ReactTable
                            data={addons}
                            resolveData={addons => addons.map(row => row)}
                            columns={columns}
                            minRows={0}
                            defaultPageSize={999}
                            showPagination={false}
                            style={{border: 'none'}}
                            className="responsive-table"
                            getTheadThProps={() => ({
                              style: {
                                display: 'none'
                              }
                            })}
                            getTbodyProps={() => ({
                              style: {
                                borderTop: 'solid 1px rgba(0, 0, 0, .05)',
                                overflow: 'visible',
                                textAlign: 'left',
                              }
                            })}
                          />
                        )
                      }
                    }}
                  />
                </Row>
            }
          </Col>
        }
      </div>
    );
  }
}

const mapStateToProps = ({quotation, hardware, mobile, authentication}) => {
  return {
    quotationId: quotation.currentQuotation.id,
    customHardwareOptions: hardware.customHardwareOptions,
    mobileOfferYear: quotation.currentQuotation.mobileOfferYear,
    devicePaymentOptions: mobile.devicePaymentOptions,
    pendingDataRetrieval: mobile.pendingDataRetrieval,
    quotation,
    authentication: authentication,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    quotationActions: bindActionCreators(quotationActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
    mobileActions: bindActionCreators(mobileActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ExistingMobileData);
