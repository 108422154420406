import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import DownloadForm from "./DownloadForm";
import downloadsActions from "../../../actions/downloadsActions";
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from "material-ui/Table";
import FileDownload from "js-file-download";
import RatioOverflowTableWrapper from "../../../components/styled-components/ratio-overflow-table-wrapper/RatioOverflowTableWrapper";

class DownloadsPanel extends Component {
    tableHeaderNames = ['Categorie', 'Omschrijving', 'Acties'];

    constructor(props) {
        super(props);

        this.state = {
            formDialogOpened: false,
            selectedFile: {},
        }
    }

    componentDidMount() {
        this.props.actions.getDownloads();
    }

    openDialog = (document) => {
        this.setState({
            formDialogOpened: true,
            selectedFile: document
        })
    };

    closeDialog = () => {
        this.setState({
            formDialogOpened: false
        })
    };

    onSubmit = (data) => {
        this.props.actions.editRatioGuideFile(data).then(() => this.closeDialog());
    };

    downloadRatioGuideFile = (document) => {
        this.props.actions.downloadRatioGuideFile(document.id).then((response) => {
            if(response === true) {
                FileDownload(this.props.downloads.downloadedDocument, `${document.name}.pdf`);
            }
        });
    };

    generateRow = (row) => {
        const { isDoceri, roles } = this.props.authentication;
        const isAdmin = roles.includes('ROLE_ADMIN');
        return (
            <TableRow key={row.id}>
                <TableRowColumn>{row.category}</TableRowColumn>
                <TableRowColumn>{row.name}</TableRowColumn>
                <TableRowColumn>
                    <div>
                        <a href="#" onClick={() => this.downloadRatioGuideFile(row)}>
                            <i className="small material-icons left themeprimarycolor">file_download</i>
                        </a>
                        {isDoceri && isAdmin &&
                            <a href="#" onClick={() => this.openDialog(row)}>
                                <i className="small material-icons left themeprimarycolor">file_upload</i>
                            </a>
                        }
                    </div>
                </TableRowColumn>
            </TableRow>
        );
    };

    generateHeader = () => {
        return (
            this.tableHeaderNames.map(headerName =>
                <TableHeaderColumn key={headerName} className="clickable" headername={headerName} >
                    {headerName}
                </TableHeaderColumn>
            )
        );
    };

    render() {
        const {downloads: { documents }} = this.props;

        return (
            <div>
                <div id="Forms" className="ratiotable paddingbottomnone">
                    <h1 className="admin-subheader-text"><span className="ratio-section-title">Downloads</span></h1>
                </div>

                <RatioOverflowTableWrapper>
                    <div className="valign-wrapper">
                        <Table selectable={false}>
                            <TableHeader displaySelectAll={false} adjustForCheckbox={false} >
                                <TableRow>
                                    {
                                        this.generateHeader()
                                    }
                                </TableRow>
                            </TableHeader>
                            <TableBody displayRowCheckbox={false}>
                                {
                                    documents.map(row =>
                                        this.generateRow(row)
                                    )
                                }
                            </TableBody>
                        </Table>
                    </div>
                </RatioOverflowTableWrapper>

                {this.state.formDialogOpened && <DownloadForm
                    document={this.state.selectedFile}
                    onClose={this.closeDialog}
                    onSubmit={this.onSubmit}
                />}
            </div>
        )
    }
}

const mapStateToProps = ({downloads, authentication}) => {
    return {
        downloads,
        authentication,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(downloadsActions, dispatch)
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DownloadsPanel));
