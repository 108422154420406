import orderState from "./orderState";

const disabledOrderStates = [
    orderState.ORDER_STATE_ACTIVATE,
    orderState.ORDER_STATE_ACTIVATING,
    orderState.ORDER_STATE_UPDATE,
    orderState.ORDER_STATE_UPDATING,
    orderState.ORDER_STATE_TERMINATE,
    orderState.ORDER_STATE_TERMINATING
]

export default disabledOrderStates;