import React from 'react';
import {TableRow, TableRowColumn} from "material-ui";
import GroupDiscountsInputs from "./GroupDiscountsInputs";
import productType from "../../../constants/productType";

const GroupProductRow = ({ product, groupDiscounts, isCustomCategory, productGroup, editable, isVerlengenType}) => {
    const productKey = product.isSwapstock ? product.id + "_SWAPSTOCK" : product.id;

    const calculateDiscountMonthly = (customMonthly = false) => {
      if (!groupDiscounts[product.id]) {
        return product.discount_gross_monthly;
      }

      let tariffCluster = product.tariffCluster || null;
      let discount = groupDiscounts[product.id].find((el) => el.discountAction === product.action && el.discountTariffCluster === tariffCluster);

      if (! discount || (discount && !discount.discountPercentageMonthly)) {
        return product.discount_gross_monthly;
      }

      if (customMonthly) {
        return (customMonthly * discount.discountPercentageMonthly) / 100;
      }

      return (product.price_gross_monthly * discount.discountPercentageMonthly) / 100;
    };

    const calculateDiscountOnetime = (customOneTime = false) => {
      if (!groupDiscounts[product.id]) {
        return product.discount_gross_onetime;
      }

      let tariffCluster = product.tariffCluster || null;
      let discount = groupDiscounts[product.id].find((el) => el.discountAction === product.action && el.discountTariffCluster === tariffCluster);
      if (! discount || (discount && !discount.discountPercentageOnetime)) {
        return product.discount_gross_onetime;
      }

      if (customOneTime) {
        return (customOneTime * discount.discountPercentageOnetime) / 100;
      }

      return (product.price_gross_onetime * discount.discountPercentageOnetime) / 100;
    };

    const calculateTotalMonthlyPrice = (isCustomCategory, final = false) => {
      let productAmount = product.only_once_per_quotation && product.only_once_type === productType.TYPE_ONCE_ALL ? 1 : product.amount;
      if (isCustomCategory) {
        let monthlyPrice = (product.price_gross_monthly);
        let monthlyTotal = productAmount * monthlyPrice;

        if (final) {
          monthlyTotal = monthlyTotal - (productAmount * calculateDiscountMonthly(monthlyPrice));
        }
        return monthlyTotal;
      } else {
        let monthlyTotal = productAmount * product.price_gross_monthly;
        if (final) {
          monthlyTotal = monthlyTotal - (productAmount * calculateDiscountMonthly());
        }
        return monthlyTotal;
      }
    };

    const calculateTotalOneTimePrice = (isCustomCategory, final = false) => {
      let productAmount = product.only_once_per_quotation &&
      [productType.TYPE_ONCE_ALL, productType.TYPE_ONCE_ONE_TIME].includes(product.only_once_type) ? 1 : product.amount;
      if (isCustomCategory) {
        let oneTimePrice = (product.price_gross_onetime + product.setUpPrice + product.installationPrice + product.pmPrice);
        let oneTimeTotal = productAmount * oneTimePrice;

        if (final) {
          oneTimeTotal = oneTimeTotal - (productAmount * calculateDiscountOnetime(oneTimePrice));
        }
        return oneTimeTotal;
      } else {
        let oneTimeTotal = productAmount * product.price_gross_onetime;
        if (final) {
          oneTimeTotal = oneTimeTotal - (productAmount * calculateDiscountOnetime());
        }
        return oneTimeTotal;
      }
    };

    const calculatePieceMonthlyPrice = (isCustomCategory) => {
        if (isCustomCategory) {
            return product.price_gross_monthly;
        } else {
            return product.price_gross_monthly;
        }
    };

    const calculatePieceOneTimePrice = (isCustomCategory) => {
        if (isCustomCategory) {
            return product.price_gross_onetime + product.setUpPrice + product.installationPrice + product.pmPrice;
        } else {
            return product.price_gross_onetime;
        }
    };

   const getActionName = (action, isVerlengenType, fetchedFromGrexx) => {
     if (!action && isVerlengenType && !fetchedFromGrexx) {
       return 'Nieuw';
     }

    switch (action) {
      case 5:
        return 'Nieuw';
      case 1:
        return 'Porteren';
      case 4:
        return 'Simless porteren';
      case 6:
        return isVerlengenType ? 'Up/downgraden' : 'Upgraden';
      case 7:
        return 'Verlenging';
      case 8:
        return 'Opheffing';
      case 9:
        return 'Toevoeging';
      case 10:
        return 'Retentie';
      default:
        return 'Nieuw';
    }
  };

    return (
        <TableRow>
            <TableRowColumn style={{width: `${isCustomCategory ? "15%" : "30%"}`, whiteSpace: 'normal', wordWrap: 'break-word'}}>
              {product.name}
              {product.fetchedFromGrexx && <span style={{ color: 'red', fontStyle: 'italic' }}>(LET OP: prijs onbekend)</span>}
            </TableRowColumn>
            <TableRowColumn style={{width: "10%", whiteSpace: 'normal', wordWrap: 'break-word'}}>
              {getActionName(product.action, isVerlengenType, product.fetchedFromGrexx)}
            </TableRowColumn>
            <TableRowColumn>{product.allow_decimal_quantity ? new Intl.NumberFormat('nl-NL', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(product.amount) : product.amount}
              {product.only_once_per_quotation && product.only_once_type &&
              <span>
                      {product.only_once_type === productType.TYPE_ONCE_ALL ?
                        '(max 1 per offerte mogelijk)' : '(eenmalige kosten 1x berekend)'
                      }
                    </span>
              }
            </TableRowColumn>
            <TableRowColumn style={product.fetchedFromGrexx ? {textAlign: 'right', color: 'red'} : {textAlign: 'right'}}>
              {new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR'
              }).format(calculatePieceMonthlyPrice(isCustomCategory))}
            </TableRowColumn>
            <TableRowColumn style={product.fetchedFromGrexx ? {textAlign: 'right', color: 'red'} : {textAlign: 'right'}}>
              {new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR'
             }).format(calculatePieceOneTimePrice(isCustomCategory))}
            </TableRowColumn>
            <TableRowColumn style={{textAlign: 'right', width: '15%'}}>
                <GroupDiscountsInputs
                  key={productKey}
                  type={'Monthly'}
                  productType={isCustomCategory ? product.category_id: "product"}
                  productId={product.id}
                  groupDiscounts={groupDiscounts}
                  productGroup={productGroup}
                  editable={editable}
                  productAction={product.action}
                  tariffCluster={product.tariffCluster || null}
                />
            </TableRowColumn>
            <TableRowColumn style={{textAlign: 'right', width: '15%'}}>
                <GroupDiscountsInputs
                  key={productKey}
                  type={'Onetime'}
                  productType={isCustomCategory ? product.category_id: "product"}
                  productId={product.id}
                  groupDiscounts={groupDiscounts}
                  productGroup={productGroup}
                  editable={editable}
                  productAction={product.action}
                  tariffCluster={product.tariffCluster || null}
                />
            </TableRowColumn>
            <TableRowColumn style={product.fetchedFromGrexx ? {textAlign: 'right', color: 'red'} : {textAlign: 'right'}}>
              {new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR'
              }).format(calculateTotalMonthlyPrice(isCustomCategory, true))}
            </TableRowColumn>
            <TableRowColumn style={product.fetchedFromGrexx ? {textAlign: 'right', color: 'red'} : {textAlign: 'right'}}>
              {new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR'
              }).format(calculateTotalOneTimePrice(isCustomCategory, true))}
            </TableRowColumn>
        </TableRow>
    );
};

export default GroupProductRow;
