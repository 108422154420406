import {apiRoutes} from "../constants/apiRoutes";
import ApiService from "../services/apiService";
import RequestType from "../constants/requestType";
import {toastr} from "react-redux-toastr";
import {actionTypes} from "../constants/actionTypes";

const getAdminOrdersData = (queryParams) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.adminOrders.getAll + (queryParams ? queryParams : '');
            const response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(getAllAdminOrdersSuccess(response));
            dispatch(savePaginationParameters(response))
        } catch ({message}) {
            toastr.error('Het is niet mogelijk de lijst met offertes op te halen', message);
        }
    }
};

const getAllAdminOrdersSuccess = (response) => {
    return {
        type: actionTypes.getAdminOrdersData,
        response
    };
};

const setAdminCurrentQuotationOrder = (quotationOrder) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.setCurrentQuotationAdminOrder,
            payload: quotationOrder
        });
    }
};

const setAdminCurrentSection = (sectionId) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.setCurrentAdminOrderSection,
            payload: sectionId
        });
    }
};

const getQuotationAdminOrderProducts = (quotation, queryParams) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.adminOrders.getQuotationAdminOrderProducts
                    .replace('{quotation}', quotation)
                + (queryParams ? queryParams : '');
            const response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(getQuotationAdminOrderProductsSuccess(response));
        } catch ({message}) {
            toastr.error('Something went wrong', message);
        }
    }
};

const getQuotationAdminOrderProductsSuccess = (response) => {
    return {
        type: actionTypes.getQuotationAdminOrderProductsSuccess,
        payload: response
    };
};

const savePaginationParameters = ({currentPage, resultsCount, pages}) => {
    return {
        type: actionTypes.savePagination,
        payload: {
            currentPage,
            resultsCount,
            pages
        }
    };
};

const changeAdminOrderStatus = (id, status, isProductStatus) => {
    return async (dispatch) => {
        try {
            let quotation = '';
            let product = '';
            if (!isProductStatus) {
                quotation = id;
                const url = apiRoutes.adminOrders.changeAdminOrderStatus.replace('{id}', quotation)
                const body = {status: status, isProductStatus: isProductStatus}
                const response = await ApiService.performRequest(RequestType.PATCH, url, body);
                if (response) {
                    dispatch(changeOrderStatusSuccess(quotation, status))
                }
            } else {
                product = id;
                const url = apiRoutes.adminOrders.changeAdminOrderStatus.replace('{id}', product)
                const body = {status: status, isProductStatus: isProductStatus}
                const response = await ApiService.performRequest(RequestType.PATCH, url, body);
                if (response) {
                    dispatch(changeProductOrderStatusSuccess(product, status))
                }
            }
        } catch ({message}) {
            toastr.error('Something went wrong', message);
        }
    }
};

const changeOrderStatusSuccess = (quotation, status) => {
    return {
        type: actionTypes.setQuotationAdminStatus,
        payload: {
            quotation: quotation,
            status: status
        }
    };
}

const changeProductOrderStatusSuccess = (product, status) => {
    const orderProductStatusList = [
        {value: 'active', name: 'Order activatie'},
        {value: 'declined', name: 'Order afgewezen'},
        {value: 'success', name: 'Success'},
    ];

    let statusName = '';
    let statusIndex =  orderProductStatusList.findIndex((productStatus) => productStatus.value === status);
    if (statusIndex >= 0) {
        statusName = orderProductStatusList[statusIndex].name;
    }

    return {
        type: actionTypes.setProductOrderAdminStatus,
        payload: {
            product: product,
            statusName: statusName
        }
    };
}

export default {
    getAdminOrdersData,
    setAdminCurrentQuotationOrder,
    setAdminCurrentSection,
    getQuotationAdminOrderProducts,
    changeAdminOrderStatus
};
