import {
    actionTypes
} from '../constants/actionTypes';

const initialState = {
    discountProduct: {
        id: null,
        name: '',
        priceOneOff: 0,
        pricePerMonth: 0
    }
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.getDiscountProduct:
            return {
                ...state,
                discountProduct: action.response
            };
        case actionTypes.editDiscountProduct:
            return {
                ...state,
                discountProduct: action.response
            };
        default:
            return state;
    }
}
