import {
  actionTypes
} from '../constants/actionTypes';

const initialState = {
  allProducts: []
};

export default (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.getDependencyProducts:
      return {
        ...state,
        allProducts: [...action.payload]
      };
    default:
      return state;
  }
}
