import styled from 'styled-components';

export default styled.div`
  width: 100px;
  margin: 0 auto 16px auto;
  font-size: 0.8rem;
  color: #9e9e9e;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;
