import React from 'react';

import DialogHeader from "../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../containers/common/KpnDialog/DialogBody";
import DialogFooterFlex from "../../containers/common/KpnDialog/DialogFooterFlex";
import DialogButton from "../../containers/common/KpnDialog/DialogButton";
import KpnDialog from "../../containers/common/KpnDialog/KpnDialog";

const dialogStyles = {
  footer: {
    buttonContainer: { minWidth: '200px' },
  },
};

class VerlengenUsageDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      irmaID: ''
    };
  }

  changeIrmaId = ({target}) => {
    this.setState({ irmaID: target.value });
  };

  render() {
    let { verlengenDialogOpen, closeVerlengenUsageDialog, updateIrmaId } = this.props;
    let irmaID = this.state.irmaID;

    return (
      <KpnDialog
        dialogState={verlengenDialogOpen}
        onRequestClose={() => closeVerlengenUsageDialog()}
        modal={false}
        dialogHeader={(
          <DialogHeader
            headerText="De volgende velden dienen juist gevuld te worden."
            closeHandler={() => closeVerlengenUsageDialog()}
          />
        )}
        dialogBody={
          <DialogBody>
            <div className="row">
              <div className="input-field col s12 m12">
                <span>
                  Om bestaande orders te kunnen aanpassen hebben wij de IRMA ID nodig van de gekozen klant. Aub het correcte IRMA ID ingeven zodat we de meest recente data kunnen ophalen voor deze klant.
                </span>
              </div>
            </div>
            <div className="row">
            </div>
            <div className="row">
              <div className="input-field col s12 m12">
                <input
                  id="irmaID"
                  type="text"
                  required
                  name="irmaID"
                  value={irmaID}
                  onChange={this.changeIrmaId}
                  placeholder="klant IRMA ID"
                />
                <label htmlFor="irmaID">IRMA ID</label>
              </div>
            </div>
          </DialogBody>
        }
        dialogFooter={
          <DialogFooterFlex>
            <div className={"col s12 m12"}>
              <div style={dialogStyles.footer.buttonContainer}>
                <DialogButton
                  buttonAction={() => updateIrmaId(irmaID)}
                  buttonText='Opslaan'
                />
              </div>
            </div>
          </DialogFooterFlex>
        }
      />
    );
  }
}

export default VerlengenUsageDialog;
