const hardwareTypes = {
  access: 'access',
  mobile: 'mobiel',
  voice: 'voice',
  partner: 'partner',
  it: 'it',
};

export default hardwareTypes;

export const allTypes = [
  hardwareTypes.access,
  hardwareTypes.mobile,
  hardwareTypes.voice,
  hardwareTypes.partner,
  hardwareTypes.it,
];