import React, { Component } from 'react';
import {Input, Row, Col} from "react-materialize";

import KpnDialog from "../../../containers/common/KpnDialog/KpnDialog";
import DialogHeader from "../../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";

import FileUpload from "../../../components/fileUpload";

import ProviderHelper from "../../../helper/providerHelper";

class WordDocumentsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wordDocument: {
        id: this.props.document.id || null,
        name: this.props.document.name,
        default: this.props.document.default ? 1 : 0,
        file: '',
        provider: this.props.document.provider || 0,
        availabilityType: this.props.availabilityType || 'quotation',
        documentType: this.props.documentType || 'docx',
        webLeadAvailable: this.props.document.webLeadAvailable ? 1 : 0,
        webLeadDefault: this.props.document.webLeadDefault ? 1 : 0,
        dsAudit: this.props.document.dsAudit ? 1 : 0,
        dsAttachment: this.props.document.dsAttachment ? 1 : 0,
        isQuote: this.props.document.isQuote ? 1 : 0,
        documentAccessType: this.initializeDocumentAccessType()
      },
      uploadErrors: ''
    }
  }

  componentDidMount() {
    const {document, providers} = this.props;
    if (! document.provider && providers.length) {
      this.setState({wordDocument: {...this.state.wordDocument, provider: providers[0].provider}});
    }
  }

  onChange = ({target}) => {
    let {name, value, type, checked} = target;

    if ('checkbox' === type) {
      value = checked ? 1 : 0;
    }

    this.setState(prevState => ({
      wordDocument: {
        ...prevState.wordDocument,
        [name]: value
      }
    }));
  };

  onDrop = (files) => {
    const file = files[0];
    const documentType = this.state.wordDocument.documentType;

    if ((!documentType || documentType === 'docx') && file.type === 'application/pdf') {
      this.setState({uploadErrors: 'Upload een geldig docx-bestand!'});
      return;
    }
    if (documentType === 'pdf' && file.type !== 'application/pdf') {
      this.setState({uploadErrors: 'Upload een geldig pdf-bestand!'});
      return;
    }

    if (documentType === 'xlsx' && file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.setState({uploadErrors: 'Upload een geldig excel-bestand!'});
      return;
    }

    if (documentType === 'xls' && file.type !== 'application/vnd.ms-excel') {
      this.setState({uploadErrors: 'Upload een geldig excel-bestand!'});
      return;
    }

    if (documentType === 'csv' && file.type !== 'text/csv') {
      this.setState({uploadErrors: 'Upload een geldig excel-bestand!'});
      return;
    }

    this.setState(prevState => ({
      wordDocument: {
        ...prevState.wordDocument,
        file: files[0],
      },
      uploadErrors: ''
    }));
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.onSubmit(this.state.wordDocument);
  };

  initializeDocumentAccessType = () => {
    let dsAudit = this.props.document.dsAudit ? 1 : 0;
    let dsAttachment = this.props.document.dsAttachment ? 1 : 0;
    let documentDefault = this.props.document.default ? 1 : 0;
    let isQuote = this.props.document.isQuote ? 1 : 0;
    let wordDocumentValue = '';

    if (dsAudit) {
      wordDocumentValue = 'dsAudit';
    }

    if (dsAttachment && !wordDocumentValue) {
      wordDocumentValue = 'dsAttachment';
    }

    if (documentDefault && !wordDocumentValue) {
      wordDocumentValue = 'standard';
    }

    if (isQuote) {
      wordDocumentValue = 'isQuote';
    }

    return wordDocumentValue;
  };
  
  getWordDocumentValue = () => {
    const {dsAudit, dsAttachment, documentAccessType, isQuote} = this.state.wordDocument;
    let wordDocumentValue = documentAccessType ?? -1;
    
    if (dsAudit && !documentAccessType) {
      wordDocumentValue = 'dsAudit';
    }
  
    if (dsAttachment && !documentAccessType) {
      wordDocumentValue = 'dsAttachment';
    }

    if (isQuote && !documentAccessType) {
      wordDocumentValue = 'isQuote';
    }
  
    if (this.state.wordDocument.default && !documentAccessType) {
      wordDocumentValue = 'standard';
    }

    return wordDocumentValue;
  };

  render() {
    const { wordDocument } = this.state;

    return (
      <KpnDialog
        dialogState={true}
        modal={true}
        onRequestClose={this.props.onClose}
        dialogHeader={(
          <DialogHeader
            headerIcon="person"
            headerText="Word document"
            closeHandler={this.props.onClose}
          />
        )}
        dialogBody={
          <DialogBody>
            <form onSubmit={this.onSubmit}>
              <Row>
                <Input
                  required
                  s={12}
                  defaultValue={wordDocument.name}
                  onChange={this.onChange}
                  label="Naam"
                  name='name'
                  type='text'/>
              </Row>

              <Row>
                <Input
                  s={12}
                  name={'provider'}
                  id={'provider'}
                  type="select"
                  multiple={false}
                  required={true}
                  label={'Provider'}
                  defaultValue={wordDocument.provider}
                  onChange={this.onChange}
                  className="validate">
                  <option value="-1" disabled={'disabled'} key={-1}>Kies provider</option>
                  {this.props.providers.filter((el => el.provider !== 1 )).map((el) => <option value={el.provider} key={el.provider}>{ProviderHelper.getQuotationProviderName(el.provider)}</option>)}
                </Input>
              </Row>

              {
                this.props.allowLead  && (
                  <Row>
                    <Input
                      s={12}
                      name={'availabilityType'}
                      id={'availabilityType'}
                      type="select"
                      multiple={false}
                      required={true}
                      label={'Van toepassing op'}
                      defaultValue={'quotation'}
                      onChange={this.onChange}
                      className="validate">
                      <option value="quotation" key={1}>Offerte</option>
                      <option value="lead" key={2}>Lead</option>
                    </Input>
                  </Row>
                )
              }

              {this.props.allowWebLead &&
                <Row>
                  <Row>
                    <Input
                      s={12}
                      name={'documentAccessType'}
                      id={'documentAccessType'}
                      type="select"
                      multiple={false}
                      label={'Type'}
                      value={this.getWordDocumentValue()}
                      onChange={this.onChange}
                      className="validate">
                      <option key={-1} value="-1">Selecteer een optie</option>
                      <option value="standard" key={'standard'}>Standaard</option>
                      <option value="dsAudit" key={'dsAudit'}>DS Audit</option>
                      <option value="dsAttachment" key={'dsAttachment'}>DS Bijlage</option>
                      {!!this.props.hasQuoteBtn ? <option value="isQuote" key={'isQuote'}>Prijsopgave</option> : <></>}
                    </Input>
                  </Row>
                  <Row>
                    <Input
                      s={4}
                      type='checkbox'
                      name='webLeadAvailable'
                      checked={wordDocument.webLeadAvailable}
                      onChange={this.onChange}
                      label='Is beschikbaar voor webLeads?'
                    />
                    <Input
                      s={4}
                      type='checkbox'
                      name='webLeadDefault'
                      checked={wordDocument.webLeadDefault}
                      onChange={this.onChange}
                      label='Is standaard voor webLeads?'
                    />
                  </Row>
                </Row>
              }

              {!this.props.allowWebLead &&
                <Row>
                  <Input
                    s={12}
                    name={'documentAccessType'}
                    id={'documentAccessType'}
                    type="select"
                    multiple={false}
                    label={'Type'}
                    value={this.getWordDocumentValue()}
                    onChange={this.onChange}
                    className="validate">
                    <option key={-1} value="-1">Selecteer een optie</option>
                    <option value="standard" key={'standard'}>Standaard</option>
                    <option value="dsAudit" key={'dsAudit'}>DS Audit</option>
                    <option value="dsAttachment" key={'dsAttachment'}>DS Bijlage</option>
                    {!!this.props.hasQuoteBtn ? <option value="isQuote" key={'isQuote'}>Prijsopgave</option> : <></>}
                  </Input>
                </Row>
              }

              <Row>
                <Input
                  s={12}
                  name={'documentType'}
                  id={'documentType'}
                  type="select"
                  multiple={false}
                  label={'Bestandstype'}
                  value={wordDocument.documentType}
                  onChange={this.onChange}
                  className="validate">
                  <option key={-1} value="-1">Selecteer een optie</option>
                  <option value="docx" key={'docx'}>Docx</option>
                  <option value="pdf" key={'pdf'}>PDF</option>
                  <option value="xls" key={'xls'}>Excel 97 - Excel 2003</option>
                  <option value="xlsx" key={'xlsx'}>Excel 2007 - Excel 2019</option>
                  <option value="csv" key={'csv'}>CSV</option>
                </Input>
              </Row>

              <Row>
                <Col s={12} className="input-field">
                  <FileUpload
                    onDrop={this.onDrop}
                    caption="Sleep uw bestand hier of klik voor upload. Let op, dit overschrijft het oude bestand"
                  />

                  {wordDocument.file && <div>Geselecteerd bestand: {wordDocument.file.name}</div>}
                </Col>
                {this.state.uploadErrors && (
                  <Col s={12}>
                    <span style={{color: 'red'}}>{this.state.uploadErrors}</span>
                  </Col>
                )}
              </Row>

              <button
                className='btn doceri-btn-right ratio-btn-right-fullwidth right'
                type="submit"
                name="action"
              >
                Submit
              </button>
            </form>
          </DialogBody>
        }
      />
    )
  }
}

export default WordDocumentsForm;
