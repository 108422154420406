import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 20px;
  @media only screen and (max-width: 599px) {
    justify-content: center;
    margin-left: 0;
  }
`;
