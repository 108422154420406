import React from 'react';
import {Col, Input, Row} from 'react-materialize'
import './HostedVoiceAddons.css'

const HostedVoiceAddons = ({
                               hostedVoiceOptions,
                               onChangeCallback,
                               onQuantitySaveAction,
                               usersSumValid,
                               quotation,
                               receptionTypes,
                               isSalesValue,
                               vamoAmountString,
                               isVerlengenType,
                               inputsDisabled,
                               onChangeCrmCaraSoloOptions,
                               crmCaraSoloRequiredErrorMessage
                           }) => {
  return (
    <div id="Forms" className="col s12">
      <div className="row">
        <div className="col s12">
          <h1 className={'ratio-section-title'}>
            <span className="ratio-section-title">
                <i className="small material-icons left ratio-section-title">settings_phone</i>Vast Bellen:
            </span> gewenste opties
          </h1>
        </div>
      </div>
      {
        isVerlengenType &&
        <Col s={12}>
          <Row style={{marginBottom: 0}}>
            <div className="line tussenkop">
              {vamoAmountString}
            </div>
          </Row>
        </Col>
      }
      <div className="row">
        {quotation.currentQuotation.provider === 0 ?
          <div className="col s12">
            <div className="row" style={{marginBottom: 0}}>
              <div className="input-field col s12 m12 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input id="users_per_public_switch"
                       type="number"
                       min={0}
                       max={999}
                       className="validate"
                       name="usersPerPublicSwitch"
                       value={Number(hostedVoiceOptions.usersPerPublicSwitch).toString()}
                       onChange={onChangeCallback}
                       onBlur={onQuantitySaveAction}
                       style={{marginBottom: 20}}
                       disabled={inputsDisabled}
                />
                <label htmlFor="usersPerPublicSwitch">Aantal gebruikers</label>
              </div>
              {(parseInt(hostedVoiceOptions.callChargeType, 10) === 3) && (
                <React.Fragment>
                  <div className="row">
                    {!usersSumValid() ? (
                      <div className="input-field col s12 m12 inner-addon" style={{color: '#FF0000', fontSize: 12}}>
                        Het aantal P2G en onbeperkt gebruikers dient overeen te komen met het aantal gebruikers.
                      </div>
                    ) : null}
                  </div>
                  <div className="input-field col s6 m6 inner-addon">
                    <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                    <input
                      id="p2gUsersCount"
                      className={!usersSumValid() ? 'invalid' : 'valid'}
                      type="number"
                      min={0}
                      max={999}
                      name="p2gUsersCount"
                      value={Number(hostedVoiceOptions.p2gUsersCount).toString()}
                      onChange={onChangeCallback}
                      onBlur={onQuantitySaveAction}
                      style={{marginBottom: 20}}
                      disabled={inputsDisabled}
                    />
                    <label htmlFor="p2gUsersCount"> Hoeveel gebruikers voor P2G?</label>
                  </div>
                  <div className="input-field col s6 m6 inner-addon">
                    <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                    <input
                      id="unlimitedUsersCount"
                      type="number"
                      min={0}
                      max={999}
                      className={!usersSumValid() ? 'invalid' : 'valid'}
                      name="unlimitedUsersCount"
                      value={Number(hostedVoiceOptions.unlimitedUsersCount).toString()}
                      onChange={onChangeCallback}
                      onBlur={onQuantitySaveAction}
                      style={{marginBottom: 20}}
                      disabled={inputsDisabled}
                    />
                    <label htmlFor="unlimitedUsersCount"> Hoeveel gebruikers voor ongelimiteerd?</label>
                  </div>
                </React.Fragment>
              )}

              <div className="input-field col s6 m6 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input
                  id="receptionistRoleCount"
                  type="number"
                  min={0}
                  max={999}
                  className="validate"
                  name="receptionistRoleCount"
                  value={Number(hostedVoiceOptions.receptionistRoleCount).toString()}
                  style={{marginBottom: 20}}
                  onChange={onChangeCallback}
                  onBlur={onQuantitySaveAction}
                  disabled={inputsDisabled}
                />
                <label htmlFor="receptionistRoleCount"> Aantal Receptie gebruikers</label>
              </div>
              <Input
                s={6}
                name="receptionistRole"
                type='select'
                label="Welk type receptie?"
                onChange={onChangeCallback}
                value={hostedVoiceOptions.receptionistRole}
                style={{marginBottom: 20}}
                multiple={false}
                disabled={inputsDisabled}
              >
                <option value={1}>maak keuze</option>
                {
                  receptionTypes && receptionTypes.map((el) => {
                    return <option value={el.value}>{el.name}</option>
                  })
                }
              </Input>
            </div>
            <div className="row" style={{marginBottom: 0}}>
              <div className="input-field col s12 m12" style={{marginBottom: 20}}>Desktop Integratie</div>
              <div className="input-field col s4 m4 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input
                  id="managerAssistantOption3"
                  type="number"
                  min={0}
                  max={999}
                  className="validate"
                  name="managerAssistantOption3"
                  value={Number(hostedVoiceOptions.managerAssistantOption3).toString()}
                  style={{marginBottom: 20}}
                  onChange={onChangeCallback}
                  onBlur={onQuantitySaveAction}
                  disabled={inputsDisabled}
                />
                <label htmlFor="managerAssistantOption3">Desktop Integratie Basis</label>
              </div>
              <div className="input-field col s4 m4 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input
                  id="desktopIntegration1"
                  type="number"
                  min={0}
                  max={999}
                  className="validate"
                  name="desktopIntegration1"
                  value={Number(hostedVoiceOptions.desktopIntegration1).toString()}
                  style={{marginBottom: 20}}
                  onChange={onChangeCallback}
                  onBlur={onQuantitySaveAction}
                  disabled={inputsDisabled}
                />
                <label htmlFor="desktopIntegration1">Desktop Integratie Standaard</label>
              </div>
              <div className="input-field col s4 m4 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input
                  id="callCenterAgentOption3"
                  type="number"
                  min={0}
                  max={999}
                  className="validate"
                  name="callCenterAgentOption3"
                  value={Number(hostedVoiceOptions.callCenterAgentOption3).toString()}
                  style={{marginBottom: 20}}
                  onChange={onChangeCallback}
                  onBlur={onQuantitySaveAction}
                  disabled={inputsDisabled}
                />
                <label htmlFor="callCenterAgentOption3">Desktop Integratie Premium</label>
              </div>
            </div>
            <div className="row" style={{marginBottom: 0}}>
              <div className="input-field col s12 m12" style={{marginBottom: 20}}>Aantal Callcenter agenten</div>
              <div className="input-field col s4 m4 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input
                  id="callCenterAgentRoleOption1"
                  type="number"
                  min={0}
                  max={999}
                  className="validate"
                  name="callCenterAgentRoleOption1"
                  value={Number(hostedVoiceOptions.callCenterAgentRoleOption1).toString()}
                  style={{marginBottom: 20}}
                  onChange={onChangeCallback}
                  onBlur={onQuantitySaveAction}
                  disabled={inputsDisabled}
                />
                <label htmlFor="callCenterAgentRoleOption1">Basic</label>
              </div>
              <div className="input-field col s4 m4 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input
                  id="callCenterAgentRoleOption2"
                  type="number"
                  min={0}
                  max={999}
                  className="validate"
                  name="callCenterAgentRoleOption2"
                  value={Number(hostedVoiceOptions.callCenterAgentRoleOption2).toString()}
                  style={{marginBottom: 20}}
                  onChange={onChangeCallback}
                  onBlur={onQuantitySaveAction}
                  disabled={inputsDisabled}
                />
                <label htmlFor="callCenterAgentRoleOption2">Standaard</label>
              </div>
              <div className="input-field col s4 m4 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input
                  id="callCenterAgentRoleOption3"
                  type="number"
                  min={0}
                  max={999}
                  className="validate"
                  name="callCenterAgentRoleOption3"
                  value={Number(hostedVoiceOptions.callCenterAgentRoleOption3).toString()}
                  style={{marginBottom: 20}}
                  onChange={onChangeCallback}
                  onBlur={onQuantitySaveAction}
                  disabled={inputsDisabled}
                />
                <label htmlFor="callCenterAgentRoleOption3">Premium</label>
              </div>
            </div>
            <div className="row" style={{marginBottom: 0}}>
              <div className="input-field col s12 m12" style={{marginBottom: 20}}>Opties voor Call Center Agent</div>
              <div className="input-field col s4 m4 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input
                  id="callCenterAgentOption1"
                  type="number"
                  min={0}
                  max={999}
                  className="validate"
                  name="callCenterAgentOption1"
                  value={Number(hostedVoiceOptions.callCenterAgentOption1).toString()}
                  style={{marginBottom: 20}}
                  onChange={onChangeCallback}
                  onBlur={onQuantitySaveAction}
                  disabled={inputsDisabled}
                />
                <label htmlFor="callCenterAgentOption1">Call Center Agent Software</label>
              </div>
              <div className="input-field col s4 m4 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input
                  id="callCenterAgentOption2"
                  type="number"
                  min={0}
                  max={999}
                  className="validate"
                  name="callCenterAgentOption2"
                  value={Number(hostedVoiceOptions.callCenterAgentOption2).toString()}
                  style={{marginBottom: 20}}
                  onChange={onChangeCallback}
                  onBlur={onQuantitySaveAction}
                  disabled={inputsDisabled}
                />
                <label htmlFor="callCenterAgentOption2">Call Center Supervisor Software</label>
              </div>
            </div>
            <div className="row" style={{marginBottom: 0}}>
              <div className="input-field col s12 m12" style={{marginBottom: 20}}>Schakeling waarbij secretaresse kan
                inbreken in het telefoongesprek van manager
              </div>
              <div className="input-field col s6 m6 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input
                  id="managerAssistantOption1"
                  type="number"
                  min={0}
                  max={999}
                  className="validate"
                  name="managerAssistantOption1"
                  value={Number(hostedVoiceOptions.managerAssistantOption1).toString()}
                  style={{marginBottom: 20}}
                  onChange={onChangeCallback}
                  onBlur={onQuantitySaveAction}
                  disabled={inputsDisabled}
                />
                <label htmlFor="managerAssistantOption1">Manager</label>
              </div>
              <div className="input-field col s6 m6 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input
                  id="managerAssistantOption2"
                  type="number"
                  min={0}
                  max={999}
                  className="validate"
                  name="managerAssistantOption2"
                  value={Number(hostedVoiceOptions.managerAssistantOption2).toString()}
                  style={{marginBottom: 20}}
                  onChange={onChangeCallback}
                  onBlur={onQuantitySaveAction}
                  disabled={inputsDisabled}
                />
                <label htmlFor="managerAssistantOption2">Managementassistent</label>
              </div>
            </div>
            <div className="row" style={{marginBottom: 0}}>
              <div className="input-field col s12 m12" style={{marginBottom: 20}}>Opties voor Gespreksopname</div>
              <div className="input-field col s6 m6 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input
                  id="callRecordingOption1"
                  type="number"
                  min={0}
                  max={999}
                  className="validate"
                  name="callRecordingOption1"
                  value={Number(hostedVoiceOptions.callRecordingOption1).toString()}
                  onChange={onChangeCallback}
                  onBlur={onQuantitySaveAction}
                  disabled={inputsDisabled}
                />
                <label htmlFor="callRecordingOption1">Gespreksopname gebruiker 90 dagen</label>
              </div>
              <div className="input-field col s6 m6 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input
                  id="callRecordingOption2"
                  type="number"
                  min={0}
                  max={999}
                  className="validate"
                  name="callRecordingOption2"
                  value={Number(hostedVoiceOptions.callRecordingOption2).toString()}
                  onChange={onChangeCallback}
                  onBlur={onQuantitySaveAction}
                  disabled={inputsDisabled}
                />
                <label htmlFor="callRecordingOption2">Gespreksopname gebruiker 365 dagen</label>
              </div>
              {!isSalesValue &&
              <div>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <input
                    id="callRecordingOption3"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="callRecordingOption3"
                    value={Number(hostedVoiceOptions.callRecordingOption3).toString()}
                    onChange={onChangeCallback}
                    onBlur={onQuantitySaveAction}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="callRecordingOption3">Gespreksopname gebruiker 7 jaar maximaal 1000 min</label>
                </div>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <input
                    id="callRecordingOption4"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="callRecordingOption4"
                    value={Number(hostedVoiceOptions.callRecordingOption4).toString()}
                    onChange={onChangeCallback}
                    onBlur={onQuantitySaveAction}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="callRecordingOption4">Gespreksopname gebruiker 7 jaar maximaal 3500 min</label>
                </div>
              </div>
              }
            </div>
            {!isSalesValue &&
            <div className="row" style={{marginBottom: 0}}>
              <div className="input-field col s12 m12" style={{marginBottom: 20}}>Gespreksrapportage</div>
              <div className="input-field col s6 m6 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input
                  id="callReportingOption1"
                  type="number"
                  min="0"
                  className="validate"
                  name="callReportingOption1"
                  value={hostedVoiceOptions.callReportingOption1}
                  style={{marginBottom: 20}}
                  onChange={onChangeCallback}
                  onBlur={onQuantitySaveAction}
                  disabled={inputsDisabled}
                />
                <label htmlFor="callReportingOption1">Gespreksrapportage Instap</label>
              </div>
              <div className="input-field col s6 m6 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input
                  id="callReportingOption2"
                  type="number"
                  min="0"
                  className="validate"
                  name="callReportingOption2"
                  value={hostedVoiceOptions.callReportingOption2}
                  style={{marginBottom: 20}}
                  onChange={onChangeCallback}
                  onBlur={onQuantitySaveAction}
                  disabled={inputsDisabled}
                />
                <label htmlFor="callReportingOption2">Gespreksrapportage Standaard</label>
              </div>
              <div className="input-field col s6 m6 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input
                  id="callReportingOption3"
                  type="number"
                  min="0"
                  className="validate"
                  name="callReportingOption3"
                  value={hostedVoiceOptions.callReportingOption3}
                  style={{marginBottom: 20}}
                  onChange={onChangeCallback}
                  onBlur={onQuantitySaveAction}
                  disabled={inputsDisabled}
                />
                <label htmlFor="callReportingOption3">Gespreksrapportage Premium</label>
              </div>
              {
                hostedVoiceOptions.callReportingOption3 >= 1 && (
                  <div className="input-field col s6 m6 inner-addon">
                    <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                    <input
                      id="callReportingOption4"
                      type="number"
                      min="0"
                      className="validate"
                      name="callReportingOption4"
                      value={hostedVoiceOptions.callReportingOption4}
                      style={{marginBottom: 20}}
                      onChange={onChangeCallback}
                      onBlur={onQuantitySaveAction}
                      disabled={inputsDisabled}
                    />
                    <label htmlFor="callReportingOption4">Gespreksrapportage - Extra Wallboard</label>
                  </div>
                )
              }
            </div>
            }

            <div className="row" style={{marginBottom: 0}}>
              <div className="input-field col s12 m12" style={{marginBottom: 20}}>Aantal Webex licenties</div>
              <div className="input-field col s6 m6 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input
                  id="webexLicenceOption1"
                  type="number"
                  min="0"
                  className="validate"
                  name="webexLicenceOption1"
                  value={hostedVoiceOptions.webexLicenceOption1}
                  style={{marginBottom: 20}}
                  onChange={onChangeCallback}
                  onBlur={onQuantitySaveAction}
                  disabled={inputsDisabled}
                />
                <label htmlFor="webexLicenceOption1">Basic</label>
              </div>

              <div className="input-field col s6 m6 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input
                  id="webexLicenceOption2"
                  type="number"
                  min="0"
                  className="validate"
                  name="webexLicenceOption2"
                  value={hostedVoiceOptions.webexLicenceOption2}
                  style={{marginBottom: 20}}
                  onChange={onChangeCallback}
                  onBlur={onQuantitySaveAction}
                  disabled={inputsDisabled}
                />
                <label htmlFor="webexLicenceOption2">Standaard</label>
              </div>

              <div className="input-field col s6 m6 inner-addon">
                <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                <input
                  id="webexLicenceOption3"
                  type="number"
                  min="0"
                  className="validate"
                  name="webexLicenceOption3"
                  value={hostedVoiceOptions.webexLicenceOption3}
                  style={{marginBottom: 20}}
                  onChange={onChangeCallback}
                  onBlur={onQuantitySaveAction}
                  disabled={inputsDisabled}
                />
                <label htmlFor="webexLicenceOption3">Premium</label>
              </div>
            </div>
          </div> :
          (quotation.currentQuotation.provider === 2 &&
            <div>
              <div className="row">
                <div className="input-field col s12 m12" style={{marginBottom: 20}}>Aantal gebruikers</div>
                <div className="input-field col s4 m4 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <input
                    id="fixedUserOption1"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="fixedUserOption1"
                    value={Number(hostedVoiceOptions.fixedUserOption1).toString()}
                    style={{marginBottom: 20}}
                    onChange={onChangeCallback}
                    onBlur={onQuantitySaveAction}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="fixedUserOption1">Aantal One Net Vast gebruikers</label>
                </div>
                <div className="input-field col s2 m2 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <input
                    id="fixedUserOption2"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="fixedUserOption2"
                    value={Number(hostedVoiceOptions.fixedUserOption2).toString()}
                    style={{marginBottom: 20}}
                    onChange={onChangeCallback}
                    onBlur={onQuantitySaveAction}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="fixedUserOption2">Aantal afkoop belverkeer</label>
                </div>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <input
                    id="fixedUserOption3"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="fixedUserOption3"
                    value={Number(hostedVoiceOptions.fixedUserOption3).toString()}
                    style={{marginBottom: 20}}
                    onChange={onChangeCallback}
                    onBlur={onQuantitySaveAction}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="fixedUserOption3">Aantal One Net Flex gebruikers</label>
                </div>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <input
                    id="fixedUserOption4"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="fixedUserOption4"
                    value={Number(hostedVoiceOptions.fixedUserOption4).toString()}
                    style={{marginBottom: 20}}
                    onChange={onChangeCallback}
                    onBlur={onQuantitySaveAction}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="fixedUserOption4">Aantal One Net Combi gebruikers</label>
                </div>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <input
                    id="fixedUserOption5"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="fixedUserOption5"
                    value={Number(hostedVoiceOptions.fixedUserOption5).toString()}
                    style={{marginBottom: 20}}
                    onChange={onChangeCallback}
                    onBlur={onQuantitySaveAction}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="fixedUserOption5">Aantal One Net Mobile gebruikers</label>
                </div>
              </div>
              <div className="row">
                <div className="input-field col s12 m12" style={{marginBottom: 20}}>Receptionist opties</div>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <input
                    id="receptionistRoleCount"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="receptionistRoleCount"
                    value={Number(hostedVoiceOptions.receptionistRoleCount).toString()}
                    style={{marginBottom: 20}}
                    onChange={onChangeCallback}
                    onBlur={onQuantitySaveAction}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="receptionistOption1">Aantal One Receptie gebruikers (telefoniste bedienpost
                    add-on)</label>
                </div>
                <Input
                  s={6}
                  name="receptionistRole"
                  type='select'
                  label="Type receptionist"
                  onChange={onChangeCallback}
                  value={hostedVoiceOptions.receptionistRole}
                  style={{marginBottom: 20}}
                  multiple={false}
                  disabled={inputsDisabled}
                >
                  <option value={0}>Selecteer</option>
                  {
                    receptionTypes && receptionTypes.map((el) => {
                      return <option value={el.value}>{el.name}</option>
                    })
                  }
                </Input>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <input
                    id="receptionistOption3"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="receptionistOption3"
                    value={Number(hostedVoiceOptions.receptionistOption3).toString()}
                    style={{marginBottom: 20}}
                    onChange={onChangeCallback}
                    onBlur={onQuantitySaveAction}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="receptionistOption3">IVR / keuzemenufunctie (per laag per gebruiker)</label>
                </div>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <input
                    id="receptionistOption4"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="receptionistOption4"
                    value={Number(hostedVoiceOptions.receptionistOption4).toString()}
                    style={{marginBottom: 20}}
                    onChange={onChangeCallback}
                    onBlur={onQuantitySaveAction}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="receptionistOption4">Queue / Wachtrijfunctie</label>
                </div>
              </div>
                <div className="input-field col s12 m12" style={{marginBottom: 20}}>Unified Communications en CRM
                    Integratie Add-ons
                </div>
                <Row>
                    <Col s={6}>
                        <div className="input-field inner-addon">
                            <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                            <input
                                id="crmOption1"
                                type="number"
                                min={0}
                                max={999}
                                className="validate"
                                name="crmOption1"
                                value={Number(hostedVoiceOptions.crmOption1).toString()}
                                style={{marginBottom: 20}}
                                onChange={onChangeCallback}
                                onBlur={onQuantitySaveAction}
                                disabled={inputsDisabled}
                            />
                            <label htmlFor="crmOption1">One Integrate Cara Solo</label>
                        </div>
                        <div className="input-field inner-addon">
                            <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                            <input
                                id="crmOption2"
                                type="number"
                                min={0}
                                max={999}
                                className="validate"
                                name="crmOption2"
                                value={Number(hostedVoiceOptions.crmOption2).toString()}
                                style={{marginBottom: 20}}
                                onChange={onChangeCallback}
                                onBlur={onQuantitySaveAction}
                                disabled={inputsDisabled}
                            />
                            <label htmlFor="crmOption2">One Integrate Cara Team</label>
                        </div>
                        <div className="input-field inner-addon">
                            <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                            <input
                                id="crmOption3"
                                type="number"
                                min={0}
                                max={999}
                                className="validate"
                                name="crmOption3"
                                value={Number(hostedVoiceOptions.crmOption3).toString()}
                                style={{marginBottom: 20}}
                                onChange={onChangeCallback}
                                onBlur={onQuantitySaveAction}
                                disabled={inputsDisabled}
                            />
                            <label htmlFor="crmOption3">One Integrate Cara Unite</label>
                        </div>
                        <div className="input-field">
                            <div className="cara-solo-container">
                                {
                                    Number(hostedVoiceOptions.crmOption3) > 0 &&
                                    <Input
                                        name="crmCaraSoloOption"
                                        type='select'
                                        label="One Integrate Cara Unite Instalatie"
                                        onChange={onChangeCrmCaraSoloOptions}
                                        style={{marginBottom: 20, padding: 0}}
                                        value={hostedVoiceOptions.crmCaraSoloOption1 ? 'crmCaraSoloOption1' : (hostedVoiceOptions.crmCaraSoloOption2 ? 'crmCaraSoloOption2' : hostedVoiceOptions.crmCaraSoloOption3 ? 'crmCaraSoloOption3' : '')}
                                        multiple={false}
                                        disabled={inputsDisabled}
                                        required={true}
                                    >
                                        <option value={1}>Selecteer</option>
                                        <option value="crmCaraSoloOption1">Cara Unite Installatie Type A (per integratie o.b.v. self service)</option>
                                        <option value="crmCaraSoloOption2">Cara Unite Installatie Type B (per integratie)</option>
                                        <option value="crmCaraSoloOption3">Cara Unite Installatie Type C (per integratie)</option>
                                    </Input>
                                }
                                { hostedVoiceOptions.crmOption3 > 0 && (!hostedVoiceOptions.crmCaraSoloOption1 && !hostedVoiceOptions.crmCaraSoloOption2 && !hostedVoiceOptions.crmCaraSoloOption3) &&
                                    <p1 className="crmCaraSoloErrorMessage">{crmCaraSoloRequiredErrorMessage}</p1>
                                }
                            </div>
                        </div>
                    </Col>
                    <Col s={6}>
                        <div className="input-field inner-addon">
                            <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                            <input
                                id="crmOption4"
                                type="number"
                                min={0}
                                max={999}
                                className="validate"
                                name="crmOption4"
                                value={Number(hostedVoiceOptions.crmOption4).toString()}
                                style={{marginBottom: 20}}
                                onChange={onChangeCallback}
                                onBlur={onQuantitySaveAction}
                                disabled={inputsDisabled}
                            />
                            <label htmlFor="crmOption4">One Integrate Premium add-on (Desktop Tool voor telefonie +
                                ICT Desktop Integratie)</label>
                        </div>

                        <div className="input-field inner-addon">
                            <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                            <input
                                id="crmOption5"
                                type="number"
                                min={0}
                                max={999}
                                className="validate"
                                name="crmOption5"
                                value={Number(hostedVoiceOptions.crmOption5).toString()}
                                style={{marginBottom: 20}}
                                onChange={onChangeCallback}
                                onBlur={onQuantitySaveAction}
                                disabled={inputsDisabled}
                            />
                            <label htmlFor="crmOption5">One Integrate Basic add-on
                                (Desktop Tool voor geavanceerde telefonie)</label>
                        </div>

                        <div className="input-field inner-addon">
                            <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                            <input
                                id="crmOption6"
                                type="number"
                                min={0}
                                max={999}
                                className="validate"
                                name="crmOption6"
                                value={Number(hostedVoiceOptions.crmOption6).toString()}
                                style={{marginBottom: 20}}
                                onChange={onChangeCallback}
                                onBlur={onQuantitySaveAction}
                                disabled={inputsDisabled}
                            />
                            <label htmlFor="crmOption6">Webex voor One Net Softphone
                                (telefonie add-on; niet i.c.m. One Mobiel profiel)</label>
                        </div>

                        <div className="input-field inner-addon">
                            <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                            <input
                                id="crmOption7"
                                type="number"
                                min={0}
                                max={999}
                                className="validate"
                                name="crmOption7"
                                value={Number(hostedVoiceOptions.crmOption7).toString()}
                                style={{marginBottom: 20}}
                                onChange={onChangeCallback}
                                onBlur={onQuantitySaveAction}
                                disabled={inputsDisabled}
                            />
                            <label htmlFor="crmOption7">Webex voor One Net Messaging (UC add-on)</label>
                        </div>
                    </Col>
                </Row>
              <div className="row">
                <div className="input-field col s12 m12" style={{marginBottom: 20}}>One Call Centre opties</div>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <input
                    id="centerOption1"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="centerOption1"
                    value={Number(hostedVoiceOptions.centerOption1).toString()}
                    style={{marginBottom: 20}}
                    onChange={onChangeCallback}
                    onBlur={onQuantitySaveAction}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="centerOption1">One Call Centre Basic add-on</label>
                </div>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <input
                    id="centerOption2"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="centerOption2"
                    value={Number(hostedVoiceOptions.centerOption2).toString()}
                    style={{marginBottom: 20}}
                    onChange={onChangeCallback}
                    onBlur={onQuantitySaveAction}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="centerOption2">One Call Centre Premium add-on</label>
                </div>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <input
                    id="centerOption3"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="centerOption3"
                    value={Number(hostedVoiceOptions.centerOption3).toString()}
                    style={{marginBottom: 20}}
                    onChange={onChangeCallback}
                    onBlur={onQuantitySaveAction}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="centerOption3">One Call Centre Agent Desktop</label>
                </div>
                <div className="input-field col s6 m6 inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
                  <input
                    id="centerOption4"
                    type="number"
                    min={0}
                    max={999}
                    className="validate"
                    name="centerOption4"
                    value={Number(hostedVoiceOptions.centerOption4).toString()}
                    style={{marginBottom: 20}}
                    onChange={onChangeCallback}
                    onBlur={onQuantitySaveAction}
                    disabled={inputsDisabled}
                  />
                  <label htmlFor="callCenterAgentOption3">One Call Centre Supervisor Desktop</label>
                </div>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
};

export default HostedVoiceAddons;
