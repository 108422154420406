import React, {Component} from 'react';
import {Input, Row} from 'react-materialize'

import KpnDialog from '../../../containers/common/KpnDialog/KpnDialog';
import DialogHeader from "../../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";
import DialogFooter from "../../../containers/common/KpnDialog/DialogFooter";
import DialogButton from "../../../containers/common/KpnDialog/DialogButton";
import crmConnections from "../../../constants/crmConnections";

class OrganizationForm extends Component {
  state = {
    organization: {
      name: this.props.organization.name || '',
      customWebUrl: this.props.organization.customWebUrl || '',
      customerNumber: this.props.organization.customerNumber || '',
      crmConnection: this.props.organization.crmConnection || '',
      providers: this.props.organization.providers || [],
      allowHip: this.props.organization.allowHip,
      mobilePerLocation: this.props.organization.mobilePerLocation,
      voipPerLocation: this.props.organization.voipPerLocation,
      extensionPoints: this.props.organization.extensionPoints,
      userInsight: this.props.organization.userInsight,
      allowDigitalSigning: this.props.organization.allowDigitalSigning,
      allowLead: this.props.organization.allowLead,
      allowQAQuotation: this.props.organization.allowQAQuotation,
      showQAPrices: this.props.organization.showQAPrices,
      showQALicences: this.props.organization.showQALicences,
      versioning: this.props.organization.versioning,
      hasTermsAndConditions: this.props.organization.hasTermsAndConditions,
      hasConnectWiseSync: this.props.organization.hasConnectWiseSync,
      allowAppraisal: this.props.organization.allowAppraisal,
      canBeSwitched: this.props.organization.canBeSwitched,
    }
  };

  onChange = ({target}) => {
    let {name, value} = target;

    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        [name]: value
      }
    }));
  };

  onProviderChange = ({target}) => {
    let {value, checked} = target;
    let newProviders = [];

    if (checked) {
      newProviders = [...this.state.organization.providers, {provider: parseInt(value), organization: this.props.organization.id}];
    } else {
      newProviders = this.state.organization.providers.filter(el => el.provider !== parseInt(value));
    }

    this.setState({
      ...this.state,
      organization: {
        ...this.state.organization,
        providers: newProviders
      }
    });
  };

  onAllowHipChange = ({target}) => {
    let { name } = target;
    let value;

    value = name === 'hipActive';

    if (value) {
      value = !(value && this.state.organization.allowHip);
    } else {
      value = ! this.state.organization.allowHip;
    }

    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        allowHip: value
      }
    }));
  };

  onAllowDigitalSigning = ({target}) => {
    let { name } = target;
    let value;

    value = name === 'digitalSigningActive';

    if (value) {
      value = !(value && this.state.organization.allowDigitalSigning);
    } else {
      value = ! this.state.organization.allowDigitalSigning;
    }

    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        allowDigitalSigning: value
      }
    }));
  };
  
  onAllowLeadQuotation = ({target}) => {
    let { name } = target;
    let value;
    
    value = name === 'leadQuotationActive';
    
    if (value) {
      value = !(value && this.state.organization.allowLead);
    } else {
      value = ! this.state.organization.allowLead;
    }
    
    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        allowLead: value
      }
    }));
  };
  
  onAllowQAQuotation = ({target}) => {
    let { name } = target;
    let value;
    let {showQAPrices, showQALicences} = this.state;
    
    value = name === 'QAQuotationActive';
    
    if (value) {
      value = !(value && this.state.organization.allowQAQuotation);
    } else {
      value = ! this.state.organization.allowQAQuotation;
    }

    if (!value) {
      showQAPrices = value;
      showQALicences = value;
    }
    
    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        allowQAQuotation: value,
        showQAPrices: showQAPrices,
        showQALicences: showQALicences
      }
    }));
  };

  onShowQAPrices = ({target}) => {
    let { name } = target;
    let value;

    value = name === 'showQAPricesActive';

    if (value) {
      value = !(value && this.state.organization.showQAPrices);
    } else {
      value = ! this.state.organization.showQAPrices;
    }

    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        showQAPrices: value
      }
    }));
  };

  onShowQALicences = ({target}) => {
    let { name } = target;
    let value;

    value = name === 'showQALicences';

    if (value) {
      value = !(value && this.state.organization.showQALicences);
    } else {
      value = ! this.state.organization.showQALicences;
    }

    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        showQALicences: value
      }
    }));
  };

  onUserInsightChange = ({target}) => {
    let { name } = target;
    let value;

    value = name === 'userInsightActive';

    if (value) {
      value = !(value && this.state.organization.userInsight);
    } else {
      value = ! this.state.organization.userInsight;
    }

    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        userInsight: value
      }
    }));
  };

  onMobilePerLocationChange = ({target}) => {
    let { name } = target;
    let value;

    value = name === 'mobilePerLocationActive';

    if (value) {
      value = !(value && this.state.organization.mobilePerLocation);
    } else {
      value = ! this.state.organization.mobilePerLocation;
    }

    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        mobilePerLocation: value
      }
    }));
  };

  onVoipPerLocationChange = ({target}) => {
    let { name } = target;
    let value;

    value = name === 'voipPerLocationActive';

    if (value) {
      value = !(value && this.state.organization.voipPerLocation);
    } else {
      value = ! this.state.organization.voipPerLocation;
    }

    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        voipPerLocation: value
      }
    }));
  };

  onExtensionPointsChange = ({target}) => {
    let { name } = target;
    let value;

    value = name === 'extensionPointsActive';

    if (value) {
      value = !(value && this.state.organization.extensionPoints);
    } else {
      value = ! this.state.organization.extensionPoints;
    }

    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        extensionPoints: value
      }
    }));
  };

  onTermsAndConditionsChange = ({target}) => {
    let { name } = target;
    let value;

    value = name === 'termsAndConditionsActive';

    if (value) {
      value = !(value && this.state.organization.hasTermsAndConditions);
    } else {
      value = ! this.state.organization.hasTermsAndConditions;
    }

    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        hasTermsAndConditions: value
      }
    }));
  };

  onConnectWiseSyncChange = ({target}) => {
    let { name } = target;
    let value;

    value = name === 'connectWiseSyncActive';

    if (value) {
      value = !(value && this.state.organization.hasConnectWiseSync);
    } else {
      value = ! this.state.organization.hasConnectWiseSync;
    }

    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        hasConnectWiseSync: value
      }
    }));
  };

  onAllowAppraisalChange = ({target}) => {
    let { name } = target;
    let value;

    value = name === 'allowAppraisal';

    if (value) {
      value = !(value && this.state.organization.allowAppraisal);
    } else {
      value = ! this.state.organization.allowAppraisal;
    }

    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        allowAppraisal: value
      }
    }));
  };

  onAllowSwitchOrganizationChange = ({target}) => {
    let { name } = target;
    let value;

    value = name === 'canBeSwitched';

    if (value) {
      value = !(value && this.state.organization.canBeSwitched);
    } else {
      value = ! this.state.organization.canBeSwitched;
    }

    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        canBeSwitched: value
      }
    }));
  };

  onVersioningChange = ({ target: { name } }) => {
    let value;
    value = name === 'versioning';

    if (value) {
      value = !(value && this.state.organization.versioning);
    } else {
      value = ! this.state.organization.versioning;
    }

    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        versioning: value
      }
    }));
  };

  onSubmit = () => {
    this.props.onSubmit(this.state.organization);
  };

  onCancel = () => {
    this.props.onCancel();
  };

  render() {
    let {organization} = this.state;

    return (
      <KpnDialog
        dialogState={true}
        modal={false}
        dialogHeader={(
          <DialogHeader
            headerIcon="business"
            headerText="Organisatie"
          />
        )}

        dialogBody={
          <DialogBody>
            <Input
              s={12}
              value={organization.name}
              onChange={this.onChange}
              label='Name'
              name='name'
              type='text'
            />
            
            <Input
              s={12}
              value={organization.customWebUrl}
              onChange={this.onChange}
              label='Custom Hostname'
              name='customWebUrl'
              readOnly={true}
              type='text'
            />

            <Input
                s={12}
                value={organization.customerNumber}
                onChange={this.onChange}
                label='Customer Number'
                name='customerNumber'
                type='text'
            />

            <Input
              s={12}
              name={'crmConnection'}
              id="crmConnection"
              type="select"
              multiple={false}
              value={organization.crmConnection}
              label='Heeft deze klant een CRM koppeling'
              onChange={this.onChange}
            >
              <option value="" key={-1}>Selecteer een optie</option>

              {Object.keys(crmConnections).map((key) => {
                const item = crmConnections[key];
                return <option value={item} key={item}>{item}</option>
              })}
            </Input>

            <DialogHeader
              style={{marginTop: 0}}
              headerIcon="person"
              headerText="Portfolios"
            />
            <DialogBody>
              {this.props.providersList.map((el) =>
                <Input
                  key={el.id}
                  name='providers'
                  type='checkbox'
                  checked={organization.providers.filter((orgProvider) => orgProvider.provider === el.id).length}
                  value={el.id}
                  label={el.name}
                  onChange={this.onProviderChange}
                />
              )}
            </DialogBody>
  
            <>
              <div className="col s6 m6 l6" style={{paddingLeft: 0}}>
                <DialogHeader
                  headerIcon="settings"
                  headerText="Lead Quotation"
                />
                <DialogBody>
                  <Input
                    name='leadQuotationActive'
                    type='checkbox'
                    checked={organization.allowLead}
                    label='YES'
                    onChange={this.onAllowLeadQuotation}
                  />
                  <Input
                    name='notLeadQuotationActive'
                    type='checkbox'
                    checked={!organization.allowLead}
                    label='NO'
                    onChange={this.onAllowLeadQuotation}
                  />
                </DialogBody>
              </div>
  
              <div className="col s6 m6 l6" style={{paddingLeft: 0}}>
                <DialogHeader
                  headerIcon="settings"
                  headerText="Q&A Quotation"
                />
                <DialogBody>
                  <Input
                    name='QAQuotationActive'
                    type='checkbox'
                    checked={organization.allowQAQuotation}
                    label='YES'
                    onChange={this.onAllowQAQuotation}
                  />
                  <Input
                    name='notQAQuotationActive'
                    type='checkbox'
                    checked={!organization.allowQAQuotation}
                    label='NO'
                    onChange={this.onAllowQAQuotation}
                  />
                </DialogBody>
              </div>
            </>

            {
              organization.allowQAQuotation && (
                <>
                  <div className="col s6 m6 l6" style={{paddingLeft: 0}}>
                    <DialogHeader
                      headerIcon="settings"
                      headerText="Q&A Offerte prijzen"
                    />
                    <DialogBody>
                      <Input
                        name='showQAPricesActive'
                        type='checkbox'
                        checked={organization.showQAPrices}
                        label='Q&A Offerte prijzen tonen'
                        onChange={this.onShowQAPrices}
                      />
                      <Input
                        name='notShowQAPricesActive'
                        type='checkbox'
                        checked={!organization.showQAPrices}
                        label='Q&A Offerte prijzen niet tonen'
                        onChange={this.onShowQAPrices}
                      />
                    </DialogBody>
                  </div>

                  <div className="col s6 m6 l6" style={{paddingLeft: 0}}>
                    <DialogHeader
                      headerIcon="settings"
                      headerText="Q&A Offerte licentie"
                    />
                    <DialogBody>
                      <Input
                        name='showQALicences'
                        type='checkbox'
                        checked={organization.showQALicences}
                        label='Q&A Offerte licentie tonen'
                        onChange={this.onShowQALicences}
                      />
                      <Input
                        name='notShowQALicences'
                        type='checkbox'
                        checked={!organization.showQALicences}
                        label='Q&A Offerte licentie niet tonen'
                        onChange={this.onShowQALicences}
                      />
                    </DialogBody>
                  </div>
                </>
              )
            }
            
            <>
              <div className="col s6 m6 l6" style={{paddingLeft: 0}}>
                <DialogHeader
                  headerIcon="settings"
                  headerText="Digitaal ondertekenen"
                />
                <DialogBody>
                  <Input
                    name='digitalSigningActive'
                    type='checkbox'
                    checked={organization.allowDigitalSigning}
                    label='YES'
                    onChange={this.onAllowDigitalSigning}
                  />
                  <Input
                    name='notDigitalSigningActive'
                    type='checkbox'
                    checked={!organization.allowDigitalSigning}
                    label='NO'
                    onChange={this.onAllowDigitalSigning}
                  />
                </DialogBody>
              </div>
  
              <div className="col s6 m6 l6" style={{padding: 0}}>
                <DialogHeader
                  headerIcon="settings"
                  headerText="HIP-verbindingen toestaan"
                />
                <DialogBody>
                  <Input
                    name='hipActive'
                    type='checkbox'
                    checked={organization.allowHip}
                    label='YES'
                    onChange={this.onAllowHipChange}
                  />
                  <Input
                    name='notHipActive'
                    type='checkbox'
                    checked={!organization.allowHip}
                    label='NO'
                    onChange={this.onAllowHipChange}
                  />
                </DialogBody>
              </div>
            </>
  
            <>
              <div className="col s6 m6 l6" style={{paddingLeft: 0}}>
                <DialogHeader
                  headerIcon="settings"
                  headerText="Mobile Per Location"
                />
                <DialogBody>
                  <Input
                    name='mobilePerLocationActive'
                    type='checkbox'
                    checked={organization.mobilePerLocation}
                    label='YES'
                    onChange={this.onMobilePerLocationChange}
                  />
                  <Input
                    name='notMobilePerLocationActive'
                    type='checkbox'
                    checked={!organization.mobilePerLocation}
                    label='NO'
                    onChange={this.onMobilePerLocationChange}
                  />
                </DialogBody>
              </div>
    
              <div className="col s6 m6 l6" style={{paddingLeft: 0}}>
                <DialogHeader
                  headerIcon="settings"
                  headerText="Voip Per Location"
                />
                <DialogBody>
                  <Input
                    name='voipPerLocationActive'
                    type='checkbox'
                    checked={organization.voipPerLocation}
                    label='YES'
                    onChange={this.onVoipPerLocationChange}
                  />
                  <Input
                    name='notVoipPerLocationActive'
                    type='checkbox'
                    checked={!organization.voipPerLocation}
                    label='NO'
                    onChange={this.onVoipPerLocationChange}
                  />
                </DialogBody>
              </div>
            </>
  
            <>
              <div className="col s6 m6 l6" style={{padding: 0}}>
                <DialogHeader
                  headerIcon="settings"
                  headerText="Verbredingspunten"
                />
                <DialogBody>
                  <Input
                    name='extensionPointsActive'
                    type='checkbox'
                    checked={organization.extensionPoints}
                    label='YES'
                    onChange={this.onExtensionPointsChange}
                  />
                  <Input
                    name='notExtensionPointsActive'
                    type='checkbox'
                    checked={!organization.extensionPoints}
                    label='NO'
                    onChange={this.onExtensionPointsChange}
                  />
                </DialogBody>
              </div>
            </>

            <>
              <div className="col s6 m6 l6" style={{padding: 0}}>
                <DialogHeader
                    headerIcon="settings"
                    headerText="Algemene Voorwaarden"
                />
                <DialogBody>
                  <Input
                      name='termsAndConditionsActive'
                      type='checkbox'
                      checked={organization.hasTermsAndConditions}
                      label='YES'
                      onChange={this.onTermsAndConditionsChange}
                  />
                  <Input
                      name='noTermsAndConditionsEnabled'
                      type='checkbox'
                      checked={!organization.hasTermsAndConditions}
                      label='NO'
                      onChange={this.onTermsAndConditionsChange}
                  />
                </DialogBody>
              </div>
            </>

            <>
              <div className="col s6 m6 l6" style={{padding: 0}}>
                <DialogHeader
                    headerIcon="settings"
                    headerText="ConnectWise Sync"
                />
                <DialogBody>
                  <Input
                      name='connectWiseSyncActive'
                      type='checkbox'
                      checked={organization.hasConnectWiseSync}
                      label='YES'
                      onChange={this.onConnectWiseSyncChange}
                  />
                  <Input
                      name='noConnectWiseSyncActive'
                      type='checkbox'
                      checked={!organization.hasConnectWiseSync}
                      label='NO'
                      onChange={this.onConnectWiseSyncChange}
                  />
                </DialogBody>
              </div>
            </>
  
            <DialogHeader
              headerIcon="settings"
              headerText="Activeer gebruikers inzicht op de overzicht pagina"
            />
            <DialogBody>
              <Input
                name='userInsightActive'
                type='checkbox'
                checked={organization.userInsight}
                label='YES'
                onChange={this.onUserInsightChange}
              />
              <Input
                name='notUserInsightActive'
                type='checkbox'
                checked={!organization.userInsight}
                label='NO'
                onChange={this.onUserInsightChange}
              />
            </DialogBody>

            <DialogHeader
                headerIcon="settings"
                headerText="Activeer versie beheer"
            />
            <DialogBody>
              <Input
                  name='versioning'
                  type='checkbox'
                  checked={organization.versioning}
                  label='YES'
                  onChange={this.onVersioningChange}
              />
              <Input
                  name='notVersioning'
                  type='checkbox'
                  checked={!organization.versioning}
                  label='NO'
                  onChange={this.onVersioningChange}
              />
            </DialogBody>

            <>
              <div className="col s6 m6 l6" style={{padding: 0}}>
                <DialogHeader
                    headerIcon="settings"
                    headerText="Toestaan Prijsopgave"
                />
                <DialogBody>
                  <Input
                      name='allowAppraisal'
                      type='checkbox'
                      checked={organization.allowAppraisal}
                      label='YES'
                      onChange={this.onAllowAppraisalChange}
                  />
                  <Input
                      name='noAllowAppraisal'
                      type='checkbox'
                      checked={!organization.allowAppraisal}
                      label='NO'
                      onChange={this.onAllowAppraisalChange}
                  />
                </DialogBody>
              </div>
            </>

            <DialogHeader
                headerIcon="settings"
                headerText="Schakelorganisatie Toestaan"
            />
            <DialogBody>
              <Input
                  name='canBeSwitched'
                  type='checkbox'
                  checked={organization.canBeSwitched}
                  label='YES'
                  onChange={this.onAllowSwitchOrganizationChange}
              />
              <Input
                  name='noCanBeSwitched'
                  type='checkbox'
                  checked={!organization.canBeSwitched}
                  label='NO'
                  onChange={this.onAllowSwitchOrganizationChange}
              />
            </DialogBody>

          </DialogBody>
        }
        dialogFooter={
          <DialogFooter
            buttonLeft={(
              <DialogButton
                style={{marginTop: '30px'}}
                buttonAction={this.onCancel}
                buttonText='Annuleren'
                left={true}
              />
            )}
            buttonRight={(
              <DialogButton
                style={{marginTop: '30px'}}
                buttonAction={this.onSubmit}
                buttonText='Opslaan'
              />
            )}
          />
        }
      />
    )
  };
}

export default OrganizationForm;
