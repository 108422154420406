import React, {Component} from 'react';
import ProductsOverviewGrid from "./ProductsOverviewGrid";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import partnerServicesActions from "../../../actions/partnerServicesActions";
import Pagination from "../../../components/pagination/pagination";
import {DelayInput} from "react-delay-input";
import DialogHeader from "../../common/KpnDialog/DialogHeader";
import DialogBody from "../../common/KpnDialog/DialogBody";
import KpnDialog from "../../common/KpnDialog/KpnDialog";
import Tree from 'react-d3-tree';
import AddPartnerService from "../PartnersServices/AddPartnerService";
import {Input, Preloader} from "react-materialize";
import ProviderHelper from "../../../helper/providerHelper";
import AddHardwareOption from "../HardwareOptions/AddHardwareOption";
import hardwareActions from "../../../actions/hardwareActions";
import DialogFooter from "../../common/KpnDialog/DialogFooter";
import DialogButton from "../../common/KpnDialog/DialogButton";
import MenuItem from 'material-ui/MenuItem';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import FileDownload from "js-file-download";
import ImportHardware from "../HardwareOptions/ImportHardware";
import ImportPartnerServices from "../PartnersServices/ImportPartnerServices";
import EditPartnerService from "../PartnersServices/EditPartnerService";
import EditHardwareOption from "../HardwareOptions/EditHardwareOption";
import DefaultDialogBody from "../../common/KpnDialog/DefaultDialogBody";
import providerType from "../../../constants/providerType";
import '../index.css';
class ProductsOverviewPanel extends Component {
  sortInitialState = {
    name: false,
    createdAt: false,
    updatedAt: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      sorting: {
        ...this.sortInitialState
      },
      loading: true,
      page: 1,
      searchPhrase: '',
      active: 1,
      editDialogOpened: false,
      deleteDialogOpened: false,
      relationshipsDialogOpened: false,
      selectedProduct: {},
      treeData: [],
      selectedProductTreeData: [],
      translate: {
        x: 250,
        y: 170
      },
      manualAdditionDialogOpened: false,
      manualEditDialogOpened: false,
      manualHardwareAdditionDialogOpened: false,
      manualHardwareEditDialogOpened: false,
      serviceProvider: this.props.authentication.providers.length ? this.props.authentication.providers[0].provider : 0,
      openAssortiment: false,
      openHardware: false,
      openPartner: false,
      importHardwareDialogOpened: false,
      importPartnerDialogOpened: false,
      setToInactive: 0,
      copyProduct: 0,
      importFailureDialogOpened: false,
      importErrorMessage: '',
      importErrorTitle: '',
      file: null,
      uploadType: ''
    };
  }

  componentDidMount() {
    this.getAllProducts();
    this.props.actions.getProductsToLink(this.state.serviceProvider);
    this.props.actions.getServiceOptionCategories();
    this.props.hardwareActions.getHardwareOptionCategories();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.active !== this.state.active) {
      this.getAllProducts();
    }
    if (prevState.serviceProvider !== this.state.serviceProvider) {
      this.getAllProducts();
      this.props.actions.getProductsToLink(this.state.serviceProvider);
      this.props.actions.getServiceOptionCategories();
      this.props.hardwareActions.getHardwareOptionCategories();
    }

    if (prevState.selectedProductTreeData !== this.props.partnerServices.selectedProductTreeData) {
      this.setState({selectedProductTreeData: this.props.partnerServices.selectedProductTreeData});
    }
  }

  handleChangeProvider(event) {
    this.setState({serviceProvider: event.target.value});
  };

  getProductsByType() {
    this.setState({
      active: Number(!this.state.active)
    });
  }

  getAllProducts = (sortField, direction, page, searchQuery) => {
    let queryParams = '';
    if (sortField && direction) {
      queryParams += '?sortField=' + sortField;
      queryParams += '&sortDirection=' + direction;
    }

    if (page) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?')
      queryParams += 'page=' + page
    }
    if (searchQuery) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?')
      queryParams += 'searchQuery=' + searchQuery;
    }

    this.props.actions.getProductsForOverview(queryParams, this.state.active, this.state.serviceProvider);
  };

  changePage = ({target}) => {
    const {value} = target;
    this.setState({page: value});

    const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false);
    const sortValue = this.state.sorting[sortField];

    this.getAllProducts(sortField, sortValue, value, this.state.searchPhrase);
  };

  onSearch = ({target}) => {
    let {name, value} = target;

    const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false);
    const sortValue = this.state.sorting[sortField];

    this.setState({searchPhrase: value});

    this.getAllProducts(sortField, sortValue, this.state.page, value)
  };

  onSortChange = ({target}) => {
    const headerName = target.getAttribute('headername');
    const propertyName = this.getHeaderPropertyForName(headerName);

    if (!propertyName) {
      return;
    }

    this.changeSortDirection(propertyName, this.getSortDirection(propertyName));
  };

  getSortDirection = (propertyName) => {
    const sortDirection = this.state.sorting[propertyName];
    switch (sortDirection) {
      case false:
        return "ASC";
      case "ASC":
        return "DESC";
      case "DESC":
        return false
    }
  };

  changeSortDirection = (propertyName, direction) => {
    this.setState({sorting: {...this.sortInitialState, [propertyName]: direction}});

    this.getAllProducts(propertyName, direction, this.state.page, this.state.searchPhrase)
  };

  getHeaderPropertyForName = (displayName) => {
    const index = this.tableHeaderNames.indexOf(displayName);
    return this.tableObjectProperties[index];
  };

  onEdit = (product) => {
    this.setState({
      editDialogOpened: true
    });

    this.props.actions.getProductTreeData(product.id).then(() => {
      this.setState({
        selectedProduct: {
          ...product,
          treeData: this.state.selectedProductTreeData,
          linking: this.state.selectedProductTreeData.length ? 'Linked' : 'Unlinked'
        }
      });
    });
  };

  onClose = (product) => {
    this.setState({
      deleteDialogOpened: true
    });

    this.props.actions.getProductTreeData(product.id).then(() => {
      this.setState({
        selectedProduct: {
          ...product,
          treeData: this.state.selectedProductTreeData,
          linking: this.state.selectedProductTreeData.length ? 'Linked' : 'Unlinked'
        }
      });
    });
  };

  viewRelationships = (product) => {
    this.setState({
      loading: true,
      relationshipsDialogOpened: true,
      selectedProduct: product
    });

    this.props.actions.getProductTreeData(product.id).then(() => {
      const treeData = [
        {
          name: product.name,
          nodeSvgShape: {
            shape: 'circle',
            shapeProps: {
              r: 5,
              fill: 'white',
            },
          },
          children: this.state.selectedProductTreeData || [],
        },
      ];

      this.setState({
        loading: false,
        treeData: treeData
      });
    });
  };

  closeEditDialog = () => {
    this.setState({
      editDialogOpened: false,
      selectedProduct: {}
    });
  };

  closeDeleteDialog = () => {
    this.setState({
        deleteDialogOpened: false,
        selectedProduct: {}
    });
  };

  closeRelationshipsDialog = () => {
    this.setState({
      relationshipsDialogOpened: false
    });
  };

  changeActiveProp = (value) => {
    let serviceData = {
      active: value
    };

    this.props.actions.patchService(this.state.selectedProduct.id, serviceData, this.state.active).then(() => {
      this.closeEditDialog();
      this.closeDeleteDialog();
      this.getAllProducts();
    });
  };

  duplicateProduct = (keepRelations, keepActive) => {
    this.props.actions.duplicateService(this.state.selectedProduct.id, keepRelations, keepActive).then(() => {
      this.closeEditDialog();
      this.getAllProducts();
    });
  };

  _handleManualAdditionDialogOpen = () => {
    this.setState({
      manualAdditionDialogOpened: true,
      editDialogOpened: false,
      openPartner: false,
      importPartnerDialogOpened: false,
    });
  };

  _handleManualAdditionDialogClose = () => {
    this.setState({manualAdditionDialogOpened: false});
  };

  _handleManualEditDialogOpen = () => {
    this.setState({
      manualEditDialogOpened: true,
      editDialogOpened: false,
      openPartner: false,
      importPartnerDialogOpened: false,
    });
  };

  _handleManualEditDialogClose = () => {
    this.setState({manualEditDialogOpened: false});
  };

  _handleImportFailureDialogClose = () => {
    this.setState({importFailureDialogOpened: false});
  };

  _onAddClick = (data) => {
    data = {newServiceData : data} ;
    const response = this.props.actions.addPartnerService(data, this.state.serviceProvider, true);
    response.then(() => {
      this.getAllProducts();
      this.setState({
        manualAdditionDialogOpened: false,
      });
    });
  };

  _onEditClick = (data) => {
    const response = this.props.actions.patchService(data.id, data);
    response.then(() => {
      this.getAllProducts();
      this.setState({
        manualEditDialogOpened: false,
        selectedProduct: {}
      });
    });
  };

  _handleManualHardwareAdditionDialogOpen = () => {
    this.setState({
      manualHardwareAdditionDialogOpened: true,
      editDialogOpened: false,
      openHardware: false,
      importHardwareDialogOpened: false,
      selectedProduct: {}
    });
  };

  _handleManualHardwareAdditionDialogClose = () => {
    this.setState({manualHardwareAdditionDialogOpened: false});
  };

  _handleManualHardwareEditDialogOpen = () => {
    this.setState({
      manualHardwareEditDialogOpened: true,
      editDialogOpened: false,
      openHardware: false,
      importHardwareDialogOpened: false,
      selectedProduct: {}
    });
  };

  _handleManualHardwareEditDialogClose = () => {
    this.setState({manualHardwareEditDialogOpened: false});
  };

  _onHardwareAddClick = (data) => {
    data = {newHardwareData : data} ;
    const response = this.props.hardwareActions.addHardwareOption(data, this.state.serviceProvider, true);
    response.then(() => {
      this.getAllProducts();
      this.setState({
        manualHardwareAdditionDialogOpened: false,
      });
    });
  };

  _onHardwareEditClick = (data) => {
    const response = this.props.actions.patchService(data.id, data);
    response.then(() => {
      this.getAllProducts();
      this.setState({
        manualHardwareAdditionDialogOpened: false,
        selectedProduct: {}
      });
    });
  };

  handleClickMenu = (event, type) => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
        [type]: true,
        anchorEl: event.currentTarget,
    });
  };

  handleRequestCloseMenu = (type) => {
    this.setState({
        [type]: false,
    });
  };

  getHardwareAssortimentFile = () => {
    this.setState({
        openAssortiment: false
    });
    this.props.hardwareActions.getAssortimentFile(this.state.serviceProvider).then(() => {
        const providerName = ProviderHelper.getQuotationProviderName(this.state.serviceProvider);
        FileDownload(this.props.hardware.assortimentFile, providerName + '-assortiment-hardwareopties.xlsx');
    });
  };

  getPartnerAssortimentFile = () => {
    this.setState({
        openAssortiment: false
    });
    this.props.actions.getAssortimentFile(this.state.serviceProvider).then(() => {
      const providerName = ProviderHelper.getQuotationProviderName(this.state.serviceProvider);
        FileDownload(this.props.partnerServices.assortimentFile, providerName + '-assortiment-partnerservices.xlsx');
    });
  };

  _handleHardwareDialogOpen = () => {
    this.setState({
        importHardwareDialogOpened: true,
        openHardware: false
    });
  };

  _handleHardwareDialogClose = () => {
    this.setState({importHardwareDialogOpened: false});
  };

  _handlePartnerDialogOpen = () => {
    this.setState({
        importPartnerDialogOpened: true,
        openPartner: false
    });
  };

  _handlePartnerDialogClose = () => {
    this.setState({importPartnerDialogOpened: false});
  };

  _changeOptions = ({target}) => {
    const {name, checked} = target;
    this.setState({[name]: checked});
  };

  _onDrop = (files, type) => {
    let file = files[0];

    if (!file)
        return;

    this.setState({
      file: file,
      uploadType: type
    });
  };

  saveFile = () => {
    let action = this.props.actions;
    const {uploadType, file} = this.state;
    if (uploadType === 'hardware') {
      action = this.props.hardwareActions;
    }
    const response = action.uploadBulk(file, this.state.serviceProvider,
        {setToInactive: this.state.setToInactive, copyProduct: this.state.copyProduct}, true);
    response.then((val) => {
      if (val && (val.error || !val.success)) {
        let errorTitle = 'Upload mislukt';
        let errorMessage = '';
        if (val.error) {
          errorMessage = val.error.response.data;
        } else if (val.options_with_errors && val.options_with_errors.length) {
          errorMessage = 'De volgende producten bevatten geen uniek ID: ';
          val.options_with_errors.forEach((el) => {
            errorMessage += el + '\n';
          });
        } else if (val.validation_error) {
          errorTitle = 'Bulk geweigerd';
          errorMessage = val.validation_error;
        } else {
          errorMessage = val;
        }
        this.setState({
          importErrorTitle: errorTitle,
          importErrorMessage: errorMessage,
          importFailureDialogOpened: true
        })
      } else {
        this.setState({file: file});
        this.getAllProducts();
      }
    });

    if (uploadType === 'hardware') {
      this._handleHardwareDialogClose();
    } else {
      this._handlePartnerDialogClose();
    }
  };

  tableHeaderNames = ['Naam product', 'Product type', 'Portfolio', '€ p.m.', 'Door wie gemaakt', 'Aangemaakt',
    'laatst gewijzigd', 'Status', 'Acties'];
  tableObjectProperties = ['name', 'type', 'provider', 'purchasePricePerMonth', 'user', 'createdAt', 'updatedAt', 'active', ''];

  render() {
    const {allProductsForOverview, currentPage, pages} = this.props.partnerServices;

    const partnerProduct = ['partner', 'it'].includes(this.state.selectedProduct.type);
    const isDbitPortfolioSelected = Number(this.state.serviceProvider) === providerType.DBIT;

    return (
      <div>
        <div id="Forms" className="ratiotable paddingbottomnone">
          <h1 className="admin-subheader-text">
            <span className="ratio-section-title">Product overzicht</span>
          </h1>
        </div>

        <div className="overviewkopbg ratiotable row">
          <div className="col s3">
            <div className="overviewkop">
              <form>
                <div className="input-field margingone inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">search</i>
                  <DelayInput delayTimeout={800} id="search" type="search" name="searchPhrase"
                              value={this.state.searchPhrase} onChange={this.onSearch}/>
                </div>
              </form>
            </div>
          </div>
          <div className="col s9">
            <button
                className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
                type="submit"
                name="action"
                onClick={(event) => this.handleClickMenu(event, 'openPartner')}>
              <i className="small material-icons left white-text">add</i>
              Partner/It
            </button>
              <Popover
                  open={this.state.openPartner}
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                  targetOrigin={{horizontal: 'left', vertical: 'top'}}
                  onRequestClose={() => this.handleRequestCloseMenu('openPartner')}
              >
                  <Menu>
                      {/*<MenuItem
                          primaryText="Manually" onClick={() => this._handleManualAdditionDialogOpen()}/>*/}
                      <MenuItem
                          primaryText="Bulk upload" onClick={() => this._handlePartnerDialogOpen()}/>
                  </Menu>
              </Popover>
            <button
                className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
                type="submit"
                name="action"
                onClick={(event) => this.handleClickMenu(event, 'openHardware')}>
              <i className="small material-icons left white-text">add</i>
              Hardware
            </button>
              <Popover
                  open={this.state.openHardware}
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                  targetOrigin={{horizontal: 'left', vertical: 'top'}}
                  onRequestClose={() => this.handleRequestCloseMenu('openHardware')}
              >
                  <Menu>
                      {/*<MenuItem primaryText="Manually" onClick={() => this._handleManualHardwareAdditionDialogOpen()}/>*/}
                      <MenuItem primaryText="Bulk upload" onClick={() => this._handleHardwareDialogOpen()}/>
                  </Menu>
              </Popover>
            <button
                className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
                type="submit"
                name="action"
                onClick={() => this.getProductsByType()}>
              <i className="small material-icons left white-text">remove_red_eye</i>
              Toon {this.state.active ? 'inactive' : 'active'} producten
            </button>
            <button
                className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
                onClick={(event) => this.handleClickMenu(event, 'openAssortiment')}
                type="submit"
                name="action">
              <i className="small material-icons left white-text">save_alt</i>
              Download assortiment
            </button>
            <Popover
              open={this.state.openAssortiment}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
              targetOrigin={{horizontal: 'left', vertical: 'top'}}
              onRequestClose={() => this.handleRequestCloseMenu('openAssortiment')}
            >
              <Menu>
                  {!isDbitPortfolioSelected &&
                    <MenuItem primaryText="Hardware" onClick={() => this.getHardwareAssortimentFile()} />
                  }

                  <MenuItem primaryText="Partner/It" onClick={() => this.getPartnerAssortimentFile()} />
              </Menu>
            </Popover>
            <div className="hardware-provider-overview">
              <Input
                  name={'serviceProvider'}
                  id={'serviceProvider'}
                  type="select"
                  multiple={false}
                  required={true}
                  defaultValue={0}
                  onChange={(e) => this.handleChangeProvider(e)}
                  className="validate">
                <option value="-1" disabled={'disabled'} key={-1}>Kies provider</option>
                {this.props.authentication.providers.filter((el => el.provider !== 1)).map((el) => <option
                    value={el.provider}
                    key={el.provider}>{ProviderHelper.getQuotationProviderName(el.provider)}</option>)}
              </Input>
            </div>
          </div>
        </div>

        <ProductsOverviewGrid
          rows={allProductsForOverview}
          onHeaderClick={this.onSortChange}
          sortingState={this.state.sorting}
          getPropertyNameFromDisplayName={this.getHeaderPropertyForName}
          headerNames={this.tableHeaderNames}
          onEditClick={this.onEdit}
          onViewClick={this.viewRelationships}
          onCloseClick={this.onClose}
        />

        <div className="col s12 sendendform">
          <div className="col s12 m10">
            <Pagination page={currentPage}
                        pages={pages}
                        changePage={this.changePage}
            />
          </div>
        </div>

        <KpnDialog
          dialogState={this.state.deleteDialogOpened}
          modal={false}
          dialogHeader={(
              <DialogHeader
                  headerIcon="close"
                  headerText="Deactiveer dit product"
                  closeHandler={this.closeDeleteDialog}
              />
          )}
          dialogBody={
              <DialogBody>
                  Are you sure you want to set product to inactive?
              </DialogBody>
          }
          dialogFooter={
              <DialogFooter
                  buttonLeft={(
                      <DialogButton
                          buttonAction={this.closeDeleteDialog}
                          buttonText='No'
                          left={true}
                      />
                  )}
                  buttonRight={(
                      <DialogButton
                          buttonAction={() => this.changeActiveProp(0)}
                          buttonText='Yes'
                      />
                  )}
              />
          }
        />

        <KpnDialog
            dialogState={this.state.editDialogOpened}
            modal={false}
            dialogHeader={(
                <DialogHeader
                    headerIcon="edit"
                    headerText="Wat wenst u te doen?"
                    closeHandler={this.closeEditDialog}
                />
            )}
            dialogBody={
              <DialogBody>
                {this.state.active ?
                    <div>
                      <button onClick={() => partnerProduct ? this._handleManualEditDialogOpen() :
                          this._handleManualHardwareEditDialogOpen()}
                              className="btn doceri-btn-right ratio-btn-right products-overview-btn" type="submit"
                              name="action-1">
                        Bestaande product details wijzigen
                      </button>
                      < button onClick={() => this.duplicateProduct(1, 1)}
                               className="btn doceri-btn-right ratio-btn-right products-overview-btn" type="submit"
                               name="action-2">
                        Dupliceer
                      </button>
                      <button onClick={() => this.duplicateProduct(0, 1)}
                              className="btn doceri-btn-right ratio-btn-right products-overview-btn" type="submit"
                              name="action-3">
                        Dupliceer zonder behoud van relaties
                      </button>
                      <button onClick={() => this.duplicateProduct(1, 0)}
                              className="btn doceri-btn-right ratio-btn-right products-overview-btn" type="submit"
                              name="action-4">
                        Deactiveer dit product en dupliceer met relaties
                      </button>
                      <button onClick={() => this.changeActiveProp(0)}
                              className="btn doceri-btn-right ratio-btn-right products-overview-btn" type="submit"
                              name="action-5">
                        Deactiveer dit product
                      </button>
                    </div> :
                    <div>
                      <button onClick={() => this.changeActiveProp(1)}
                              className="btn doceri-btn-right ratio-btn-right products-overview-btn" type="submit"
                              name="action-1">
                        Activeer dit product
                      </button>
                      <button onClick={() => this.duplicateProduct(1, 0)}
                              className="btn doceri-btn-right ratio-btn-right products-overview-btn" type="submit"
                              name="action-2">
                        Dupliceer dit product naar een actieve kopie met dezelfde relaties
                      </button>
                      <button onClick={() => this.duplicateProduct(0, 0)}
                              className="btn doceri-btn-right ratio-btn-right products-overview-btn" type="submit"
                              name="action-3">
                        Dupliceer dit product naar een actieve kopie zonder relaties
                      </button>
                    </div>
                }
              </DialogBody>
            }
        />

        <KpnDialog
            width={'none'}
            fixedWidth={'97%'}
            dialogState={this.state.relationshipsDialogOpened}
            modal={false}
            dialogHeader={(
                <DialogHeader
                    headerIcon="remove_red_eye"
                    headerText="Product relaties"
                    closeHandler={this.closeRelationshipsDialog}
                />
            )}
            dialogBody={
              <DialogBody>
                <div id="treeWrapper" style={{
                  height: '20em',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  {this.state.loading ?
                    <Preloader size='big'/> :
                    <Tree data={this.state.treeData}
                      pathFunc="diagonal"
                      nodeSize={{x: 500, y: 140}}
                      translate={this.state.translate}
                    />
                  }
                </div>
              </DialogBody>
            }
        />

        <AddPartnerService dialogOpened={this.state.manualAdditionDialogOpened}
           onRequestClose={this._handleManualAdditionDialogClose}
           tab={"partner en IT Diensten"}
           onClickFunc={this._onAddClick}
           productsToLink={this.props.partnerServices.productsToLink}
           categories={this.props.partnerServices.serviceOptionCategories.categories}
           isQAQuotation={this.props.authentication.allowQAQuotation}
        />

        <EditPartnerService dialogOpened={this.state.manualEditDialogOpened}
           onRequestClose={this._handleManualEditDialogClose}
           tab={"partner en IT Diensten"}
           onClickFunc={this._onEditClick}
           productsToLink={this.props.partnerServices.productsToLink}
           categories={this.props.partnerServices.serviceOptionCategories.categories}
           editProduct={this.state.selectedProduct}
           isQAQuotation={this.props.authentication.allowQAQuotation}
        />

        <AddHardwareOption dialogOpened={this.state.manualHardwareAdditionDialogOpened}
           onRequestClose={this._handleManualHardwareAdditionDialogClose}
           tab={'hardware opties'}
           onClickFunc={ this._onHardwareAddClick}
           productsToLink={this.props.partnerServices.productsToLink}
           categories={this.props.hardware.hardwareOptionCategories.categories}
           isQAQuotation={this.props.authentication.allowQAQuotation}
        />

        <EditHardwareOption dialogOpened={this.state.manualHardwareEditDialogOpened}
           onRequestClose={this._handleManualHardwareEditDialogClose}
           tab={'hardware opties'}
           onClickFunc={this._onHardwareEditClick}
           productsToLink={this.props.partnerServices.productsToLink}
           categories={this.props.hardware.hardwareOptionCategories.categories}
           editProduct={this.state.selectedProduct}
           isQAQuotation={this.props.authentication.allowQAQuotation}
        />

        <ImportHardware dialogOpened={this.state.importHardwareDialogOpened}
          onRequestClose={this._handleHardwareDialogClose}
          changeOptions={this._changeOptions}
          save={this.saveFile}
          onDrop={(files) => this._onDrop(files, 'hardware')}
          droppedFile={this.state.file}
          manualAddition={this._handleManualHardwareAdditionDialogOpen}
          setToInactive={this.state.setToInactive}
          copyProduct={this.state.copyProduct}
        />

        <ImportPartnerServices dialogOpened={this.state.importPartnerDialogOpened}
          onRequestClose={this._handlePartnerDialogClose}
          changeOptions={this._changeOptions}
          save={this.saveFile}
          onDrop={(files) => this._onDrop(files, 'partner')}
          droppedFile={this.state.file}
          manualAddition={this._handleManualAdditionDialogOpen}
          setToInactive={this.state.setToInactive}
          copyProduct={this.state.copyProduct}
        />

        <KpnDialog
            dialogState={this.state.importFailureDialogOpened}
            onRequestClose={this._handleImportFailureDialogClose}
            dialogHeader={
              <DialogHeader
                  headerText={this.state.importErrorTitle}
                  headerIcon='location_on'
                  closeHandler={this._handleImportFailureDialogClose}
              />
            }
            dialogBody={
              <DialogBody>
                <DefaultDialogBody text={this.state.importErrorMessage}/>
              </DialogBody>
            }
            dialogFooter={
              <DialogFooter
                  buttonLeft={
                    <DialogButton
                        left={true}
                        buttonAction={this._handleImportFailureDialogClose}
                        buttonText='Melding sluiten'
                    />
                  }
              />
            }
        />
      </div>
    )
  }
}

const mapStateToProps = ({partnerServices, authentication, hardware}) => {
  return {
    partnerServices,
    authentication,
    hardware,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(partnerServicesActions, dispatch),
    hardwareActions: bindActionCreators(hardwareActions, dispatch)
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductsOverviewPanel));
