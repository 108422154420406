import styled from 'styled-components';

export default styled.div`
  a:hover {
    color: ${props => props.theme.secondaryColor};
  }
  
  .green-text {
    color: #090;
  }
  
  .yellow-text {
    color:#d9b800;
  }
  
  .red-text {
    color:#DD000D;
  }
  
  button div span {
    color: #4c4c4c !important;
  }
	
  .themeprimarycolor {
    color: ${props => props.theme.primaryColor};
  }
  
  .bulkupload {
    border-color: ${props => props.theme.primaryColor};
  }
  
  .logincontainer {
      border-color: ${props => props.theme.primaryColor};
  }
  .popupcontainer {
      border-color: ${props => props.theme.primaryColor};
  }
  
  [type="radio"]:checked + label:after {
    background-color: ${props => props.theme.primaryColor} !important;
    border-color: ${props => props.theme.primaryColor} !important;
  }
  
  .dropdown-content li > a, .dropdown-content li > span {
    color: ${props => props.theme.primaryColor};
  }
  
  .application-tab .active-btn,
  .activeStep {
    background-color: ${props => props.theme.primaryColor} !important;
    color: white !important;
  }
  
  .line {
    border-top: 1px dashed ${props => props.theme.primaryColor};
    margin:15px 0px 30px 0px;
  }
  .line30 {
    border-top: 1px dashed ${props => props.theme.primaryColor};
    margin:30px 0px;
  }
  
  thead th {
    color: ${props => props.theme.theadTextColor} !important;
  }
  .overviewkopbg{
    border:1px solid ${props => props.theme.primaryColor};
    background-color: #fff;
  }
  .overviewkopbgtotal{
    border:1px solid ${props => props.theme.primaryColor};
    background-color: ${props => props.theme.primaryColor} !important;
    color: #fff;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
  }
  .overviewkopbgalleen{
    border:1px solid ${props => props.theme.primaryColor};
  }
  
  .overviewkopbggroen {
	background-color:${props => props.theme.primaryColor};
  }
  .blokborder{
    border: solid ${props => props.theme.primaryColor};
  }
  .blokbordertable{
    border: solid ${props => props.theme.primaryColor};
  }
  table{
    border:1px solid ${props => props.theme.primaryColor};
    border-right:2px solid ${props => props.theme.primaryColor};
    border-top:0px solid ${props => props.theme.primaryColor};
  }
    
  .tussenkop {
    color: ${props => props.theme.primaryColor};
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
    padding-right: 15px;
    display: grid;
  }
  
  .select-wrapper span.caret {
    color: ${props => props.theme.primaryColor};
  }
  
  #Locations, #Forms {
  	border-color: ${props => props.theme.primaryColor};
  }
  
  .Forms {
    border-color: ${props => props.theme.primaryColor};
  }
  
  #Locationsearch{
      border-color: ${props => props.theme.primaryColor};
  }
  #Locationslist a:hover {
    color: #ffffff;
    background-color: ${props => props.theme.secondaryColor} !important;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
  }
  .active-location {
    background-color: ${props => props.theme.primaryColor} !important;
    color: #fff !important;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
  }
  /* Logo */
  .logo-large, .logo-large-digital-signing {
    border-color: ${props => props.theme.primaryColor};
  }
  
  /* Buttons */
  .btn, .btn-large {
      background-color: ${props => props.theme.primaryColor};
  }
  .btn:hover, .btn-large:hover {
    background-color: ${props => props.theme.secondaryColor};
    color: #fff;
  }
  .btn:focus, .btn-large:focus, .btn-floating:focus {
      background-color: ${props => props.theme.primaryColor};
  }
  
  .ratio-btn:hover{
    color: #fff;
    background-color: ${props => props.theme.secondaryColor};
  }
  
  .ratio-btn-right-fullwidth{
    color: #fff;
    background-color: ${props => props.theme.secondaryColor};
      border: 1px solid ${props => props.theme.secondaryColor};
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
    width: 100%;
    text-align: center;
  }
  .ratio-btn-white-right{
    color: #fff;
    background-color: ${props => props.theme.secondaryColor};
      border: 1px solid ${props => props.theme.secondaryColor};
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
  }
  
  .ratio-btn-right {
    color: #fff;
	  font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
	  background-color: ${props => props.theme.secondaryColor};
  	border: 1px solid ${props => props.theme.secondaryColor};
  }
  
  .doceri-btn-left {
    background: #FFF !important;
    color: #4c4c4c !important;
   }
   
    .doceri-btn-left:hover {
      color: #FFF !important;
      background-color: ${props => props.theme.secondaryColor} !important;
    }
   
    .doceri-btn-left.active {
      background-color: ${props => props.theme.secondaryColor} !important;
      color: #FFF !important;
    }
  
  .ratio-btn-right:hover{
    color: #fff;
	  background-color: ${props => props.theme.primaryColor};
  	border: 1px solid ${props => props.theme.primaryColor};
  }
  
  .ratio-btn-right-fullwidth:hover{
    color: #fff;
    background-color: ${props => props.theme.primaryColor};
      border: 1px solid ${props => props.theme.primaryColor};
  }
  .ratio-btn-white-right:hover{
    color: #fff;
    background-color: ${props => props.theme.primaryColor};
      border: 1px solid ${props => props.theme.primaryColor};
  }
  .ratio-btn-white-right:focus{
    color: #333;
    background-color: #fff;
    border: 1px solid ${props => props.theme.secondaryColor};
  }
  .ratio-btn-steps{
    border: 1px solid ${props => props.theme.primaryColor};
    margin-right: 15px;
    margin-bottom: 15px;
    color: ${props => props.theme.mainTextColor};
    background-color: transparent;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
  }
  
  .ratio-btn-steps:hover{
    color: #fff;
    background-color: ${props => props.theme.secondaryColor} !important;
    border: 1px solid ${props => props.theme.secondaryColor};
  }
  
  .ratio-btn-steps-disabled{
    border: 1px solid #DFDFDF;
    margin-right: 15px;
    margin-bottom: 15px;
    color: #9F9F9F !important;
    background-color: #DFDFDF !important;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
  }
  
  .ratio-btn-steps-disabled:hover{
    color: #fff;
    background-color: #DFDFDF !important;
    border: 1px solid #DFDFDF;
  }
  
  .ratio-btn-locations {
    color: #4c4c4c;
    background-color: #fff;
    width: 100%;
    padding: 0px 3%;
    text-overflow: ellipsis;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
  }
  
  .ratio-btn-locations:hover{
    color: #4c4c4c;
    background-color: ${props => props.theme.secondaryColor};
  }
  
  .input-field input[type="text"].valid {
     border-bottom: 1px solid ${props => props.theme.formValidColor} !important;
     box-shadow: 0 1px 0 0 ${props => props.theme.formValidColor} !important;
  }
   
  [type="checkbox"].filled-in:not(:checked) + label::after {
      border-color: ${props => props.theme.primaryColor};
  }
  [type="checkbox"].filled-in:checked + label::after {
    border-color: ${props => props.theme.primaryColor};
    background-color: ${props => props.theme.primaryColor};
  }
   
  .pagination li.active {
    background-color: ${props => props.theme.primaryColor};
  }
  
  .bordered {
    border-color: ${props => props.theme.primaryColor};
  }

   nav {
    height: 44px;
    line-height: 44px !important;
    color: rgb(88, 88, 90);
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid ${props => props.theme.primaryColor} !important;
    box-shadow: none !important;
   }
   
   nav .nav-wrapper {
     display: flex;
     justify-content: end;
   }
   
   nav .nav-wrapper i {
    height: 44px;
    line-height: 44px !important;
    color: ${props => props.theme.primaryColor};
   }
   
   .technical-person-btn, 
   .project-manager-person-btn {
    border-top: 1px dashed ${props => props.theme.primaryColor};
    height: 2rem !important;
    line-height: 2rem !important;
    margin: 15px 0 30px 2px;
    text-transform: none;
    flex-grow: 1;
   }
   
  [type="checkbox"] + label {
    text-align: left;
  }
  
  .akb-table .rt-tbody .rt-tr-group {
    flex: none !important;
  }
  
  .secondaryBackgroundColor {
      background-color: ${props => props.theme.secondaryColor} !important;
  }
`;

// #090 = primary
// 00aaff = secondary
