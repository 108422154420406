import {
    actionTypes
} from '../constants/actionTypes';

const initialState = {
    exampleFile: '',
    assortimentFile: '',
    allProducts: {
        linked: [],
        unlinked: [],
    },
    selectedProductTreeData: [],
    mkbProducts: [],
    mkbOptions: [],
    mkbSelections: [],
    securityCategories: [],
    securityOptions: [],
    securitySelections: [],
    securitySelectedCategories: [],
    allProductsForOverview: [],
    pages: 1,
    currentPage: 1,
    resultsCount: 0,
    productsToLink: [],
    serviceOptionCategories: [],
    partnerServicesOptions: [],
    itServicesOptions: [],
    partnerServicesSelections: [],
    itServicesSelections: [],
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.partnerServicesDownloadExampleFile:
            return {
              ...state,
              exampleFile: action.payload
            };
        case actionTypes.partnerServicesDownloadAssortimentFile:
            return {
                ...state,
                assortimentFile: action.payload
            };
        case actionTypes.uploadBulk:
            return {
                ...state,
                allProducts: [...state.allProducts, ...action.payload]
            };
        case actionTypes.setPartnerServicesProducts:
            return {
                ...state,
                allProducts: action.payload
            };
        case actionTypes.partnerServicesOpenImportDialog:
            return {
                ...state,
                importDialogOpened: true
            };
        case actionTypes.partnerServicesCloseImportDialog:
            return {
                ...state,
                importDialogOpened: false
            };
        case actionTypes.partnerServicesToggleImportLoading:
            return {
                ...state,
                importLoading: !state.importLoading
            };
        case actionTypes.partnerServicesSetImportDialogData:
            return {
                ...state,
                importDialogData: action.payload
            };
        case actionTypes.setPartnerServicesOptions:
            return {
                ...state,
                partnerServicesOptions: action.payload
            };
        case actionTypes.setITServicesOptions:
            return {
                ...state,
                itServicesOptions: action.payload
            };
        case actionTypes.setPartnerServiceSelections:
            return {
                ...state,
                partnerServicesSelections: action.payload
            };
        case actionTypes.setITServiceSelections:
            return {
                ...state,
                itServicesSelections: action.payload
            };
        case actionTypes.setProductsToLink:
            return {
                ...state,
                productsToLink: action.payload
            };
        case actionTypes.setServiceOptionCategories:
            return {
                ...state,
                serviceOptionCategories: action.payload
            };
        case actionTypes.getProductsForOverview:
            return {
                ...state,
                allProductsForOverview: action.payload
            };
        case actionTypes.getProductTreeData:
            return {
                ...state,
                selectedProductTreeData: action.payload
            };
        case actionTypes.getMbkProducts:
            return {
                ...state,
                mkbProducts: action.payload.products,
                mkbOptions: action.payload.options,
                mkbSelections: action.payload.selections
            };
        case actionTypes.getSecurityOptions:
            return {
                ...state,
                securityCategories: action.payload.categories,
                securityOptions: action.payload.options,
                securitySelections: action.payload.selections,
                securitySelectedCategories: action.payload.selectedCategories
            }
        case actionTypes.savePagination:
            return {
                ...state,
                pages : action.payload.pages,
                currentPage: action.payload.currentPage,
                resultsCount: action.payload.resultsCount
            };
        default:
            return state;
    }
}
