import {
    actionTypes
} from '../constants/actionTypes';

const initialState = {
    newFile: {
        name: '',
        category: ''
    },
    documents: [],
    downloadedDocument: '',
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.getDownloads:
            return {
                ...state,
                documents: action.response,
            };
        case actionTypes.downloadRatioGuideFile:
            return {
                ...state,
                downloadedDocument: action.response
            };
        case actionTypes.editRatioGuideFile:
            return {
                ...state,
                documents: state.documents.map( (document) => document.id === action.response.id ? action.response : document )
            };
        default:
            return state;
    }
}
