import {
  actionTypes
} from '../constants/actionTypes';

const initialState = {
  documents: [],
  documentBlob: '',
};

export default (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.getQuotationDocuments:
      return {
        ...state,
        documents: action.response,
      };
    case actionTypes.addQuotationDocument:
      return {
        ...state,
        documents: [...state.documents, action.response]
      };
    case actionTypes.downloadQuotationDocument:
      return {
        ...state,
        documentBlob: action.response
      };
    default:
      return state;
  }
}
