import DialogBody from "../../containers/common/KpnDialog/DialogBody";
import React, {Component} from "react";
import KpnDialog from "../../containers/common/KpnDialog/KpnDialog";
import DialogHeader from "../../containers/common/KpnDialog/DialogHeader";
import DialogFooter from "../../containers/common/KpnDialog/DialogFooter";
import DialogButton from "../../containers/common/KpnDialog/DialogButton";

class ChangeAddressDialog extends Component {
  render() {
    const {dialogOpened, onSave, selectedCustomer, updateCompanyInfo, fieldNotFilled} = this.props;

    return (
      <KpnDialog
        dialogState={dialogOpened}
        modal={false}
        dialogHeader={(
          <DialogHeader
            headerText="De volgende velden dienen juist gevuld te worden."
          />
        )}

        dialogBody={
          <DialogBody>
            <div style={{ marginBottom: '3rem' }}>
              Het veld huisnummer is leeg. Wij vermoeden dat het huisnummer bij de straatnaam staat.
              Zou u aub de drie velden hieronder juist willen invullen voor dit adres. Dit is nodig om te kunnen orderen.
            </div>

            {fieldNotFilled &&
              <div style={{ marginBottom: '3rem', color: 'red', fontStyle: 'italic' }}>
                Vul de vereiste velden in!
              </div>
            }

            <form>
              <div className={'row'}>
                <div className="input-field col s12 m8">
                  <input
                    type="text"
                    ref="street"
                    name="street"
                    value={selectedCustomer.street || ''}
                    className="validate company"
                    onChange={updateCompanyInfo}
                    required
                  />
                  <label htmlFor="street">Straat *</label>
                </div>

                <div className="input-field col s6 m2">
                  <input
                    type="number"
                    ref="houseNumber"
                    name="houseNumber"
                    value={selectedCustomer.houseNumber || ''}
                    className="validate company"
                    pattern="[0-9]"
                    onChange={updateCompanyInfo}
                    required
                  />
                  <label htmlFor="houseNumber">huisnr. *</label>
                </div>

                <div className="input-field col s6 m2">
                  <input
                    type="text"
                    ref="houseNumberExtension"
                    name="houseNumberExtension"
                    minLength="0"
                    maxLength="20"
                    pattern="[a-zA-Z0-9]{20}"
                    onChange={updateCompanyInfo}
                    value={selectedCustomer.houseNumberExtension ? '' + selectedCustomer.houseNumberExtension : ''}
                  />
                  <label htmlFor="houseNumberExtension">toev.</label>
                </div>
              </div>
            </form>
          </DialogBody>
        }

        dialogFooter={
          <DialogFooter
            buttonRight={(
              <DialogButton
                buttonAction={() => onSave()}
                buttonText="Opslaan"
              />
            )}
          />
        }
      />
    );
  }
}

export default ChangeAddressDialog;