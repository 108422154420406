import {
    actionTypes
} from '../constants/actionTypes';
import ObjectHelper from '../services/objectHelper';

const initialState = {
    accessState: [],
    globalAccessOptions: [],
    locationsAccessOptions: [],
    locationAbleToBeShown: false,
    connectionErrors: [],
    existingAccessData: [],
    organizationHip: false,
    ntDependencyActive: false,
    allowsNt: false,
    ftthException: false,
    pendingDataRetrieval: [],
};

export default (state = initialState, action) => {
    let globalAccessState = Object.assign({}, state.accessState.find(x => x.locationID === 'global'));

    switch (action.type) {
        case actionTypes.updateAccessGlobalSettings:
            globalAccessState[action.payload.name] = action.payload.value;

            if (action.payload.name === 'security_secure-vpn_selection' && action.payload.value === true) {
                globalAccessState['security_private-vpn_selection'] = true;
            }

            return {
                ...state,
                accessState: state.accessState.map(x => x.locationID === 'global' ? globalAccessState : x),
                locationAbleToBeShown: ObjectHelper.checkIfAllValuesAreNotEmptyStringsOrUndefined(globalAccessState)
            };
        case actionTypes.updateAccessLocationConnection:
            let {name, value, connectionId, locationId} = action.payload;
            let accessToBeChanged = Object.assign({}, state.accessState.find(x => x.locationID === locationId));
            let connection = accessToBeChanged.connections.find(x => x.id === connectionId);
            connection[name] = value;

            return {
                ...state,
                accessState: state.accessState.map(x => x.locationID === locationId ? accessToBeChanged : x),
            };
        case actionTypes.updateAccessLocation:
            let locationToBeChanged = Object.assign({}, state.accessState.find(x => x.locationID === action.payload.locationId));
            locationToBeChanged[action.payload.name] = action.payload.value;

            return {
              ...state,
              accessState: state.accessState.map(x => x.locationID === action.payload.locationId ? locationToBeChanged : x),
            };
        case actionTypes.getAccessState:

            return {
                ...state,
                accessState: action.payload,
                locationAbleToBeShown: ObjectHelper.checkIfAllValuesAreNotEmptyStringsOrUndefined(globalAccessState)
            };
        case actionTypes.getGlobalAccessOptions:
            return {
                ...state,
                globalAccessOptions: action.payload
            };
        case actionTypes.getLocationsAccessOptions:
            return {
                ...state,
                locationsAccessOptions: action.payload
            };
        case actionTypes.getOrganizationHip:
            return {
                ...state,
                organizationHip: action.payload
            };
        case actionTypes.getExistingAccessData:
            return {
              ...state,
              existingAccessData: action.payload
            };
        case actionTypes.connectionAllowed:

            let errors = state.connectionErrors;
            let foundIndex = errors.findIndex(error => error.connectionID === action.payload.data.connectionID);
            if(foundIndex > -1) {
                errors[foundIndex] = action.payload.data;
            } else {
                errors.push(action.payload.data);
            }

            return {
                ...state,
                connectionErrors: errors
            };
        case actionTypes.updateNtProperties:
            return {
                ...state,
                allowsNt: action.payload.allowsNt,
                ntDependencyActive: action.payload.dependencyStatus,
            };
        case actionTypes.connectionHasFtthException:
            return {
                ...state,
                ftthException: action.payload.ftthException,
            };
        case actionTypes.updateAccessDataRetrieval:
            return {
                ...state,
                pendingDataRetrieval: action.payload
            };
        default:
            return state;
    }
}
