import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import RatioOverflowTableWrapper
  from "../../../components/styled-components/ratio-overflow-table-wrapper/RatioOverflowTableWrapper";
import {Button, Col, Input} from "react-materialize";
import RegularSelect from "../../../components/RegularSelect";
import DatePicker from "../../../components/datePicker";
import {formatDate} from "../../../utils/utils";

class ProductsAdjustingGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: this.props.rows,
      sortingState: this.props.sortingState,
      selectedProduct: this.props.selectedProduct,
      selectedProductDetails: this.props.selectedProductDetails,
      selectedOdidoProduct: this.props.selectedOdidoProduct,
      selectedOdidoProductDetails: this.props.selectedOdidoProductDetails,
      headerNames: this.props.headerNames
    };
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState) {
    const {
      rows,
      sortingState,
      selectedProduct,
      selectedProductDetails,
      selectedOdidoProduct,
      selectedOdidoProductDetails
    } = this.props;

    if (prevProps.rows !== rows) {
      this.setState({rows: rows});
    }
    if (prevProps.sortingState !== sortingState) {
      this.setState({sortingState: sortingState});
    }
    if (prevProps.selectedProduct !== selectedProduct) {
      this.setState({selectedProduct: selectedProduct});
    }
    if (prevProps.selectedProductDetails !== selectedProductDetails) {
      this.setState({selectedProductDetails: selectedProductDetails});
    }
    if (prevProps.selectedOdidoProduct !== selectedOdidoProduct) {
      this.setState({selectedOdidoProduct: selectedOdidoProduct});
    }
    if (prevProps.selectedOdidoProductDetails !== selectedOdidoProductDetails) {
      this.setState({selectedOdidoProductDetails: selectedOdidoProductDetails});
    }
  }

  generateProductDetails = () => {
    let {selectedProductDetails} = this.state;
    if (!selectedProductDetails) {
      return;
    }

    return (
      <TableRowColumn colspan={8} style={{overflow: 'scroll'}}>
        <div style={{height: 'auto', marginTop: '20px'}}>
          <Col s={12} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <h1 className="admin-subheader-text">
              <span className="ratio-section-title">Productdetails</span>
            </h1>
            <Button
              waves="light"
              className="doceri-btn-right"
              onClick={() => this.props.onMinimize()}
              style={{marginLeft: '50px'}}
            >
              <i className="small material-icons left">visibility_off</i>
              Annuleren
            </Button>
            <Button
              waves="light"
              className="doceri-btn-right"
              onClick={() => this.props.onLoadApiRelations(selectedProductDetails.id)}
              style={{marginLeft: '50px'}}
            >
              <i className="small material-icons left">swap_horiz</i>
              Zie API-relaties
            </Button>
          </Col>
          <Input
            s={3}
            value={selectedProductDetails.id}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='ID'
            name='id'
            type='text'
            disabled={true}
          />
          <Input
            s={6}
            value={selectedProductDetails.name}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Productnaam'
            name='name'
            type='text'
          />
          <Input
            s={3}
            value={selectedProductDetails.offer}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Aanbod'
            name='offer'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.module}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Module'
            name='module'
            type='text'
            disabled={true}
          />
          <Input
            s={6}
            value={selectedProductDetails.description}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Productomschrijving'
            name='description'
            type='text'
          />
          <Input
            s={3}
            value={selectedProductDetails.productNumber}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Productnummer'
            name='productNumber'
            type='text'
          />
          <Input
            s={3}
            value={selectedProductDetails.originalProductNumber}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Original productnummer'
            name='originalProductNumber'
            type='text'
          />
          <Input
            s={3}
            value={selectedProductDetails.calculationType_id}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Berekeningstype-id'
            name='calculationType_id'
            type='text'
          />
          <Input
            s={3}
            value={this.getProductCategory(selectedProductDetails.productCategory)}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Categorie'
            name='productCategory'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.contractLength}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Contractduur'
            name='contractLength'
            type='number'
          />
          <Input
            s={3}
            value={selectedProductDetails.displayOrder}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Weergavevolgorde'
            name='displayOrder'
            type='number'
          />
          <Input
            s={3}
            value={selectedProductDetails.offerYear}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Aanbieding jaar'
            name='offerYear'
            type='text'
          />
          <Input
            s={3}
            value={selectedProductDetails.mobileType}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Mobiel type'
            name='mobileType'
            type='text'
          />
          <Input
            s={3}
            value={selectedProductDetails.accessType}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Toegangstype'
            name='accessType'
            type='text'
          />
          <Input
            s={3}
            value={selectedProductDetails.accessProvider}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Toegangsaanbieder'
            name='accessProvider'
            type='text'
          />
          <Input
            s={3}
            value={selectedProductDetails.accessQuality}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Toegang tot kwaliteit'
            name='accessQuality'
            type='text'
          />
          <Input
            s={3}
            value={selectedProductDetails.accessSla}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Toegang sla'
            name='accessSla'
            type='text'
          />
          <Input
            s={3}
            value={selectedProductDetails.grexxType}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Grexx type'
            name='grexxType'
            type='text'
          />
          <Input
            s={3}
            value={selectedProductDetails.downloadSpeed}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Download snelheid'
            name='downloadSpeed'
            type='number'
          />
          <Input
            s={3}
            value={selectedProductDetails.uploadSpeed}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Upload snelheid'
            name='uploadSpeed'
            type='number'
          />
          <Input
            s={3}
            value={selectedProductDetails.material}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Material'
            name='material'
            type='text'
          />
          <Input
            s={3}
            value={selectedProductDetails.extensionPoints}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Uitbreidingspunten'
            name='extensionPoints'
            type='number'
          />
          <Input
            s={3}
            value={selectedProductDetails.minSubProducts}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Min. subproducten'
            name='minSubProducts'
            type='number'
          />
          <Input
            s={3}
            value={selectedProductDetails.maxSubProducts}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Maximale subproducten'
            name='maxSubProducts'
            type='number'
          />
          <Input
            s={3}
            value={selectedProductDetails.maxAmount}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Maximale hoeveelheid'
            name='maxAmount'
            type='number'
          />

          <Col s={12} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <h1 className="admin-subheader-text">
              <span className="ratio-section-title">Productprijzen</span>
            </h1>
          </Col>

          <Input
            s={3}
            value={selectedProductDetails.oneTimeCosts}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Eenmalige kosten'
            name='oneTimeCosts'
            type='number'
          />
          <Input
            s={3}
            value={selectedProductDetails.monthlyCosts}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Maandelijkse kosten'
            name='monthlyCosts'
            type='number'
          />

          <Input
            s={3}
            value={selectedProductDetails.oneTimeCostExtra}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Eenmalige kosten extra'
            name='oneTimeCostExtra'
            type='number'
          />
          <Input
            s={3}
            value={selectedProductDetails.monthlyCostsExtra}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Maandelijkse kosten extra'
            name='monthlyCostsExtra'
            type='number'
          />

          <Input
            s={3}
            value={selectedProductDetails.oneTimeBasicCosts}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Eenmalige basiskosten'
            name='oneTimeBasicCosts'
            type='number'
          />
          <Input
            s={3}
            value={selectedProductDetails.monthlyBasicCosts}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Maandelijkse basiskosten'
            name='monthlyBasicCosts'
            type='number'
          />

          <Input
            s={3}
            value={selectedProductDetails.oneTimeCostIndex}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Eenmalige kostenindex'
            name='oneTimeCostIndex'
            type='number'
          />
          <Input
            s={3}
            value={selectedProductDetails.monthlyCostIndex}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Maandelijkse kostenindex'
            name='monthlyCostIndex'
            type='number'
          />

          <Input
            s={3}
            value={selectedProductDetails.monthlySlaCosts}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Maandelijkse Sla-kosten'
            name='monthlySlaCosts'
            type='number'
          />
          <Input
            s={3}
            value={selectedProductDetails.monthlyDiscountLength}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Maandelijkse kortingsduur'
            name='monthlyDiscountLength'
            type='number'
          />

          <Input
            s={3}
            value={selectedProductDetails.monthlyDiscountContractLength}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Maandelijkse korting Contractduur'
            name='monthlyDiscountContractLength'
            type='number'
          />
          <Input
            s={3}
            value={selectedProductDetails.oneTimeDiscount}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Eenmalige korting'
            name='oneTimeDiscount'
            type='number'
          />

          <Input
            s={3}
            value={selectedProductDetails.oneTimePurchase}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Eenmalige aankoop'
            name='oneTimePurchase'
            type='number'
          />
          <Input
            s={3}
            value={selectedProductDetails.monthlyPurchase}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Maandelijkse aankoop'
            name='monthlyPurchase'
            type='number'
          />

          <Col s={12} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <h1 className="admin-subheader-text">
              <span className="ratio-section-title">Productinstellingen</span>
            </h1>
          </Col>

          <Col s={3} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <label htmlFor={"combinationDiscount"}>Combinatiekorting</label>
            <RegularSelect
              id="combinationDiscount"
              name="combinationDiscount"
              onChange={(event) => {
                this.onChange(event)
              }}
              value={selectedProductDetails.combinationDiscount}
              multiple={false}
            >
              <option value={null} key="no-combination-discount">Selecteer</option>
              <option value={1} key="is-combination-discount">Ja</option>
              <option value={0} name="is-not-combination-discount">Nee</option>
            </RegularSelect>
          </Col>
          <Col s={3} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <label htmlFor={"grexxOrderingPossible"}>GREXX bestellen mogelijk</label>
            <RegularSelect
              id="grexxOrderingPossible"
              name="grexxOrderingPossible"
              onChange={(event) => {
                this.onChange(event)
              }}
              value={selectedProductDetails.grexxOrderingPossible}
              multiple={false}
            >
              <option value={null} key="no-combination-discount">Selecteer</option>
              <option value={1} key="is-combination-discount">Ja</option>
              <option value={0} name="is-not-combination-discount">Nee</option>
            </RegularSelect>
          </Col>
          <Col s={3} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <label htmlFor={"ninaOrderingPossible"}>NINA bestellen mogelijk</label>
            <RegularSelect
              id="ninaOrderingPossible"
              name="ninaOrderingPossible"
              onChange={(event) => {
                this.onChange(event)
              }}
              value={selectedProductDetails.ninaOrderingPossible}
              multiple={false}
            >
              <option value={null} key="no-nina-ordering-possible">Selecteer</option>
              <option value={1} key="nina-ordering-possible">Ja</option>
              <option value={0} name="nina-ordering-possible-not">Nee</option>
            </RegularSelect>
          </Col>
          <Col s={3} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <label htmlFor={"odidoOrderingPossible"}>ODIDO bestellen mogelijk</label>
            <RegularSelect
              id="odidoOrderingPossible"
              name="odidoOrderingPossible"
              onChange={(event) => {
                this.onChange(event)
              }}
              value={selectedProductDetails.odidoOrderingPossible}
              multiple={false}
            >
              <option value={null} key="no-odido-ordering-possible">Selecteer</option>
              <option value={1} key="odido-ordering-possible">Ja</option>
              <option value={0} name="odido-ordering-possible-not">Nee</option>
            </RegularSelect>
          </Col>
          <Col s={3} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <label htmlFor={"cip"}>Is CIP</label>
            <RegularSelect
              id="cip"
              name="cip"
              onChange={(event) => {
                this.onChange(event)
              }}
              value={selectedProductDetails.cip}
              multiple={false}
            >
              <option value={null} key="no-cip">Selecteer</option>
              <option value={1} key="cip">Ja</option>
              <option value={0} name="cip-no">Nee</option>
            </RegularSelect>
          </Col>
          <Col s={3} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <label htmlFor={"hip"}>Is HIP</label>
            <RegularSelect
              id="hip"
              name="hip"
              onChange={(event) => {
                this.onChange(event)
              }}
              value={selectedProductDetails.hip}
              multiple={false}
            >
              <option value={null} key="no-hip">Selecteer</option>
              <option value={1} key="hip">Ja</option>
              <option value={0} name="hip-no">Nee</option>
            </RegularSelect>
          </Col>
          <Col s={3} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <label htmlFor={"isExtra"}>Extra</label>
            <RegularSelect
              id="isExtra"
              name="isExtra"
              onChange={(event) => {
                this.onChange(event)
              }}
              value={selectedProductDetails.isExtra}
              multiple={false}
            >
              <option value={null} key="no-extra">Selecteer</option>
              <option value={1} key="extra">Ja</option>
              <option value={0} name="extra-no">Nee</option>
            </RegularSelect>
          </Col>
          <Col s={3} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <label htmlFor={"isDataOnly"}>Zijn alleen gegevens</label>
            <RegularSelect
              id="isDataOnly"
              name="isDataOnly"
              onChange={(event) => {
                this.onChange(event)
              }}
              value={selectedProductDetails.isDataOnly}
              multiple={false}
            >
              <option value={null} key="no-is-data-only">Selecteer</option>
              <option value={1} key="is-data-only">Ja</option>
              <option value={0} name="is-data-only-no">Nee</option>
            </RegularSelect>
          </Col>
          <Col s={3} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <label htmlFor={"mandatory"}>Verplicht</label>
            <RegularSelect
              id="mandatory"
              name="mandatory"
              onChange={(event) => {
                this.onChange(event)
              }}
              value={selectedProductDetails.mandatory}
              multiple={false}
            >
              <option value={null} key="no-mandatory">Selecteer</option>
              <option value={1} key="is-mandatory">Ja</option>
              <option value={0} name="is-mandatory-no">Nee</option>
            </RegularSelect>
          </Col>
          <Col s={3} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <label htmlFor={"fetchedFromGrexx"}>Opgehaald bij GREXX</label>
            <RegularSelect
              id="fetchedFromGrexx"
              name="fetchedFromGrexx"
              onChange={(event) => {
                this.onChange(event)
              }}
              value={selectedProductDetails.fetchedFromGrexx}
              multiple={false}
            >
              <option value={null} key="no-fetchedFromGrexx">Selecteer</option>
              <option value={1} key="is-fetchedFromGrexx">Ja</option>
              <option value={0} name="is-fetchedFromGrexx-no">Nee</option>
            </RegularSelect>
          </Col>
          <Col s={3} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <label htmlFor={"onlyOncePerQuotation"}>Slechts één keer per offerte</label>
            <RegularSelect
              id="onlyOncePerQuotation"
              name="onlyOncePerQuotation"
              onChange={(event) => {
                this.onChange(event)
              }}
              value={selectedProductDetails.onlyOncePerQuotation}
              multiple={false}
            >
              <option value={null} key="no-onlyOncePerQuotation">Selecteer</option>
              <option value={1} key="is-onlyOncePerQuotation">Ja</option>
              <option value={0} name="is-onlyOncePerQuotation-no">Nee</option>
            </RegularSelect>
          </Col>
          <Col s={3} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <label htmlFor={"onlyOnceType"}>Slechts één keer typen</label>
            <RegularSelect
              id="onlyOnceType"
              name="onlyOnceType"
              onChange={(event) => {
                this.onChange(event)
              }}
              value={selectedProductDetails.onlyOnceType}
              multiple={false}
            >
              <option value={null} key="no-onlyOnceType">Selecteer</option>
              <option value={1} key="is-onlyOnceType">Ja</option>
              <option value={0} name="is-onlyOnceType-no">Nee</option>
            </RegularSelect>
          </Col>
          <Col s={3} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <label htmlFor={"showWhen0"}>Toon wanneer 0</label>
            <RegularSelect
              id="showWhen0"
              name="showWhen0"
              onChange={(event) => {
                this.onChange(event)
              }}
              value={selectedProductDetails.showWhen0}
              multiple={false}
            >
              <option value={null} key="no-showWhen0">Selecteer</option>
              <option value={1} key="is-showWhen0">Ja</option>
              <option value={0} name="is-showWhen0-no">Nee</option>
            </RegularSelect>
          </Col>

          <Col s={12} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <h1 className="admin-subheader-text">
              <span className="ratio-section-title">Tijdstempels van producten</span>
            </h1>
          </Col>

          <Col s={3} className="input-field porting-date-field" style={{marginBottom: '20px'}}>
            <label htmlFor={"endOfSale"}>End verkoopdatum</label>
            <DatePicker
              name="endOfSale"
              id={"endOfSale"}
              selected={Date.parse(selectedProductDetails.endOfSale)}
              onChange={this.onChangeEndOfSale}
              onChangeRaw={e => e.preventDefault()}
              dateFormat="yyyy-MM-dd"
              autoComplete="off"
              popperPlacement="bottom"
              popperModifiers={{
                preventOverflow: {
                  enabled: false
                },
                hide: {
                  enabled: false
                }
              }}
            />
          </Col>
          <Col s={3} className="input-field porting-date-field" style={{marginBottom: '20px'}}>
            <label htmlFor={"createdAt"}>Aangemaakt</label>
            <DatePicker
              name="createdAt"
              id={"createdAt"}
              selected={Date.parse(selectedProductDetails.createdAt)}
              onChange={this.onChangeEndOfSale}
              onChangeRaw={e => e.preventDefault()}
              dateFormat="yyyy-MM-dd"
              autoComplete="off"
              popperPlacement="bottom"
              popperModifiers={{
                preventOverflow: {
                  enabled: false
                },
                hide: {
                  enabled: false
                }
              }}
              disabled={true}
            />
          </Col>
          <Col s={3} className="input-field porting-date-field" style={{marginBottom: '20px'}}>
            <label htmlFor={"updatedAt"}>Laatst gewijzigd</label>
            <DatePicker
              name="updatedAt"
              id={"updatedAt"}
              selected={Date.parse(selectedProductDetails.updatedAt)}
              onChange={this.onChangeEndOfSale}
              onChangeRaw={e => e.preventDefault()}
              dateFormat="yyyy-MM-dd"
              autoComplete="off"
              popperPlacement="bottom"
              popperModifiers={{
                preventOverflow: {
                  enabled: false
                },
                hide: {
                  enabled: false
                }
              }}
              disabled={true}
            />
          </Col>

          <Col s={12} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <h1 className="admin-subheader-text">
              <span className="ratio-section-title">Product acties</span>
            </h1>
          </Col>
          <Col s={12} className="input-field porting-date-field" style={{marginBottom: '20px'}}>
            <Button
              waves="light"
              className="doceri-btn-right"
              onClick={() => this.props.onUpdateProductDetails(selectedProductDetails)}
              style={{marginLeft: '50px'}}
            >
              <i className="small material-icons left">edit</i>
              Productgegevens bijwerken
            </Button>
          </Col>
        </div>
      </TableRowColumn>
    );
  };

  generatePfsRow = (row) => {
    let {selectedProduct} = this.state;

    if (selectedProduct && row.id === selectedProduct.id) {
      return (
        <TableRow key={row.id}>
          {this.generateProductDetails()}
        </TableRow>
      );
    }

    return (
      <TableRow key={row.id}>
        <TableRowColumn style={{width: '5%'}}>{row.id}</TableRowColumn>
        <TableRowColumn style={{width: '30%'}}>{row.name}</TableRowColumn>
        <TableRowColumn style={{width: '12%'}}>{row.productNumber}</TableRowColumn>
        <TableRowColumn style={{width: '12%'}}>{row.module}</TableRowColumn>
        <TableRowColumn style={{width: '12%'}}>{this.getProductCategory(row.productCategory)}</TableRowColumn>
        <TableRowColumn style={{width: '12%'}}>{row.expired ? 'Verlopen' : 'Actief'}</TableRowColumn>
        <TableRowColumn style={{width: '12%'}}>{new Date(row.updatedAt).toLocaleString('nl-NL')}</TableRowColumn>
        <TableRowColumn style={{width: '5%'}}>
          <div>
            <a href="#" onClick={() => this.props.onExtend(row)}>
              <i className="small material-icons left themeprimarycolor">remove_red_eye</i>
            </a>
          </div>
        </TableRowColumn>
      </TableRow>
    );
  };

  generateApiRow = (row) => {
    let {selectedOdidoProduct} = this.state;

    if (selectedOdidoProduct && row.id === selectedOdidoProduct.id) {
      return (
        <TableRow key={row.id}>
          {this.generateOdidoProductDetails()}
        </TableRow>
      );
    }

    return (
      <TableRow key={row.id}>
        <TableRowColumn style={{width: '10%'}}>{row.id}</TableRowColumn>
        <TableRowColumn style={{width: '30%'}}>{row.name}</TableRowColumn>
        <TableRowColumn style={{width: '10%'}}>{row.code}</TableRowColumn>
        <TableRowColumn style={{width: '10%'}}>{row.scenario}</TableRowColumn>
        <TableRowColumn style={{width: '10%'}}>{row.category}</TableRowColumn>
        <TableRowColumn style={{width: '10%'}}>{row.specification}</TableRowColumn>
        <TableRowColumn style={{width: '10%'}}>{new Date(row.updatedAt).toLocaleString('nl-NL')}</TableRowColumn>
        <TableRowColumn style={{width: '10%'}}>
          <div>
            <a href="#" onClick={() => this.props.onExtend(row)}>
              <i className="small material-icons left themeprimarycolor">remove_red_eye</i>
            </a>
          </div>
        </TableRowColumn>
      </TableRow>
    );
  };

  showOdidoProductRelationTree = (selectedOdidoProductDetails) => {
    let relations = [];

    let rootRelation = (
      <Input
        s={3}
        value={selectedOdidoProductDetails.rootProduct}
        onChange={(event) => {
          this.onChange(event)
        }}
        label='Behoort tot het basisproduct'
        name='rootProduct'
        type='text'
        disabled={true}
      />
    );

    let parentRelation = (
      <Input
        s={3}
        value={selectedOdidoProductDetails.parentProduct}
        onChange={(event) => {
          this.onChange(event)
        }}
        label='Behoort tot het moederproduct'
        name='parentProduct'
        type='text'
        disabled={true}
      />
    );

    let header = (
      <Col s={12} className="input-field inner-addon" style={{marginBottom: '20px'}}>
        <h1 className="admin-subheader-text">
          <span className="ratio-section-title">Product-stamboom</span>
        </h1>
      </Col>
    );

    relations.push(header);
    relations.push(rootRelation);
    relations.push(parentRelation);

    return relations;
  };

  generateOdidoProductDetails = () => {
    let selectedProductDetails = this.state.selectedOdidoProductDetails;
    if (!selectedProductDetails) {
      return;
    }

    return (
      <TableRowColumn colspan={8} style={{overflow: 'scroll'}}>
        <div style={{height: 'auto', marginTop: '20px'}}>
          {
            selectedProductDetails.isChild && this.showOdidoProductRelationTree(selectedProductDetails)
          }

          <Col s={12} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <h1 className="admin-subheader-text">
              <span className="ratio-section-title">Productdetails</span>
            </h1>
            <Button
              waves="light"
              className="doceri-btn-right"
              onClick={() => this.props.onMinimize()}
              style={{marginLeft: '50px'}}
            >
              <i className="small material-icons left">visibility_off</i>
              Annuleren
            </Button>
          </Col>
          <Input
            s={3}
            value={selectedProductDetails.id}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='ID'
            name='id'
            type='text'
            disabled={true}
          />
          <Input
            s={6}
            value={selectedProductDetails.name}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Productnaam'
            name='name'
            type='text'
          />
          <Input
            s={3}
            value={selectedProductDetails.variantName}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Variantnaam'
            name='variantName'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.uuid}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='UUID'
            name='uuid'
            type='text'
            disabled={true}
          />
          <Input
            s={6}
            value={selectedProductDetails.code}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Code'
            name='code'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.variantCode}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Variant code'
            name='variantCode'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.kind}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Vriendelijk'
            name='kind'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.category}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Categorie'
            name='category'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.subCategory}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Sub-Categorie'
            name='subCategory'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.specification}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Specificatie'
            name='specification'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.brand}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Brand'
            name='brand'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.customerCategory}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Klantcategorie'
            name='customerCategory'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.family}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Familie'
            name='family'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.priority}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Prioriteit'
            name='priority'
            type='number'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.contentIdentifier}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Inhoudsidentificatie'
            name='contentIdentifier'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.distributionChannelId}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Distributiekanaal-ID'
            name='distributionChannelId'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.distributionChannelName}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Naam distributiekanaal'
            name='distributionChannelName'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.brandId}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Brand ID'
            name='brandId'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.marketId}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Market ID'
            name='marketId'
            type='text'
            disabled={true}
          />

          <Input
            s={3}
            value={selectedProductDetails.marketName}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Market naam'
            name='marketName'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.posMarketId}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='PosMarket ID'
            name='posMarketId'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.posMarketName}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='PosMarket naam'
            name='posMarketName'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.scenario}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Scenario'
            name='scenario'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.mandatory ? 'Ja' : 'Nee'}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Verplicht'
            name='mandatory'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={selectedProductDetails.availableTerms}
            onChange={(event) => {
              this.onChange(event)
            }}
            label='Beschikbare voorwaarden'
            name='availableTerms'
            type='text'
            disabled={true}
          />


          <Col s={12} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <h1 className="admin-subheader-text">
              <span className="ratio-section-title">Productprijzen</span>
            </h1>
          </Col>

          {
            this.renderOdidoPrices(selectedProductDetails.prices)
          }

          <Col s={12} className="input-field inner-addon" style={{marginBottom: '20px'}}>
            <h1 className="admin-subheader-text">
              <span className="ratio-section-title">Product acties</span>
            </h1>
          </Col>
          <Col s={12} className="input-field porting-date-field" style={{marginBottom: '20px'}}>
            <Button
              waves="light"
              className="doceri-btn-right"
              onClick={() => this.props.onUpdateProductDetails(selectedProductDetails)}
              style={{marginLeft: '50px'}}
            >
              <i className="small material-icons left">edit</i>
              Productgegevens bijwerken
            </Button>
          </Col>
        </div>
      </TableRowColumn>
    );
  };

  renderOdidoPrices = (prices) => {
    return prices.map((price) => {
      return (
        <div>
          <Input
            s={3}
            value={price.id}
            onChange={(event) => {
              this.onChangeOdidoPrice(event, price.id)
            }}
            label='ID'
            name='id'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={price.kind}
            onChange={(event) => {
              this.onChangeOdidoPrice(event, price.id)
            }}
            label='Soort'
            name='kind'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={price.specification}
            onChange={(event) => {
              this.onChangeOdidoPrice(event, price.id)
            }}
            label='Specificatie'
            name='specification'
            type='text'
            disabled={true}
          />
          <Input
            s={3}
            value={price.value}
            onChange={(event) => {
              this.onChangeOdidoPrice(event, price.id)
            }}
            label='Prijs'
            name='value'
            type='number'
          />
          <Input
            s={3}
            value={price.valueInclVAT}
            onChange={(event) => {
              this.onChangeOdidoPrice(event, price.id)
            }}
            label='Prijs met BTW'
            name='valueInclVAT'
            type='number'
          />
          <Input
            s={3}
            value={price.targetPrice}
            onChange={(event) => {
              this.onChangeOdidoPrice(event, price.id)
            }}
            label='Richtprijs'
            name='targetPrice'
            type='text'
            disabled={true}
          />
        </div>
      );
    })
  };

  getProductCategory = (productCategory) => {
    switch (productCategory) {
      case 0:
        return 'Bundel';
      case 1:
        return 'Categorie Bundeloptie';
      case 2:
        return 'Bundel optie';
      default:
        return '';
    }
  };

  getIcon = (sortDirection) => {
    switch (sortDirection) {
      case "ASC":
        return 'arrow_downward';
      case "DESC":
        return 'arrow_upward';
      case false:
        return 'swap_vert';
      default:
        return '';
    }
  };

  generateHeader = () => {
    const {pfsOverview} = this.props;
    const {headerNames, sortingState} = this.state;
    return (
      headerNames.map((headerName) => {
        let headerWidth = '12%';

        if (['ID', 'Acties'].includes(headerName)) {
          headerWidth = '5%';
        }
        if (!pfsOverview) {
          headerWidth = '10%';
        }
        if (headerName === 'Productnaam') {
          headerWidth = '30%';
        }

        return (
          <TableHeaderColumn
            key={headerName}
            className="clickable"
            headername={headerName}
            style={{width: headerWidth}}
          >
            {headerName}
            <i className="material-icons right lightgrey">
              {this.getIcon(sortingState[this.props.getPropertyNameFromDisplayName(headerName)])}
            </i>
          </TableHeaderColumn>
        );
      })
    );
  };

  generateTable = (rows) => {
    const {pfsOverview} = this.props;

    return (
      <Table selectable={false}>
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow onCellClick={this.props.onHeaderClick}>
            {this.generateHeader()}
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {
            rows.map(row => pfsOverview ? this.generatePfsRow(row) : this.generateApiRow(row))
          }
        </TableBody>
      </Table>
    );
  };

  generateInfoWarning = () => {
    return (
      <div id="Forms" className="ratiotable paddingbottomnone">
        <h1 className="admin-subheader-text">
          <span className="ratio-section-title">
            Om het productenoverzicht te zien, selecteert u eerst een aanbieder
          </span>
        </h1>
      </div>
    );
  };

  onChangeOdidoPrice = ({target}, priceID) => {
    let {name, value} = target;
    let {selectedOdidoProductDetails} = this.state;
    let {prices} = selectedOdidoProductDetails;

    if (! prices.length) {
      return;
    }

    let priceIndex = prices.findIndex((price) => price.id === priceID);
    if (priceIndex !== -1) {
      prices[priceIndex][name] = value;
    } else {
      return;
    }

    selectedOdidoProductDetails['prices'] = prices;

    this.setState({selectedOdidoProductDetails: selectedOdidoProductDetails});
  };

  onChangeEndOfSale = (date) => {
    let selectedDate = formatDate(date);

    let {selectedProductDetails} = this.state;
    selectedProductDetails['endOfSale'] = selectedDate;
    this.setState({endOfSale: selectedDate});
  };

  onChange = ({target}) => {
    const {name, value} = target;
    const {pfsOverview} = this.props;

    let {selectedProductDetails, selectedOdidoProductDetails} = this.state;

    if (pfsOverview) {
      selectedProductDetails[name] = value;

      this.setState({selectedProductDetails: selectedProductDetails});
    } else {
      selectedOdidoProductDetails[name] = value;
      this.setState({selectedOdidoProductDetails: selectedOdidoProductDetails});
    }
  };

  render() {
    const {rows} = this.state;

    return (
      <RatioOverflowTableWrapper>
        <div className="valign-wrapper">
          {rows.length ? this.generateTable(rows) : this.generateInfoWarning()}
        </div>
      </RatioOverflowTableWrapper>
    );
  }
}

const mapStateToProps = ({}) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductsAdjustingGrid));
