import React, { Component } from 'react';
import {Input, Row, Col} from "react-materialize";

import KpnDialog from "../../../containers/common/KpnDialog/KpnDialog";
import DialogHeader from "../../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";

import FileUpload from "../../../components/fileUpload";

import ProviderHelper from "../../../helper/providerHelper";


class OrganizationProvidersLogosForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logoFile: {
        id: this.props.logo.id || null,
        name: this.props.logo.name,
        default: this.props.logo.default ? 1 : 0,
        file: '',
        provider: this.props.logo.provider || 0,
        documentType: this.props.documentType || 'png',
      },
      uploadErrors: ''
    }
  }

  componentDidMount() {
    const {logo, providers} = this.props;
    if (! logo.provider && providers.length) {
      this.setState({logoFile: {...this.state.logoFile, provider: providers[0].provider}});
    }
  }

  onChange = ({target}) => {
    let {name, value, type, checked} = target;

    if ('checkbox' === type) {
      value = checked ? 1 : 0;
    }

    this.setState(prevState => ({
      logoFile: {
        ...prevState.logoFile,
        [name]: value
      }
    }));
  };

  onDrop = (files) => {
    this.setState(prevState => ({
      logoFile: {
        ...prevState.logoFile,
        file: files[0],
      },
      uploadErrors: ''
    }));
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.onSubmit(this.state.logoFile);
  };

  render() {
    const { logoFile } = this.state

    return (
      <KpnDialog
        dialogState={true}
        modal={true}
        onRequestClose={this.props.onClose}
        dialogHeader={(
          <DialogHeader
            headerIcon="person"
            headerText="Logos"
            closeHandler={this.props.onClose}
          />
        )}
        dialogBody={
          <DialogBody>
            <form onSubmit={this.onSubmit}>
              <Row>
                <Input
                  required
                  s={12}
                  defaultValue={logoFile.name}
                  onChange={this.onChange}
                  label="Naam"
                  name='name'
                  type='text'/>
              </Row>
              {
                !logoFile.id &&
                <Row>
                  <Input
                    s={12}
                    name={'provider'}
                    id={'provider'}
                    type="select"
                    multiple={false}
                    required={true}
                    label={'Provider'}
                    defaultValue={logoFile.provider}
                    onChange={this.onChange}
                    className="validate">
                    <option value="-1" disabled={'disabled'} key={-1}>Kies provider</option>
                    {this.props.providers.filter((el => el.provider !== 1 )).map((el) => <option value={el.provider} key={el.provider}>{ProviderHelper.getQuotationProviderName(el.provider)}</option>)}
                  </Input>
                </Row>
              }

              <Row>
                <Input
                  type="checkbox"
                  label="Standaard"
                  name="default"
                  id="default"
                  checked={this.state.logoFile.default}
                  onChange={this.onChange}
                />
              </Row>

              <Row>
                <Col s={12} className="input-field">
                  <FileUpload
                    onDrop={this.onDrop}
                    caption="Sleep uw bestand hier of klik voor upload. Let op, dit overschrijft het oude bestand"
                  />

                  {logoFile.file && <div>Geselecteerd bestand: {logoFile.file.name}</div>}
                </Col>
                {this.state.uploadErrors && (
                  <Col s={12}>
                    <span style={{color: 'red'}}>{this.state.uploadErrors}</span>
                  </Col>
                )}
              </Row>

              <button
                className='btn doceri-btn-right ratio-btn-right-fullwidth right'
                type="submit"
                name="action"
              >
                Submit
              </button>
            </form>
          </DialogBody>
        }
      />
    )
  }
}

export default OrganizationProvidersLogosForm;
