import React, { Component } from 'react';
import DiscountOverview from './discountOverview';

class DiscountStep extends Component {
    render() {
        return (
            <DiscountOverview title="Kortingen" subTitle="Kortingen geven" editable={true} />
        );
    }
}

export default DiscountStep;
