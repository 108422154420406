import { apiRoutes } from '../../constants/apiRoutes';
import {actionTypes} from "../../constants/actionTypes";
import ApiService from "../../services/apiService";
import RequestType from "../../constants/requestType";
import { toastr } from 'react-redux-toastr';
import { setQuotationLocations, updateWholeSelectedLocationInRedux } from '../locationActions';

const updateBusinessConnectionOptions = (locationId, name, value) => {
    return async (dispatch) => {
        const url = apiRoutes.businessConnectionRelated.patch.replace(/{locationId}/, locationId );
        try {
            dispatch(updateBusinessConnectionState(name, value, locationId));
            const response = await ApiService.performRequest(RequestType.PATCH, url, {[name]: value});
        } catch (error) {
            toastr.error(error.message);
        }

    }
};

const updateBusinessCallChargeType = (unlimitedCount, p2gCount, locationId) => {
    return async (dispatch) => {
        const url = apiRoutes.businessConnectionRelated.patch.replace(/{locationId}/, locationId );
        try {
            dispatch(updateBusinessCallChargeState(unlimitedCount, p2gCount, locationId));
            const response = await ApiService.performRequest(RequestType.PATCH, url, {p2gCount, unlimitedCount});
        } catch (error) {
            toastr.error(error.message);
        }

    }
};

const updateLocationCallChargeType = (locationId, locationCallChargeType) => {
    return async (dispatch) => {
        const url = apiRoutes.hostedVoiceRelated.patchLocationCallChargeType.replace(/{locationId}/, locationId );
        try {
            const response = await ApiService.performRequest(RequestType.PATCH, url, {locationCallChargeType});
            dispatch(updateLocationCallChargeTypeSuccess(locationCallChargeType));
        } catch (error) {
            toastr.error(error.message);
        }

    }
};

const addLocationSipTrunk = (locationId, data, quotationId) => {
    return async (dispatch) => {
        const url = apiRoutes.hostedVoiceRelated.postSipTrunk.replace(/{locationId}/, locationId );
        try {
            const response = await ApiService.performRequest(RequestType.POST, url, data);
            await dispatch(setQuotationLocations(quotationId));
            dispatch(updateWholeSelectedLocationInRedux());
        } catch (error) {
            toastr.error(error.message);
        }
    }
};

const updateLocationSipTrunk = (locationId, sipTrunkId, data, quotationId) => {
    return async (dispatch) => {
        const url = apiRoutes.hostedVoiceRelated.pathSipTrunk.replace(/{locationId}/, locationId ).replace(/{sipTrunkId}/, sipTrunkId );
        try {
            dispatch(updateLocationSipTrunkSuccess(data, sipTrunkId));
            await ApiService.performRequest(RequestType.PATCH, url, data);
            await dispatch(setQuotationLocations(quotationId));
            dispatch(updateWholeSelectedLocationInRedux());
        } catch (error) {
            toastr.error(error.message);
        }
    }
};

const deleteLocationSipTrunk = (locationId, sipTrunkId, quotationId) => {
    return async (dispatch) => {
        const url = apiRoutes.hostedVoiceRelated.deleteSipTrunk.replace(/{locationId}/, locationId ).replace(/{sipTrunkId}/, sipTrunkId );
        try {
            await ApiService.performRequest(RequestType.DELETE, url);
            await dispatch(setQuotationLocations(quotationId));
            dispatch(updateWholeSelectedLocationInRedux());
        } catch (error) {
            toastr.error(error.message);
        }
    }
};

const updateLocationSipTrunkSuccess = (data, sipTrunkId) => {
    return {
        type: actionTypes.updateLocationSipTrunkSuccess,
        payload: { data, sipTrunkId },
    }
};

const updateLocationCallChargeTypeSuccess = (locationCallChargeType) => {
    return {
        type: actionTypes.updateLocationCallChargeTypeSuccess,
        payload: locationCallChargeType,
    }
};

const updateBusinessCallChargeState = (unlimitedCount, p2gCount, locationId) => {
    return {
        type: actionTypes.updateCallChargeState,
        payload: {
            unlimitedCount,
            p2gCount,
            locationId
        }
    }
};

const updateBusinessConnectionState = (name, value, locationId) => {
    return {
        type: actionTypes.updateBusinessConnectionOptions,
        payload: {
            name,
            value,
            locationId
        }
    }
};

export default {
    updateBusinessConnectionOptions,
    updateBusinessCallChargeType,
    updateLocationCallChargeType,
    addLocationSipTrunk,
    updateLocationSipTrunk,
    deleteLocationSipTrunk,
}