import {
  actionTypes
} from '../constants/actionTypes';

const initialState = {
  newDocument: {
    name: '',
    default: false,
    webLeadAvailable: false,
    webLeadDefault: false
  },
  documents: [],
  documentBlob: '',
  convertedDocument: '',
  excelExtensions: ['xls', 'xlsx', 'csv'],
};

export default (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.getWordDocuments:
      return {
        ...state,
        documents: action.response,
      };
    case actionTypes.addWordDocument:
      return {
        ...state,
        documents: [...state.documents, action.response]
      };
    case actionTypes.editWordDocument:
      return {
        ...state,
        documents: state.documents.map( (document) => document.id === action.response.id ? action.response : document )
      };
    case actionTypes.deleteWordDocument:
      return {
        ...state,
        documents: state.documents.filter( document => document.id !== action.documentId )
      };
    case actionTypes.downloadWordDocument:
      return {
        ...state,
        documentBlob: action.response
      };
    case actionTypes.downloadConvertedWordDocument:
      return {
        ...state,
        convertedDocument: action.response
      };
    default:
      return state;
  }
}
