import React, { Component } from 'react';
import FileUpload from '../../../components/fileUpload';
import KpnDialog  from '../../../containers/common/KpnDialog/KpnDialog';
import DialogHeader from "../../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";
import DialogFooter from "../../../containers/common/KpnDialog/DialogFooter";
import DialogButton from "../../../containers/common/KpnDialog/DialogButton";
import {connect} from "react-redux";
import {Input} from "react-materialize";

class ImportHardware extends Component {
  render() {
    let {
      dialogOpened,
      onRequestClose,
      changeOptions,
      save,
      onDrop,
      droppedFile,
      manualAddition,
      copyProduct,
      setToInactive
    } = this.props;

    return (
      <KpnDialog
        dialogState={dialogOpened}
        dialogHeader={(
          <DialogHeader
            headerIcon="router"
            headerText="Toevoegen:"
            headerSubtext="hardware producten"
            closeHandler={onRequestClose}
          />
        )}
        dialogBody={
          <DialogBody>
            <p>Kies hieronder aub of u een los product wenst toe te voegen of een bulk-upload wenst te doen van uw hele assortiment.</p>
            <div className="row">
              <Input
                  id="set-to-inactive-hardware"
                  type="checkbox"
                  label="Producten die niet meer in mijn bulk voorkomen mogen op inactief worden gezet."
                  name="setToInactive"
                  className="checkbox"
                  checked={setToInactive}
                  onChange={(event) => changeOptions(event)}
              />
              <Input
                  id="copy-product-hardware"
                  type="checkbox"
                  label="Voor alle wijzigingen die ik in mijn bulk heb aangebracht wens in een duplicaat product aan te
                  maken en de bestaande product op inactief te zetten zodat de prijzen en gegevens in oude offertes behouden blijft.
                  De bestaande producten in offertes krijgen hierdoor geen wijzigingen mee."
                  name="copyProduct"
                  className="checkbox"
                  checked={copyProduct}
                  onChange={(event) => changeOptions(event)}
              />
            </div>
            <div className="input-field col s12">
              <FileUpload onDrop={onDrop} droppedFile={droppedFile} caption="Sleep uw bestand hier of klik om uw bestand te selecteren."/>
            </div>
          </DialogBody>
        }
        dialogFooter={
          <DialogFooter
            buttonRight={(
              <DialogButton
                buttonAction={() => save()}
                buttonText='Bulk bestand importeren'
              />
            )}
            buttonLeft={(
              <DialogButton
                  buttonAction={() => manualAddition()}
                  buttonText='+ Los product'
                  left={true}
              />
            )}
          />
        }
      />
    );
  }
};

const mapStateToProps = ({quotation}) =>
{
  return {
    quotation: quotation
  }
};

export default connect(mapStateToProps)(ImportHardware);
