import _ from 'lodash';
import {bindActionCreators} from "redux";
import {poll} from "../../utils/utils";
import {toastr} from "react-redux-toastr";
import connect from "react-redux/es/connect/connect";
import "react-table/react-table.css";

import React, {Component, Fragment} from 'react';
import ReactTable from 'react-table';
import {Button, Icon, Input, Preloader} from "react-materialize";
import {DelayInput} from "react-delay-input";
import FileDownload from "js-file-download";
import RegularSelect from "../../components/RegularSelect";
import {apiRoutes} from "../../constants/apiRoutes";

import quotationActions from "../../actions/quotationActions";
import stepsActions from "../../actions/stepsActions";
import mobileActions from "../../actions/workflow/mobileActions";

import GeneralDialog from "../../containers/common/GeneralDialog";
import DefaultDialogBody from "../../containers/common/KpnDialog/DefaultDialogBody";
import productActionTypes from "../../constants/productActionTypes";
import BulkUpload from "../workflow/info/BulkUpload";

class UpgradeMobileTelephoneNumbersTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.sortData(this.props.data),
      prevData: [],
      dialogOpened: false,
      saveRequired: false,
      deleteDialogOpened: false,
      deleteDataDialogOpened: false,
      selectedPhoneNumber: {},
      uploadErrors: {},
      successBulkImport: false,
      importedRows: 0,
      loading: false,
      phoneNumberSelected: null,
      profilesProcessing: false,
      profilesSelectAll: false,
    }
  }

  componentDidMount() {
    this.toggleFlagForAllAKBNumbers = _.debounce(this.toggleFlagForAllAKBNumbers, 500);

    const self = this;
    this.saveAKBTelephoneNumberDebounced = _.wrap(_.memoize(function () {
      return _.debounce(self.saveAKBTelephoneNumber, 500);
    }, _.property('id')), function (func, obj) {
      return func(obj)(obj);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.sortData(this.props.data),
        prevData: prevProps.data
      });
    }
    if (prevState.saveRequired !== this.state.saveRequired) {
      this.props.stepsActions.setAvailableToGoForward(!this.state.saveRequired);
    }
  }

  sortData = (data) => {
    data.sort((numberA, numberB) => {
      if (this.hasSelectedProfileAvailable(numberA)) {
        return 1;
      }

      if (this.hasSelectedProfileAvailable(numberB)) {
        return -1;
      }

      return 0;
    });

    return data;
  };

  getActionOptions = () => {
    let options = [
      {
        key: 5,
        value: 'nieuw',
      },
      {
        key: 6,
        value: 'up/downgraden',
      },
      {
        key: 7,
        value: 'verlengen',
      },
      {
        key: 8,
        value: 'opzeggen',
      },
      {
        key: 1,
        value: 'porteren'
      }
    ];

    if (!this.props.quotation.currentQuotation.provider) {
      options = options.concat([
        {
          key: 4,
          value: 'simless porteren'
        }
      ]);
    }

    return options;
  };

  getExampleFile = () => {
    const {quotation, quotationId, locationId} = this.props;
    const quotationType = quotation.currentQuotation.type;

    if ((quotationType).includes(4, 5)) {
      let url = apiRoutes.mobileRelated.getMobileBulkXlsExample
        .replace('{quotationId}', quotationId)
        .replace(/{locationId}/, locationId)
        .replace(/{existing}/, true);

      this.props.mobileActions.getMobileBulkExampleFileForQuotation(quotationId, locationId)
        .then(() => poll(url, {responseType: 'arraybuffer'}))
        .then((response) => FileDownload(response, quotationId + '-Bulk-upload-ratio-mobiele-telefoonnummers.xlsx'))
        .catch((e) => toastr.error('Kan word document niet downloaden', e.message))
    } else {
      this.props.quotationActions.getMobileBulkExampleFile(quotationId).then((success) => {
        if (success) {
          this.downloadExampleFile()
        }
      });
    }
  };

  downloadExampleFile = () => {
    FileDownload(this.props.quotation.mobileBulkExampleFile, 'Bulk-upload-ratio-mobiele-telefoonnummers.xlsx');
  };

  saveAKBTelephoneNumber = (telephoneData, originalTelephoneNumbers) => {
    if (this.props.quotationStatus) {
      return;
    }
    const product = this.state.prevData.find((el) => el.id === telephoneData.id);

    if (this.state.profilesSelectAll) {
      let availableTelephoneNumbers = this.state.data.map((phoneNumber) => phoneNumber.id);

      this.props.quotationActions.saveAllAKBTelephoneNumber({
        telephoneNumbers: availableTelephoneNumbers,
        product: telephoneData.product
      }, this.props.quotationId)
        .then((val) => {
          if (val && val.error) {
            this.setState({
              data: originalTelephoneNumbers
            });
          }
        })
        .then(() => this.props.mobileActions.mobileOfferProducts(this.props.mobileOfferYear, this.props.quotationId, this.props.locationId))
        .then(() => this.setState({profilesProcessing: false}));

      return;
    }

    this.props.quotationActions.saveAKBTelephoneNumber(telephoneData.id, telephoneData, this.props.quotationId, product ? telephoneData.product : null)
      .then((val) => {
        if (val && val.error) {
          this.setState({
            data: originalTelephoneNumbers
          });
        }
      })
      .then(() => this.props.mobileActions.mobileOfferProducts(this.props.mobileOfferYear, this.props.quotationId, this.props.locationId))
      .then(() => this.setState({profilesProcessing: false}));
  };

  toggleFlagForAllAKBNumbers = (column, value, originalTelephoneNumbers) => {
    this.props.quotationActions.toggleFlagForAllAKBNumbers(this.props.quotationId, {
      flag: column,
      value: value
    }).then((val) => {
      if (val && val.error) {
        this.setState({
          data: originalTelephoneNumbers
        });
      }
    });
  };

  renderProductOptions = (data) => {
    let {profiles, action} = data;
    let profilesCondition = profiles && profiles.length;
    let {mobileProducts, authentication} = this.props;
    let actionCondition = [productActionTypes.UPGRADE, productActionTypes.RETENTIE].includes(action);
    let options = [];

    const {isSalesValue} = authentication;

    options.push(<option value="" key={0}>Selecteer een optie</option>);

    if (action === productActionTypes.RETENTIE) {
      mobileProducts = mobileProducts.filter((mobileProduct) => mobileProduct.id === data.product);
      mobileProducts.forEach((mobileProduct) => {
        options.push(<option value={mobileProduct.id} key={mobileProduct.id}>{mobileProduct.name}</option>);
      });
    }

    if (profilesCondition && actionCondition) {
      profiles.forEach((profile) => {
        const profileProductNumber = isSalesValue ? profile.productNumber : profile.product_id;
        const product = this.identifyMobileProduct(profileProductNumber);

        if (product) {
          options.push(<option value={product.id} key={product.id}>{product.name}</option>);
        }
      });
    } else {
      mobileProducts.forEach((mobileProduct) => {
        options.push(<option value={mobileProduct.id} key={mobileProduct.id}>{mobileProduct.name}</option>);
      });
    }

    return options;
  };

  identifyMobileProduct = (productNumber) => {
    return this.props.getAllMobileProducts.find((mobileProduct) => mobileProduct.productNumber === productNumber);
  };

  renderDropdowns = (data, index1, categoryName) => {
    if (['Add-ons', 'Add-ons Onbeperkt', 'Voordeelmodules', 'Data SIM add-ons', 'Add-ons Data SIM'].includes(categoryName)) {
      return;
    }

    const {currentQuotation} = this.props.quotation;
    let {inputsDisabled} = currentQuotation;
    let {selectedMobileProducts, mobileOptions} = this.props;

    const selection = selectedMobileProducts.find((selection) => selection.productId === data.product);
    if (! selection || !mobileOptions) {
      return;
    }

    const selectionID = selection.id;
    if (!mobileOptions[selectionID]) {
      return;
    }

    const index = mobileOptions[selectionID].findIndex((option => option.category.name === categoryName));
    const selectedCategory = mobileOptions[selectionID][index];
    if (!selectedCategory) {
      return;
    }

    const maxQuantity = 999;

    let {selectedProducts = [{id: -1}]} = selectedCategory || {};
    selectedProducts = selectedProducts.filter(item => item.telephone_number_id === data.id);

    const selectedProductsArray = selectedProducts.length ? selectedProducts : [{id: -1, quantity: 1}];
    let selectedCategoryProducts = selectedCategory.products;
    let value = selectedProductsArray[0];

    return (
      <Input
        s={12}
        type="select"
        value={value.id}
        multiple={false}
        disabled={inputsDisabled}
        onChange={this.props.handleOptionsChange(
          value.id,
          value.quantity,
          maxQuantity,
          selectionID,
          index,
          data.id
        )}
      >
        <option key={-1} value={-1}>Selecteer een optie</option>
        {selectedCategoryProducts.map((el) =>
          <option value={el.id} key={el.id}>{el.name}</option>
        )}
      </Input>
    );
  };

  onChange = (e, index) => {
    if (this.props.quotationStatus) {
      return;
    }

    this.setState({profilesProcessing: true});

    let {name, value} = e.target;
    const originalTelephoneNumbers = [...this.state.data];
    const telephoneNumbers = [...this.state.data];

    //do not trigger change if it is selected the same value
    if (telephoneNumbers[index][name] && telephoneNumbers[index][name].toString() === value.toString()) {
      return;
    }

    if (name === 'product' && value !== "") {
      //verify if the product can be selected
      const {mobileProducts, getAllMobileProducts} = this.props;
      const allMobileProducts = getAllMobileProducts.concat(mobileProducts);

      const found = allMobileProducts.find(product => {
        return product.id === parseInt(value)
      });
      if (!found) {
        return;
      }

      const selectedCount = telephoneNumbers.reduce((acc, crtElem) => {
        return parseInt(crtElem.product) === parseInt(value) ? ++acc : acc;
      }, 0);

      if (selectedCount >= found.quantity) {
        this.openDialog();
        return;
      }
    }

    if (name !== 'number') {
      value = value ? parseInt(value) : ""
    }

    telephoneNumbers[index] = {
      ...telephoneNumbers[index],
      [name]: value
    };

    this.setState({
      data: telephoneNumbers
    });

    this.saveAKBTelephoneNumberDebounced(telephoneNumbers[index], originalTelephoneNumbers);
  };

  getColumnWidth = (rows, accessor, headerText) => {
    const maxWidth = 400;
    const magicSpacing = 10;
    const cellLength = Math.max(
      ...rows.map(row => (`${row[accessor]}` || '').length),
      headerText.length,
    );

    return Math.min(maxWidth, cellLength * magicSpacing) + 15;
  };

  closeDialog = () => {
    this.setState({dialogOpened: false});
  };

  openDialog = () => {
    this.setState({dialogOpened: true});
  };

  calculateMinHeight = () => {
    const height = this.props.mobileProducts
      ? Math.max((this.props.mobileProducts.length * 50) + 20 /*select scroll-x*/,
      this.getActionOptions().length * 50) /*Maximum between mobile profiles and action selectboxes height*/
      + 106 /*table header height*/
      + 20 /*padding*/
      : 300;
    return height + 'px';
  };

  calculateMinBodyHeight = () => {
    const data = this.state.data || []
    const maxRows = data.length > 10 ? 10 : data.length;
    const height = maxRows * 91;

    return height + 'px';
  };

  _handleDialogClose = () => {
    this.setState({bulkUploadDialogOpened: false});
  };

  importBulkTemplateMobile = () => {
    this.setState({profilesProcessing: true});
    this.props.quotationActions.bulkImport(this.props.quotationId, this.props.locationId, this.state.file, 'mobile', true).then((response) => {
      if (response) {
        if (response.errors) {
          this.setState({uploadErrors: response.errors, profilesProcessing: false});
        }

        if (response.importedRows) {
          this.setState({
            successBulkImport: true,
            importedRows: response.importedRows,
            profilesProcessing: false
          });
          this.props.getMobileProducts(this.props.quotation.currentQuotation.mobileOfferYear, this.props.locationId);
        }
      } else {
        this.props.getMobileProducts(this.props.quotation.currentQuotation.mobileOfferYear, this.props.locationId);
        this.setState({profilesProcessing: false});
      }
    });
  };

  save = () => {
    if (this.props.quotationStatus) {
      return;
    }

    this.importBulkTemplateMobile();

    this._handleDialogClose();
  };

  patchMobileSettings = (phoneNumber) => {
    if (this.props.quotationStatus) {
      return;
    }

    this.setState({loading: true, phoneNumberSelected: phoneNumber});

    this.props.quotationActions.patchMobileSettings(this.props.quotationId, phoneNumber)
      .then(() => this.props.mobileActions.mobileOfferProducts(this.props.mobileOfferYear, this.props.quotationId, this.props.locationId))
      .then(() => this.setState({loading: false}));
  };

  getHeaderOptions = () => {
    return {
      // vastMobielOption: 'Vast-mobiel opties',
      //addons: 'Add-ons',
      //dataSim: 'Optie Data SIM',
    };
  };

  countSelectedProfiles = () => {
    const {data} = this.props;

    let selectedProfiles = 0;
    data.forEach(el => {
      const found = this.hasSelectedProfileAvailable(el);

      if (found) {
        selectedProfiles++;
      }
    });

    return selectedProfiles;
  };

  hasSelectedProfileAvailable = (number) => {
    const {mobileProducts, getAllMobileProducts} = this.props;
    const allMobileProducts = getAllMobileProducts.concat(mobileProducts);

    const foundProduct = allMobileProducts.find(product => {
      return product.id === parseInt(number.product)
    });

    if (number.profiles) {
      const found = number.profiles.find(product => {
        return foundProduct && product.product_id === foundProduct.productNumber
      });

      return !!found;
    }

    return false;
  };

  getProfileSelectionContent = (phoneNumber, index, inputsDisabled) => {
    let {hasUpgradeDataRetrieved, action, product, profiles, orderId} = phoneNumber;
    let disableCondition = inputsDisabled || [1, 4, 7, 8].includes(action);
    let {pendingDataRetrieval} = this.props;
    let pendingDataCondition = !!pendingDataRetrieval.find((pendingOrderId) => parseInt(pendingOrderId) === parseInt(orderId));

    if (action === productActionTypes.UPGRADE) {
      if (!profiles || (profiles && !profiles.length)) {
        return hasUpgradeDataRetrieved && !pendingDataCondition ? (
          <span className="no-upgrade-data-text">Up/Downgrade niet mogelijk</span>
        ) : (
          <span className="waiting-upgrade-data-text">Up/Downgrade opties worden opgehaald</span>
        );
      }
    }

    return (
      <RegularSelect
        s={12}
        name="product"
        onChange={(event) => this.onChange(event, index)}
        value={product || ''}
        disabled={disableCondition}
        multiple={false}
      >
        {this.renderProductOptions(phoneNumber, index)}
      </RegularSelect>
    );
  };

  render() {
    const {data, inputsDisabled} = this.props;
    const headerOptions = this.getHeaderOptions();
    const countSelectedProfiles = this.countSelectedProfiles();

    let columns = [
      {
        id: 'number',
        accessor: 'number',
        Cell: ({original, index}) => {
          return (
            <DelayInput
              delayTimeout={2000}
              type="text"
              name="number"
              onChange={(event) => this.onChange(event, index)}
              disabled={inputsDisabled || [6, 7, 8, 10].includes(original.action)}
              value={original.number}
            />
          )
        },
        Header: 'Telefoonnummer',
        className: 'col-telephone-number',
        headerClassName: 'col-telephone-number',
        minWidth: this.getColumnWidth(data, 'number', 'Telefoonnummer'),
      },
      {
        id: "product",
        Cell: ({original, index}) => {
          return this.getProfileSelectionContent(original, index, inputsDisabled);
        },
        Header: () => {
          return (
            <div>
              <div>
                Profiel toegewezen:
                {countSelectedProfiles} / {this.state.data.length}
              </div>
            </div>
          );
        },
        sortable: false,
        className: 'col-product col-select actionSelection',
        headerClassName: 'head-checkbox',
        minWidth: 300
      },
    ];

    for (let optionKey in headerOptions) {
      columns.push({
        id: optionKey,
        accessor: "",
        Cell: ({original, index}) => {
          return (
            this.renderDropdowns(original, index, headerOptions[optionKey])
          )
        },
        Header: headerOptions[optionKey],
        sortable: false,
        resizable: false,
        className: 'col-option',
        headerClassName: 'head-option',
        minWidth: 400,
        style: {overflow: 'initial'}
      });
    }

    return (
      <div>
        <GeneralDialog
          dialogState={this.state.dialogOpened}
          closeHandler={this.closeDialog}
          headerText={'Toewijzen niet mogelijk'}
          dialogBodyContent={(
            <DefaultDialogBody text='Een profiel kan niet vaker aan een nummer toegewezen worden dan dat het
                                bovenaan deze pagina is opgegeven. Wijs een ander profiel aan dit nummer toe of
                                voeg dit profiel vaker toe aan de offerte.'/>
          )}
          rightButtonAction={this.closeDialog}
          rightButtonText={'Ik begrijp het.'}
        />

        {this.state.profilesProcessing &&
        (
          <div className="loader-component" style={{zIndex: 99}}>
            <div className="overlay" style={{backgroundColor: `rgba(255, 255, 255, 0.3)`}}/>
            <Preloader size='medium'/>
          </div>
        )
        }

        <ReactTable
          data={this.state.data}
          resolveData={data => data.map(row => row)}
          columns={columns}
          minRows={0}
          defaultPageSize={999}
          showPagination={false}
          className={'akb-table'}
          getTbodyProps={() => {
            return {
              style: {
                overflow: 'visible',
                height: this.calculateMinBodyHeight()
              }
            }
          }}
          style={{minHeight: this.calculateMinHeight()}}
        />
        
        {this.state.saveRequired ? (
          <span className="red-text right" style={{clear: "both"}}>Om verder te gaan, dient u eerst de wijzigingen in het telefoonnummermenu op te slaan.</span>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({quotation, hardware, mobile, authentication}) => {
  return {
    quotationId: quotation.currentQuotation.id,
    customHardwareOptions: hardware.customHardwareOptions,
    mobileOfferYear: quotation.currentQuotation.mobileOfferYear,
    devicePaymentOptions: mobile.devicePaymentOptions,
    pendingDataRetrieval: mobile.pendingDataRetrieval,
    quotation,
    authentication: authentication
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    quotationActions: bindActionCreators(quotationActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
    mobileActions: bindActionCreators(mobileActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeMobileTelephoneNumbersTable);