import React, {Component} from 'react';
import Dialog from 'material-ui/Dialog';
import LinearProgress from 'material-ui/LinearProgress'

class Loader extends Component {

    render () {

        return <Dialog open={true}>
            <div style={{display: "flex", justifyContent: 'center', flexDirection: 'column'}}>
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px'}}>
                    { this.props.message }
                </div>

                <LinearProgress color="#090" />
            </div>
        </Dialog>
    }
}

export default Loader;