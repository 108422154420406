import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom'

import adminActions from '../../../actions/adminActions';
import {getUserInfo} from "../../../actions/authenticationActions";
import {Input} from "react-materialize";
import providerType from "../../../constants/providerType";

class UserPanel extends Component {
  state = {
    user: {
      lastName: this.props.selectedUser.last_name || '',
      surName: this.props.selectedUser.sur_name || '',
      username: this.props.selectedUser.username || '',
      phoneNumber: this.props.selectedUser.phone_number || '',
      allowedDiscount: this.props.selectedUser.allowed_discount || 0,
      roles: this.props.selectedUser.roles || [],
      categoryDiscounts: this.props.selectedUser.categoryDiscounts || [],
      mandate: this.props.selectedUser.mandate || '',
      connectWiseId: this.props.selectedUser.connectWiseId || '',
      allowOrder: this.props.selectedUser.allow_order || false,
    },
    categories: this.props.categories || [],
    isSubmitButtonDisabled: false
  };

  componentDidMount = () => {
    let {phoneNumber} = this.state.user;
    let {isAdminFromDoceri} = this.props.authUser;

    if (!isAdminFromDoceri) {
      this.setState({isSubmitButtonDisabled: !this.phoneNumberIsValid(phoneNumber)});
    }
  };

  phoneNumberIsValid = (phoneNumber) => {
    let sixPattern = !!(phoneNumber.match(new RegExp("^06\\d{8,10}$")) || phoneNumber.match(new RegExp("^\\+316\\d{8,10}$")));
    let ninePattern = !!(phoneNumber.match(new RegExp("^09\\d{8,10}$")) || phoneNumber.match(new RegExp("^\\+319\\d{8,10}$")));

    return sixPattern || ninePattern;
  };

  goToUsersPanel = () => {
    this.props.adminActions.setCurrentSection('users');
  };

  getDiscountValue = (offerId, category = null, subcategory = null) => {
    let discount = 0;
    if (offerId) {
      discount = this.state.user.categoryDiscounts.find((item) => item.offer === offerId);
    } else {
      discount = this.state.user.categoryDiscounts.find((item) => (item.category === category && item.subcategory === subcategory));
    }

    return discount ? parseFloat(discount.percentage) : 0;
  };

  onRoleChange = ({target}) => {
    let {value, checked} = target;
    let newRoles = [];

    if (checked) {
      newRoles = [...this.state.user.roles, value];
    } else {
      newRoles = this.state.user.roles.filter(el => el !== value);
    }

    if (newRoles.includes('ROLE_MANAGE_USERS') && !newRoles.includes('ROLE_SALES_SUPPORT')) {
      newRoles = newRoles.filter(el => el !== 'ROLE_MANAGE_USERS');
    }

    this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        roles: newRoles
      }
    });
  };

  onChange = ({target}) => {
    let {name, value} = target;
    let {isAdminFromDoceri} = this.props.authUser;

    if (name === 'allowedDiscount' && parseInt(value) > 100) {
      value = 100;
    }

    if (name === 'phoneNumber') {
      let phoneNumberIsValid = this.phoneNumberIsValid(value);

      if (!isAdminFromDoceri) {
        this.setState({isSubmitButtonDisabled: !phoneNumberIsValid});
      }
    }

    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [name]: value
      }
    }));
  };

  onCategoryChange = (target, offerId = null, category = null, subcategory = null) => {
    let {value} = target;

    let newData = {
      offer: offerId,
      user: this.props.selectedUser.id || null,
      category: category,
      subcategory: subcategory,
      percentage: parseFloat(value) || 0,
    };

    let currentDiscounts = this.state.user.categoryDiscounts;
    let discount = null;

    if (offerId) {
      discount = currentDiscounts.find((item) => item.offer === offerId);
    } else {
      discount = currentDiscounts.find((item) => (item.category === category && item.subcategory === subcategory));
    }
    if (!discount) {
      currentDiscounts.push(newData);
    } else {
      if (offerId) {
        currentDiscounts = this.state.user.categoryDiscounts.map((item) => item.offer === offerId ? newData : item);
      } else {
        currentDiscounts = this.state.user.categoryDiscounts.map((item) => (item.category === category && item.subcategory === subcategory) ? newData : item);
      }
    }

    this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        categoryDiscounts: currentDiscounts
      }
    });
  };

  onMandateChange = ({target}) => {
    let { value } = target;

    this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        mandate: value
      }
    });
  };

  onAllowOrderChange = ({target}) => {
    let { value } = target;

    this.setState({
      ...this.state,
      user: {
        ...this.state.user,
        allowOrder: value === 'true'
      }
    });
  };

  onSubmit = () => {
    let {user} = this.state;
    const userId = this.props.selectedUser.id;

    if (userId === 0) {
      this.props.adminActions.addUser(user).then(() => {this.props.adminActions.setCurrentSection('users');});
    } else {
      this.props.adminActions.editUser(userId, user).then(() => this.props.adminActions.getUsers());
    }
  };

  getCategoryProviderName = (index) => {
    switch (index) {
      case 1:
        return providerType.VODAFONE_PROVIDER_NAME;
      case 2:
        return providerType.ODIDO_PROVIDER_NAME;
      case 3:
        return providerType.VENECO_PROVIDER_NAME;
      case 4:
        return providerType.PHC_PROVIDER_NAME;
      default:
        return providerType.KPN_PROVIDER_NAME
    }
  };

  render() {
    const vodafoneCategories = this.props.categories.filter(category => category.name.includes(providerType.VODAFONE_PROVIDER_NAME));
    const kpnCategories = this.props.categories.filter(category => !category.name.includes(providerType.VODAFONE_PROVIDER_NAME) && !category.name.includes(providerType.ODIDO_PROVIDER_NAME));
    const odidoCategories = this.props.categories.filter(category => category.name.includes(providerType.ODIDO_PROVIDER_NAME));
    const venecoCategories = this.props.categories.filter(category => category.name.includes(providerType.VENECO_PROVIDER_NAME));
    const phcCategories = this.props.categories.filter(category => category.name.includes(providerType.PHC_PROVIDER_NAME));

    const {isVenecoOrganization} = this.props.authUser;

    return (
      <React.Fragment>
        <div id="Forms" className="ratiotable paddingbottomnone">
          <h1 className="admin-subheader-text">
            <span className="ratio-section-title">Gebruikers</span>
          </h1>

          <button
            className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
            onClick={() => this.goToUsersPanel()}
            type="submit"
            name="action"
          >
            <i className="small material-icons left white-text">chevron_left</i>
            Terug naar gebruikersoverzicht
          </button>
        </div>

        <div className="col s12 m12 l12" style={{padding: 0, display: 'flex'}}>
          <div className="col s6 m6 l6 Forms" style={{ marginRight: 10, height: '100%'}}>
            <h1 className={'ratio-section-title'}>
              <span className="ratio-section-title" style={{color: 'black', fontWeight: '600'}}>
                <i className="small material-icons left">person</i>
                Gebruiker aanmaken
              </span>
            </h1>

            <div className="row">
              <Input
                s={6}
                value={this.state.user.surName}
                onChange={this.onChange}
                label="Voornaam"
                name='surName'
                type='text'
              />

              <Input
                s={6}
                value={this.state.user.lastName}
                onChange={this.onChange}
                label='Achternaam'
                name='lastName'
                type='text'
              />
            </div>

            <div className="row">
              <Input
                s={6}
                value={this.state.user.username}
                onChange={this.onChange}
                label='E-mail'
                name='username'
                type='text'/>

              <Input
                s={6}
                value={this.state.user.phoneNumber}
                label='Telefoonnummer'
                maxLength={14}
                onChange={this.onChange}
                name='phoneNumber'
                type='text'
                className="validate"
              />
            </div>

            <div className="row">
              <Input
                value={this.state.user.allowedDiscount}
                s={6}
                maxLength="3"
                minLength="0"
                onChange={this.onChange}
                label='Toegestane kortings percentage'
                name='allowedDiscount'
                type='text'
              />
            </div>

            {
              isVenecoOrganization && (
                <h1 className={'ratio-section-title'}>
                  <span className="ratio-section-title" style={{color: 'black', fontWeight: '600'}}>
                    <i className="small material-icons left">person</i>
                    ConnectWise
                  </span>
                </h1>
                )
            }
            {
              isVenecoOrganization && (
                <div className="row">
                  <Input
                    s={6}
                    value={this.state.user.connectWiseId}
                    onChange={this.onChange}
                    label='ConnectWiseID'
                    name='connectWiseId'
                    type='text'
                  />
                </div>
                )
            }

            <h1 className={'ratio-section-title'}>
              <span className="ratio-section-title" style={{color: 'black', fontWeight: '600'}}>
                <i className="small material-icons left">person</i>
                Rollen
              </span>
            </h1>

            <div className="row">
              {this.props.allowedRoles.includes('ROLE_ADMIN') &&
                <Input
                  name='roles'
                  type='checkbox'
                  checked={this.state.user.roles.includes('ROLE_ADMIN')}
                  value='ROLE_ADMIN'
                  label='Admin'
                  onChange={this.onRoleChange}
                />
              }

              {this.props.allowedRoles.includes('ROLE_SALES') &&
                <Input
                  name='roles'
                  type='checkbox'
                  checked={this.state.user.roles.includes('ROLE_SALES')}
                  value='ROLE_SALES'
                  label='Sales'
                  onChange={this.onRoleChange}
                />
              }

              {this.props.allowedRoles.includes('ROLE_SALES_SUPPORT') &&
                <Input
                  name='roles'
                  type='checkbox'
                  checked={this.state.user.roles.includes('ROLE_SALES_SUPPORT')}
                  value='ROLE_SALES_SUPPORT'
                  label='Sales support'
                  onChange={this.onRoleChange}
                />
              }

              {this.props.allowedRoles.includes('ROLE_SALES_SUPPORT') && this.state.user.roles.includes('ROLE_SALES_SUPPORT') &&
                <Input
                  name='roles'
                  type='checkbox'
                  checked={this.state.user.roles.includes('ROLE_MANAGE_USERS')}
                  value='ROLE_MANAGE_USERS'
                  label='Gebruikersbeheer'
                  onChange={this.onRoleChange}
                />
              }
            </div>

            {(this.state.user.roles.includes('ROLE_ADMIN'))  &&
                <>
                  <h1 className={'ratio-section-title'}>
                  <span className="ratio-section-title" style={{color: 'black', fontWeight: '600'}}>
                    <i className="small material-icons left">library_books</i>
                    Orderen
                  </span>
                  </h1>
                  <div className="row">
                    <Input
                        name='allowOrder'
                        type='checkbox'
                        checked={this.state.user.allowOrder}
                        value={true}
                        label='Ja'
                        onChange={this.onAllowOrderChange}
                    />
                    <Input
                        name='allowOrder'
                        type='checkbox'
                        checked={this.state.user.allowOrder === false}
                        value={false}
                        label='Nee'
                        onChange={this.onAllowOrderChange}
                    />
                  </div>
                </>
            }

            {(this.state.user.roles.includes('ROLE_SALES_SUPPORT') || this.state.user.roles.includes('ROLE_ADMIN'))  &&
                <>
                  <h1 className={'ratio-section-title'}>
                  <span className="ratio-section-title" style={{color: 'black', fontWeight: '600'}}>
                    <i className="small material-icons left">library_books</i>
                    Mandate
                  </span>
                  </h1>
                  <div className="row">
                    <Input
                        name='mandate'
                        type='checkbox'
                        checked={this.state.user.mandate === 'resell'}
                        value='resell'
                        label='Resell'
                        onChange={this.onMandateChange}
                    />
                    <Input
                        name='mandate'
                        type='checkbox'
                        checked={this.state.user.mandate === 'yielder'}
                        value='yielder'
                        label='Yielder'
                        onChange={this.onMandateChange}
                    />
                  </div>
                </>
            }
          </div>

          <div className="col s6 m6 l6" style={{ marginLeft: 10, padding: 0}}>
            {[kpnCategories, vodafoneCategories, odidoCategories, venecoCategories, phcCategories].map((categoryProvider, index) =>
              <div key={index}>
                {categoryProvider && categoryProvider.length > 0 &&
                  <div className="Forms" key={index}>
                    <h1 className={'ratio-section-title'}>
                      <span className="ratio-section-title" style={{color: 'black', fontWeight: '600'}}>
                        <i className="small material-icons left">euro</i>
                        Maximaal toegestane korting {this.getCategoryProviderName(index)}
                      </span>
                    </h1>

                  {categoryProvider.map((category, index) =>
                    <div className="row" key={category.name}>
                      <p className="ratio-section-title" style={{color: 'black', fontWeight: '500'}}>{category.name}</p>

                      {category.offers.map((offer, indexOffer) => {
                        return (
                          <div className="input-field inner-addon col s4" key={indexOffer}>
                            <label className="label-ellipsis active" >{offer.name}</label>
                            <i className="glyphicon tiny lightgrey" style={{fontWeight: "bold", top: "10px"}}>%</i>
                            <input
                              key={`input-${index}-${indexOffer}`}
                              type="number"
                              min={0}
                              max={100}
                              value={this.getDiscountValue(offer.id, offer.category, offer.subcategory)}
                              onChange={(event) => {
                                this.onCategoryChange(event.target, offer.id, offer.category, offer.subcategory)
                              }}
                            />
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
                }
              </div>
            )}
          </div>
        </div>

        <button className={'btn doceri-btn-right '} disabled={this.state.isSubmitButtonDisabled} onClick={() => this.onSubmit()}>
          Opslaan
        </button>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ admin, authentication }) => {
  return {
    users: admin.users,
    categories: admin.categories,
    selectedUser: admin.selectedUser,
    allowedRoles: authentication.roles,
    authUser: authentication,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminActions: bindActionCreators(adminActions, dispatch),
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserPanel));
