import {apiRoutes} from "../constants/apiRoutes";
import ApiService from "../services/apiService";
import RequestType from "../constants/requestType";
import {actionTypes} from "../constants/actionTypes";
import {toastr} from "react-redux-toastr";

const getMessages = (quotation) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.messages.getMessages.replace('{quotation}', quotation);
            const response = await ApiService.performRequest(RequestType.GET, url);
            dispatch(getAllMessagesSuccess(response));
        } catch ({message}) {
            toastr.error('We konden de berichten niet ophalen', message);
        }
    }
};

const getAllMessagesSuccess = (response) => {
    return {
        type: actionTypes.getAllMessages,
        response
    };
};

const sendMessage = (quotation, message) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.messages.sendMessage.replace('{quotation}', quotation);
            const body = {
                message: message
            }
            const response = await ApiService.performRequest(RequestType.POST, url, body);
            dispatch(sendNewMessageSuccess(response));
        } catch ({message}) {
            toastr.error('We konden de berichten niet ophalen', message);
        }
    }
}

const sendNewMessageSuccess = (response) => {
    return {
        type: actionTypes.sendMessage,
        response
    };
}

export default {
    getMessages,
    sendMessage
}