import React, {Component} from 'react';
import {DelayInput} from "react-delay-input";
import {bindActionCreators} from "redux";
import discountActions from "../../../actions/workflow/discountActions";
import connect from "react-redux/es/connect/connect";

class GroupDiscountsInputs extends Component {

  getDiscountByType = (percent = false) => {
    const {type, productId, productAction, tariffCluster, groupDiscounts} = this.props;
    if (!groupDiscounts[productId]) {
      return 0;
    }

    const productDiscounts = groupDiscounts[productId];
    let discount = productDiscounts.find((el) => el.discountAction === productAction && el.discountTariffCluster === tariffCluster);
    let propertyKey = (percent ? 'discountPercentage' : 'discount') + type
    if (!discount) {
      return 0;
    }

    return discount[propertyKey];
  };

  state = {
    discountPercentage: this.getDiscountByType(true),
    discount: this.getDiscountByType(false),
    products: this.props.productGroup.products,
    regularDiscountEnabled: false,
    percentageDiscountEnabled: true
  };

  componentDidMount() {
    const {discountPercentage} = this.state;

    if (discountPercentage > 0) {
      this.enablePercentageDiscount();
    } else {
      this.enableRegularDiscount();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.groupDiscounts !== this.props.groupDiscounts) {
      this.setState({
        discountPercentage: this.getDiscountByType(true),
        discount: this.getDiscountByType(false),
      });
    }
  }

  onDiscountUpdate = () => {
    let quotationId = this.props.quotation.currentQuotation.id;
    let isLead = this.props.quotation.isLead;
    let data = {
      productId: this.props.productId
    };

    data['discountPercentage' + this.props.type] = this.state.discountPercentage;
    data['discount' + this.props.type] = this.state.discount;
    data['productType'] = this.props.productType;
    data['productAction'] = this.props.productAction;
    data['tariffCluster'] = this.props.tariffCluster;

    this.props.discountActions.updateDiscount(data, this.props.productGroup, quotationId, isLead)
      .then(() => this.props.discountActions.getDiscountState(quotationId, this.props.editable, isLead));
  };

  onFocus = () => {
    const {openDialog, productGroup} = this.props;
    if (productGroup.isMeraki === true) {
      openDialog();
    }
  };

  onChange = ({target}) => {
    const {name, value} = target;
    let newValue = this.convertNumber(value, 'nl');
    let stateName = name.replace(this.props.type, '');

    if (name.indexOf('Percentage') !== -1) {
      this.enablePercentageDiscount();
    } else {
      this.enableRegularDiscount();
    }

    this.setState({
      [stateName]: newValue
    }, () => {
      this.onDiscountUpdate();
    });
  };

  convertNumber = (num, locale) => {
    const { format } = new Intl.NumberFormat(locale);
    const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
    return +num
      .replace(new RegExp(`[^${decimalSign}\\d]`, 'g'), '')
      .replace(decimalSign, '.');
  };

  enableRegularDiscount = () => {
    this.setState({
      regularDiscountEnabled: true,
      percentageDiscountEnabled: false,
      discountPercentage: 0,
    });

  };

  enablePercentageDiscount = () => {
    this.setState({
      regularDiscountEnabled: false,
      percentageDiscountEnabled: true,
      discount: 0,
    });

  };

  getErrorMessage = (type, offerId) => {
    const errors = this.props.discount.discountErrors;
    const productId = this.props.productId;
    offerId = offerId || null;

    return errors.find(x => x.categoryID === offerId && x.productId === productId && x.type === type);
  };

  render() {

    const {
      discountPercentage,
      discount,
      regularDiscountEnabled,
      percentageDiscountEnabled
    } = this.state;
    const {productGroup, editable} = this.props;

    const error = this.getErrorMessage(this.props.type.toLowerCase(), productGroup.id);

    return (
      <React.Fragment>
        <div className="input-field inner-addon" style={{display: "inline-block", width: "50%"}}>
          <i className="glyphicon tiny lightgrey" style={{fontWeight: "bold", top: 0}}>%</i>
          <DelayInput
            value={discountPercentage ? discountPercentage.toLocaleString('nl') : 0}
            type="text"
            pattern="[0-9]+([,][0-9]{1,2})?"
            name={`discountPercentage${this.props.type}`}
            min={0}
            max={100}
            delayTimeout={500}
            className="without-margin-bottom"
            onChange={this.onChange}
            onFocus={this.onFocus}
            style={{width: "50%"}}
            disabled={!editable}
          />


        </div>
        <div className="input-field inner-addon" style={{display: "inline-block", width: "50%"}}>
          <i className="glyphicon material-icons left lightgrey"
             style={{paddingTop: 7, fontSize: "1.35em", top: 0}}>euro_symbol</i>
          <DelayInput
            type="text"
            pattern="[0-9]+([,][0-9]{1,2})?"
            name={`discount${this.props.type}`}
            min={0}
            delayTimeout={1000}
            className="without-margin-bottom"
            onChange={this.onChange}
            value={discount ? discount.toLocaleString('nl') : 0}
            style={{width: "50%"}}
            disabled={!editable}
          />
        </div>

        {
          error && editable && (percentageDiscountEnabled || regularDiscountEnabled) ? (
            <div className="error-small">{error.message}<br/>Korting niet opgeslagen</div>
          ) : null
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({quotation, discount}) => {
  return {
    quotation,
    discount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    discountActions: bindActionCreators(discountActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupDiscountsInputs);
