export default class FormBuilderService {
    static prepareFormValuesAndCaptions(formProperties) {
        let result = [];

        for(let propertyName of Object.keys(formProperties)) {
            result.push({
                name: propertyName,
                dropdownConfiguration: {
                    enumValues: formProperties[propertyName].enum,
                    enumCaptions: formProperties[propertyName].enum_titles
                }
            });
        }

        return result;
    }
}