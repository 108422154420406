import {
    actionTypes
} from '../constants/actionTypes';
import AuthenticatedService from '../services/authenticationService';
import RatioTheme from '../constants/theme';

const theme = AuthenticatedService.getThemeFromLocalStorage();

const initialState = {
    isLoggedIn: AuthenticatedService.loggedIn(),
    logInError: '',
    name: '',
    username: '',
    company: '',
    roles: [],
    isLoginChecked: false,
    allowedDiscount: 0,
    type: process.env.REACT_APP_TYPE,
    theme: theme ? theme : { ...RatioTheme },
    isSalesValue: false,
    isAxoftUser: false,
    isDoceri: false,
    isPHC: false,
    nameInitials: '',
    providers: [],
    expiredSession: false,
    ordering: false,
    quotationValidity: null,
    oneTimePartnerService: null,
    versioning: 0,
    readonlyOfferExport: false,
    userInsight: 0,
    crmConnection: '',
    isPIT: false,
    allowDigitalSigning: false,
    useNina: false,
    allowLead: false,
    allowWebLead: false,
    hasCustomPortfolio: false,
    allowQAQuotation: false,
    showQAPrices: false,
    showQALicences: false,
    isAdminFromDoceri: false,
    allowedUsersToNina: [],
    organizationId: null,
    hasConnectWiseSync: false,
    downloadAccess: true,
    hasQuoteBtn: false,
    isVenecoOrganization: false,
    allowOrder: false,
    switchableOrganizationList: [],
    switchOrganization: false,
    userId: ''
}

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.loginSuccess:
            return {
                ...state,
                isLoggedIn: true,
                logInError: ''
            }
        case actionTypes.loginFailed:
            return {
                ...state,
                isLoggedIn: false,
                logInError: action.payload
            }
        case actionTypes.logoutSuccess:
            return {
                ...initialState,
                isLoggedIn: false,
            }
      case actionTypes.loginCheckSuccess:
          return {
            ...state,
            isLoginChecked: true
          }
      case actionTypes.setExpiredSession:
        return {
          ...state,
          expiredSession:true
        }
      case actionTypes.updateThemeSuccess:
        return {
            ...state,
            theme: { ...state.theme, ...action.response },
        }
      case actionTypes.getSwitchableOrganizationsSuccess:
        return {
            ...state,
            switchableOrganizationList: action.response
        }
      case actionTypes.switchOrganizationSuccess:
        return {
            ...state,
            switchOrganization: action.response
        }
      case actionTypes.setUserDetails:
            const theme = action.payload.theme ? { ...action.payload.theme } : {};
            if (action.payload.theme && !theme.logo) {
                delete theme.logo;
            }

            if (action.payload.theme && !theme.background) {
                delete theme.background;
            }

            // Setting local storage probably doesnt belong here. But due to time pressure i need to build it here.
            localStorage.setItem('company', action.payload.company);
            localStorage.setItem('name', action.payload.name);
            localStorage.setItem('username', action.payload.username);
            localStorage.setItem('roles', action.payload.roles);
            localStorage.setItem('allowedDiscount', action.payload.allowedDiscount);
            localStorage.setItem('type', action.payload.type);
            localStorage.setItem('isSalesValue', action.payload.isSalesValue);
            localStorage.setItem('isAxoftUser', action.payload.isAxoftUser);
            localStorage.setItem('isFlash', action.payload.isFlash);
            localStorage.setItem('isDoceri', action.payload.isDoceri);
            localStorage.setItem('isPHC', action.payload.isPHC);
            localStorage.setItem('nameInitials', action.payload.nameInitials);
            localStorage.setItem('providers', action.payload.providers);
            localStorage.setItem('ordering', action.payload.ordering);
            localStorage.setItem('expiredSession', false);
            localStorage.setItem('quotationValidity', action.payload.quotationValidity);
            localStorage.setItem('oneTimePartnerService', action.payload.oneTimePartnerService);
            localStorage.setItem('versioning', action.payload.versioning);
            localStorage.setItem('readonlyOfferExport', action.payload.readonlyOfferExport);
            localStorage.setItem('userInsight', action.payload.userInsight);
            localStorage.setItem('crmConnection', action.payload.crmConnection);
            localStorage.setItem('kpnITServiceOption', action.payload.kpnITServiceOption);
            localStorage.setItem('kpnITServiceOption', action.payload.salesValueAcronisOption);
            localStorage.setItem('isPIT', action.payload.isPIT);
            localStorage.setItem('allowDigitalSigning', action.payload.allowDigitalSigning);
            localStorage.setItem('useNina', action.payload.useNina);
            localStorage.setItem('allowLead', action.payload.allowLead);
            localStorage.setItem('hasCustomPortfolio', action.payload.hasCustomPortfolio);
            localStorage.setItem('allowWebLead', action.payload.allowWebLead);
            localStorage.setItem('allowQAQuotation', action.payload.allowQAQuotation);
            localStorage.setItem('showQAPrices', action.payload.showQAPrices);
            localStorage.setItem('showQALicences', action.payload.showQALicences);
            localStorage.setItem('isAdminFromDoceri', action.payload.isAdminFromDoceri);
            localStorage.setItem('allowedUsersToNina', action.payload.allowedUsersToNina);
            localStorage.setItem('organizationId', action.payload.organizationId);
            localStorage.setItem('hasConnectWiseSync', action.payload.hasConnectWiseSync);
            localStorage.setItem('downloadAccess', action.payload.downloadAccess);
            localStorage.setItem('hasQuoteBtn', action.payload.hasQuoteBtn);
            localStorage.setItem('isVenecoOrganization', action.payload.isVenecoOrganization);
            localStorage.setItem('allowOrder', action.payload.allowOrder);
            localStorage.setItem('switchableOrganizationList', action.payload.switchableOrganizationList);
            localStorage.setItem('switchOrganization', action.payload.switchOrganization);
            localStorage.setItem('allowSwitch', action.payload.allowSwitch);
            localStorage.setItem('userId', action.payload.userId);
          return {
                ...state,
                name: action.payload.name,
                username: action.payload.username,
                company: action.payload.company,
                roles: action.payload.roles.slice(),
                allowedDiscount: action.payload.allowedDiscount,
                type: action.payload.type,
                theme: { ...state.theme, ...theme },
                isSalesValue: action.payload.isSalesValue,
                isAxoftUser: action.payload.isAxoftUser,
                isFlash: action.payload.isFlash,
                isDoceri: action.payload.isDoceri,
                isPHC: action.payload.isPHC,
                nameInitials: action.payload.nameInitials,
                providers: action.payload.providers,
                ordering: action.payload.ordering,
                quotationValidity: action.payload.quotationValidity,
                oneTimePartnerService: action.payload.oneTimePartnerService,
                versioning: action.payload.versioning,
                readonlyOfferExport: action.payload.readonlyOfferExport,
                userInsight: action.payload.userInsight,
                crmConnection: action.payload.crmConnection,
                kpnITServiceOption: action.payload.kpnITServiceOption,
                salesValueAcronisOption: action.payload.salesValueAcronisOption,
                isPIT: action.payload.isPIT,
                allowDigitalSigning: action.payload.allowDigitalSigning,
                useNina: action.payload.useNina,
                allowLead: action.payload.allowLead,
                allowWebLead: action.payload.allowWebLead,
                hasCustomPortfolio: action.payload.hasCustomPortfolio,
                allowQAQuotation: action.payload.allowQAQuotation,
                showQAPrices: action.payload.showQAPrices,
                showQALicences: action.payload.showQALicences,
                isAdminFromDoceri: action.payload.isAdminFromDoceri,
                allowedUsersToNina: action.payload.allowedUsersToNina,
                organizationId: action.payload.organizationId,
                hasConnectWiseSync: action.payload.hasConnectWiseSync,
                downloadAccess: action.payload.downloadAccess,
                hasQuoteBtn: action.payload.hasQuoteBtn,
                isVenecoOrganization: action.payload.isVenecoOrganization,
                allowOrder: action.payload.allowOrder,
                switchableOrganizationList: action.payload.switchableOrganizationList,
                switchOrganization: action.payload.switchOrganization,
                allowSwitch: action.payload.allowSwitch,
                userId: action.payload.userId,
            };
        default:
            return state;
    }
}
