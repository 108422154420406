import {
  actionTypes
} from '../constants/actionTypes';

const initialState = {
  selectedOptions: [],
};

export default (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.getTelephonyLocationOptions:
    case actionTypes.updateTelephonyLocationOptions:
      return {
        ...state,
        selectedOptions: action.payload.telephonyLocationOptions
      };
    default:
      return state;
  }
}
