import React from 'react';
import QuotationType from '../../constants/quotationType';
import { Input } from 'react-materialize';

import DialogHeader from "../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../containers/common/KpnDialog/DialogBody";
import DialogFooterFlex from "../../containers/common/KpnDialog/DialogFooterFlex";
import DialogButton from "../../containers/common/KpnDialog/DialogButton";
import KpnDialog from "../../containers/common/KpnDialog/KpnDialog";
import ProviderHelper from "../../helper/providerHelper";
import providerType, {odidoProviders} from "../../constants/providerType";

const dialogStyles = {
    footer: {
        buttonContainer: { minWidth: '200px' },
    },
};

class NewQuotationDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            quotationType: QuotationType.TYPE_STANDARD,
            quotationAKB: "",
            quotationProvider: "",
            quotationAccessPortfolio: 'no-access'
        };
    }

    componentDidMount() {
        this.setState({quotationProvider: this.props.authentication.providers[0].provider});
    }

    handleChange(event) {
        this.setState({quotationType: event.target.value});
    }

    handleChangeProvider(event) {
        this.setState({quotationProvider: event.target.value});
        this.props.stepActions.setQuotationProvider(event.target.value);
    }

    handleChangeAccessPortfolio(event) {
        this.setState({quotationAccessPortfolio: event.target.value});
    }

    renderOptions =()=> {
        const {isLeadOverview, isPitOrganization, authentication} = this.props;

        if (isLeadOverview) {
            let options = [];
            options.push(<option value="-1" disabled={'disabled'} key={-1}>Selecteer</option>);
            options.push(<option key={1} value={1}>{'Lead'}</option>);
            return options;
        }

        const {quotationProvider} = this.state;

        let quotationTypeNames = ['Nieuwe klant', 'Bestaande klant', 'Q&A Offerte'];
        const isOdidoProvider = odidoProviders.includes(parseInt(quotationProvider));

        quotationTypeNames.push('Aanpassen bestaand');

        let quotationTypes = {
            'Nieuwe klant' : QuotationType.TYPE_STANDARD,
            'Verlengen' : QuotationType.TYPE_EXTENSION,
            'Migratie' : QuotationType.TYPE_MIGRATION,
            'Uitbreiding' : QuotationType.TYPE_EXPANSION,
            'Bestaande klant' : QuotationType.TYPE_VERLENGEN,
            'Aanpassen bestaand' : QuotationType.TYPE_EXISTING,
            'Q&A Offerte' : QuotationType.TYPE_QA,
            'Koop' : QuotationType.TYPE_KOOP,
            'Huur' : QuotationType.TYPE_HUUR,
            'Vergelijking' : QuotationType.TYPE_VERGELIJKING
        };

        let index = quotationTypeNames.indexOf('Aanpassen bestaand');
        if (index > -1 && !isPitOrganization) {
            quotationTypeNames.splice(index, 1);
        }

        quotationTypeNames.forEach((el, index) => {
            const hasVerlengenOption = ['Verlengen', 'Bestaande klant'].includes(el);

            if (hasVerlengenOption && !['', '0', 0, 4, '4', 7, '7', 8, '8'].includes(quotationProvider)) {
                quotationTypeNames.splice(index, 1);
            }
        });

        if (isOdidoProvider) {
            quotationTypeNames = quotationTypeNames.filter((el) => ['Nieuwe klant', 'Q&A Offerte', 'Bestaande klant'].includes(el));
        }

        if (parseInt(quotationProvider) === providerType.VODAFONE) {
            quotationTypeNames.push('Bestaande klant');
            quotationTypes['Bestaande klant'] = QuotationType.TYPE_MIGRATION;
        }

        if (parseInt(quotationProvider) === providerType.FLASH) {
            quotationTypeNames.push('Koop');
            quotationTypeNames.push('Huur');
            quotationTypeNames.push('Vergelijking')
        }

        if (! authentication.allowQAQuotation) {
            quotationTypeNames = quotationTypeNames.filter((el) => el !== 'Q&A Offerte');
        }

        return quotationTypeNames.map((el) => {
            return <option key={quotationTypes[el]} value={quotationTypes[el]}>{el}</option>
        })
    };

    render() {
        let {
            isNewOpened,
            closeDialogFunc,
            createNewQuotationFunc,
            providers,
            isLeadOverview,
            isYielderPartner
        } = this.props;
        let {
            quotationAKB,
            quotationType,
            quotationProvider,
            quotationAccessPortfolio
        } = this.state;

        let dialogTitle = isLeadOverview ? 'Nieuwe lead' : 'Nieuwe offerte';
        let typeLabel = isLeadOverview ? 'Type lead' : 'Type offerte';
        let createButtonText = isLeadOverview ? 'Lead aanmaken' : 'Offerte aanmaken';
        if (isYielderPartner) {
            dialogTitle = 'Nieuwe order';
            typeLabel = 'Type order'
            createButtonText = 'Order aanmaken'
        }

        return (
          <KpnDialog
            dialogState={isNewOpened}
            onRequestClose={() => closeDialogFunc('isNewOpened')}
            modal={false}
            overflowException={true}
            dialogHeader={(
              <DialogHeader
                headerText={dialogTitle}
                closeHandler={() => closeDialogFunc('isNewOpened')}
              />
            )}

            dialogBody={
                <DialogBody>
                    <div className="row">
                        <div className="input-field col s12 m12">
                            <Input
                              s={12}
                              name={'provider'}
                              id={'provider'}
                              type="select"
                              multiple={false}
                              required={true}
                              label={'Provider'}
                              defaultValue={0}
                              onChange={(e) => this.handleChangeProvider(e)}
                              className="validate">
                                <option value="-1" disabled={'disabled'} key={-1}>Kies provider</option>
                                {
                                    providers.filter(providerItem => providerItem.provider !== providerType.SALESVALUE)
                                      .map((providerItem) =>  {
                                          return (
                                            <option value={providerItem.provider} key={providerItem.provider}>
                                                {ProviderHelper.getQuotationProviderName(providerItem.provider)}
                                            </option>
                                          );
                                      })
                                }
                            </Input>
                        </div>
                        <div className="input-field col s12 m12">
                            <Input
                              s={12}
                              name={'type'}
                              id={'type'}
                              type="select"
                              multiple={false}
                              required={true}
                              label={typeLabel}
                              value={quotationType || '0'}
                              onChange={(e) => this.handleChange(e)}
                              className="validate">
                                {this.renderOptions()}
                            </Input>
                        </div>
                      {
                        parseInt(quotationProvider) === providerType.ODIDO && (
                          <div className="input-field col s12 m12">
                            <Input
                              s={12}
                              name={'type'}
                              id={'type'}
                              type="select"
                              multiple={false}
                              required={true}
                              label={'Access portfolio'}
                              value={quotationAccessPortfolio || ''}
                              onChange={(e) => this.handleChangeAccessPortfolio(e)}
                              className="validate"
                            >
                                <option value="no-access" key={1}>Geen Access gewenst</option>
                                <option value="irma" key={2}>IRMA (KPN)</option>
                                <option value="tele2" key={3}>TELE2</option>
                            </Input>
                          </div>
                        )
                      }
                    </div>
                </DialogBody>
            }
            dialogFooter={
                <DialogFooterFlex>
                    <div className={"col s12 m12"}>
                        <div style={dialogStyles.footer.buttonContainer}>
                            <DialogButton
                              buttonAction={() => createNewQuotationFunc(quotationType, quotationAKB, quotationProvider, quotationAccessPortfolio)}
                              buttonText={createButtonText}
                            />
                        </div>
                    </div>
                </DialogFooterFlex>
            }
          />
        );
    }
}

export default NewQuotationDialog;
