import ratioTheme from '../../constants/theme';

export const normalizeTheme = (theme) => {
  const paletteCSS = {};

  if (theme.hasOwnProperty('palette')) {
    Object.keys(theme.palette).forEach(key => {
      const { r, g, b, a } = theme.palette[key];
      paletteCSS[key] = `rgba(${r}, ${g}, ${b}, ${a})`;
    });
  }

  const themeLogo = (theme.hasOwnProperty('logo') && theme.logo) ? theme.logo : ratioTheme.logo;
  const themeBackground = (theme.hasOwnProperty('background') && theme.background) ?
  theme.background :
  ratioTheme.background;
  const themeBackgroundOpacity = theme.backgroundOpacity || theme.backgroundOpacity === 0 ? theme.backgroundOpacity : 1;

  return { logo: themeLogo, background: themeBackground, backgroundOpacity: themeBackgroundOpacity,
    primaryColor: paletteCSS['primaryColor'] ? paletteCSS['primaryColor'] : ratioTheme.primaryColor,
    secondaryColor: paletteCSS['secondaryColor'] ? paletteCSS['secondaryColor'] : ratioTheme.secondaryColor,
    mainTextColor: theme.mainTextColor, theadTextColor: theme.theadTextColor, formValidColor: theme.formValidColor
  };
};

export const colorNames = {
  nl: {
    primaryColor: 'Hoofdkleur 1',
    secondaryColor: 'Hoofdkleur 2'
  },
};

export default {
  ratio: ratioTheme,
  normalizeTheme,
  colorNames,
};
