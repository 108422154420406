import React, {Component} from 'react';
import {Input} from 'react-materialize';

import KpnDialog from '../../containers/common/KpnDialog/KpnDialog';
import DialogHeader from "../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../containers/common/KpnDialog/DialogBody";
import DialogFooter from "../../containers/common/KpnDialog/DialogFooter";
import DialogButton from "../../containers/common/KpnDialog/DialogButton";
import {bindActionCreators} from "redux";
import orderingActions from "../../actions/workflow/orderingActions";
import connect from "react-redux/es/connect/connect";
import ReactDOM from "react-dom";
import quotationType from "../../constants/quotationType";
import {formatDate, isElInput, sleep} from "../../utils/utils";
import DatePicker from "../../components/datePicker";
import {opheffen} from "../../constants/telephoneNumberType";

class VamoProductDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vamoProduct: this.props.selectedVamoProduct || null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.vamoProduct !== prevProps.vamoProduct) {
      const vamoProduct = this.props.vamoProduct;
      this.setState({
        contractEndDate: {...vamoProduct, contractEndDate: vamoProduct.contractEndDate ? vamoProduct.contractEndDate.substr(0, 10) : null}
      });
    }
  }

  componentDidMount() {
    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);
      this.validateDates();
    });
  }

  handleChangeTerminateAsSoonAsPossible = ({target}) => {
    const {name, value} = target;

    this.setState({
      vamoProduct: {
        ...this.state.vamoProduct,
        [name]: value === 'true'
      }
    });

    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);
    });
  };

  onChangeContractEndDate = (date) => {
    this.setState({
      vamoProduct: {
        ...this.state.vamoProduct,
        contractEndDate: formatDate(date)
      }
    });
    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);
      this.validateDates();
    });
  };

  validateDates = () => {
    let form = ReactDOM.findDOMNode(this.globalForm);
    let el;
    let startDay = new Date();
    startDay.setUTCHours(0,0,0,0);

    if (!this.state.vamoProduct || !form) {
      return;
    }

    const {action} = this.state.vamoProduct;

    if (parseInt(action) === opheffen) {
      const today = startDay;
      const contractEndDate = this.state.vamoProduct?.contractEndDate ? new Date(this.state.vamoProduct.contractEndDate) : null;
      const minimumContractTerminationDate = new Date(formatDate(new Date(today.setMonth(today.getMonth() + 1))));

      el = form.querySelector('input[name="contractEndDate"]');
      if (el && contractEndDate) {
        if (
          (parseInt(action) === opheffen &&
            contractEndDate.getTime() < minimumContractTerminationDate.getTime()) ||
          contractEndDate === startDay
        ) {
          el.classList.remove('valid');
          el.classList.add('invalid');
        } else {
          el.classList.remove('invalid');
          el.classList.add('valid');
        }
      }
    }
  };

  onSubmit = () => {
    sleep(1000).then(() => {
      this.checkInputsForValidity(this.globalForm);
      this.validateDates();

      let invalidElements = ReactDOM.findDOMNode(this.globalForm).getElementsByClassName('invalid');

      if (!invalidElements.length) {
        this.props.onSave(this.state.vamoProduct);
      }
    });
  };

  onCancel = () => {
    this.props.onCancel();
  };

  checkInputsForValidity = (el) => {
    if (el && el.children) {
      Array.from(el.children).forEach((child) => {
        this.checkInputsForValidity(child);
      });
    }

    if(isElInput(el) === false) {
      return;
    }

    const parentElement = el.parentElement;

    if (el.checkValidity() === false) {
      el.classList.remove('valid');
      el.classList.add('invalid');

      if(parentElement.classList.contains('select-wrapper')) {
        parentElement.classList.remove('valid');
        parentElement.classList.add('invalid');
      }
    }

    if(el.checkValidity() === true) {
      el.classList.remove('invalid');
      el.classList.add('valid');

      if(parentElement.classList.contains('select-wrapper')) {
        parentElement.classList.remove('invalid');
        parentElement.classList.add('valid');
      }
    }
  };

  render() {
    const {selectedVamoProduct, quotationType} = this.props;
    const {vamoProduct} = this.state;

    const isOpheffenAction = selectedVamoProduct?.action === opheffen;
    const isVerlengenQuotation = [quotationType.TYPE_VERLENGEN, quotationType.TYPE_EXISTING].includes(quotationType);

    return (
      <div className="telephone-number-dialog">
        {<KpnDialog
          dialogState={this.props.dialogOpened}
          modal={false}
          overflowException={isVerlengenQuotation && isOpheffenAction}
          dialogHeader={(
            <DialogHeader
              headerIcon="edit_location"
              headerText="Aanvullen data: Ophefwensen"
              closeHandler={this.onCancel}
            />
          )}

          dialogBody={
            <DialogBody>
              {
                <form ref={(ref) => (this.globalForm = ref)}>
                  {[opheffen].includes(selectedVamoProduct?.action) &&
                  <div style={{marginTop: '10'}}>
                    <Input
                      type="select"
                      name="terminateAsSoonAsPossible"
                      label="Zo spoedig mogelijk opheffen?"
                      onChange={this.handleChangeTerminateAsSoonAsPossible}
                      value={vamoProduct?.terminateAsSoonAsPossible}
                    >
                      <option value={false} key={0}>Nee</option>
                      <option value={true} key={1}>Ja</option>
                    </Input>

                    {!vamoProduct?.terminateAsSoonAsPossible &&
                    <div className="col input-field porting-date-field">
                      <label htmlFor={"contractEndDate"}>Per wanneer opheffen?</label>
                      <DatePicker
                        name="contractEndDate"
                        id={"contractEndDate"}
                        selected={vamoProduct?.contractEndDate ? new Date(vamoProduct?.contractEndDate) : ''}
                        dateFormat="yyyy/MM/dd"
                        minDate={new Date(new Date().setMonth(new Date().getMonth() + 1))}
                        autoComplete="off"
                        onChange={this.onChangeContractEndDate}
                        onChangeRaw={e => e.preventDefault()}
                        required={true}
                      />
                    </div>
                    }
                  </div>
                  }
                </form>
              }
            </DialogBody>
          }
          dialogFooter={
            <DialogFooter
              buttonLeft={(
                <DialogButton
                  buttonAction={this.onCancel}
                  buttonText='Annuleren'
                  left={true}
                />
              )}
              buttonRight={(
                <DialogButton
                  buttonAction={this.onSubmit}
                  buttonText='Opnieuw versturen'
                />
              )}
            />
          }
        />}
      </div>
    )
  };
}

const mapStateToProps = () => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {
    orderingActions: bindActionCreators(orderingActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(VamoProductDialog);
