import React, {Component} from 'react';

class DigitalSigningConfirmation extends Component {
  render() {
    return (
      <fieldset style={{border: 'none', padding: 'unset', margin: 'unset'}}>
        <div className={'Forms digitalSigningForm'}>
          <div>
            <h4 style={{ paddingBottom: '2rem' }}>
              <b>
                Geachte klant,
              </b>
            </h4>
          </div>

          <div className="row">
            <span>
              Bedankt voor het akkoord gaan met de offerte.
            </span>
          </div>

          <div className="row">
            <span>
              U ontvangt binnen enkele ogenblikken het getekende document per e-mail.
            </span>
          </div>

          <div className="row">
            <span>
              Mocht u nog vragen hebben, kunt u contact opnemen met uw contactpersoon.
            </span>
          </div>

          <div className="row">
            <span>
              Met vriendelijke groet,
            </span>
          </div>
          <div className="row">
            <img src={this.props.theme.logo} className="responsive-img app-header-logo-digital-signing" alt="" />
          </div>
        </div>
      </fieldset>
    );
  }
}

export default DigitalSigningConfirmation;
