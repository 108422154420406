import { withTheme, injectGlobal } from 'styled-components';

const DropdownStyle = ({ theme }) => {
    injectGlobal`
    .dropdown-container .menu-button {
        background-color: ${theme.secondaryColor};
        color: white !important;
        text-transform: none;
        font-weight: unset;
        font-size: 14px;
        height: 3rem;
        &:hover {
            background-color: ${theme.primaryColor};
            color: white;
        }
        &:disabled {
            color: #9F9F9F;
            background-color: #DFDFDF;
        }
    }

    .dropdown-container .expanded-dropdown {
        color: #333 !important;
        background-color: #fff;
        border: 1px solid ${theme.secondaryColor};
    }
    
    .doceri-menu .MuiPaper-root {
        background: transparent;
    }
    
    .doceri-menu .menu-item {
        background-color: ${theme.secondaryColor};
        color: white;
    }
    
    .doceri-menu .MuiPaper-root.MuiMenu-paper ul {
        padding: 0;
    }
    
    .doceri-menu .MuiMenu-list li {
        margin-bottom: 5px;
        height: 3rem;
        
        &:hover {
            background: ${theme.primaryColor};
        }
    }
        
    .doceri-menu .MuiMenu-list li:last-child {
        margin-bottom: 0;
    }
    
    .doceri-menu .MuiList-root .MuiMenu-list {
        background: transparent;
    }

  `;

    return null;
}

export default withTheme(DropdownStyle);