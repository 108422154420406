import {
    updateConnectionOptions,
    updateConnectionOptionsSuccess,
    updateConnectionOptionsError,
} from '../../isdnMigrationActions/updateConnectionOptions';
import {apiRoutes} from '../../../constants/apiRoutes';
import ApiService from '../../../services/apiService';
import RequestType from '../../../constants/requestType';
import { getLocationOptionsThunk } from './getLocationOptionsThunk';
import hardwareActions from "../../hardwareActions";

const updateConnectionOptionsThunk = (quotationId, locationId, connectionId, payload) => async (
    dispatch,
) => {
    try {
        dispatch(updateConnectionOptions(connectionId, payload));

        const url = apiRoutes.isdnMigrationRelated.patchConnectionOptions.replace(
            /{connectionId}/,
            connectionId,
        );

        const requestData = await ApiService.performRequest(
            RequestType.PATCH,
            url,
            payload,
        );

        dispatch(updateConnectionOptionsSuccess(connectionId, requestData));

        dispatch(hardwareActions.getCustomHardwareOptions('access', quotationId));
        dispatch(hardwareActions.getCustomHardwareOptionsSelections(quotationId, 'access', locationId));

        dispatch(getLocationOptionsThunk(locationId));
    } catch (error) {
        dispatch(updateConnectionOptionsError(connectionId, error));
    }
};

export default updateConnectionOptionsThunk;
