import React from 'react';
import PropTypes from 'prop-types';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn,} from 'material-ui/Table';

import {DelayInput} from "react-delay-input";
import './index.css';
const HeadersNames = ['Producttype'];

class FlashCalculationTable extends React.Component {
    changeFlashCalculation = (calculation, {target}) => {
        let {name, value} = target;

        if (!isNaN(value) && value >= 0 && value <= 10000) {
            let calculationData = {...calculation};
            calculationData[name] = value;
            this.props.updateFlashCalculation(calculationData);
        }
    };

    generateInputMaxValue = (row) => {
        let max = 100;

        if (row.isIntegerDiscount) {
            max = 1000;
        }

        if (row.isEuroDiscount) {
            max = 10000;
        }

        return max;
    }

    spliceIntoChunks = (arr, chunkSize) => {
        const res = [];
        while (arr.length > 0) {
            const chunk = arr.splice(0, chunkSize);
            res.push(chunk);
        }
        return res;
    }


    generateRows = (data) => {
        const chunks = this.spliceIntoChunks(data, 2)

        return chunks.map((el) => (
            <TableRow>
                {
                    el.map((child) =>
                        <TableRowColumn>
                            <div className="input-field inner-addon calculation-label-container">
                                <span className="label">{child.label} {child.calculationName}</span>
                                <DelayInput
                                    value={child.discountValue.toString()}
                                    type="number"
                                    name="discountValue"
                                    min={0}
                                    max={this.generateInputMaxValue(child)}
                                    delayTimeout={1000}
                                    className="without-margin-bottom validate"
                                    onChange={(e) => this.changeFlashCalculation(child, e)}
                                />
                            </div>
                        </TableRowColumn>
                    )
                }
            </TableRow>
        ));
    }

    generateHeader = () => {
        return (
            HeadersNames.map(headerName =>
                <TableHeaderColumn key={headerName}>{headerName}</TableHeaderColumn>
            )
        );
    };

    render() {
        return (
            <div className="valign-wrapper">
                <div className="valign">
                    <Table selectable={false}>
                        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                            <TableRow>
                                {this.generateHeader()}
                            </TableRow>
                        </TableHeader>
                        <TableBody displayRowCheckbox={false}>
                            {this.generateRows(this.props.rows)}
                        </TableBody>
                    </Table>
                </div>
            </div>
        );
    }
}

FlashCalculationTable.propTypes = {
    rows: PropTypes.array.isRequired,
    updateFlashCalculation: PropTypes.func.isRequired
};

export default FlashCalculationTable;