import React from 'react';

import DialogHeader from "../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../containers/common/KpnDialog/DialogBody";
import DialogButton from "../../containers/common/KpnDialog/DialogButton";
import KpnDialog from "../../containers/common/KpnDialog/KpnDialog";
import DialogFooter from "../../containers/common/KpnDialog/DialogFooter";

const FlexiblePortingNumbersDialog =  (props) => {
  const {dialogOpened, onCancelDialog, sendOrder, inputsDisabled} = props;

  return (
    <KpnDialog
      dialogState={dialogOpened}
      modal={false}
      dialogHeader={(
        <DialogHeader
          headerText="Let op:"
          closeHandler={onCancelDialog}
        />
      )}

      dialogBody={
        <DialogBody>
          Bij porteren met de optie zelf afroepen zijn er momenteel aanpassingen binnen Routit. Neem contact op
            met support als je niet bekend bent met de juiste werkwijze na het orderen.
        </DialogBody>
      }

      dialogFooter={
        <DialogFooter
          buttonLeft={(
            <DialogButton
              buttonAction={onCancelDialog}
              buttonText="Order niet versturen"
              left={true}
            />
          )}

          buttonRight={(
            <DialogButton
              buttonAction={() => sendOrder()}
              buttonText="Akkoord, order versturen"
              buttonDisabled={inputsDisabled}
            />
          )}
        />
      }
    />
  );
};

export default FlexiblePortingNumbersDialog;
