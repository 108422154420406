import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";

import {Col, Input, Row} from 'react-materialize'
import {DelayInput} from "react-delay-input";

import telephonyActions from "../../../actions/workflow/telephonyActions";
import stepsActions from "../../../actions/stepsActions";
import quotationActions from "../../../actions/quotationActions";

class TelephonyContractLevel extends Component {
  render() {
    const {communicationOptions, onChangeCallback, inputsDisabled} = this.props;

    let solutions = [
      {id: 1, name:'Spraakcommunicatie', value: 1},
      {id: 2, name:'Datacommunicatie', value: 2},
      {id: 3, name:'Applicaties', value: 3},
      {id: 4, name:'Diensten', value: 4}
      ];

    return (
      <div>
        <div id="Forms" style={{paddingBottom: "10px"}}>
          <Row>
            <Col s={12}>
              <div>
                <h1 className={'ratio-section-title'}>
                    <span className="ratio-section-title">
                      <i className="small material-icons left ratio-section-title">settings_phone</i>
                      Gewenste oplossingen
                    </span>
                </h1>
              </div>
            </Col>
          </Row>
          <Row>
            {solutions.map((el) =>
              <button
                key={el.id}
                name="solution"
                value={el.value}
                className={`btn doceri-btn-left ratio-btn white-text ratio-btn-right ${communicationOptions.solution === el.value ? "active" : ""}`}
                style={{marginRight: 15}}
                onClick={onChangeCallback}
                disabled={inputsDisabled}
              >
                {el.name}
              </button>
            )
            }
          </Row>
        </div>

        <div id="Forms" style={{paddingBottom: "10px"}}>
          <Row>
            <Col s={12}>
              <div>
                <h1 className={'ratio-section-title'}>
                    <span className="ratio-section-title">
                      <i className="small material-icons left ratio-section-title">settings_phone</i>
                      Spraakcommunicatie:
                    </span> gewenste opties
                </h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Input
              s={6}
              type="select"
              label="Tetra"
              name="tetra"
              onChange={onChangeCallback}
              multiple={false}
              value={communicationOptions.tetra}
              disabled={inputsDisabled}
            >
              <option key={-1} value={-1}>
                Niets geselecteerd
              </option>
              <option key={1} value={false}>Nee</option>
              <option key={2} value={true}>Ja</option>
            </Input>
            <Input
              s={6}
              type="select"
              label="DMR"
              name="dmr"
              onChange={onChangeCallback}
              multiple={false}
              value={Number(communicationOptions.dmr).toString()}
              disabled={inputsDisabled}
            >
              <option key={-1} value={0}>
                Niets geselecteerd
              </option>
              <option key={1} value={1}>Hydra DMR</option>
              <option key={2} value={2}>Motorola MOTOTRBO</option>
            </Input>
            <Input
              s={6}
              type="select"
              label="Push-to-Talk"
              name="pushToTalk"
              onChange={onChangeCallback}
              multiple={false}
              value={communicationOptions.pushToTalk}
              disabled={inputsDisabled}
            >
              <option key={-1} value={-1}>
                Niets geselecteerd
              </option>
              <option key={1} value={false}>Nee</option>
              <option key={2} value={true}>Ja</option>
            </Input>
            <Input
              s={6}
              type="select"
              label="NEXEDGE"
              name="nexedge"
              onChange={onChangeCallback}
              multiple={false}
              value={communicationOptions.nexedge}
              disabled={inputsDisabled}
            >
              <option key={-1} value={-1}>
                Niets geselecteerd
              </option>
              <option key={1} value={false}>Nee</option>
              <option key={2} value={true}>Ja</option>
            </Input>
          </Row>
        </div>

        <div id="Forms" style={{paddingBottom: "10px"}}>
          <Row>
            <Col s={12}>
              <div>
                <h1 className={'ratio-section-title'}>
                    <span className="ratio-section-title">
                      <i className="small material-icons left ratio-section-title">settings_phone</i>
                      Hytera DMR:
                    </span> gewenste opties
                </h1>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="col s12">
              <span>Hytera-portofoons</span>
              <br/><br/>
            </div>
            <div className="input-field col s6 m6 inner-addon">
              <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
              <DelayInput
                delayTimeout={500}
                id="hyteraUsers"
                type="number"
                min={0}
                max={999}
                className="validate"
                name="hyteraUsers"
                value={Number(communicationOptions.hyteraUsers).toString()}
                onChange={onChangeCallback}
                disabled={inputsDisabled}
              />
              <label htmlFor="hyteraUsers">Aantal Hytera-portofoon gebruikers</label>
            </div>
            <Input
              s={6}
              name="hyteraPurchaseType"
              type='select'
              label="Koop, huur of Lease"
              onChange={onChangeCallback}
              value={Number(communicationOptions.hyteraPurchaseType).toString()}
              multiple={false}
              disabled={inputsDisabled}
            >
              <option key={-1} value={0}>
                maak keuze
              </option>
              <option key={1} value={1}>Huur</option>
              <option key={2} value={2}>Koop</option>
              <option key={3} value={3}>Lease</option>
            </Input>
          </Row>
          <Row>
            <div className="col s12">
              <span>Site aantallen</span>
              <br/><br/>
            </div>
            <div className="input-field col s6 m6 inner-addon">
              <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
              <DelayInput
                delayTimeout={500}
                id="newSites"
                type="number"
                min={0}
                max={999}
                className="validate"
                name="newSites"
                value={Number(communicationOptions.newSites).toString()}
                onChange={onChangeCallback}
                disabled={inputsDisabled}
              />
              <label htmlFor="newSites">Aantal nieuwe sites</label>
            </div>
            <div className="input-field col s6 m6 inner-addon">
              <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
              <DelayInput
                delayTimeout={500}
                id="existingSites"
                type="number"
                min={0}
                max={999}
                className="validate"
                name="existingSites"
                value={Number(communicationOptions.existingSites).toString()}
                onChange={onChangeCallback}
                disabled={inputsDisabled}
              />
              <label htmlFor="existingSites">Aantal bestaande sites</label>
            </div>
          </Row>
          <Row>
            <div className="col s12">
              <span>Extra gespreks opties</span>
              <br/><br/>
            </div>
            <div className="input-field col s6 m6 inner-addon">
              <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
              <DelayInput
                delayTimeout={500}
                id="conversationEavesdropping"
                type="number"
                min={0}
                max={999}
                className="validate"
                name="conversationEavesdropping"
                value={Number(communicationOptions.conversationEavesdropping).toString()}
                onChange={onChangeCallback}
                disabled={inputsDisabled}
              />
              <label htmlFor="conversationEavesdropping">Gesprekken afluisterbaar?</label>
            </div>
            <div className="input-field col s6 m6 inner-addon">
              <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
              <DelayInput
                delayTimeout={500}
                id="analogBoot"
                type="number"
                min={0}
                max={999}
                className="validate"
                name="analogBoot"
                value={Number(communicationOptions.analogBoot).toString()}
                onChange={onChangeCallback}
                disabled={inputsDisabled}
              />
              <label htmlFor="analogBoot">Aantal analoog opstarten?</label>
            </div>
          </Row>
          <Row>
            <div className="col s12">
              <span>Multi-site koppeling</span>
              <br/><br/>
            </div>
            <div className="input-field col s6 m6 inner-addon">
              <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
              <DelayInput
                delayTimeout={500}
                id="multipleSites"
                type="number"
                min={0}
                max={999}
                className="validate"
                name="multipleSites"
                value={Number(communicationOptions.multipleSites).toString()}
                onChange={onChangeCallback}
                disabled={inputsDisabled}
              />
              <label htmlFor="multipleSites">Multi-site toepassing gewenst?</label>
            </div>
            <div className="input-field col s6 m6 inner-addon">
              <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
              <DelayInput
                delayTimeout={500}
                id="ipAddresses"
                type="number"
                min={0}
                max={999}
                className="validate"
                name="ipAddresses"
                value={Number(communicationOptions.ipAddresses).toString()}
                onChange={onChangeCallback}
                disabled={inputsDisabled}
              />
              <label htmlFor="ipAddresses">Aantal IP addressen te koppelen</label>
            </div>
          </Row>
          <Row>
            <div className="col s12">
              <span>XPT</span>
              <br/><br/>
            </div>
            <Input
              s={6}
              type="select"
              label="XPT gewenst?"
              name="xptActive"
              onChange={onChangeCallback}
              multiple={false}
              value={communicationOptions.xptActive}
              disabled={inputsDisabled}
            >
              <option key={-1} value={-1}>
                Niets geselecteerd
              </option>
              <option key={1} value={false}>Nee</option>
              <option key={2} value={true}>Ja</option>
            </Input>
            <div className="input-field col s6 m6 inner-addon">
              <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
              <DelayInput
                delayTimeout={500}
                id="xptSemiTrunks"
                type="number"
                min={0}
                max={999}
                className="validate"
                name="xptSemiTrunks"
                value={Number(communicationOptions.xptSemiTrunks).toString()}
                onChange={onChangeCallback}
                disabled={inputsDisabled}
              />
              <label htmlFor="xptSemiTrunks">Aantal XPT semi-trunks</label>
            </div>
            <Input
              s={6}
              type="select"
              label="Upgrade naar DMR Tier III gewenst?"
              name="dmrTier3Upgrade"
              onChange={onChangeCallback}
              multiple={false}
              value={communicationOptions.dmrTier3Upgrade}
              disabled={inputsDisabled}
            >
              <option key={-1} value={-1}>
                Niets geselecteerd
              </option>
              <option key={1} value={false}>Nee</option>
              <option key={2} value={true}>Ja</option>
            </Input>
            <div className="input-field col s6 m6 inner-addon">
              <i className="glyphicon tiny material-icons left lightgrey">local_phone</i>
              <DelayInput
                delayTimeout={500}
                id="dmrTier3Trunks"
                type="number"
                min={0}
                max={999}
                className="validate"
                name="dmrTier3Trunks"
                value={Number(communicationOptions.dmrTier3Trunks).toString()}
                onChange={onChangeCallback}
                disabled={inputsDisabled}
              />
              <label htmlFor="dmrTier3Trunks">Aantal DMR Tier III trunks</label>
            </div>
          </Row>
        </div>
      </div>
    );
  };
}

const mapStateToProps = ({quotation, authentication, locations}) => {
  return {
    quotation,
    quotationId: quotation.currentQuotation.id,
    authentication: authentication,
    locations: locations
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    quotationActions: bindActionCreators(quotationActions, dispatch),
    telephonyActions: bindActionCreators(telephonyActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TelephonyContractLevel);
