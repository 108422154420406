import React from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table';
import RatioOverflowTableWrapper from "../../../components/styled-components/ratio-overflow-table-wrapper/RatioOverflowTableWrapper";

const DigitalSigningFieldGrid = (props) => {
    const generateRow = (row) => {
        return (
            <TableRow key={row.id}>
                <TableRowColumn>{row.name}</TableRowColumn>
                <TableRowColumn>{row.description}</TableRowColumn>
                <TableRowColumn>{`\${${row.tag}}`}</TableRowColumn>
                <TableRowColumn>{new Date(row.createdAt).toLocaleString('nl-NL')}</TableRowColumn>
                <TableRowColumn>{new Date(row.updatedAt).toLocaleString('nl-NL')}</TableRowColumn>
                <TableRowColumn>{row.updatedBy}</TableRowColumn>
                <TableRowColumn>
                    <div>
                        <a href="#" onClick={() => props.onEditClick(row)}>
                            <i className="small material-icons left themeprimarycolor">edit</i>
                        </a>
                        <a href="#" onClick={() => props.onSortClick(row)}>
                            <i className="small material-icons left themeprimarycolor">arrow_upward</i>
                        </a>
                        <a href="#" onClick={() => props.onDeleteClick(row)}>
                            <i className="small material-icons left themeprimarycolor">clear</i>
                        </a>
                    </div>
                </TableRowColumn>
            </TableRow>
        );
    };

    const generateHeader = () => {
        return (
            props.headerNames.map(headerName =>
                <TableHeaderColumn key={headerName} headername={headerName} >
                    {headerName}
                </TableHeaderColumn>
            )
        );
    };

    return (
        <RatioOverflowTableWrapper>
            <div className="valign-wrapper">
                <Table selectable={false}>
                    <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                        <TableRow onCellClick = {props.onHeaderClick}>
                            {
                                generateHeader()
                            }
                        </TableRow>
                    </TableHeader>

                    <TableBody displayRowCheckbox={false}>
                        {
                            props.rows.map(row => generateRow(row))
                        }
                    </TableBody>
                </Table>
            </div>
        </RatioOverflowTableWrapper>
    );
};

DigitalSigningFieldGrid.propTypes = {
    onEditClick: PropTypes.func.isRequired,
    getPropertyNameFromDisplayName: PropTypes.func.isRequired,
    rows: PropTypes.array.isRequired,
    headerNames: PropTypes.array.isRequired
};

export default DigitalSigningFieldGrid;