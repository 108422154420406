import {withTheme, injectGlobal} from "styled-components";

const RatioTableStyle = ({ theme }) => {
    injectGlobal`
   @media (max-width: 768px) {
      .ratio-table-container {
        overflow-x: auto;
        width: 100%;
      }
    }
    
    @media (max-width: 768px) {
        .ratio-table-max-width {
          width: 1350px;
        }
    }
    
    .ratio-table-max-width div:first-child {
        width: 100%;
        height: 100%;
    }
  `;
    return null;
}

export default withTheme(RatioTableStyle);
