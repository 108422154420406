import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import RatioOverflowTableWrapper from "../../../components/styled-components/ratio-overflow-table-wrapper/RatioOverflowTableWrapper";

const DataManagementGrid = (props) => {

  const generateRow = (row) => {
    return (
      <TableRow key={row.id}>
        <TableRowColumn>{row.name}</TableRowColumn>
        <TableRowColumn>{row.description}</TableRowColumn>
        <TableRowColumn>
            <div>
                <a href="#" onClick={() => props.onEditClick(row)}>
                    <i className="small material-icons left themeprimarycolor">edit</i>
                </a>
            </div>
        </TableRowColumn>
      </TableRow>
    );
  }

  const generateHeader = () => {
    return (
      props.headerNames.map(headerName =>
        <TableHeaderColumn key={headerName} className="clickable" headername={headerName} >
            {headerName}
        </TableHeaderColumn>
      )
    );
  }

  return (
      <RatioOverflowTableWrapper>
          <div className="valign-wrapper">
              <Table selectable={false}>
                  <TableHeader displaySelectAll={false} adjustForCheckbox={false} >
                      <TableRow>
                          {
                              generateHeader()
                          }
                      </TableRow>
                  </TableHeader>
                  <TableBody displayRowCheckbox={false}>
                      {
                          props.rows.map(row =>
                              generateRow(row)
                          )
                      }
                  </TableBody>
              </Table>
          </div>
      </RatioOverflowTableWrapper>
  );
}

DataManagementGrid.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  rows: PropTypes.array.isRequired,
  headerNames: PropTypes.array.isRequired
}

export default DataManagementGrid;