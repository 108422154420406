import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom'

import adminActions from '../../../actions/adminActions';
import hardwareActions from '../../../actions/hardwareActions';

import FileDownload from "js-file-download";
import ImportHardware from "./ImportHardware";
import DeleteHardwareAndServices from "../DeleteHardwareAndServices";
import AddHardwareOption from "./AddHardwareOption";
import HardwareOptionForm from "./HardwareOptionForm";
import HardwareOption from "./HardwareOption";
import ProviderHelper from "../../../helper/providerHelper";
import {Input} from "react-materialize";

class HardwareOptionsPanel extends Component {

  constructor(props) {
    super(props);

    this.state = {
      importDialogOpened: false,
      importFailureDialogOpened: false,
      importErrorMessage: '',
      deleteDialogOpened: false,
      manualAdditionDialogOpened: false,
      allowEditOnHardware: null,
      hardwareProvider: 0,
      setToInactive: 0,
      copyProduct: 0
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hardwareProvider !== this.state.hardwareProvider) {
      this.getData();
    }
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    let providerId = this.state.hardwareProvider;
    this.props.hardwareActions.getAllHardwareProducts(providerId);
    this.props.hardwareActions.getProductsToLink(providerId);
    this.props.hardwareActions.getHardwareOptionCategories();
  }

  goToDashboard = () => {
    this.props.history.push('/');
  };

  getExampleFile = () => {
    this.props.hardwareActions.getExampleFile().then(() => this.downloadExampleFile());
  };

  getAssortimentFile = () => {
    this.props.hardwareActions.getAssortimentFile(this.state.hardwareProvider).then(() => this.downloadAssortimentFile());
  };

  downloadExampleFile = () => {
    FileDownload(this.props.hardware.exampleFile, 'KPN-EEN-hardwareopties.xlsx');
  };

  downloadAssortimentFile = () => {
    FileDownload(this.props.hardware.assortimentFile, 'KPN-EEN-assortiment-hardwareopties.xlsx');
  };


  onDeleteClick = (id) => {
      const response = this.props.hardwareActions.removeHardwareOption(id, this.state.hardwareProvider);
      response.then(() => {
          this.setState({
              deleteDialogOpened: false,
          });
      });
  };

  onEditClick = (hardwareId) => {
      this.setState({
          allowEditOnHardware: hardwareId,
      });
  };

  onSaveClick = (id, data) => {
      let hardwareData = {
          hardwareData: data
      };

      this.props.hardwareActions.updateHardwareOption(id, hardwareData, this.state.hardwareProvider);

  };

  _handleManualAdditionDialogClose = () => {
      this.setState({manualAdditionDialogOpened: false});
  };

  _handleManualAdditionDialogOpen = () => {
    this.setState({manualAdditionDialogOpened: true});
  };

  _onAddClick = (data) => {
      data = {newHardwareData : data} ;
      const response = this.props.hardwareActions.addHardwareOption(data, this.state.hardwareProvider);
      response.then(() => {
          this.setState({
              manualAdditionDialogOpened: false,
          });
      });
  };

  _handleLocationDialogOpen = () => {
    this.setState({importDialogOpened: true});
  };

  _handleLocationDialogClose = () => {
    this.setState({importDialogOpened: false});
  };

  _changeOptions = ({target}) => {
    const {name, value} = target;
    this.setState({[name]: value});
  };

  _handleDeletionDialogOpen = () => {
      this.setState({deleteDialogOpened: true});
  };

  _handleDeletionDialogClose = () => {
      this.setState({deleteDialogOpened: false});
  };

  _onClick = () => {
    const response = this.props.hardwareActions.removeHardware(this.state.hardwareProvider);
    response.then(() => {
      this.setState({
          deleteDialogOpened: false,
      });
    });
  };

  _onDrop = (files) => {
    let file = files[0];

    if (!file)
      return;

    const response = this.props.hardwareActions.uploadBulk(file, this.state.hardwareProvider,
        {setToInactive: this.state.setToInactive, copyProduct: this.state.copyProduct});
    response.then((val) => {
      if (val && val.error) {
        this.setState({
          importErrorMessage: val.error.response.data,
          importFailureDialogOpened: true
        })
      } else {
        this.setState({file: file});
      }
    });

    this._handleLocationDialogClose();
  };

  updateProduct = (product, hwIndex, property, value) => {
    let index;
    this.props.hardware.allProducts.forEach((item, pos) => {
      if (item.id === product.id) {
        index = pos;
      }
    });

    this.props.hardware.allProducts[index]['hardware_options'][hwIndex][property] = value;
    this.props.hardwareActions.setHardwareProducts(this.props.hardware.allProducts);
  };

  handleChangeProvider(event) {
    this.setState({hardwareProvider: event.target.value});
  };

  render() {
    let {allProducts, allHardwareOptions} = this.props.hardware;
    let accessProductsExist = allProducts && allProducts.length;
    let accessOptionExist = allHardwareOptions.access && allHardwareOptions.access.length;
    let mobileOptionExist = allHardwareOptions.mobile && allHardwareOptions.mobile.length;
    let voiceOptionExist = allHardwareOptions.voice && allHardwareOptions.voice.length;

    return (
      <div>
        <div id="Forms" className="ratiotable paddingbottomnone">
          <h1>
              <span className="ratio-section-title">
                  Hardware opties
              </span>
              <div className="hardware-provider">
                  <Input
                      name={'hardwareProvider'}
                      id={'hardwareProvider'}
                      type="select"
                      multiple={false}
                      required={true}
                      defaultValue={0}
                      onChange={(e) => this.handleChangeProvider(e)}
                      className="validate">
                      <option value="-1" disabled={'disabled'} key={-1}>Kies provider</option>
                      {this.props.authentication.providers.filter((el => el.provider !== 1)).map((el) => <option
                          value={el.provider}
                          key={el.provider}>{ProviderHelper.getQuotationProviderName(el.provider)}</option>)}
                  </Input>
              </div>
          </h1>
        </div>

        <div className="row">
          <div className="col s12 admin-top-buttons">
            <button
              className="btn doceri-btn-right ratio-btn-right"
              onClick={() => this.getAssortimentFile()}
              style={{marginLeft: 20}}
              type="submit"
              name="action">
              <i className="small material-icons left white-text">save_alt</i>
              Download assortiment
            </button>

            <button
              className="btn doceri-btn-right ratio-btn-right"
              onClick={() => this._handleLocationDialogOpen()}
              type="submit"
              name="action">
              <i className="small material-icons left white-text">add</i>
                Bulkupload hardware
            </button>

            <button
              className="btn doceri-btn-right kpn-btn-right"
              onClick={() => this._handleManualAdditionDialogOpen()}
              type="submit"
              name="action">
              <i className="small material-icons left white-text">add</i>
                Voeg hardware toe
            </button>

            <button
              className="btn doceri-btn-right ratio-btn-right"
              onClick={() => this._handleDeletionDialogOpen()}
              type="submit"
              name="action">
              <i className="small material-icons left white-text">delete</i>
              Verwijder alles
            </button>
          </div>
        </div>

        {accessProductsExist &&
        <div>
          <h1 style={{borderBottom: '1px solid black'}}>Access hardware</h1>
          {
            this.props.hardware.allProducts.map((product, index) => {
              return <HardwareOptionForm
                  key={index}
                  product={product}
                  onDelete={this.onDeleteClick}
                  onSave={this.onSaveClick}
                  onEdit={this.onEditClick}/>
            })
          }
        </div>
        }

        {accessOptionExist &&
        <div>
          <h1 style={{borderBottom: '1px solid black'}}>Access ongekoppelde hardware</h1>
          {this.props.hardware.allHardwareOptions.access.map((hardwareOption, index) => {
            return <HardwareOption
                key={index}
                index={index}
                hardwareOption={hardwareOption}
                isLinked={false}
                allowEditOnHardware={this.state.allowEditOnHardware}
                onDelete={this.onDeleteClick}
                onSave={this.onSaveClick}
                onEdit={this.onEditClick}
            />
          })}
        </div>
        }

        {mobileOptionExist &&
        <div>
          <h1 style={{borderBottom: '1px solid black'}}>Mobiel hardware</h1>
          {this.props.hardware.allHardwareOptions.mobile.map((hardwareOption, index) => {
            return <HardwareOption
                key={index}
                index={index}
                hardwareOption={hardwareOption}
                isLinked={false}
                allowEditOnHardware={this.state.allowEditOnHardware}
                onDelete={this.onDeleteClick}
                onSave={this.onSaveClick}
                onEdit={this.onEditClick}/>
          })}
        </div>
        }

        {voiceOptionExist &&
        <div>
          <h1 style={{borderBottom: '1px solid black'}}>Voice hardware</h1>
          {this.props.hardware.allHardwareOptions.voice && this.props.hardware.allHardwareOptions.voice.map((hardwareOption, index) => {
            return <HardwareOption
                key={index}
                index={index}
                hardwareOption={hardwareOption}
                isLinked={false}
                allowEditOnHardware={this.state.allowEditOnHardware}
                onDelete={this.onDeleteClick}
                onSave={this.onSaveClick}
                onEdit={this.onEditClick}/>
          })}
        </div>
        }

        <ImportHardware dialogOpened={this.state.importDialogOpened}
                        onRequestClose={this._handleLocationDialogClose}
                        changeOptions={this._changeOptions}
                        save={this.save}
                        onDrop={this._onDrop}
                        droppedFile={this.state.file}
        />

        <AddHardwareOption dialogOpened={this.state.manualAdditionDialogOpened}
                           onRequestClose={this._handleManualAdditionDialogClose}
                           tab={'hardware opties'}
                           onClickFunc={this._onAddClick}
                           productsToLink={this.props.hardware.productsToLink.products}
                           categories={this.props.hardware.hardwareOptionCategories.categories}
        />

        <DeleteHardwareAndServices dialogOpened={this.state.deleteDialogOpened}
                        onRequestClose={this._handleDeletionDialogClose}
                        tab={'hardware opties'}
                        onClickFunc={this._onClick}
        />

      </div>
    );
  }
}

const mapStateToProps = ({authentication, hardware}) => {
  return {
    authentication: authentication,
    hardware: hardware,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminActions: bindActionCreators(adminActions, dispatch),
    hardwareActions: bindActionCreators(hardwareActions, dispatch)
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HardwareOptionsPanel));
