import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';

import connectionsActions from '../../../actions/connectionsActions';
import accessActions from '../../../actions/workflow/accessActions';

import {xdslConnectionTypes} from "../../../constants/connectionType";

class ConnectionAvailability extends Component {
  componentDidMount() {
    this.loadConnectionsGrid(this.props);
  }

  loadConnectionsGrid(props) {
    const location = props.locations.selectedLocation;

    if (location.id !== -1 && !location.flight_case) {
      if (!location.fetched) {
        props.setLoading(true);
      }
      props.connectionsActions.getConnectionsAvailabilityPerLocation(location.id)
        .then(() => {
          this.props.accessActions.getOptions(this.props.quotation.currentQuotation.id);
          this.props.accessActions.getAccessStepState(this.props.quotation.currentQuotation.id);
          props.setLoading(false);
        })
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.locations.selectedLocation.id !== this.props.locations.selectedLocation.id) {
      this.loadConnectionsGrid(newProps);
    }
  }

  getColorBasedOnAvailability = (availability) => {
    switch (availability) {
      case 'Green':
        return 'green-text';
      case 'Yellow':
        return 'yellow-text';
      case 'Red':
        return 'red-text';
      default:
        return '';
    }
  };

  generateHeaders = () => {
    return (
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow>
          <TableHeaderColumn style={{width: '25%'}}>Provider</TableHeaderColumn>
          <TableHeaderColumn style={{width: '15%'}}>Technology</TableHeaderColumn>
          <TableHeaderColumn style={{width: '30%'}}>Availability</TableHeaderColumn>
          <TableHeaderColumn style={{whiteSpace: 'normal', width: '40%', wordWrap: 'break-word'}}>
              Description
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>
    )
  };

  generateOwnBrandHeaders = () => {
    return (
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow>
          <TableHeaderColumn style={{width: '10%'}}>Provider</TableHeaderColumn>
          <TableHeaderColumn style={{width: '10%'}}>Technology</TableHeaderColumn>
          <TableHeaderColumn style={{width: '10%'}}>Availability</TableHeaderColumn>
          <TableHeaderColumn style={{whiteSpace: 'normal', width: '50%', wordWrap: 'break-word'}}>
            Description
          </TableHeaderColumn>
          <TableHeaderColumn style={{width: '10%'}}>NLS</TableHeaderColumn>
          <TableHeaderColumn style={{width: '10%'}}>FTU</TableHeaderColumn>
        </TableRow>
      </TableHeader>
    )
  };

  generateRow = (row) => {
    const location = this.props.locations.selectedLocation;
    if (typeof row.technology === 'object') {
      row.technology = ''
    }

    return (
      <TableRow key={row.id}>
        <TableRowColumn style={{width: '25%'}}>{row.provider}</TableRowColumn>
        <TableRowColumn style={{width: '15%'}}>
          {row.technology} {location.dsl_eos_date && xdslConnectionTypes.includes(row.technology) ? '(End of Sale)' : ''}
        </TableRowColumn>
        <TableRowColumn style={{width: '30%'}} className={this.getColorBasedOnAvailability(row.availability)}>
            {row.availability} {row.portfolio ? ` (${row.portfolio})` : ''}
        </TableRowColumn>
        <TableRowColumn style={{whiteSpace: 'normal', width: '40%', wordWrap: 'break-word'}}>{row.description}</TableRowColumn>
      </TableRow>
    );
  };

  generateOwnBrandRow = (row) => {
    const location = this.props.locations.selectedLocation;
    if (typeof row.technology === 'object') {
      row.technology = ''
    }

    return (
      <TableRow key={row.id}>
        <TableRowColumn style={{width: '10%'}}>{row.provider}</TableRowColumn>
        <TableRowColumn style={{width: '10%'}}>
          {row.technology} {location.dsl_eos_date && xdslConnectionTypes.includes(row.technology) ? '(End of Sale)' : ''}
        </TableRowColumn>
        <TableRowColumn style={{width: '10%'}} className={this.getColorBasedOnAvailability(row.availability)}>
          {row.availability} {row.portfolio ? ` (${row.portfolio})` : ''}
        </TableRowColumn>
        <TableRowColumn style={{whiteSpace: 'normal', width: '50%', wordWrap: 'break-word'}}>{row.description}</TableRowColumn>
        <TableRowColumn style={{whiteSpace: 'normal', width: '10%', wordWrap: 'break-word'}}>{row.nls}</TableRowColumn>
        <TableRowColumn style={{whiteSpace: 'normal', width: '10%', wordWrap: 'break-word'}}>{row.ftu}</TableRowColumn>
      </TableRow>
    );
  };

  render() {
    const {results} = this.props.connections;
    const locationId = this.props.locations.selectedLocation.id;
    const {isOwnBrand} = this.props.quotation.currentQuotation;

    return this.props.showConnections ? (
      results.length > 0 && locationId !== 0 &&
      <Table wrapperStyle={{overflow: 'initial'}} onRowSelection={this.props.handleRowSelection} selectable={false}>
        {isOwnBrand ? this.generateOwnBrandHeaders() : this.generateHeaders()}
        <TableBody displayRowCheckbox={false}>
          {results.map(row => isOwnBrand ? this.generateOwnBrandRow(row) : this.generateRow(row))}
        </TableBody>
      </Table>
    ) : null;
  }
}

const mapStateToProps = ({connections, locations, quotation}) => {
  return {
    connections,
    locations,
    quotation,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    connectionsActions: bindActionCreators(connectionsActions, dispatch),
    accessActions: bindActionCreators(accessActions, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionAvailability);
