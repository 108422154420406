import React from 'react';

const DialogBody = ({children}) => {
    return (
      <div className=" col s12 left-align">
        <div className="md-dialog__body-description" style={{marginBottom: 20}}>
            {children}
        </div>
      </div>
    )
};

/* const DialogBody = ({children}) => {
    return (
        <div className="col s12 md-dialog__body-description marginbottom30">
            {children}
        </div>
    )
}; */

export default DialogBody;
