import React from 'react';

const DefaultDialogBody = ({ text, style }) => {

  return (
    <div className="md-dialog__body-description" style={style}>
      {text}
    </div>
  )
}

DefaultDialogBody.defaultProps = {
  style: {
    whiteSpace: 'pre-line'
  }
}

export default DefaultDialogBody;