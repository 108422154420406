import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: ${props => props.empty ? 'column' : 'row'};
  align-items: center;
  justify-content: center;
  width: auto;
  height: ${props => props.height ? props.height : '100px'};
  border: ${props => props.empty ? '1px dashed #d1d1d1' : 'none'};
  text-align: center;
  color: #9e9e9e;
  .material-icons {
    margin: 0;
  }
  .upload-label {
    display: inline-block;
    font-size: 0.8rem;
    line-height: 1.3rem;
  }
`;
