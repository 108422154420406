import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import OrdersMenu from "../../components/ordersMenu";
import Navbar from "../../components/navbar";
import authenticationActions from "../../actions/authenticationActions";
import ordersActions from "../../actions/ordersActions";
import OrdersList from "./ordersList";
import QuotationOrders from "./quotationOrderProducts";

class Orders extends Component {
  renderContent = () => {
    switch (this.props.orders.currentSectionId) {
      case 'quotation-orders':
        return <QuotationOrders />;
      case 'orders':
        return <OrdersList />;
      default:
        return <OrdersList />;
    }
  };

  redirectToHome = () => {
    this.props.history.push('/');
  };

  goToAdminPage = () => {
    this.props.history.push('/admin');
  };

  logout = () => {
    this.props.authenticationActions.logout();
  };

  switchOrganization = async ({target}) => {
    const organizationId = target.value;
    await this.props.authenticationActions.switchOrganization(organizationId);
    const {switchOrganization} = this.props.authentication;

    if (switchOrganization) {
      window.location.reload();
    }
  }

  render() {
    const { name, company, theme } = this.props.authentication;
    const {currentSectionId, currentQuotationOrder} = this.props.orders;

    return (
      <div>
        <Navbar
          onAdminClick={this.goToAdminPage}
          onLogoutClick={this.logout}
          onSettingsClick={this.redirectToHome}
          alerts={this.props.alerts}
          switchableOrganizationList={this.props.authentication.switchableOrganizationList}
          switchOrganizationAction={this.switchOrganization}
          allowSwitch={this.props.authentication.allowSwitch}
        />

        <div className="valign-wrapper">
          <div className="valign">
            <div className="container maincontainer">
              <div className="section">
                <div className="row">
                  <OrdersMenu
                    company={company}
                    fullName={name}
                    theme={theme}
                    currentSectionId={currentSectionId}
                    customerName={currentQuotationOrder.customerName}
                  />
                </div>

                <div className="row">
                  {this.renderContent()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({authentication, orders, alerts}) => {
  return {
    authentication,
    orders,
    alerts
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch),
    ordersActions: bindActionCreators(ordersActions, dispatch),
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));
