import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';

import stepsActions from "../../../actions/stepsActions";
import quotationActions from "../../../actions/quotationActions";
import orderWizardActions from "../../../actions/orderWizardActions";

import LoaderComponent from "../../../containers/common/LoaderComponent";
import {Input} from "react-materialize";
import {DelayInput} from "react-delay-input";
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'

import DatePicker from "../../../components/datePicker";
import GeneralDialog from "../../../containers/common/GeneralDialog";
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";

import "react-datepicker/dist/react-datepicker.css";
import {registerLocale, setDefaultLocale} from "react-datepicker";

import nl from 'date-fns/locale/nl';
import countryCodes, {countries} from "../../../constants/countryCodes";

registerLocale('nl', nl);
setDefaultLocale('nl');

const gendersTranslation = new Map([
    ["M", "Mannelijk"],
    ["F", "Vrouwelijk"],
    ["U", "Niet openbaar gemaakt"],
  ]
);

const primaryIDTranslation = new Map([
  ['European identification', 'Europese identificatie'],
  ['Passport', 'Paspoort'],
  ['IND sticker', 'IND-sticker'],
  ['Drivers license', 'Rijbewijs'],
  ['Privileged person identity card', 'Identiteitskaart bevoorrechte persoon'],
  ['Residence permit type I', 'Verblijfsvergunning type I'],
  ['Residence permit type II', 'Verblijfsvergunning type II'],
  ['Residence permit type III', 'Verblijfsvergunning type III'],
  ['Residence permit type IV', 'Verblijfsvergunning type IV'],
  ['Residence card EU/EER', 'Verblijfskaart EU/EER'],
  ['Residence permit Article 50 TEU', 'Verblijfsvergunning Artikel 50 VEU'],
  ['Document permanent residency', 'Document permanente verblijfsvergunning'],
]);

const addressTranslation = new Map([
  ["MAIN", 'Hoofdadres'],
  ["MAIL", 'Mail adres'],
  ["DELIVERY", 'Bezorgadres'],
  ["PICKUP", 'Ophaaladres'],
  ["CONTACT", 'Contact address'],
]);

const newAddress = {
  id: -1,
  country: '',
  city: '',
  street: '',
  postalCode: '',
  houseNumber: '',
  houseNumberExtension: '',
  contactType: '',
  addressRole: '',
  phoneNumber: '',
};

class OrderWizardStep extends Component {

  constructor(props) {
    super(props);
    this.state = {
      wizardLoading: true,
      orderDetails: this.props.orderDetails,
      orderAddressList: this.props.addressList,
      wizardOptions: this.props.wizardOptions,
      addressDialogOpen: false,
      addressDialogAction: null,
      selectedAddressID: null,
    }
  }

  componentDidMount() {
    const quotationID = this.props.quotation.id;

    this.props.orderWizardActions.getOrderWizardDetails(quotationID).then(() => this.setState({wizardLoading: false}));
    this.props.orderWizardActions.getOrderWizardDetailOptions();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.orderDetails !== this.props.orderDetails) {
      this.setState({orderDetails: this.props.orderDetails});
    }

    if (prevProps.wizardOptions !== this.props.wizardOptions) {
      this.setState({wizardOptions: this.props.wizardOptions});
    }

    if (prevProps.addressList !== this.props.addressList) {
      this.setState({orderAddressList: this.props.addressList});
    }

    if (prevProps.orderDetails.id !== this.props.orderDetails.id) {
      this.props.orderWizardActions.getOrderWizardAddressList(this.props.orderDetails.id);
    }

    this.checkRequiredInformationFilled();
  };

  checkRequiredInformationFilled = () => {
    let requiredInformationFilled = this.isRequiredInformationFilled();
    this.props.stepsActions.setAvailableToGoForward(requiredInformationFilled);
  };

  isRequiredInformationFilled = () => {
    let {orderDetails, orderAddressList} = this.state;

    const orderDetailsPropertiesToSkip = [
      'id',
      'firstName',
      'namePrefix',
      'infoUsage',
      'newsletter',
      'commercialOffer',
      'contactPersonFirstName',
      'contactPersonNamePrefix',
      'undefined',
      'created_at',
      'updated_at'
    ];
    const orderAddressPropertiesToSkip = [
      'id',
      'houseNumberExtension',
      'created_at',
      'updated_at'
    ];

    let orderDetailsRequiredInformationFilled = true;
    for (const [property, propertyValue] of Object.entries(orderDetails)) {
      if (!orderDetailsPropertiesToSkip.includes(property) && !propertyValue) {
        orderDetailsRequiredInformationFilled = false;
        break;
      }
    }

    let orderAddressListRequiredInformationFilled = true;
    for (const address of orderAddressList) {
      for (const [property, propertyValue] of Object.entries(address)) {
        if (!orderAddressPropertiesToSkip.includes(property) && !propertyValue) {
          orderAddressListRequiredInformationFilled = false;
          break;
        }
      }
    }

    return orderDetailsRequiredInformationFilled && orderAddressListRequiredInformationFilled;
  };

  handleChange = ({target}) => {
    let {name, value, type} = target;

    let updatedOrderDetails = this.state.orderDetails;

    if (value === 'Maak een keuze a.u.b') {
      value = null;
    }

    updatedOrderDetails[name] = value;

    this.setState({orderDetails: updatedOrderDetails});

    if (type === 'select-one') {
      this.saveOrderDetails();
    }
  };

  saveOrderDetails = () => {
    this.props.orderWizardActions.updateOrderWizardDetails(this.state.orderDetails);
  };

  handleChangeBirthDate = (date) => {
    this.handleChangeDate('birthDate', date);
  };

  handleChangeContactPersonBirthDate = (date) => {
    this.handleChangeDate('contactPersonBirthDate', date);
  };

  handleChangePrimaryIDValidityDate = (date) => {
    this.handleChangeDate('primaryIDValidityDate', date);
  };

  handleChangeDate = (name, value) => {
    let updatedOrderDetails = this.state.orderDetails;
    updatedOrderDetails[name] = value.toISOString();

    this.setState({orderDetails: updatedOrderDetails});
    this.saveOrderDetails();
  };

  handleChangeAddress = ({target}) => {
    let {name, value} = target;

    let {orderAddressList, selectedAddressID} = this.state;
    let updatedAddressList = [...orderAddressList];

    let selectedAddressIndex = orderAddressList.findIndex((address) => address.id === selectedAddressID);
    if (selectedAddressIndex < 0) {
      return;
    }

    updatedAddressList[selectedAddressIndex][name] = value;
    this.setState({orderAddressList: updatedAddressList});
  };

  handleCheckboxChange = ({target: {name, checked}}) => {
    let updatedOrderDetails = this.state.orderDetails;
    updatedOrderDetails[name] = checked;

    this.setState({orderDetails: updatedOrderDetails});
    this.saveOrderDetails();
  };


  renderPersonaDetails = () => {
    let {orderDetails, wizardOptions} = this.state;

    return (
      <div id="Forms" className="paddingbottomnone">
        <div className="row without-margin-bottom">
          <div className="col s3">
            <h1 className={'ratio-section-title'}>
              <i className="small material-icons left">
                person
              </i>
              <span>Persoonlijke gegevens</span>
            </h1>
          </div>

          <div className="col s12">
            <div className="row">
              <h1 className={'ratio-section-title'}>
                <i className="small material-icons left">
                  perm_identity
                </i>
                <span>Contracteigenaar</span>
              </h1>
            </div>
          </div>

          <div className="col s12">
            <div className="row">
              <Input
                  type="text"
                  name="nameInitials"
                  label="Naam initialen*"
                  style={{marginBottom: '20px'}}
                  onChange={this.handleChange}
                  onBlur={this.saveOrderDetails}
                  value={orderDetails.nameInitials || ''}
                  className={"validate " + (orderDetails.nameInitials?.length ? 'valid' : 'invalid')}
              />
              <Input
                  type="text"
                  name="lastName"
                  label="Achternaam*"
                  style={{marginBottom: '20px'}}
                  onChange={this.handleChange}
                  onBlur={this.saveOrderDetails}
                  value={orderDetails.lastName || ''}
                  className={"validate " + (orderDetails.lastName?.length ? 'valid' : 'invalid')}
              />
              <Input
                  type="text"
                  name="firstName"
                  label="Voornaam"
                  style={{marginBottom: '20px'}}
                  onChange={this.handleChange}
                  onBlur={this.saveOrderDetails}
                  value={orderDetails.firstName || ''}
              />
              <Input
                  type="text"
                  name="namePrefix"
                  label="Voorvoegsel naam"
                  style={{marginBottom: '20px'}}
                  onChange={this.handleChange}
                  onBlur={this.saveOrderDetails}
                  value={orderDetails.namePrefix || ''}
              />
            </div>
            <div className="row">
              <Input
                  type="select"
                  name="gender"
                  label="Geslacht*"
                  style={{marginBottom: '20px'}}
                  onChange={this.handleChange}
                  value={orderDetails.gender}
                  className={"validate " + (orderDetails.gender?.length ? 'valid' : 'invalid')}
              >
                <option value={null} key="gender-no-selection">Maak een keuze a.u.b</option>
                {wizardOptions.genderOptions.map((gender) => (
                    <option value={gender} key={gender}>{gendersTranslation.get(gender)}</option>
                ))}
              </Input>

              <div className="col input-field">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateField
                      label="Geboortedatum*"
                      format="DD-MM-YYYY"
                      name="birthDate"
                      value={orderDetails.birthDate ? dayjs(orderDetails.birthDate) : ''}
                      style={{marginBottom: 0}}
                      onChange={this.handleChangeBirthDate}
                  />
                </LocalizationProvider>
              </div>

              <Input
                  type="text"
                  name="contactPersonEmail"
                  label="Contact person email*"
                  style={{marginBottom: '20px'}}
                  onChange={this.handleChange}
                  onBlur={this.saveOrderDetails}
                  value={orderDetails.contactPersonEmail || ''}
                  className={"validate " + (orderDetails.contactPersonEmail?.length ? 'valid' : 'invalid')}
              />
              <Input
                  type="text"
                  name="contactPersonPhoneNumber"
                  label="Contact person telefoonnummer*"
                  style={{marginBottom: '20px'}}
                  onChange={this.handleChange}
                  onBlur={this.saveOrderDetails}
                  value={orderDetails.contactPersonPhoneNumber || ''}
                  className={"validate " + (orderDetails.contactPersonPhoneNumber?.length ? 'valid' : 'invalid')}
              />
            </div>
          </div>
          <div className="col s12">
            <div className="row">
              <Input
                  type="select"
                  name="primaryIDDocument"
                  label="Primair ID-document*"
                  style={{marginBottom: '20px'}}
                  onChange={this.handleChange}
                  value={orderDetails.primaryIDDocument}
                  className={"validate " + (orderDetails.primaryIDDocument ? 'valid' : 'invalid')}
              >
                <option value={null} key="primaryID-no-selection">Maak een keuze a.u.b</option>
                {wizardOptions.identificationOptions.map((id) => (
                    <option value={id} key={id}>{primaryIDTranslation.get(id)}</option>
                ))}
              </Input>
              <Input
                  type="select"
                  name="primaryIDNationality"
                  label="Primaire ID Nationaliteit*"
                  style={{marginBottom: '20px'}}
                  onChange={this.handleChange}
                  value={orderDetails.primaryIDNationality}
                  className={"validate " + (orderDetails.primaryIDNationality?.length ? 'valid' : 'invalid')}
              >
                <option value={null} key="primaryID-nationality-no-selection">Maak een keuze a.u.b</option>
                {countries.map((country) => (
                    <option value={countryCodes[country]} key={countryCodes[country]}>{country}</option>
                ))}
              </Input>
              <Input
                  type="text"
                  name="primaryIDNumber"
                  label="Primair ID-nummer*"
                  style={{marginBottom: '20px'}}
                  onChange={this.handleChange}
                  onBlur={this.saveOrderDetails}
                  value={orderDetails.primaryIDNumber || ''}
                  className={"validate " + (orderDetails.primaryIDNumber?.length ? 'valid' : 'invalid')}
              />
              <div className="col input-field">
                <DatePicker
                    dateFormat="dd-MM-yyyy"
                    minDate={new Date()}
                    style={{marginBottom: '20px'}}
                    name="primaryIDValidityDate"
                    onChange={this.handleChangePrimaryIDValidityDate}
                    onChangeRaw={e => e.preventDefault()}
                    selected={orderDetails.primaryIDValidityDate ? Date.parse(orderDetails.primaryIDValidityDate) : ''}
                    className={"validate " + (orderDetails.primaryIDValidityDate?.length ? 'valid' : 'invalid')}
                />
                <label>Primaire ID Geldigheidsdatum*</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderCustomerInformation = () => {
    let {orderDetails, wizardOptions} = this.state;

    return (
        <div id="Forms" className="paddingbottomnone">
          <div className="row without-margin-bottom">
            <div className="col s3">
              <h1 className={'ratio-section-title'}>
                <i className="small material-icons left">
                  payment
                </i>
                <span>Klant informatie</span>
              </h1>
            </div>

            <div className="col s12">
              <div className="row">
                <Input
                    type="text"
                    name="bankAccountNumber"
                    label="Bankrekeningnummer"
                    style={{marginBottom: '20px'}}
                    onChange={this.handleChange}
                    onBlur={this.saveOrderDetails}
                    value={orderDetails.bankAccountNumber || ''}
                    className={"validate " + (orderDetails.bankAccountNumber?.length ? 'valid' : 'invalid')}
                />
                <Input
                    type="select"
                    name="paymentMethod"
                    label="Betalingsmiddel"
                    style={{marginBottom: '20px'}}
                    onChange={this.handleChange}
                    value={orderDetails.paymentMethod}
                    className={"validate " + (orderDetails.paymentMethod?.length ? 'valid' : 'invalid')}
                >
                  <option value={null} key="payment-method-no-selection">Maak een keuze a.u.b</option>
                  {wizardOptions.paymentMethodOptions.map((paymentMethod) => (
                      <option value={paymentMethod}
                              key={paymentMethod}>{paymentMethod === 'Direct Debit' ? 'Automatische Incasso' : paymentMethod}</option>
                  ))}
                </Input>
                <Input
                    type="select"
                    name="paymentTerm"
                    label="Betalingstermijn"
                    style={{marginBottom: '20px'}}
                    onChange={this.handleChange}
                    value={orderDetails.paymentTerm}
                    className={"validate " + (orderDetails.paymentTerm?.length ? 'valid' : 'invalid')}
                >
                  <option value={null} key="payment-term-no-selection">Maak een keuze a.u.b</option>
                  {wizardOptions.paymentTermOptions.map((paymentTerm) => (
                      <option value={paymentTerm} key={paymentTerm}>{paymentTerm}</option>
                  ))}
                </Input>
                <Input
                    type="select"
                    name="customerType"
                    label="Klant type"
                    style={{marginBottom: '20px'}}
                    onChange={this.handleChange}
                    value={orderDetails.customerType}
                    className={"validate " + (orderDetails.customerType?.length ? 'valid' : 'invalid')}
                >
                  <option value={null} key="customer-type-no-selection">Maak een keuze a.u.b</option>
                  {wizardOptions.customerTypeOptions.map((customerType) => (
                      <option value={customerType} key={customerType}>{customerType}</option>
                  ))}
                </Input>
              </div>
            </div>

            <div className="col s12">
              <div className="row">
                <Input
                    key="order-details-commercial-offer"
                    name='commercialOffer'
                    type='checkbox'
                    checked={orderDetails.commercialOffer}
                    label="Commercieel aanbod"
                    onChange={this.handleCheckboxChange}
                />
                <Input
                    key="order-details-info-usage"
                    name='infoUsage'
                    type='checkbox'
                    checked={orderDetails.infoUsage}
                    label="Gebruik"
                    onChange={this.handleCheckboxChange}
                />
                <Input
                    key="order-details-newsletter"
                    name='newsletter'
                    type='checkbox'
                    checked={orderDetails.newsletter}
                    label="Nieuwsbrief"
                    onChange={this.handleCheckboxChange}
                />
              </div>
            </div>
          </div>
        </div>
    );
  };

  renderAddressList = () => {
    let {orderAddressList, wizardOptions} = this.state;
    let maxAddressAllowed = wizardOptions.addressRoleOptions.length;
    let canAddNewAddress = orderAddressList.length < maxAddressAllowed;
    let iconClass = "small material-icons right themeprimarycolor";

    return (
      <div id="Forms" className="paddingbottomnone">
        <div className="row without-margin-bottom">
          <div className="col s3">
            <h1 className={'ratio-section-title'}>
              <i className="small material-icons left">
                location_on
              </i>
              <span>Adressen</span>
            </h1>
          </div>
          <div className="col s9">
            {canAddNewAddress && (
              <button
                onClick={() => this.openAddressDialog('add', -1)}
                type="submit"
                name="action"
                className="btn doceri-btn-right ratio-btn-white-right"
                style={{marginTop: '20px'}}
              >
                <i className="small material-icons left">add_location</i>
                Address toevoegen
              </button>
            )}
          </div>
        </div>


        {
          orderAddressList.filter((address) => address.id > 0).map((address) => {
            return (
              <div className="row">
                <div className="col s3 overviewkopbgalleen">
                  <div className="overviewkop">
                <span className="themeprimarycolor">
                    <i className="small material-icons left themeprimarycolor">location_on</i>
                  {addressTranslation.get(address.role)}
                </span>
                    <a
                      href="#"
                      onClick={() => this.openAddressDialog('edit', address.id)}
                      style={{cursor: 'pointer'}}
                    >
                      <i className={iconClass}>mode_edit</i>
                    </a>

                    {address.role !== 'MAIN' && (
                      <a
                        href="#"
                        onClick={() => this.removeAddress(address.id)}
                        style={{cursor: 'pointer'}}
                      >
                        <i className={iconClass}>close</i>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };

  removeAddress = (addressID) => {
    this.props.orderWizardActions.removeOrderWizardAddress(addressID);
  };

  renderAddressForm = () => {
    let {wizardOptions, orderAddressList, selectedAddressID} = this.state;

    let selectedAddress = selectedAddressID ? orderAddressList.find((address) => address.id === selectedAddressID) : newAddress;
    let usedAddressRoles = orderAddressList.map((address) => address.role);
    let availableAddressRoles = wizardOptions.addressRoleOptions.filter((role) => !usedAddressRoles.includes(role) || role === selectedAddress.role);
    let shouldBeDisabledForMain = selectedAddress.role === 'MAIN';

    return (
      <div className="row">
        <div className="col s12">
          <Input
            type="select"
            name="role"
            label="Adres rol"
            onChange={this.handleChangeAddress}
            value={selectedAddress.role}
            className={"nomarginbottom validate " + (selectedAddress.role?.length ? 'valid' : 'invalid')}
          >
            <option value={null} key="address-role-no-selection">Maak een keuze a.u.b</option>
            {availableAddressRoles.map((addressRole) => (
              <option value={addressRole} key={`${selectedAddressID}-${addressRole}`}>{addressRole}</option>
            ))}
          </Input>
          <Input
            type="select"
            name="contactType"
            label="Contact Type"
            onChange={this.handleChangeAddress}
            value={selectedAddress.contactType}
            className={"nomarginbottom validate " + (selectedAddress.contactType?.length ? 'valid' : 'invalid')}
          >
            <option value={null} key="address-contact-type-no-selection">Maak een keuze a.u.b</option>
            {wizardOptions.addressContactOptions.map((contactOption) => (
              <option value={contactOption} key={`${selectedAddressID}-${contactOption}`}>{contactOption}</option>
            ))}
          </Input>
        </div>

        <div className="col s12">
          <Input
            type="select"
            name="country"
            label="Land"
            onChange={this.handleChangeAddress}
            value={selectedAddress.country}
            className={"nomarginbottom validate " + (selectedAddress.country?.length ? 'valid' : 'invalid')}
          >
            <option value={null} key="address-main-country-no-selection">Maak een keuze a.u.b</option>
            {countries.map((country) => (
              <option value={countryCodes[country]} key={countryCodes[country]}>{country}</option>
            ))}
          </Input>
        </div>

        <div className="col s12">
          <div className="input-field col inner-addon">
            <DelayInput
              id={`${selectedAddressID}-city`}
              type="text"
              name="city"
              delayTimeout={1000}
              style={{marginBottom: '20px'}}
              onChange={this.handleChangeAddress}
              value={selectedAddress.city || ''}
              className={"validate " + (selectedAddress.city?.length ? 'valid' : 'invalid')}
            />
            <label htmlFor={`${selectedAddressID}-city`}>Stad</label>
          </div>
          <div className="input-field col inner-addon">
            <DelayInput
              id={`${selectedAddressID}-street`}
              type="text"
              name="street"
              delayTimeout={1000}
              style={{marginBottom: '20px'}}
              onChange={this.handleChangeAddress}
              value={selectedAddress.street || ''}
              className={"validate " + (selectedAddress.street?.length ? 'valid' : 'invalid')}
            />
            <label htmlFor={`${selectedAddressID}-street`}>Straat</label>
          </div>
        </div>

        <div className="col s12">
          <div className="input-field col inner-addon">
            <DelayInput
              id={`${selectedAddressID}-postalCode`}
              type="text"
              name="postalCode"
              delayTimeout={1000}
              style={{marginBottom: '20px'}}
              onChange={this.handleChangeAddress}
              value={selectedAddress.postalCode || ''}
              className={"validate " + (selectedAddress.postalCode?.length ? 'valid' : 'invalid')}
            />
            <label htmlFor={`${selectedAddressID}-postalCode`}>Postcode</label>
          </div>
          <div className="input-field col inner-addon">
            <DelayInput
              id={`${selectedAddressID}-houseNumber`}
              type="text"
              name="houseNumber"
              delayTimeout={1000}
              style={{marginBottom: '20px'}}
              onChange={this.handleChangeAddress}
              value={selectedAddress.houseNumber || ''}
              className={"validate " + (selectedAddress.houseNumber?.length ? 'valid' : 'invalid')}
            />
            <label htmlFor={`${selectedAddressID}-houseNumber`}>Huisnummer</label>
          </div>
        </div>

        <div className="col s12">
          <div className="input-field col inner-addon">
            <DelayInput
              id={`${selectedAddressID}-houseNumberExtension`}
              type="text"
              name="houseNumberExtension"
              delayTimeout={1000}
              style={{marginBottom: '20px'}}
              onChange={this.handleChangeAddress}
              value={selectedAddress.houseNumberExtension || ''}
            />
            <label htmlFor={`${selectedAddressID}-houseNumberExtension`}>Huisnummer uitbreiding</label>
          </div>
        </div>

        <div className="col s12">
          <div className="input-field col inner-addon">
            <DelayInput
              id={`${selectedAddressID}-phoneNumber`}
              type="text"
              name="phoneNumber"
              delayTimeout={1000}
              style={{marginBottom: '20px'}}
              onChange={this.handleChangeAddress}
              value={selectedAddress.phoneNumber || ''}
              className={"validate " + (selectedAddress.phoneNumber?.length ? 'valid' : 'invalid')}
            />
            <label htmlFor={`${selectedAddressID}-phoneNumber`}>Telefoonnummer</label>
          </div>
        </div>
      </div>
    );
  };

  openAddressDialog = (dialogAction = null, addressID = null) => {
    if (dialogAction === 'add') {
      let addressList = this.state.orderAddressList;
      let newAddressIndex = addressList.findIndex((address) => address.id === -1);

      if (newAddressIndex < 0) {
        this.setState({orderAddressList: [...addressList, newAddress]});
      }
    }

    this.setState({addressDialogOpen: true, selectedAddressID: addressID, addressDialogAction: dialogAction});
  };

  closeAddressDialog = () => {
    this.setState({addressDialogOpen: false, selectedAddressID: null, addressDialogAction: null});
  };

  saveAddressData = () => {
    let {addressDialogAction, selectedAddressID, orderAddressList, orderDetails} = this.state;
    let selectedAddress = orderAddressList.find((address) => address.id === selectedAddressID);

    if (addressDialogAction === 'add') {
      this.props.orderWizardActions.addOrderWizardAddress(orderDetails.id, selectedAddress);
    }
    if (addressDialogAction === 'edit') {
      this.props.orderWizardActions.updateOrderWizardAddress(selectedAddressID, selectedAddress);
    }

    this.closeAddressDialog();
  };

  render() {
    const {theme} = this.props.authentication;
    let {wizardLoading, addressDialogOpen, addressDialogAction} = this.state;
    let addressHeaderText = '';
    if (addressDialogAction === 'add') {
      addressHeaderText = 'Besteladres toevoegen';
    }
    if (addressDialogAction === 'edit') {
      addressHeaderText = 'Besteladres bewerken';
    }

    return (
      <div>
        {wizardLoading ?
          <LoaderComponent theme={theme}/> :
          <React.Fragment>
            <GeneralDialog
              dialogState={addressDialogOpen}
              headerText={addressHeaderText}
              closeHandler={this.closeAddressDialog}
              dialogBodyContentRaw={(
                <DialogBody>
                  {this.renderAddressForm()}
                </DialogBody>
              )}
              leftButtonAction={this.closeAddressDialog}
              leftButtonText={'Annuleren'}
              leftButtonLeft={true}
              rightButtonAction={this.saveAddressData}
              rightButtonText={'Opslaan'}
            />

            {this.renderPersonaDetails()}
            {this.renderCustomerInformation()}
            {this.renderAddressList()}
          </React.Fragment>
        }
      </div>
    );
  }
}

const mapStateToProps = ({quotation, orderWizard, authentication}) => {
  return {
    quotation: quotation.currentQuotation,
    orderDetails: orderWizard.orderDetails,
    addressList: orderWizard.addressList,
    wizardOptions: orderWizard.wizardOptions,
    authentication: authentication,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    stepsActions: bindActionCreators(stepsActions, dispatch),
    orderWizardActions: bindActionCreators(orderWizardActions, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderWizardStep));
