import React, {Component} from 'react';
import TagsManagementGrid from "./TagsManagementGrid";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Pagination from "../../../components/pagination/pagination";
import {DelayInput} from "react-delay-input";
import tagActions from "../../../actions/tagActions";
import DialogHeader from "../../common/KpnDialog/DialogHeader";
import DialogBody from "../../common/KpnDialog/DialogBody";
import KpnDialog from "../../common/KpnDialog/KpnDialog";
import DialogFooter from "../../common/KpnDialog/DialogFooter";
import DialogButton from "../../common/KpnDialog/DialogButton";
import {Input} from "react-materialize";
import adminActions from "../../../actions/adminActions";

class TagsManagementPanel extends Component {
  sortInitialState = {
    name: false,
    createdAt: false,
    updatedAt: false,
    category: false
  };

  constructor(props) {
    super(props);

    this.state = {
      sorting: {
        ...this.sortInitialState
      },
      page: 1,
      searchPhrase: '',
      dialogOpened: false,
      selectedTag: {}
    };
  }

  componentDidMount() {
    this.getAllTags();
    this.props.actions.getCategoriesToLink();
  };

  getAllTags = (sortField, direction, page, searchQuery) => {
    let queryParams = '';
    if (sortField && direction) {
      queryParams += '?sortField=' + sortField;
      queryParams += '&sortDirection=' + direction;
    }

    if (page) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?')
      queryParams += 'page=' + page
    }
    if (searchQuery) {
      queryParams += (queryParams.match(/\?/) ? '&' : '?')
      queryParams += 'searchQuery=' + searchQuery;
    }

    this.props.actions.getAllTags(queryParams);
  };

  changePage = ({target}) => {
    const {value} = target;
    this.setState({page: value});

    const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false);
    const sortValue = this.state.sorting[sortField];

    this.getAllTags(sortField, sortValue, value, this.state.searchPhrase);
  };

  onSearch = ({target}) => {
    let {name, value} = target;

    const sortField = this.tableObjectProperties.find(x => this.state.sorting[x] !== false);
    const sortValue = this.state.sorting[sortField];

    this.setState({searchPhrase: value});

    this.getAllTags(sortField, sortValue, this.state.page, value)
  };

  onSortChange = ({target}) => {
    const headerName = target.getAttribute('headername');
    const propertyName = this.getHeaderPropertyForName(headerName);

    if (!propertyName) {
      return;
    }

    this.changeSortDirection(propertyName, this.getSortDirection(propertyName));
  };

  onEdit = (tag) => {
    this.setState({
        dialogOpened: true,
        isEdit: true,
        selectedTag: tag
    });
  };

  onAdd = () => {
    this.setState({
        dialogOpened: true,
        isEdit: false,
        selectedTag: {}
    })
  };

  getTagCategories = () => {
    this.props.adminActions.setCurrentSection('tag-categories-management');
  };

  closeDialog = () => {
    this.setState({
        dialogOpened: false
    });
  };

  submitDialog = () => {
      if (this.state.isEdit) {
          const {id, name, category, description} = this.state.selectedTag;
          this.props.actions.patchTag(id, {name, category, description}).then(() => {
              this.closeDialog();
          });
      } else {
          this.props.actions.addTag(this.state.selectedTag).then(() => {
              this.closeDialog();
              this.getAllTags();
          });
      }
  };

  onChange = ({target}) => {
    let {name, value} = target;

    this.setState(prevState => ({
        selectedTag: {
            ...prevState.selectedTag,
            [name]: value
        }
    }));
  };

  getSortDirection = (propertyName) => {
    const sortDirection = this.state.sorting[propertyName];
    switch (sortDirection) {
      case false:
        return "ASC";
      case "ASC":
        return "DESC";
      case "DESC":
        return false
    }
  };

  changeSortDirection = (propertyName, direction) => {
    this.setState({sorting: {...this.sortInitialState, [propertyName]: direction}});

    this.getAllTags(propertyName, direction, this.state.page, this.state.searchPhrase)
  };

  getHeaderPropertyForName = (displayName) => {
    const index = this.tableHeaderNames.indexOf(displayName);
    return this.tableObjectProperties[index];
  };

  tableHeaderNames = ['Categorie', 'Omschrijving', 'Tag', 'Aangemaak', 'Laatst gewijzigd', 'Laatst gewijzigd door', 'Acties'];
  tableObjectProperties = ['category', 'description', 'name', 'createdAt', 'updatedAt', 'updatedBy', ''];

  render() {
    const {allTags, categoriesToLink, currentPage, pages} = this.props.tags;

    const isEdit = this.state.isEdit;

    return (
      <div>
        <div id="Forms" className="ratiotable paddingbottomnone">
          <h1 className="admin-subheader-text">
            <span className="ratio-section-title">Tags management</span>
          </h1>
        </div>

        <div className="overviewkopbg ratiotable row">
          <div className="col s3">
            <div className="overviewkop">
              <form>
                <div className="input-field margingone inner-addon">
                  <i className="glyphicon tiny material-icons left lightgrey">search</i>
                  <DelayInput delayTimeout={800} id="search" type="search" name="searchPhrase"
                              value={this.state.searchPhrase} onChange={this.onSearch}/>
                </div>
              </form>
            </div>
          </div>
          <div className="col s9">
              <button
                  className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
                  onClick={() => this.onAdd()}
                  type="submit"
                  name="action">
                  <i className="small material-icons left white-text">add</i>
                  Nieuwe tag
              </button>
              <button
                  className="btn doceri-btn-right ratio-btn-right admin-subheader-button"
                  onClick={() => this.getTagCategories()}
                  type="submit"
                  name="action">
                  <i className="small material-icons left white-text">edit</i>
                  Beheer categorieën
              </button>
          </div>
        </div>

        <TagsManagementGrid
          rows={allTags}
          onHeaderClick={this.onSortChange}
          sortingState={this.state.sorting}
          getPropertyNameFromDisplayName={this.getHeaderPropertyForName}
          headerNames={this.tableHeaderNames}
          onEditClick={this.onEdit}
        />

        <KpnDialog
          dialogState={this.state.dialogOpened}
          modal={false}
          dialogHeader={(
              <DialogHeader
                  headerIcon={isEdit ? 'edit' : 'add'}
                  headerText={`${isEdit ? 'Edit' : 'Add'} tag`}
                  closeHandler={this.closeDialog}
              />
          )}
          dialogBody={
              <DialogBody>
                  <div className="row">
                      <Input
                          s={12}
                          value={this.state.selectedTag.name}
                          onChange={this.onChange}
                          label='Name'
                          name='name'
                          type='text'/>
                      <Input
                          s={12}
                          value={this.state.selectedTag.description}
                          onChange={this.onChange}
                          label='Description'
                          name='description'
                          type='text'/>
                      <Input
                          s={12}
                          name="category"
                          type="select"
                          label="Category"
                          value={this.state.selectedTag.category}
                          onChange={this.onChange}
                          multiple={false}
                          required
                          className="validate"
                      >
                          <option value="">Select category</option>
                          {categoriesToLink.map((el) => <option
                              value={el.id}
                              key={el.id}>{el.name}</option>)}
                      </Input>
                  </div>
              </DialogBody>
          }
          dialogFooter={
              <DialogFooter
                  buttonLeft={(
                      <DialogButton
                          buttonAction={this.closeDialog}
                          buttonText='Close'
                          left={true}
                      />
                  )}
                  buttonRight={(
                      <DialogButton
                          buttonAction={this.submitDialog}
                          buttonText='Submit'
                      />
                  )}
              />
          }
        />

        <div className="col s12 sendendform">
          <div className="col s12 m10">
            <Pagination page={currentPage}
                        pages={pages}
                        changePage={this.changePage}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({tags}) => {
  return {
    tags
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(tagActions, dispatch),
    adminActions: bindActionCreators(adminActions, dispatch)
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TagsManagementPanel));
