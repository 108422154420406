import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

const FileUpload = (props) => {
    const dropAreaCaption = 'Sleep uw bestand hierheen of klik om een bestand te selecteren.';
    return (
        <Dropzone className="bulkupload" onDrop={props.onDrop}>
            <div>
                <span>{props.caption || dropAreaCaption}</span>
                {props.droppedFile &&
                    <span style={{float: 'right'}}>
                        <b>{props.droppedFile.name}</b>
                    </span>
                }
            </div>
        </Dropzone>
    );
}

FileUpload.propTypes = {
    onDrop: PropTypes.func.isRequired,
    caption: PropTypes.string,
    droppedFile: PropTypes.any
};

export default FileUpload
