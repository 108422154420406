import {actionTypes} from '../constants/actionTypes';
import {apiRoutes} from '../constants/apiRoutes';
import {toastr} from 'react-redux-toastr'

import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

const uploadBulk = (file, providerId, options, noList) => {
    return async (dispatch) => {
        try {
            dispatch(toggleImportLoading());
            let url = apiRoutes.hardwareRelated.hardwareBulk.replace(/{providerId}/, providerId);
            url = url.replace(/{inactive}/, options.setToInactive);
            url = url.replace(/{copy}/, options.copyProduct);
            const response = await ApiService.performRequest(RequestType.POST, url, file);
            if (!noList) {
                dispatch(getAllHardwareProducts(providerId));
            } else {
                if (!response.success) {
                    toastr.error('Bulk upload error', '');
                }
                return response;
            }
        }
        catch (error) {
            return {error}
        } finally {
          dispatch(toggleImportLoading());
        }
    }
};

const toggleImportLoading = () => ({
    type: actionTypes.hardwareToggleImportLoading
});

const getAllHardwareProducts = (providerId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.hardwareRelated.getAllHardware.replace(/{providerId}/, providerId);
      let response = await ApiService.performRequest(RequestType.GET, url);
      dispatch(setHardwareProducts(response));
    } catch (error) {
      return {error};
    }
  }
};

const removeHardware = (providerId) => {
    return async (dispatch) => {
      try {
        let url = apiRoutes.hardwareRelated.removeHardware;
        let response = await  ApiService.performRequest(RequestType.DELETE, url);
        dispatch(getAllHardwareProducts(providerId));
      } catch (error) {
        return {error};
      }
    }
};

const setHardwareProducts = ({accessProducts, ...allHardwareOptions}) => {
  return {
    type: actionTypes.setHardwareProducts,
    payload: {
        allProducts: accessProducts,
        allHardwareOptions
    }
  }
};

const getExampleFile = () => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.hardwareRelated.getImportXls;
            let requestData = await ApiService.performRequest(RequestType.GETFILE, url);

            dispatch(_setExampleFile(requestData));
        }
        catch ({message}) {
            toastr.error('Kan het voorbeeldbestand niet downloaden', message);
        }
    }
};

const getAssortimentFile = (providerId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.hardwareRelated.getAssortimentXls.replace('{providerId}', providerId);
            let requestData = await ApiService.performRequest(RequestType.GETFILE, url);

            dispatch(_setAssortimentFile(requestData));
        }
        catch ({message}) {
            toastr.error('Kan het voorbeeldbestand niet downloaden', message);
        }
    }
};

const _setExampleFile = (data) => {
  return {
    type: actionTypes.hardwareDownloadExampleFile,
    payload: data
  };
};

const _setAssortimentFile = (data) => {
  return {
    type: actionTypes.hardwareDownloadAssortimentFile,
    payload: data
  };
};

const setCustomHardwareOptions = (type, options) => {
  return {
    type: actionTypes.setCustomHardwareOptions,
    payload: {
        key: type,
        value: options
    }
  }
};

const setCustomHardwareSelections = (type, selections) => {
  return {
    type: actionTypes.setCustomHardwareSelections,
    payload: {
        [type]: selections
    }
  }
};

const getCustomHardwareOptions = (type, id) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.hardwareRelated.getCustomHardwareOptions
          .replace(/{type}/, type)
          .replace(/{id}/, id)
      ;
      let response = await ApiService.performRequest(RequestType.GET, url);
      dispatch(setCustomHardwareOptions(type, response));
    } catch (error) {
      return {error};
    }
  }
};

const getCustomHardwareOptionsSelections = (quotationId, type, locationId = null) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.hardwareRelated.getCustomHardwareOptionsSelections
        .replace(/{quotationId}/, quotationId)
        .replace(/{locationId}/, locationId)
        .replace(/{type}/, type);
      let response = await ApiService.performRequest(RequestType.GET, url);

      const selections = [];
      response.options.map(selection => {
        selections.push({
            id: selection.id,
            quantity: selection.quantity,
            connectionId: selection.connectionId,
            locationId: selection.locationId,
            category: selection.category
        });
      });

      dispatch(setCustomHardwareSelections(type,selections));

    } catch (error) {
      return {error};
    }
  }
};

const getAllCustomHardwareOptionsSelections = (quotationId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.hardwareRelated.getAllCustomHardwareOptionsSelections.replace(/{quotationId}/, quotationId);
      let response = await ApiService.performRequest(RequestType.GET, url);

      let selections = [];
      let {options} = response;
      Object.keys(options).forEach((type) => {
        selections[type] = [];
        options[type].forEach((selection) => {
          selections[type].push({
            id: selection.id,
            quantity: selection.quantity,
            connectionId: selection.connectionId,
            locationId: selection.locationId,
            category: selection.category
          });
        });
      });

      Object.keys(selections).forEach((type) => {
        dispatch(setCustomHardwareSelections(type,selections[type]));
      });
    } catch (error) {
      return {error};
    }
  }
};

const persistCustomHardwareSelections = (selections, quotationId, idsProductsToDelete, type = null, locationId = null) => {
  return async () => {
    try {
      let url = apiRoutes.hardwareRelated.persistCustomHardwareSelections.replace(/{type}/, type)
          .replace(/{locationId}/, locationId);
      await ApiService.performRequest(RequestType.POST, url, {selections, quotationId, idsProductsToDelete});
    } catch (error) {
      return {error};
    }
  }
};

const addHardwareOption = (data, providerId, noList) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.hardwareRelated.addHardwareOption.replace(/{providerId}/, providerId);
            let response = await  ApiService.performRequest(RequestType.POST, url, data);

            if (!noList) {
                dispatch(addHardwareOptionSuccess(response));
                dispatch(getAllHardwareProducts(providerId));
            }
        } catch ({message}) {
            toastr.error('Kan de nieuwe partnerservice niet toevoegen: ', message);
        }
    }
};

const addHardwareOptionSuccess = (data) => {
    return {
        type: actionTypes.addHardwareOptionSuccess,
        payload: data
    };
};

const removeHardwareOption = (hardwareId, providerId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.hardwareRelated.removeHardwareOption.replace('{hardwareId}', hardwareId);
            let response = await ApiService.performRequest(RequestType.DELETE, url);

            dispatch(getAllHardwareProducts(providerId));
        } catch (error) {
            return {error};
        }
    }
};

const updateHardwareOption = (hardwareId, data, providerId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.hardwareRelated.updateHardwareOption.replace(/{hardwareId}/, hardwareId);
            let requestData = await ApiService.performRequest(RequestType.POST, url, data);

            dispatch(getAllHardwareProducts(providerId));
        } catch ({message}) {
            toastr.error('Kan de hardware optie niet bijwerken ', message);
        }
    }
};

const getProductsToLink = (providerId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.hardwareRelated.getAllProductsToLink.replace(/{providerId}/, providerId);
            let response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(setProductsToLink(response));
        } catch (error) {
            return {error};
        }
    }
};

const setProductsToLink = (products) => {
    return {
        type: actionTypes.setHardwareProductsToLink,
        payload: products
    }
};

const getHardwareOptionCategories = () => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.hardwareRelated.getHardwareOptionCategories;
            let response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(setHardwareOptionCategories(response));
        } catch (error) {
            return {error};
        }
    }
};

const setHardwareOptionCategories = (products) => {
    return {
        type: actionTypes.setHardwareOptionCategories,
        payload: products
    }
};

const retrieveExistingVoiceData = (quotationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.hardwareRelated.retrieveExistingVoiceData.replace(/{quotationId}/, quotationId);
      const response = await ApiService.performRequest(RequestType.GET, url);

      if (response) {
        dispatch(retrieveExistingVoiceDataSuccess(response));
      }
    } catch (message) {
      toastr.error('Kan bestaande gegevens niet ophalen omdat ', message);
    }
  }
};

const retrieveExistingVoiceDataSuccess = (result) => {
  return {
    type: actionTypes.getExistingVoiceData,
    payload: result
  }
};

const retrieveExistingTelephoneExchange = (quotationId) => {
  return async () => {
    try {
      const url = apiRoutes.hardwareRelated.retrieveExistingTelephoneExchange.replace(/{quotationId}/, quotationId);
      return await ApiService.performRequest(RequestType.GET, url);
    } catch (message) {
      toastr.error('Kan bestaande gegevens niet ophalen omdat ', message);
    }
  }
};

const updateTelephoneExchangeOrderId = (quotationId, data) => {
  return async () => {
    try {
      let url = apiRoutes.hardwareRelated.updateExistingTelephoneExchangeOrderId.replace(/{quotationId}/, quotationId);
      return await ApiService.performRequest(RequestType.POST, url, data);
    } catch ({message}) {
      toastr.error('Kan de hardware optie niet bijwerken ', message);
    }
  }
};

export default {
    uploadBulk,
    getExampleFile,
    getAssortimentFile,
    getAllHardwareProducts,
    removeHardware,
    setHardwareProducts,
    getCustomHardwareOptions,
    setCustomHardwareOptions,
    setCustomHardwareSelections,
    getCustomHardwareOptionsSelections,
    getAllCustomHardwareOptionsSelections,
    persistCustomHardwareSelections,
    addHardwareOption,
    removeHardwareOption,
    updateHardwareOption,
    getProductsToLink,
    getHardwareOptionCategories,
    retrieveExistingVoiceData,
    retrieveExistingTelephoneExchange,
    updateTelephoneExchangeOrderId,
};
