import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useState} from "react";
import DropdownStyle from "./DropdownStyle";

const DoceriDropDownMenu = ( { menuItems, buttonLabel, buttonIcon, button, className, ...props } ) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const itemOnClick = (fn) => {
      fn();
      handleMenuClose();
    };

    const disableBtn = props.disabled;

    return (
        <div className="dropdown-container" style={{marginLeft: 20, marginTop: 20}}>
            <DropdownStyle/>

            <Button
                aria-controls={anchorEl ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? 'true' : undefined}
                onClick={(e) => handleMenuOpen(e)}
                className={`${className} menu-button ${anchorEl ? 'expanded-dropdown' : ''}`}
                disabled={disableBtn}
            >
                <i className="small material-icons left">
                   {buttonIcon}
                </i>
                { buttonLabel }
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                className="doceri-menu"
            >
                {
                    menuItems.map((item, index) => (
                        <MenuItem
                            button
                            key={index}
                            onClick={() => itemOnClick(item.onClick)}
                            className="menu-item"
                        >
                            {item.label}
                        </MenuItem>
                    ))
                }
            </Menu>
        </div>
    )
}

export default DoceriDropDownMenu;