import {
    actionTypes
} from '../constants/actionTypes';

const initialState = {
    hardware: [],
    products: {},
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.getHostedVoiceHardware:
            return {
                ...state,
                hardware: action.response,
            };
        case actionTypes.getVamoVoiceProductsCount:
            return {
                ...state,
                products: action.response.products,
            };
        default:
            return state;
    }
}
