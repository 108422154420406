import {
    actionTypes
} from '../constants/actionTypes';
import ObjectHelper from '../services/objectHelper';

const initialState = {
    searchPhrase: '',
    matchingLocations: [],
    allLocations: [],
    locationAddress: '',
    matchingAddresses: [],
    refusedPostcode: false,
    selectedLocation: {
        id: 0,
        name: '',
        quotation: 0,
        street: '',
        city: '',
        postal_code: '',
        house_number: '',
        house_number_extension: '',
        isra_street: '',
        isra_city: '',
        isra_postal_code: '',
        isra_house_number: '',
        isra_house_number_extension: '',
        isra_reference: '',
        bandWidthType: '',
        bandWidthDown: 0,
        bandWidthUp: 0,
        optionPin: false,
        optionAlarm: false,
        optionIpAddresses: 0,
        sdWanActive: null,
        voipPorts: 1,
        businessConnectionOptions: {
            enabled: false,
            p2gCount: 0,
            concurrentCalls: 0,
            unlimitedCount: 0,
            sipTrunks: 0,
            countISDN: 0,
            countPSTN: 0
        },
        locationSdWanOptions: {
            sdWanActive: false,
            type: '',
            product: null,
            ipVpn: false,
            internetAccess: false,
            serviceLevelType: 0,
            businessAnywhere: false,
            businessAnywhereAmount: 0,
            sipTrunk: false,
            sipTrunkAmount: 0,
            sdWanSipTrunks: []
        },
        sip_trunks: [],
        connections: [],
        serviceErrors: {},
        israDialogData: {},
    },
    israCheckInProgressLocationIds: [],
    editionDialogOpened: false,
    israDialogOpened: false,
    israLoading: false,
    exampleFile: '',
    assortimentFile: '',
    locationCallChargeType: null,
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.setCustomerLocations:
            return {
                ...state,
                allLocations: action.payload
            };
        case actionTypes.downloadExampleFile:
            return {
              ...state,
              exampleFile: action.payload
            };
        case actionTypes.setSelectedLocation:

            let location = action.payload;
            if (location) {
              // this is needed for adding a new location from address search
              location.postal_code = location.postalCode || location.postal_code;
              location.house_number = location.houseNumber || location.house_number;
              location.house_number_extension = location.houseNumberExtension || location.house_number_extension;
            }

            if (action.merge === true) {
                location = Object.assign({}, state.selectedLocation, action.payload);
            }

            return {
                ...state,
                selectedLocation: location
            };
        case actionTypes.updateSelectedLocation:
            return {
                ...state,
                selectedLocation: {
                    ...state.selectedLocation,
                    [action.payload.propertyName]: action.payload.value
                }
            };
        case actionTypes.setLocationsAsInvalid:
            const allLocations = [...state.allLocations];

            action.payload.locationsIds.forEach((idRow) => {
                const invalidLocationIndex = allLocations.findIndex(row => row.id === idRow);
                allLocations[invalidLocationIndex].invalid = true;
            });

            return {
                ...state,
                allLocations,
            };
        case actionTypes.updateExistingLocationInDb:
            return {
                ...state,
                allLocations: state.allLocations.map((location, i) => {
                    if (location.id === action.payload.id){
                        return Object.assign(location, action.payload);
                    }
                    return location
                })
            };
        case actionTypes.addNewLocationInDb:
            return {
                ...state,
                allLocations: [...state.allLocations, action.payload]
            };
        case actionTypes.resetSelectedLocation:
            return {
                ...state,
                selectedLocation: initialState.selectedLocation
            };
        case actionTypes.removeLocation:
            return {
                ...state,
                allLocations: state.allLocations.filter(item => item.id !== action.payload)
            };
        case actionTypes.uploadBulk:
            return {
                ...state,
                allLocations: [...state.allLocations, ...action.payload]
            };
        case actionTypes.setAddresses:
            return {
                ...state,
                matchingAddresses: action.payload
            };
        case actionTypes.setLocationAddress:
              return {
                ...state,
                locationAddress: action.payload
            };
        case actionTypes.isRefusedPostcode:
            return {
                ...state,
                refusedPostcode: action.payload
            };
        case actionTypes.closeEditionDialog:
            return {
                ...state,
                editionDialogOpened: false
            };
        case actionTypes.openEditionDialog:
            return {
                ...state,
                editionDialogOpened: true
            };
        case actionTypes.openIsraDialog:
            return {
                ...state,
                israDialogOpened: true
            };
        case actionTypes.closeIsraDialog:
            return {
                ...state,
                israDialogOpened: false
            };
        case actionTypes.toggleIsraLoading:
            return {
                ...state,
                israLoading: !state.israLoading
            };
        case actionTypes.setIsraDialogData:
            return {
                ...state,
                selectedLocation: {
                    ...state.selectedLocation,
                    israDialogData: action.payload
                }
            };
        case actionTypes.setIsraServiceErrors:
            return {
                ...state,
                selectedLocation: {
                    ...state.selectedLocation,
                    serviceErrors: action.payload
                }
            };
        case actionTypes.searchLocationsList:
            return {
                ...state,
                searchPhrase: action.payload
            };
        case actionTypes.setLocationAddressToEmptyString:
            return {
                ...state,
                locationAddress: ''
            };
        case actionTypes.changeSortingOfLocations:
            return {
                ...state,
                allLocations: ObjectHelper.reversLocationsArrayApartFromFirstElement(state.allLocations)
            };
        case actionTypes.fillGlobalLocationProperties:
            return {
                ...state,
                allLocations: state.allLocations.map(x => x.id === -1 ? ObjectHelper.fillGlobalLocationProperties(x, action.payload) : x)
            };
        case actionTypes.updateBusinessConnectionOptions:
            return {
                ...state,
                allLocations: state.allLocations.map((x) => {
                    if(x.id === action.payload.locationId){
                        x.businessConnectionOptions[action.payload.name] = action.payload.value;
                    }
                    return x
                }),
                selectedLocation: {
                    ...state.selectedLocation,
                    businessConnectionOptions: {
                        ...state.selectedLocation.businessConnectionOptions,
                        [action.payload.name]: action.payload.value
                    }
                }
            };
        case actionTypes.updateLocationCallChargeTypeSuccess:
            return {
                ...state,
                selectedLocation: {
                    ...state.selectedLocation,
                    locationCallChargeType: action.payload,
                }
            };
        case actionTypes.updateCallChargeState:
            return {
                ...state,
                allLocations: state.allLocations.map((x) => {
                    if(x.id === action.payload.locationId){
                        x.businessConnectionOptions.p2gCount = action.payload.p2gCount;
                        x.businessConnectionOptions.unlimitedCount = action.payload.unlimitedCount;

                    }
                    return x
                }),
                selectedLocation: {
                    ...state.selectedLocation,
                    businessConnectionOptions: {
                        ...state.selectedLocation.businessConnectionOptions,
                        p2gCount: action.payload.p2gCount,
                        unlimitedCount: action.payload.unlimitedCount
                    }
                }
            };
        case actionTypes.createSdLanStackSuccess:
            return {
                ...state,
                allLocations: state.allLocations.map((x) => {
                    if(x.id === action.payload.locationId){
                        return {
                            ...x,
                            sdLanLocationOptions: {
                                ...x.sdLanLocationOptions,
                                stacks: [...x.sdLanLocationOptions.stacks, action.payload.sdLanStack]
                            }
                        };
                    }
                    return x;
                }),
                selectedLocation: {
                    ...state.selectedLocation,
                    sdLanLocationOptions: {
                        ...state.selectedLocation.sdLanLocationOptions,
                        stacks: [
                            ...state.selectedLocation.sdLanLocationOptions.stacks, action.payload.sdLanStack
                        ]
                    }
                },
            };
        case actionTypes.updateSdLanStackSuccess:
            return {
                ...state,
                selectedLocation: {
                    ...state.selectedLocation,
                    sdLanLocationOptions: {
                        ...state.selectedLocation.sdLanLocationOptions,
                        stacks: state.selectedLocation.sdLanLocationOptions.stacks.map((x) => {
                            if (x.id === action.payload.id) {
                                x[action.payload.name] = action.payload.value
                            }
                            return x;
                        })
                    }
                }
            };
        case actionTypes.deleteSdLanStackSuccess:
            return {
                ...state,
                allLocations: state.allLocations.map((x) => {

                    if(x.id === action.payload.locationId){
                        const filtered = x.sdLanLocationOptions.stacks.filter((s) => s.id !== action.payload.sdLanStackId);
                        return {
                            ...x,
                            sdLanLocationOptions: {
                                ...x.sdLanLocationOptions,
                                stacks: filtered
                            }
                        };
                    }
                    return x
                }),
                selectedLocation: {
                    ...state.selectedLocation,
                    sdLanLocationOptions: {
                        ...state.selectedLocation.sdLanLocationOptions,
                        stacks: state.selectedLocation.sdLanLocationOptions.stacks.filter((x) => x.id !== action.payload.sdLanStackId)
                    }
                }
            };
        case actionTypes.locationIsraCheckSuccess:
            return {
                ...state,
                israCheckInProgressLocationIds: [...state.israCheckInProgressLocationIds.filter((locationId) => locationId !== action.payload.id)],
            };
        case actionTypes.locationIsraCheckError:
            return {
                ...state,
                israCheckInProgressLocationIds: [...state.israCheckInProgressLocationIds.filter((locationId) => locationId !== action.payload.id)],
            };
        case actionTypes.updateLocationSipTrunkSuccess:
            return {
                ...state,
                selectedLocation: {
                    ...state.selectedLocation,
                    sip_trunks: [...state.selectedLocation.sip_trunks].map((row) => {
                        if (row.id === action.payload.sipTrunkId) {
                            return { ...row, ...action.payload.data };
                        } else {
                            return row;
                        }
                    }),
                }
            };
        /**
        * When a new quotation is selected, we need to clear the current locations
        */
        case actionTypes.setQuotationId:
            return {...initialState};
        case actionTypes.updateLocationSdWanOptions:
            return {
                ...state,
                selectedLocation: {
                    ...state.selectedLocation,
                    locationSdWanOptions: action.payload,
                }
            };
        case actionTypes.updateSdWanLocationOption:
            return {
                ...state,
                selectedLocation: {
                    ...state.selectedLocation,
                    locationSdWanOptions: {
                        ...state.selectedLocation.locationSdWanOptions,
                        [action.payload.property]: action.payload.value
                    },
                }
            };
        case actionTypes.updateSdWanLocationProduct:
            return {
                ...state,
                selectedLocation: {
                    ...state.selectedLocation,
                    locationSdWanOptions: {
                        ...state.selectedLocation.locationSdWanOptions,
                        product: action.payload
                    },
                }
            };
        case actionTypes.updateLocationSdWanActiveSuccess:
            return {
                ...state,
                selectedLocation: {
                    ...state.selectedLocation,
                    sdWanActive: action.payload,
                }
            };
        default:
            return state;
    }
}
