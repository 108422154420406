import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

import { actionTypes } from '../constants/actionTypes';
import { apiRoutes } from '../constants/apiRoutes';
import { toastr } from 'react-redux-toastr'

const getUsers = () => {
    return async (dispatch) => {
        try
        {
            let url = apiRoutes.adminRelated.getUsers;
            let response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(_getUsersSuccess(response['users']));
            dispatch(_getCategoriesSuccess(response['categories']));
        }
        catch (error)
        {
          if (error.response.status === 403) {
            toastr.error('Het is niet mogelijk om de gebruikers op te halen', 'U heeft geen rechten om de gebuikers op te halen')
          } else {
            toastr.error('Het is niet mogelijk de gebruikers op te halen', error.message);
          }
        }
    }
};

const _getUsersSuccess = (users) => {
    return {
        type: actionTypes.getUsers,
        payload: users
    }
};

const _getCategoriesSuccess = (categories) => {
    return {
        type: actionTypes.getCategories,
        payload: categories
    }
};

const getCompensation = () => {
    return async (dispatch) => {
        try
        {
            let url = apiRoutes.adminRelated.compensation;
            let response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(getCompensationSuccess(response));
        }
        catch (error)
        {
            if (error.response.status === 403) {
                toastr.error('Het is niet mogelijk om de gebruikers op te halen', 'U heeft geen rechten om de gebuikers op te halen')
            } else {
                toastr.error('Het is niet mogelijk de gebruikers op te halen', error.message);
            }
        }
    }
};

const getCompensationSuccess = (compensations) => {
    return {
        type: actionTypes.getCompensation,
        payload: compensations
    }
};

const updateCompensation = (compensation) => {
    return {
        type: actionTypes.updateCompensation,
        payload: compensation
    }
};

const saveCompensation = (compensation)=> {
    return async () => {
        try {
            let {id} = compensation;
            let url = apiRoutes.adminRelated.updateCompensation.replace(/{id}/, id);
            await ApiService.performRequest(RequestType.PATCH, url, compensation);
        } catch (error) {
            if (error.response.status === 403) {
                toastr.error('Het is niet mogelijk om de gebruikers op te halen', 'U heeft geen rechten om de gebuikers op te halen')
            } else {
                toastr.error('Het is niet mogelijk de gebruikers op te halen', error.message);
            }
        }
    }
};

const editUser = (userId, data) => {
    return async (dispatch) => {
        try
        {
            let url = apiRoutes.adminRelated.editUser.replace(/{userId}/, userId);
            await ApiService.performRequest(RequestType.PATCH, url, data);

            toastr.success('Gebruiker bewerkt', '');
            dispatch(_editUserSuccess(data));
        }
        catch (error)
        {
            if (error.response.status === 400) {
                toastr.error('Het is niet mogelijk om deze gebruiker te bewerken', 'Formulier bevat fouten')
            } else if(error.response.status === 403) {
                toastr.error('Het is niet mogelijk om deze gebruiker te bewerken', 'U heeft geen rechten om deze gebruiker te bewerken')
            } else {
                toastr.error('Het is niet mogelijk om deze gebruiker te bewerken', error.message);
            }
        }
    }
};

const _editUserSuccess = (user) => {
    return {
        type: actionTypes.editUser,
        payload: user
    }
};

const deleteUser = (userId) => {
    return async (dispatch) => {
        try
        {
            let url = apiRoutes.adminRelated.user + `/${userId}`;
            await ApiService.performRequest(RequestType.DELETE, url);

            toastr.success('U heeft deze gebruiker verwijderd', '');
            dispatch(_deleteUserSuccess(userId));
        }
        catch (error)
        {
          if (error.response.status === 403) {
            toastr.error('U kunt deze gebruiker niet verwijderen', 'U heeft geen rechten')
          } else {
            toastr.error('U kunt deze gebruiker niet verwijderen', error.message);
          }
        }
    }
};

const _deleteUserSuccess = (user) => {
    return {
        type: actionTypes.deleteUser,
        payload: user
    }
};

const assignDataToUser = (userToReplace, userToAssign) => {
    return async (dispatch) => {
        try
        {
          let url = apiRoutes.adminRelated.userToAssign.replace(/{userToReplace}/, userToReplace)
            .replace(/{userToAssign}/, userToAssign);
          let response = await ApiService.performRequest(RequestType.POST, url,
            {userToReplace: userToReplace, userToAssign: userToAssign});

          if (response) {
            return response.success;
          }
        }
        catch (error)
        {
            if (error.response.status === 400) {
                toastr.error('Het is niet mogelijk een nieuwe gebruiker toe te voegen', 'Het formulier bevat fouten')
            } else if(error.response.status === 403) {
                toastr.error('Het is niet mogelijk een nieuwe gebruiker toe te voegen', 'U heeft geen rechten')
            } else {
                toastr.error('Het is niet mogelijk een nieuwe gebruiker toe te voegen', error.message);
            }
        }
    }
};

const isDependentUser = (userId) => {
    return async (dispatch) => {
        try
        {
            let url = apiRoutes.adminRelated.hasQuotationData.replace(/{userId}/, userId);
            let response = await ApiService.performRequest(RequestType.GET, url);

            if(response) {
                return response.success;
            }
        }
        catch (error)
        {
            if (error.response.status === 400) {
                toastr.error('Het is niet mogelijk een nieuwe gebruiker toe te voegen', 'Het formulier bevat fouten')
            } else if(error.response.status === 403) {
                toastr.error('Het is niet mogelijk een nieuwe gebruiker toe te voegen', 'U heeft geen rechten')
            } else {
                toastr.error('Het is niet mogelijk een nieuwe gebruiker toe te voegen', error.message);
            }
        }
    }
};

const addUser = (user) => {
    return async (dispatch) => {
        try
        {
            let url = apiRoutes.adminRelated.createUser;
            let response = await ApiService.performRequest(RequestType.POST, url, user);

            toastr.success('Nieuwe gebruiker toegevoegd', '');
            dispatch(_addUserSuccess(response));
        }
        catch (error)
        {
            if (error.response.status === 400) {
                const uniquenessErrors = error.response.data;

                const errorMessages = {
                    email: {
                        title: 'Dit email adres is al in gebruik',
                        message: 'Er is al een gebruiker met dit email adres in Ratio. Het is niet mogelijk 2 accounts aan te maken op hetzelfde e-mailadres.'
                    },
                    phoneNumber: {
                        title: 'Dit telefoonnummer is al in gebruik',
                        message: 'Er is al een gebruiker met dit telefoonnummer in Ratio. Het is niet mogelijk 2 accounts aan te maken met hetzelfde telefoonnummer.'
                    }
                };

                ['phoneNumber', 'username', 'email'].forEach(key => {
                    if (uniquenessErrors[key]) {
                        uniquenessErrors[key].forEach(() => {
                            if (key === 'username') {
                                key = 'email';
                            }
                            toastr.error(errorMessages[key].title, errorMessages[key].message);
                        });
                    }
                });

                toastr.error('Het is niet mogelijk een nieuwe gebruiker toe te voegen', 'Het formulier bevat fouten')
            } else if(error.response.status === 403) {
              toastr.error('Het is niet mogelijk een nieuwe gebruiker toe te voegen', 'U heeft geen rechten')
            } else {
                toastr.error('Het is niet mogelijk een nieuwe gebruiker toe te voegen', error.message);
            }
        }
    }
};

const _addUserSuccess = (user) => {
    return {
        type: actionTypes.addUser,
        payload: user
    }
};

const getMenuItems = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.getAdminMenuItems
    })
  };
};

const setCurrentSection = (sectionId) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.setCurrentAdminSection,
      payload: sectionId
    });
  }
};

const setCurrentUser = (user) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.setCurrentUser,
        payload: user
      });
  }
};

const getFlashCalculations = () => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.adminRelated.getFlashCalculations;
            let response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(setFlashData(response));

            return response;
        } catch (error) {
            if (error.response.status === 403) {
                toastr.error('Het is niet mogelijk om de gebruikers op te halen', 'U heeft geen rechten om de gebuikers op te halen')
            } else {
                toastr.error('Het is niet mogelijk de gebruikers op te halen', error.message);
            }
        }
    }
};

const setFlashData = (data) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.getFlashCalculations,
            payload: data
        });
    }
};

const updateFlashCalculation = (flashCalculation)=> {
    return async () => {
        try {
            let {id} = flashCalculation;
            let url = apiRoutes.adminRelated.updateFlashCalculation.replace(/{flashCalculationId}/, id);
            await ApiService.performRequest(RequestType.PATCH, url, flashCalculation);
        } catch (error) {
            if (error.response.status === 403) {
                toastr.error('Het is niet mogelijk om de gebruikers op te halen', 'U heeft geen rechten om de gebuikers op te halen')
            } else {
                toastr.error('Het is niet mogelijk de gebruikers op te halen', error.message);
            }
        }
    }
};


export default {
    getUsers,
    editUser,
    deleteUser,
    addUser,
    getMenuItems,
    assignDataToUser,
    isDependentUser,
    setCurrentSection,
    getCompensation,
    updateCompensation,
    saveCompensation,
    setCurrentUser,
    updateFlashCalculation,
    getFlashCalculations
}
