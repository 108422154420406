import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import getQuotationOptionsThunk from "../../actions/workflow/isdnMigrationActions/getQuotationOptionsThunk";
import updateQuotationOptionsThunk from "../../actions/workflow/isdnMigrationActions/updateQuotationOptionsThunk";
import quotationActions from "../../actions/quotationActions";
import partnerServicesActions from "../../actions/partnerServicesActions";
import connect from "react-redux/lib/connect/connect";
import {Input} from "react-materialize";
import InputNumberWrapper from "./InputNumberWrapper";
import debounce from "lodash/debounce";

class ITServicesAcronisOption extends Component {
    constructor(props) {
        super(props);
        this.state ={
            products: [],
            selections: []
        }
    }

    componentDidMount() {
        const salesValueAcronisOption = this.props.authentication.salesValueAcronisOption;
        this.saveSelections = debounce(this.saveSelections, 1000);

        const selections = this.props.partnerServices.itServicesSelections;

        this.setState({
            products: this.props.partnerServices.productsToLink.acronisProducts.products,
            selections: selections.filter(selection => selection.category === salesValueAcronisOption.category)
        })
    }

    removeOption = (index) => {
        const {selections} = this.state;
        selections.splice(index, 1);

        this.saveSelections(selections, this.props.quotation.currentQuotation.id, 'it');
        this.setState({selections});
    };

    addOption = () => {
        const {selections} = this.state;
        const {allowDecimalQuantity} = this.props;
        const salesValueAcronisOption = this.props.authentication.salesValueAcronisOption;

        const quantityStep = allowDecimalQuantity ? 0.01 : 1;

        selections.push({
            id: '',
            quantity: quantityStep,
            category: salesValueAcronisOption.category
        });

        this.setState({selections});
    };

    changeQuantity = (event, index) => {
        let {value, min, max} = event.target;
        let {selections} = this.state;

        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        selections[index].quantity = parseInt(value).toString();

        this.setState({selections});

        //filter selections to not send null ids
        selections = selections.filter(selection => selection.id != null) || [];

        this.saveSelections(selections, this.props.quotation.currentQuotation.id, 'it');
    }

    renderQuantityInput = (option, index) => {
        const {allowDecimalQuantity} = this.props;
        const quantityStep = allowDecimalQuantity ? 0.01 : 1;
        let optionQuantity = option.quantity ? option.quantity : 1;
        const {selections} = this.state;
        const {inputsDisabled} = this.props.quotation.currentQuotation;

        return (
            !allowDecimalQuantity ?
                <Input
                    s={1}
                    style={{marginBottom: selections?.length > 1 ? '15px' : null}}
                    type="number"
                    label={index === 0 ? 'aantal' : ''}
                    value={optionQuantity}
                    onChange={(e) => {
                        this.changeQuantity(e, index)
                    }}
                    step={quantityStep}
                    min={quantityStep}
                    max={999}
                    disabled={inputsDisabled}
                /> :
                <InputNumberWrapper
                    s={1}
                    style={{marginBottom: selections?.length > 1 ? '15px' : null}}
                    label={index === 0 ? 'aantal' : ''}
                    optionQuantity={optionQuantity}
                    index={index}
                    changeDecimalQuantity={this.changeDecimalQuantity}
                    step={quantityStep}
                    min={quantityStep}
                    max={999}
                    precision={2}
                    className={'col input-field s1'}
                    inputsDisabled={inputsDisabled}
                />
        );
    };

    onChange = (event, index) => {
        const {value} = event.target;
        const {selections} = this.state;
        const {allowDecimalQuantity} = this.props;

        selections[index].id = parseInt(value);
        selections[index].category = 'Acronis';

        const foundSelectedIndex = selections.findIndex((selection, idx) => selection.id === parseInt(value) && idx !== index);
        if (foundSelectedIndex !== -1) {

            if (selections[foundSelectedIndex].quantity >= 999) {
                return;
            }

            if (allowDecimalQuantity) {
                selections[index].quantity = (parseFloat(selections[index].quantity)
                    + parseFloat(selections[foundSelectedIndex].quantity)).toFixed(2);
            } else {
                selections[index].quantity = parseInt(selections[index].quantity)
                    + parseInt(selections[foundSelectedIndex].quantity);
            }

            selections.splice(foundSelectedIndex, 1);
        }

        this.setState({selections});

        this.saveSelections(selections, this.props.quotation.currentQuotation.id, 'it');
    };

    saveSelections = (selections, quotationId, type) => {
        const {itServicesSelections} = this.props.partnerServices;
        const allSelections = itServicesSelections.filter(selection => selection.category !== this.props.authentication.salesValueAcronisOption.category);
        const newSelections = [...allSelections, ...selections];

        this.props.partnerServicesActions.persistPartnerServicesSelections(newSelections, quotationId, type).then(() => {
            this.props.partnerServicesActions.setITServiceSelection(newSelections);
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const selections = this.props.partnerServices.itServicesSelections;
        const oldSelections = prevProps.partnerServices.itServicesSelections;

        if (oldSelections !== selections) {
            this.setState({
                selections: [...selections.filter(selection => selection.category === this.props.authentication.salesValueAcronisOption.category)]
            })
        }
    }

    render() {
        const {selections, products} = this.state;
        const {inputsDisabled} = this.props.quotation.currentQuotation;
        const {allowDecimalQuantity} = this.props;
        const quantityStep = allowDecimalQuantity ? 0.01 : 1;
        const salesValueAcronisOption = this.props.authentication.salesValueAcronisOption;

        if (!selections.length) {
            selections.push({
                id: "",
                quantity: quantityStep,
                category: salesValueAcronisOption.category
            });
        }

        return (
            <>
                <fieldset style={{border: 'none', padding: 'unset', margin: 'unset'}}>
                    <div className="row">
                        <h1 className='ratio-section-title' style={{marginTop: 0}}>
                            <i className="small material-icons left">
                                settings
                            </i>
                            Acronis Diensten
                        </h1>
                    </div>

                    {selections.map((option, index) => {
                        return (
                            <div className="row" key={`${index}`} style={{marginBottom: 0}}>
                                {option.id > 0 && this.renderQuantityInput(option, index)}

                                <Input
                                    s={!option?.id ? 8 : 7}
                                    className={selections?.length > 1 && !(selections?.length - 1 === index) ? "ratio-input" : ''}
                                    name='product'
                                    type='select'
                                    label={index === 0 ? 'Kies de gewenste Acronis IT dienst' : ''}
                                    onChange={(e) => {this.onChange(e, index)}}
                                    value={option.id}
                                    multiple={false}
                                    disabled={inputsDisabled}
                                >
                                    <option value="">Selecteer</option>
                                    {products.map((optionItem) => <option key={optionItem.id} value={optionItem.id}>{optionItem.name}</option>)}
                                </Input>

                                <button
                                    className='btn doceri-btn-right ratio-btn-right'
                                    onClick={() => this.removeOption(index)}
                                    style={{float: 'left', marginLeft: 10}}
                                    disabled={inputsDisabled}
                                >
                                    Verwijder
                                </button>

                                {selections.length - 1 === index &&
                                <button className="btn doceri-btn-right ratio-btn-right" onClick={this.addOption} disabled={inputsDisabled}>
                                    Acronis IT dienst toevoegen
                                </button>
                                }
                            </div>
                        );
                    })}
                </fieldset>
            </>
        )
    }
}

const mapStateToProps = ({quotation, quotationOptions, partnerServices, authentication}) => {
    return {
        quotation,
        quotationOptions,
        partnerServices,
        authentication
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getQuotationOptions: bindActionCreators(getQuotationOptionsThunk, dispatch),
        updateQuotationOptions: bindActionCreators(updateQuotationOptionsThunk, dispatch),
        quotationActions: bindActionCreators(quotationActions, dispatch),
        partnerServicesActions: bindActionCreators(partnerServicesActions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ITServicesAcronisOption);