import * as React from 'react';

const DialogFooter = ({buttonLeft, buttonCenter, buttonRight, children}) => {
    return (
        <footer className="md-dialog__footer">
            {children || (
                <React.Fragment>
                    <div className="col s4 alignleft">
                        {buttonLeft && buttonLeft}
                    </div>
                    <div className="col s4 alignleft">
                        {buttonCenter && buttonCenter}
                    </div>
                    {buttonRight && (
                        <div className="col s4 alignright">{buttonRight}</div>
                    )}
                </React.Fragment>
            )}
        </footer>
    );
};

export default DialogFooter;
