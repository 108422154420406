import styled from 'styled-components';

export default styled.div`
  width: 60px;
  height: 60px;
  padding: 5px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #d1d1d1;
  display: inline-block;
  cursor: pointer;
`;
