import React, {Component} from 'react';
import {connect} from 'react-redux';

import {DelayInput} from "react-delay-input";
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from "material-ui";
import {Input} from "react-materialize";

class DataManagementProductsGrid extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isModuleCollapsed: true,
      isProductOptionsCollapsed: true,
      isHipProductsCollapsed: true,
      module: this.props.module,
      searchAccessTypeValue: '',
    }
  }

  generateHeaders = () => {
    return (
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow>
          <TableHeaderColumn>
            <div style={{fontWeight: 'bold'}}>Aantal</div>
            <div className="subthread" style={{display: 'inline-block'}}/>
          </TableHeaderColumn>
          <TableHeaderColumn style={{width: "40%"}}>
            <div style={{whiteSpace: 'break-word', fontWeight: 'bold'}}>Product naam</div>
            <div className="subthread" style={{display: 'inline-block'}}/>
          </TableHeaderColumn>
          <TableHeaderColumn style={{position: 'unset'}}>
            <span style={{fontWeight: 'bold'}}>Product type</span>
            <div className="subthread"/>
          </TableHeaderColumn>
          <TableHeaderColumn style={{position: 'unset'}}>
            <span style={{fontWeight: 'bold'}}>Actie</span>
            <div className="subthread"/>
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>
    )
  };


  onChange = (event, product) => {
    const {value} = event.target;
    this.props.updateSelection(value, product);
  };

  renderAmount = (product) => {
    let identifiedSelection = this.props.identifySelection(product);
    let currentAmount = identifiedSelection ? identifiedSelection.quantity : 0;

    return (
      <DelayInput
        value={currentAmount}
        type="number"
        name="quantity"
        className="without-margin-bottom delayInputForLead"
        delayTimeout={1000}
        min={0}
        style={{width: '50%', height: '1.5rem'}}
        onChange={event => this.onChange(event, product)}
      />
    );
  };

  toggleCollapse = (name, value) => {
    this.setState({[name]: !value});
  };

  filterProducts = (products) => {
    const {searchAccessTypeValue} = this.state;

    if (searchAccessTypeValue) {
      return products.filter(product => product.accessType?.match(new RegExp(searchAccessTypeValue, 'i')));
    }

    return products;
  };

  renderModuleTable = (module) => {
    const isProductOptionsCollapsed = this.state.isProductOptionsCollapsed;
    const isHipProductsCollapsed = this.state.isHipProductsCollapsed;
    const {moduleName, products, productOptions, hipProducts} = module;
    const isAccessModule = moduleName === 'Access';

    const mainProducts = isAccessModule ? this.filterProducts(products) : products;
    const prodOptions = isAccessModule ? this.filterProducts(productOptions) : productOptions;
    const hipProds = isAccessModule ? this.filterProducts(hipProducts) : hipProducts;

    return (
      <Table
        className="highlight without-margin-bottom"
        wrapperStyle={{overflow: 'initial'}}
        selectable={false}
        displayRowCheckbox={false}
      >
        {this.generateHeaders()}
        <TableBody displayRowCheckbox={false}>
          {mainProducts.map((product) => this.renderProduct(product, 'product'))}

          {prodOptions && prodOptions.length && (
            <TableRow key={module.id + 'productOptions'}>
              <TableRowColumn style={{borderRight: 'none'}}>
                <h4>
                  <span className="ratio-section-title">{module.moduleName} productopties</span>
                </h4>
              </TableRowColumn>
              <TableRowColumn style={{borderRight: 'none'}}/>
              <TableRowColumn style={{borderRight: 'none'}}/>
              <TableRowColumn>
                <button
                  className="btn doceri-btn-right ratio-btn-right doceri-btn-margin-right"
                  onClick={() => this.toggleCollapse('isProductOptionsCollapsed', isProductOptionsCollapsed)}
                >
                  <i className="small material-icons left white-text">unfold_more</i>
                  {isProductOptionsCollapsed ? 'Uitklappen' : 'Inklappen'}
                </button>
              </TableRowColumn>
            </TableRow>
          )}

          {!isProductOptionsCollapsed && prodOptions && prodOptions.length &&
          prodOptions.map((product) => this.renderProduct(product, 'option'))
          }

          {hipProds && hipProds.length && (
            <TableRow key={module.id + 'hipProducts'}>
              <TableRowColumn style={{borderRight: 'none'}}>
                <h4>
                  <span className="ratio-section-title">{module.moduleName} HIP products</span>
                </h4>
              </TableRowColumn>
              <TableRowColumn style={{borderRight: 'none'}}/>
              <TableRowColumn style={{borderRight: 'none'}}/>
              <TableRowColumn>
                <button
                  className="btn doceri-btn-right ratio-btn-right doceri-btn-margin-right"
                  onClick={() => this.toggleCollapse('isHipProductsCollapsed', isHipProductsCollapsed)}
                >
                  <i className="small material-icons left white-text">unfold_more</i>
                  {isHipProductsCollapsed ? 'Uitklappen' : 'Inklappen'}
                </button>
              </TableRowColumn>
            </TableRow>
          )}

          {!isHipProductsCollapsed && hipProds && hipProds.length &&
          hipProds.map((product) => this.renderProduct(product, 'hip'))
          }
        </TableBody>
      </Table>
    );
  };

  renderProduct = (product, type) => {
    return (
      <TableRow key={product.id + type}>
        <TableRowColumn style={{height: '28px', fontSize: '0.8rem'}}>
          {this.renderAmount(product)}
        </TableRowColumn>

        <TableRowColumn
          style={{width: "40%", whiteSpace: 'normal', wordWrap: 'break-word', height: '28px', fontSize: '0.8rem'}}>
          {product.name}
        </TableRowColumn>

        <TableRowColumn style={{textAlign: 'right', height: '28px', fontSize: '0.8rem'}}>
          {product.isCustomProduct ? 'Product op maat' : 'Portfolio product'}
        </TableRowColumn>

        <TableRowColumn style={{textAlign: 'right', height: '28px', fontSize: '0.8rem'}}>
          {''}
        </TableRowColumn>
      </TableRow>
    );
  };

  render() {
    const isModuleCollapsed = this.state.isModuleCollapsed;
    const {module, searchAccessTypeValue} = this.state;
    const isAccessModule = module.moduleName === 'Access';

    return (
      <div className="ratiotable">
        <div className="row">
          <div className="col s12 overviewkopbg">
            <div className="col s12 m5 overviewkop" style={{padding: '10px'}}>
              <h4>
                <span className="ratio-section-title">
                  {module.moduleName}
                </span>
              </h4>
            </div>

            <div
              className="col s12 m7 overviewkop"
              style={{
                padding: '10px',
                float: 'right',
                display: isAccessModule ? 'flex' : '',
                justifyContent: isAccessModule ? 'space-between' : ''
              }}
            >
              {isAccessModule &&
              <div style={{display: 'flex'}}>
                <Input
                  name="accessType"
                  type='select'
                  onChange={e => this.setState({searchAccessTypeValue: e.target.value, searchDownloadSpeedValue: ''})}
                  value={searchAccessTypeValue}
                  style={{marginBottom: 20}}
                  multiple={false}
                >
                  <option value={''}>Verbindingstype</option>
                  {
                    module.accessTypes && module.accessTypes.map((el) => {
                      return <option value={el}>{el}</option>
                    })
                  }
                </Input>

              </div>
              }
              <button
                className="btn doceri-btn-right ratio-btn-right doceri-btn-margin-right"
                onClick={() => this.toggleCollapse('isModuleCollapsed', isModuleCollapsed)}
              >
                <i className="small material-icons left white-text">unfold_more</i>
                {isModuleCollapsed ? 'Uitklappen' : 'Inklappen'}
              </button>
            </div>
          </div>
          {
            !isModuleCollapsed && this.renderModuleTable(module)
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({}) => {
  return {}
};

const mapDispatchToProps = () => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(DataManagementProductsGrid);
