import {
  actionTypes
} from '../constants/actionTypes';

const initialState = {
  allProducts: [],
  provider: null,
  pages: 1,
  currentPage: 1,
  resultsCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.setProductsProvider:
      return {
        ...state,
        provider: action.payload
      };
    case actionTypes.setAllProducts:
      return {
        ...state,
        allProducts: action.payload
      };
    case actionTypes.setProductPagination:
      return {
        ...state,
        pages : action.payload.pages,
        currentPage: action.payload.currentPage,
        resultsCount: action.payload.resultsCount
      };
    default:
      return state;
  }
}
