import {actionTypes} from '../constants/actionTypes';

const initialState = {
    signingLog: [],
    quotationSelectedProducts: [],
    quotationOrganization: null,
    digitalSigningGroups: null,
    digitalSigningFields: null,
    isSentSmsCode: false,
    isSmsCodeConfirmed: false,
    pdfDocument: '',
    attachmentDocument: '',
    digitalSigningDetails: {
        header: '',
        isHeaderBold: false,
        content: '',
        isContentBold: false,
    },
    isTermsAndConditionsEnabled: false,
    isSubmitButtonDisabled: false,
    isDigitalSigningLogSigned: false
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.getDigitalSigningLog:
            return {
                ...state,
                signingLog: action.payload
            };
        case actionTypes.getQuotationSelectedProducts:
            return {
                ...state,
                quotationSelectedProducts: action.payload
            };
        case actionTypes.getQuotationOrganization:
            return {
                ...state,
                quotationOrganization: action.payload
            };
        case actionTypes.getDigitalSigningGroupsSuccess:
            return {
                ...state,
                digitalSigningGroups: action.payload
            };
        case actionTypes.getDigitalSigningFieldsSuccess:
            return {
                ...state,
                digitalSigningFields: action.payload
            };
        case actionTypes.sendSmsCodeSuccess:
            return {
                ...state,
                isSentSmsCode: action.payload
            };
        case actionTypes.confirmSmsCodeSuccess:
            return {
                ...state,
                isSmsCodeConfirmed: action.payload
            };
        case actionTypes.downloadPDFDocumentSuccess:
            return {
                ...state,
                pdfDocument: action.response
            };
        case actionTypes.downloadAttachmentFilesSuccess:
            return {
                ...state,
                attachmentDocument: action.response
            };
        case actionTypes.setDigitalSigningDetails:
            return {
                ...state,
                digitalSigningDetails: action.payload
            };
        case actionTypes.setIsTermsAndConditions:
            return {
                ...state,
                isTermsAndConditionsEnabled: action.payload
            }
        case actionTypes.setIsDigitalSigningLogSigned:
            return {
                ...state,
                isDigitalSigningLogTermsSigned: action.payload
            }
        case actionTypes.setIsSubmitButtonDisabled:
            return {
                ...state,
                isSubmitButtonDisabled: action.payload
            }
        default:
            return state;
    }
}
