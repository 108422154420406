import {apiRoutes} from '../../constants/apiRoutes';
import {actionTypes} from "../../constants/actionTypes";
import ApiService from "../../services/apiService";
import RequestType from "../../constants/requestType";
import {toastr} from 'react-redux-toastr';
import _ from "lodash";

const updateHostedVoiceOptions = (quotationId, locationId, hostedVoiceOptions) => {
  return async (dispatch) => {
    const url = apiRoutes.hostedVoiceRelated.patch.replace(/{quotationId}/, quotationId)
        .replace(/{locationId}/, locationId);
    try {
      const response = await ApiService.performRequest(RequestType.PATCH, url, hostedVoiceOptions);
      dispatch(updateHostedVoiceOptionsState(response));
    } catch (error) {
      toastr.error(error.message);
    }

  }
};

const removeHostedVoiceNumber = (quotationId, locationId, data) => {
  return async (dispatch) => {
    const url = apiRoutes.hostedVoiceRelated.removeHostedVoiceNumber.replace(/{quotationId}/, quotationId)
      .replace(/{locationId}/, locationId);
    try {
      const response = await ApiService.performRequest(RequestType.PATCH, url, data);
      dispatch(updateHostedVoiceOptionsState(response));
    } catch (error) {
      const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;
      toastr.error(message);
    }
  }
};

const getHardware = () => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.hostedVoiceRelated.hardware;
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(_getHardwareSuccess(response));
    }
    catch ({message}) {
      toastr.error('Het is niet mogelijk om de hosted voice hardware op te halen', message);
    }
  }
};

const _getHardwareSuccess = (response) => {
  return {
    type: actionTypes.getHostedVoiceHardware,
    response: response
  }
};

const updateHostedVoiceOptionsState = (hostedVoiceOptions) => {
  return {
    type: actionTypes.updateHostedVoiceOptions,
    payload: {
      hostedVoiceOptions
    }
  }
};

const getVamoVoiceProductsCount = (quotationId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.hostedVoiceRelated.getVamoVoiceProductsCount
          .replace(/{quotationId}/, quotationId);
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getVamoVoiceProductsCountSuccess(response));

    } catch ({message}) {
      toastr.error('Het is niet mogelijk om de hosted voice hardware op te halen', message);
    }
  }
}

const getVamoVoiceProductsCountSuccess = (response) => {
  return {
    type: actionTypes.getVamoVoiceProductsCount,
    response: response
  }
}

const getUccOptions = (quotationId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.hostedVoiceRelated.getUccOptions.replace(/{quotationId}/, quotationId);
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getUccOptionsSuccess(response));

    } catch ({message}) {
      toastr.error('Het is niet mogelijk om de hosted voice hardware op te halen', message);
    }
  }
}

const getUccOptionsSuccess = (uccOptions) => {
  return {
   type: actionTypes.getUccOptions,
   payload: uccOptions
  }
}

const patchUccOptions = (quotationId, data) => {
  return async () => {
    try {
      let url = apiRoutes.hostedVoiceRelated.patchUccOptions.replace(/{quotationId}/, quotationId);
      await ApiService.performRequest(RequestType.PATCH, url, data);
    } catch ({message}) {
      toastr.error('Het is niet mogelijk om de hosted voice hardware op te halen', message);
    }
  }
}

const getUccSelections = (quotationId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.hostedVoiceRelated.getUccSelections.replace(/{quotationId}/, quotationId);
      return await ApiService.performRequest(RequestType.GET, url);
    } catch ({message}) {
      toastr.error('Het is niet mogelijk om de hosted voice hardware op te halen', message);
    }
  }
}

const patchUccProfiles = (quotationId, data) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.hostedVoiceRelated.patchUccProfiles.replace(/{quotationId}/, quotationId);
      return await ApiService.performRequest(RequestType.PATCH, url, data);
    } catch ({message}) {
      toastr.error('Het is niet mogelijk om de hosted voice hardware op te halen', message);
    }
  }
}

const patchUccTelephoneNumbers = (quotationId, data) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.hostedVoiceRelated.patchTelephoneNumbers.replace(/{quotationId}/, quotationId);
      return await ApiService.performRequest(RequestType.PATCH, url, data);
    } catch ({message}) {
      toastr.error('Het is niet mogelijk om de hosted voice hardware op te halen', message);
    }
  }
}

export default {
  updateHostedVoiceOptions,
  getHardware,
  removeHostedVoiceNumber,
  updateHostedVoiceOptionsState,
  getVamoVoiceProductsCount,
  getUccOptions,
  patchUccOptions,
  getUccSelections,
  patchUccProfiles,
  patchUccTelephoneNumbers,
}
