import React, {Component} from 'react';
import PropTypes from 'prop-types';
import WrapperStyled from './WrapperStyled';
import Swatch from './Swatch';
import Color from './Color';
import Cover from './Cover';
import Popover from './Popover';
import SketchPickerStyled from './SketchPickerStyled';
import AuthenticatedService from "../../services/authenticationService";

const setPrestColors = () => {
  const storageTheme = AuthenticatedService.getThemeFromLocalStorage();

  let presetColors = [];
  if (storageTheme && storageTheme.hasOwnProperty('palette')) {
    presetColors = Object.keys(storageTheme.palette).map(key => {
      const {r, g, b, a} = storageTheme.palette[key];
      return `rgba(${r}, ${g}, ${b}, ${a})`;
    });
  }

  return presetColors;
};

const presetColors = setPrestColors();

class ColorPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayColorPicker: false,
      color: this.props.value,
    };
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.setState({ color: color.rgb });
    this.props.onChange(color.rgb);
  };

  nornalizeRGB = (color) => {
    if (color.hasOwnProperty('r')) {
      return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
    } else {
      return color;
    }
  };

  render() {
    return (
      <WrapperStyled>
        <Swatch onClick={ this.handleClick }>
          <Color background={this.nornalizeRGB(this.state.color)} />
        </Swatch>
        { this.state.displayColorPicker ? (
          <Popover>
            <Cover onClick={ this.handleClose } />
            <SketchPickerStyled
              color={ this.state.color }
              onChangeComplete={ this.handleChange }
              presetColors={presetColors}
            />
          </Popover>
         ) : null }

      </WrapperStyled>
    )
  }
}

ColorPicker.defaultProps = {
  value: { r: 255, g: 255, b: 255, a: 1 },
};

ColorPicker.propTypes = {
  value: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

export default ColorPicker;
