import { createStore, compose, applyMiddleware } from 'redux';
import { combineReducers } from 'redux'
import thunk from 'redux-thunk';
import RatioReducers from './reducers';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(combineReducers({
  ...RatioReducers,
}), composeEnhancer(applyMiddleware(thunk)));

export default store;
