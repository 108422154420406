import {
  actionTypes
} from '../constants/actionTypes';

const initialState = {
  mobileYears: [],
  mobileProducts: [],
  mobileDataProducts: [],
  currentMobileProducts: [],
  currentMobileProductOptions: {},
  devicePaymentOptions: [],
  existingMobileData: [],
  upgradingMobileData: [],
  vamoProducts: [],
  simCardProducts: [],
  exceptionEndDateProducts: [],
  profilesLoader: false,
  pendingDataRetrieval: [],
  quotationOperators: [],
  availableTelephoneNumbers: [],
};

export default (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.updateMobileOfferYears:
      return {
        ...state,
        mobileYears: action.payload
      };
    case actionTypes.getMobileOfferProducts:
      return {
        ...state,
        mobileProducts: action.payload.mobileProducts,
        currentMobileProducts: action.payload.currentlySelectedProducts,
        currentMobileProductOptions: action.payload.mobileProductOptions,
        devicePaymentOptions: action.payload.devicePaymentOptions,
        mobileDataProducts: action.payload.mobileDataProducts,
        vamoProducts: action.payload.vamoProducts,
        simCardProducts: action.payload.simCardProducts,
      };
    case actionTypes.updateMobileOfferProducts:
      return {
        ...state,
        currentMobileProducts: action.payload
      };
    case actionTypes.addNewMobileProductSelection:
      return {
        ...state,
        currentMobileProducts: [...state.currentMobileProducts, action.payload]
      };
    case actionTypes.getExistingMobileData:
      return {
        ...state,
        existingMobileData: action.payload
      };
    case actionTypes.getUpgradingMobileData:
      return {
        ...state,
        upgradingMobileData: action.payload
      };
    case actionTypes.getExceptionEndDateProducts:
      return {
        ...state,
        exceptionEndDateProducts: action.payload
      };
    case actionTypes.updateProfilesLoader:
      return {
        ...state,
        profilesLoader: action.payload
      };
    case actionTypes.updateMobileDataRetrieval:
      return {
        ...state,
        pendingDataRetrieval: action.payload
      };
    case actionTypes.getQuotationOperators:
      return {
        ...state,
        quotationOperators: action.payload
      };
    case actionTypes.updateQuotationOperators:
      const {id, amount} = action.payload;
      let quotationOperators = [...state.quotationOperators];

      let foundIndex = quotationOperators.find((operator) => operator.id === id);
      if (foundIndex > -1) {
        quotationOperators[foundIndex]['amount'] = amount;
      }

      return {
        ...state,
        quotationOperators: quotationOperators
      }
    case actionTypes.getAvailableTelephoneNumbers:
      return {
        ...state,
        availableTelephoneNumbers: action.payload
      };
    default:
      return state;
  }
}
